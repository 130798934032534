/**
 * 기획전 - 타임특가
 */

import TopicHeader from "src/pages/component/topicSection/topicHeader";
import TopicSectionContainer from "src/pages/component/topicSection/topicSectionContainer";
import TopicProductList from "src/pages/component/topicSection/topicProductList";
import Timer from "src/pages/component/topicSection/timer";

const pathList = require("src/path/pathJSON.json");

const TopicTimer = (props) => {
	const { timeSaleInfo } = props;

	if (!timeSaleInfo) return;
	return (
		<TopicSectionContainer>
			<TopicHeader
				topic={timeSaleInfo}
				href={`${pathList?.product?.topicTimer}?listNo=${timeSaleInfo?.listNo}`}
			>
				<Timer startAt={timeSaleInfo?.startAt} endAt={timeSaleInfo?.endAt} isShowTimer />
			</TopicHeader>
			<TopicProductList topicProductList={timeSaleInfo?.items || []} />
		</TopicSectionContainer>
	);
};

export default TopicTimer;
