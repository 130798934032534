/**
 * 회원 정보 수정
 */

import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/**
 * 닉네임, 프로필 이미지 수정
 * @param {Object} d
 * <code>
 * {
 * 		fileNo: 0
 * 		, nickname: ""
 * }
 * </code>
 * @returns
 */
export const modifyMemberProfile = async (d) => {
	const url = `${API_PATHS.MEMBER_PROFILE}`;
	try {
		const result = await api.put(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 비밀번호 변경
 * @param {Object} d
 * <code>
 * {
 * 		currentPassword: ""
 * 		, newPassword: ""
 * }
 * </code>
 * @returns
 */
export const modifyMemberPassword = async (d) => {
	const url = `${API_PATHS.MEMBER_CHANGE_PW}`;
	try {
		const result = await api.put(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 휴대폰 변경 ( 본인인증 후 리턴값으로 진행 )
 * @param {Object} d
 * <code>
 * {
 * 		ci: ""
 * 		, newPhone: ""
 * }
 * </code>
 * @returns
 */
export const modifyMemberPhone = async (d) => {
	const url = `${API_PATHS.MEMBER_MODIFY_PHONE}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
