/**
 * 쿠폰 관리
 */

import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/**
 * 쿠폰 목록 조회
 * @param {Object} d
 * <code>
 * {
 * 		offset: 0
 * 		, page: 0
 * }
 * </code>
 * @returns
 */
export const getCouponList = async (d) => {
	const url = `${API_PATHS.MEMBER_COUPON}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
