/**
 * 마이페이지 - 쇼핑내역 - 환불
 */

import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/**
 * 주문 교환 배송비 조회
 * @param {Object} d
 * <code>
 * {
 * 		*deliveryType: ""//수거방법 - COMPANY:택배발송, DIRECT:직접발송, LATER: 나중에입력;
 * 		, *itemNo: 0
 * 		, memberAddressNo: 0
 * 		, *orderNo: 0
 * 		, reasonNo: 0
 * }
 * </code>
 * @returns
 */
// export const getRefundDeliveryPrice = async ( d ) => {
// 	const url = `${API_PATHS.ORDER_REFUND_DELIVERY_PRICE}`;
// 	try {
// 		const result = await api.post( url, d );
// 		return result.data;
// 	} catch( err ) {
// 		await Promise.reject( err );
// 	}
// };

/**
 * 환불 예정 금액 조회
 * @param {Object} d
 * <code>
 * {
 * 		*deliveryType: ""//수거방법 - COMPANY:택배발송, DIRECT:직접발송, LATER: 나중에입력;
 * 		, items: [
 * 			{ *itemNo: 0, *quantity: 0 }, { *itemNo: 0, *quantity: 0 }, ...
 * 		]
 * 		, *orderNo: 0
 * 		, reasonNo: 0
 * 		, collectMemberAddressNo: 0//회수지 식별자 ( null인 경우 주문 시 배송지 )
 * }
 * </code>
 * @returns
 */
export const expectedRefundAmount = async (d) => {
	const url = `${API_PATHS.ORDER_REFUND_EXPECTED_REFUND_AMOUNT}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 환불건 상세 조회
 * @param {Number} claimProductNo
 * @returns
 */
export const orderRefundDetail = async (claimProductNo) => {
	const url = `${API_PATHS.ORDER_REFUND_DETAIL}`;
	try {
		const result = await api.get(url.replace(":claimProductNo", claimProductNo));
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 주문 환불 요청
 * @param {Object} d
 * <code>
 * {
 * 		companyNo: 0//택배사 번호;
 * 		, content: ""//교환사유 상세;
 * 		, *deliveryType: ""//수거방법 - COMPANY:택배발송, DIRECT:직접발송, LATER: 나중에입력;
 * 		, env: ""//결제환경 - MOBILE, PC, APP;
 * 		, invoiceCode: ""//송장번호;
 * 		, *itemNo: 0//교환 상품 번호;
 * 		, *memberAddressNo: 0//회수지 주소 번호;
 * 		, *orderNo: 0//주문번호;
 * 		, paymentMethod: ""//결제유형 - CARD:신용카드, VBANK:무통장, PHONE:휴대폰, REWARD:리워드;
 * 		, *quantity: 0//수량;
 * 		, *reasonNo: 0//교환사유번호;
 * }
 * </code>
 * @returns
 */
export const orderRefund = async (d) => {
	const url = `${API_PATHS.ORDER_REFUND_REQUEST}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 환불 철회 요청
 * 반품 요청 시 선택했던 상품 목록 전체에 대해 반품 취소
 * @param {Object} d
 * <code>
 * {
 * 		*claimProductNo: 0//환불상품번호;
 * }
 * </code>
 * @returns
 */
export const orderRefundCancel = async (d) => {
	const url = `${API_PATHS.ORDER_REFUND_CANCEL}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
