/**
 * 마이린미 - 건강상태 기본정보 3 (몸무게)
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { REG_EXP } from "src/data/constEnum";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myLinme/survey/survey.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const MyLinmeSurveyDefault3 = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 설문 이전페이지로 이동
	 */
	const handlerClickPrev = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		props.handlerClickPrev(e);
	};

	/**
	 * 설문 다음페이지로 이동
	 */
	const handlerClickNext = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (!validation()) return;

		props.handlerClickNext(e);
	};
	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 설문 선택
	 */
	const handlerChangeData = (value) => {
		props?.setDefaultSelectedData3(value);
	};

	/**
	 * validation
	 */
	const validation = () => {
		if (!props?.defaultSelectedData3) return false;
		if (!REG_EXP.myLinme.weight.test(props?.defaultSelectedData3)) return false;

		return true;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="container underTopMenu myLinmeContainer survey">
			<div className="contents">
				<div className="surveyContainer">
					<div className="surveyContainerHeader">
						<div className="stepList">
							<div className="stepList">
								<div className="selectedStep">{textInfo.myLinme.survey.step.first}</div>
								<div className="step">2</div>
								<div className="step">3</div>
								<div className="step">4</div>
							</div>
						</div>
						<div className="closeButton" onClick={props.handlerClickClose} />
					</div>

					<div className="defaultContainer">
						<div className="issueIcon_3" />
						<div className="surveyTitle">
							<div>{textInfo.myLinme.survey.default3.title}</div>
							<div>{textInfo.myLinme.survey.default3.title2}</div>
						</div>
						<div className="surveySubTitle">
							{textInfo.myLinme.survey.default3.desc}
							<span>{textInfo.myLinme.survey.default3.desc2}</span>
							{textInfo.myLinme.survey.default3.desc3}
						</div>
						<div className="surveyAnswerContainer_3">
							<input
								type="text"
								placeholder={textInfo.myLinme.survey.default3.placeholder}
								value={props?.defaultSelectedData3 || ""}
								onChange={(e) => handlerChangeData(e.target.value)}
							/>
							<div className="unit">{textInfo.myLinme.survey.default3.unit}</div>
						</div>
					</div>
					<div className="buttonContainer">
						<div className="button prev" onClick={handlerClickPrev}>
							{textInfo.myLinme.survey.button.prev}
						</div>
						<div
							className={`button next ${validation() ? "" : "disable"}`}
							onClick={handlerClickNext}
						>
							{textInfo.myLinme.survey.button.next}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MyLinmeSurveyDefault3;
