export const LOCAL_STORAGE_KEY = {
	ACCESS_TOKEN: "linmeAccessToken",
	EMAIL: "linmeEmail",
	REFRESH_TOKEN: "linmeRefreshToken",
	USE_ALARM: "linmeUseAlarm",
	UUID: "linmeUUID",
};

export const COOKIE_KEY = {
	REMEMBER_EMAIL: "linmeRememberEmail",
	SEARCH: "linmeSearch",
};
