/**
 * 리워드 - 챌린지 카드
 */

import { getTimeStamp } from "src/utils/date";
import Image from "src/pages/component/common/image";
import { REWARD_CHALLENGE_STATUS, REWARD_CHALLENGE_TYPE } from "src/data";
const textInfo = require("src/data/textInfo.json");

const RewardChallengeCard = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const isIn = props?.status === REWARD_CHALLENGE_STATUS.IN.value;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<>
			<div className={`challengeImageCover ${!isIn && "finished"}`}>
				{props?.image?.webPath && <Image src={props?.image?.webPath} alt={props?.title} />}
			</div>
			<div className="challengeTitle">{props?.title}</div>
			{props?.type !== REWARD_CHALLENGE_TYPE.NOTICE && (
				<div className="challengeReward">
					<span>{props?.reward?.toLocaleString()}</span>
					{textInfo.common.rewardUnit}
				</div>
			)}
			<div className="challengePeriod">
				{getTimeStamp(props?.startAt, "YY.MM.DD")} - {getTimeStamp(props?.endAt, "YY.MM.DD")}
			</div>
		</>
	);
};

export default RewardChallengeCard;
