/**
 * 카테고리
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */
import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/* ---------------------------------------------------------------------------------------------------- */
/* FUNCTION */
/* ---------------------------------------------------------------------------------------------------- */

/**
 * 카테고리 목록
 * @returns
 */
export const getCategoryList = async () => {
	const url = `${API_PATHS.CATEGORY_LIST}`;
	try {
		const result = await api.get(url);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 카테고리별 상품 목록
 * @param {Object} d
 * <code>
 * {
 * 		categoryNoList: [ 0, 0, ... ]//-1 입력 시 전체 데이터;
 * 		, *offset: 0
 * 		, *page: 0
 * }
 * </code>
 */
export const productListCategory = async (d) => {
	const url = `${API_PATHS.PRODUCT_CATEGORY}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
