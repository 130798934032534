import { uploadFile, deleteFile } from "src/api/etc/file";
import { API_RESULT_STATUS, FILE_TYPE, FILE_IMAGE_MAX_SIZE_BYTE } from "src/data/constEnum";

// ----------------------------------------------------------------------------------------------------;
// EVENT;
// ----------------------------------------------------------------------------------------------------;

// ----------------------------------------------------------------------------------------------------;
// FUNCTION;
// ----------------------------------------------------------------------------------------------------;

/**
 * 파일 사이즈 체크
 * @param {Object} file
 * @returns
 */
export const checkFileSize = async (file) => {
	if (file.size > FILE_IMAGE_MAX_SIZE_BYTE) return false;
	return true;
};

/**
 * 파일 형식 체크
 * @param {Object} file
 * @returns
 */
export const checkFileType = async (file) => {
	//file.type;
	return true;
};

/**
 * 파일 삭제 요청
 * @param {Number} fileNo
 */
export const requestDeleteFile = async (fileNo) => {
	try {
		const d = { fileNoList: [fileNo] };
		const result = await deleteFile(d);
		if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
			return result.data;
		} else {
			window.linmeLog.log(result);
			return false;
		}
	} catch (error) {
		window.linmeLog.log(error);
		return false;
	}
};

/**
 * 파일 목록 삭제 요청
 * @param {Number} fileNo
 */
export const requestDeleteFileList = async (fileNoList) => {
	try {
		const d = { fileNoList };
		const result = await deleteFile(d);
		if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
			return result.data;
		} else {
			window.linmeLog.log(result);
			return false;
		}
	} catch (error) {
		window.linmeLog.log(error);
		return false;
	}
};

/**
 * 파일 업로드 요청
 * @param {String} type
 * @param {Array} files
 */
export const requestUploadFile = async (type, files) => {
	try {
		const d = {
			files: files,
			type: type,
		};
		const result = await uploadFile(d);
		if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
			return result.data;
		} else {
			window.linmeLog.log(result);
			return false;
		}
	} catch (error) {
		window.linmeLog.log(error);
		return false;
	}
};

// ----------------------------------------------------------------------------------------------------;
// SETTER;
// ----------------------------------------------------------------------------------------------------;

/**
 * 미리보기 설정
 * @param {Object} file
 * @param {Object} refImage//img tag ref;
 */
export const setPreview = async (file, refImage) => {
	const reader = new FileReader();
	reader.onload = function (event) {
		refImage.current.setAttribute("src", event.target.result);
	};
	reader.readAsDataURL(file);
};
