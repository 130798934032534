/**
 * 모달 - Alert ( 공통 사용 )
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

const ModalCommonAlert = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="commonAlert">
			{props.title ? (
				<>
					<div className="alertTitle">{props.title}</div>
					{props.html ? (
						<div className="alertContent" dangerouslySetInnerHTML={{ __html: props.html }} />
					) : (
						<div className="alertContent">{props.text}</div>
					)}
				</>
			) : (
				<>
					{props.html ? (
						<div className="commonContent" dangerouslySetInnerHTML={{ __html: props.html }} />
					) : (
						<div className="commonContent">{props.text}</div>
					)}
				</>
			)}
		</div>
	);
};
export default ModalCommonAlert;
