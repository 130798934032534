import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import Image from "src/pages/component/common/image";

import { BANNER_LANDING_TARGET } from "src/data";

const BannerSlideItem = React.memo(({ banner, className, ...rest }) => {
	const hasLink = !!banner?.landing?.value;

	const imageComponent = (
		<Image
			className={clsx([className])}
			src={banner.image.webPath}
			alt={banner.title}
			draggable={false}
		/>
	);

	const commonProps = {
		className: clsx("slide", { noLink: !hasLink }),
		...rest,
	};

	if (hasLink) {
		return (
			<Link
				{...commonProps}
				to={banner?.landing?.value ?? ""}
				target={BANNER_LANDING_TARGET[banner?.pageType] ?? BANNER_LANDING_TARGET.NEW_SELF}
			>
				{imageComponent}
			</Link>
		);
	}

	return <div {...commonProps}>{imageComponent}</div>;
});

BannerSlideItem.displayName = "BannerSlideItem";

export default BannerSlideItem;
