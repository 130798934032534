/**
 * 결제
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AppContext } from "src/App";
import useAuth from "src/hooks/useAuth";
import useModalStack from "src/hooks/useModalStack";
import {
	setSpinner,
	removeSpinner,
	setInnerSpinner,
	removeInnerSpinner,
} from "src/utils/linmeDisplay";
import {
	checkAvailableVirtualNumber,
	changeOrder,
	getCouponListForOrder,
} from "src/api/order/order";
import { checkPaymentCompleted, orderPayment, orderPaymentResult } from "src/api/order/payment";
import {
	API_RESULT_STATUS,
	COLOR,
	DELIVERY_MEMO_DIRECT_INPUT,
	DELIVERY_MEMO_LIST,
	PAYMENT_METHOD_TYPE,
	PAYMENT_METHOD_VISIBLE_LIST,
	PAYMENT_RESULT_STATUS,
	VERIFY_ENV,
} from "src/data/constEnum";
// import { getIndexFromArrayByKeyAndValue } from "src/utils/arrayCollection";
import {
	checkPaymentProcessing,
	handlerClickSetRewardAll,
	handlerClickCancelRewardAll,
	setCheckOrderNo,
	validationReward,
} from "src/utils/linmePayment";

import { isMobile } from "react-device-detect";

import CartProgressStage from "src/pages/component/cart/cartProgressStage";
import MyPageAddressBook from "src/pages/myPage/addressBook";
import VirtualNumber from "src/pages/payment/modal/virtualNumber";
import SelectCoupon from "src/pages/payment/modal/selectCoupon";
import DiscountDetail from "src/pages/cart/layer/discountDetail";
import TermsElectronicPaymentAgencyService from "src/pages/terms/termsElectronicPaymentAgencyService";
import TermsPersonalInformationToThirdParties from "src/pages/terms/termsPersonalInformationToThirdParties";
import ModalCommonAlert from "src/pages/common/modalAlert";
import Image from "src/pages/component/common/image";
import PaymentIframe from "src/pages/payment/modal/paymentIframe";
import PaymentProductCard from "./paymentProductCard";
import CustomSelectBox from "src/pages/component/common/customSelectBox";
import { sendNaverPurchase } from "src/utils/linmeNaverScript";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/payment/payment.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

const htmlInfo = require("src/data/htmlInfo.json");
const textInfo = require("src/data/textInfo.json");

const Payment = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const { isLogin } = useContext(AppContext);
	const auth = useAuth();
	const location = useLocation();
	const navigate = useNavigate();
	const [orderData, setOrderData] = useState({});
	const [fromPage, setFromPage] = useState(null);
	const modalStack = useModalStack();
	const [layerDiscountDetail, setLayerDiscountDetail] = useState(false);
	const [deliveryMemo, setDeliveryMemo] = useState("");
	const [addressNo, setAddressNo] = useState(-1);
	const [memberCouponInfo, setMemberCouponInfo] = useState(null);
	const [couponCount, setCouponCount] = useState(-1);
	const ref = {
		address: {
			// addressNo: useRef( null )
			address: useRef(null),
			name: useRef(null),
			nickname: useRef(null),
			phone: useRef(null),
			// , virtualNumber: useRef( null )
			// , isCheckRep: useRef( null )
			// , postcode: useRef( null )
			memo: useRef(null),
		},
		button: {
			applyCoupon: useRef(null),
			cancelCoupon: useRef(null),
			showCoupon: useRef(null),
			applyRewardAll: useRef(null),
			cancelRewardAll: useRef(null),
		},
		methodCover: useRef(null),
		checkVirtualNumber: useRef(null),
		coupon: useRef(null),
		reward: useRef(null),
		terms: {
			all: useRef(null), //모두 동의;
			electronicPaymentAgencyService: useRef(null), //전자결제대행 서비스 이용약관 동의;
			personalInformation: useRef(null), //개인정보 수집·이용 및 처리 동의;
		},
	};
	const modalId = {
		payment: "modalPayment",
	};

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 리워드 Blur
	 * @param {Event} e
	 */
	const handlerBlurReward = async (e) => {
		if (orderData && orderData.availableReward) {
			const resultValidation = await validationReward(
				orderData.price.finalPrice,
				orderData.price.usedReward,
				orderData.availableReward,
				ref.reward
			);
			if (resultValidation.result === false) {
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: resultValidation.text,
				});
				return false;
			} else {
				await setRewardAmount();
			}
		}
	};

	/**
	 * 모두 동의 클릭 시
	 * @param {Event} e
	 */
	const handlerChangeAgencyServiceTermsAll = (e) => {
		const ct = e.currentTarget;
		const checked = ct.checked;

		ref.terms.electronicPaymentAgencyService.current.checked = checked;
		ref.terms.personalInformation.current.checked = checked;
	};

	/**
	 * 전자결제대행 서비스 이용약관 동의 / 개인정보 수집·이용 및 처리 동의 클릭 시
	 * @param {Event} e
	 */
	const handlerChangeTerms = (e) => {
		if (
			ref.terms.electronicPaymentAgencyService.current.checked &&
			ref.terms.personalInformation.current.checked
		) {
			ref.terms.all.current.checked = true;
		} else {
			ref.terms.all.current.checked = false;
		}
	};

	/**
	 * 변경할 주소 선택 시
	 * @param {String} addressType
	 * @param {Number} changeAddressNo
	 * @param {Object} data
	 */
	const handlerChangeAddress = async (addressType, changeAddressNo, data) => {
		await modalStack.removeModal("modalChangeAddress");
		updateAddressMemo(data.memo);

		ref.address.nickname.current.innerText = data.nickname;
		ref.address.name.current.innerText = data.name;
		ref.address.phone.current.innerText = data.phone;
		ref.address.address.current.innerText = data.address1 + " " + data.address2;
		setAddressNo(changeAddressNo);

		// 배송지 변경 시 쿠폰, 리워드 정보 초기화
		const result = await requestChangeOrder(changeAddressNo, -1, 0);
		if (result) {
			ref.coupon.current.value = "";
			ref.button.showCoupon.current.classList.add("on");
			ref.button.cancelCoupon.current.classList.remove("on");
			ref.reward.current.value = 0;
			ref.button.applyRewardAll?.current?.classList?.add("on");
			ref.button.cancelRewardAll?.current?.classList?.remove("on");
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.deliveryAddressChanged,
			});
		}
	};

	/**
	 * 리워드 입력 시
	 * @param {Event} e
	 */
	const handlerChangeReward = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const resultValidation = await validationReward(
			orderData.price.finalPrice,
			orderData.price.usedReward,
			orderData.availableReward,
			ref.reward
		);
		if (resultValidation.result === false) {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: resultValidation.text,
			});
			return false;
		} else {
			await setRewardAmount();
		}
	};

	/**
	 * 안심번호 서비스 가능 여부 확인
	 * @param {Event} e
	 */
	const handlerChangeUseVirtualNumber = async (e) => {
		const ct = e.currentTarget;
		if (ct.checked) {
			//안심번호 서비스 가능 여부 확인;
			await requestCheckAvailableVirtualNumber();
		}
	};

	/**
	 * 안심번호 사용하지 못할 경우 출력 컨펌 확인 클릭 시
	 * @param {Event} e
	 */
	const handlerClickModalNotAvailableVirtualNumber = (e) => {
		ref.checkVirtualNumber.current.checked = false;
		ref.checkVirtualNumber.current.disabled = true;
		modalStack.removeModal("modalCommonConfirm");
	};

	/**
	 * 안심번호 사용하지 못할 경우 출력 컨펌 닫기 클릭 시
	 * @param {Event} e
	 */
	const handlerClickModalNotAvailableVirtualNumberClose = (e) => {
		modalStack.removeModal("modalCommonConfirm");
	};

	/**
	 * 쿠폰 취소 클릭 시
	 * @param {Event} e
	 */
	const handleClickCancelCoupon = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const result = await requestChangeOrder(null, -1, null);
		if (result) {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.memberCouponCancel,
			});
			setMemberCouponInfo(null);
			ref.coupon.current.value = "";
			ref.button.showCoupon.current.classList.add("on");
			ref.button.cancelCoupon.current.classList.remove("on");
		} else {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.memberCouponCancelFailed,
			});
			ref.button.showCoupon.current.classList.remove("on");
			ref.button.cancelCoupon.current.classList.add("on");
		}
	};

	/**
	 * 주소지 변경 클릭 시
	 * @param {Event} e
	 */
	const handlerClickChangeAddress = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.addModal({
			id: "modalChangeAddress",
			type: "alert",
			component: <MyPageAddressBook />,
			button: {
				iconClose: true,
			},
			isModal: true,
			selectedAddressNo: addressNo,
			afterSelected: handlerChangeAddress,
			requestChangeOrder: requestChangeOrder,
		});
	};

	/**
	 * 결제하기 클릭 시
	 * @param {Event} e
	 */
	const handlerClickPayment = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const resultValidation = await validation();
		if (resultValidation) {
			await setInnerSpinner();
			await requestOrderPayment();
			await removeInnerSpinner();
		}
	};

	/**
	 * 리워드 전액 적용
	 * @param {Event} e
	 */
	const handlerClickRewardApplyAll = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const resultValidation = await handlerClickSetRewardAll(
			e,
			orderData.price.finalPrice,
			orderData.price.usedReward,
			orderData.availableReward,
			ref.reward,
			ref.button.applyRewardAll,
			ref.button.cancelRewardAll
		);
		if (resultValidation.result === false) {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: resultValidation.text,
			});
			return false;
		} else {
			await setRewardAmount();
		}
	};

	/**
	 * 리워드 전액 취소
	 * @param {Event} e
	 */
	const handlerClickRewardCancelAll = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const resultValidation = await handlerClickCancelRewardAll(
			e,
			orderData.price.finalPrice,
			orderData.availableReward,
			ref.reward,
			ref.button.applyRewardAll,
			ref.button.cancelRewardAll
		);
		if (resultValidation.result === false) {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: resultValidation.text,
			});
			return false;
		} else {
			await setRewardAmount();
		}
	};

	/**
	 * 리워드 키 업
	 * @param {Event} e
	 */
	const handlerKeyUpReward = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		let key = e.key || e.keyCode;

		if (key === "Enter" || key === 13) {
			const resultValidation = await validationReward(
				orderData.price.finalPrice,
				orderData.price.usedReward,
				orderData.availableReward,
				ref.reward
			);
			if (resultValidation.result === false) {
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: resultValidation.text,
				});
				return false;
			} else {
				await setRewardAmount();
			}
		}
	};

	/**
	 * 할인 상세보기 toggle
	 * @param {Event} e
	 */
	const handleClickDiscountDetail = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		setLayerDiscountDetail(!layerDiscountDetail);
	};

	/**
	 * 전자결제대행 서비스 이용약관 보기
	 * @param {Event} e
	 */
	const handlerClickOpenModalTermsElectronicPaymentAgencyService = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.addModal({
			id: "modalTermsElectronicPaymentAgencyService",
			type: "alert",
			component: <TermsElectronicPaymentAgencyService />,
		});
	};

	/**
	 * 개인정보 제3자 이용 동의 약관 보기
	 * @param {Event} e
	 */
	const handlerClickOpenModalTermsPersonalInformationToThirdParties = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.addModal({
			id: "modalTermsPersonalInformationToThirdParties",
			type: "alert",
			component: <TermsPersonalInformationToThirdParties />,
		});
	};

	/**
	 * 안심번호 서비스 안내 보기
	 * @param {Event} e
	 */
	const handlerClickOpenModalVirtualNumber = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.addModal({
			id: "modalVirtualNumber",
			type: "alert",
			component: <VirtualNumber />,
		});
	};

	/**
	 * 쿠폰 목록 보기
	 * @param {Event} e
	 */
	const handlerClickOpenModalCouponList = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.addModal({
			id: "modalCouponList",
			type: "alert",
			component: <SelectCoupon />,
			button: {
				ok: {
					text: textInfo.button.select2,
					fn: applySelectedCoupon,
				},
			},
			orderNo: orderData.orderNo,
		});
	};

	// /**
	//  * 타이머 결제창이 닫혔는지 확인. 닫히지 않은 경우 다시 타이머 설정
	//  */
	// const handlerTimer = async () => {
	// 	await window.clearTimeout( window.processTimerId );
	// 	if( !window.paymentPopup.closed ) {
	// 		await checkPaymentProcessing();
	// 	} else {
	// 		await requestCheckPaymentCompleted();
	// 	}
	// }

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	const changeRewardValue = (value) => {
		const reward = value < 0 ? 0 : value;
		ref.reward.current.value = reward?.toLocaleString();
		orderData.price.usedReward = reward;

		return reward;
	};

	/**
	 * 쿠폰 사용 시 리워드 재조정
	 * @param {Object} coupon
	 */
	const changeRewardAfterUsedCoupon = async (coupon) => {
		const { totalProductPrice: originalPrice, totalDiscountPrice: discountThreshold } =
			orderData.price;
		const { couponType, couponValue, maxDiscount } = coupon;
		const isPercentageCoupon = couponType === "PERCENT";
		const calculatedDiscount = isPercentageCoupon
			? originalPrice * (couponValue / 100)
			: couponValue;

		let finalPriceAfterDiscount;

		if (Number(orderData.price.usedReward) === 0) {
			return changeRewardValue(0);
		}

		// 쿠폰 가격 + 현재 리워드 금액이 최종 금액보다 클 경우 리워드 금액은 최종가격 - 쿠폰 가격
		if (calculatedDiscount + orderData.price.usedReward > originalPrice) {
			finalPriceAfterDiscount = originalPrice - calculatedDiscount;
			return changeRewardValue(finalPriceAfterDiscount);
		}
		//
		if (originalPrice <= discountThreshold) {
			// 무제한 분기 처리
			if (maxDiscount !== -1) {
				const effectiveDiscount =
					calculatedDiscount > maxDiscount ? maxDiscount : calculatedDiscount;
				finalPriceAfterDiscount = discountThreshold - effectiveDiscount;
			} else {
				finalPriceAfterDiscount = discountThreshold - calculatedDiscount;
			}

			return changeRewardValue(finalPriceAfterDiscount);
		}
	};
	/**
	 * 쿠폰 목록 모달에서 선택한 쿠폰 적용
	 * @param {Object} o
	 */
	const applySelectedCoupon = async (coupon) => {
		if (coupon && coupon.name && coupon.hasOwnProperty("memberCouponNo")) {
			ref.coupon.current.value = coupon.name;
			setMemberCouponInfo(coupon);
			await modalStack.removeModal("modalCouponList"); //쿠폰 모달 닫기;

			// 쿠폰 사용 시 리워드 재조정
			const calcReward = await changeRewardAfterUsedCoupon(coupon);

			const result = await requestChangeOrder(null, coupon.memberCouponNo, calcReward);
			if (result) {
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.memberCouponApplied,
				});
				ref.button.showCoupon.current.classList.remove("on");
				ref.button.cancelCoupon.current.classList.add("on");
			} else {
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.memberCouponFailed,
				});
				ref.button.showCoupon.current.classList.add("on");
				ref.button.cancelCoupon.current.classList.remove("on");
			}
		}
	};

	// /**
	//  * 결제창이 닫혔는지 확인하는 타이머 설정
	//  */
	// const checkPaymentProcessing = async () => {
	// 	window.processTimerId = window.setTimeout( handlerTimer, 1000 );
	// }

	/**
	 * 안심번호 사용 여부 확인 요청
	 * @returns
	 */
	const requestCheckAvailableVirtualNumber = async () => {
		try {
			const result = await checkAvailableVirtualNumber();
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (!result.data.isCheckAvailable) {
					await modalStack.addModal({
						id: "modalCommonConfirm",
						type: "confirm",
						component: <ModalCommonAlert />,
						html: htmlInfo.order.cannotUseVirtualNumber,
						button: {
							confirm: {
								text: textInfo.button.confirm,
								style: { width: "fit-content", padding: "3px 10px" },
								fn: handlerClickModalNotAvailableVirtualNumber,
							},
							cancel: {
								text: textInfo.button.cancel,
								style: { width: "fit-content", padding: "3px 10px", border: "unset" },
								fn: handlerClickModalNotAvailableVirtualNumberClose,
							},
						},
					});
				}
				return result.data;
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (error) {
			window.linmeLog.log(error);
			return false;
		}
	};

	/**
	 * 해당 주문에 적용 가능한 쿠폰 개수 확인
	 * @returns
	 */
	const requestGetAvailableCouponList = async (orderNo) => {
		try {
			const d = {
				offset: 1,
				page: 1,
			};
			const result = await getCouponListForOrder(orderNo, d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (result.data.content && result.data.content.length > 0) {
					setCouponCount(result.data.totalCount);
				}
				return result.data;
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (error) {
			window.linmeLog.log(error);
			return false;
		}
	};

	/**
	 * 주문 정보 변경 요청
	 * @param {Number} addressNo
	 * @param {Number} couponNo
	 * @param {Number} reward
	 * @returns
	 */
	const requestChangeOrder = async (addressNo, couponNo, reward) => {
		const d = {
			memberAddressNo: addressNo ? addressNo : orderData.address.addressNo,
			memberCouponNo: couponNo ? couponNo : orderData.coupon ? orderData.coupon.memberCouponNo : -1,
			usedReward:
				reward != null ? reward : orderData.price?.usedReward ? orderData.price?.usedReward : 0,
		};

		try {
			await setInnerSpinner();
			const result = await changeOrder(orderData.orderNo, d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (result.data) {
					setOrderData(result.data);
					if (result.data.price.finalPrice === 0) {
						ref.methodCover.current.style.display = "none";
					} else {
						ref.methodCover.current.style.display = "block";
					}
				}
				return result.data;
			} else {
				window.linmeLog.log(result);
				if (result.message === textInfo.APIError.orderTimeExpires) {
					await modalStack.addModal({
						id: "modalCommonAlert",
						type: "alert",
						component: <ModalCommonAlert />,
						html: htmlInfo.alert.orderTimeExpires,
					});
					await navigate(-1);
				}
				return false;
			}
		} catch (error) {
			window.linmeLog.log(error);
			if (error?.response?.data?.message === textInfo.APIError.orderTimeExpires) {
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					html: htmlInfo.alert.orderTimeExpires,
				});
				await navigate(-1);
			} else {
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: error?.response?.data?.message || textInfo.alert.error,
				});
			}
			return false;
		} finally {
			await removeInnerSpinner();
		}
	};

	/**
	 * 결제 실패 후 실행
	 * @param {String} messageType
	 * @param {String} message
	 */
	// const afterPaymentFail = async ( messageType, message ) => {
	// 	await removeInnerSpinner();
	// 	if ( message === textInfo.APIError.orderTimeExpires ) {//결제 가능 시간 초과 ( 주문 만료 );
	// 		await modalStack.addModal({
	// 			id: "modalCommonAlert"
	// 			, type: "alert"
	// 			, component: <ModalCommonAlert />
	// 			, html: htmlInfo.alert.orderTimeExpires
	// 		});
	// 		await navigate( -1 );
	// 	} else if ( message === htmlInfo.payment.pleaseFinishPayment ) {//결제 미완;
	// 		await modalStack.addModal({
	// 			id: "modalCommonAlert"
	// 			, type: "alert"
	// 			, component: <ModalCommonAlert />
	// 			, html: htmlInfo.payment.pleaseFinishPayment
	// 		});
	// 	} else {
	// 		/*/
	// 		const o = {
	// 			id: "modalCommonAlert"
	// 			, type: "alert"
	// 			, component: <ModalCommonAlert />
	// 		}
	// 		if( messageType === "text" ) o.text = message;
	// 		else o.html = message;
	// 		await modalStack.addModal( o );
	// 		/*/
	// 		await navigate( pathList.payment.failure, { state: { orderNo: orderData.orderNo, message: message, from: fromPage } });
	// 		//*/
	// 	}
	// };

	/**
	 * 결제 완료 이후 실행
	 * @param {String} messageType
	 * @param {Object} data
	 */
	// const afterPaymentSuccess = async ( messageType, data ) => {
	// 	await removeInnerSpinner();
	// 	await navigate( pathList.payment.complete, { state: { orderNo: orderData.orderNo, data: data } });
	// };

	// /**
	//  * 결제 완료 여부 확인
	//  * @returns
	//  */
	// const requestCheckPaymentCompleted = async () => {
	// 	const result = await checkPaymentCompleted( orderData.orderNo );
	// 	if( result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS ) {
	// 		if( result.data.result === API_RESULT_STATUS.SUCCESS ) {
	// 			await removeInnerSpinner();
	// 			await navigate( pathList.payment.complete, { state: { orderNo: orderData.orderNo }} );
	// 		}
	// 	} else {
	// 		window.linmeLog.log( result );
	// 		await removeInnerSpinner();
	// 		if( result.message === textInfo.APIError.orderTimeExpires ) {
	// 			await modalStack.addModal({
	// 				id: "modalCommonAlert"
	// 				, type: "alert"
	// 				, component: <ModalCommonAlert />
	// 				, html: htmlInfo.alert.orderTimeExpires
	// 			});
	// 		}
	// 		return false;
	// 	}
	// }

	/**
	 * 결제 요청
	 * @returns
	 */ const requestOrderPayment = async () => {
		try {
			const method = window.document.querySelector(".method:checked");
			const d = {
				env: isMobile ? VERIFY_ENV.WEB.MOBILE : VERIFY_ENV.WEB.PC,
				isCheckVirtualNumber: ref.checkVirtualNumber.current.checked,
				memberAddressNo: orderData.address.addressNo,
				deliveryMemo:
					deliveryMemo === DELIVERY_MEMO_DIRECT_INPUT
						? ref?.address?.memo?.current?.value || ""
						: deliveryMemo,
				memberCouponNo: orderData.coupon
					? orderData.coupon.memberCouponNo
					: memberCouponInfo && memberCouponInfo.memberCouponNo
					? memberCouponInfo.memberCouponNo
					: -1,
				paymentMethod: orderData.price.finalPrice === 0 ? PAYMENT_METHOD_TYPE.REWARD : method.value,
				usedReward: parseInt(ref.reward.current.value.replace(/[^0-9]/g, "")),
			};
			const result = await orderPayment(orderData.orderNo, d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (result.data.url) {
					//*/
					await modalStack.addModal({
						id: modalId.payment,
						type: "none",
						component: <PaymentIframe {...result.data} />,
						setDataAfterPayment: setDataAfterPayment,
					});
					/*/
					const paymentPopup = window.open( pathList.payment.process + "?url=" + result.data.url, "paymentPopup", "width=820, height=620" );
					if( !paymentPopup ) {
						await removeInnerSpinner()
						await modalStack.addModal({
							id: "modalCommonAlert"
							, type: "alert"
							, component: <ModalCommonAlert />
							, text: textInfo.alert.pleaseDisablePopupBlocker
						});

						return false;
					}
					if( paymentPopup.window ) {
						// paymentPopup.window.onbeforeunload = afterPayment;
						paymentPopup.window.onmessage = afterPayment;
						const form = window.document.createElement( "form" );
						const elUrl = window.document.createElement( "hidden" );
						elUrl.value = result.data.url;
						form.appendChild( elUrl );
						form.target = "paymentPopup";
						form.action = pathList.payment.process;
						// form.submit();

						window.paymentPopup = paymentPopup;
						await setCheckOrderNo( orderData.orderNo );
						await checkPaymentProcessing( afterPaymentSuccess, afterPaymentFail );//결제창이 닫혔는지 확인하는 타이머 설정;
					}
					//*/
				} else {
					//전액 리워드 결제;
					await requestPaymentResult();
					/*/
					try {
						resultCheck = await requestCheckCompleted( orderData.orderNo );
						await removeInnerSpinner();
						if ( resultCheck ) {
							await navigate( pathList.payment.complete, { state: { orderNo: orderData.orderNo } });
						} else {
							await modalStack.addModal({
								id: "modalCommonAlert"
								, type: "alert"
								, component: <ModalCommonAlert />
								, html: htmlInfo.error.payment
							});
						}
					} catch ( err ) {
						if ( err.response.data && err.response.data.message && err.response.data.message === textInfo.alert.pleaseFinishPayment ) {
							await modalStack.addModal({
								id: "modalCommonAlert"
								, type: "alert"
								, component: <ModalCommonAlert />
								, html: htmlInfo.alert.pleaseFinishPayment
							});
						}
					}
					//*/
				}
				return result.data;
			} else {
				window.linmeLog.log(result);
				await removeInnerSpinner();
				if (result.message === textInfo.APIError.orderTimeExpires) {
					await modalStack.addModal({
						id: "modalCommonAlert",
						type: "alert",
						component: <ModalCommonAlert />,
						html: htmlInfo.alert.orderTimeExpires,
					});
					await navigate(-1);
				}
				return false;
			}
		} catch (err) {
			window.linmeLog.error(err);
			await removeInnerSpinner();
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: err?.response?.data?.message || textInfo.alert.paymentError,
			});
			return false;
		}
	};

	/**
	 * 리워드 전액 결제 시 후처리 ( 결제 완료 처리 )
	 * @param {Number} orderNo
	 * @returns
	 */
	const requestCheckCompleted = async (orderNo) => {
		try {
			// const result = await checkPaymentCompleted( orderNo );
			const result = await requestPaymentResult();
		} catch (err) {
			window.linmeLog.error(err);
			return false;
		}
	};

	/**
	 * (결제 성공 후) 결과 조회 및 리워드 확정 요청
	 * @returns
	 */
	const requestPaymentResult = async () => {
		try {
			const result = await orderPaymentResult(orderData.orderNo);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (result.data) {
					sendNaverPurchase(result.data); // 네이버 전환 스크립트 (구매 완료);
					await auth.checkStatistics();
					await removeInnerSpinner();
					//페이지 이동;
					await navigate(pathList.payment.complete, { state: { orderNo: orderData.orderNo } });
				} else {
					await removeInnerSpinner();
					await modalStack.addModal({
						id: "modalCommonAlert",
						type: "alert",
						component: <ModalCommonAlert />,
						html: htmlInfo.error.payment,
					});
				}
				return true;
			} else {
				await removeInnerSpinner();
				window.linmeLog.log(result);
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					html: htmlInfo.error.payment,
				});
			}
		} catch (err) {
			window.linmeLog.error(err);
			await removeInnerSpinner();
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.error.payment,
			});
		}
	};

	/**
	 * 결제 후 message 로 받은 데이터로 후처리 ( 성공 시 결과 조회 및 리워드 확정/실패 시 실패 페이지로 이동)
	 * @param {Object} d
	 *
	 */
	const setDataAfterPayment = async (d) => {
		if (d.result === PAYMENT_RESULT_STATUS.SUCCESS) {
			await modalStack.removeModal(modalId.payment);
			await requestPaymentResult();
		} else if (d.result === PAYMENT_RESULT_STATUS.FAIL) {
			await modalStack.removeModal(modalId.payment);
			await removeInnerSpinner();
			navigate(pathList.payment.failure, {
				state: {
					message: d.message,
					from: fromPage,
					product: orderData.orderItems,
				},
			});
		}
	};

	/**
	 * 입력된 리워드 적용
	 * @returns {Number}
	 */
	const setRewardAmount = async () => {
		const reward = ref.reward.current.value.replace(/[^0-9]/g, ""); //숫자만 적용;
		const result = await requestChangeOrder(null, null, parseInt(reward));
		if (orderData?.price?.usedReward === parseInt(reward)) return;

		if (result) {
			ref.reward.current.value = result?.price?.usedReward?.toLocaleString();

			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.memberRewardApplied,
			});
		}
	};

	/**
	 * 배송요청사항 선택 후 적용
	 * @param {Object} o
	 */

	const setSelectedDeliveryMemo = async (o) => {
		setDeliveryMemo(o.value);
	};

	/**
	 * validation 결제
	 * @returns
	 */
	const validation = async () => {
		//결제수단 선택;
		const method = window.document.querySelector(".method:checked");
		if (orderData.price.finalPrice !== 0) {
			if (!method) {
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.pleaseSelectPaymentMethod,
				});
				return false;
			}
		}
		//이용약관 동의;
		if (!ref.terms.all.current.checked) {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.pleaseAgreeTermsForPayment,
			});
			return false;
		}
		return true;
	};
	const updateAddressMemo = (memo) => {
		const addressMemoList = DELIVERY_MEMO_LIST.map((memo) => memo.value);
		const isHasMemo = addressMemoList.includes(memo);
		const isDirectMemo = !isHasMemo && memo !== "";

		if (isHasMemo) {
			setDeliveryMemo(memo);
		} else if (isDirectMemo) {
			setDeliveryMemo(DELIVERY_MEMO_DIRECT_INPUT);
			ref.address.memo.current.value = memo;
		} else {
			setDeliveryMemo("");
		}
	};
	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		const setData = async () => {
			await setSpinner();

			if (location.state === null || !location.state?.data) {
				await removeSpinner();
				navigate(pathList.main);
				return false;
			}
			const d = location.state?.data;
			const from = location.state?.from;
			setFromPage(from);

			updateAddressMemo(d?.address?.memo);

			setAddressNo(d.address.addressNo);
			await requestGetAvailableCouponList(d.orderNo);
			setOrderData(d);

			await removeSpinner();
		};
		setData();
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="container underTopMenu paymentContainer">
			<div className="contentsBackground">
				<div className="contents">
					<CartProgressStage {...{ on: "step2" }} />
				</div>
				<div className="contents">
					<div className="paymentData">
						<div className="title">{textInfo.payment.title}</div>
						<div className="dataInfo deliveryInfo">
							<div className="title">{textInfo.payment.deliveryInfo.title}</div>
							<div className="changeDeliveryInfo" onClick={(e) => handlerClickChangeAddress(e)}>
								{textInfo.button.change}
							</div>
							<ul className="addressBookInfoList">
								<li className="addressBookInfoItem">
									<div className="label required">{textInfo.payment.deliveryInfo.nickname}</div>
									<div className="data addressNickname" ref={ref.address.nickname}>
										{orderData && orderData.address ? orderData.address.nickname : ""}
									</div>
								</li>
								<li className="addressBookInfoItem">
									<div className="label required">{textInfo.payment.deliveryInfo.name}</div>
									<div className="data addressName" ref={ref.address.name}>
										{orderData && orderData.address ? orderData.address.name : ""}
									</div>
								</li>
								<li className="addressBookInfoItem">
									<div className="label required">{textInfo.payment.deliveryInfo.phone}</div>
									<div className="data phone">
										<span ref={ref.address.phone}>
											{orderData && orderData.address ? orderData.address.phone : ""}
										</span>
										<div
											className="virtualNumberService"
											onClick={(e) => handlerClickOpenModalVirtualNumber(e)}
										>
											<span>{textInfo.payment.deliveryInfo.safeNumberService}</span>
										</div>
										<div className="checkUseVirtualNumberCover">
											<input
												className="useVirtualNumber"
												name="useVirtualNumber"
												id="useVirtualNumber"
												type="checkbox"
												defaultChecked={false}
												onChange={(e) => {
													handlerChangeUseVirtualNumber(e);
												}}
												ref={ref.checkVirtualNumber}
											/>
											<label htmlFor="useVirtualNumber">&nbsp;</label>
										</div>
									</div>
								</li>
								<li className="addressBookInfoItem">
									<div className="label required">{textInfo.payment.deliveryInfo.address}</div>
									<div className="data" ref={ref.address.address}>
										{orderData && orderData.address
											? orderData.address.address1 + " " + orderData.address.address2
											: ""}
									</div>
								</li>
								<li className="addressBookInfoItem">
									<div className="label">{textInfo.payment.deliveryInfo.memo}</div>
									<div className="data addressMemo">
										<CustomSelectBox
											options={DELIVERY_MEMO_LIST}
											value={deliveryMemo}
											placeholder="배송시 요청사항을 선택해주세요."
											onChange={(item) => setSelectedDeliveryMemo(item)}
										/>
										<input
											type={deliveryMemo === DELIVERY_MEMO_DIRECT_INPUT ? "text" : "hidden"}
											className="memo"
											name="directInput"
											ref={ref.address.memo}
											placeholder={textInfo.myPage.myAddressBook.add.memoList.directInput}
										/>
									</div>
								</li>
							</ul>
						</div>
						<div className="dataInfo checkOrderProduct">
							<div className="title">{textInfo.payment.checkOrderProduct.title}</div>
							{/* 주문 상품 정보 */}
							<PaymentProductCard orderData={orderData} />
						</div>
						{/* 쿠폰 할인 적용 */}
						<div className="dataInfo couponInfo">
							<div className="title">{textInfo.payment.coupon.title}</div>
							<ul>
								{/* <li>
									<div className="label">{textInfo.payment.coupon.levelDiscount}</div>
									<div className="data">
										<input type="text" className="discountLevel" value="00,000" readOnly /><span className="currencyUnit">{textInfo.common.currencyUnit}</span>
									</div>
								</li> */}
								<li>
									<div className="label">{textInfo.payment.coupon.coupon}</div>
									<div className="data">
										<input
											type="text"
											className="selectedCouponName"
											readOnly
											placeholder={
												couponCount > 0
													? textInfo.payment.coupon.placeholder_selectCoupon
													: textInfo.payment.coupon.placeholder_noData
											}
											ref={ref.coupon}
										/>
										<div className="button applyCoupon" ref={ref.button.applyCoupon}>
											{textInfo.button.applyCoupon}
										</div>
										<div
											className="button cancelCoupon"
											ref={ref.button.cancelCoupon}
											onClick={(e) => handleClickCancelCoupon(e)}
										>
											{textInfo.button.cancelCoupon}
										</div>
										{couponCount > 0 ? (
											<div
												className="button showCouponList on"
												ref={ref.button.showCoupon}
												onClick={(e) => handlerClickOpenModalCouponList(e)}
											>
												{textInfo.button.listCoupon}
											</div>
										) : (
											<></>
										)}
									</div>
								</li>
								<li>
									<div className="label">{textInfo.payment.reward.title}</div>
									<div className="data reward">
										<input
											type="text"
											className={
												"discountReward " +
												(orderData && orderData.availableReward ? "" : "readOnly")
											}
											defaultValue="0"
											ref={ref.reward}
											readOnly={orderData && orderData.availableReward ? false : true}
											// onChange={(e)=>handlerChangeReward(e)}
											onKeyUp={(e) => handlerKeyUpReward(e)}
											onBlur={(e) => handlerBlurReward(e)}
										/>
										<span className="rewardUnit">{textInfo.common.rewardUnit}</span>
										<span className="rewardDivider">/</span>
										<span className="rewardAll">
											{orderData && orderData.availableReward
												? orderData.availableReward.toLocaleString()
												: 0}
											<span className="rewardUnit">{textInfo.common.rewardUnit}</span>
										</span>
										<div className="buttonRewardCover">
											{orderData && orderData.availableReward ? (
												<div
													className="button applyRewardAll on"
													ref={ref.button.applyRewardAll}
													onClick={(e) => handlerClickRewardApplyAll(e)}
												>
													{textInfo.payment.reward.useAll}
												</div>
											) : (
												<></>
											)}
											<div
												className="button cancelRewardAll"
												ref={ref.button.cancelRewardAll}
												onClick={(e) => handlerClickRewardCancelAll(e)}
											>
												{textInfo.payment.reward.cancelAll}
											</div>
										</div>
									</div>
								</li>
								<li className="totalDiscount">
									<div>
										{orderData && orderData.price && orderData.price.totalDiscountPrice
											? orderData.price.totalDiscountPrice.toLocaleString()
											: 0}
										<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
									</div>
								</li>
							</ul>
						</div>
						<div className="dataInfo paymentMethod" ref={ref.methodCover}>
							<div className="title">{textInfo.payment.payMethod.title}</div>
							<ul className="paymentMethodList">
								{PAYMENT_METHOD_VISIBLE_LIST.map((method, index) => (
									<li className="paymentMethodItem methodBox" key={index}>
										<input
											className="method"
											name="method"
											id={"payMethod" + method.value.toLowerCase()}
											type="radio"
											value={method.value}
											defaultChecked={method.value === "CARD"}
										/>
										<label className="method" htmlFor={"payMethod" + method.value.toLowerCase()}>
											{method.label}
										</label>
									</li>
								))}
							</ul>
						</div>
					</div>
					<div className="paymentTotalContainer">
						<div className="paymentTotalInfo">
							<div className="title">{textInfo.order.totalInfo}</div>
							<ul>
								<li>
									<div className="label">{textInfo.order.selectedProductPrice}</div>
									<div className="price selectedProductPrice">
										{orderData && orderData.price
											? orderData.price.totalProductPrice.toLocaleString()
											: 0}
										<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
									</div>
								</li>
								<li>
									<div className="label">{textInfo.order.totalDeliveryPrice}</div>
									<div className="price selectedDeliveryPrice">
										<Image
											srcSet="
												/images/icon/signPlus.png 1x
												, /images/icon/signPlus@2x.png 2x
												, /images/icon/signPlus@3x.png 3x
											"
											alt={textInfo.order.totalDeliveryPrice}
										/>
										{orderData && orderData.price
											? orderData.price.deliveryPrice.toLocaleString()
											: 0}
										<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
									</div>
								</li>
								<li>
									<div className="label">
										<div
											className={"discountDetail " + (layerDiscountDetail ? "close" : "open")}
											onClick={(e) => handleClickDiscountDetail(e)}
										>
											{textInfo.order.totalDiscountPrice}
										</div>
										<DiscountDetail
											isShow={layerDiscountDetail}
											handlerToggleClose={handleClickDiscountDetail}
											// discountLevelPrice={2000}
											discountCouponPrice={
												orderData && orderData.price && orderData.price.couponDiscount
													? orderData.price.couponDiscount.toLocaleString()
													: 0
											}
											discountCouponName={
												memberCouponInfo && memberCouponInfo.name ? memberCouponInfo.name : ""
											}
											discountRewardPrice={
												orderData && orderData.price && orderData.price.usedReward
													? orderData.price.usedReward.toLocaleString()
													: 0
											}
											discountTotalPrice={
												orderData &&
												orderData.price &&
												(orderData.price.couponDiscount || orderData.price.usedReward)
													? (
															(orderData.price.couponDiscount
																? orderData.price.couponDiscount
																: 0) + (orderData.price.usedReward ? orderData.price.usedReward : 0)
													  ).toLocaleString()
													: 0
											}
										/>
									</div>
									<div className="price selectedDiscountPrice">
										<Image
											srcSet="
												/images/icon/signMinusDiscount.png 1x
												, /images/icon/signMinusDiscount@2x.png 2x
												, /images/icon/signMinusDiscount@3x.png 3x
											"
											alt="할인금액"
										/>
										{/* {orderData && orderData.price ? orderData.price.totalDiscountPrice.toLocaleString() : 0} */}
										{orderData &&
										orderData.price &&
										(orderData.price.couponDiscount || orderData.price.usedReward)
											? (
													(orderData.price.couponDiscount ? orderData.price.couponDiscount : 0) +
													(orderData.price.usedReward ? orderData.price.usedReward : 0)
											  ).toLocaleString()
											: 0}
										<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
									</div>
								</li>
							</ul>
							<div className="orderTotal">
								<div className="label">{textInfo.order.totalOrderPrice}</div>
								<div className="price orderPriceTotal">
									{orderData && orderData.price && orderData.price.finalPrice
										? orderData.price.finalPrice.toLocaleString()
										: 0}
									<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
								</div>
							</div>
							<div className="button clickable buy cover" onClick={(e) => handlerClickPayment(e)}>
								<div className="buttonInner">
									<div className="label">{textInfo.button.payment}</div>
									{/* <div className="orderProductCountCover">
										<div className="orderProductCount">1</div>
									</div> */}
								</div>
							</div>
						</div>
						<div className="paymentTermsInfo">
							<div className="question">{textInfo.payment.terms.question}</div>
							<div className="paymentAgencyServiceTermsAllCover">
								<input
									className="paymentAgencyServiceTermsAll"
									name="paymentAgencyServiceTermsAll"
									id="paymentAgencyServiceTermsAll"
									type="checkbox"
									ref={ref.terms.all}
									onChange={(e) => {
										handlerChangeAgencyServiceTermsAll(e);
									}}
								/>
								<label htmlFor="paymentAgencyServiceTermsAll">
									{textInfo.payment.terms.agreeAll}
								</label>
							</div>
							<div className="description">{textInfo.payment.terms.description}</div>
							<div className="paymentAgencyServiceTerms">
								{textInfo.payment.terms.agencyServiceTerms}
							</div>
							<div className="terms electronicPaymentAgencyServiceTermsCover">
								<input
									className="electronicPaymentAgencyServiceTerms"
									name="electronicPaymentAgencyServiceTerms"
									id="electronicPaymentAgencyServiceTerms"
									type="checkbox"
									ref={ref.terms.electronicPaymentAgencyService}
									onChange={(e) => {
										handlerChangeTerms(e);
									}}
								/>
								<label htmlFor="electronicPaymentAgencyServiceTerms">
									{textInfo.payment.terms.electronicPaymentAgencyServiceTerms}
								</label>
								<div
									className="button clickable showTerms"
									onClick={(e) => handlerClickOpenModalTermsElectronicPaymentAgencyService(e)}
								>
									{textInfo.button.showTerms}
								</div>
							</div>
							<div className="terms personalInformationTermsCover">
								<input
									className="personalInformationTerms"
									name="personalInformationTerms"
									id="personalInformationTerms"
									type="checkbox"
									ref={ref.terms.personalInformation}
									onChange={(e) => {
										handlerChangeTerms(e);
									}}
								/>
								<label htmlFor="personalInformationTerms">
									{textInfo.payment.terms.personalInformationTerms}
								</label>
								<div
									className="button clickable showTerms"
									onClick={(e) => handlerClickOpenModalTermsPersonalInformationToThirdParties(e)}
								>
									{textInfo.button.showTerms}
								</div>
							</div>
						</div>
						<div
							className="button clickable buy cover mobile"
							onClick={(e) => handlerClickPayment(e)}
						>
							{textInfo.button.payment}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Payment;
