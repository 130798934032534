import { useQuery } from "@tanstack/react-query";
import { PRODUCT_TYPE_NO } from "src/data/constEnum";
import { productListTopicSummaryTimeSale } from "../../api/product/topic";
import { API_RESULT_STATUS, queryKeys } from "src/data";

/**
 * 기획전 - 타임특가 목록 가져오기 (상품 최대 개수 5개)
 */
const getReqTopicTimeSaleList = async (mainSelectedMenuType) => {
	try {
		let d = {
			typeNo: PRODUCT_TYPE_NO[mainSelectedMenuType],
		};

		const result = await productListTopicSummaryTimeSale(d);
		if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
			return result.data;
		} else {
			window.linmeLog.log(result);
		}
	} catch (err) {
		window.linmeLog.error(err);
	}
};

const useTopicTimeSaleList = (mainSelectedMenuType) => {
	const { data } = useQuery({
		queryKey: [queryKeys.TIME_SALE_TOPIC, mainSelectedMenuType],
		queryFn: () => getReqTopicTimeSaleList(mainSelectedMenuType),
		staleTime: 1000 * 60,
	});

	return { data };
};

export default useTopicTimeSaleList;
