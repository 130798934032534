/**
 * Number Stepper
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import useModalStack from "src/hooks/useModalStack";
import { COLOR } from "src/data/constEnum";

import ModalCommonAlert from "src/pages/common/modalAlert";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const NumberStepperContainer = styled.div`
	position: relative;
	display: flex;
	width: fit-content;
	height: fit-content;
	border: 1px solid ${COLOR.GRAY07};
	&.small {
		.button {
			&.minus {
				background-image: image-set(
					url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAACAgMAAADp8S7zAAAADFBMVEVHcEyNjY2NjY2Ojo4NkxC+AAAAA3RSTlMA4HD0DUYOAAAADklEQVQI12Oo/8HAwAAABmEBeK9rwhsAAAAASUVORK5CYII=")
						1x,
					url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAADBAMAAABR+CM8AAAAElBMVEWPj49HcEyNjY2Ojo6Pj4+Ojo7GrIU8AAAABXRSTlNAANDoEJwBAkgAAAAWSURBVAjXY1ANBQFDBghtwiDAAAKCAEBMBCMxitWlAAAAAElFTkSuQmCC")
						2x,
					url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAEBAMAAAB8cQKlAAAAG1BMVEWPj49HcEyPj4+Ojo6Ojo6NjY2Pj4+MjIyOjo5nExqkAAAACHRSTlNAADCw74AgUBsBoE0AAAAcSURBVAjXY4jogAInBhiro5nBAsZsZxBigIJEAJ8tD5j07/pdAAAAAElFTkSuQmCC")
						3x
				);
				background-image: -webkit-image-set(
					url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAACAgMAAADp8S7zAAAADFBMVEVHcEyNjY2NjY2Ojo4NkxC+AAAAA3RSTlMA4HD0DUYOAAAADklEQVQI12Oo/8HAwAAABmEBeK9rwhsAAAAASUVORK5CYII=")
						1x,
					url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAADBAMAAABR+CM8AAAAElBMVEWPj49HcEyNjY2Ojo6Pj4+Ojo7GrIU8AAAABXRSTlNAANDoEJwBAkgAAAAWSURBVAjXY1ANBQFDBghtwiDAAAKCAEBMBCMxitWlAAAAAElFTkSuQmCC")
						2x,
					url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAEBAMAAAB8cQKlAAAAG1BMVEWPj49HcEyPj4+Ojo6Ojo6NjY2Pj4+MjIyOjo5nExqkAAAACHRSTlNAADCw74AgUBsBoE0AAAAcSURBVAjXY4jogAInBhiro5nBAsZsZxBigIJEAJ8tD5j07/pdAAAAAElFTkSuQmCC")
						3x
				);
			}
			&.plus {
				background-image: image-set(
					url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHBAMAAAA2fErgAAAAG1BMVEWPj4+Ojo5HcEyNjY2Pj4+Ojo6Ojo6Pj4+NjY0p7K1WAAAACXRSTlM/wABlENCwMPBBYlmYAAAAIUlEQVQI12NQKlNSYFBiBBIFpgwODIkRggYQLoRwVlIAAFZOBJCAkUjJAAAAAElFTkSuQmCC")
						1x,
					url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOBAMAAADtZjDiAAAAGFBMVEWOjo6Ojo6Ojo5HcEyNjY2Pj4+Ojo6Ojo5VIh/3AAAACHRSTlN+/8AAWBDgv5rcXEsAAAAySURBVAjXYzA2NhEwNjZmMDY2EMJHOzCwizEwODMIgoEoA5OSmqCSEjNBfQjzjYOBGABgEg/R4SlN2AAAAABJRU5ErkJggg==")
						2x,
					url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUBAMAAAB/pwA+AAAAGFBMVEWOjo5HcEyNjY2Ojo6Pj4+NjY2Ojo6Ojo7BNkhrAAAAB3RSTlO+AIDoIGCQKry54wAAADlJREFUCNdjEASCYBYQyQAi2IuoxAxgYGAwLwYSLAzm5VBQgGCWMTgpKSmZFwCJQGpZXApnOiSCSADHoRviHwteggAAAABJRU5ErkJggg==")
						3x
				);
				background-image: -webkit-image-set(
					url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHBAMAAAA2fErgAAAAG1BMVEWPj4+Ojo5HcEyNjY2Pj4+Ojo6Ojo6Pj4+NjY0p7K1WAAAACXRSTlM/wABlENCwMPBBYlmYAAAAIUlEQVQI12NQKlNSYFBiBBIFpgwODIkRggYQLoRwVlIAAFZOBJCAkUjJAAAAAElFTkSuQmCC")
						1x,
					url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOBAMAAADtZjDiAAAAGFBMVEWOjo6Ojo6Ojo5HcEyNjY2Pj4+Ojo6Ojo5VIh/3AAAACHRSTlN+/8AAWBDgv5rcXEsAAAAySURBVAjXYzA2NhEwNjZmMDY2EMJHOzCwizEwODMIgoEoA5OSmqCSEjNBfQjzjYOBGABgEg/R4SlN2AAAAABJRU5ErkJggg==")
						2x,
					url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUBAMAAAB/pwA+AAAAGFBMVEWOjo5HcEyNjY2Ojo6Pj4+NjY2Ojo6Ojo7BNkhrAAAAB3RSTlO+AIDoIGCQKry54wAAADlJREFUCNdjEASCYBYQyQAi2IuoxAxgYGAwLwYSLAzm5VBQgGCWMTgpKSmZFwCJQGpZXApnOiSCSADHoRviHwteggAAAABJRU5ErkJggg==")
						3x
				);
			}
		}
	}
`;

const ButtonCover = styled.div`
	position: relative;
	display: block;
	width: 60px;
	height: 15px;
`;

const ButtonMinus = styled.button`
	position: absolute;
	display: block;
	width: 10px;
	height: 2px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	background-image: image-set(
		url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAACAQMAAABbr9+TAAAABlBMVEWOjo6NjY12RJLUAAAAAnRSTlM/f+73i6kAAAAOSURBVAjXY6hvYKhvAAAF/wH/uRboQwAAAABJRU5ErkJggg==")
			1x,
		url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAEAQMAAAC0kY3VAAAABlBMVEVHcEyNjY1r4xUZAAAAAXRSTlMAQObYZgAAABFJREFUCNdjYACC+v8PwBgIACUuBL1OThNpAAAAAElFTkSuQmCC")
			2x,
		url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAGBAMAAAAmmzNnAAAAD1BMVEWNjY1HcEyLi4uOjo6NjY2jdMZFAAAABHRSTlN/AEA/xtC0aAAAAB1JREFUCNdjEEQFDMIMyECRQcAFGTBi8NHVo5kHAMV9CXHmD6+LAAAAAElFTkSuQmCC")
			3x
	);
	background-image: -webkit-image-set(
		url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAACAQMAAABbr9+TAAAABlBMVEWOjo6NjY12RJLUAAAAAnRSTlM/f+73i6kAAAAOSURBVAjXY6hvYKhvAAAF/wH/uRboQwAAAABJRU5ErkJggg==")
			1x,
		url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAEAQMAAAC0kY3VAAAABlBMVEVHcEyNjY1r4xUZAAAAAXRSTlMAQObYZgAAABFJREFUCNdjYACC+v8PwBgIACUuBL1OThNpAAAAAElFTkSuQmCC")
			2x,
		url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAGBAMAAAAmmzNnAAAAD1BMVEWNjY1HcEyLi4uOjo6NjY2jdMZFAAAABHRSTlN/AEA/xtC0aAAAAB1JREFUCNdjEEQFDMIMyECRQcAFGTBi8NHVo5kHAMV9CXHmD6+LAAAAAElFTkSuQmCC")
			3x
	);
`;

const ButtonPlus = styled.button`
	position: absolute;
	display: block;
	width: 10px;
	height: 10px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	background-image: image-set(
		url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKAgMAAADwXCcuAAAADFBMVEWNjY2Li4tHcEyNjY3Ky7ZxAAAABHRSTlOAQADAtTSf0QAAABlJREFUCNdjWBW1gGEVFwI7fBAAY2QxoBoAE+cNwaeLiFMAAAAASUVORK5CYII=")
			1x,
		url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUAgMAAADw5/WeAAAACVBMVEWNjY1HcEyNjY37Ark3AAAAA3RSTlPAAPDWZKCCAAAAHElEQVQI12MIBQGG0FBHkkgHBg0GRihJql6wjQDo/h11R70kvQAAAABJRU5ErkJggg==")
			2x,
		url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeBAMAAADJHrORAAAAElBMVEVHcEyLi4uOjo6NjY2Pj4+NjY0yEEGGAAAABXRSTlMAQD96IJj2TZUAAAA3SURBVBjTY2DACliMHVD4jKECQ4TPogQCyqFGYNqBgTkUGRhg8FkEQUA41BBMOww5/2KPLzgAACfGFoQFynDIAAAAAElFTkSuQmCC")
			3x
	);
	background-image: -webkit-image-set(
		url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKAgMAAADwXCcuAAAADFBMVEWNjY2Li4tHcEyNjY3Ky7ZxAAAABHRSTlOAQADAtTSf0QAAABlJREFUCNdjWBW1gGEVFwI7fBAAY2QxoBoAE+cNwaeLiFMAAAAASUVORK5CYII=")
			1x,
		url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUAgMAAADw5/WeAAAACVBMVEWNjY1HcEyNjY37Ark3AAAAA3RSTlPAAPDWZKCCAAAAHElEQVQI12MIBQGG0FBHkkgHBg0GRihJql6wjQDo/h11R70kvQAAAABJRU5ErkJggg==")
			2x,
		url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeBAMAAADJHrORAAAAElBMVEVHcEyLi4uOjo6NjY2Pj4+NjY0yEEGGAAAABXRSTlMAQD96IJj2TZUAAAA3SURBVBjTY2DACliMHVD4jKECQ4TPogQCyqFGYNqBgTkUGRhg8FkEQUA41BBMOww5/2KPLzgAACfGFoQFynDIAAAAAElFTkSuQmCC")
			3x
	);
`;

const InputNumber = styled.input`
	width: 50px;
	height: 15px;
	border: unset;
	margin: 0;
	padding: 0;
	-moz-appearance: textfield;
	text-align: center;
	&:focus {
		outline-color: ${COLOR.LINME};
		outline-width: 1px;
	}
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
`;

const NumberStepper = (props) => {
	/**
	 * minus 1x : url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAACAQMAAABbr9+TAAAABlBMVEWOjo6NjY12RJLUAAAAAnRSTlM/f+73i6kAAAAOSURBVAjXY6hvYKhvAAAF/wH/uRboQwAAAABJRU5ErkJggg==")
	 * minus 2x : url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAEAQMAAAC0kY3VAAAABlBMVEVHcEyNjY1r4xUZAAAAAXRSTlMAQObYZgAAABFJREFUCNdjYACC+v8PwBgIACUuBL1OThNpAAAAAElFTkSuQmCC")
	 * minus 3x : url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAGBAMAAAAmmzNnAAAAD1BMVEWNjY1HcEyLi4uOjo6NjY2jdMZFAAAABHRSTlN/AEA/xtC0aAAAAB1JREFUCNdjEEQFDMIMyECRQcAFGTBi8NHVo5kHAMV9CXHmD6+LAAAAAElFTkSuQmCC")
	 * plus 1x : url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKAgMAAADwXCcuAAAADFBMVEWNjY2Li4tHcEyNjY3Ky7ZxAAAABHRSTlOAQADAtTSf0QAAABlJREFUCNdjWBW1gGEVFwI7fBAAY2QxoBoAE+cNwaeLiFMAAAAASUVORK5CYII=")
	 * plus 2x : url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUAgMAAADw5/WeAAAACVBMVEWNjY1HcEyNjY37Ark3AAAAA3RSTlPAAPDWZKCCAAAAHElEQVQI12MIBQGG0FBHkkgHBg0GRihJql6wjQDo/h11R70kvQAAAABJRU5ErkJggg==")
	 * plus 3x : url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeBAMAAADJHrORAAAAElBMVEVHcEyLi4uOjo6NjY2Pj4+NjY0yEEGGAAAABXRSTlMAQD96IJj2TZUAAAA3SURBVBjTY2DACliMHVD4jKECQ4TPogQCyqFGYNqBgTkUGRhg8FkEQUA41BBMOww5/2KPLzgAACfGFoQFynDIAAAAAElFTkSuQmCC")
	 */

	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const [number, setNumber] = useState(1);
	const [max, setMax] = useState(1);
	const [min, setMin] = useState(1);
	const [readOnly, setReadOnly] = useState(false);
	const ref = { number: useRef(null) };
	const modalStack = useModalStack();

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 입력
	 * @param {Event} e
	 */
	const handlerBlurNumber = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const ct = ref.number.current;
		const count = parseInt(ct.value);
		if (count > max) {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.numberStepper.overMax,
			});
			ct.value = number;
			return false;
		}
		if (count < min) {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.numberStepper.belowMin,
			});
			ct.value = number;
			return false;
		}
		await setNumber(count);

		await callPropsSetCount(count);
	};

	/**
	 * 빼기
	 * @param {Event} e
	 */
	const handlerClickMinus = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const count = number - 1;
		if (count >= min) {
			ref.number.current.value = count;
			await setNumber(count);
			await callPropsSetCount(count);
		} else {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.numberStepper.belowMin,
			});
			return false;
		}
	};

	/**
	 * 더하기
	 * @param {Event} e
	 */
	const handlerClickPlus = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const count = number + 1;
		if (count <= max) {
			ref.number.current.value = count;
			await setNumber(count);
			await callPropsSetCount(count);
		} else {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.numberStepper.overMax,
			});
			return false;
		}
	};

	/**
	 * 입력 - 키업
	 * @param {Event}} e
	 */
	const handlerKeyUpNumber = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		let key = e.key || e.keyCode;

		if (key === "Enter" || key === 13) {
			await handlerBlurNumber(e);
		} else {
			const ct = ref.number.current;
			ct.value = ct.value.replace(/[^0-9]/g, ""); //자연수만 입력 가능하도록 함;
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 부모 컨테이너에 count 전달
	 * @param {Number} count
	 */
	const callPropsSetCount = (count) => {
		let target = props.target ? props.target : null;
		let targetIndex = props.hasOwnProperty("targetIndex") ? props.targetIndex : null;
		if (props.setCount) {
			props.setCount(target, count, targetIndex);
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		const setData = () => {
			let dCount = 1;
			let dMax = 1;
			let dMin = 1;

			if (props.hasOwnProperty("count")) dCount = parseInt(props.count);
			setNumber(dCount);
			ref.number.current.value = dCount;

			if (props.hasOwnProperty("max")) dMax = parseInt(props.max);
			setMax(dMax);
			ref.number.current.max = dMax;

			if (props.hasOwnProperty("min")) dMin = parseInt(props.min);
			setMin(dMin);
			ref.number.current.min = dMin;

			if (props.setCount) props.setCount(null, dCount);
			if (props.soldout) setReadOnly(true);
		};
		setData();
	}, [props]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<NumberStepperContainer
			className={
				"numberStepper " +
				(props.className ? props.className + " " : "") +
				(props.soldout ? "soldout" : "")
			}
		>
			<ButtonCover className="button cover" onClick={(e) => handlerClickMinus(e)}>
				<ButtonMinus className="button minus" />
			</ButtonCover>
			<div className="numberCount">
				<InputNumber
					type="number"
					ref={ref.number}
					defaultValue={number}
					min={min}
					max={max}
					onBlur={(e) => handlerBlurNumber(e)}
					onKeyUp={(e) => handlerKeyUpNumber(e)}
					readOnly={readOnly}
				/>
			</div>
			<ButtonCover className="button cover" onClick={(e) => handlerClickPlus(e)}>
				<ButtonPlus className="button plus" />
			</ButtonCover>
		</NumberStepperContainer>
	);
};

export default NumberStepper;
