/**
 * 마이페이지 - 내 정보 관리 - 비밀번호 변경
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useRef } from "react";

import useModalStack from "src/hooks/useModalStack";
import { API_RESULT_STATUS } from "src/data/constEnum";
import { modifyMemberPassword } from "src/api/member/modify";
import {
	handlerBlurRequired,
	handlerClickTogglePwType,
	handlerKeyUpUserPW,
	handlerKeyUpUserPWCheck,
	validationPw,
	validationPwCheck,
} from "src/utils/linmeMember";

import MoveToBack from "src/pages/component/moveToBack";
import ModalCommonAlert from "src/pages/common/modalAlert";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myPage/myInfo/myInfoPasswordModify.scss";
import { encryptPassword } from "src/utils/linmeSession";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const MyPageMyInfoPasswordModify = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();
	const ref = {
		pwNow: useRef(null),
		pwNew: useRef(null),
		pwNewCheck: useRef(null),
		buttonConfirm: useRef(null),
	};

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 사용자 입력 변경 시
	 * @param {Event} e
	 */
	const handlerChangeInput = (e) => {
		/*/
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();
		//*/

		if (validation()) {
			ref.buttonConfirm.current.classList.add("on");
		} else {
			ref.buttonConfirm.current.classList.remove("on");
		}
	};

	/**
	 * 확인 버튼 클릭 시
	 * @param {Event} e
	 */
	const handlerClickConfirm = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const ct = e.currentTarget;
		if (ct.classList.contains("on")) {
			await requestModifyMemberPassword();
		} else {
			return false;
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 비밀번호 수정 요청
	 */
	const requestModifyMemberPassword = async () => {
		const hashedNowPassword = await encryptPassword(ref.pwNow.current.value);
		const hashedNewPassword = await encryptPassword(ref.pwNew.current.value);

		const d = {
			currentPassword: hashedNowPassword,
			newPassword: hashedNewPassword,
		};

		try {
			const result = await modifyMemberPassword(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.changedMemberPassword,
				});
				await reset();
				return result.data;
			} else {
				window.linmeLog.log(result);
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: result.message,
				});
				return false;
			}
		} catch (error) {
			window.linmeLog.log(error);
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: error?.response?.data?.message || textInfo.alert.error,
			});
			return false;
		}
	};

	/**
	 * 입력 및 버튼 클리어
	 */
	const reset = () => {
		ref.pwNow.current.value = "";
		ref.pwNew.current.value = "";
		ref.pwNewCheck.current.value = "";
		ref.buttonConfirm.current.classList.remove("on");
	};

	/**
	 * validation
	 * @returns
	 */
	const validation = () => {
		if (!validationPw(ref.pwNow.current)) return false;
		if (!validationPw(ref.pwNew.current)) return false;
		if (!validationPwCheck(ref.pwNewCheck.current, ref.pwNew.current)) return false;

		return true;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="myInfoPasswordModifyContainer">
			<div className="titleWrapper">
				<MoveToBack />
				<div className="title">{textInfo.myPage.changePassword.title}</div>
			</div>
			<div className="myInfoPasswordModifyCover">
				<ul className="modifyList">
					<li className="modifyItem">
						<div className="label">{textInfo.myPage.changePassword.passwordNow}</div>
						<div className="userInput">
							<input
								type="password"
								ref={ref.pwNow}
								className="inputData pwNow"
								placeholder={textInfo.myPage.changePassword.placeholder.passwordNow}
								onBlur={(e) => handlerBlurRequired(e)}
								onChange={(e) => handlerChangeInput(e)}
								onKeyUp={(e) => handlerKeyUpUserPW(e)}
							/>
							<div className="pwShowHide close" onClick={(e) => handlerClickTogglePwType(e)} />
							<div className="guide">
								<div className="inputGuideWarn"></div>
							</div>
						</div>
					</li>
					<li className="modifyItem">
						<div className="label">{textInfo.myPage.changePassword.passwordNew}</div>
						<div className="userInput">
							<input
								type="password"
								ref={ref.pwNew}
								className="inputData pwNew"
								placeholder={textInfo.myPage.changePassword.placeholder.passwordNew}
								onBlur={(e) => handlerBlurRequired(e)}
								onChange={(e) => handlerChangeInput(e)}
								onKeyUp={(e) => handlerKeyUpUserPW(e, ref.pwNow, ref.pwNew)}
							/>
							<div className="pwShowHide close" onClick={(e) => handlerClickTogglePwType(e)} />
							<div className="guide">
								<div className="inputGuide">{textInfo.member.guide.password.characterLength}</div>
								<div className="inputGuide">{textInfo.member.guide.password.character}</div>
								<div className="inputGuideWarn"></div>
							</div>
						</div>
					</li>
					<li className="modifyItem">
						<div className="label">{textInfo.myPage.changePassword.passwordNewCheck}</div>
						<div className="userInput">
							<input
								type="password"
								ref={ref.pwNewCheck}
								className="inputData pwNewCheck"
								placeholder={textInfo.myPage.changePassword.placeholder.passwordNewCheck}
								onBlur={(e) => handlerBlurRequired(e)}
								onChange={(e) => handlerChangeInput(e)}
								onKeyUp={(e) => handlerKeyUpUserPWCheck(e, ref.pwNew)}
							/>
							<div className="pwShowHide close" onClick={(e) => handlerClickTogglePwType(e)} />
							<div className="guide">
								<div className="inputGuideWarn"></div>
							</div>
						</div>
					</li>
				</ul>
				<div
					className="button clickable confirm"
					ref={ref.buttonConfirm}
					onClick={(e) => handlerClickConfirm(e)}
				>
					{textInfo.button.confirm}
				</div>
			</div>
		</div>
	);
};

export default MyPageMyInfoPasswordModify;
