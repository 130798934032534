/**
 * 클레임 ( 주문 취소/교환/환불) 상태바
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/claim/claimProgressStage.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
// const textInfo = require( "src/data/textInfo.json" );

const ClaimProgressStage = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="progressStageClaim">
			<div className={"progressStep step1 " + (props.on === "step1" ? "on" : "")}>
				<div className="progressNumberCover">
					<div className="progressNumber">1</div>
				</div>
				<div className="progressName">{props.text.step1}</div>
			</div>
			<div className={"progressStep step2 " + (props.on === "step2" ? "on" : "")}>
				<div className="progressNumberCover">
					<div className="progressNumber">2</div>
				</div>
				<div className="progressName">{props.text.step2}</div>
			</div>
		</div>
	);
};

export default ClaimProgressStage;
