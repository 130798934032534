/**
 * 안심번호 서비스 안내
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/payment/modal/virtualNumber.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const VirtualNumber = () => {
	return (
		<div className="modalContentsContainer virtualNumberContainer">
			<div className="title">{textInfo.payment.virtualNumber.title}</div>
			<div className="articleContainer">
				<div className="article">{textInfo.payment.virtualNumber.vnIs}</div>
				<div className="articleContent">{textInfo.payment.virtualNumber.content}</div>
				<div className="article">{textInfo.payment.virtualNumber.howToUse}</div>
				<div className="articleContent">
					{textInfo.payment.virtualNumber.content1}
					<br />
					{textInfo.payment.virtualNumber.content2}
					<br />
					{textInfo.payment.virtualNumber.content3}
					<br />
					{textInfo.payment.virtualNumber.content4}
					<br />
					{textInfo.payment.virtualNumber.content5}
				</div>
			</div>
		</div>
	);
};

export default VirtualNumber;
