/**
 * 결제 - 주문 상품 정보
 */
import CombinedProduct from "src/pages/component/order/combinedProduct";

import "src/styles/payment/payment.scss";

const textInfo = require("src/data/textInfo.json");

const PaymentProductCard = (props) => {
	const { orderData } = props;

	return (
		<>
			{orderData?.combinedShip?.map((combinedItem, index) => (
				<div className="combinedProductList" key={index}>
					<ul className="combinedProductContainer">
						<li className="productItem title">
							<div className="product">{textInfo.payment.checkOrderProduct.productInfo}</div>
							<div className="salePriceInfo">{textInfo.payment.checkOrderProduct.salePrice}</div>
							<div className="priceInfo">{textInfo.payment.checkOrderProduct.price}</div>
							<div className="deliveryPriceInfo">
								{textInfo.payment.checkOrderProduct.deliveryPrice}
							</div>
						</li>
						{combinedItem?.products?.map((product, index) => {
							return <CombinedProduct key={index} product={product} index={index} />;
						})}
						<li className="productItem lineProductInfo"></li>
						<li className="productItem lineSalePriceInfo"></li>
						<li className="productItem linePriceInfo"></li>
					</ul>

					{/* 총 배송비 */}
					<div className="cardDeliveryPrice">
						<div className="cardDeliveryPriceItem">{textInfo.order.totalDeliveryPrice}</div>
						<div className="cardDeliveryPriceItem bold">
							{combinedItem?.products && combinedItem?.products.length > 1 && (
								<span className="cardDeliveryPriceItem gray">
									({textInfo.cart.data.productDeliveryCombined})
								</span>
							)}
							{combinedItem?.deliveryPrice?.toLocaleString() || 0}
							{textInfo.common.currencyUnit}
						</div>
					</div>
				</div>
			))}
		</>
	);
};

export default PaymentProductCard;
