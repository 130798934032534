/**
 * 개인정보 제3자 이용 동의
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";
import axios from "axios";
import API_PATHS from "src/api/apiPaths";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/terms/termsPersonalInformationToThirdParties.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
// const textInfo = require( "src/data/textInfo.json" );

const TermsPersonalInformationToThirdParties = () => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const [content, setContent] = useState("");

	const url = `${process.env.REACT_APP_LINME_CLIENT_API}${API_PATHS.UI_TERMS_PERSONAL_INFORMATION_TO_THIRD_PARTIES}`;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 데이터 불러오기
	 */
	const getData = async () => {
		await axios
			.get(url)
			.then((res) => {
				setContent(res.data.replace(/<p>&nbsp;/g, "<p>"));
			})
			.catch((err) => {
				window.linmeLog.error(err);
			});
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		getData();

		//언마운트 시 실행;
		return () => {};
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="modalContentsContainer termsPersonalInformationToThirdPartiesContainer">
			<div className="termsContent" dangerouslySetInnerHTML={{ __html: content }} />
		</div>
	);
};

export default TermsPersonalInformationToThirdParties;
