import React, { useState } from "react";
import useQueryParams from "src/hooks/useQueryParams";
import clsx from "clsx";
import "src/styles/healthIssue.scss";

/** 이슈 검색 필터 */
const ProductIssueFilter = ({ selectedIssueNo, issueList }) => {
	const [isOpen, setIsOpen] = useState(false);
	const query = useQueryParams({ isSetPageFirst: false });

	const handleIssueClick = (issue) => {
		if (issue.issueNo) {
			query.set("issueNo", issue.issueNo);
		} else {
			setIsOpen((prev) => !prev);
		}
	};

	return (
		<div className="container healthIssue healthIssueEffect">
			<div className="contents">
				<div className="info">
					<ul className={clsx("healthIssueList", isOpen ? "open" : "close")}>
						{issueList?.map((issue, index) => (
							<li
								key={index}
								className={clsx("healthIssueItem", issue.en, {
									on: selectedIssueNo === issue.issueNo,
								})}
								data-issueno={issue.issueNo}
								onClick={() => handleIssueClick(issue)}
							>
								<div className="healthIssueName">{issue.name}</div>
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default ProductIssueFilter;
