/**
 * 상품 목록
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import ProductCard from "./productCard";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

const ProductList = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 상품 목록 만들기
	 * @returns
	 */
	const makeListContents = () => {
		let result = [];
		let iLen = props?.limit;
		if (!props?.limit || props?.limit > props?.items?.length) iLen = props?.items?.length;
		for (let i = 0; i < iLen; i++) {
			result?.push(
				<ProductCard
					{...(props?.items?.[i]?.hasOwnProperty("product")
						? props?.items?.[i]?.product
						: props?.items?.[i])}
					key={i}
				/>
			);
		}
		return result;
	};

	/**
	 * 스크롤이 존재 또는 존재하지 않는 상품 목록 만들기
	 * @returns
	 */
	// const showListType = () => {
	// 	if ( props.items && props.items.length ) {
	// 		if ( props.items && props.items.length && props.limit > 0 ) {
	// 			return makeListContents();
	// 		} else return (
	// 			<div className="productScroll">
	// 				{makeListContents()}
	// 			</div>
	// 		);
	// 	} else return <></>;
	// };

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="productList">
			{/* {showListType()} */}
			{makeListContents()}
		</div>
	);
};

export default ProductList;
