import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import { AppContext } from "src/App";
import Image from "src/pages/component/common/image";
import SearchProduct from "src/pages/search/layer/searchProduct";
import TopMainHeaderToggle from "src/pages/component/layout/topMain/topMainHeaderToggle";

const pathList = require("src/path/pathJSON.json");
const textInfo = require("src/data/textInfo.json");
import { TOP_MENU_ACTIVE_TYPE } from "src/data/constEnum";

const TopMainHeader = (props) => {
	const { menuType, setIsShowNotificationLayer } = props;

	const auth = useAuth();
	const { pathname } = useLocation();
	const { mainSelectedMenuType, setMainSelectedMenuType } = useContext(AppContext);
	const [searchKeyword, setSearchKeyword] = useState("");
	const [searchLayerClassName, setSearchLayerClassName] = useState("");
	const [isShowSearchLayer, setIsShowSearchLayer] = useState(false);
	const navigate = useNavigate();
	const [params, _] = useSearchParams();
	const searchKeywordParam = params.get("keyword");

	/**
	 * 기능식품/맞춤식품 토글 노출 여부
	 */
	const showToggle = () => {
		if (
			pathname === "" ||
			pathname === "/" ||
			pathname === "/new" ||
			pathname === "/best" ||
			pathname === "/special"
		) {
			return true;
		} else {
			return false;
		}
	};

	/**
	 * 상단 토글메뉴 클릭 후
	 * @param {Boolean} b
	 * true : 기능식품 / false : 맞춤식품
	 */
	const afterToggle = (b) => {
		if (b) {
			setMainSelectedMenuType("effect"); // 기능식품;
		} else {
			setMainSelectedMenuType("ingredients"); // 맞춤식품;
		}
	};

	/**
	 * 검색 레이어 toggle
	 * @param {Event} e
	 */
	const handlerToggleProductSearch = async (e, val, className) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (val) await setIsShowSearchLayer(val);
		if (className) await setSearchLayerClassName(className);
		if (!isShowSearchLayer && pathname.indexOf("/searchResult") === -1) {
			await setSearchKeyword("");
		}
		await setIsShowSearchLayer(!isShowSearchLayer);
	};

	/**
	 * 검색레이어에서 검색 후 검색어에 대하여 설정
	 * @param {String} keyword
	 */
	const setSearchKeywordFromLayer = (keyword) => {
		setSearchKeyword(keyword);
	};

	/**
	 * 알림 클릭 시
	 * @param {Event} e
	 */
	const handlerClickNotification = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (auth?.isLogin) setIsShowNotificationLayer((prev) => !prev);
		else navigate(pathList.member.login);
	};

	useEffect(() => {
		if (searchKeywordParam) {
			setSearchKeyword(searchKeywordParam);
		} else {
			setSearchKeyword("");
		}
	}, [searchKeywordParam]);

	return (
		<div className="headerContainer">
			{/* 로고 */}
			<div className="headerItem logo">
				<Link to={pathList.main}>
					<Image className="logoMain" srcSet={LOGO_MAIN_SRC_SET} alt={textInfo.button.moveToHome} />
				</Link>
			</div>
			<div className="headerItem toggle">
				{showToggle() && (
					<TopMainHeaderToggle
						mainSelectedMenuType={mainSelectedMenuType}
						afterToggle={afterToggle}
					/>
				)}
			</div>
			{/* 검색 */}
			<div className="headerItem search">
				<div
					className="searchContainer"
					onClick={(e) => handlerToggleProductSearch(e, null, "fromNormalType")}
				>
					<input
						type="text"
						className="searchKeywordInput"
						name="searchKeyword"
						placeholder={textInfo.menu.search.placeholder}
						value={searchKeyword}
						readOnly
					/>
					<div className="searchIcon">
						<Image srcSet={SEARCH_ICON_SRC_SET} alt={textInfo.search.title} />
					</div>
				</div>
				<SearchProduct
					{...{
						isShow: isShowSearchLayer,
						className: searchLayerClassName,
						handlerToggleLayer: handlerToggleProductSearch,
						searchKeywordNow: searchKeyword,
						afterInput: setSearchKeywordFromLayer,
					}}
				/>
			</div>
			{/* 장바구니 등 아이콘 */}
			<div className="headerItem icons">
				{/* 마이린미 */}
				<div
					className={`headerItemIcon myLinme ${menuType === TOP_MENU_ACTIVE_TYPE.MYLINME && "on"}`}
					onClick={() => navigate(pathList.myLinme.index)}
				/>
				{/* 주문/배송 */}
				<div
					className={`headerItemIcon myShopping ${
						menuType === TOP_MENU_ACTIVE_TYPE.SHOPPING && "on"
					}`}
					onClick={() => navigate(pathList.myPage.shoppingOrder)}
				/>
				{/* 장바구니 */}
				<div
					className={`headerItemIcon cart ${menuType === TOP_MENU_ACTIVE_TYPE.CART && "on"}`}
					onClick={() => navigate(pathList.cart)}
				>
					{auth.isLogin && auth?.user?.cartCount > 0 && (
						<div className="cartCount">
							{auth?.user?.cartCount > 99 ? "99+" : auth?.user?.cartCount}
						</div>
					)}
				</div>
				{/* 알림 */}
				<div
					className={`headerItemIcon notification ${auth?.user?.checkUnread && "on"}`}
					onClick={(e) => handlerClickNotification(e)}
				/>
			</div>
		</div>
	);
};

export default TopMainHeader;

const SEARCH_ICON_SRC_SET = `
                        ../../images/top/search_pink.png 1x
                        , ../../images/top/search_pink@2x.png 2x
                        , ../../images/top/search_pink@3x.png 3x
                        `;

const LOGO_MAIN_SRC_SET = `
                        ../../images/logo/logo_top.png 1x
                        , ../../images/logo/logo_top@2x.png 2x
                        , ../../images/logo/logo_top@3x.png 3x
                    `;
