/**
 * 메인페이지 배너
 */
import useBannerList from "src/hooks/main/useBannerList";
import BannerSlider from "src/pages/component/carousel/bannerSlider";
import { BANNER_TYPE } from "src/data/constEnum";

const BannerMain = (props) => {
	const { data: bannerList, isLoading } = useBannerList(BANNER_TYPE.MAIN);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	if (isLoading) return;

	return (
		<div className="container bannerMain">
			<BannerSlider items={bannerList} />
		</div>
	);
};

export default BannerMain;
