/**
 * 마이린미 - 기본정보 수정 모달
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";

import useModalStack from "src/hooks/useModalStack";
import { getMemberInfo, modifyMemberInfo } from "src/api/myLinme/main";
import { AGE_TYPE, API_RESULT_STATUS, GENDER_TYPE, REG_EXP } from "src/data/constEnum";

import CustomSelectBox from "src/pages/component/common/customSelectBox";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myLinme/infoModify.scss";
import { removeInnerSpinner, setInnerSpinner } from "src/utils/linmeDisplay";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const InfoModify = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();

	const [data, setData] = useState({});

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 취소 클릭 시
	 * @param {Event} e
	 */
	const handlerClickClose = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.removeModal(props.id);
	};

	/**
	 * 완료 클릭 시
	 * @param {Event} e
	 */
	const handlerClickComplete = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		_modifyMemberInfo();
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 내 기본정보 조회
	 */
	const _getMemberInfo = async () => {
		try {
			await setInnerSpinner();

			const result = await getMemberInfo();

			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				setData(result?.data);
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		} finally {
			await removeInnerSpinner();
		}
	};

	/**
	 * 내 기본정보 수정
	 */
	const _modifyMemberInfo = async () => {
		if (!validation()) return;

		try {
			await setInnerSpinner();

			const d = {
				linmeAgeGroup: data.linmeAgeGroup,
				linmeGender: data.linmeGender,
				height: parseInt(data.height),
				weight: parseInt(data.weight),
			};

			const result = await modifyMemberInfo(d);

			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				modalStack.removeModal(props.id);
				if (props.handlerClickConfirm) await props.handlerClickConfirm(props.item);
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		} finally {
			await removeInnerSpinner();
		}
	};

	/**
	 * validation
	 */
	const validation = () => {
		if (!REG_EXP.myLinme.weight.test(data?.weight)) return false;
		if (!REG_EXP.myLinme.height.test(data?.height)) return false;
		return true;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		_getMemberInfo();
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="modalInfoModifyContainer">
			<div className="modalInfoModifyTitle">{textInfo.myLinme.myInfo.title}</div>
			<div className="modalInfoModifyList">
				{/* 연령 */}
				<div className="modalInfoModifyListContainer">
					<div className="modalInfoModifyListTitle">{textInfo.myLinme.myInfo.age}</div>
					<div className="modalInfoModifyListValue">
						<CustomSelectBox
							options={AGE_TYPE}
							value={data?.linmeAgeGroup}
							placeholder={textInfo.myLinme.myInfo.age}
							onChange={(option) => setData({ ...data, linmeAgeGroup: option.value })}
						/>
					</div>
				</div>
				{/* 성별 */}
				<div className="modalInfoModifyListContainer">
					<div className="modalInfoModifyListTitle">{textInfo.myLinme.myInfo.gender}</div>
					<div className="modalInfoModifyListValue">
						<CustomSelectBox
							options={GENDER_TYPE}
							value={data?.linmeGender}
							placeholder={textInfo.myLinme.myInfo.gender}
							onChange={(option) => setData({ ...data, linmeGender: option.value })}
						/>
					</div>
				</div>
				{/* 키 */}
				<div className="modalInfoModifyListContainer">
					<div className="modalInfoModifyListTitle">{textInfo.myLinme.myInfo.height}</div>
					<div className="modalInfoModifyListValue">
						<input
							type="text"
							className="modalInfoModifyListInput"
							placeholder={textInfo.myLinme.myInfo.height}
							value={data?.height || ""}
							onChange={(e) => setData({ ...data, height: e.target.value })}
						/>
					</div>
					<div className="modalInfoModifyListUnit">{textInfo.common.unit.height}</div>
				</div>
				{/* 체중 */}
				<div className="modalInfoModifyListContainer">
					<div className="modalInfoModifyListTitle">{textInfo.myLinme.myInfo.weight}</div>
					<div className="modalInfoModifyListValue">
						<input
							type="text"
							className="modalInfoModifyListInput"
							placeholder={textInfo.myLinme.myInfo.weight}
							value={data?.weight || ""}
							onChange={(e) => setData({ ...data, weight: e.target.value })}
						/>
					</div>
					<div className="modalInfoModifyListUnit">{textInfo.common.unit.weight}</div>
				</div>
				{/* divider */}
				<div className="modalInfoModifyListDivider" />
				{/* 흡연 */}
				<div className="modalInfoModifyListContainer">
					<div className="modalInfoModifyListTitle">{textInfo.myLinme.myInfo.smoke}</div>
					<div className="modalInfoModifyListValue">
						<input
							type="text"
							className="modalInfoModifyListInput disabled"
							disabled
							placeholder={textInfo.myLinme.myInfo.smoke}
							value={data?.smoke || ""}
						/>
					</div>
				</div>
				{/* 음주 */}
				<div className="modalInfoModifyListContainer">
					<div className="modalInfoModifyListTitle">{textInfo.myLinme.myInfo.drink}</div>
					<div className="modalInfoModifyListValue">
						<input
							type="text"
							className="modalInfoModifyListInput disabled"
							disabled
							placeholder={textInfo.myLinme.myInfo.drink}
							value={data?.drink || ""}
						/>
					</div>
				</div>
			</div>
			<div className="buttonCover">
				<div className="button clickable close" onClick={(e) => handlerClickClose(e)}>
					{textInfo.button.cancel}
				</div>
				<div
					className={`button clickable confirm ${validation() ? "" : "disable"}`}
					onClick={(e) => handlerClickComplete(e)}
				>
					{textInfo.myLinme.myInfo.confirm}
				</div>
			</div>
		</div>
	);
};

export default InfoModify;
