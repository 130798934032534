/**
 * 환불 상세 보기 모달
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
// import moment from "moment";

import useModalStack from "src/hooks/useModalStack";
import { copyStructure } from "src/utils/obj";
import { setClaimDeliveryPickupDetail } from "src/api/myShopping/claim";
import { getDeliveryCompany } from "src/api/etc/delivery";
import { orderRefundCancel, orderRefundDetail } from "src/api/myShopping/refund";
import {
	API_RESULT_STATUS,
	CLAIM_STATUS,
	COLOR,
	PAYMENT_METHOD_TYPE,
	RETURN_DELIVERY_TYPE,
	Z_INDEX,
} from "src/data/constEnum";
import { makeDeliveryCompanySelectOptions } from "src/utils/linmeClaim";
import {
	setInnerSpinner,
	removeInnerSpinner,
	setSpinner,
	removeSpinner,
} from "src/utils/linmeDisplay";

import ModalCommonAlert from "src/pages/common/modalAlert";
// import Image from "src/pages/component/common/image";
import ReturnDiscountInfo from "src/pages/claim/modalReturnDiscountInfo";
import CustomSelectBox from "src/pages/component/common/customSelectBox";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/claim/orderRefundDetail.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

const htmlInfo = require("src/data/htmlInfo.json");
const textInfo = require("src/data/textInfo.json");

const OrderRefundDetail = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();
	const navigate = useNavigate();
	const [data, setData] = useState(null);
	const [totalPrice, setTotalPrice] = useState(null);
	const [deliveryCompanyList, setDeliveryCompanyList] = useState(null);
	const [deliveryCompanyNo, setDeliveryCompanyNo] = useState(-1);
	const ref = {
		deliveryCompany: useRef(null),
		invoiceNo: useRef(null),
	};
	const modalId = {
		dataCallFail: "modalCommonAlert", //환불 데이터 호출 실패;
		deliveryCompanyListFail: "modalCommonAlert", //택배사 목록 호출 실패;
		deliveryInfoSelectDeliveryCompany: "modalCommonAlert", //반송정보 저장 시 택배사명 선택;
		deliveryInfoInvoiceNo: "modalCommonAlert", //반송정보 저장 시 송장번호 입력;
		deliveryInfoComplete: "modalCommonAlert", //반송정보 적용 완료;
		deliveryInfoFail: "modalCommonAlert", //반송정보 적용 실패;
		refundCancel: "modalCommonConfirm", //환불 철회;
		refundCancelComplete: "modalCommonAlert", //환불 철회 성공;
		refundCancelFail: "modalCommonAlert", //환불 철회 실패;
	};

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 모달 닫기
	 * @param {Event} e
	 */
	const handlerClickClose = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.removeModal(props.id);
	};

	/**
	 * 나중에 입력 주소 저장 완료 시
	 * @param {Event} e
	 */
	const handlerClickPickupDetailClose = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await modalStack.removeModal(modalId.deliveryInfoComplete);
		await modalStack.removeModal(props.id);
	};

	/**
	 * 환불 철회 모달 확인 시
	 * @param {Event} e
	 */
	const handlerClickModalOrderRefundCancel = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await modalStack.removeModal(modalId.refundCancel);
		await setInnerSpinner();
		await requestOrderRefundCancel(data.claimProductNo);
	};

	/**
	 * 환불 철회 모달 취소 시
	 * @param {Event} e
	 */
	const handlerClickModalOrderRefundClose = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.removeModal("modalCommonConfirm");
	};

	/**
	 * 환불 철회 클릭 시
	 * @param {Event} e
	 */
	const handlerClickOrderRefundCancel = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await modalStack.addModal({
			id: modalId.refundCancel,
			type: "confirm",
			component: <ModalCommonAlert />,
			text: textInfo.alert.confirmOrderRefundCancel,
			button: {
				iconClose: false,
				confirm: {
					text: textInfo.button.confirm,
					style: { width: "fit-content", padding: "3px 10px" },
					fn: handlerClickModalOrderRefundCancel,
				},
				cancel: {
					text: textInfo.button.cancel,
					style: { width: "fit-content", padding: "3px 10px", border: "unset" },
					fn: handlerClickModalOrderRefundClose,
				},
			},
		});
	};

	/**
	 * 환불 쳘회 완료
	 * @param {Event} e
	 */
	const handlerClickModalRefundCancelComplete = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await modalStack.removeModal(modalId.refundCancelComplete);
		await modalStack.removeModal(props.id);
		await navigate(pathList.myPage.shoppingOrder);
	};

	/**
	 * 환불 쳘회 실패
	 * @param {Event} e
	 */
	const handlerClickModalRefundCancelFail = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await modalStack.removeModal(modalId.refundCancelFail);
		await modalStack.removeModal(props.id);
		await navigate(pathList.myPage.shoppingClaim);
	};

	/**
	 * 반환 할인액 물음표 클릭 시
	 * @param {Event} e
	 * @param {String} couponName
	 */
	const handlerClickReturnDiscountInfo = async (e, couponName) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await modalStack.addModal({
			id: "modalReturnDiscountInfo",
			type: "alert",
			component: <ReturnDiscountInfo />,
			couponName: couponName,
		});
	};

	/**
	 * 반송정보입력 "저장" 클릭 시
	 * @param {Event} e
	 */
	const handlerClickSaveDeliveryInfo = async (e) => {
		try {
			//버블링 막기;
			e.preventDefault();
			e.stopPropagation();

			if (deliveryCompanyNo === -1) {
				await modalStack.addModal({
					id: modalId.deliveryInfoSelectDeliveryCompany,
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.pleaseSelectDeliveryCompany,
				});
				return false;
			}
			if (!ref.invoiceNo.current.value) {
				await modalStack.addModal({
					id: modalId.deliveryInfoInvoiceNo,
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.pleaseInputInvoiceNo,
				});
				return false;
			}

			await requestSetDeliveryPickupDetail().then(async (data) => {
				// const o = await copyStructure(data);
				// o.delivery.deliveryType = RETURN_DELIVERY_TYPE.DIRECT;
				// o.delivery.companyName =
				// 	ref.deliveryCompany.current.querySelector(".selectedValue").innerText;
				// o.delivery.invoiceCode = ref.invoiceNo.current.value;
				// await setData(o);
			});
		} catch (err) {
			window.linmeLog.error(err);
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 환불 철회 ( 환불 요청을 취소 )
	 * @param {Number} claimProductNo
	 */
	const requestOrderRefundCancel = async (claimProductNo) => {
		try {
			const d = { claimProductNo };
			const result = await orderRefundCancel(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				await removeInnerSpinner();
				await modalStack.addModal({
					id: modalId.refundCancelComplete,
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.completeOrderRefundCancel,
					button: {
						ok: {
							text: textInfo.button.complete,
							fn: handlerClickModalRefundCancelComplete,
						},
					},
				});
				return result.data;
			} else {
				window.linmeLog.log(result);
				await removeInnerSpinner();
				await modalStack.addModal({
					id: modalId.refundCancelFail,
					type: "alert",
					component: <ModalCommonAlert />,
					html: htmlInfo.error.refundCancel,
					button: {
						ok: {
							text: textInfo.button.complete,
							fn: handlerClickModalRefundCancelFail,
						},
					},
				});
				return false;
			}
		} catch (err) {
			window.linmeLog.error(err);
			await removeInnerSpinner();
			await modalStack.addModal({
				id: modalId.refundCancelFail,
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.error.refundCancel,
				button: {
					ok: {
						text: textInfo.button.complete,
						fn: handlerClickModalRefundCancelFail,
					},
				},
			});
			return false;
		}
	};

	/**
	 * 택배사 목록 요청
	 * @returns
	 */
	const requestGetDeliveryCompany = async () => {
		try {
			const result = await getDeliveryCompany();
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				const list = makeDeliveryCompanySelectOptions(result.data);
				setDeliveryCompanyList(list);
				return result.data;
			} else {
				window.linmeLog.log(result);
				await modalStack.addModal({
					id: modalId.deliveryCompanyListFail,
					type: "alert",
					component: <ModalCommonAlert />,
					html: htmlInfo.error.dataCall,
				});
				return false;
			}
		} catch (err) {
			window.linmeLog.error(err);
			await modalStack.addModal({
				id: modalId.deliveryCompanyListFail,
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.error.dataCall,
			});
			return false;
		}
	};

	/**
	 * 환불상품번호로 환불 정보 호출
	 * @param {Number} orderNo
	 * @returns
	 */
	const requestOrderRefundDetail = async (claimProductNo) => {
		try {
			await setInnerSpinner();
			const result = await orderRefundDetail(claimProductNo);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				await setData(result.data);
				const o = await copyStructure(totalPrice ? totalPrice : {});
				o.deliveryPrice = result.data.tobePrice.deliveryPrice; //추가 배송비;
				o.refundPrice = result.data.tobePrice.price; //환불 예정 금액;
				o.refundReward = result.data.tobePrice.reward; //환불 예정 리워드;
				o.returnDiscount = result.data.tobePrice.returnDiscount; //반환 할인 금액;
				o.coupon = result.data.tobePrice.coupon; //쿠폰;
				if (o.coupon) o.coupon.isCheckTobeCoupon = result.data.tobePrice.isCheckTobeCoupon; //쿠폰 복원 여부;
				if (o.coupon)
					o.coupon.isCheckTobeCouponAvailable = result?.data?.tobePrice?.isCheckTobeCouponAvailable; //쿠폰 기간만료 여부;
				await setTotalPrice(o);
				return result.data;
			} else {
				window.linmeLog.log(result);
				await modalStack.addModal({
					id: modalId.dataCallFail,
					type: "alert",
					component: <ModalCommonAlert />,
					html: result?.response?.data?.message || htmlInfo.error.dataCall,
				});
				return false;
			}
		} catch (err) {
			window.linmeLog.error(err);
			await modalStack.addModal({
				id: modalId.dataCallFail,
				type: "alert",
				component: <ModalCommonAlert />,
				html: err?.response?.data?.message || htmlInfo.error.dataCall,
			});
			return false;
		} finally {
			await removeInnerSpinner();
		}
	};

	/**
	 * 반송 정보 적용 요청
	 * @returns
	 */
	const requestSetDeliveryPickupDetail = async () => {
		try {
			const d = {
				claimProductNo: props.claimProductNo,
				companyNo: deliveryCompanyNo,
				invoiceCode: ref.invoiceNo.current.value,
			};
			const result = await setClaimDeliveryPickupDetail(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (result.data && result.data.hasOwnProperty("claimProductNo")) {
					await modalStack.addModal({
						id: modalId.deliveryInfoComplete,
						type: "alert",
						component: <ModalCommonAlert />,
						text: textInfo.alert.pickupInfoChanged,
						button: {
							ok: {
								text: textInfo.button.complete,
								fn: handlerClickPickupDetailClose,
							},
						},
					});
				} else {
					window.linmeLog.log(result);
					await modalStack.addModal({
						id: modalId.deliveryInfoFail,
						type: "alert",
						component: <ModalCommonAlert />,
						html: htmlInfo.error.laterDeliveryInfoFail,
					});
					return false;
				}
				return result.data;
			} else {
				window.linmeLog.log(result);
				await modalStack.addModal({
					id: modalId.deliveryInfoFail,
					type: "alert",
					component: <ModalCommonAlert />,
					html: htmlInfo.error.laterDeliveryInfoFail,
				});
				return false;
			}
		} catch (err) {
			window.linmeLog.error(err);
			await modalStack.addModal({
				id: modalId.deliveryInfoFail,
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.error.laterDeliveryInfoFail,
			});
			return false;
		}
	};

	/**
	 * 택배사 선택 후 적용
	 * @param {Object} o
	 */
	const setSelectedDeliveryCompany = (o) => {
		setDeliveryCompanyNo(o.value);
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		const getData = async () => {
			await requestGetDeliveryCompany(); //택배사 목록;
			if (props.claimProductNo) {
				const claimProductNo = props.claimProductNo;
				await requestOrderRefundDetail(claimProductNo);
			}
		};
		getData();
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="orderRefundDetailContainer">
			<div className="title">
				{textInfo.claim.title.refund}&nbsp;{textInfo.claim.detail}
			</div>
			{/* 환불 사유 */}
			<ul className="refundDataList reason">
				<li className="refundDataItem title">
					<div className="label">{textInfo.orderRefund.orderRefundReason}</div>
					<div className="data">{data && data.reason ? data.reason : "-"}</div>
				</li>
				<li className="refundDataItem reasonDetail">
					<div className="label">{textInfo.orderRefund.orderRefundReasonDetail}</div>
					<div className="data">{data && data.content ? data.content : "-"}</div>
				</li>
			</ul>
			{/* 환불 배송비 정보 */}
			{data && data.deliveryPayment ? (
				<ul className="refundDataList">
					<li className="refundDataItem title">
						<div className="label">{textInfo.claim.deliveryPrice.orderRefundInfo}</div>
					</li>
					<li className="refundDataItem">
						<div className="label">{textInfo.claim.deliveryPrice.orderRefund}</div>
						<div className="data price">
							{totalPrice.deliveryPrice ? totalPrice.deliveryPrice.toLocaleString() : 0}
							<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
						</div>
					</li>
					<li className="refundDataItem">
						<div className="label">{textInfo.order.payment.method}</div>
						<div className="data method">
							{data &&
							data.deliveryPayment &&
							data.deliveryPayment.method === PAYMENT_METHOD_TYPE.CARD ? (
								<>
									<span>
										{textInfo.payment.payMethod.creditCard} {textInfo.order.payment.pay}
									</span>
									<span>
										{data.deliveryPayment.cardInfo.issuer} ({data.deliveryPayment.cardInfo.cardNo})
										/{" "}
										{data.deliveryPayment.cardInfo.installment === 0
											? textInfo.payment.paid.card.once
											: data.deliveryPayment.cardInfo.installment +
											  textInfo.payment.paid.card.installment}
									</span>
								</>
							) : (
								<></>
							)}
							{data &&
							data.deliveryPayment &&
							data.deliveryPayment.method === PAYMENT_METHOD_TYPE.REWARD ? (
								<>
									<span>{textInfo.payment.paid.reward}</span>
								</>
							) : (
								<></>
							)}
						</div>
					</li>
				</ul>
			) : (
				<></>
			)}
			{/* 회수 정보 "나중에 입력"일 때 택배사 및 송장번호 입력 */}
			{data &&
			data.delivery &&
			data.delivery.deliveryType &&
			data.delivery.deliveryType === RETURN_DELIVERY_TYPE.LATER ? (
				<>
					{deliveryCompanyList !== null ? (
						<div className="deliveryCompanyList" ref={ref.deliveryCompany}>
							<ul className="refundDataList">
								<li className="refundDataItem title">
									<div className="label">{textInfo.claim.pickup.enterDeliveryInfoDetail}</div>
								</li>
								<li className="refundDataItem userInput">
									<div className="label">{textInfo.claim.pickup.companyName}</div>
									<CustomSelectBox
										options={deliveryCompanyList}
										value={deliveryCompanyNo}
										placeholder={textInfo.claim.pickup.pleaseSelectDeliveryCompany}
										onChange={(item) => setSelectedDeliveryCompany(item)}
									/>
								</li>
								<li className="refundDataItem userInput">
									<div className="label invoice">{textInfo.claim.pickup.invoiceNo}</div>
									<input
										type="text"
										className="invoiceNo"
										name="invoiceNo"
										ref={ref.invoiceNo}
										placeholder={textInfo.claim.pickup.placeholder.invoiceNo}
									/>
								</li>
								<li className="refundDataItem">
									<div
										className="button clickable save"
										onClick={(e) => handlerClickSaveDeliveryInfo(e)}
									>
										{textInfo.button.save}
									</div>
								</li>
							</ul>
						</div>
					) : (
						<></>
					)}
				</>
			) : (
				<></>
			)}
			{/* 회수 정보 - 직접입력 */}
			{data &&
			data.delivery &&
			data.delivery.deliveryType &&
			data.delivery.deliveryType === RETURN_DELIVERY_TYPE.DIRECT ? (
				<ul className="refundDataList direct">
					<li className="refundDataItem title">
						<div className="label">{textInfo.claim.pickup.deliveryInfoDetail}</div>
					</li>
					<li className="refundDataItem">
						<div className="label">{textInfo.claim.pickup.companyName}</div>
						<div className="data">
							{data && data.delivery && data.delivery.companyName ? data.delivery.companyName : "-"}
						</div>
					</li>
					<li className="refundDataItem">
						<div className="label">{textInfo.claim.pickup.invoiceNo}</div>
						<div className="data">
							{data && data.delivery && data.delivery.invoiceCode ? data.delivery.invoiceCode : "-"}
						</div>
					</li>
				</ul>
			) : (
				<></>
			)}
			{/* 회수지 정보 */}
			{data &&
			data.delivery &&
			data.delivery.deliveryType &&
			data.delivery.deliveryType === RETURN_DELIVERY_TYPE.COMPANY ? (
				<ul className="refundDataList">
					<li className="refundDataItem title">
						<div className="label">{textInfo.claim.collectionAreaInfo.title}</div>
					</li>
					<li className="refundDataItem">
						<div className="label">{textInfo.claim.collectionAreaInfo.sender}</div>
						<div className="data">
							{data && data.delivery && data.delivery.collectAddress
								? data.delivery.collectAddress.name
								: "-"}
						</div>
					</li>
					<li className="refundDataItem">
						<div className="label">{textInfo.claim.collectionAreaInfo.phone}</div>
						<div className="data">
							{data && data.delivery && data.delivery.collectAddress
								? data.delivery.collectAddress.postcode
								: "-"}
						</div>
					</li>
					<li className="refundDataItem">
						<div className="label">{textInfo.claim.collectionAreaInfo.address}</div>
						<div className="data">
							{data && data.delivery && data.delivery.collectAddress
								? data.delivery.collectAddress.address1
								: "-"}
						</div>
					</li>
					<li className="refundDataItem onlyData">
						<div className="data">
							{data && data.delivery && data.delivery.collectAddress
								? data.delivery.collectAddress.address2
								: "-"}
						</div>
					</li>
					{data &&
					data.delivery &&
					data.delivery.collectAddress &&
					data.delivery.collectAddress.memo ? (
						<li className="refundDataItem">
							<div className="label">{textInfo.claim.collectionAreaInfo.memo}</div>
							<div className="data">{data.delivery.collectAddress.memo}</div>
						</li>
					) : (
						<></>
					)}
				</ul>
			) : (
				<></>
			)}
			{/* 환불 정보 */}
			<ul className="refundDataList">
				<li className="refundDataItem title">
					<div className="label">{textInfo.orderRefund.orderRefundInfo}</div>
				</li>
				<li className="refundDataItem refundPrice">
					{/* 환불 예정 금액 */}
					<div className="label">{textInfo.claim.price.restoreDiscountPrice}</div>
					<div className="data price">
						{totalPrice && totalPrice.refundPrice ? totalPrice.refundPrice.toLocaleString() : 0}
						<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
					</div>
				</li>
				<li className="refundDataItem reward">
					{/* 환불 리워드 */}
					<div className="label">{textInfo.claim.price.restoreDiscountReward}</div>
					<div className="data price">
						{totalPrice && totalPrice.refundReward ? totalPrice.refundReward.toLocaleString() : 0}
						<span className="rewardUnit">{textInfo.common.rewardUnit}</span>
					</div>
				</li>
				{/* 반품 배송비 */}
				{/* {totalPrice && totalPrice.deliveryPrice ? <li className="refundDataItem deliveryPrice">
					<div className="label">{textInfo.claim.price.refundDeliveryPrice}</div>
					<div className="data deliveryPrice">(-)&nbsp;{ totalPrice && totalPrice.deliveryPrice ? totalPrice.deliveryPrice.toLocaleString() : 0}<span className="currencyUnit">{textInfo.common.currencyUnit}</span></div>
				</li> : <></> } */}
				<li className="refundDataItem">
					{/* 사용한 쿠폰 */}
					<div className="label">{textInfo.claim.price.usedCoupon}</div>
					<div className="data">
						{totalPrice &&
						totalPrice?.coupon &&
						totalPrice?.coupon?.name &&
						totalPrice?.coupon?.isCheckTobeCoupon
							? totalPrice.coupon.name
							: textInfo.common.noExists}
						{totalPrice?.coupon?.isCheckTobeCoupon &&
						totalPrice?.coupon?.isCheckTobeCouponAvailable === true
							? textInfo.orderCancel.isCheckTobeCouponAvailable
							: ""}
					</div>
				</li>
				{/* 복구 예정 쿠폰 */}
				{/* <li className="refundDataItem restoreCoupon">
					<div className="label">{textInfo.claim.price.restoreCoupon}</div>
					<div className="data">{totalPrice && totalPrice.coupon && totalPrice.coupon.name && totalPrice.coupon.isCheckTobeCoupon ? totalPrice.coupon.name : textInfo.common.noExists }</div>
				</li> */}
				{/* 미복구 쿠폰에 대한 상세 */}
				{/* {totalPrice && totalPrice.coupon && totalPrice.coupon.name && totalPrice.coupon.isCheckTobeCoupon === false ? <li className="priceItem couponDetail">
					{(moment().isBetween( totalPrice.coupon.startAt, totalPrice.coupon.endAt )) ? <span className="expires">{textInfo.claim.price.couponExpires}</span> : <></>}
					<span className="couponName">{totalPrice.coupon.name}</span>
					<span className="period">( {moment( totalPrice.coupon.startAt ).format( FORMAT_MOMENT.KO_YMD )} ~ {moment( totalPrice.coupon.endAt ).format( FORMAT_MOMENT.KO_YMD )})</span>
				</li> : <></>} */}
				{totalPrice && totalPrice.coupon && totalPrice.coupon.name && totalPrice.returnDiscount ? (
					<li className="refundDataItem returnDiscount">
						{/* 반환 할인 금액 */}
						<div className="label">
							{textInfo.claim.price.refundDiscount}
							<div
								className="returnDiscountInfo"
								onClick={(e) =>
									handlerClickReturnDiscountInfo(
										e,
										totalPrice.coupon && totalPrice.coupon.name ? totalPrice.coupon.name : "-"
									)
								}
							/>
						</div>
						<div className="data price">
							(-)&nbsp;
							{totalPrice && totalPrice.returnDiscount
								? totalPrice.returnDiscount.toLocaleString()
								: 0}
							<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
						</div>
					</li>
				) : (
					<></>
				)}
				<li className="refundDataItem">
					<div className="label">{textInfo.order.payment.method}</div>
					<div className="data method">
						{data &&
						data.refundPayment &&
						data.refundPayment.method === PAYMENT_METHOD_TYPE.CARD ? (
							<>
								<span>
									{textInfo.payment.payMethod.creditCard} {textInfo.order.payment.pay}
								</span>
								<span>
									{data.refundPayment.cardInfo.issuer} ({data.refundPayment.cardInfo.cardNo}) /{" "}
									{data.refundPayment.cardInfo.installment === 0
										? textInfo.payment.paid.card.once
										: data.refundPayment.cardInfo.installment +
										  textInfo.payment.paid.card.installment}
								</span>
							</>
						) : (
							<></>
						)}
						{data &&
						data.refundPayment &&
						data.refundPayment.method === PAYMENT_METHOD_TYPE.REWARD ? (
							<>
								<span>{textInfo.payment.paid.reward}</span>
							</>
						) : (
							<></>
						)}
					</div>
				</li>
				{/* {totalPrice && totalPrice.useReward ? <li className="priceItem useReward">
					<div className="label">{textInfo.order.usedReward}</div>
					<div className="data useReward"><Image srcSet="
								/images/icon/signMinusDiscount.png 1x
								, /images/icon/signMinusDiscount@2x.png 2x
								, /images/icon/signMinusDiscount@3x.png 3x
							"
							alt={textInfo.order.usedReward}
						/>{ totalPrice && totalPrice.useReward ? totalPrice.useReward.toLocaleString() : 0}<span className="rewardUnit">{textInfo.common.rewardUnit}</span></div>
				</li> : <></> } */}
			</ul>
			{/* 환불 요청 상태일 때 환불 철회 가능 */}
			<div className="buttonCover">
				{data && data.status === CLAIM_STATUS.REFUND_REQ.value ? (
					<>
						<div
							className="button clickable refundCancel"
							onClick={(e) => handlerClickOrderRefundCancel(e)}
						>
							{textInfo.button.shopping.refundCancel}
						</div>
						<div className="button clickable close" onClick={(e) => handlerClickClose(e)}>
							{textInfo.button.confirm}
						</div>
					</>
				) : (
					<>
						<div className="button clickable close" onClick={(e) => handlerClickClose(e)}>
							{textInfo.button.confirm}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default OrderRefundDetail;
