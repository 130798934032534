/**
 * 장바구니/결제 등의 상태바
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/cart/cartProgressStage.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

const CartProgressStage = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="progressStageCart">
			<div className={"progressStep step1 " + (props.on === "step1" ? "on" : "")}>
				<div className="progressNumberCover">
					<div className="progressNumber">1</div>
				</div>
				<div className="progressName">장바구니</div>
			</div>
			<div className={"progressStep step2 " + (props.on === "step2" ? "on" : "")}>
				<div className="progressNumberCover">
					<div className="progressNumber">2</div>
				</div>
				<div className="progressName">주문/결제</div>
			</div>
			<div className={"progressStep step3 " + (props.on === "step3" ? "on" : "")}>
				<div className="progressNumberCover">
					<div className="progressNumber">3</div>
				</div>
				<div className="progressName">주문완료</div>
			</div>
		</div>
	);
};

export default CartProgressStage;
