import { QueryClient } from "@tanstack/react-query";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			enabled: true,
			retry: false,
			refetchOnMount: true,
		},
		mutations: {
			retry: false,
		},
	},
});

export default queryClient;
