/**
 * 문의 카드
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";

import useModalStack from "src/hooks/useModalStack";
import { API_RESULT_STATUS, INQUIRY_TYPE, INQUIRY_STATUS } from "src/data/constEnum";
import { deleteInquiry, deleteMyProductInquiry } from "src/api/product/inquiry";
import { getTimeStamp } from "src/utils/date";
import { removeInnerSpinner, setInnerSpinner } from "src/utils/linmeDisplay";

import ModalCommonAlert from "src/pages/common/modalAlert";
import InquiryImageList from "src/pages/component/inquiry/inquiryImageList";
import MyPageModalInquiryWrite from "src/pages/component/inquiry/inquiryWrite";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

// import "src/styles/inquiry/inquiryCard.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

const htmlInfo = require("src/data/htmlInfo.json");
const textInfo = require("src/data/textInfo.json");

const InquiryCard = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();
	// const navigate = useNavigate();
	const [data, setData] = useState(null);

	const modalId = {
		inquiryModify: "modalInquiryModifyConfirm",
		inquiryDeleted: "modalCommonAlert",
		errorRedraw: "modalCommonAlert",
		errorDelete: "modalCommonAlert",
	};

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 문의 단건 toggle
	 * @param {Event} e
	 */
	const handlerClickToggleContent = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const ct = e.currentTarget;
		const elInquiryA = ct.closest("li").querySelector(".inquiryA");
		if (elInquiryA.classList.contains("on")) elInquiryA.classList.remove("on");
		else elInquiryA.classList.add("on");
	};

	/**
	 * 삭제 클릭 시
	 * @param {Event} e
	 */
	const handlerClickDeleteInquiry = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await setInnerSpinner();

		if (data?.inquiryProductNo) {
			await requestDeleteProductInquiry();
		} else {
			await requestDeleteInquiry();
		}
	};

	/**
	 * 수정 클릭 시
	 * @param {Event} e
	 */
	const handlerClickModifyInquiry = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.addModal({
			id: modalId.inquiryModify,
			type: "confirm",
			component: <MyPageModalInquiryWrite />,
			// , item: null
			// , order: null
			modifyData: data,
			redraw: redraw,
		});
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 문의 수정 후 다시 그리기
	 * @param {Number} pageNo
	 * @returns
	 */
	const redraw = async (pageNo) => {
		if (props.redraw) props.redraw(pageNo);
	};

	/**
	 * 1:1 문의 삭제 요청
	 */
	const requestDeleteInquiry = async () => {
		try {
			const result = await deleteInquiry(data.inquiryNo);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (props.afterDeleted && props.hasOwnProperty("itemIndex")) {
					await props.afterDeleted(props.itemIndex);
				}
				await removeInnerSpinner();
				await modalStack.addModal({
					id: modalId.inquiryDeleted,
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.deleted,
				});
				redraw();
			} else {
				await removeInnerSpinner();
				await modalStack.addModal({
					id: modalId.errorDelete,
					type: "alert",
					component: <ModalCommonAlert />,
					html: htmlInfo.error.delete,
				});
			}
		} catch (err) {
			await removeInnerSpinner();
			window.linmeLog.error(err);
			await modalStack.addModal({
				id: modalId.errorDelete,
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.error.delete,
			});
			return false;
		}
	};

	/**
	 * 상품 문의 삭제 요청
	 */
	const requestDeleteProductInquiry = async () => {
		try {
			const result = await deleteMyProductInquiry(data.inquiryProductNo);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (props.afterDeleted && props.hasOwnProperty("itemIndex")) {
					await props.afterDeleted(props.itemIndex);
				}
				await removeInnerSpinner();
				await modalStack.addModal({
					id: modalId.inquiryDeleted,
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.deleted,
				});
				redraw();
			} else {
				await removeInnerSpinner();
				await modalStack.addModal({
					id: modalId.errorDelete,
					type: "alert",
					component: <ModalCommonAlert />,
					html: htmlInfo.error.delete,
				});
			}
		} catch (err) {
			await removeInnerSpinner();
			window.linmeLog.error(err);
			await modalStack.addModal({
				id: modalId.errorDelete,
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.error.delete,
			});
			return false;
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		if (props && props.data) setData(props.data);
	}, [props]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<li className="inquiryItem">
			{/* 질문 */}
			<div className="inquiryQ" onClick={(e) => handlerClickToggleContent(e)}>
				<div className="inquiryInfoContent">
					{/* 질문유형 */}
					{data && props.from === "myInquiry" ? (
						<div className="inquiryInfoWrapper">
							<div className="inquiryInfo">
								<div className="inquiryType">
									{data && data.type ? INQUIRY_TYPE[data.type].label : ""}
								</div>
								{data && data.product ? (
									<div className="inquiryTarget">{data.product.name}</div>
								) : (
									<></>
								)}
							</div>
							<div className="inquiryButtons">
								{/* 수정버튼 MOBILE */}
								{/* {data && data.status && data.status === INQUIRY_STATUS.WAIT.value ? (
									<div
										className="button clickable inquiryModify"
										onClick={(e) => handlerClickModifyInquiry(e)}
									></div>
								) : (
									<span />
								)} */}
								{/* 삭제버튼 MOBILE */}
								<div
									className="button clickable inquiryDelete"
									onClick={(e) => handlerClickDeleteInquiry(e)}
								></div>
							</div>
						</div>
					) : (
						<></>
					)}
					{/* 질문내용 */}
					<div className="inquiryContentWrapper">
						{/* 답변상태 MOBILE */}
						<div
							className={
								"inquiryStatus status " + (data && data.status ? data.status.toLowerCase() : "")
							}
						>
							<span>
								{data && data.status
									? textInfo.button.inquiryStatus[data.status.toLowerCase()]
									: ""}
							</span>
						</div>
						<div className={"inquiryContent " + (data && data.isCheckSecret ? "isSecret" : "")}>
							{data && data.content ? data.content : ""}
						</div>
					</div>
				</div>
				{/* 작성자 */}
				{data && props.from === "myInquiry" ? (
					<></>
				) : (
					<div className="inquiryWriter">{data && data.writer ? data.writer : ""}</div>
				)}
				{/* 작성일 PC&TABLET */}
				<div className="inquiryWriteDate">
					{data && data.registered ? getTimeStamp(data.registered, "YYYY. MM. DD") : ""}
				</div>
				{/* 답변상태 PC&TABLET */}
				<div
					className={
						"inquiryStatus status " + (data && data.status ? data.status.toLowerCase() : "")
					}
				>
					<span>
						{data && data.status ? textInfo.button.inquiryStatus[data.status.toLowerCase()] : ""}
					</span>
				</div>
				<div className="inquiryButtons">
					{/* 수정버튼 PC&TABLET */}
					{/* {data && data.status && data.status === INQUIRY_STATUS.WAIT.value ? (
						<div
							className="button clickable inquiryModify"
							onClick={(e) => handlerClickModifyInquiry(e)}
						></div>
					) : (
						<span />
					)} */}
					{/* 삭제버튼 PC&TABLET */}
					<div
						className="button clickable inquiryDelete"
						onClick={(e) => handlerClickDeleteInquiry(e)}
					></div>
				</div>
			</div>
			{/* 답변 */}
			<div className="inquiryA">
				{/* 질문내용 */}
				{data && data.content ? (
					<div className="inquiryContent">
						{data.content}
						{data && data.reqFile && data.reqFile.length ? (
							<InquiryImageList {...{ images: data.reqFile }} />
						) : (
							<></>
						)}
					</div>
				) : (
					<></>
				)}
				{/* 답변내용 */}
				{data && data.replyContent ? (
					<div className="replyContent">{data.replyContent}</div>
				) : (
					<></>
				)}
				{data && data.repFile ? <InquiryImageList {...{ images: [data.repFile] }} /> : <></>}
			</div>
		</li>
	);
};

export default InquiryCard;
