import apiMyLinme from "src/api/axiosMyLinme";
import API_PATHS from "src/api/apiPaths";

/**
 * 건강상태 현황
 * @returns
 */
export const getCondition = async () => {
	const url = `${API_PATHS.CONDITION}`;
	try {
		const result = await apiMyLinme.get(url);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 영양제 섭취 관리(달력)
 * @param {Object} d
 * <code>
	nowMonth = 2024-06-01
 * </code>
 * @returns
 */
export const getMainMonth = async (d) => {
	const url = `${API_PATHS.MAIN_MONTH}`;
	try {
		const result = await apiMyLinme.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 일일 알람 내역
 * @param {Object} d
 * <code>
	nowDate = 2024-06-21
 * </code>
 * @returns
 */
export const getMainDay = async (d) => {
	const url = `${API_PATHS.MAIN_DAY}`;
	try {
		const result = await apiMyLinme.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 건강조합 추천 상품 리스트
 * @returns
 */
export const getMainProduct = async () => {
	const url = `${API_PATHS.MAIN_PRODUCT}`;
	try {
		const result = await apiMyLinme.get(url);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 건강조합 추천 상품 리스트 (페이징)
 * @returns
 */
export const getMainProductDetail = async (d) => {
	const url = `${API_PATHS.MAIN_PRODUCT_DETAIL}`;
	try {
		const result = await apiMyLinme.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 내 기본정보 조회
 * @returns
 */
export const getMemberInfo = async () => {
	const url = `${API_PATHS.MEMBER_INFO}`;
	try {
		const result = await apiMyLinme.get(url);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 내 기본정보 수정
 * @param {Object} d
 * <code>
	{
		"linmeAgeGroup" : "20",
		"linmeGender" : "남성",
		"height" : 400,
		"weight" : 1
	}
 * </code>
 */
export const modifyMemberInfo = async (d) => {
	const url = `${API_PATHS.MEMBER_MODIFY}`;
	try {
		const result = await apiMyLinme.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
