/**
 * 푸터
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import useModalStack from "src/hooks/useModalStack";

import Image from "src/pages/component/common/image";
import TermsPrivacyPolicy from "src/pages/terms/termsPrivacyPolicy";
import TermsService from "src/pages/terms/termsService";
import { moveAppDownload } from "src/utils/linmeDevice";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

const textInfo = require("src/data/textInfo.json");

function Footer() {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 회사 소개
	 * @param {Event} e
	 */
	const handlerClickCompanyInfo = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (pathList?.siteGuide?.companyInfo) window.open(pathList.siteGuide.companyInfo, "_blank");
	};

	/**
	 * 서비스 이용약관 보기
	 * @param {Event} e
	 */
	const handlerClickOpenModalTermsService = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.addModal({
			id: "modalTermsService",
			type: "alert",
			component: <TermsService />,
		});
	};

	/**
	 * 개인정보 처리방침 약관 보기
	 * @param {Event} e
	 */
	const handlerClickOpenModalTermsPrivacyPolicy = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.addModal({
			id: "modalTermsPrivacyPolicy",
			type: "alert",
			component: <TermsPrivacyPolicy />,
		});
	};

	/**
	 * 입점제휴 문의
	 * @param {Event} e
	 */
	const handlerClickJoin = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (pathList?.siteGuide?.join) window.open(pathList.siteGuide.join, "_blank");
	};

	/**
	 * 사업자 등록번호
	 * @param {Event} e
	 */
	const handlerClickBusinessNumber = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (pathList?.siteGuide?.businessNumber)
			window.open(pathList.siteGuide.businessNumber, "_blank");
	};

	/**
	 * 소셜 아이콘 클릭
	 * @param {Event} e
	 */
	const handlerClickOfficialSns = (snsType) => {
		switch (snsType) {
			case "NaverBlog":
				if (pathList?.officialSns?.naverBlog) window.open(pathList.officialSns.naverBlog, "_blank");
				break;
			case "Instagram":
				if (pathList?.officialSns?.instagram) window.open(pathList.officialSns.instagram, "_blank");
				break;
			case "Facebook":
				if (pathList?.officialSns?.facebook) window.open(pathList.officialSns.facebook, "_blank");
				break;
			default:
				break;
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<footer className="container footer">
			<div className="terms">
				<div className="contents">
					<ul className="guideList">
						<li className="guideItem clickable" onClick={(e) => handlerClickCompanyInfo(e)}>
							{textInfo.siteGuide.companyInfo}
						</li>
						<li
							className="guideItem clickable"
							onClick={(e) => handlerClickOpenModalTermsService(e)}
						>
							{textInfo.siteGuide.termsService}
						</li>
						<li
							className="guideItem clickable"
							onClick={(e) => handlerClickOpenModalTermsPrivacyPolicy(e)}
						>
							{textInfo.siteGuide.termsPrivacy}
						</li>
						<li className="guideItem clickable" onClick={(e) => handlerClickJoin(e)}>
							{textInfo.siteGuide.join}
						</li>
					</ul>
				</div>
			</div>
			<div className="footerContents">
				<div className="contents">
					<div className="linmeIs">
						<Image
							className="logoMain"
							srcSet="
					../../images/logo/logo_footer.png 1x
					, ../../images/logo/logo_footer@2x.png 2x
					, ../../images/logo/logo_footer@3x.png 3x
				"
							alt="linme logo"
						/>
						<div className="copy">{textInfo.site.copy}</div>
					</div>
					<div className="companyInfoContainer">
						<div className="companyInfo">
							<div className="companyName">{textInfo.companyInfo.name}</div>
							<div className="companyInfoDetail">
								<div className="companyCEOAndLocation">
									<span>대표자 : {textInfo.companyInfo.ceo}</span>
									<div className="mark_separation" />
									<span>주소 : {textInfo.companyInfo.address}</span>
								</div>
								<div className="reportNumber">
									<span className="mailOrderSalesRegistrationNumber">
										통신판매업신고 : {textInfo.companyInfo.mailOrderBusinessReportNumber}
									</span>
									<div className="mark_separation" />
									<span className="businessNumber" onClick={handlerClickBusinessNumber}>
										사업자번호 : {textInfo.companyInfo.businessNumber}
									</span>
								</div>
							</div>
							<div className="companyOfficialSns desktop">
								<Image
									className="officialNaverBlog"
									srcSet="
					../../images/footer/sns_naver_blog.png 1x
					, ../../images/footer/sns_naver_blog@2x.png 2x
					, ../../images/footer/sns_naver_blog@3x.png 3x
					"
									alt="Official Naver Blog"
									handlerClick={() => handlerClickOfficialSns("NaverBlog")}
								/>
								<Image
									className="officialInstagram"
									srcSet="
					../../images/footer/sns_instagram.png 1x
					, ../../images/footer/sns_instagram@2x.png 2x
					, ../../images/footer/sns_instagram@3x.png 3x
					"
									alt="Official Instagram"
									handlerClick={() => handlerClickOfficialSns("Instagram")}
								/>
								<Image
									className="officialFacebook"
									srcSet="
					../../images/footer/sns_facebook.png 1x
					, ../../images/footer/sns_facebook@2x.png 2x
					, ../../images/footer/sns_facebook@3x.png 3x
				 "
									alt="Official Facebook"
									handlerClick={() => handlerClickOfficialSns("Facebook")}
								/>
							</div>
						</div>
						<div className="contactUs">
							<div className="cs">
								<span>{textInfo.cs.name}</span>
								<span className="tel">{textInfo.cs.tel}</span>
							</div>
							<div className="csTimeContainer">
								<div className="csTime">
									{textInfo.cs.csTime.day} <span>{textInfo.cs.csTime.time}</span>
								</div>
								<div className="csBreak">{textInfo.cs.breakTime}</div>
							</div>

							<div className="contactContainer">
								<a className="email" href={"mailto:" + textInfo.cs.email}>
									E-mail : {textInfo.cs.email}
								</a>
								<div className="fax">Fax : {textInfo.cs.fax}</div>
							</div>
						</div>
						<div className="companyOfficialSns mobile">
							<Image
								className="officialNaverBlog"
								srcSet="
					../../images/footer/sns_naver_blog.png 1x
					, ../../images/footer/sns_naver_blog@2x.png 2x
					, ../../images/footer/sns_naver_blog@3x.png 3x
					"
								alt="Official Naver Blog"
								handlerClick={() => handlerClickOfficialSns("NaverBlog")}
							/>
							<Image
								className="officialInstagram"
								srcSet="
					../../images/footer/sns_instagram.png 1x
					, ../../images/footer/sns_instagram@2x.png 2x
					, ../../images/footer/sns_instagram@3x.png 3x
					"
								alt="Official Instagram"
								handlerClick={() => handlerClickOfficialSns("Instagram")}
							/>
							<Image
								className="officialFacebook"
								srcSet="
					../../images/footer/sns_facebook.png 1x
					, ../../images/footer/sns_facebook@2x.png 2x
					, ../../images/footer/sns_facebook@3x.png 3x
				 "
								alt="Official Facebook"
								handlerClick={() => handlerClickOfficialSns("Facebook")}
							/>
						</div>
						<div className="appDownload">
							<div className="download clickable android" onClick={() => moveAppDownload("aos")} />
							<div className="download clickable iOS" onClick={() => moveAppDownload("ios")} />
						</div>
					</div>
					<div className="copyright">{textInfo.site.copyright}</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
