/**
 * 마이페이지 - 쇼핑내역 - 주문/배송
 */

import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/**
 * 배송 조회
 * @param {Number} itemNo
 * @returns
 */
export const getDeliveryDetail = async (itemNo) => {
	const url = `${API_PATHS.ORDER_DELIVERY_DETAIL}`;
	try {
		const result = await api.get(url.replace(":itemNo", itemNo));
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 주문/배송 목록
 * @param {Object} d
 * <code>
 * {
 * 		offset: 0
 * 		, page: 0
 * }
 * </code>
 * //결제완료(PAY_FIN), 상품준비중(PRD_READY), 배송대기중(DLVY_READY), 배송중(DLVY_IN), 배송완료(DLVY_FIN), 구매확정(CONFIRM)
 */
export const getMyOrderList = async (d) => {
	const url = `${API_PATHS.ORDER}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 구매확정
 * @param {Number} itemNo
 */
export const orderConfirm = async (itemNo) => {
	const url = `${API_PATHS.ORDER_CONFIRM}`;
	try {
		const result = await api.post(url.replace(":itemNo", itemNo));
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 주문 상세
 * @param {Number} orderNo
 * @returns
 */
export const orderDetail = async (orderNo) => {
	const url = `${API_PATHS.ORDER_DETAIL}`;
	try {
		const result = await api.get(url.replace(":orderNo", orderNo));
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
