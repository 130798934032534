/**
 * 마이린미 - 건강 MBTI 테스트 결과
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { AppContext } from "src/App";
import { getMbtiDetail } from "src/api/myLinme/mbti";
import { API_RESULT_STATUS } from "src/data/constEnum";
import Image from "src/pages/component/common/image";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myLinme/MBTIResult.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const MyLinmeMBTIResult = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const { device } = useContext(AppContext);

	const [resultData, setResultData] = useState();

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 질문 목록 가져오기 API
	 */
	const _getMbtiDetail = async () => {
		try {
			const d = { env: device };

			const result = await getMbtiDetail(d);

			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				setResultData(result?.data);
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		_getMbtiDetail();
	}, [device]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="container underTopMenu myLinme healthMBTITestResultContainer">
			<div className="contents">
				<div className="suggestionInfo">
					<Image className="suggestionImage" src={resultData?.filePath} alt="mbtiResult" />
					{/* <div className={"suggestionImage " + mbti}></div> */}
					<Link to={pathList.myLinme.MBTITest} className="buttonContainer desktop">
						<div className="button clickable mbtiRetry ">{textInfo.button.mbtiRetry}</div>
					</Link>
				</div>
				<Image className="suggestionList" src={resultData?.otherFilePath} alt="mbtiResult" />
				<Link to={pathList.myLinme.MBTITest} className="buttonContainer mobile">
					<div className="button clickable mbtiRetry">{textInfo.button.mbtiRetry}</div>
				</Link>
			</div>
		</div>
	);
};

export default MyLinmeMBTIResult;
