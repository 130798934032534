/**
 * 작성 중 나갈 때
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import useModalStack from "src/hooks/useModalStack";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/product/modal/whenLeaveConfirmWrite.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const WhenLeaveConfirmWrite = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 취소 클릭 시
	 * @param {Event} e
	 */
	const handlerClickCancel = (e) => {
		if (e) {
			//버블링 막기;
			e.preventDefault();
			e.stopPropagation();
		}

		if (props.handlerCancel) props.handlerCancel(e);
	};

	/**
	 * 나가기 클릭 시
	 * @param {Event} e
	 */
	const handlerClickExit = (e) => {
		if (e) {
			//버블링 막기;
			e.preventDefault();
			e.stopPropagation();
		}

		if (props.handlerExit) props.handlerExit(e);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="whenLeaveConfirmWriteContainer">
			{props.html ? (
				<div className="alertContent" dangerouslySetInnerHTML={{ __html: props.html }} />
			) : (
				<div className="alertContent">{props.text}</div>
			)}
			<div className="buttonCover">
				<div className="button clickable cancel" onClick={(e) => handlerClickCancel()}>
					{textInfo.button.cancel}
				</div>
				<div className="button clickable exit" onClick={(e) => handlerClickExit()}>
					{textInfo.button.exit}
				</div>
			</div>
		</div>
	);
};

export default WhenLeaveConfirmWrite;
