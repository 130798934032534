/**
 * 마이린미 - 알람 최종 섭취 완료 모달
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useNavigate } from "react-router-dom";

import useModalStack from "src/hooks/useModalStack";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myLinme/alarmComplete.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const AlarmComplete = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();
	const navigate = useNavigate();

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;
	/**
	 * "추가 구매하기" 클릭 시
	 * @param {Event} e
	 */
	const handlerClickBuyMore = async (product) => {
		// 상품 상세 페이지로 이동
		await modalStack.removeModal(props.id);
		if (!!product.productType && !!product.productNo) {
			navigate(
				pathList.product.detail + `${product?.productType?.toLowerCase()}/` + product?.productNo
			);
		} else {
			console.error("상품 정보가 없습니다.");
		}
	};

	/**
	 * "취소" 클릭 시
	 * @param {Event} e
	 */
	const handlerClickCancel = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		// 모달 닫기
		await modalStack.removeModal(props.id);
	};

	/**
	 * "완료" 클릭 시
	 * @param {Event} e
	 */
	const handlerClickSave = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		// 내 리워드로 이동
		await modalStack.removeModal(props.id);
		await navigate(pathList.reward.index);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<>
			<div className="alarmCompleteContent">
				<div className="alarmCompleteTrophyImg" />
				<div className="alarmCompleteTitle">{textInfo.myLinme.alarm.actionModalFinalResult1}</div>
				<div className="alarmCompleteSubTitle">
					{textInfo.myLinme.alarm.actionModalFinalResult2}
					{props?.data?.totalCompletionReward.toLocaleString() || 0}
					{textInfo.common.rewardUnit}
					{textInfo.myLinme.alarm.actionModalFinalResult3}
				</div>
				<div className="completeProductList">
					{props.data?.product?.map((product, i) => (
						<div className="completeProductListItem" key={i}>
							<div className="completeProductListReward">
								{product?.completionReward?.toLocaleString()} {textInfo.common.rewardUnit}
							</div>
							<div className="completeProductListName">{product?.productName}</div>
							<div
								className="completeProductListButton"
								onClick={() => handlerClickBuyMore(product)}
							>
								{textInfo.myLinme.alarm.actionModalFinalBuyMore}
							</div>
						</div>
					))}
				</div>
			</div>
			<div className="buttonCover">
				<div className="button save" onClick={handlerClickSave}>
					{textInfo.myLinme.alarm.actionModalFinalMoveToReward}
				</div>
				<div className="button cancel" onClick={handlerClickCancel}>
					{textInfo.button.cancel}
				</div>
			</div>
		</>
	);
};

export default AlarmComplete;
