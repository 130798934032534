/**
 * 리뷰 카드
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import useModalStack from "src/hooks/useModalStack";
import { deleteReview, getReviewDetail } from "src/api/product/review";
import { getTimeStamp } from "src/utils/date";
import {
	API_RESULT_STATUS,
	EMPTY_USER_PROFILE_IMAGE,
	PRODUCT_OPTIONS_TYPE,
} from "src/data/constEnum";

import StarScore from "src/pages/component/review/starScore";
import ReviewImageList from "src/pages/component/review/reviewImageList";
import ReviewLike from "src/pages/component/product/reviewLike";
import Image from "src/pages/component/common/image";
import ReviewWriteConfirm from "src/pages/product/modal/reviewWriteConfirm";
import ModalCommonAlert from "src/pages/common/modalAlert";
import { getProductDetailType } from "src/utils/linmeSearch";
import Avatar from "src/pages/component/review/avatar";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/review/reviewCard.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

const htmlInfo = require("src/data/htmlInfo.json");
const textInfo = require("src/data/textInfo.json");

const ReviewCard = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;
	const {
		productNo,
		type,
		reviewItem,
		afterDeleted,
		itemIndex,
		thumbnail,
		from,
		name,
		redrawList,
	} = props;
	const modalStack = useModalStack();
	const [data, setData] = useState(null);

	const modalId = {
		reviewModify: "modalReviewModifyConfirm",
		reviewDeleted: "modalCommonAlert",
		errorRedraw: "modalCommonAlert",
		errorDelete: "modalCommonAlert",
	};

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 삭제 클릭 시
	 * @param {Event} e
	 */
	const handlerClickDeleteReview = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await requestDeleteReview();
	};

	/**
	 * 수정 클릭 시
	 * @param {Event} e
	 */
	const handlerClickModifyReview = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.addModal({
			id: modalId.reviewModify,
			type: "confirm",
			component: <ReviewWriteConfirm />,
			item: null,
			order: null,
			modifyData: props,
			redraw: redraw,
			redrawList: redrawList,
		});
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 리뷰 수정 후 다시 그리기
	 * @returns
	 */
	const redraw = async () => {
		try {
			const result = await getReviewDetail(data.product.productNo, data.reviewNo);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (result.data) {
					await setData(result.data);
				} else {
					await modalStack.addModal({
						id: modalId.errorRedraw,
						type: "alert",
						component: <ModalCommonAlert />,
						html: htmlInfo.error.redraw,
					});
				}
			} else {
				await modalStack.addModal({
					id: modalId.errorRedraw,
					type: "alert",
					component: <ModalCommonAlert />,
					html: htmlInfo.error.redraw,
				});
			}
		} catch (err) {
			window.linmeLog.error(err);
			await modalStack.addModal({
				id: modalId.errorRedraw,
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.error.redraw,
			});
			return false;
		}
	};

	/**
	 * 리뷰 삭제 요청
	 */
	const requestDeleteReview = async () => {
		try {
			const result = await deleteReview(data.reviewNo);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (afterDeleted && hasOwnProperty("itemIndex")) {
					await afterDeleted(itemIndex);
				}
				await modalStack.addModal({
					id: modalId.reviewDeleted,
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.deleted,
				});
			} else {
				await modalStack.addModal({
					id: modalId.errorDelete,
					type: "alert",
					component: <ModalCommonAlert />,
					html: htmlInfo.error.delete,
				});
			}
		} catch (err) {
			window.linmeLog.error(err);
			await modalStack.addModal({
				id: modalId.errorDelete,
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.error.delete,
			});
			return false;
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		if (props && reviewItem) setData(reviewItem);
	}, [reviewItem]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<>
			{/* 상단 정보 */}
			{data && from === "myReview" ? (
				// 마이페페이지 내 리뷰 목록
				<div className="myPageReviewInfoContainer">
					<div className="myPageReviewInfoItem">
						{/* 상품이미지 */}
						<Link to={pathList?.product?.detail + `${getProductDetailType(type)}/` + productNo}>
							<div className="reviewProductImage">
								<Image src={thumbnail?.webPath} alt={name} />
							</div>
						</Link>
						<div className="reviewProductDetail">
							{/* 브랜드명 */}
							<div className="brandName">{reviewItem?.brand?.name || ""}</div>
							{/* 상품명 */}
							<Link to={pathList?.product?.detail + `${getProductDetailType(type)}/` + productNo}>
								<div className="productName">{reviewItem?.product?.name || ""}</div>
							</Link>
							{/* 옵션 */}
							{data?.product?.option?.optionFullName && (
								<div className="optionName">{data?.product?.option?.optionFullName}</div>
							)}
							<div className="reviewWriteInfo">
								<StarScore {...{ score: data && data.rate ? data.rate : 0 }} />
								<div className="writeDate">
									{data && data.registered ? getTimeStamp(data.registered, "YYYY. MM. DD") : ""}
								</div>
							</div>
						</div>
					</div>
					<div className="myPageReviewInfoContent">
						<div className="reviewContent">
							{/* 리뷰 이미지 */}
							{data?.images?.length > 0 && <ReviewImageList {...data} />}
							{/* 리뷰 텍스트 */}
							<div className="reviewText">{data?.content}</div>
						</div>
						{/* 리뷰 좋아요 */}
						{data && (
							<ReviewLike
								{...{
									reviewNo: data.reviewNo,
									isCheckLike: data.isCheckLike,
									likeCount: data.likeCount,
									refetch: redraw,
								}}
							/>
						)}
					</div>
				</div>
			) : (
				// 상품 상세 페이지 리뷰
				<>
					<div className="reviewInfoContainer">
						{/* 프로필 이미지 */}
						<div className="reviewUserProfile">
							<Avatar url={data?.member?.image?.webPath} alt={data?.member?.nickname} />
						</div>
						<div className="reviewInfoItem">
							<div className="reviewInfoItemTitle">
								{/* 회원명 */}
								<div className="userName">{data?.member?.nickname}</div>
								{/* 작성일 */}
								<div className="writeDate">
									{data && data.registered ? getTimeStamp(data.registered, "YYYY. MM. DD") : ""}
								</div>
							</div>
							{/* 별점 */}
							<StarScore {...{ score: data && data.rate ? data.rate : 0 }} />
							{/* 옵션 */}
							{data?.product?.option?.optionFullName && (
								<div className="reviewInfoItemOption">{data?.product?.option?.optionFullName}</div>
							)}
							<div className="reviewContent">
								{/* 리뷰 이미지 */}
								{data?.images?.length > 0 && <ReviewImageList {...data} />}
								{/* 리뷰 텍스트 */}
								<div className="reviewText">{data?.content}</div>
							</div>
							{/* 리뷰 좋아요 */}
							{data && (
								<ReviewLike
									{...{
										reviewNo: data.reviewNo,
										isCheckLike: data.isCheckLike,
										likeCount: data.likeCount,
										refetch: redraw,
									}}
								/>
							)}
						</div>
					</div>
				</>
			)}

			{/* 액션 버튼 */}
			<div className="button clickable reviewModify" onClick={(e) => handlerClickModifyReview(e)}>
				{textInfo.button.modify}
			</div>
			{/* <div className="button clickable reviewDelete" onClick={(e) => handlerClickDeleteReview(e)}>
				{textInfo.button.delete}
			</div> */}
		</>
	);
};

export default ReviewCard;
