/**
 * 리뷰 별점 그리기
 */

import { useEffect, useRef, useState } from "react";

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { REVIEW_SCORE_MAX, REVIEW_SCORE_MIN, REVIEW_SCORE_STEP } from "src/data/constEnum";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const DrawStarScore = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const [score, setScore] = useState(0);
	const ref = {
		guide: useRef(null),
	};

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 드래그로 별점 설정하는 경우 점수 계산
	 * @param {Event} e
	 */
	const handlerChangeRange = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const ct = e.currentTarget;

		const val = Math.ceil(ct.value / REVIEW_SCORE_STEP) * REVIEW_SCORE_STEP;
		if (val < REVIEW_SCORE_MIN) {
			ref.guide.current.innerText = textInfo.review.scoreIsUnderMin.replace(
				"___min___",
				REVIEW_SCORE_MIN
			);
			return false;
		} else {
			ref.guide.current.innerText = "";
			setScore(val);
			if (props.afterDrawStarScore) props.afterDrawStarScore(val);
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 점수대로 별 그려서 반환
	 * @returns {*}
	 */
	const makeStarList = () => {
		const iLen = 5;
		let result = [];
		let className = "";
		for (let i = 0; i < iLen; i++) {
			className = "iconStar ";
			if (score - i > 0.5) {
				className += "score1";
			} else if (score - i === REVIEW_SCORE_STEP) {
				className += "score0_5";
			} else {
				className += "score0";
			}
			result.push(<li key={i} className={className}></li>);
		}
		return result;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		if (props.hasOwnProperty("value")) setScore(props.value);
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="drawStarScore">
			<ul className="starScore">{makeStarList()}</ul>
			<input
				type="range"
				min={REVIEW_SCORE_MIN}
				max={REVIEW_SCORE_MAX}
				step={REVIEW_SCORE_STEP}
				defaultValue={score}
				onChange={(e) => handlerChangeRange(e)}
				onClick={(e) => handlerChangeRange(e)}
			/>
			<div className="guide" ref={ref.guide}></div>
		</div>
	);
};

export default DrawStarScore;
