/**
 * 소개 영역
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import Image from "src/pages/component/common/image";
import { moveAppDownload } from "src/utils/linmeDevice";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const Intro = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 앱 다운로드
	 * @param {Event} e
	 */
	const handlerClickDownloadApp = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		moveAppDownload();
	};

	/**
	 * pageDown 버튼 클릭 시
	 * @param {MouseEvent} e
	 */
	const handlerClickPageDown = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		window.scrollTo({ top: window.innerHeight, behavior: "smooth" });
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className={"container intro " + (props.isMain ? "type1" : "type3")}>
			<div className="filterCover"></div>
			<div className="linmeIs">
				<div className="copy" />
			</div>
			<div
				className={"arrowPageDown " + (props.isMain ? "on" : "")}
				onClick={handlerClickPageDown}
			/>
			<div
				className={"downloadApp " + (props.isMain ? "" : "on")}
				onClick={handlerClickDownloadApp}
			>
				{textInfo.button.installApp}
			</div>
		</div>
	);
};

export default Intro;
