/**
 * 개발용 API ( 삭제 예정 )
 */

import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/**
 * 로그인
 * @param {Object} d
 * <code>
 * 		email: ""
 * 		, pw: ""
 * </code>
 */
export const devSignIn = async (d) => {
	const url = `${API_PATHS.DEV_____MEMBER_SIGN_IN}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 개발용 회원 삭제 ( 삭제 예정 )
 * @param {Object} d
 * <code>
 * {
 * 		email: ""
 * }
 * </code>
 */
export const devDeleteMember = async (d) => {
	const url = `${API_PATHS.DEV_____MEMBER_DELETE}`;
	try {
		const result = await api.delete(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 결제완료 상태의 더미데이터 생성
 * @param {Object} d
 * <code>
 * {
 * 		optionsList: [
 *	 		{
 * 				optionCategoryNo: 0
 * 				, optionNo: 0
 * 				, quantity: 0
 * 			}
 * 			, {
 * 				optionCategoryNo: 0
 * 				, optionNo: 0
 * 				, quantity: 0
 * 			}
 * 			, ...
 * 		]
 * 		, productNo: 0
 * }
 * </code>
 * @returns
 */
export const devMakeOrderSample = async (d) => {
	const url = `${API_PATHS.DEV_____ORDER_SAMPLE}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 주문건에 대하여 배송완료 처리
 * @param {Object} d
 * <code>
 * {
 * 		invoiceCode: ""
 * 		, orderNo: 0
 * }
 * </code>
 */
export const devMakeStatusOrderDeliveryComplete = async (d) => {
	const url = `${API_PATHS.DEV_____ORDER_STATUS_DELIVERY_COMPLETE}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
