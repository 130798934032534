/**
 * 쇼핑내역 - 주문/배송
 */
import { useEffect, useState } from "react";
import useInfiniteScroll from "src/hooks/useInfiniteScroll";
import { getMyOrderList } from "src/api/myShopping/order";
import { OrderCard, OrderNoData } from "src/pages/component/order";
import { PAGINATION_DEFAULT_OFFSET } from "src/data";

import "src/styles/myPage/order/orderList.scss";
import InfiniteTrigger from "../infiniteTrigger";

const OrderList = () => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;
	const [orderList, setOrderList] = useState([]);

	const infiniteScroll = useInfiniteScroll({
		fetchFn: getMyOrderList,
		param: { offset: PAGINATION_DEFAULT_OFFSET.DEFAULT },
		setDataList: setOrderList,
	});

	/**
	 * 특정 페이지 데이터 새로고침
	 * @param {object} pageNo
	 */
	const refetchOrderData = async (item) => {
		const refetchPage = infiniteScroll.getPageNumber(orderList, "orderNo", item?.orderNo);
		await infiniteScroll.refetch(refetchPage);
	};

	//최초 실행;
	useEffect(() => {
		infiniteScroll.setInitData();
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<ul className={`orderList on ${!orderList.length ? "noOrder" : ""}`}>
			{orderList && orderList.length ? (
				<>
					{orderList?.map((order) => (
						<OrderCard key={order.orderNo} order={order} refetchOrderData={refetchOrderData} />
					))}
					{!infiniteScroll.isLastPage && <InfiniteTrigger ref={infiniteScroll.triggerRef} />}
				</>
			) : (
				<OrderNoData />
			)}
		</ul>
	);
};

export default OrderList;
