import { memo } from "react";
import { Link } from "react-router-dom";
import pathJSON from "src/path/pathJSON.json";

import styles from "src/styles/product/recommendProductItem.module.scss";

const RecommendProductItem = (props) => {
	const { productId, imageUrl, productName } = props;

	return (
		<Link to={pathJSON.product.detail + productId}>
			<div className={styles.productContainer}>
				<img className={styles.productImage} src={imageUrl} alt={productName} />
			</div>
		</Link>
	);
};

export default memo(RecommendProductItem);
