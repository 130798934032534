/**
 * 리뷰 아바타
 */

import { EMPTY_USER_PROFILE_IMAGE } from "src/data/constEnum";
import Image from "src/pages/component/common/image";

import "src/styles/review/reviewCard.scss";

const Avatar = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const { url, alt } = props;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	const getSecureUrl = (url) => {
		if (!url) return url;
		return url?.startsWith("http://") ? url?.replace("http://", "https://") : url;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="userProfile">
			<Image src={url ? getSecureUrl(url) : EMPTY_USER_PROFILE_IMAGE} alt={alt || ""} />
		</div>
	);
};

export default Avatar;
