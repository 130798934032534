/**
 * 주문 취소 상세 보기 모달
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";

import useModalStack from "src/hooks/useModalStack";

import { orderCancelDetail } from "src/api/myShopping/cancel";
import { API_RESULT_STATUS, PAYMENT_METHOD_TYPE } from "src/data/constEnum";
import { removeInnerSpinner, setInnerSpinner } from "src/utils/linmeDisplay";
import ModalCommonAlert from "src/pages/common/modalAlert";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/claim/orderCancelDetail.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

const htmlInfo = require("src/data/htmlInfo.json");
const textInfo = require("src/data/textInfo.json");

const OrderCancelDetail = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();

	const [data, setData] = useState(null);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 주문번호로 취소 정보 호출
	 * @param {Number} orderNo
	 * @returns
	 */
	const requestOrderCancelDetail = async (claimNo) => {
		try {
			await setInnerSpinner();
			const result = await orderCancelDetail(claimNo);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				await setData(result.data);
				return result.data;
			} else {
				window.linmeLog.log(result);
				modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					html: result?.response?.data?.message || htmlInfo.error.dataCall,
				});
				return false;
			}
		} catch (err) {
			window.linmeLog.error(err);
			modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				html: err?.response?.data?.message || htmlInfo.error.dataCall,
			});
			return false;
		} finally {
			await removeInnerSpinner();
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		const getData = async () => {
			if (props.claimNo) {
				const claimNo = props.claimNo;
				await requestOrderCancelDetail(claimNo);
			}
		};
		getData();
	}, [props.claimNo]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="orderCancelDetailContainer">
			<div className="title">
				{textInfo.claim.title.cancel}&nbsp;{textInfo.claim.detail}
			</div>
			<ul className="reasonInfo">
				<li className="reasonInfoItem title">
					<div className="label">{textInfo.orderCancel.orderCancelReason}</div>
					<div className="data">{data && data.reason ? data.reason : "-"}</div>
				</li>
				<li className="reasonInfoItem reasonDetail">
					<div className="label">{textInfo.orderCancel.orderCancelReasonDetail}</div>
					<div className="data">{data && data.content ? data.content : "-"}</div>
				</li>
			</ul>
			<ul className="priceList">
				<li className="priceItem">
					<div className="label">{textInfo.claim.price.recoveryPrice}</div>
					<div className="data">
						{data && data.price
							? (Math.abs(data.price.directPrice) + Math.abs(data.price.tobePrice)).toLocaleString()
							: 0}
						<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
					</div>
				</li>
				<li className="priceItem">
					<div className="label">{textInfo.payment.reward.title}</div>
					<div className="data">
						{data && data.price
							? (
									Math.abs(data.price.directReward) + Math.abs(data.price.tobeReward)
							  ).toLocaleString()
							: 0}
						<span className="rewardUnit">{textInfo.common.rewardUnit}</span>
					</div>
				</li>
				<li className="priceItem">
					<div className="label">{textInfo.claim.price.usedCoupon}</div>
					<div className="data couponName">
						{data && data.price && data.price?.isCheckTobeCoupon && data.price?.coupon ? (
							<>
								<span>{data.price?.coupon?.name}</span>
								{data.price?.isCheckTobeCouponAvailable && (
									<span className="recoveryPoint">
										{textInfo.orderCancel.isCheckTobeCouponAvailable}
									</span>
								)}
							</>
						) : (
							<>{textInfo.common.noExists}</>
						)}
					</div>
				</li>
			</ul>
			<ul className="paymentInfoList">
				<li className="paymentInfoItem title">
					<div className="label">{textInfo.claim.restore.title}</div>
				</li>
				<li className="paymentInfoItem">
					<div className="label">{textInfo.claim.restore.method}</div>
					<div className="data">
						{data && data.payment && data.payment.method === PAYMENT_METHOD_TYPE.CARD ? (
							<>
								<span>
									{textInfo.payment.payMethod.creditCard} {textInfo.order.payment.pay}
								</span>
								<span>
									{data.payment.cardInfo.issuer} ({data.payment.cardInfo.cardNo}) |{" "}
									{data.payment.cardInfo.installment === 0
										? textInfo.payment.paid.card.once
										: data.payment.cardInfo.installment + textInfo.payment.paid.card.installment}
								</span>
							</>
						) : (
							<></>
						)}
						{data && data.payment && data.payment.method === PAYMENT_METHOD_TYPE.REWARD ? (
							<>
								<span>{textInfo.payment.payMethod.reward}</span>
							</>
						) : (
							<></>
						)}
					</div>
				</li>
			</ul>
		</div>
	);
};

export default OrderCancelDetail;
