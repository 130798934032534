/**
 * 상품 상세 - 간략 정보
 */

import { useEffect, useState, memo } from "react";

import { useNavigate } from "react-router-dom";
import { devMakeOrderSample } from "src/api/dev";
import { addToCart } from "src/api/order/cart";
import { createOrderProduct } from "src/api/order/order";
import { checkProductStock } from "src/api/product/detail";
import { API_RESULT_STATUS, ORDER_TYPE, PRODUCT_OPTIONS_TYPE } from "src/data/constEnum";
import useAuth from "src/hooks/useAuth";
import useModalStack from "src/hooks/useModalStack";

import ModalCommonAlert from "src/pages/common/modalAlert";
import ProductDetailImagePaging from "src/pages/component/carousel/productDetailImagePaging";
import ProductDetailOptions from "src/pages/component/product/productDetailOptions";
import ProductDetailSelectedOptions from "src/pages/component/product/productDetailSelectedOptions";
import AdditionalInformation from "src/pages/member/modal/additionalInformation";
import ModalLogin from "src/pages/member/modal/modalLogin";
import RequiredNotationInformation from "src/pages/product/modal/requiredNotationInformation";
import ProductDetailInfoReview from "src/pages/component/product/detail/productDetailInfoReview";
import { sendNaverAddToCart } from "src/utils/linmeNaverScript";
import FixedPurchaseButton from "./detail/fixedPurchaseButton";
import { ProductBuyInfo } from "./detail/productByInfoList";
import useInView from "src/hooks/useInView";

const pathList = require("src/path/pathJSON.json");
const htmlInfo = require("src/data/htmlInfo.json");
const textInfo = require("src/data/textInfo.json");

const ProductDetailInfo = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const auth = useAuth();
	const modalStack = useModalStack();
	const navigate = useNavigate();

	const [layerShare, setLayerShare] = useState(false);
	const isSingleOption = props?.optionCategory?.[0]?.values.length < 1; // 옵션 설정 여부
	const [optionCount, setOptionCount] = useState(1); // 옵션 미설정 시 상품 개수
	const [selectedResultRequired, setSelectedResultRequired] = useState([]); // 선택한 필수 옵션
	const [selectedResultAdditional, setSelectedResultAdditional] = useState([]); // 선택한 추가 옵션
	const [totalSelectedProductPrice, setTotalSelectedProductPrice] = useState(0); // 상품금액 합계
	const [ref, isVisible] = useInView({
		threshold: 0.01,
	});
	const [isBottomPurchaseButtonOpen, setIsBottomPurchaseButtonOpen] = useState(false);

	const modalId = {
		afterAddToCart: "modalCommonConfirm",
		additionalInformation: "modalAdditionalInformation",
		login: "modalLogin",
		requiredNotationInformation: "modalRequiredNotationInformation",
		validation: "modalCommonAlert",
	};

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	const afterLogin = async () => {
		await validation();
	};

	/**
	 * 장바구니 담기
	 * @param {Event} e
	 */
	const handlerClickAddCart = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		// 로그인 체크;
		if (!auth.isLogin) {
			await modalStack.addModal({
				id: modalId.login,
				type: "alert",
				component: <ModalLogin />,
				button: {
					iconClose: true,
				},
				afterLogin: afterLogin,
			});
			return false;
		}

		// validation
		if (!(await validation())) {
			return false;
		}

		await requestAddToCart().then(async (data) => {
			if (data.cartNoList && data.cartNoList.length > 0) {
				// 네이버 전환 스크립트 (장바구니 담기);
				sendNaverAddToCart({
					totalPrice: totalSelectedProductPrice,
					productNo: props.productNo,
					productName: props.name,
					quantity:
						selectedResultRequired.reduce((acc, item) => acc + item.quantity, 0) || optionCount,
				});

				await modalStack.addModal({
					id: modalId.afterAddToCart,
					type: "confirm",
					component: <ModalCommonAlert />,
					html: htmlInfo.product.afterAddToCart,
					button: {
						iconClose: false,
						confirm: {
							text: textInfo.button.move,
							fn: handlerClickModalCheckCartConfirm,
						},
						cancel: {
							text: textInfo.button.close,
							fn: handlerClickModalCheckCartClose,
						},
					},
				});
			} //end if;
			if (data.hasOwnProperty("count")) auth.modifyUserData({ cartCount: data.count });
		});
	};

	/**
	 * 바로 구매
	 * @param {Event} e
	 */
	const handlerClickBuyNow = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		// 로그인 체크;
		if (!auth.isLogin) {
			await modalStack.addModal({
				id: modalId.login,
				type: "alert",
				component: <ModalLogin />,
				button: {
					iconClose: true,
				},
			});
			return false;
		}

		// validation
		if (!(await validation())) {
			return false;
		}

		//재고 확인;
		const checkStock = await requestCheckProductStock();
		if (checkStock && checkStock.isCheckBuy) {
			//주문서 작성;
			await requestCreateOrderProduct();
		} else {
			// 재고 없음
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.noAvailableStock,
			});
		}
	};

	/**
	 * 주문서 샘플 ( 결제 완료 상태 ) 만들기
	 * @param {Event} e
	 * @returns
	 */
	const handlerClickMakeOrderSample = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (!auth.isLogin) {
			await modalStack.addModal({
				id: modalId.login,
				type: "alert",
				component: <ModalLogin />,
				button: {
					iconClose: true,
				},
			});
			return false;
		}

		if (!(await validation())) {
			return false;
		}

		//재고 확인;
		const checkStock = await requestCheckProductStock();
		if (checkStock && checkStock.isCheckBuy) {
			const optionList = await makeOptionListForOrder();
			let d = {
				productNo: props.productNo,
				optionList: [],
			};
			if (!optionList) return false;
			else {
				if (optionList.required && optionList.required.length) d.optionList = optionList.required;
				if (optionList.additional && optionList.additional.length)
					d.optionList = d.optionList.concat(optionList.additional);
			}
			// 샘플 만들기;
			const result = await devMakeOrderSample(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				const data = result.data;
				modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					text: "샘플 생성 완료.",
				});
				return data;
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} else {
			// 재고 없음
			modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				text: textInfo.alert.noAvailableStock,
			});
		}
	};

	/**
	 * 장바구니 담은 이후 "닫기" 클릭 시
	 * @param {Event} e
	 */
	const handlerClickModalCheckCartClose = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.removeModal(modalId.afterAddToCart);
	};

	/**
	 * 장바구니 담은 이후 "이동" 클릭 시
	 * @param {Event} e
	 */
	const handlerClickModalCheckCartConfirm = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.removeModal(modalId.afterAddToCart);
		navigate(pathList.cart);
	};

	/**
	 * 필수표기정보 더 보기
	 * @param {Event} e
	 */
	const handlerClickOpenModalRequiredNotationInformation = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.addModal({
			id: modalId.requiredNotationInformation,
			type: "alert",
			component: <RequiredNotationInformation {...props} />,
		});
	};

	/**
	 * 공유 버튼 toggle
	 * @param {Event} e
	 */
	const handleClickProductShare = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		setLayerShare(!layerShare);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 합계 금액 계산
	 * 옵션 미설정 : 상품 할인 금액 * 상품수량
	 * 필수옵션 : ( 상품금액 + 옵션별 추가금 ) * 상품수량
	 * 선택옵션 : 옵션별 추가금 * 상품수량
	 */
	const calcTotalPrice = async () => {
		if (isSingleOption) {
			//옵션 미설정;
			const resultPrice = props.salePrice * optionCount;
			setTotalSelectedProductPrice(resultPrice || 0);
		} else {
			//필수옵션, 선택옵션;
			const requiredTotal =
				selectedResultRequired?.reduce((acc, curr) => acc + curr.totalPrice, 0) || 0;
			const additionalTotal =
				selectedResultAdditional?.reduce((acc, curr) => acc + curr.totalPrice, 0) || 0;
			const resultPrice = requiredTotal + additionalTotal;
			setTotalSelectedProductPrice(resultPrice || 0);
		}
	};

	/**
	 * 택배사명 반환
	 * @returns {String}
	 */
	const getLogisticsName = () => {
		let logistics = "";
		if (props && props.address && props.address.length > 0) {
			const a = props.address;
			if (a && a.length > 0) {
				const result = a.filter((logistic) => logistic.type === "DEPT");
				if (result.length === 1) logistics = result[0].companyName;
			}
		}
		return logistics;
	};

	/**
	 * 장바구니 담기 / 바로구매에 사용할 옵션 정보 생성하여 반환
	 * @returns {Object}
	 */
	const makeOptionListForOrder = () => {
		// 옵션 미설정
		const makeSingleOption = () => {
			const result = {
				required: [{ optionNo: props?.options?.[0]?.optionNo, quantity: optionCount }],
			};
			return result;
		};

		// 필수옵션, 선택옵션
		const makeMultipleOption = () => {
			const required = selectedResultRequired?.map((item) => ({
				optionNo: item.optionNo,
				quantity: item.quantity,
			}));
			const additional = selectedResultAdditional?.map((item) => ({
				optionNo: item.optionNo,
				quantity: item.quantity,
			}));
			const result = { required: required, additional: additional };
			return result;
		};

		return isSingleOption ? makeSingleOption() : makeMultipleOption();
	};

	/**
	 * 장바구니 담기 요청
	 */
	const requestAddToCart = async () => {
		const optionList = await makeOptionListForOrder();
		if (!optionList) return false;

		try {
			const d = {
				productNo: props.productNo,
				required: optionList.required,
				additional: optionList.additional,
			};
			const result = await addToCart(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				const data = result.data;
				//사용자 장바구니 개수 수정;
				auth.modifyUserData({ cartCount: result.data.count });
				return data;
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	/**
	 * 상품 재고 확인;
	 * @returns
	 */
	const requestCheckProductStock = async () => {
		const optionList = await makeOptionListForOrder();
		let d = {
			options: [],
		};
		if (!optionList) return false;
		else {
			if (optionList.required && optionList.required.length) d.options = optionList.required;
			if (optionList.additional && optionList.additional.length)
				d.options = d.options.concat(optionList.additional);
		}
		if (!d.options.length) return false;

		try {
			const result = await checkProductStock(props.productNo, d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				const data = result.data;
				return data;
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	/**
	 * 주문서 생성
	 * @returns
	 */
	const requestCreateOrderProduct = async () => {
		const optionList = await makeOptionListForOrder();
		let d = {
			type: ORDER_TYPE.PRODUCT,
			productNo: props.productNo,
			optionList: [],
		};
		if (!optionList) return false;
		else {
			if (optionList.required && optionList.required.length) d.optionList = optionList.required;
			if (optionList.additional && optionList.additional.length)
				d.optionList = d.optionList.concat(optionList.additional);
		}

		try {
			const result = await createOrderProduct(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				const data = result.data;
				await navigate(pathList.payment.index, {
					state: { data: result.data, from: ORDER_TYPE.PRODUCT },
				});
				return data;
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	/**
	 * 장바구니 담기 / 바로 구매 validation
	 * @returns
	 */
	const validation = async () => {
		// 필수 옵션이 존재할때 선택한 필수 옵션이 있는지 확인;
		const isRequired = props?.optionCategory?.some(
			(option) => option.type?.toUpperCase() === PRODUCT_OPTIONS_TYPE?.REQUIRED
		);
		if (!isSingleOption && isRequired && selectedResultRequired.length < 1) {
			await modalStack.addModal({
				id: modalId.validation,
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.pleaseSelectOptionRequired,
			});
			return false;
		}
		//로그인한 회원의 경우 필수 정보 여부 확인;
		if (!auth.user.isBaseInfo) {
			await modalStack.addModal({
				id: modalId.additionalInformation,
				type: "alert",
				component: <AdditionalInformation />,
			});
			return false;
		}
		return true;
	};

	// 옵션 정보 변경 시 합계 금액 재계산;
	useEffect(() => {
		calcTotalPrice();
	}, [props, optionCount, selectedResultRequired, selectedResultAdditional]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<>
			<div className="productDetailInfo">
				{/* 상품 이미지 */}
				<ProductDetailImagePaging {...props} showPageNumber />
				{/* 타임특가 타이머 */}
				{/* {props && props.topicItem && props.topicItem.startAt && props.topicItem.endAt && ( */}
				{/* // )} */}
				{/* 상품 정보 */}
				<div className="productInfoCover" ref={ref}>
					<div className="productBasicInfo">
						{/* <div className="shareAndLikeCover">
						<div className="productShare" onClick={(e) => handleClickProductShare(e)}></div>
						<ProductShare
							{...{
								isShow: layerShare,
								handlerToggleClose: handleClickProductShare,
								productName: props.name,
								url: window.location.href,
							}}
						/>
						<div className={"productLike " + (props.isCheckLike ? "on" : "")}></div>
					</div> */}
						<ul className="productInfoList">
							<li className="productInfoItem brandName">{props.brand.name}</li>
							<li className="productInfoItem productName">{props.name}</li>
							<ProductDetailInfoReview
								averageRate={props.review.averageRate}
								count={props.review.count}
							/>
							{props.salePercent > 0 && (
								<li className="productInfoItem productPriceInfo">
									<span className="discountRate">
										{props.salePercent.toLocaleString()}
										<span>{textInfo.common.percentUnit}</span>
									</span>
									<span className="price">{props.price.toLocaleString()}</span>
								</li>
							)}

							<li className="productInfoItem salePrice">
								{props.salePrice.toLocaleString()}
								<span>{textInfo.common.currencyUnit}</span>
							</li>
							{auth.isLogin && props.reward ? (
								<li className={"productInfoItem rewardInfo " + (props.reward ? "on" : "")}>
									<span>
										{textInfo.product.detail.reward}{" "}
										<span>
											{props.reward.totalReward.toLocaleString()}
											{textInfo.common.rewardUnit}
										</span>
									</span>
									&nbsp;{textInfo.product.detail.rewardBenefit}
								</li>
							) : (
								<></>
							)}
						</ul>
						{/* <div className="shareAndLikeCover">
						<div className="productShare" onClick={(e) => handleClickProductShare(e)}></div>
						<ProductShare
							{...{
								isShow: layerShare,
								handlerToggleClose: handleClickProductShare,
								productName: props.name,
								url: window.location.href,
							}}
						/>
						<div className={"productLike " + (props.isCheckLike ? "on" : "")}></div>
					</div> */}
						<ul className="productInfoBuyConditionList">
							{/* 배송정보 */}
							<li className="productInfoBuyConditionItem deliveryInfo">
								<div className="label">{textInfo.product.detail.deliveryInfo}</div>
								<div className="content">
									<div className="deliveryBasic">
										<div className="deliveryPrice">
											{props.delivery?.deliveryFeeType === "FREE" ? (
												textInfo.payment.deliveryInfo.free
											) : (
												<>
													<span>{props.delivery?.deliveryPrice.toLocaleString()}</span>
													{textInfo.common.currencyUnit}
												</>
											)}
										</div>
										<div className="division">|</div>
										<div className="logistics">{getLogisticsName()}</div>
									</div>
									{/* 2권역 배송비 */}
									{props?.delivery?.deliveryPriceDepth2 > 0 &&
										props?.delivery?.deliveryPriceDepth3 === 0 && (
											<div className="deliveryFreeCondition">{`${
												textInfo.product.detail.deliveryPriceDepth2
											} ${props.delivery?.deliveryPriceDepth2?.toLocaleString()}${
												textInfo.common.currencyUnit
											}`}</div>
										)}
									{/* 3권역 배송비 */}
									{props?.delivery?.deliveryPriceDepth2 > 0 &&
										props?.delivery?.deliveryPriceDepth3 > 0 && (
											<div className="deliveryFreeCondition">{`${
												textInfo.product.detail.deliveryPriceDepth3desc1
											} ${props.delivery?.deliveryPriceDepth2?.toLocaleString()}${
												textInfo.common.currencyUnit
											}, ${
												textInfo.product.detail.deliveryPriceDepth3desc2
											} ${props.delivery?.deliveryPriceDepth3?.toLocaleString()}${
												textInfo.common.currencyUnit
											}`}</div>
										)}
									{/* 배송비 조건 */}
									<div className="deliveryFreeCondition">
										{props.delivery?.deliveryFeeType === "FREEBYTOTAMT" &&
											`${props.delivery?.totalAmountForFree?.toLocaleString()}${
												textInfo.common.currencyUnit
											} ${textInfo.payment.deliveryInfo.freeByAmount}`}
										{props.delivery?.deliveryFeeType === "CHARGEDBYQTY" &&
											`${props.delivery?.quantityByFee?.toLocaleString()}${
												textInfo.payment.deliveryInfo.chargeByQty
											} (${props.delivery?.deliveryPrice.toLocaleString()}${
												textInfo.common.currencyUnit
											})`}
									</div>
								</div>
							</li>
							{/* 필수 표기 정보 */}
							<li className="productInfoBuyConditionItem detailInfo">
								{/*
							<div className="label">상품정보</div>
							<div className="content">
								<ul className="productDetailInfoList">
									<li className="productDetailInfoItem">영양제 기능: 혈액순환</li>
									<li className="productDetailInfoItem">타입(형태): 정</li>
									<li className="productDetailInfoItem">총 수량: 90정/1통</li>
									<li className="productDetailInfoItem">상품 번호: 2000153115</li>
									<li className="productDetailInfoItem">유통기한: 20241101</li>
									<li className="productDetailInfoItem"><button onClick={(e)=>handlerClickOpenModalRequiredNotationInformation(e)}>필수 표기 정보</button></li>
								</ul>
							</div>
							*/}
								<button
									className="requiredNotationInformation"
									onClick={(e) => handlerClickOpenModalRequiredNotationInformation(e)}
								>
									{textInfo.button.requiredNotationInformation}
								</button>
							</li>
						</ul>
						{/* 옵션 선택 */}
						<ProductDetailOptions
							data={props}
							selectedResultRequired={selectedResultRequired}
							setSelectedResultRequired={setSelectedResultRequired}
							selectedResultAdditional={selectedResultAdditional}
							setSelectedResultAdditional={setSelectedResultAdditional}
							optionCount={optionCount}
							setOptionCount={setOptionCount}
							isSingleOption={isSingleOption}
						/>
						{/* 선택한 옵션 목록 ( 필수,선택 ) */}
						<ProductDetailSelectedOptions
							data={props}
							selectedResultRequired={selectedResultRequired}
							setSelectedResultRequired={setSelectedResultRequired}
							selectedResultAdditional={selectedResultAdditional}
							setSelectedResultAdditional={setSelectedResultAdditional}
						/>
					</div>
					<ProductBuyInfo status={props.status}>
						<ProductBuyInfo.TotalPrice totalSelectedProductPrice={totalSelectedProductPrice} />
						<ProductBuyInfo.TotalPriceDivider />
						<ProductBuyInfo.BuyOptions
							onAddToCart={handlerClickAddCart}
							onBuyNow={handlerClickBuyNow}
						/>
					</ProductBuyInfo>

					{/* <ProductByInfoList
						status={props.status}
						totalSelectedProductPrice={totalSelectedProductPrice}
						handlerClickAddCart={handlerClickAddCart}
						handlerClickBuyNow={handlerClickBuyNow}
					/> */}
				</div>
				{/* 하단 구매 버튼 */}
				{!isVisible && (
					<FixedPurchaseButton
						isOpen={isBottomPurchaseButtonOpen}
						handleOpen={setIsBottomPurchaseButtonOpen}
					>
						<ProductDetailOptions
							data={props}
							selectedResultRequired={selectedResultRequired}
							setSelectedResultRequired={setSelectedResultRequired}
							selectedResultAdditional={selectedResultAdditional}
							setSelectedResultAdditional={setSelectedResultAdditional}
							optionCount={optionCount}
							setOptionCount={setOptionCount}
							isSingleOption={isSingleOption}
							isReverse
						/>
						{/* 선택한 옵션 목록 ( 필수,선택 ) */}
						<ProductDetailSelectedOptions
							data={props}
							selectedResultRequired={selectedResultRequired}
							setSelectedResultRequired={setSelectedResultRequired}
							selectedResultAdditional={selectedResultAdditional}
							setSelectedResultAdditional={setSelectedResultAdditional}
						/>
						<ProductBuyInfo status={props.status}>
							<ProductBuyInfo.TotalPriceDivider />
							<ProductBuyInfo.BottomInfoContainer>
								<ProductBuyInfo.BuyOptions
									onAddToCart={handlerClickAddCart}
									onBuyNow={handlerClickBuyNow}
								/>
								<ProductBuyInfo.TotalPrice totalSelectedProductPrice={totalSelectedProductPrice} />
							</ProductBuyInfo.BottomInfoContainer>
						</ProductBuyInfo>
					</FixedPurchaseButton>
				)}
			</div>
		</>
	);
};

export default memo(ProductDetailInfo);
