/**
 * 뒤로가기 버튼 ( 모바일 )
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AppContext } from "src/App";
import { DEVICE_TYPE } from "src/data/constEnum";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/moveToBack.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

// const htmlInfo = require( "src/data/htmlInfo.json" );
// const textInfo = require( "src/data/textInfo.json" );

const MoveToBack = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const { pathname } = useLocation();
	const navigate = useNavigate();
	const { device } = useContext(AppContext);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 뒤로가기 화살표 클릭 시 ( 모바일 )
	 * @param {Event} e
	 */
	const handlerClickMoveToBack = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (props.handlerClickMoveToBack) props.handlerClickMoveToBack(e); // 커스텀 함수가 있다면 적용
		else navigate(-1); // 없다면 뒤로 가기
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 버튼 그리기
	 * @returns
	 */
	const drawButton = () => {
		if (device !== DEVICE_TYPE.PC) {
			if (pathname === pathList.myPage.level) {
				return <div className="moveToBack" onClick={(e) => handlerClickMoveToBack(e)} />;
			}
			if (pathname === pathList.myPage.myInfo) {
				return <div className="moveToBack" onClick={(e) => handlerClickMoveToBack(e)} />;
			}
			if (pathname.indexOf(pathList.myPage.index) > -1) {
				return <div className="moveToBack" onClick={(e) => handlerClickMoveToBack(e)} />;
			}
			if (pathname.indexOf(pathList.reward.detail) > -1) {
				return <div className="moveToBack" onClick={(e) => handlerClickMoveToBack(e)} />;
			}
		}
		if (device !== DEVICE_TYPE.TABLET && pathname.indexOf(pathList.reward.index) > -1) {
			return <div className="moveToBack" onClick={(e) => handlerClickMoveToBack(e)} />;
		}
		return null;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return <>{drawButton()}</>;
};

export default MoveToBack;
