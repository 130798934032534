import { API_RESULT_STATUS, REG_EXP } from "src/data/constEnum";
import { duplicateCheckNickname, duplicateCheckCi } from "src/api/member/signUp";
const textInfo = require("src/data/textInfo.json");

/**
 * linme 회원 관련 함수
 */

// ----------------------------------------------------------------------------------------------------;
// 공통;
// ----------------------------------------------------------------------------------------------------;

/**
 * 필수입력항목 onBlur
 * @param {Event} e
 * @returns
 */
export const handlerBlurRequired = (e) => {
	//버블링 막기;
	e.preventDefault();
	e.stopPropagation();

	const ct = e.currentTarget;
	const elUserInput = ct.closest(".userInput");
	const elGuideList = elUserInput.querySelectorAll(".inputGuide");
	const elGuideWarn = elUserInput.querySelector(".inputGuideWarn");
	if (ct.value.length === 0) {
		elGuideList.forEach((element) => {
			element.classList.add("off");
		});
		elGuideWarn.innerText = textInfo.member.warn.common.required;
		return false;
	}
};

/**
 * 본인인증 ci 중복체크
 * @param {String} ci
 * @returns
 */
export const getDuplicateCheckCi = async (ci) => {
	try {
		const d = {
			ci: ci,
		};
		const result = await duplicateCheckCi(d);
		if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
			return result.data.isCheckExist;
		} else {
			window.linmeLog.log(result);
		}
	} catch (error) {
		window.linmeLog.log(error);
	}
};

// ----------------------------------------------------------------------------------------------------;
// 비밀번호;
// ----------------------------------------------------------------------------------------------------;

/**
 * 비밀번호 가리기 on/off
 * @param {Event} e
 */
export const handlerClickTogglePwType = (e) => {
	//버블링 막기;
	e.preventDefault();
	e.stopPropagation();

	const ct = e.currentTarget;
	const elUserInput = ct.closest(".userInput");
	const elInput = elUserInput.querySelector("input");

	if (ct.classList.contains("open")) {
		ct.classList.add("close");
		ct.classList.remove("open");
		elInput.type = "password";
	} else {
		ct.classList.add("open");
		ct.classList.remove("close");
		elInput.type = "text";
	}
};

/**
 * 비밀번호 KeyUp
 * @param {Event} e
 */
export const handlerKeyUpUserPW = (e, refPwNow, refPwNew) => {
	//버블링 막기;
	e.preventDefault();
	e.stopPropagation();

	const ct = e.currentTarget;
	const elUserInput = ct.closest(".userInput");
	const elGuideList = elUserInput.querySelectorAll(".inputGuide");
	const elGuideWarn = elUserInput.querySelector(".inputGuideWarn");
	if (ct.value.match(REG_EXP.blank)) {
		//공백 포함 시;
		elGuideList.forEach((element) => {
			element.classList.add("off");
		});
		elGuideWarn.innerText = textInfo.member.warn.common.noUseBlank;
		return false;
	}

	if (refPwNow && refPwNew) {
		if (refPwNow.current.value === refPwNew.current.value) {
			elGuideList.forEach((element) => {
				element.classList.add("off");
			});
			elGuideWarn.innerText = textInfo.myPage.changePassword.warn.equals;
			return false;
		}
	}

	if (REG_EXP.member.pw.test(ct.value)) {
		elGuideList.forEach((element) => {
			element.classList.add("off");
		});
		elGuideWarn.innerText = "";
		return true;
	} else {
		elGuideList.forEach((element) => {
			element.classList.add("off");
		});
		elGuideWarn.innerText = textInfo.member.warn.password.character;
		return false;
	}
};

/**
 * 비밀번호 확인 KeyUp
 * @param {Event} e
 */
export const handlerKeyUpUserPWCheck = (e, refPw) => {
	//버블링 막기;
	e.preventDefault();
	e.stopPropagation();

	const ct = e.currentTarget;
	const elUserInput = ct.closest(".userInput");
	const elGuideList = elUserInput.querySelectorAll(".inputGuide");
	const elGuideWarn = elUserInput.querySelector(".inputGuideWarn");
	if (ct.value.match(REG_EXP.blank)) {
		//공백 포함 시;
		elGuideList.forEach((element) => {
			element.classList.add("off");
		});
		elGuideWarn.innerText = textInfo.member.warn.common.noUseBlank;
		return false;
	}
	if (refPw.current.value === ct.value) {
		elGuideList.forEach((element) => {
			element.classList.add("off");
		});
		elGuideWarn.innerText = "";
	} else {
		elGuideList.forEach((element) => {
			element.classList.add("off");
		});
		elGuideWarn.innerText = textInfo.member.warn.password.notMatch;
	}
};

/**
 * validation 비밀번호
 * @returns
 */
export const validationPw = (ct) => {
	if (!ct) return false;
	const elUserInput = ct.closest(".userInput");
	const elGuideList = elUserInput.querySelectorAll(".inputGuide");
	const elGuideWarn = elUserInput.querySelector(".inputGuideWarn");
	if (ct.value.length === 0) {
		elGuideList.forEach((element) => {
			element.classList.add("off");
		});
		elGuideWarn.innerText = textInfo.member.warn.common.required;
		return false;
	}

	if (ct.value.match(REG_EXP.blank)) {
		//공백 포함 시;
		elGuideList.forEach((element) => {
			element.classList.add("off");
		});
		elGuideWarn.innerText = textInfo.member.warn.common.noUseBlank;
		return false;
	}

	if (!REG_EXP.member.pw.test(ct.value)) {
		elGuideList.forEach((element) => {
			element.classList.add("off");
		});
		elGuideWarn.innerText = textInfo.member.warn.password.character;
		return false;
	}
	return true;
};

/**
 * validation 비밀번호 확인
 * @returns
 */
export const validationPwCheck = (ct, pw) => {
	if (!ct || !pw) return false;
	const elUserInput = ct.closest(".userInput");
	const elGuideList = elUserInput.querySelectorAll(".inputGuide");
	const elGuideWarn = elUserInput.querySelector(".inputGuideWarn");
	if (ct.value.length === 0) {
		elGuideList.forEach((element) => {
			element.classList.add("off");
		});
		elGuideWarn.innerText = textInfo.member.warn.common.required;
		return false;
	} else if (pw.value !== ct.value) {
		elGuideList.forEach((element) => {
			element.classList.add("off");
		});
		elGuideWarn.innerText = textInfo.member.warn.password.notMatch;
		return false;
	}
	return true;
};

// ----------------------------------------------------------------------------------------------------;
// 닉네임;
// ----------------------------------------------------------------------------------------------------;

/**
 * 닉네임 중복 확인
 * @param {Event} e
 */
export const handlerClickDuplicateCheckNickname = async (
	e,
	refNickname,
	refNicknameCheck,
	refButtonApply,
	afterCheckTrue
) => {
	//버블링 막기;
	e.preventDefault();
	e.stopPropagation();

	const ct = refNickname.current;
	const elUserInput = ct.closest(".userInput");
	const elGuideList = elUserInput.querySelectorAll(".inputGuide");
	const elGuideWarn = elUserInput.querySelector(".inputGuideWarn");
	if (ct.value.length === 0) {
		elGuideList.forEach((element) => {
			element.classList.add("off");
		});
		elGuideWarn.innerText = textInfo.member.warn.common.required;
		return false;
	}

	if (ct.value.match(REG_EXP.blank)) {
		//공백 포함 시;
		elGuideList.forEach((element) => {
			element.classList.add("off");
		});
		elGuideWarn.innerText = textInfo.member.warn.common.noUseBlank;
		return false;
	}

	if (!REG_EXP.member.nickname.test(ct.value)) {
		elGuideList.forEach((element) => {
			element.classList.add("off");
		});
		elGuideWarn.innerText = textInfo.member.warn.nickname.character;
		return false;
	}

	elGuideList.forEach((element) => {
		element.classList.add("off");
	});
	elGuideWarn.innerText = "";
	const elGuideChecked = elUserInput.querySelector(".inputGuideChecked");
	elGuideChecked.innerText = "";
	await getDuplicateCheckNickname(refNickname).then((isExist) => {
		if (isExist) {
			elGuideWarn.innerText = textInfo.member.warn.nickname.inUse;
			refNicknameCheck.current.value = false;
			if (refButtonApply) {
				refButtonApply.current.classList.remove("on");
			}
		} else {
			elGuideChecked.classList.remove("off");
			elGuideChecked.innerText = textInfo.member.checked.nickname.available;
			refNicknameCheck.current.value = true;
			if (refButtonApply) {
				refButtonApply.current.classList.add("on");
			}
			if (afterCheckTrue) afterCheckTrue();
		}
	});
};

/**
 * 닉네임 KeyUp
 * @param {Event} e
 */
export const handlerKeyUpNickname = (e, refNicknameCheck, refButtonApply, afterCheck) => {
	//버블링 막기;
	e.preventDefault();
	e.stopPropagation();

	refNicknameCheck.current.value = false;
	if (afterCheck) afterCheck();
	if (refButtonApply) refButtonApply.current.classList.remove("on");

	const ct = e.currentTarget;
	const elUserInput = ct.closest(".userInput");
	const elGuideList = elUserInput.querySelectorAll(".inputGuide");
	const elGuideWarn = elUserInput.querySelector(".inputGuideWarn");
	const elInputGuideChecked = elUserInput.querySelector(".inputGuideChecked");

	if (ct.value.match(REG_EXP.blank)) {
		//공백 포함 시;
		elGuideList.forEach((element) => {
			element.classList.add("off");
		});
		elInputGuideChecked.classList.add("off");
		elGuideWarn.innerText = textInfo.member.warn.common.noUseBlank;
		return false;
	}

	if (REG_EXP.member.nickname.test(ct.value)) {
		elGuideList.forEach((element) => {
			element.classList.add("off");
		});
		elInputGuideChecked.classList.add("off");
		elGuideWarn.innerText = textInfo.member.warn.nickname.checkDuplicate;
		return false;
	} else {
		elGuideList.forEach((element) => {
			element.classList.add("off");
		});
		elInputGuideChecked.classList.add("off");
		elGuideWarn.innerText = textInfo.member.warn.nickname.character;
		return false;
	}
};

/**
 * validation 닉네임 확인
 * @returns
 */
export const validationNickname = (refNickname, refNicknameCheck) => {
	const ct = refNickname.current;
	const elUserInput = ct.closest(".userInput");
	const elGuideList = elUserInput.querySelectorAll(".inputGuide");
	const elGuideWarn = elUserInput.querySelector(".inputGuideWarn");
	if (ct.value.length === 0) {
		elGuideList.forEach((element) => {
			element.classList.add("off");
		});
		elGuideWarn.innerText = textInfo.member.warn.common.required;
		return false;
	}

	if (refNicknameCheck.current.value !== "true") {
		elGuideList.forEach((element) => {
			element.classList.add("off");
		});
		elGuideWarn.innerText = textInfo.member.warn.nickname.checkDuplicate;
		return false;
	}
	return true;
};

/**
 * 닉네임 중복 확인 요청
 */
const getDuplicateCheckNickname = async (refNickname) => {
	try {
		const d = {
			nickname: refNickname.current.value,
		};
		const result = await duplicateCheckNickname(d);
		if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
			return result.data.isCheckExist;
		} else {
			window.linmeLog.log(result);
		}
	} catch (error) {
		window.linmeLog.log(error);
	}
};

// ----------------------------------------------------------------------------------------------------;
// 연락처;
// ----------------------------------------------------------------------------------------------------;

/**
 * 연락처 KeyUp
 * @param {Event} e
 */
export const handlerKeyUpPhone = (e) => {
	//버블링 막기;
	e.preventDefault();
	e.stopPropagation();

	const ct = e.currentTarget;
	ct.value = ct.value.replace(/[^0-9]/g, "");

	const elUserInput = ct.closest(".userInput");
	const elGuideWarn = elUserInput.querySelector(".inputGuideWarn");
	if (ct.value.match(REG_EXP.blank)) {
		//공백 포함 시;
		elGuideWarn.innerText = textInfo.member.warn.common.noUseBlank;
		return false;
	}
};

/**
 * validation 휴대폰 확인
 * @param {Object} refCheckVerify
 * @returns
 */
export const validationPhone = (refCheckVerify) => {
	const ct = refCheckVerify.current;
	const elUserInput = ct.closest(".userInput");
	const elGuideList = elUserInput.querySelectorAll(".inputGuide");
	const elGuideWarn = elUserInput.querySelector(".inputGuideWarn");
	if (ct.value.length === 0) {
		elGuideList.forEach((element) => {
			element.classList.add("off");
		});
		elGuideWarn.innerText = textInfo.member.warn.verify.checkVerify;
		return false;
	}
	return true;
};

// ----------------------------------------------------------------------------------------------------;
// 주소;
// ----------------------------------------------------------------------------------------------------;

/**
 * validation 주소
 * @param {Object} refAddress1
 * @returns
 */
export const validationAddress1 = (refAddress1) => {
	const ct = refAddress1.current;
	const elUserInput = ct.closest(".userInput");
	const elGuideList = elUserInput.querySelectorAll(".inputGuide");
	const elGuideWarn = elUserInput.querySelector(".inputGuideWarn");
	if (ct.value.length === 0) {
		elGuideList.forEach((element) => {
			element.classList.add("off");
		});
		elGuideWarn.innerText = textInfo.member.warn.common.required;
		return false;
	} else {
		elGuideList.forEach((element) => {
			element.classList.add("off");
		});
		elGuideWarn.innerText = "";
	}
	return true;
};

/**
 * validation 상세주소
 * @param {Object} refAddress2
 * @returns
 */
export const validationAddress2 = (refAddress2) => {
	const ct = refAddress2.current;
	const elUserInput = ct.closest(".userInput");
	const elGuideList = elUserInput.querySelectorAll(".inputGuide");
	const elGuideWarn = elUserInput.querySelector(".inputGuideWarn");
	if (ct.value.length === 0) {
		elGuideList.forEach((element) => {
			element.classList.add("off");
		});
		elGuideWarn.innerText = textInfo.member.warn.common.required;
		return false;
	} else {
		elGuideList.forEach((element) => {
			element.classList.add("off");
		});
		elGuideWarn.innerText = "";
	}
	return true;
};
