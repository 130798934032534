/**
 * 상품 목록
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useState } from "react";

import { PRODUCT_LIST_ORDER } from "src/data/constEnum";

import ProductListOrder from "src/pages/component/product/productListOrder";
import ProductList from "src/pages/component/product/productList";
import Pagination from "src/pages/component/pagination";
import Image from "src/pages/component/common/image";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/productList.scss";
import "src/styles/product/productDisplay.scss";

function ProductDisplay() {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const [listOrderSelected, setListOrderSelected] = useState(PRODUCT_LIST_ORDER[0].value);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 상품 목록 정렬 선택 결과
	 * @param {String} value
	 */
	const setProductListOrderSelected = (value) => {
		setListOrderSelected(value);
	};

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="container underTopMenu productDisplay">
			<div className="contents">
				<div className="bannerCover">
					<Image
						className="banner"
						srcSet="
							../../sample/productBanner.png 1x
							, ../../sample/productBanner@2x.png 2x
							, ../../sample/productBanner@3x.png 3x"
						alt="기획전 배너"
					/>
				</div>
				<div className="productDisplayContainer">
					<div className="title">행운을 가득 담은 먹거리</div>
					<div className="subtitle">당신의 찬란할 미래를 응원해요</div>
					<ProductListOrder
						{...{
							selected: listOrderSelected,
							afterSelected: setProductListOrderSelected,
						}}
					/>
					<div className="productListContainer">
						<ProductList
							{...{
								title: "행운을 가득 담은 먹거리",
								subtitle: "당신의 찬란할 미래를 응원해요",
								totalCount: 37,
								page: 1,
								offset: 20,
								items: [
									{
										productNo: 309,
										type: "건강식품",
										brand: {
											brandNo: 91,
											name: "삭제용 브랜드 테스트",
										},
										name: "상품검수테스트 19",
										price: 123,
										salePrice: 122,
										salePercent: 1,
										deliveryPrice: 123,
										thumbnail: {
											fileNo: 1144,
											thumbPath:
												"http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_8c4522a7294c4fc4a79cddc62c2c321e.png",
											webPath:
												"http://172.30.1.55:9000/linme/PRODUCT/LM_F_8c4522a7294c4fc4a79cddc62c2c321e.png",
										},
										tag: null,
										status: "SALE",
										likeYn: "N",
										registered: "2024-03-22 18:47:11",
									},
									{
										productNo: 246,
										type: "건강식품",
										brand: {
											brandNo: 90,
											name: "동국제약",
										},
										name: "테스트 상품명",
										price: 1000,
										salePrice: 1000,
										salePercent: 0,
										deliveryPrice: 0,
										thumbnail: {
											fileNo: 1025,
											thumbPath:
												"http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_b08edb4fa2fa4cdf8c0806016c9d3380.png",
											webPath:
												"http://172.30.1.55:9000/linme/PRODUCT/LM_F_b08edb4fa2fa4cdf8c0806016c9d3380.png",
										},
										tag: null,
										status: "SALE",
										likeYn: "N",
										registered: "2024-03-18 16:14:22",
									},
									{
										productNo: 289,
										type: "건강식품",
										brand: {
											brandNo: 91,
											name: "삭제용 브랜드 테스트",
										},
										name: "상품검수테스트 4",
										price: 123,
										salePrice: 122,
										salePercent: 1,
										deliveryPrice: 123,
										thumbnail: {
											fileNo: 1133,
											thumbPath:
												"http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_b024e727c6cc45efa7c79ec9eef25c88.png",
											webPath:
												"http://172.30.1.55:9000/linme/PRODUCT/LM_F_b024e727c6cc45efa7c79ec9eef25c88.png",
										},
										tag: null,
										status: "SALE",
										likeYn: "N",
										registered: "2024-03-22 16:59:31",
									},
									{
										productNo: 290,
										type: "건강식품",
										brand: {
											brandNo: 91,
											name: "삭제용 브랜드 테스트",
										},
										name: "상품검수테스트 5",
										price: 123,
										salePrice: 122,
										salePercent: 1,
										deliveryPrice: 123,
										thumbnail: {
											fileNo: 1134,
											thumbPath:
												"http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_0aff33b58d014466ab13f0b4833ddd09.png",
											webPath:
												"http://172.30.1.55:9000/linme/PRODUCT/LM_F_0aff33b58d014466ab13f0b4833ddd09.png",
										},
										tag: null,
										status: "SALE",
										likeYn: "N",
										registered: "2024-03-22 17:00:49",
									},
									{
										productNo: 295,
										type: "건강식품",
										brand: {
											brandNo: 91,
											name: "삭제용 브랜드 테스트",
										},
										name: "상품검수테스트 10 (관리자가 생성)",
										price: 123,
										salePrice: 122,
										salePercent: 1,
										deliveryPrice: 123,
										thumbnail: {
											fileNo: 1139,
											thumbPath:
												"http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_d62dd23cebf4481bb1d1f4f39121fce2.png",
											webPath:
												"http://172.30.1.55:9000/linme/PRODUCT/LM_F_d62dd23cebf4481bb1d1f4f39121fce2.png",
										},
										tag: null,
										status: "SALE",
										likeYn: "N",
										registered: "2024-03-22 17:29:44",
									},
									{
										productNo: 298,
										type: "건강식품",
										brand: {
											brandNo: 91,
											name: "삭제용 브랜드 테스트",
										},
										name: "상품검수테스트 6-1 (관리자가 생성)",
										price: 123,
										salePrice: 122,
										salePercent: 1,
										deliveryPrice: 123,
										thumbnail: {
											fileNo: 1135,
											thumbPath:
												"http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_841ae8b59d174ddda130cd80c4323422.png",
											webPath:
												"http://172.30.1.55:9000/linme/PRODUCT/LM_F_841ae8b59d174ddda130cd80c4323422.png",
										},
										tag: null,
										status: "SALE",
										likeYn: "N",
										registered: "2024-03-22 18:08:28",
									},
									{
										productNo: 305,
										type: "건강식품",
										brand: {
											brandNo: 91,
											name: "삭제용 브랜드 테스트",
										},
										name: "상품검수테스트 15",
										price: 123,
										salePrice: 122,
										salePercent: 1,
										deliveryPrice: 123,
										thumbnail: {
											fileNo: 1142,
											thumbPath:
												"http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_5d4877d50ddf486d97ef72d77dcac2ff.png",
											webPath:
												"http://172.30.1.55:9000/linme/PRODUCT/LM_F_5d4877d50ddf486d97ef72d77dcac2ff.png",
										},
										tag: null,
										status: "SALE",
										likeYn: "N",
										registered: "2024-03-22 18:26:33",
									},
									{
										productNo: 306,
										type: "건강식품",
										brand: {
											brandNo: 91,
											name: "삭제용 브랜드 테스트",
										},
										name: "상품검수테스트 16 (15에서 수정)",
										price: 123,
										salePrice: 122,
										salePercent: 1,
										deliveryPrice: 123,
										thumbnail: {
											fileNo: 1142,
											thumbPath:
												"http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_5d4877d50ddf486d97ef72d77dcac2ff.png",
											webPath:
												"http://172.30.1.55:9000/linme/PRODUCT/LM_F_5d4877d50ddf486d97ef72d77dcac2ff.png",
										},
										tag: null,
										status: "SALE",
										likeYn: "N",
										registered: "2024-03-22 18:27:29",
									},
									{
										productNo: 307,
										type: "건강식품",
										brand: {
											brandNo: 91,
											name: "삭제용 브랜드 테스트",
										},
										name: "상품검수테스트 17 ",
										price: 123,
										salePrice: 122,
										salePercent: 1,
										deliveryPrice: 123,
										thumbnail: {
											fileNo: 1143,
											thumbPath:
												"http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_156a24892cef4b169c6be3d1b9d84dbc.png",
											webPath:
												"http://172.30.1.55:9000/linme/PRODUCT/LM_F_156a24892cef4b169c6be3d1b9d84dbc.png",
										},
										tag: null,
										status: "SALE",
										likeYn: "N",
										registered: "2024-03-22 18:37:06",
									},
									{
										productNo: 308,
										type: "건강식품",
										brand: {
											brandNo: 91,
											name: "삭제용 브랜드 테스트",
										},
										name: "상품검수테스트 17 (17 수정)",
										price: 123,
										salePrice: 122,
										salePercent: 1,
										deliveryPrice: 123,
										thumbnail: {
											fileNo: 1143,
											thumbPath:
												"http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_156a24892cef4b169c6be3d1b9d84dbc.png",
											webPath:
												"http://172.30.1.55:9000/linme/PRODUCT/LM_F_156a24892cef4b169c6be3d1b9d84dbc.png",
										},
										tag: null,
										status: "SALE",
										likeYn: "N",
										registered: "2024-03-22 18:39:20",
									},
									{
										productNo: 245,
										type: "건강식품",
										brand: {
											brandNo: 90,
											name: "동국제약",
										},
										name: "이경제 더힘찬 녹용 블랙 2022년 최신상 66ml, 1.98L, 3개",
										price: 5000000,
										salePrice: 5000000,
										salePercent: 0,
										deliveryPrice: 0,
										thumbnail: {
											fileNo: 1018,
											thumbPath:
												"http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_1d2d0d663c214a9380713db27ed7ce6d.jpeg",
											webPath:
												"http://172.30.1.55:9000/linme/PRODUCT/LM_F_1d2d0d663c214a9380713db27ed7ce6d.jpeg",
										},
										tag: null,
										status: "SALE",
										likeYn: "N",
										registered: "2024-03-18 15:58:25",
									},
									{
										productNo: 310,
										type: "건강식품",
										brand: {
											brandNo: 91,
											name: "삭제용 브랜드 테스트",
										},
										name: "상품검수테스트 20",
										price: 123,
										salePrice: 122,
										salePercent: 1,
										deliveryPrice: 123,
										thumbnail: {
											fileNo: 1145,
											thumbPath:
												"http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_6ead2c5758ae478caab7352e36780f13.png",
											webPath:
												"http://172.30.1.55:9000/linme/PRODUCT/LM_F_6ead2c5758ae478caab7352e36780f13.png",
										},
										tag: null,
										status: "SALE",
										likeYn: "N",
										registered: "2024-03-22 18:55:15",
									},
									{
										productNo: 311,
										type: "건강식품",
										brand: {
											brandNo: 91,
											name: "삭제용 브랜드 테스트",
										},
										name: "상품검수테스트 21 (20수정)",
										price: 123,
										salePrice: 122,
										salePercent: 1,
										deliveryPrice: 123,
										thumbnail: {
											fileNo: 1145,
											thumbPath:
												"http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_6ead2c5758ae478caab7352e36780f13.png",
											webPath:
												"http://172.30.1.55:9000/linme/PRODUCT/LM_F_6ead2c5758ae478caab7352e36780f13.png",
										},
										tag: null,
										status: "SALE",
										likeYn: "N",
										registered: "2024-03-22 18:55:40",
									},
									{
										productNo: 313,
										type: "건강식품",
										brand: {
											brandNo: 91,
											name: "삭제용 브랜드 테스트",
										},
										name: "상품검수테스트 23 (22수정)",
										price: 123,
										salePrice: 122,
										salePercent: 1,
										deliveryPrice: 123,
										thumbnail: {
											fileNo: 1146,
											thumbPath:
												"http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_2a821c15d9e444c69aa22ea5797089b7.png",
											webPath:
												"http://172.30.1.55:9000/linme/PRODUCT/LM_F_2a821c15d9e444c69aa22ea5797089b7.png",
										},
										tag: null,
										status: "SALE",
										likeYn: "N",
										registered: "2024-03-22 18:57:38",
									},
									{
										productNo: 315,
										type: "건강식품",
										brand: {
											brandNo: 91,
											name: "삭제용 브랜드 테스트",
										},
										name: "상품검수테스트 24",
										price: 123,
										salePrice: 122,
										salePercent: 1,
										deliveryPrice: 123,
										thumbnail: {
											fileNo: 1147,
											thumbPath:
												"http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_0899a4ecbde344978336ca3cf0062676.png",
											webPath:
												"http://172.30.1.55:9000/linme/PRODUCT/LM_F_0899a4ecbde344978336ca3cf0062676.png",
										},
										tag: null,
										status: "SALE",
										likeYn: "N",
										registered: "2024-03-22 19:05:44",
									},
									{
										productNo: 319,
										type: "건강식품",
										brand: {
											brandNo: 91,
											name: "삭제용 브랜드 테스트",
										},
										name: "상품검수테스트 317 수정)",
										price: 123,
										salePrice: 122,
										salePercent: 1,
										deliveryPrice: 123,
										thumbnail: {
											fileNo: 1146,
											thumbPath:
												"http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_2a821c15d9e444c69aa22ea5797089b7.png",
											webPath:
												"http://172.30.1.55:9000/linme/PRODUCT/LM_F_2a821c15d9e444c69aa22ea5797089b7.png",
										},
										tag: null,
										status: "SALE",
										likeYn: "N",
										registered: "2024-03-22 19:15:32",
									},
									{
										productNo: 320,
										type: "건강식품",
										brand: {
											brandNo: 91,
											name: "삭제용 브랜드 테스트",
										},
										name: "상품검수테스트 26 (25 수정)",
										price: 123,
										salePrice: 122,
										salePercent: 1,
										deliveryPrice: 123,
										thumbnail: {
											fileNo: 1149,
											thumbPath:
												"http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_838ddaeda94c4169af65c5bf6b399aad.png",
											webPath:
												"http://172.30.1.55:9000/linme/PRODUCT/LM_F_838ddaeda94c4169af65c5bf6b399aad.png",
										},
										tag: null,
										status: "SALE",
										likeYn: "N",
										registered: "2024-03-22 19:15:42",
									},
									{
										productNo: 322,
										type: "건강식품",
										brand: {
											brandNo: 91,
											name: "삭제용 브랜드 테스트",
										},
										name: "상품검수테스트 322 test",
										price: 123,
										salePrice: 122,
										salePercent: 1,
										deliveryPrice: 123,
										thumbnail: {
											fileNo: 1151,
											thumbPath:
												"http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_86a703bc430f40478060b42bc0ad1d80.jpg",
											webPath:
												"http://172.30.1.55:9000/linme/PRODUCT/LM_F_86a703bc430f40478060b42bc0ad1d80.jpg",
										},
										tag: null,
										status: "SALE",
										likeYn: "N",
										registered: "2024-03-22 19:47:53",
									},
									{
										productNo: 323,
										type: "건강식품",
										brand: {
											brandNo: 91,
											name: "삭제용 브랜드 테스트",
										},
										name: "상품검수테스트 27_Jerry",
										price: 123,
										salePrice: 122,
										salePercent: 1,
										deliveryPrice: 123,
										thumbnail: {
											fileNo: 1152,
											thumbPath:
												"http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_ac4aa5fbf7644d89b71d1085aa382a31.png",
											webPath:
												"http://172.30.1.55:9000/linme/PRODUCT/LM_F_ac4aa5fbf7644d89b71d1085aa382a31.png",
										},
										tag: null,
										status: "SALE",
										likeYn: "N",
										registered: "2024-03-22 19:54:15",
									},
									{
										productNo: 236,
										type: "건강식품",
										brand: {
											brandNo: 69,
											name: "엔썸잇",
										},
										name: "엔썸잇 비오틴 10000mcg 판토텐산 맥주효모 L시스틴 바이오틴 100정",
										price: 80000,
										salePrice: 62100,
										salePercent: 22,
										deliveryPrice: 3000,
										thumbnail: {
											fileNo: 920,
											thumbPath:
												"http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_cdbdd41102164a14bed06e69a156f2ba.png",
											webPath:
												"http://172.30.1.55:9000/linme/PRODUCT/LM_F_cdbdd41102164a14bed06e69a156f2ba.png",
										},
										tag: null,
										status: "SALE",
										likeYn: "N",
										registered: "2024-03-18 15:34:23",
									},
								],
								isLastPage: false,
								limit: 20,
							}}
						/>
					</div>
					<Pagination
						{...{
							currentPage: 1,
							lastPage: 100,
						}}
					/>
				</div>
			</div>
			<div className="divisionTransparent70" />
		</div>
	);
}

export default ProductDisplay;
