/**
 * 마이린미 - 심플 건강 체크
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import useAuth from "src/hooks/useAuth";
import { removeSpinner, setSpinner } from "src/utils/linmeDisplay";
import { API_RESULT_STATUS } from "src/data/constEnum";

import {
	checkSimpleCategory,
	getSimpleCategory,
	getSimpleProduct,
} from "src/api/myLinme/simpleHealthCheck";
import Image from "src/pages/component/common/image";
import ProductList from "src/pages/component/product/productList";
import RecommendProductItem from "../component/product/recommendProductItem";
import RecommendProductList from "../component/product/recommendProductList";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myLinme/simpleHealthCheck.scss";
/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */

const pathList = require("src/path/pathJSON.json");

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const MyLinmeSimpleHealthCheck = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const auth = useAuth();

	const [issueList, setIssueList] = useState([]);
	const [recommendedProductList, setRecommendedProductList] = useState([]);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 고민 클릭 시
	 * @param {MouseEvent} e
	 * @param {object} issueData
	 */
	const handlerClickHealthIssue = async (e, issueData) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await _checkSimpleCategory(issueData);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 고민 목록 조회 API
	 */
	const _getSimpleCategory = async () => {
		await setSpinner();

		try {
			const result = await getSimpleCategory();

			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				_getSimpleProduct(); // 목록 조회 시 추천 상품 조회
				setIssueList(result.data);
			} else {
				window.linmeLog.log(result);
			}
		} catch (err) {
			window.linmeLog.error(err);
		} finally {
			await removeSpinner();
		}
	};

	/**
	 * 선택한 고민에 서버로 전달 API
	 */
	const _checkSimpleCategory = async (issueData) => {
		let d = {
			subCode: issueData?.subCode,
			codeGroupId: issueData?.codeGroupId,
			check: !issueData?.check,
		};

		try {
			const result = await checkSimpleCategory(d);

			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				_getSimpleCategory();
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	/**
	 * 선택한 고민에 해당하는 상품 목록 API
	 */
	const _getSimpleProduct = async () => {
		try {
			const result = await getSimpleProduct();

			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				setRecommendedProductList(result?.data || []);
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	/**
	 * 선택된 고민 목록 이름 가져오기
	 */
	const getIssueListName = () => {
		let selectedIssueList = issueList.filter((item) => item.check === true);
		let selectedIssueName = selectedIssueList.map((item) => `#${item.rsvVal1}`);
		return selectedIssueName.join(" ");
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// 고민 목록 가져오기
	useEffect(() => {
		_getSimpleCategory();
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<>
			<div className="container underTopMenu simpleHealthIssueContainer">
				<div className="contents">
					<div className="simpleHealthIssueTop">
						{/* 내 정보 카드 */}
						<div className="simpleHealthIssueCard">
							<div>
								<div className="tag">{textInfo.myLinme.simpleHealthCheck.title}</div>
								<div className="title">
									<span>{auth?.user?.nickname || textInfo.healthIssue.notLoginMember}</span>
									{textInfo.myLinme.simpleHealthCheck.subTitle1}
									<span className="titleIcon" />
									<br />
									{textInfo.myLinme.simpleHealthCheck.subTitle2}
									<br />
									{textInfo.myLinme.simpleHealthCheck.subTitle3}
								</div>
							</div>
							<div className="description">
								{textInfo.myLinme.simpleHealthCheck.desc1}
								<br />
								{textInfo.myLinme.simpleHealthCheck.desc2}
							</div>
						</div>
						{/* 건강 고민 리스트 */}
						<div className="simpleHealthIssueTopic">
							{issueList.map((item, i) => (
								<div
									key={i}
									className={"healthIssueItem " + (item?.check ? " on" : "")}
									onClick={(e) => handlerClickHealthIssue(e, item)}
								>
									<Image className="healthIssueImage" src={item?.rsvVal4} alt={item.name} />
									<div className="healthIssueName">{item.rsvVal1}</div>
								</div>
							))}
						</div>
					</div>
				</div>
				{/* 상품 추천 */}
				<div className="simpleHealthIssueProduct">
					<div
						className={
							"selectedHealthIssueCover " +
							(recommendedProductList && recommendedProductList.length > 0 ? "on " : "")
						}
					>
						<Link to={pathList.myLinme.simpleHealthCheckProduct}>
							<div className="title">
								{`${auth?.user?.nickname}${textInfo.myLinme.simpleHealthCheck.productTitle}`}
							</div>
						</Link>
						<div className="description">{getIssueListName()}</div>
						<div className="recommendedProductsForMain">
							<div className="recommendedProductsContainer">
								{recommendedProductList.length > 0 ? (
									<ProductList {...{ items: recommendedProductList, limit: 5 }} />
								) : (
									<div className="noData"></div>
								)}
							</div>
						</div>
						<div className="recommendedProducts"></div>
					</div>
				</div>
			</div>
		</>
	);
};

export default MyLinmeSimpleHealthCheck;
