// URL에 쿼리 파라미터를 추가하는 함수
export const addQueryParam = (relativeUrl, key, value) => {
	try {
		// 현재 페이지의 URL을 기준으로 절대 URL을 생성
		const baseUrl = window.location.origin;
		const urlObj = new URL(relativeUrl, baseUrl);

		// URL 객체의 검색 파라미터에 새로운 키-값 쌍을 설정
		urlObj.searchParams.set(key, value);

		// 수정된 URL 객체의 상대 경로를 반환
		return urlObj.pathname + urlObj.search;
	} catch (error) {
		console.error("Invalid URL:", relativeUrl);
		throw error;
	}
};

// 기능식품/맞춤식품 상품 상세 페이지 URL 생성
export const getProductDetailType = (type) => {
	if (type === "1" || type === "건강식품") {
		return "effect";
	} else {
		return "ingredients";
	}
};
