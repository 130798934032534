import { useQuery } from "@tanstack/react-query";
import { getCategoryList } from "src/api/product/category";
import useQueryParams from "src/hooks/useQueryParams";
import { queryKeys, MILLISECONDS_IN_A_DAY } from "src/data";
const pathList = require("src/path/pathJSON.json");

/**
 * 각 카테고리에 "전체보기" 항목을 추가합니다.
 * @param {Array} categories - 서버로부터 가져온 카테고리 목록
 * @returns {Array} - "전체보기" 항목이 추가된 카테고리 목록
 */
const addAllDepth = (categories) => {
	const allDepth2 = {
		categoryNo: -1,
		name: "전체보기",
	};
	return categories.map((category) => {
		return {
			...category,
			child: [allDepth2, ...(category.child || [])],
		};
	});
};

/**
 * 카테고리 목록 호출 api.
 */
const requestGetCategoryList = async () => {
	try {
		const result = await getCategoryList();
		return addAllDepth(result.data);
	} catch (error) {
		window.linmeLog.error(error);
		throw new Error(error);
	}
};

/** 카테고리 목록 가져오는 커스텀 훅
 * @returns {Object} 카테고리 목록과 이벤트 핸들러 함수
 * @returns {Array} categoryList - 카테고리 목록
 * @returns {Function} handleClickProductType - 뎁스 1(대분류) 카테고리를 선택할 때 호출되는 함수
 * @returns {Function} handleClickDepth - 뎁스 2(소분류) 카테고리를 선택할 때 호출되는 함수
 */
const useCategory = () => {
	const { data, isLoading } = useQuery({
		queryKey: [queryKeys.CATEGORY_LIST],
		queryFn: requestGetCategoryList,
		staleTime: MILLISECONDS_IN_A_DAY, // 24시간 동안 캐시 유지
	});

	const query = useQueryParams({ path: pathList.product.category, isReplace: true });

	/**
	 * 뎁스 1(대분류) 카테고리를 선택할 때 호출됩니다.
	 * 선택된 카테고리에 해당하는 상품 유형을 쿼리 파라미터로 설정하고,
	 * 뎁스 2(소분류) 카테고리는 "전체보기"로 설정합니다.
	 * @param {number} categoryNo - 선택된 뎁스 1 카테고리 번호
	 */
	const handleClickProductType = (categoryNo) => {
		query.setAll({ productType: categoryNo, categoryNo: -1 });
	};

	/**
	 * 뎁스 2(소분류) 카테고리를 선택할 때 호출됩니다.
	 * 선택된 카테고리를 쿼리 파라미터로 설정합니다.
	 * @param {number} categoryNo - 선택된 뎁스 2 카테고리 번호
	 */
	const handleClickDepth = (categoryNo) => {
		query.set("categoryNo", categoryNo);
	};

	return { categoryList: data, handleClickProductType, handleClickDepth, isLoading };
};

export default useCategory;
