/**
 * 반환할인액 안내 모달
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/claim/returnDiscountInfo.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

const htmlInfo = require("src/data/htmlInfo.json");
const textInfo = require("src/data/textInfo.json");

const ReturnDiscountInfo = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="returnDiscountInfoContainer">
			<div className="title">{textInfo.claim.price.refundDiscount}</div>
			<ul className="infoList">
				<li
					className="infoItem"
					dangerouslySetInnerHTML={{ __html: htmlInfo.claim.returnDiscountInfo.info1 }}
				/>
				<li
					className="infoItem"
					dangerouslySetInnerHTML={{ __html: htmlInfo.claim.returnDiscountInfo.info2 }}
				/>
				<li
					className="infoItem"
					dangerouslySetInnerHTML={{ __html: htmlInfo.claim.returnDiscountInfo.info3 }}
				/>
				<li
					className="infoItem"
					dangerouslySetInnerHTML={{ __html: htmlInfo.claim.returnDiscountInfo.info4 }}
				/>
			</ul>
			<div className="couponInfo">{props.couponName ? props.couponName : ""}</div>
		</div>
	);
};

export default ReturnDiscountInfo;
