/**
 * 상품 옵션 목록 (필수,옵션)
 *
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/order/productOptionsEdit.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const ProductOptionsList = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const { option } = props;

	if (!option) return;

	return (
		<div className="productOptionEditContainer">
			<div className="productOptionEditItem">
				<div className="productOptionEditItemInfo">
					<div className="productOptionEditItemInfoName">
						{option.optionFullName + " | " + option.quantity + textInfo.common.quantityUnit}
					</div>
					<div className="productOptionEditItemInfoPrice">
						{option?.totalPrice?.toLocaleString() || 0}
						{textInfo.common.currencyUnit}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductOptionsList;
