import { memo } from "react";
import useAuth from "src/hooks/useAuth";
import useRecentlyViewed from "src/hooks/layout/useRecentlyViewed";
import Image from "src/pages/component/common/image";
import { IMAGE_ERROR_SRC } from "src/data/constEnum";

const textInfo = require("src/data/textInfo.json");

const RecentlyViewed = () => {
	const auth = useAuth();
	const { data: productList, moveToProductDetail } = useRecentlyViewed();

	if (!auth.isLogin || !productList || !productList.length) {
		return;
	}

	return (
		<div className="fixedMenu recentlyViewed">
			<div className="title">{textInfo.fixedMenu.recentlyViewed}</div>
			<ul className="recentlyProductList">
				{productList.map((product, index) => (
					<li
						className="recentlyProductItem"
						key={index}
						onClick={() => moveToProductDetail(product)}
					>
						{product.thumbnail && product?.thumbnail?.thmbPath ? (
							<Image src={product.thumbnail.thmbPath} alt={product.name} type="small" />
						) : (
							<Image src={IMAGE_ERROR_SRC.small} alt={product.name} type="small" />
						)}
					</li>
				))}
			</ul>
		</div>
	);
};

export default memo(RecentlyViewed);
