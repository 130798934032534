import { Link } from "react-router-dom";

const pathList = require("src/path/pathJSON.json");
const textInfo = require("src/data/textInfo.json");

/** 주문 데이터 없을 때 표시 컴포넌트 */
const OrderNoData = () => {
	return (
		<li className="noData">
			<div className="noOrderList">{textInfo.myPage.myShopping.order.noData}</div>
			<Link to={pathList.main}>
				<div className="button clickable moveToMain">{textInfo.button.moveToShopping}</div>
			</Link>
		</li>
	);
};

export default OrderNoData;
