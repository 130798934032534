import { Link } from "react-router-dom";
import Image from "src/pages/component/common/image";
import { getProductDetailType } from "src/utils/linmeSearch";
import { ORDER_STATUS, PRODUCT_OPTIONS_TYPE } from "src/data";
const pathList = require("src/path/pathJSON.json");
const textInfo = require("src/data/textInfo.json");

/**
 * 주문 상품 정보
 */
const OrderProductInfo = (props) => {
	const { item } = props;
	return (
		<div className="orderProductItemInfoContainer">
			<div className="status">{ORDER_STATUS[item.status]?.name}</div>
			<div className="orderProductItemInfo">
				{/* 상품이미지 */}
				<div className="productImageCover">
					<Link
						to={
							pathList.product.detail +
							`${getProductDetailType(item?.product?.type)}/` +
							item.product.productNo
						}
					>
						<Image src={item?.product?.thumbnail?.webPath} alt={item.product?.name} />
					</Link>
				</div>
				{/* 상품정보 */}
				<div className="selectProductInfo">
					{/* 브랜드명 */}
					{/* <div className="brandName">{item.product.brand?.name}</div> */}
					{/* 상품명 */}
					<Link
						to={
							pathList?.product?.detail +
							`${getProductDetailType(item?.product?.type)}/` +
							item?.product?.productNo
						}
					>
						<div className="productName">{item.product.name}</div>
					</Link>
					{/* 옵션 */}
					{item?.product?.option?.type === PRODUCT_OPTIONS_TYPE.REQUIRED &&
					item?.product?.option?.categoryName1 === "" &&
					item?.product?.option?.optionName1 === "" ? (
						<div className="productQuantity">
							{/* 옵션이 없는 경우 */}
							<span className="option">
								{item.product.option.addPrice > 0 ? "(+" + item.product.option.addPrice + ")" : ""}
							</span>
							{item?.quantity}
							<span className="quantityUnit">{textInfo.common.quantityUnit}</span>
						</div>
					) : (
						<>
							{/* 옵션이 있는 경우 */}
							<div className="productOptionAndQuantity">
								<span className="optionType">
									{
										textInfo.product.detail.option.optionType[
											item.product.option.type.toLowerCase()
										]
									}
								</span>
								<div className="optionList">
									{/* 옵션에 대한 map 함수 삽입하면 되는 부분 */}
									<div className="optionAndQuantity">
										<span className="option">
											{item.product.option.optionFullName +
												(item.product.option.addPrice
													? " (+" + item.product.option.addPrice.toLocaleString() + ")"
													: "")}
										</span>
										<span className="quantity">
											{item.quantity}
											{textInfo.common.quantityUnit}
										</span>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
			{/* 총 상품금액 */}
			<div className="price">
				<span>
					{item.totalPrice.toLocaleString()}
					<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
				</span>
			</div>
		</div>
	);
};

export default OrderProductInfo;
