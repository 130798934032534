/**
 * 장바구니 - 상품 목록 (묶음배송별)
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useRef, useState } from "react";

import { PRODUCT_OPTIONS_TYPE, PRODUCT_STATUS } from "src/data/constEnum";

import CartProductCard from "src/pages/component/cart/cartProductCard";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/cart/cartProductList.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const CartProductList = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const [selectAll, setSelectAll] = useState(false);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 묶음상품 전체 선택  시
	 * @param {Event} e
	 */
	const handlerSelectAll = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const saleProductList = props.data?.products?.filter(
			(product) =>
				product.status !== PRODUCT_STATUS.SOLDOUT && product.status !== PRODUCT_STATUS.DEAD
		);
		const productNoList = saleProductList?.map((product) => product.productNo);

		if (props.handlerChangeSelectedMultiple)
			props.handlerChangeSelectedMultiple(!selectAll, productNoList);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 전체 선택 체크박스 disabled 여부
	 */
	const isAllDisabled = () => {
		if (props.data?.products && props.data?.products.length) {
			for (let i = 0; i < props.data.products.length; i++) {
				if (
					props.data.products[i].status === PRODUCT_STATUS.SOLDOUT ||
					props.data.products[i].status === PRODUCT_STATUS.DEAD
				) {
					return true;
				}
			}
		}
		return false;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// 묶음배송 전체 선택 체크박스 상태 변경;
	useEffect(() => {
		const saleProductList = props.data?.products?.filter(
			(product) =>
				product.status !== PRODUCT_STATUS.SOLDOUT && product.status !== PRODUCT_STATUS.DEAD
		);
		const productNoList = saleProductList?.map((product) => product.productNo);

		if (productNoList?.every((productNo) => props?.checkedProduct.includes(productNo))) {
			setSelectAll(true);
		} else {
			setSelectAll(false);
		}
	}, [props?.checkedProduct, props.data?.products]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="cartProductContainer">
			<div className="cardTop">
				<div className="label check">
					<input
						className="itemSelection"
						id={"itemSelection" + props.index}
						type="checkbox"
						disabled={isAllDisabled() ? true : false}
						checked={selectAll}
						onChange={(e) => handlerSelectAll(e)}
					/>
					<label htmlFor={"itemSelection" + props.index}></label>
				</div>
				<div className="label product">{textInfo.cart.data.productInfo}</div>
				<div className="label productPrice">{textInfo.cart.data.productPriceInfo}</div>
				<div className="label deliveryPrice">{textInfo.cart.data.productDeliveryPriceInfo}</div>
			</div>
			<div className="cardCenter">
				<div className="cartProductList">
					{props.data?.products && props.data?.products.length ? (
						props?.data?.products?.map((product, index) => (
							<div className={"cartProductItem productNo" + product.productNo} key={index}>
								<CartProductCard
									{...product}
									index={index}
									handlerChangeSelected={props.handlerChangeSelected} // 체크박스 선택
									afterOptionChanged={props.afterOptionChanged} // 상품 정보 수정시
									checkedProduct={props.checkedProduct} // 선택한 상품 목록
									isFirstProduct={index === 0} // 첫번째 상품 여부
									requestDeleteProductFromCart={props.requestDeleteProductFromCart} // 상품 삭제 요청
									requestDeleteOptionFromCart={props.requestDeleteOptionFromCart} // 상품 옵션 삭제 요청
								/>
							</div>
						))
					) : (
						<></>
					)}
				</div>
			</div>
			<div className="cardDeliveryPrice">
				<div className="cardDeliveryPriceItem">{textInfo.cart.data.productDeliveryPriceInfo}</div>
				<div className="cardDeliveryPriceItem bold">
					{props.data?.products && props.data?.products.length > 1 && (
						<span className="cardDeliveryPriceItem gray">
							({textInfo.cart.data.productDeliveryCombined})
						</span>
					)}
					{props?.data?.deliveryPrice?.toLocaleString() || 0}
					{textInfo.common.currencyUnit}
				</div>
			</div>
		</div>
	);
};

export default CartProductList;
