/**
 * 소셜 관련 ( 소셜 로그인, 소셜 공유 등 )
 */

/**
 * 카카오
 */
export const kakao = {
	/**
	 * javascript 삽입
	 */
	insertScript: () => {
		if (typeof window.Kakao === "undefined") {
			const script = window.document.createElement("script");
			script.type = "text/javascript";
			script.src = "https://t1.kakaocdn.net/kakao_js_sdk/2.7.1/kakao.min.js";
			script.integrity = "sha384-kDljxUXHaJ9xAb2AzRd59KxjrFjzHa5TAoFQ6GbYTCAG0bjM55XohjjDT7tDDC01";
			script.crossOrigin = "anonymous";
			script.async = true;
			window.document.getElementsByTagName("body")[0].appendChild(script);
		}
	},
	/**
	 * initialize
	 */
	init: () => {
		if (!window.Kakao.isInitialized())
			window.Kakao.init(`${process.env.REACT_APP_SOCIAL_KAKAO_JS}`);
	},
	/**
	 * 카카오톡 공유하기
	 */
	share: (sendText, sendUrl) => {
		window.Kakao.Share.sendCustom({
			templateId: 0,
			templateArgs: {
				title: sendText,
				description: sendUrl,
			},
		});
	},
};

/**
 * 네이버
 */
export const naver = {
	/**
	 * javascript 삽입
	 */
	insertScript: () => {
		if (typeof window.naver_id_login === "undefined") {
			const script = window.document.createElement("script");
			script.type = "text/javascript";
			script.src = "https://static.nid.naver.com/js/naverLogin_implicit-1.0.3.js";
			script.async = true;
			script.charset = "utf-8";
			window.document.getElementsByTagName("body")[0].appendChild(script);
		}
	},
};

/**
 * 애플
 */
export const apple = {
	/**
	 * javascript 삽입
	 */
	insertScript: () => {
		if (typeof window.AppleID === "undefined") {
			const script = window.document.createElement("script");
			script.type = "text/javascript";
			script.src =
				"https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
			script.async = true;
			window.document.getElementsByTagName("body")[0].appendChild(script);
		}
	},
};
