import { useState } from "react";
import Image from "src/pages/component/common/image";
import CategoryListMobileItem from "src/pages/component/categoryListMobileItem";

import styles from "src/styles/categoryListMobile.module.scss";
import LeftMenuUserLogin from "src/pages/component/leftMenuUserLogin";
import "src/styles/categoryList.scss";

/**
 * CategoryListMobile 컴포넌트
 * @param {Object} props - 컴포넌트에 전달되는 props 객체
 * @param {Array} props.categoryList - 표시할 카테고리 리스트 배열
 * @param {Function} props.handlerClickToggle - 왼쪽 메뉴 토글 핸들러 함수
 */
const CategoryListMobile = (props) => {
	const { categoryList, handlerClickToggle } = props;
	const [selected, setSelected] = useState(null); // 현재 선택된 카테고리를 상태로 관리

	/**
	 * - 클릭한 카테고리의 번호를 상태로 설정하여 해당 카테고리를 열거나 닫음.
	 *
	 * @param {number} categoryNo - 클릭한 카테고리의 번호
	 */
	const toggleProductType = (categoryNo) => {
		setSelected(selected === categoryNo ? null : categoryNo);
	};

	return (
		<div className="leftMenuContainer">
			<Image
				className="logo"
				srcSet="
        ../../images/logo/logo_leftMenu.png 1x
        , ../../images/logo/logo_leftMenu@2x.png 2x
        , ../../images/logo/logo_leftMenu@3x.png 3x
    "
				alt="linme logo"
			/>
			<LeftMenuUserLogin handlerClickToggle={handlerClickToggle} />
			<div className={styles.categoryContainer}>
				{categoryList?.map((category) => (
					<CategoryListMobileItem
						key={category.categoryNo}
						isOpen={selected === category.categoryNo}
						category={category}
						toggleProductType={toggleProductType}
						handlerClickToggle={props.handlerClickToggle}
					/>
				))}
			</div>
		</div>
	);
};

export default CategoryListMobile;
