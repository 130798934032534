import React from "react";
import clsx from "clsx";
import styles from "src/styles/search/searchCategoryFilter.module.scss";
import useQueryParams from "src/hooks/useQueryParams";
import useCategory from "src/hooks/product/useCategory";

/**
 * SearchCategoryFilter 컴포넌트
 * @param {Array} props.categoryList - 카테고리 리스트 배열
 */
const SearchCategoryFilter = ({ categoryList }) => {
	const { handleClickDepth, handleClickProductType } = useCategory();
	const query = useQueryParams();
	// 현재 선택된 productType과 categoryNo를 쿼리에서 가져옴
	const selectedType = Number(query.get("productType"));
	const selectedDepth = Number(query.get("categoryNo"));

	/**
	 * 카테고리 필터 UI 렌더링.
	 * - Depth 1 (대분류) 카테고리와 Depth 2 (소분류) 카테고리를 렌더링.
	 * - 선택된 카테고리에 따라 시각적으로 강조.
	 */
	return (
		<div className={styles.searchCategoryFilterContainer}>
			{/* Depth 1 (대분류) 카테고리 */}
			<div className={clsx([styles.categoryDepthDefault, styles.categoryDepth1])}>
				{categoryList?.map((category) => (
					<span
						key={category.categoryNo}
						className={clsx([
							styles.categoryItem,
							selectedType === category.categoryNo && styles.selected,
						])}
						onClick={() => handleClickProductType(category.categoryNo)}
					>
						{category.name}
					</span>
				))}
			</div>

			{/* Depth 2 (소분류) 카테고리 */}
			<div className={clsx([styles.categoryDepthDefault, styles.categoryDepth2])}>
				{categoryList[selectedType - 1]?.child.map((category) => (
					<span
						key={category.categoryNo}
						className={clsx([
							styles.categoryItem,
							selectedDepth === category.categoryNo && styles.selected,
						])}
						onClick={() => handleClickDepth(category.categoryNo)}
					>
						{category.name}
					</span>
				))}
			</div>
		</div>
	);
};

export default SearchCategoryFilter;
