/**
 * 문의 관련
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */
import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/* ---------------------------------------------------------------------------------------------------- */
/* FUNCTION */
/* ---------------------------------------------------------------------------------------------------- */

/**
 * 1:1 문의 삭제
 * @param {Number} inquiryNo
 */
export const deleteInquiry = async (inquiryNo) => {
	const url = `${API_PATHS.INQUIRY_MODIFY}`;
	try {
		const result = await api.delete(url.replace(":inquiryNo", inquiryNo));
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 1:1 문의 목록
 * @param {Object} d
 * <code>
 * {
 * 		*offset: 0
 * 		, *page: 0
 * 		, type: ""//EMPTY:전체, ACCOUNT:로그인/계정, PRODUCT:상품, DELIVERY:배송, ORDER:주문/결제, CLAIM:교환/환불/취소, SERVICE:서비스/오류, ELSE:기타;
 * }
 * </code>
 * @returns
 */
export const getInquiryList = async (d) => {
	const url = `${API_PATHS.INQUIRY}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 모든 상품 문의 목록
 * @param {Number} productNo
 * @param {Object} d
 * <code>
 * {
 * 		*offset: 0
 * 		, *page: 0
 * }
 * </code>
 * @returns
 */
export const getInquiryListProduct = async (productNo, d) => {
	const url = `${API_PATHS.PRODUCT_INQUIRY}`;
	try {
		const result = await api.get(url.replace(":productNo", productNo), { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 1:1 문의 수정
 * @param {Number} inquiryNo
 * @param {Object} d
 * <code>
 * {
 * 		*"content": ""
 * 		, "fileNoList": ""
 * 		, "isCheckSecret": ""
 * }
 * </code>
 */
export const modifyInquiry = async (inquiryNo, d) => {
	const url = `${API_PATHS.INQUIRY_MODIFY}`;
	try {
		const result = await api.put(url.replace(":inquiryNo", inquiryNo), d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 1:1 문의 작성
 * @param {Object} d
 * <code>
 * {
 * 		*content: ""
 * 		, contentNo: 0//주문 또는 상품 번호;
 * 		, fileNoList: [ 0, 0, ... ]
 * 		, *isCheckSecret: true//비밀글 여부;
 * 		, *type: ""//EMPTY:전체, ACCOUNT:로그인/계정, PRODUCT:상품, DELIVERY:배송, ORDER:주문/결제, CLAIM:교환/환불/취소, SERVICE:서비스/오류, ELSE:기타;
 * }
 * </code>
 */
export const writeInquiry = async (d) => {
	const url = `${API_PATHS.INQUIRY}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 내 상품 문의 목록
 * @param {Object} d
 * <code>
 * {
 * 		*offset: 0
 * 		, *page: 0
 * 		, type: ""//EMPTY:전체, ACCOUNT:로그인/계정, PRODUCT:상품, DELIVERY:배송, ORDER:주문/결제, CLAIM:교환/환불/취소, SERVICE:서비스/오류, ELSE:기타;
 * }
 * </code>
 * @returns
 */
export const getMyProductInquiryList = async (d) => {
	const url = `${API_PATHS.MY_PRODUCT_INQUIRY}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 상품 문의 작성
 * @param {Object} d
 * <code>
 * {
 * 		*content: ""
 * 		, contentNo: 0//주문 또는 상품 번호;
 * 		, fileNoList: [ 0, 0, ... ]
 * 		, *isCheckSecret: true//비밀글 여부;
 * 		, *type: ""//EMPTY:전체, ACCOUNT:로그인/계정, PRODUCT:상품, DELIVERY:배송, ORDER:주문/결제, CLAIM:교환/환불/취소, SERVICE:서비스/오류, ELSE:기타;
 * }
 * </code>
 */
export const writeMyProductInquiry = async (d) => {
	const url = `${API_PATHS.MY_PRODUCT_INQUIRY}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 상품 문의 수정
 * @param {Number} inquiryProductNo
 * @param {Object} d
 * <code>
 * {
 * 		*"content": ""
 * 		, "fileNoList": ""
 * 		, "isCheckSecret": ""
 * }
 * </code>
 */
export const modifyMyProductInquiry = async (inquiryProductNo, d) => {
	const url = `${API_PATHS.MY_PRODUCT_INQUIRY_MODIFY}`;
	try {
		const result = await api.put(url.replace(":inquiryProductNo", inquiryProductNo), d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 상품 문의 삭제
 * @param {Number} inquiryProductNo
 */
export const deleteMyProductInquiry = async (inquiryProductNo) => {
	const url = `${API_PATHS.MY_PRODUCT_INQUIRY_MODIFY}`;
	try {
		const result = await api.delete(url.replace(":inquiryProductNo", inquiryProductNo));
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
