/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { createContext, useContext, useEffect, useState } from "react";

import CryptoJS from "crypto-js";

import { API_RESULT_STATUS, EMPTY_USER_PROFILE_IMAGE } from "src/data/constEnum";
import {
	getCartCount,
	getCouponCount,
	getProfile,
	getReviewCount,
	getStatistics,
	signIn,
	signInSocial,
} from "src/api/member/signIn";
import { devSignIn } from "src/api/dev";
import { addAddress } from "src/api/member/addressBook";
import { requestSignup } from "src/api/member/signUp";
import { checkMemberNotificationUnread } from "src/api/member/notification";
import { signOut } from "src/api/member/signOut";
import { copyStructure, mergeObject } from "src/utils/obj";
import { LOCAL_STORAGE_KEY } from "src/data/key";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

const htmlInfo = require("src/data/htmlInfo.json");
const textInfo = require("src/data/textInfo.json");

export const Auth = createContext();

function AuthProvider({ children }) {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const [user, setUser] = useState(null);
	const [isLogin, setIsLogin] = useState(false);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 알림 관련 재확인 + 재설정
	 */
	const checkNotification = async () => {
		const resultCheckNotification = await requestCheckNotificationUnread();
		if (!resultCheckNotification) return false;
		else {
			await modifyUserData(resultCheckNotification);
		}
	};

	/**
	 * 회원 통계 재확인 + 재설정
	 * 주문 수, 리뷰 수, 쿠폰 수
	 */
	const checkStatistics = async () => {
		//회원 통계 조회 - ;
		const resultStatistics = await requestGetStatistics();
		if (!resultStatistics) return false;
		else {
			await modifyUserData(resultStatistics);
		}
	};

	/**
	 * 초기화
	 */
	const clear = async () => {
		window.localStorage.removeItem(`${LOCAL_STORAGE_KEY.ACCESS_TOKEN}`);
		window.localStorage.removeItem(`${LOCAL_STORAGE_KEY.EMAIL}`);
		window.localStorage.removeItem(`${LOCAL_STORAGE_KEY.REFRESH_TOKEN}`);
		await setIsLogin(false);
		await setUser(null);
	};

	/**
	 * 읽지 않은 알림 존재 여부 조회
	 * @returns
	 */
	const requestCheckNotificationUnread = async () => {
		try {
			const result = await checkMemberNotificationUnread();
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				return result.data;
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (err) {
			window.linmeLog.error(err);
			return false;
		}
	};

	/**
	 * 장바구니 개수 조회
	 */
	const requestGetCartCount = async () => {
		try {
			const result = await getCartCount();
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				return result.data;
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (err) {
			window.linmeLog.error(err);
			return false;
		}
	};

	/**
	 * 쿠폰 개수 조회
	 */
	const requestGetCouponCount = async () => {
		try {
			const result = await getCouponCount();
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				return result.data;
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (err) {
			window.linmeLog.error(err);
			return false;
		}
	};

	/**
	 * 회원 정보 조회
	 */
	const requestGetProfile = async () => {
		try {
			const result = await getProfile();
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				return result.data;
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (err) {
			window.linmeLog.error(err);
			return false;
		}
	};

	/**
	 * 리뷰 개수 조회
	 */
	const requestGetReviewCount = async () => {
		try {
			const result = await getReviewCount();
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				return result.data;
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (err) {
			window.linmeLog.error(err);
			return false;
		}
	};

	/**
	 * 회원 통계 조회
	 */
	const requestGetStatistics = async () => {
		try {
			const result = await getStatistics();
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				return result.data;
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (err) {
			window.linmeLog.error(err);
			return false;
		}
	};

	/**
	 * 로그인 시
	 * @param {String} email
	 * @param {String} password
	 * @returns {Promise - Boolean}
	 */
	const login = async (email, password) => {
		const d = {
			email: email,
			password: password,
		};

		try {
			let result = null;
			// if (d.email === "dev@inroes.co.kr") result = await devSignIn(); //개발용;
			// else
			result = await signIn(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				window.localStorage.setItem(`${LOCAL_STORAGE_KEY.ACCESS_TOKEN}`, result.data.accessToken);
				window.localStorage.setItem(`${LOCAL_STORAGE_KEY.EMAIL}`, result.data.email);
				window.localStorage.setItem(`${LOCAL_STORAGE_KEY.REFRESH_TOKEN}`, result.data.refreshToken);
				// window.localStorage.setItem( "userData", d.userData );
				if (await setUserData()) return true;
				else return false;
			} else {
				window.linmeLog.log(result);
				return {
					result: false,
					type: "text",
					message: result.message,
				};
				// return false;
			}
		} catch (err) {
			return {
				result: false,
				type: "html",
				message: err?.response?.data?.message || htmlInfo.error.login,
			};
		}
	};

	/**
	 * 로그인 여부 체크
	 */
	const loginCheck = async () => {
		if (!isLogin || user === null) {
			const accessToken = window.localStorage.getItem(`${LOCAL_STORAGE_KEY.ACCESS_TOKEN}`);
			const email = window.localStorage.getItem(`${LOCAL_STORAGE_KEY.EMAIL}`);
			const refreshToken = window.localStorage.getItem(`${LOCAL_STORAGE_KEY.REFRESH_TOKEN}`);
			if (accessToken && email && refreshToken) {
				const check = await setUserData();
				if (check) return true;
				else return false;
			} else return true;
		} else return true;
	};

	/**
	 * 소셜로그인 시
	 * @param {String} accessToken//각 소셜측에서 받은 accessToken;
	 * @param {String} type
	 */
	const loginSocial = async (accessToken, type) => {
		try {
			const d = {
				accessToken: accessToken,
				type: type,
			};
			const result = await signInSocial(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				window.localStorage.setItem(`${LOCAL_STORAGE_KEY.ACCESS_TOKEN}`, result.data.accessToken);
				window.localStorage.setItem(`${LOCAL_STORAGE_KEY.EMAIL}`, result.data.email);
				window.localStorage.setItem(`${LOCAL_STORAGE_KEY.REFRESH_TOKEN}`, result.data.refreshToken);
				// window.localStorage.setItem( "userData", d.userData );
				if (await setUserData()) return true;
				else return false;
			} else {
				window.linmeLog.log(result);
				return {
					result: false,
					message: result.message,
				};
			}
		} catch (err) {
			window.linmeLog.error(err);
			return {
				result: false,
				message: err?.response?.data?.message,
			};
		}
	};

	/**
	 * 로그아웃 시
	 * @returns {Promise - Boolean}
	 */
	const logout = async () => {
		try {
			const d = {
				refreshToken: window.localStorage.getItem(`${LOCAL_STORAGE_KEY.REFRESH_TOKEN}`),
			};
			const result = await signOut(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				await clear();
				return true;
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (err) {
			window.linmeLog.error(err);
			return false;
		}
	};

	/**
	 * 사용자 정보 업데이트
	 * @param {Object} d
	 */
	const modifyUserData = async (d) => {
		let o = copyStructure(user);
		for (let key in d) {
			o[key] = d[key];
		}

		if (!o.image) {
			o.image = {
				fileNo: -1,
				webPath: EMPTY_USER_PROFILE_IMAGE,
			};
		}

		setUser(o);
	};

	/**
	 * 회원가입 ( 일반 )
	 * @param {Object} d
	 * <code>
	 * {
	 * 		address1: ""
	 * 		, address2: ""
	 * 		, birth: ""
	 * 		, ci: ""
	 * 		, email: ""
	 * 		, gender: ""
	 * 		, isCheckAdEmail: false
	 * 		, isCheckAdSms: false
	 * 		, name: ""
	 * 		, nickname: ""
	 * 		, password: ""
	 * 		, passwordConfirm: ""
	 * 		, phone: ""
	 * 		, postcode: ""
	 * }
	 * </code>
	 */
	const signUp = async (d) => {
		try {
			const result = await requestSignup(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				await window.localStorage.setItem(
					`${LOCAL_STORAGE_KEY.ACCESS_TOKEN}`,
					result.data.accessToken
				);
				await window.localStorage.setItem(`${LOCAL_STORAGE_KEY.EMAIL}`, result.data.email);
				await window.localStorage.setItem(
					`${LOCAL_STORAGE_KEY.REFRESH_TOKEN}`,
					result.data.refreshToken
				);
				const isSetUser = await setUserData();
				if (isSetUser) {
					const isSetAddress = await setAddress(d);
					if (isSetAddress) return true;
					else return false;
				}
				return isSetUser;
			} else {
				window.linmeLog.log(result);
				return {
					result: false,
					type: "text",
					message: result.message,
				};
				// return false;
			}
		} catch (err) {
			window.linmeLog.error(err);
			if (err.response.data && err.response.data.message) {
				return {
					result: false,
					type: "text",
					message: err.response.data.message,
				};
			} else {
				return {
					result: false,
					type: "html",
					message: htmlInfo.error.signUp,
				};
			}
			// return false;
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// SETTER;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 일반 가입 후 회원 주소로 기본배송지 설정
	 */
	const setAddress = async (data) => {
		try {
			const d = {
				nickname: data.nickname, //배송지명;
				name: data.name, //수취인;
				phone: data.phone, //연락처;
				postcode: data.postcode, //우편번호;
				address1: data.address1, //주소;
				address2: data.address2, //상세주소;
				memo: null, //배송요청사항;
				isCheckRep: true, //기본 배송지 설정;
			};
			const result = await addAddress(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) return result.data;
			else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (err) {
			window.linmeLog.error(err);
			return false;
		}
	};

	/**
	 * 사용자 정보 관련 설정
	 */
	const setUserData = async () => {
		let d = {};

		//장바구니 개수;
		const resultCartCount = await requestGetCartCount();
		if (!resultCartCount) return false;
		d.cartCount = resultCartCount.count;

		//회원 정보 조회;
		const resultProfile = await requestGetProfile();
		if (!resultProfile) return false;
		d = await mergeObject(d, resultProfile);
		if (!d.image) {
			d.image = {
				fileNo: -1,
				webPath: EMPTY_USER_PROFILE_IMAGE,
			};
		}

		//회원 통계 조회 - 주문 수, 리뷰 수, 쿠폰 수;
		const resultStatistics = await requestGetStatistics();
		if (!resultStatistics) return false;
		d = await mergeObject(d, resultStatistics);

		//읽지 않은 알림 여부;
		const resultCheckNotification = await requestCheckNotificationUnread();
		if (!resultCheckNotification) return false;
		d = await mergeObject(d, resultCheckNotification);

		await setIsLogin(true);
		await setUser(d);

		return true;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<Auth.Provider
			value={{
				user,
				// , setUser
				isLogin,
				setIsLogin,
				login,
				loginCheck,
				loginSocial,
				logout,
				modifyUserData,
				setUserData,
				signUp,
				clear,
				checkNotification,
				checkStatistics,
			}}
		>
			{children}
		</Auth.Provider>
	);
}

export default AuthProvider;
