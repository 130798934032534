/**
 * 마이페이지 - 프로필 수정
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import useAuth from "src/hooks/useAuth";
import useModalStack from "src/hooks/useModalStack";

import { modifyMemberProfile } from "src/api/member/modify";
import {
	API_RESULT_STATUS,
	EMPTY_USER_PROFILE_IMAGE,
	FILE_IMAGE_MAX_SIZE_MB,
	FILE_TYPE,
	FILE_UPLOAD_ACCEPT,
} from "src/data/constEnum";
import {
	handlerClickDuplicateCheckNickname,
	handlerKeyUpNickname,
	validationNickname,
} from "src/utils/linmeMember";
import {
	checkFileSize,
	checkFileType,
	requestUploadFile,
	requestDeleteFile,
	setPreview,
} from "src/utils/linmeFile";

import ModalCommonAlert from "src/pages/common/modalAlert";
import Image from "src/pages/component/common/image";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myPage/profile.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

const htmlInfo = require("src/data/htmlInfo.json");
const textInfo = require("src/data/textInfo.json");

const MyPageProfile = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const auth = useAuth();
	const navigate = useNavigate();
	const modalStack = useModalStack();
	const [file, setFile] = useState(null);

	const ref = {
		image: useRef(null),
		nickname: useRef(null),
		checkNickname: useRef(null),
		buttonApply: useRef(null),
		buttonDeleteImage: useRef(null),
		profileImageInput: useRef(null),
	};

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 프로필 이미지 삭제 클릭 시
	 * @param {Event} e
	 */
	const handlerClickDeleteImage = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		// input 값 초기화
		ref.profileImageInput.current.value = null;

		//fileNo로 파일 삭제 -> fileNo null 처리;
		ref.image.current.src = EMPTY_USER_PROFILE_IMAGE;
		ref.buttonDeleteImage.current.classList.remove("on");

		if (auth.user.image.fileNo !== -1) {
			ref.buttonApply.current.classList.add("on");
		} else {
			ref.buttonApply.current.classList.remove("on");
		}
		setFile(-1);
	};

	/**
	 * 파일 변경 시
	 * @param {Event} e
	 */
	const handlerFileChange = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const file = e.target.files[0];

		if (file) {
			setFile(file);
			if ((await checkFileType(file)) !== true) {
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.fileNotSupported,
				});
				return false;
			}
			if ((await checkFileSize(file)) !== true) {
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: "파일 크기는 " + FILE_IMAGE_MAX_SIZE_MB + "MB 이하만 업로드 가능합니다.",
				});
				return false;
			}

			//미리보기 설정;
			setPreview(file, ref.image);
			const elFigure = ref.image.current.closest(".profile");
			elFigure.classList.add("canBeDeleted");
			ref.buttonDeleteImage.current.classList.add("on");
			ref.buttonApply.current.classList.add("on");
		}
	};

	/**
	 * 취소 클릭 시
	 * @param {Event} e
	 */
	const handlerClickCancel = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		/*/
		//업로드했던 파일이 있는 경우 삭제;
		if( fileNo ) {
			await requestDeleteFile( fileNo )
			.then((data) => {
				//auth.setUser( data );
			})
			.catch((error) => {
			});
		}
		//*/

		//마이페이지로 이동;
		navigate(pathList.myPage.index);
	};

	/**
	 * 완료 클릭 시
	 * @param {Event} e
	 */
	const handlerClickApply = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		// const check = validationNickname(ref.nickname, ref.checkNickname);

		if (!ref.buttonApply.current.classList.contains("on")) {
			return false;
		}

		let fileNo = -1;

		// 기존 프로필 파일이 존재하지 않고 새로 등록한 파일이 있는 경우
		if (auth.user.image.fileNo === -1 && file) {
			const result = await _requestUploadFile();
			if (result) {
				fileNo = result;
			}
		}

		// 기존 프로필 파일이 존재하는 경우
		if (auth.user.image.fileNo > -1) {
			if (!file) {
				// 새로 등록한 파일이 없는 경우
				fileNo = auth.user.image.fileNo;
			} else if (file === -1) {
				// 기존 프로필 파일을 삭제한 경우
				await _requestDeleteFile();
			} else {
				// 새로 등록한 파일이 있는 경우
				await _requestDeleteFile();
				const result = await _requestUploadFile();
				if (result) {
					fileNo = result;
				}
			}
		}

		//수정 요청;
		await requestModifyMemberProfile(fileNo)
			.then(async (data) => {
				await setFile();
				await auth.modifyUserData(data);
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.changedProfile,
				});
			})
			.catch(async (error) => {
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					html: htmlInfo.error.profileModify,
				});
			});
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 회원 정보 수정 요청
	 * @returns
	 */
	const requestModifyMemberProfile = async (fileNo) => {
		try {
			let d = {
				fileNo: fileNo,
				nickname: ref.nickname.current.value,
			};
			const result = await modifyMemberProfile(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				return result.data;
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	/**
	 * 프로필 파일 업로드
	 * @returns
	 */
	const _requestUploadFile = async () => {
		const result = await requestUploadFile(FILE_TYPE.MEMBER, [file]);

		if (result[0]) {
			return result[0];
		} else {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.error.fileUpload,
			});
		}
	};

	/**
	 * 기존 프로필 파일이 존재하는 경우 삭제
	 */
	const _requestDeleteFile = async () => {
		await requestDeleteFile(auth.user.image.fileNo).catch(async (error) => {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.error.profileModify,
			});
		});
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="container myPage profileContainer">
			<div className="contents">
				<div className="title">{textInfo.myPage.profileModify}</div>
				<div className="profileCover">
					<input
						ref={ref.profileImageInput}
						type="file"
						id="uploadProfile"
						accept={FILE_UPLOAD_ACCEPT.image}
						onChange={(e) => handlerFileChange(e)}
					/>
					<label htmlFor="uploadProfile">
						<figure className="profile">
							<Image
								className={
									"profileImage " +
									(auth.isLogin && auth.user.image && auth.user.image.fileNo > -1
										? "userProfile"
										: "empty")
								}
								src={auth.isLogin ? auth.user.image.webPath : ""}
								alt={auth.isLogin ? auth.user.nickname : "프로필"}
								forwardedRef={ref.image}
							/>
							<div
								className={
									"button deleteProfile " +
									(auth.isLogin && auth.user.image && auth.user.image.fileNo > -1 ? "on" : "")
								}
								ref={ref.buttonDeleteImage}
								onClick={(e) => {
									handlerClickDeleteImage(e);
								}}
							/>
							<figcaption>{textInfo.myPage.profile}</figcaption>
						</figure>
					</label>

					<div className="label">{textInfo.member.data.nickname}</div>
					<div className="userInput">
						<input
							type="text"
							ref={ref.nickname}
							min={2}
							max={12}
							defaultValue={auth.isLogin ? auth.user.nickname : ""}
							className="nickname"
							placeholder={textInfo.member.placeholder.nickname}
							onKeyUp={(e) => handlerKeyUpNickname(e, ref.checkNickname, ref.buttonApply)}
						/>
						<input type="hidden" ref={ref.checkNickname} name="checkNickname" />
						<div
							className="button clickable duplicateCheck"
							onClick={(e) =>
								handlerClickDuplicateCheckNickname(
									e,
									ref.nickname,
									ref.checkNickname,
									ref.buttonApply
								)
							}
						>
							{textInfo.button.duplicateCheck}
						</div>
						<div className="guide">
							<div className="inputGuide">{textInfo.member.guide.nickname.character}</div>
							<div className="inputGuideWarn"></div>
							<div className="inputGuideChecked"></div>
						</div>
					</div>
					<div className="buttonCover">
						<div className="button clickable cancel" onClick={(e) => handlerClickCancel(e)}>
							{textInfo.button.cancel}
						</div>
						<div
							className="button clickable applyNickname"
							onClick={(e) => handlerClickApply(e)}
							ref={ref.buttonApply}
						>
							{textInfo.button.complete}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MyPageProfile;
