/** 탑메뉴 - underHeader */
import { Link } from "react-router-dom";

const pathList = require("src/path/pathJSON.json");
const textInfo = require("src/data/textInfo.json");
import { TOP_MENU_ACTIVE_TYPE } from "src/data/constEnum";

const TopMainUnderHeader = (props) => {
	const { menuType, handlerClickCategory } = props;

	return (
		<div className="underHeaderContainer">
			{/* 카테고리 */}
			<div className="categoryContainer" onClick={(e) => handlerClickCategory(e)}>
				<div className="categoryIcon"></div>
				<div className="categoryLabel"></div>
			</div>
			{/* 메뉴 */}
			<div className="headerItem navMenu">
				<Link
					to={pathList.product.new}
					className={menuType === TOP_MENU_ACTIVE_TYPE.NEW ? "activate" : "deactivate"}
				>
					{textInfo.menu.new}
				</Link>
				<Link
					to={pathList.product.best}
					className={menuType === TOP_MENU_ACTIVE_TYPE.BEST ? "activate" : "deactivate"}
				>
					{textInfo.menu.best}
				</Link>
				<Link
					to={pathList.product.special}
					className={menuType === TOP_MENU_ACTIVE_TYPE.SPECIAL ? "activate" : "deactivate"}
				>
					{textInfo.menu.special}
				</Link>
			</div>
			<div className="headerItem"></div>
		</div>
	);
};

export default TopMainUnderHeader;
