/**
 * 메인/네비게이션바 - 카테고리
 */
import { useContext, useEffect, useState } from "react";

import { AppContext } from "src/App";
import { DEVICE_TYPE } from "src/data/constEnum";
import { setBodyScroll } from "src/utils/linmeDisplay";

import useCategory from "src/hooks/product/useCategory";
import CategoryListMobile from "src/pages/component/categoryListMobile";
import CategoryListPC from "src/pages/component/categoryListPC";

import "src/styles/categoryList.scss";

const CategoryList = (props) => {
	const { categoryList } = useCategory();

	const { device } = useContext(AppContext);

	/**
	 * 배경 클릭 시 토글
	 * @param {Event} e
	 */
	const handlerClickBackground = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (props.handlerClickToggle) await props.handlerClickToggle();
	};

	/**
	 * 컨테이너 내부 클릭 시 동작 안하도록 설정
	 * @param {Event} e
	 * @returns
	 */
	const handlerClickContainer = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		return false;
	};

	/**
	 * 영역을 벗어난 경우
	 * @param {Event} e
	 */
	const handlerMouseLeaveListCover = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		// if( props.handlerMouseOut ) props.handlerMouseOut(e);
	};

	useEffect(() => {
		setBodyScroll(!props.isShow);
	}, [props.isShow]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div
			className={"categoryListContainer " + (props.isShow ? "on " : "")}
			onClick={(e) => handlerClickBackground(e)}
		>
			<div
				className="categoryListCover"
				onMouseLeave={(e) => handlerMouseLeaveListCover(e)}
				onClick={(e) => handlerClickContainer(e)}
			>
				{/* 모바일 */}
				{device !== DEVICE_TYPE.PC ? (
					<CategoryListMobile
						handlerClickToggle={props.handlerClickToggle}
						categoryList={categoryList}
					/>
				) : (
					<CategoryListPC
						handlerClickToggle={props.handlerClickToggle}
						categoryList={categoryList}
					/>
				)}
			</div>
		</div>
	);
};

export default CategoryList;
