import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { COLOR } from "src/data/constEnum";

import Image from "src/pages/component/common/image";

export const Container = styled(ToastContainer)`
	&&&.Toastify__toast-container {
		&.Toastify__toast-container--top-center {
			top: 100px !important;
		}
		.Toastify__toast {
			width: fit-content;
			height: fit-content;
			min-height: unset;
			font-family: inherit !important;
			font-size: 16px;
			border-radius: 50px;
			margin: unset;
			padding: unset;

			div {
				width: fit-content;
				height: fit-content;
				font-size: 15px;
				font-weight: normal;
				line-height: 1.67;
				letter-spacing: -0.3px;
				text-align: center;
				color: ${COLOR.WHITE};
				box-shadow: unset;
				border-radius: 17.5px;
				margin: unset;
				padding: 9px 24px 8px 25px;
			}
			&.Toastify__toast--info {
				background: rgba(36, 36, 36, 0.76);
			}

			&.Toastify__toast--success {
				background: rgba(48, 173, 120, 0.8);
			}

			&.Toastify__toast--error {
				background: rgba(224, 72, 82, 0.8);
			}

			.Toastify__toast-body {
				margin: unset;
				padding: unset;
				.Toastify__toast-icon {
					display: none;
				}
			}
		}
	}
`;

const toastOptions = {
	position: "top-center",
	autoClose: 2000,
	hideProgressBar: true,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	pauseOnFocusLoss: true,
	closeButton: false,
};

export function showToast({ type, message, action = "바로가기" }) {
	switch (type) {
		case "success":
			toast.success(message || "성공적으로 완료되었습니다", {
				...toastOptions,
				icon: null,
			});
			break;
		case "error":
			toast.error(message || "다시 한번 시도해주세요", {
				...toastOptions,
				icon: <Image src="/svg/toast_error.svg" alt="error" />,
			});
			break;
		case "info":
			toast.info(message || "다시 한번 시도해주세요", {
				...toastOptions,
				icon: null,
			});
			break;
		default:
			toast.info(message || "다시 한번 시도해주세요", {
				...toastOptions,
				icon: null,
			});
			break;
	}
	/*/
// With a string
toast.success("You can provide any string", {
	icon: "🚀"
});

// custom icons have access to the theme and the toast type
toast.success("And of course a component of your choice", {
	icon: MyIcon
});

toast.success("Even a function, given you return something that can be rendered", {
	icon: ({theme, type}) =>	<Image src={url} alt="" />
});
		//*/
}

export default function Toast() {
	return <Container />;
}
