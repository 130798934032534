/**
 * 수정 가능한 상품 옵션 목록 (필수,옵션)
 * 장바구니
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useRef } from "react";

import { setProductQuantityFromCart } from "src/api/order/cart";
import { API_RESULT_STATUS } from "src/data/constEnum";
import useModalStack from "src/hooks/useModalStack";
import { removeInnerSpinner, setInnerSpinner } from "src/utils/linmeDisplay";

import ModalCommonAlert from "src/pages/common/modalAlert";
import NumberStepper from "src/pages/component/common/numberStepper";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/order/productOptionsEdit.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const ProductOptionsEdit = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();
	const ref = {
		list: useRef(null),
	};

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 상품 수량 변경 시
	 * @param {Object} target
	 * @param {Number} count
	 * @param {Number} targetIndex
	 */
	const handlerChangeQuantity = async (target, count, targetIndex) => {
		if (count === 0 || count === props.quantity) return false;

		if (target) {
			await setInnerSpinner();
			await requestSetProductCount(target.cartNo, count).then(async (data) => {
				if (props.afterChange) await props.afterChange(data);

				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.changedProductCount,
				});

				await removeInnerSpinner();
			});
		}
	};

	/**
	 * 옵션 삭제 클릭 시
	 * @param {Event} e
	 * @param {Object} target
	 * @param {Number} index
	 */
	const handlerClickDeleteOption = async (e, target) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (target) {
			//삭제 validation;
			const resultValidation = await validationDeleteOption(target);
			if (resultValidation) {
				await props?.requestDeleteOptionFromCart(target.cartNo);
			}
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 옵션별 상품 수량 변경 요청
	 * @param {Number} cartNo
	 * @param {Number} count
	 * @returns
	 */
	const requestSetProductCount = async (cartNo, count) => {
		try {
			const d = {
				cartNo: cartNo,
				quantity: count,
			};
			const result = await setProductQuantityFromCart(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				const data = result.data;
				return data;
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	/**
	 * validation - 옵션별 삭제
	 * @param {Object} target
	 * @returns
	 */
	const validationDeleteOption = async (target) => {
		if (props.validationDeleteOption) {
			const result = await props.validationDeleteOption(target);
			return result;
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	if (!props?.list || props?.list?.length < 1) return <></>;

	return (
		<div className="productOptionEditContainer">
			{props?.list?.map((option, index) => (
				<div className="productOptionEditItem" key={index}>
					<div className="productOptionEditItemInfo">
						<div className="productOptionEditItemInfoName">{option.optionFullName}</div>
						<div className="productOptionEditItemInfoPrice">
							{option?.totalPrice?.toLocaleString() || 0}
							{textInfo.common.currencyUnit}
						</div>
					</div>
					<div className="productOptionEditItemAction">
						<NumberStepper
							min={1}
							max={9999}
							setCount={handlerChangeQuantity}
							count={option.quantity}
							target={option}
							targetIndex={index}
						/>
						<div
							className="productOptionEditItemActionDelete"
							onClick={(e) => {
								handlerClickDeleteOption(e, option);
							}}
						/>
					</div>
				</div>
			))}
		</div>
	);
};

export default ProductOptionsEdit;
