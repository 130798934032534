/**
 * 마이린미 - 알람 추가 - 상품선택 모달
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useRef, useState } from "react";

import { PAGINATION_DEFAULT_OFFSET } from "src/data/constEnum";
import useInfiniteScroll from "src/hooks/useInfiniteScroll";
import useModalStack from "src/hooks/useModalStack";

import { getAlarmProductList } from "src/api/myLinme/alarm";
import Image from "src/pages/component/common/image";
import InfiniteTrigger from "src/pages/component/infiniteTrigger";

import MyLinmeAlarmCreateModify from "src/pages/myLinme/alarm/modal/alarmCreateModify";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myLinme/selectProduct.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const SelectProductModal = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const [productList, setProductList] = useState([]);
	const [selectedProduct, setSelectedProduct] = useState("");

	const modalStack = useModalStack();
	const ref = { list: useRef(null) };
	const infiniteScroll = useInfiniteScroll({
		fetchFn: getAlarmProductList,
		param: { offset: PAGINATION_DEFAULT_OFFSET.DEFAULT },
		setDataList: setProductList,
		ref: ref.list,
	});

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * "취소" 클릭 시
	 * @param {Event} e
	 */
	const handlerClickCancel = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		// 선택 초기화
		modalStack.removeModal(props.id);
	};

	/**
	 * "완료" 클릭 시
	 * @param {Event} e
	 */
	const handlerClickSave = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (selectedProduct === "") return;

		// 현재 모달 닫고 알람 추가 모달 열기
		await modalStack.removeModal(props.id);
		await modalStack.addModal({
			id: "modalAlarmModify",
			type: "confirm",
			component: (
				<MyLinmeAlarmCreateModify
					handlerAfterCreate={props?.handlerAfterCreate}
					handlerAfterModify={props?.handlerAfterModify}
				/>
			),
			button: {
				iconClose: false,
			},
			productItem: selectedProduct,
		});
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		infiniteScroll.setInitData();
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<>
			<div className="title">{textInfo.myLinme.alarm.selectProduct}</div>
			<div className="myLinmeProductList" ref={ref.list}>
				{productList.length > 0 ? (
					<>
						{productList.map((item, index) => {
							return (
								<div
									className="product"
									key={index}
									onClick={() => {
										setSelectedProduct(item);
									}}
								>
									<div className="selectRefundTargetCover">
										<input
											type="checkbox"
											readOnly
											checked={item?.orderItemNo === selectedProduct?.orderItemNo}
										/>
										<label />
									</div>
									<div className="productImageCover">
										<Image
											className="productImage"
											src={item.productImagePath}
											alt={item.productName}
										/>
									</div>
									<div className="productInfo">
										<div className="productName">{item.productName}</div>
										<div className="productDate">
											{item.remainQty}
											{item.unit} | {item.confirmDate}
										</div>
									</div>
								</div>
							);
						})}
						<InfiniteTrigger ref={infiniteScroll.triggerRef} />
					</>
				) : (
					<div className="myLinmeProductList noData" />
				)}
			</div>

			<div className="buttonCover">
				<div className="button cancel" onClick={(e) => handlerClickCancel(e)}>
					{textInfo.button.cancel}
				</div>
				{productList.length > 0 && (
					<div className="button save" onClick={(e) => handlerClickSave(e)}>
						{textInfo.button.complete}
					</div>
				)}
			</div>
		</>
	);
};

export default SelectProductModal;
