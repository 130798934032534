/**
 * 주문 환불
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useAuth from "src/hooks/useAuth";
import useModalStack from "src/hooks/useModalStack";
import { copyStructure } from "src/utils/obj";
import { copyCollection } from "src/utils/arrayCollection";
import { checkAvailableVirtualNumber } from "src/api/order/order";
import { getDeliveryCompany } from "src/api/etc/delivery";
import { getClaimReason } from "src/api/myShopping/claim";
import { expectedRefundAmount, orderRefund } from "src/api/myShopping/refund";
import { getRewardStatus } from "src/api/member/reward";
import {
	API_RESULT_STATUS,
	CLAIM_TYPE,
	COLOR,
	PAYMENT_METHOD_VISIBLE_LIST,
	PAYMENT_RESULT_STATUS,
	PRODUCT_OPTIONS_TYPE,
	RETURN_DELIVERY_TYPE,
	RETURN_DELIVERY_LIST,
	VERIFY_ENV,
} from "src/data/constEnum";
import {
	makeClaimReasonSelectOptions,
	makeDeliveryCompanySelectOptions,
} from "src/utils/linmeClaim";
import { setInnerSpinner, removeInnerSpinner } from "src/utils/linmeDisplay";
import {
	checkPaymentProcessing,
	handlerClickSetRewardAll,
	handlerClickCancelRewardAll,
	setCheckPaymentNo,
	validationReward,
} from "src/utils/linmePayment";
import { isMobile } from "react-device-detect";

import ModalCommonAlert from "src/pages/common/modalAlert";
import Image from "src/pages/component/common/image";
import MyPageAddressBook from "src/pages/myPage/addressBook";
import VirtualNumber from "src/pages/payment/modal/virtualNumber";
import ReturnDiscountInfo from "src/pages/claim/modalReturnDiscountInfo";
import PaymentIframe from "src/pages/payment/modal/paymentIframe";

import CheckOrderProduct from "src/pages/claim/checkOrderProduct";
import Reason from "src/pages/claim/reason";
import PickupMethod from "src/pages/claim/pickupMethod";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/claim/claimOrderRefund.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

const htmlInfo = require("src/data/htmlInfo.json");
const textInfo = require("src/data/textInfo.json");

const ClaimOrderRefund = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const auth = useAuth();
	const location = useLocation();
	const modalStack = useModalStack();
	const navigate = useNavigate();
	const [order, setOrder] = useState(null);
	const [dataList, setDataList] = useState(null);

	const [reasonNo, setReasonNo] = useState(-1);
	const [refundReasonList, setRefundReasonList] = useState(null);
	const [deliveryCompanyList, setDeliveryCompanyList] = useState(null);
	const [deliveryType, setDeliveryType] = useState(RETURN_DELIVERY_TYPE.COMPANY);
	const [deliveryCompanyNo, setDeliveryCompanyNo] = useState(-1);
	const [rewardData, setRewardData] = useState({});

	const [addressNo, setAddressNo] = useState(-1);

	const [expectedRefundInfo, setExpectedRefundInfo] = useState(null);
	const [totalPrice, setTotalPrice] = useState(null);
	const [itemNoList, setItemNoList] = useState(null);
	const ref = {
		address: {
			name: useRef(null),
			phone: useRef(null),
			postcode: useRef(null),
			address1: useRef(null),
			address2: useRef(null),
		},
		button: {
			applyRewardAll: useRef(null),
			cancelRewardAll: useRef(null),
		},
		checkVirtualNumber: useRef(null),
		deliveryCompany: useRef(null),
		deliveryInfoDetail: useRef(null),
		invoiceNo: useRef(null),
		methodCover: useRef(null),
		paymentMethod: useRef(null),
		pickupAddress: useRef(null),
		reason: useRef(null),
		reasonDetail: useRef(null),
		reward: useRef(null),
	};
	const modalId = {
		payment: "modalClaimPayment",
		paymentSuccess: "modalCommonAlert",
		paymentFail: "modalCommonAlert",
	};

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 리워드 Blur
	 * @param {Event} e
	 */
	const handlerBlurReward = async (e) => {
		if (rewardData && rewardData.availableReward) {
			const resultValidation = await validationReward(
				totalPrice.deliveryPrice,
				null,
				rewardData.availableReward,
				ref.reward
			);
			await setRewardAmount();
			if (resultValidation.result === false) {
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: resultValidation.text,
				});
				return false;
			}
		}
	};

	/**
	 * 옵션 개수 변경 시
	 * @param {Object|Null} target
	 * @param {Number} count
	 * @param {Number} targetIndex
	 */
	const handlerChangeOptionQuantity = async (target, count, targetIndex) => {
		if (targetIndex !== null && targetIndex !== undefined) {
			let o = await copyCollection(dataList);
			if (o[targetIndex].quantity === count) {
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.sameAsExistingData,
				});
				return false;
			}
			o[targetIndex].quantity = count;
			await setDataList(o);
			// await setProductPrice( o );
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.changedProductCount,
			});
		}
	};

	/**
	 * 환불 배송비 결제 실패 후 모달 확인 클릭 시
	 * @param {Event} e
	 */
	const handlerClickModalPaymentFail = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.removeModal(modalId.paymentFail);
		navigate(pathList.myPage.shoppingOrder);
	};

	/**
	 * 환불 배송비 결제 성공 후 모달 확인 클릭 시
	 * @param {Event} e
	 */
	const handlerClickModalPaymentSuccess = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.removeModal(modalId.paymentSuccess);
		navigate(pathList.myPage.shoppingClaim);
	};

	/**
	 * 반환 할인액 물음표 클릭 시
	 * @param {Event} e
	 * @param {String} couponName
	 */
	const handlerClickReturnDiscountInfo = async (e, couponName) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await modalStack.addModal({
			id: "modalReturnDiscountInfo",
			type: "alert",
			component: <ReturnDiscountInfo />,
			couponName: couponName,
		});
	};

	/**
	 * 변경할 주소 선택 시
	 * @param {Number} refundAddressNo
	 */
	const handlerRefundAddress = async (addressType, refundAddressNo, data) => {
		await modalStack.removeModal("modalRefundPickupAddress");

		ref.address.name.current.innerText = data.name;
		ref.address.phone.current.innerText = "(" + data.phone + ")";
		ref.address.postcode.current.innerText = data.postcode;
		ref.address.address1.current.innerText = data.address1;
		ref.address.address2.current.innerText = data.address2;
		await setAddressNo(refundAddressNo);

		const result = await requestGetDeliveryPrice(null, refundAddressNo, null);
		if (result) {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.pickupAddressChanged,
			});
		}
	};

	/**
	 * 수거방법 변경 시
	 * @param {Event} e
	 */
	const handlerRefundDeliveryType = async (e) => {
		const ct = e.currentTarget;
		await setDeliveryType(ct.value);

		switch (ct.value) {
			case RETURN_DELIVERY_TYPE.COMPANY: //택배수거요청;
				ref.pickupAddress.current.classList.add("on"); //회수지 정보;
				ref.deliveryCompany.current.classList.remove("on"); //택배사 목록;
				ref.deliveryInfoDetail.current.classList.remove("on"); //반송정보;
				break;
			case RETURN_DELIVERY_TYPE.DIRECT: //직접 발송;
				ref.pickupAddress.current.classList.remove("on"); //회수지 정보;
				ref.deliveryCompany.current.classList.add("on"); //택배사 목록;
				ref.deliveryInfoDetail.current.classList.add("on"); //반송정보;
				break;
			case RETURN_DELIVERY_TYPE.LATER: //나중에 입력;
				ref.pickupAddress.current.classList.remove("on"); //회수지 정보;
				ref.deliveryCompany.current.classList.remove("on"); //택배사 목록;
				ref.deliveryInfoDetail.current.classList.remove("on"); //반송정보;
				break;
			default:
				break;
		}

		await requestGetDeliveryPrice(ct.value, null, null);
	};

	/**
	 * 리워드 입력 시
	 * @param {Event} e
	 */
	const handlerRefundReward = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const resultValidation = await validationReward(
			totalPrice.deliveryPrice,
			null,
			rewardData.availableReward,
			ref.reward
		);
		await setRewardAmount();
		if (resultValidation.result === false) {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: resultValidation.text,
			});
			ref.paymentMethod.current.style.display = "block";
			return false;
		} else {
			if (ref.reward.current.value === totalPrice.deliveryPrice) {
				ref.paymentMethod.current.style.display = "none";
			}
		}
	};

	/**
	 * 안심번호 서비스 가능 여부 확인
	 * @param {Event} e
	 */
	const handlerRefundUseVirtualNumber = async (e) => {
		const ct = e.currentTarget;
		if (ct.checked) {
			//안심번호 서비스 가능 여부 확인;
			await requestCheckAvailableVirtualNumber();
		}
	};

	/**
	 * 주소지 변경 클릭 시
	 * @param {Event} e
	 * @param {String} addressType//pickup, receive;
	 */
	const handlerClickRefundAddress = (e, addressType) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.addModal({
			id: "modalRefundPickupAddress",
			type: "alert",
			component: <MyPageAddressBook />,
			button: {
				iconClose: true,
			},
			isModal: true,
			addressType,
			selectedAddressNo: addressNo,
			afterSelected: handlerRefundAddress,
		});
	};

	/**
	 * 환불요청 클릭 시
	 * @param {Event} e
	 */
	const handlerClickRefund = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const check = await validation();
		if (check) {
			await setInnerSpinner();
			const result = await requestOrderRefund();
			/*/
			if ( result ) {
				//navigate( pathList, { state: { result }});
				await modalStack.addModal({
					id: "modalCommonAlert"
					, type: "alert"
					, component: <ModalCommonAlert />
					, text: textInfo.alert.completeOrderRefundRequest
				});
			}
			//*/
		}
	};

	/**
	 * 안심번호 사용하지 못할 경우 출력 컨펌 확인 클릭 시
	 * @param {Event} e
	 */
	const handlerClickModalNotAvailableVirtualNumber = (e) => {
		ref.checkVirtualNumber.current.checked = false;
		ref.checkVirtualNumber.current.disabled = true;
		modalStack.removeModal("modalCommonConfirm");
	};

	/**
	 * 안심번호 사용하지 못할 경우 출력 컨펌 닫기 클릭 시
	 * @param {Event} e
	 */
	const handlerClickModalNotAvailableVirtualNumberClose = (e) => {
		modalStack.removeModal("modalCommonConfirm");
	};

	/**
	 * 안심번호 서비스 안내 보기
	 * @param {Event} e
	 */
	const handlerClickOpenModalVirtualNumber = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.addModal({
			id: "modalVirtualNumber",
			type: "alert",
			component: <VirtualNumber />,
		});
	};

	/**
	 * 리워드 전액 적용
	 * @param {Event} e
	 */
	const handlerClickRewardApplyAll = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const resultValidation = await handlerClickSetRewardAll(
			e,
			totalPrice.actualPrice,
			null,
			rewardData.availableReward,
			ref.reward,
			ref.button.applyRewardAll,
			ref.button.cancelRewardAll
		);
		await setRewardAmount();
		if (resultValidation.result === false) {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: resultValidation.text,
			});
			return false;
		}
	};

	/**
	 * 리워드 전액 취소
	 * @param {Event} e
	 */
	const handlerClickRewardCancelAll = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const resultValidation = await handlerClickCancelRewardAll(
			e,
			totalPrice.actualPrice,
			rewardData.availableReward,
			ref.reward,
			ref.button.applyRewardAll,
			ref.button.cancelRewardAll
		);
		await setRewardAmount();
		if (resultValidation.result === false) {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: resultValidation.text,
			});
			return false;
		}
	};

	/**
	 * 리워드 키 업
	 * @param {Event} e
	 */
	const handlerKeyUpReward = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		let key = e.key || e.keyCode;

		if (key === "Enter" || key === 13) {
			const resultValidation = await validationReward(
				totalPrice.deliveryPrice,
				null,
				rewardData.availableReward,
				ref.reward
			);
			await setRewardAmount();
			if (resultValidation.result === false) {
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: resultValidation.text,
				});
				return false;
			}
		}
	};
	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 결제 실패 후 실행
	 * @param {String} messageType
	 * @param {String} message
	 */
	const afterPaymentFail = async (messageType, message) => {
		await removeInnerSpinner();
		if (message === textInfo.APIError.orderTimeExpires) {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.alert.orderTimeExpires,
			});
			await navigate(-1);
		} else {
			const o = {
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
			};
			if (messageType === "text") o.text = message;
			else o.html = message;
			await modalStack.addModal(o);
		}
	};

	/**
	 * 결제 완료 이후 실행
	 */
	const afterPaymentSuccess = async () => {
		await removeInnerSpinner();
		await modalStack.addModal({
			id: "modalCommonAlert",
			type: "alert",
			component: <ModalCommonAlert />,
			text: textInfo.alert.completeOrderRefundRequest,
		});
	};

	/**
	 * 환불 예정 금액, 환불 요청 등을 위한 상품 정보를 설정하여 반환
	 * @param {Array} data
	 * @returns
	 */
	const getItemInfo = (data) => {
		let result = [];
		if (data && data.length) {
			let i = 0;
			const iLen = data.length;
			let io; //data[ i ];
			for (; i < iLen; ++i) {
				io = data[i];
				result.push({ itemNo: io.itemNo, quantity: io.quantity });
			}
		}
		return result;
	};

	/**
	 * 안심번호 사용 여부 확인 요청
	 * @returns
	 */
	const requestCheckAvailableVirtualNumber = async () => {
		try {
			const result = await checkAvailableVirtualNumber();
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (!result.data.isCheckAvailable) {
					await modalStack.addModal({
						id: "modalCommonConfirm",
						type: "confirm",
						component: <ModalCommonAlert />,
						html: htmlInfo.order.cannotUseVirtualNumber,
						button: {
							confirm: {
								text: textInfo.button.confirm,
								style: { width: "fit-content", padding: "3px 10px" },
								fn: handlerClickModalNotAvailableVirtualNumber,
							},
							cancel: {
								text: textInfo.button.cancel,
								style: { width: "fit-content", padding: "3px 10px", border: "unset" },
								fn: handlerClickModalNotAvailableVirtualNumberClose,
							},
						},
					});
				}
				return result.data;
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (error) {
			window.linmeLog.log(error);
			return false;
		}
	};

	/**
	 * 클레임 사유 조회
	 * @returns
	 */
	const requestGetClaimReason = async () => {
		try {
			const d = {
				type: CLAIM_TYPE.REFUND,
			};
			const result = await getClaimReason(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				const list = makeClaimReasonSelectOptions(CLAIM_TYPE.REFUND, result.data);
				await setRefundReasonList(list);
				return result.data;
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (error) {
			window.linmeLog.log(error);
			return false;
		}
	};

	/**
	 * 택배사 목록 요청
	 * @returns
	 */
	const requestGetDeliveryCompany = async () => {
		const result = await getDeliveryCompany();
		if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
			const list = [{ label: textInfo.claim.pickup.pleaseSelectDeliveryCompany, value: "" }].concat(
				makeDeliveryCompanySelectOptions(result.data)
			);
			await setDeliveryCompanyList(list);
			return result.data;
		} else {
			window.linmeLog.log(result);
			return false;
		}
	};

	/**
	 * 환불 예정 상품, 주소, 요청사유( 과실여부 )에 따른 반환 예상 금액 요청
	 * @param {String|Null} refundDeliveryType
	 * @param {Number|Null} refundAddressNo
	 * @param {Number|Null} reasonNo
	 * @param {Number|Null} orderNo
	 * @param {Array|Null} items
	 */
	const requestGetDeliveryPrice = async (
		refundDeliveryType,
		refundAddressNo,
		refundReasonNo,
		orderNo,
		items
	) => {
		//*/
		try {
			const itemList = await getItemInfo(items ? items : dataList);
			const d = {
				deliveryType: refundDeliveryType ? refundDeliveryType : deliveryType,
				items: itemList,
				collectMemberAddressNo: refundAddressNo ? refundAddressNo : addressNo,
				orderNo: orderNo ? orderNo : order.orderNo,
				reasonNo: refundReasonNo ? refundReasonNo : reasonNo,
			};
			if (d.collectMemberAddressNo === -1) delete d.collectMemberAddressNo;
			if (d.reasonNo === -1) delete d.reasonNo;

			const result = await expectedRefundAmount(d);

			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				const o = await copyStructure(totalPrice ? totalPrice : {});
				o.deliveryPrice = result.data.deliveryPrice; //추가 배송비;
				o.refundPrice = result.data.price; //환불 예정 금액;
				o.refundReward = result.data.reward; //환불 예정 리워드;
				o.returnDiscount = result.data.returnDiscount; //반환 할인 금액;
				o.coupon = result.data.coupon; //쿠폰;
				o.isCheckTobeCoupon = result?.data?.isCheckTobeCoupon; //복구예정 쿠폰;
				o.isCheckTobeCouponAvailable = result?.data?.isCheckTobeCouponAvailable; //쿠폰기한만료여부;
				await setTotalPrice(o);
				return result.data;
			} else {
				modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					html: result?.response?.data?.message || htmlInfo.error.dataCall,
				});
				window.linmeLog.log(result);
				return false;
			}
		} catch (error) {
			modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				html: error?.response?.data?.message || htmlInfo.error.dataCall,
			});
			window.linmeLog.log(error);
			return false;
		}

		/*/
		const o = await copyStructure( totalPrice ? totalPrice : {});
		o.deliveryPrice = 0;//추가 배송비;
		o.refundPrice = 0;//환불 예정 금액;
		o.refundReward = 0;//환불 예정 리워드;
		o.returnDiscount = 0;//반환 할인 금액;
		o.coupon = {
			"availableDateTime": "2024-04-29T04:06:03.171Z"
			, "availableDays": 0
			, "brand": {
				  "brandNo": 0
				  , "name": "string"
			}
			, "couponNo": 0
			, "couponType": "AMOUNT"
			, "couponValue": 0
			, "desc": "string"
			, "endAt": "2024-04-29T04:06:03.171Z"
			, "maxDiscount": 0
			, "memberCouponNo": 0
			, "minPrice": 0
			, "name": "string"
			, "startAt": "2024-04-29T04:06:03.171Z"
			  };//쿠폰;
		await setTotalPrice( o );
		//*/
	};

	/**
	 * 회원 리워드 정보 요청
	 */
	const requestGetRewardStatus = async () => {
		const result = await getRewardStatus();
		if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
			await setRewardData(result.data);
		} else {
			window.linmeLog.log(result);
		}
	};

	/**
	 * 환불 요청
	 */
	const requestOrderRefund = async () => {
		const d = {
			env: isMobile ? VERIFY_ENV.WEB.MOBILE : VERIFY_ENV.WEB.PC, //결제환경;
			deliveryType: deliveryType, //수거방법;
			collectMemberAddressNo: addressNo, //회수지 주소 번호;
			orderNo: order.orderNo, //주문번호;
			items: await getItemInfo(dataList), //환불상품정보;
			reasonNo: reasonNo, //환불사유번호;
		};
		//결제 필요;
		if (
			totalPrice &&
			totalPrice.deliveryPrice &&
			// (totalPrice.deliveryPrice || totalPrice.returnDiscount) &&
			ref.paymentMethod.current &&
			ref.paymentMethod.current.style.display !== "none"
		) {
			const method = ref.paymentMethod.current.querySelector(".orderRefundMethod:checked").value;
			d.paymentMethod = method; //결제유형;
		}

		if (ref.reasonDetail.current.value) d.content = ref.reasonDetail.current.value; //환불 사유 상세;
		if (deliveryType === RETURN_DELIVERY_TYPE.COMPANY) {
			//택배 수거 요청;
			//택배사 번호 삭제;
			delete d.companyNo;
			//송장번호 삭제;
			delete d.invoiceCode;
		} else if (deliveryType === RETURN_DELIVERY_TYPE.DIRECT) {
			//직접 발송;
			//택배사 번호 설정;
			d.companyNo = deliveryCompanyNo;
			//송장번호 설정;
			d.invoiceCode = ref.invoiceNo.current.value;
			//회수지 주소 번호 삭제;
			delete d.collectMemberAddressNo;
		} else if (deliveryType === RETURN_DELIVERY_TYPE.LATER) {
			//나중에 입력;
			//택배사 번호 삭제;
			delete d.companyNo;
			//송장번호 삭제;
			delete d.invoiceCode;
			//회수지 주소 번호 삭제;
			delete d.collectMemberAddressNo;
		}

		try {
			const result = await orderRefund(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (result.data.url) {
					await modalStack.addModal({
						id: modalId.payment,
						type: "none",
						component: <PaymentIframe {...result.data} />,
						setDataAfterPayment: setDataAfterPayment,
					});
					/*/
					const paymentPopup = window.open( pathList.payment.process + "?url=" + result.data.url, "paymentPopup", "width=820, height=620" );
					if ( paymentPopup.window ) {
						// paymentPopup.window.onbeforeunload = afterPayment;
						const form = window.document.createElement( "form" );
						const elUrl = window.document.createElement( "hidden" );
						elUrl.value = result.data.url;
						form.appendChild( elUrl );
						form.target = "paymentPopup";
						form.action = pathList.payment.process;
						// form.submit();

						window.paymentPopup = paymentPopup;
						await setCheckPaymentNo( result.data.paymentNo );
						await checkPaymentProcessing( afterPaymentSuccess, afterPaymentFail );//결제창이 닫혔는지 확인하는 타이머 설정;
					}
					//*/
				} else {
					await modalStack.addModal({
						id: "modalCommonAlert",
						type: "alert",
						component: <ModalCommonAlert />,
						text: textInfo.alert.completeOrderRefundRequest,
					});
					navigate(pathList.myPage.shoppingClaim);
				}
			} else {
				alert("환불 요청 실패");
			}
		} catch (error) {
			await removeInnerSpinner();
			alert("환불 요청 실패");
		} finally {
			await removeInnerSpinner();
		}
	};

	/**
	 * 결제 후 message 로 받은 데이터로 후처리 ( alert )
	 * @param {Object} d
	 *
	 */
	const setDataAfterPayment = async (d) => {
		if (d.result === PAYMENT_RESULT_STATUS.SUCCESS) {
			await removeInnerSpinner();
			await modalStack.addModalAfterRemove(
				{
					id: modalId.paymentSuccess,
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.completeOrderRefundRequest,
					button: {
						ok: {
							text: textInfo.button.confirm,
							fn: handlerClickModalPaymentSuccess,
						},
					},
				},
				modalId.payment
			);
		} else if (d.result === PAYMENT_RESULT_STATUS.FAIL) {
			await removeInnerSpinner();
			if (d.message === textInfo.APIError.orderTimeExpires) {
				await modalStack.addModalAfterRemove(
					{
						id: modalId.paymentFail,
						type: "alert",
						component: <ModalCommonAlert />,
						html: htmlInfo.alert.orderTimeExpires,
						button: {
							ok: {
								text: textInfo.button.confirm,
								fn: handlerClickModalPaymentFail,
							},
						},
					},
					modalId.payment
				);
				// navigate( -1 );
			} else {
				await modalStack.addModalAfterRemove(
					{
						id: modalId.paymentFail,
						type: "alert",
						component: <ModalCommonAlert />,
						text: d.message,
						button: {
							ok: {
								text: textInfo.button.confirm,
								fn: handlerClickModalPaymentFail,
							},
						},
					},
					modalId.payment
				);
			}
		}
	};

	/**
	 * 상품 금액 설정
	 * @param {Array} a
	 */
	const setProductPrice = async (a) => {
		const o = await copyStructure(totalPrice ? totalPrice : {});
		let productPrice = 0;
		let i = 0;
		const iLen = a.length;
		let io; //a[ i ];
		for (; i < iLen; ++i) {
			io = a[i];
			productPrice += io.totalPrice;
		}
		o.productPrice = productPrice;
		await setTotalPrice(o);
	};

	/**
	 * 입력된 리워드 적용
	 * @returns {Number}
	 */
	const setRewardAmount = async () => {
		const reward = ref.reward.current.value.replace(/[^0-9]/g, ""); //숫자만 적용;

		const o = await copyStructure(totalPrice ? totalPrice : {});
		o.useReward = parseInt(reward);
		if (o.deliveryPrice) {
			o.actualPrice = o.deliveryPrice - o.useReward;

			if (o.actualPrice === 0) {
				ref.paymentMethod.current.style.display = "none";
			} else {
				ref.paymentMethod.current.style.display = "flex";
			}
		}
		await setTotalPrice(o);

		/*/
		const result = await requestRefundOrder( null, null, parseInt( reward ) );
		if( result ) {
			await modalStack.addModal({
				id: "modalCommonAlert"
				, type: "alert"
				, component: <ModalCommonAlert />
				, text: textInfo.alert.memberRewardApplied
			});
		}
		//*/
	};

	/**
	 * 택배사 선택 후 적용
	 * @param {Object} o
	 */
	const setSelectedDeliveryCompany = (o) => {
		setDeliveryCompanyNo(o.value);
	};

	/**
	 * 주문 환불 사유 선택 후 적용
	 * @param {Object} o
	 */
	const setSelectedReason = async (o) => {
		const ct = ref.reason.current;
		ct.value = "";

		if (o.value) {
			//환불 사유 설정;
			await setReasonNo(parseInt(o.value));
			//배송비 조회;
			await requestGetDeliveryPrice(null, null, parseInt(o.value));
		}
	};

	/**
	 * validation
	 * @returns {Boolean}
	 */
	const validation = async () => {
		//환불사유 선택 확인;
		if (reasonNo === -1) {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.pleaseSelectReasonOrderRefund,
			});
			return false;
		}
		//수거방법 직접발송 시;
		if (deliveryType === RETURN_DELIVERY_TYPE.DIRECT) {
			//택배사 선택 확인;
			if (deliveryCompanyNo === -1) {
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.pleaseSelectDeliveryCompany,
				});
				return false;
			}
			//송장번호 입력 확인;
			if (!ref.invoiceNo.current.value) {
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.pleaseInputInvoiceNo,
				});
				return false;
			}
		}
		//배송비가 존재하는 경우 결제방식 선택;
		if (
			// totalPrice &&
			// (totalPrice.deliveryPrice || totalPrice.returnDiscount) &&
			// totalPrice.deliveryPrice + totalPrice.returnDiscount > 0
			totalPrice &&
			totalPrice.deliveryPrice &&
			totalPrice.deliveryPrice > 0
		) {
			if (!window.document.querySelector(".orderRefundMethod:checked")) {
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.pleaseSelectPaymentMethod,
				});
				return false;
			}
		}
		return true;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		const setData = async () => {
			const loginCheck = await auth.loginCheck();
			if (loginCheck) {
				const items = location.state?.items;
				const order = location.state?.order;
				const itemNoList = location.state?.itemNoList;
				if (!items && !order && !itemNoList) {
					await modalStack.addModal({
						id: "modalCommonAlert",
						type: "alert",
						component: <ModalCommonAlert />,
						text: textInfo.alert.errorNoExistsData,
					});
					await navigate(pathList.main);
					return false;
				}
				// window.linmeLog.log(JSON.stringify( items, null, 4 ));
				await setDataList(items); //환불상품정보;
				await setOrder(order); //주문정보;
				await setItemNoList(itemNoList);
				await requestGetClaimReason(); //주문환불사유;
				const priceAndDeliveryData = await requestGetDeliveryPrice(
					null,
					null,
					null,
					order.orderNo,
					items
				);
				if (priceAndDeliveryData && priceAndDeliveryData.address) {
					//회수지 정보;
					const o = priceAndDeliveryData.address;
					ref.address.name.current.innerText = o.name;
					ref.address.phone.current.innerText = "(" + o.phone + ")";
					ref.address.postcode.current.innerText = o.postcode;
					ref.address.address1.current.innerText = o.address1;
					ref.address.address2.current.innerText = o.address2;
					await setAddressNo(o.addressNo);
				}
				await requestGetDeliveryCompany(); //택배사 목록;
				// await requestGetRewardStatus();//리워드 정보;
				// await setProductPrice( items );//상품금액
			}
		};
		setData();
	}, [location.state]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="container underTopMenu claimContainer">
			<div className="contentsBackground">
				{/* <div className="contents">
					<ClaimProgressStage {...{
						on: "step1"
						, text: {
							step1: textInfo.orderRefund.stage.step1
							, step2: textInfo.orderRefund.stage.step2
						}
					}}/>
				</div> */}
				<div className="contents">
					<div className="refundLeftContainer">
						<div className="refundData">
							<div className="title">{textInfo.orderRefund.title}</div>
							<CheckOrderProduct
								claimType={CLAIM_TYPE.REFUND}
								dataList={dataList}
								handlerChangeOptionQuantity={handlerChangeOptionQuantity}
							/>
							<Reason
								refObject={ref}
								type={CLAIM_TYPE.REFUND}
								reasonList={refundReasonList}
								reasonNo={reasonNo}
								setSelectedReason={setSelectedReason}
							/>
							<PickupMethod
								refObject={ref}
								handlerChangePickupMethod={handlerRefundDeliveryType}
								handlerClickOpenModalVirtualNumber={handlerClickOpenModalVirtualNumber}
								handlerUseVirtualNumber={handlerRefundUseVirtualNumber}
								handlerChangeAddress={handlerClickRefundAddress}
								deliveryCompanyList={deliveryCompanyList}
								deliveryCompanyNo={deliveryCompanyNo}
								setSelectedDeliveryCompany={setSelectedDeliveryCompany}
							/>
						</div>
						{totalPrice && totalPrice.deliveryPrice ? (
							<div className="contents paymentInfo" ref={ref.methodCover}>
								<div className="refundData paymentMethod">
									<div className="title">{textInfo.payment.payMethod.title}</div>
									{/* <ul className="paymentDiscountList">
								<li className="paymentDiscountItem">
									<div className="label">{textInfo.payment.reward.title}</div>
									<div className="data">
										<input type="text"
											className={ "discountReward " + (rewardData && rewardData.availableReward ? "" : "readOnly" ) }
											defaultValue="0"
											ref={ref.reward}
											readOnly={ rewardData && rewardData.availableReward ? false : true }
											onChange={(e)=>handlerRefundReward(e)}
											onKeyUp={(e)=>handlerKeyUpReward(e)}
											onBlur={(e)=>handlerBlurReward(e)}
											/>
										<span className="rewardUnit">{textInfo.common.rewardUnit}</span><span className="rewardAll">{ ( rewardData && rewardData.availableReward ? rewardData.availableReward.toLocaleString() : 0 )}<span className="rewardUnit">{textInfo.common.rewardUnit}</span></span>
										<div className="buttonRewardCover">
										{ ( rewardData && rewardData.availableReward ? <div className="button applyRewardAll on"
											ref={ref.button.applyRewardAll}
											onClick={(e)=>handlerClickRewardApplyAll(e)}
											>{textInfo.payment.reward.useAll}</div> : <></> )}
										<div className="button cancelRewardAll"
											ref={ref.button.cancelRewardAll}
											onClick={(e)=>handlerClickRewardCancelAll(e)}
											>{textInfo.payment.reward.cancelAll}</div>
										</div>
									</div>
								</li>
							</ul>
							<ul className="rewardDescriptionList">
								<li className="rewardDescriptionItem">{textInfo.claim.payment.useRewardGuide.guide1}</li>
							</ul> */}
									<ul className="paymentMethodList" ref={ref.paymentMethod}>
										{PAYMENT_METHOD_VISIBLE_LIST.map((method, index) => (
											<li className="paymentMethodItem methodBox" key={index}>
												<input
													className="orderRefundMethod"
													name="orderRefundMethod"
													id={"orderRefundPayMethod" + method.value.toLowerCase()}
													type="radio"
													value={method.value}
													defaultChecked={method.value === "CARD"}
												/>
												<label
													className="orderRefundMethod"
													htmlFor={"orderRefundPayMethod" + method.value.toLowerCase()}
												>
													{method.label}
												</label>
											</li>
										))}
									</ul>
								</div>
							</div>
						) : (
							<></>
						)}
					</div>

					<div className="refundInfoContainer">
						<div className="refundInfo">
							<div className="title">{textInfo.orderRefund.orderRefundInfo}</div>
							<ul className="refundInfoList">
								{/* <li className="refundInfoItem">
									<div className="label">{textInfo.order.productPrice}</div>
									<div className="price productPrice">{ totalPrice && totalPrice.productPrice ? totalPrice.productPrice.toLocaleString() : 0}<span className="currencyUnit">{textInfo.common.currencyUnit}</span></div>
								</li> */}
								<li className="refundInfoItem refundPrice">
									{/* 환불 예정 금액 */}
									<div className="label">{textInfo.claim.price.restoreDiscountPrice}</div>
									<div className="price">
										{totalPrice && totalPrice.refundPrice
											? totalPrice.refundPrice.toLocaleString()
											: 0}
										<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
									</div>
								</li>
								{totalPrice && totalPrice.deliveryPrice ? (
									<li className="refundInfoItem deliveryPrice">
										{/* 반품 배송비 */}
										<div className="label">{textInfo.claim.price.refundDeliveryPrice}</div>
										<div className="price deliveryPrice">
											(-)&nbsp;
											{totalPrice && totalPrice.deliveryPrice
												? totalPrice.deliveryPrice.toLocaleString()
												: 0}
											<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
										</div>
									</li>
								) : (
									<></>
								)}
								{
									// totalPrice &&
									// totalPrice.coupon &&
									// totalPrice.coupon.name &&
									// totalPrice.returnDiscount ? (
									// 	<li className="refundInfoItem returnDiscount">
									// 		{/* 반환 할인 금액 */}
									// 		<div className="label">
									// 			{textInfo.claim.price.refundDiscount}
									// 			<div
									// 				className="returnDiscountInfo"
									// 				onClick={(e) =>
									// 					handlerClickReturnDiscountInfo(
									// 						e,
									// 						totalPrice.coupon && totalPrice.coupon.name
									// 							? totalPrice.coupon.name
									// 							: "-"
									// 					)
									// 				}
									// 			/>
									// 		</div>
									// 		<div className="price">
									// 			(-)&nbsp;
									// 			{totalPrice && totalPrice.returnDiscount
									// 				? totalPrice.returnDiscount.toLocaleString()
									// 				: 0}
									// 			<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
									// 		</div>
									// 	</li>
									// ) : (
									// 	<></>
									// )
								}
								<li className="restoreItem reward">
									{/* 환불 리워드 */}
									<div className="label">{textInfo.claim.price.restoreDiscountReward}</div>
									<div className="price">
										{totalPrice && totalPrice.refundReward
											? totalPrice.refundReward.toLocaleString()
											: 0}
										<span className="rewardUnit">{textInfo.common.rewardUnit}</span>
									</div>
								</li>
								<li className="restoreItem coupon">
									{/* 복구 예정 쿠폰 */}
									<div className="label">{textInfo.claim.price.restoreCoupon}</div>
									<div className="price restoreCoupon">
										{totalPrice &&
										totalPrice?.coupon &&
										totalPrice?.coupon?.name &&
										totalPrice?.isCheckTobeCoupon &&
										!totalPrice?.isCheckTobeCouponAvailable
											? totalPrice.coupon.name
											: textInfo.common.noExists}
									</div>
								</li>
								{/* 미복구 쿠폰에 대한 상세 */}
								{totalPrice?.isCheckTobeCouponAvailable && totalPrice?.isCheckTobeCoupon && (
									<li className="restoreItem couponDetail">
										<span className="expires">기간만료</span>
										<span className="couponName">{totalPrice?.coupon?.name}</span>
									</li>
								)}
								{totalPrice && totalPrice.useReward ? (
									<li className="refundInfoItem useReward">
										<div className="label">{textInfo.order.usedReward}</div>
										<div className="price useReward">
											<Image
												srcSet="
												/images/icon/signMinusDiscount.png 1x
												, /images/icon/signMinusDiscount@2x.png 2x
												, /images/icon/signMinusDiscount@3x.png 3x
											"
												alt={textInfo.order.usedReward}
											/>
											{totalPrice && totalPrice.useReward
												? totalPrice.useReward.toLocaleString()
												: 0}
											<span className="rewardUnit">{textInfo.common.rewardUnit}</span>
										</div>
									</li>
								) : (
									<></>
								)}
							</ul>
							<div className="refundTotal">
								{/* 실 결제액 */}
								<div className="label">{textInfo.claim.price.actualPaymentPrice}</div>
								<div className="price orderPriceTotal">
									{/* {totalPrice && (totalPrice.deliveryPrice || totalPrice.returnDiscount)
										? (totalPrice.deliveryPrice + totalPrice.returnDiscount).toLocaleString()
										: 0} */}
									{totalPrice && totalPrice.deliveryPrice
										? totalPrice.deliveryPrice.toLocaleString()
										: 0}
									<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
								</div>
							</div>
							<div className="button clickable refund cover" onClick={(e) => handlerClickRefund(e)}>
								<div className="buttonInner">
									<div className="label">{textInfo.button.claimRequestRefund}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ClaimOrderRefund;
