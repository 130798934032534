/**
 * 마이린미 - 건강상태 기본정보 1 (남/여)
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";

import { getSurveyDefault } from "src/api/myLinme/survey";
import { API_RESULT_STATUS } from "src/data/constEnum";
import Image from "src/pages/component/common/image";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myLinme/survey/survey.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const MyLinmeSurveyDefault1 = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const [questionData, setQuestionData] = useState([]);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 설문 이전페이지로 이동
	 */
	const handlerClickPrev = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		props.handlerClickPrev(e);
	};

	/**
	 * 설문 다음페이지로 이동
	 */
	const handlerClickNext = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (!validation()) return;

		props.handlerClickNext(e);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 질문 목록 가져오기 API
	 */
	const _getSurveyDefault = async () => {
		try {
			const result = await getSurveyDefault();

			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				setQuestionData(result?.data);
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	/**
	 * 설문 선택
	 */
	const handlerChangeData = (item) => {
		props?.setDefaultSelectedData1(item);
	};

	/**
	 * validation
	 */
	const validation = () => {
		if (!props?.defaultSelectedData1?.subCode) return false;
		return true;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		_getSurveyDefault();
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="container underTopMenu myLinmeContainer survey">
			<div className="contents">
				<div className="surveyContainer">
					<div className="surveyContainerHeader">
						<div className="stepList">
							<div className="stepList">
								<div className="selectedStep">{textInfo.myLinme.survey.step.first}</div>
								<div className="step">2</div>
								<div className="step">3</div>
								<div className="step">4</div>
							</div>
						</div>
						<div className="closeButton" onClick={props.handlerClickClose} />
					</div>

					<div className="defaultContainer">
						<div className="issueIcon_1" />
						<div className="surveyTitle">
							<div>{textInfo.myLinme.survey.default1.title}</div>
							<div>{textInfo.myLinme.survey.default1.title2}</div>
						</div>
						<div className="surveySubTitle">
							{textInfo.myLinme.survey.default1.desc}
							<span>{textInfo.myLinme.survey.default1.desc2}</span>
							{textInfo.myLinme.survey.default1.desc3}
						</div>
						<div className="surveyAnswerContainer_1">
							{questionData.map((item, idx) => {
								return (
									<div
										className={`surveyAnswer_1 ${props?.defaultSelectedData1?.subCode === item?.subCode ? "on" : ""}`}
										key={idx}
										onClick={() => handlerChangeData(item)}
									>
										<div className="surveyAnswerImage_1">
											<Image src={item.rsvVal4} alt={item.rsvVal1} />
										</div>
										<div className="surveyAnswerName_1">{item.rsvVal1}</div>
									</div>
								);
							})}
						</div>
					</div>
					<div className="buttonContainer">
						<div className="button prev" onClick={handlerClickPrev}>
							{textInfo.myLinme.survey.button.prev}
						</div>
						<div
							className={`button next ${validation() ? "" : "disable"}`}
							onClick={handlerClickNext}
						>
							{textInfo.myLinme.survey.button.next}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MyLinmeSurveyDefault1;
