/**
 * 마이페이지 - 쇼핑내역 - 교환
 */

import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/**
 * 주문 교환 배송비 조회
 * @param {Object} d
 * <code>
 * {
 * 		*deliveryType: ""//수거방법 - COMPANY:택배발송, DIRECT:직접발송, LATER: 나중에입력;
 * 		, *itemNo: 0
 * 		, memberAddressNo: 0
 * 		, *orderNo: 0
 * 		, reasonNo: 0
 * }
 * </code>
 * @returns
 */
export const getChangeDeliveryPrice = async (d) => {
	const url = `${API_PATHS.ORDER_CHANGE_DELIVERY_PRICE}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 주문 교환 요청
 * @param {Object} d
 * <code>
 * {
 * 		companyNo: 0//택배사 번호;
 * 		, content: ""//교환사유 상세;
 * 		, *deliveryType: ""//수거방법 - COMPANY:택배발송, DIRECT:직접발송, LATER: 나중에입력;
 * 		, env: ""//결제환경 - MOBILE, PC, APP;
 * 		, invoiceCode: ""//송장번호;
 * 		, *itemNo: 0//교환 상품 번호;
 * 		, *collectMemberAddressNo: 0//회수지 주소 번호;
 * 		, *isCheckCollectVirtualNumber: 0//회수지 안심번호 사용 여부;
 * 		, receiveMemberAddressNo: 0//수령지 주소 번호;
 * 		, *isCheckReceiveVirtualNumber: 0//수령지 안심번호 사용 여부;
 * 		, *orderNo: 0//주문번호;
 * 		, paymentMethod: ""//결제유형 - CARD:신용카드, VBANK:무통장, PHONE:휴대폰, REWARD:리워드;
 * 		, *quantity: 0//수량;
 * 		, *reasonNo: 0//교환사유번호;
 * }
 * </code>
 * @returns
 */
export const orderChange = async (d) => {
	const url = `${API_PATHS.ORDER_CHANGE_REQUEST}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 교환 철회 요청
 * @param {Object} d
 * <code>
 * {
 * 		*claimNo: 0//교환요청번호;
 * }
 * </code>
 * @returns
 */
export const orderChangeCancel = async (d) => {
	const url = `${API_PATHS.ORDER_CHANGE_CANCEL}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 교환건 상세 조회
 * @param {Number} claimProductNo
 * @returns
 */
export const orderChangeDetail = async (claimProductNo) => {
	const url = `${API_PATHS.ORDER_CHANGE_DETAIL}`;
	try {
		const result = await api.get(url.replace(":claimProductNo", claimProductNo));
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

export const orderChangeComplete = async (req) => {
	const url = `${API_PATHS.ORDER_CHANGE_COMPLETE}`;
	try {
		const result = await api.post(url, req);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
