/**
 * 환불 계좌 관리
 */

import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/**
 * 은행 목록 조회
 * @returns
 */
export const getBankList = async () => {
	const url = `${API_PATHS.BANK_LIST}`;
	try {
		const result = await api.get(url);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 환불 계좌 조회
 * @returns
 */
export const getRefundAccount = async () => {
	const url = `${API_PATHS.MEMBER_REFUND_ACCOUNT}`;
	try {
		const result = await api.get(url);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 환불 계좌 등록
 * @param {Object} d
 * <code>
 * {
 * 		accountNo: {String}
 * 		, bankCode: {String}
 * }
 * </code>
 * @returns
 */
export const setRefundAccount = async (d) => {
	const url = `${API_PATHS.MEMBER_REFUND_ACCOUNT}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
