/**
 * 리뷰 목록 정렬
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useState } from "react";

import { REVIEW_LIST_ORDER } from "src/data/constEnum";

/* ---------------------------------------------------------------------------------------------------- */
/* import styles */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
// const textInfo = require( "src/data/textInfo.json" );

const ReviewListOrder = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const [listOrderSelected, setListOrderSelected] = useState(
		props.selected || REVIEW_LIST_ORDER[0].value
	);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 정렬 선택 시
	 * @param {Event} e
	 */
	const handlerClickListOrder = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const t = e.target;
		const value = t.dataset.value;
		if (value && props.afterSelected) {
			setListOrderSelected(value);
			props.afterSelected(value);
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<ul className="reviewOrderList" onClick={(e) => handlerClickListOrder(e)}>
			{REVIEW_LIST_ORDER.map((item, index) => (
				<li
					className={"reviewOrderItem " + (listOrderSelected === item.value ? "on" : "")}
					key={item.value}
				>
					<span className="clickable" data-value={item.value}>
						{item.label}
					</span>
				</li>
			))}
		</ul>
	);
};

export default ReviewListOrder;
