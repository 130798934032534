/**
 * 추가 정보 입력
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useRef, useState } from "react";

import useAuth from "src/hooks/useAuth";
import useModalStack from "src/hooks/useModalStack";
import { API_RESULT_STATUS, VERIFY_ENV, VERIFY_TYPE } from "src/data/constEnum";
import { requestVerify } from "src/api/member/signUp";
import { setBaseInfoAfterSignup } from "src/api/member/signIn";
import { execDaumPostCode } from "src/common/postCodeDaum";
import {
	getDuplicateCheckCi,
	handlerClickDuplicateCheckNickname,
	handlerKeyUpNickname,
	validationNickname,
	validationPhone,
	validationAddress1,
	validationAddress2,
} from "src/utils/linmeMember";
import { isMobile } from "react-device-detect";

import AuthVerifyIframe from "src/pages/member/modal/authVerifyIframe";
import ModalCommonAlert from "src/pages/common/modalAlert";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/member/modal/additionalInformation.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

const htmlInfo = require("src/data/htmlInfo.json");
const textInfo = require("src/data/textInfo.json");

const AdditionalInformation = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const auth = useAuth();
	const modalStack = useModalStack();
	const [verifyRequest, setVerifyRequest] = useState(null);
	const ref = {
		name: useRef(null),
		nickname: useRef(null),
		checkNickname: useRef(null),
		phone: useRef(null),
		checkVerify: useRef(null),
		buttonVerify: useRef(null),
		address1: useRef(null),
		address2: useRef(null),
		postcode: useRef(null),
		birth: useRef(null),
		gender: useRef(null),
		ci: useRef(null),
		termsBenefitSMS: useRef(null),
		termsBenefitEMAIL: useRef(null),
		buttonInputCompleted: useRef(null),
		verifyRequestDimmer: useRef(null),
	};

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 필수입력항목 onBlur
	 * @param {Event} e
	 * @returns
	 */
	const handlerBlurRequired = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const ct = e.currentTarget;
		const elUserInput = ct.closest(".userInput");
		const elGuideList = elUserInput.querySelectorAll(".inputGuide");
		const elGuideWarn = elUserInput.querySelector(".inputGuideWarn");
		if (ct.value.length === 0) {
			elGuideList.forEach((element) => {
				element.classList.add("off");
			});
			elGuideWarn.innerText = "필수 입력 항목입니다.";

			return false;
		}
	};

	/**
	 *
	 * @param {Event} e
	 */
	const handlerChangeInput = (e) => {
		/*/
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();
		//*/

		if (validation() === true) {
			ref.buttonInputCompleted.current.classList.add("on");
		} else {
			ref.buttonInputCompleted.current.classList.remove("on");
		}
	};

	/**
	 * 닫기 클릭 시
	 * @param {Event} e
	 */
	const handlerClickClose = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await modalStack.removeModal(props.id);
	};

	/**
	 * 저장 클릭 시
	 * @param {Event} e
	 */
	const handlerClickSaveInfo = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if ((await validation()) === true) {
			await requestSetBaseInfoAfterSignup();
		}
	};

	/**
	 * 본인인증 클릭 시
	 * @param {Event} e
	 */
	const handlerClickVerify = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		// 기 인증 완료한 경우 실행하지 않음;
		if (ref.checkVerify.current.value) return false;

		/*/
		await postRequestVerify()
		.then(( data ) => {
			modalStack.addModal({
				id: "modalVerify"
				, type: "none"
				, component: <AuthVerifyIframe {...data}/>
				, setDataAfterVerify: setDataAfterVerify
			});
		});
		/*/
		await postRequestVerify().then((data) => {
			setVerifyRequest(data);
			ref.verifyRequestDimmer.current.classList.add("on");
		});
		//*/
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 본인인증
	 */
	const postRequestVerify = async () => {
		try {
			const d = {
				env: isMobile ? VERIFY_ENV.WEB.MOBILE : VERIFY_ENV.WEB.PC,
				memberNo: 0,
				type: VERIFY_TYPE.SIGN_UP,
			};
			const result = await requestVerify(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				return result.data;
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	/**
	 * 추가 정보 입력 요청
	 */
	const requestSetBaseInfoAfterSignup = async () => {
		//*/
		const d = {
			address1: ref.address1.current.value,
			address2: ref.address2.current.value,
			birth: ref.birth.current.value,
			ci: ref.ci.current.value,
			gender: ref.gender.current.value,
			isCheckAdEmail: ref.termsBenefitEMAIL.current.checked,
			isCheckAdSms: ref.termsBenefitSMS.current.checked,
			name: ref.name.current.value,
			nickname: ref.nickname.current.value,
			phone: ref.phone.current.value,
			postcode: ref.postcode.current.value,
		};
		/*/
		const d = {
			address1: ref.address1.current.value
			, address2: ref.address2.current.value
			, birth: verifyInfo.birth
			, ci: verifyInfo.ci
			, gender: verifyInfo.gender
			, isCheckAdEmail: ref.termsBenefitEMAIL.current.checked
			, isCheckAdSms: ref.termsBenefitSMS.current.checked
			, name: verifyInfo.name
			, nickname: ref.nickname.current.value
			, phone: verifyInfo.phone
			, postcode: ref.postcode.current.value
		}
		//*/

		try {
			const result = await setBaseInfoAfterSignup(d);
			// const result = { status: "SUCCESS", data: {} };
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				//데이터 재설정;
				const resultSetUserData = await auth.setUserData();
				if (resultSetUserData) {
					//현재 모달 삭제;
					await modalStack.removeModal(props.id);
					// await modalStack.addModal({
					// 	id: "modalCommonAlert2"
					// 	, type: "alert"
					// 	, component: <ModalCommonAlert />
					// 	, text: textInfo.alert.changedMemberBaseInfo
					// });
				} else {
					await modalStack.addModal({
						id: "modalCommonAlert",
						type: "alert",
						component: <ModalCommonAlert />,
						html: htmlInfo.error.changedMemberBaseInfo,
					});
				}
				return result.data;
			} else {
				window.linmeLog.log(result);
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					html: htmlInfo.error.changedMemberBaseInfo,
				});
				return false;
			}
		} catch (error) {
			window.linmeLog.log(error);
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				html: error?.response?.data?.message || htmlInfo.error.changedMemberBaseInfo,
			});
			return false;
		}
	};

	/**
	 * 본인인증 후처리
	 * @param {Object} d
	 */
	const setDataAfterVerify = async (d) => {
		if (!d.hasOwnProperty("status")) return;

		ref.verifyRequestDimmer.current.classList.remove("on");
		if (d.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
			//이름;
			ref.name.current.value = d.name;
			//성별;
			ref.gender.current.value = d.gender;
			//생일;
			ref.birth.current.value = d.birthday;
			//휴대폰;
			ref.phone.current.value = d.phone;
			//CI;
			ref.ci.current.value = d.ci;
			//본인인증 여부;
			ref.checkVerify.current.value = true;

			ref.buttonVerify.current.classList.add("off");

			const ct = ref.checkVerify.current;
			const elUserInput = ct.closest(".userInput");
			const elGuideList = elUserInput.querySelectorAll(".inputGuide");
			const elGuideWarn = elUserInput.querySelector(".inputGuideWarn");
			elGuideList.forEach((element) => {
				element.classList.add("off");
			});
			elGuideWarn.innerText = "";

			/*/
			//TODO: 동일 ci 존재 시 처리 로직 추가 필요;
			const ciCheckResult = await getDuplicateCheckCi( d.ci );
			if( ciCheckResult ) {
				modalStack.addModal({
					id: "modalCommonAlert"
					, type: "alert"
					, component: <ModalCommonAlert />
					, text: textInfo.alert.memberInfoExists
				});
				return false;
			}
			//*/
		} else {
			ref.checkVerify.current.value = false;

			ref.buttonVerify.current.classList.remove("off");
		}
	};

	/**
	 * validation
	 * @returns {Boolean}
	 */
	const validation = () => {
		if (!validationNickname(ref.nickname, ref.checkNickname)) return false;
		if (!validationPhone(ref.checkVerify)) return false;
		if (!validationAddress1(ref.address1)) return false;
		if (!validationAddress2(ref.address2)) return false;
		return true;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="modalContentsContainer additionalInformationContainer">
			<div className="title">{textInfo.member.additionalInformation.title}</div>
			<div className="info">{textInfo.member.additionalInformation.description}</div>
			<ul className="inputDataCover">
				<li className="inputDataItem">
					<div className="label required">
						<span>{textInfo.member.data.nickname}</span>
					</div>
					<div className="userInput">
						<div className="nicknameWrapper">
							<input
								type="text"
								ref={ref.nickname}
								className="nickname"
								name="nickname"
								placeholder={textInfo.member.placeholder.nickname}
								onKeyUp={(e) => handlerKeyUpNickname(e, ref.checkNickname)}
								onChange={(e) => handlerChangeInput(e)}
								onBlur={(e) => handlerBlurRequired(e)}
							/>
							<input type="hidden" ref={ref.checkNickname} name="checkNickname" />
							<button
								className="button duplicateCheck duplicateCheckNickName"
								onClick={(e) =>
									handlerClickDuplicateCheckNickname(e, ref.nickname, ref.checkNickname)
								}
							>
								중복확인
							</button>
						</div>
						<div className="guide">
							<div className="inputGuide">{textInfo.member.guide.nickname.character}</div>
							<div className="inputGuideWarn"></div>
							<div className="inputGuideChecked"></div>
						</div>
					</div>
				</li>
				<li className="inputDataItem">
					<div className="label required">
						<span>{textInfo.member.data.phone}</span>
					</div>
					<div className="userInput">
						<input
							type="hidden"
							ref={ref.phone}
							className="phone"
							name="phone"
							placeholder="010-1234-5678"
						/>
						<input type="hidden" ref={ref.name} className="name" name="name" />
						<input type="hidden" className="birth" name="birth" ref={ref.birth} />
						<input type="hidden" className="gender" name="gender" ref={ref.gender} />
						<input type="hidden" className="ci" name="ci" ref={ref.ci} />
						<input type="hidden" ref={ref.checkVerify} name="checkVerify" id="checkVerify" />
						<button
							className="button verification"
							ref={ref.buttonVerify}
							onClick={(e) => handlerClickVerify(e)}
						>
							본인인증
						</button>
						<div className="guide">
							<div className="inputGuideWarn"></div>
						</div>
					</div>
				</li>
				<li className="inputDataItem">
					<div className="label required">
						<span>{textInfo.member.data.address}</span>
					</div>
					<div className="userInput">
						<div>
							<input
								type="text"
								ref={ref.postcode}
								className="postcode"
								name="additionalPostcode"
								id="additionalPostcode"
								readOnly={true}
								onChange={(e) => handlerChangeInput(e)}
								placeholder={textInfo.member.placeholder.postcode}
							/>
							<button
								className="button findPostCode"
								onClick={(e) =>
									execDaumPostCode(
										{
											postcode: "additionalPostcode",
											address1: "additionalAddress1",
											address2: "additionalAddress2",
											addressExtra: "additionalAddressExtra",
										},
										window.document.getElementById("layerPostCodeContent"),
										window.document.getElementById("layerPostCode")
									)
								}
							>
								{textInfo.button.findPostcode}
							</button>
						</div>
						<div>
							<input
								type="text"
								ref={ref.address1}
								className="address"
								name="additionalAddress1"
								id="additionalAddress1"
								readOnly={true}
								placeholder={textInfo.member.placeholder.address1}
							/>
						</div>
						<div>
							<input
								type="text"
								ref={ref.address2}
								className="address2"
								name="additionalAddress2"
								id="additionalAddress2"
								readOnly={true}
								placeholder={textInfo.member.placeholder.address2}
								onChange={(e) => handlerChangeInput(e)}
							/>
							<div className="guide">
								<div className="inputGuideWarn"></div>
								<input
									type="hidden"
									className="addressExtra"
									name="additionalAddressExtra"
									id="additionalAddressExtra"
								/>
							</div>
						</div>
						<div className="guide">
							<div className="inputGuideWarn"></div>
						</div>
					</div>
				</li>
				<li className="inputDataItem termsDetail">
					<div className="label">
						<span>혜택/정보 수신 동의</span>
					</div>
					<div className="userInput">
						<span>무료배송, 할인쿠폰 등 혜택/정보 수신 동의 (선택)</span>
						<input
							className="benefitSMS"
							name="benefitSMS"
							id="benefitSMS"
							type="checkbox"
							ref={ref.termsBenefitSMS}
							onChange={(e) => handlerChangeInput(e)}
						/>
						<label htmlFor="benefitSMS">{textInfo.member.data.terms.termsBenefitSMS}</label>
						<input
							className="benefitEMAIL"
							name="benefitEMAIL"
							id="benefitEMAIL"
							type="checkbox"
							ref={ref.termsBenefitEMAIL}
							onChange={(e) => handlerChangeInput(e)}
						/>
						<label htmlFor="benefitEMAIL">{textInfo.member.data.terms.termsBenefitEMAIL}</label>
					</div>
				</li>
			</ul>
			<div className="buttonCover">
				<button className="button close" onClick={(e) => handlerClickClose(e)}>
					{textInfo.button.close}
				</button>
				<button
					className="button inputCompleted"
					ref={ref.buttonInputCompleted}
					onClick={(e) => handlerClickSaveInfo(e)}
				>
					{textInfo.button.save}
				</button>
			</div>
			<div className="verifyDimmer" ref={ref.verifyRequestDimmer}>
				<div className="verifyContent">
					<AuthVerifyIframe {...verifyRequest} setDataAfterVerify={setDataAfterVerify} />
				</div>
			</div>
		</div>
	);
};

export default AdditionalInformation;
