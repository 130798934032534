const textInfo = require("src/data/textInfo.json");

/**
 * 설정한 섭취 간격에 해당하는 문구 반환
 * @param {Object} item
 * @returns
 */
export const getTakeCycle = (item) => {
	let cycle = "";
	if (item) {
		if (item.cycle <= 1 && item.holiday === "Y") {
			cycle = textInfo.alarm.cycle.weekday;
		} else if (item.cycle <= 1) {
			cycle = textInfo.alarm.cycle.everyday;
		} else if (item.cycle > 1) {
			cycle = item.cycle + textInfo.alarm.cycle.onceEveryFewDays;
		}
	}
	return cycle;
};

/**
 * 현재 섭취 퍼센테이지 계산
 * @param {Object} item
 * @returns
 */
export const getTakePercent = (item) => {
	if (!item?.totQty || !item?.remainQty) return 100;
	if (item.totQty === item.remainQty) return 0;
	return Math.floor(((item.totQty - item.remainQty) / item.totQty) * 100);
};
