export const queryKeys = {
	CATEGORY_LIST: "categoryList",
	RECENTLY_VIEWED: "recentlyViewed",
	PRODUCT_REVIEW: "productReview",
	MEMBER_REVIEW_CHECK: "memberReviewCheck",
	ALARM_DATE: "alarmDate",
	ALARM_MONTH: "alarmMonth",
	CHALLENGE_DETAIL: "challengeDetail",
	CHALLENGE_ATTENDANCE: "challengeATTENDANCE",
	ISSUE_LIST: "issueList",
	TOPIC_INFO: "topicInfo",
	PRODUCT_LIST_TOPIC: "productListTopic",
	TIME_SALE_TOPIC: "timeSaleTopic",
	PRODUCT_LIST_TOPIC_TIME_SALE: "productListTopicTimeSale",
	BANNER_LIST: "bannerList",
};
