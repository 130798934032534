/**
 * 장바구니, 주문/결제 - 할인 상세보기 레이어
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useState, useEffect } from "react";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/cart/layer/discountDetail.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const DiscountDetail = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const [isOpen, setIsOpen] = useState(props.isShow);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 상태 변수가 변경될 때마다 실행
	 */
	useEffect(() => {
		setIsOpen(props.isShow);
	}, [props]);

	/**
	 * 닫기 버튼 클릭
	 * @param {Event} e
	 */
	const handleClickClose = (e) => {
		setIsOpen(!isOpen);
		props.handlerToggleClose(!isOpen);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className={"layerDiscountDetail " + (isOpen === true ? "on" : "")}>
			<div className="closeLayer" onClick={(e) => handleClickClose(e)}></div>
			<div className="discountTitle">{textInfo.payment.discount.detail}</div>
			<ul className="discountList">
				{/* <li className="discountItem">
					<div className="discountLabel">{textInfo.payment.discount.membership}</div>
					<div className="discountData">{props.discountLevelPrice.toLocaleString()}<span className="currencyUnit">{textInfo.common.currencyUnit}</span></div>
				</li> */}
				<li className={"discountItem coupon " + (props.discountCouponPrice ? "exists" : "")}>
					<div className="discountLabel">{textInfo.payment.discount.coupon}</div>
					<div className="discountData">
						{props.discountCouponPrice ? props.discountCouponPrice.toLocaleString() : 0}
						<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
						{props.discountCouponPrice ? (
							<div className="couponType">{props.discountCouponName}</div>
						) : (
							<></>
						)}
					</div>
				</li>
				<li className="discountItem reward">
					<div className="discountLabel">{textInfo.payment.discount.reward}</div>
					<div className="discountData">
						{props.discountRewardPrice.toLocaleString()}
						<span className="rewardUnit">{textInfo.common.rewardUnit}</span>
					</div>
				</li>
				<li className="discountItem totalInfo">
					<div className="discountLabel">{textInfo.payment.discount.total}</div>
					<div className="discountData">
						{props.discountTotalPrice.toLocaleString()}
						<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
					</div>
				</li>
			</ul>
		</div>
	);
};

export default DiscountDetail;
