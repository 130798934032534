/**
 * 섭취관리 알람 목록
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import moment from "moment";
import "moment/locale/ko"; //한글로 설정;

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myLinme/supplementManageCard.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const DailyAlarm = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const { alarmData } = props;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<>
			<div className="intakeListContainer">
				<div className="intakeTitle">{textInfo.myLinme.alarm.intakeTitle}</div>
				<ul className="intakeList">
					{alarmData && alarmData?.length > 0 ? (
						<>
							{alarmData.map((item, index) => {
								let cName = "";
								if (item?.actionFlag === "true")
									cName = "checked"; // 섭취완료
								else if (item?.actionFlag === "false")
									cName = "skip"; // 건너뛰기
								else cName = "expected"; // 액션없음
								return (
									<li key={index} className="intakeItem">
										<div className={`supplementStatus ${cName}`} />
										<div className="supplementName">{item.productName}</div>
										<div className="supplementTime">
											<span className="apm">{moment(item.time, "HH:mm").format("A")}</span>
											<span className="time">{moment(item.time, "HH:mm").format("hh:mm")}</span>
										</div>
									</li>
								);
							})}
						</>
					) : (
						<li className="intakeItem noData" />
					)}
				</ul>
			</div>
		</>
	);
};
export default DailyAlarm;
