/**
 * 검색
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */
import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/* ---------------------------------------------------------------------------------------------------- */
/* FUNCTION */
/* ---------------------------------------------------------------------------------------------------- */

/**
 * 인기 검색어 목록
 * @returns
 */
export const getPopularKeyword = async () => {
	const url = `${API_PATHS.SEARCH_KEYWORD_POPULAR}`;
	try {
		const result = await api.get(url);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 검색어 기준 상품 검색
 * @param {Object} d
 * <code>
 * {
 * 		*keyword: ""
 * 		, *offset: 0
 * 		, *page: 0
 * 		, sort: "" //정렬 ( PRODUCT_LIST_ORDER );
 * }
 * </code>
 * @returns
 */
export const search = async (d) => {
	const url = `${API_PATHS.SEARCH}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
