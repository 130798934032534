/**
 * 마이페이지 - 나의 리뷰
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";

import useAuth from "src/hooks/useAuth";
import {
	API_RESULT_STATUS,
	PAGINATION_DEFAULT_OFFSET,
	REVIEW_LIST_ORDER,
} from "src/data/constEnum";
import { getReviewList } from "src/api/member/review";
import { getLastPageNumber } from "src/utils/number";
import { setScrollTop } from "src/utils/linmeDisplay";

import ReviewListOrder from "src/pages/component/myPage/reviewListOrder";
import ReviewCard from "src/pages/component/review/reviewCard";
import Pagination from "src/pages/component/pagination";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myPage/review.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const MyPageReview = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const auth = useAuth();
	const [listOrder, setListOrder] = useState(REVIEW_LIST_ORDER[0].value);
	const [reviewList, setReviewList] = useState(null);
	const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);
	const [paginationOffset, setPaginationOffset] = useState(PAGINATION_DEFAULT_OFFSET.DEFAULT);
	const [paginationTotalCount, setPaginationTotalCount] = useState(0);
	const [paginationLastPage, setPaginationLastPage] = useState(0);
	const [paginationIsLastPage, setPaginationIsLastPage] = useState(false);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 리뷰 단건 삭제 후 실행
	 * @param {Number} itemIndex
	 */
	const afterDeleted = async (itemIndex) => {
		if (reviewList && reviewList.length) {
			await redraw(1);
			await auth.checkStatistics();
		}
	};

	/**
	 * 다시 그리기
	 * @param {Number} pageNo
	 */
	const redraw = async (pageNo = 1) => {
		await requestReviewList(pageNo);
		await setScrollTop();
	};

	/**
	 * 리뷰 목록 조회
	 * @param {Number} pageNo
	 * @returns
	 */
	const requestReviewList = async (pageNo, sort) => {
		try {
			let d = {
				offset: paginationOffset,
				page: pageNo,
			};

			if (sort) d.sort = sort;
			else if (listOrder) d.sort = listOrder;
			const result = await getReviewList(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (result.data.content) {
					setReviewList(result.data.content);

					setPaginationCurrentPage(result.data.page);
					setPaginationTotalCount(result.data.totalCount);
					setPaginationIsLastPage(result.data.isLastPage);
					setPaginationLastPage(getLastPageNumber(result.data.totalCount, paginationOffset));
				}
				return result.data;
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (err) {
			window.linmeLog.error(err);
			return false;
		}
	};

	/**
	 * 정렬 선택 결과
	 * @param {String} value
	 */
	const setListOrderSelected = (value) => {
		setListOrder(value);
		requestReviewList(1, value);
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		const getData = async () => {
			await requestReviewList(1);
		};
		getData();
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<>
			<div className="reviewCover">
				<div className="title">{textInfo.myPage.myReview.myReview}</div>
				<ReviewListOrder afterSelected={setListOrderSelected} />
				<ul className="reviewList">
					{reviewList && reviewList.length ? (
						reviewList.map((item, index) => (
							<li className="reviewItem" key={index}>
								<ReviewCard
									{...item.product}
									reviewItem={item}
									itemIndex={index}
									from={"myReview"}
									afterDeleted={afterDeleted}
									redrawList={() => requestReviewList(1)}
								/>
							</li>
						))
					) : (
						<li className="noData" />
					)}
				</ul>
				{reviewList && reviewList.length ? (
					<Pagination
						{...{
							currentPage: paginationCurrentPage,
							offset: paginationOffset,
							totalCount: paginationTotalCount,
							lastPage: paginationLastPage,
							isLastPage: paginationIsLastPage,
							redraw: redraw,
						}}
					/>
				) : (
					<></>
				)}
			</div>
		</>
	);
};

export default MyPageReview;
