/**
 * 결제 관련 함수
 */

import { API_RESULT_STATUS } from "src/data/constEnum";
import { checkPaymentCompleted } from "src/api/order/payment";
import { checkClaimPaymentCompleted } from "src/api/myShopping/claim";

const htmlInfo = require("src/data/htmlInfo.json");
const textInfo = require("src/data/textInfo.json");

let checkOrderNo = null;
let checkPaymentNo = null;
let afterPaymentSuccess = null;
let afterPaymentFail = null;

/**
 * 타이머 결제창이 닫혔는지 확인. 닫히지 않은 경우 다시 타이머 설정
 */
export const handlerTimer = async () => {
	await window.clearTimeout(window.processTimerId);
	if (!window.paymentPopup.closed) {
		await checkPaymentProcessing();
	} else {
		if (checkOrderNo) await requestCheckPaymentCompleted();
		else if (checkPaymentNo) await requestCheckClaimPaymentCompleted();
	}
};

/**
 * 주문번호 설정
 * @param {Number} orderNo
 */
export const setCheckOrderNo = (orderNo) => {
	checkOrderNo = orderNo;
};

/**
 * 클레임 - 결제번호 설정
 * @param {Number} paymentNo
 */
export const setCheckPaymentNo = (paymentNo) => {
	checkPaymentNo = paymentNo;
};

/**
 * 클레임 결제 완료 여부 확인
 * @returns
 */
export const requestCheckClaimPaymentCompleted = async () => {
	try {
		const result = await checkClaimPaymentCompleted(checkPaymentNo);
		if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
			if (result.data.status === API_RESULT_STATUS.SUCCESS) {
				//결제 성공;
				if (afterPaymentSuccess) await afterPaymentSuccess("text", result.data.result);
			} else {
				//결제 실패;
				if (afterPaymentFail) await afterPaymentFail("text", result.data.message);
			}
		} else {
			window.linmeLog.log(result);
			if (afterPaymentFail) await afterPaymentFail("text", result.message);
			return false;
		}
	} catch (err) {
		//결제창을 열었다가 그냥 닫은 경우 400 (Bad Request);
		if (afterPaymentFail) await afterPaymentFail("html", htmlInfo.payment.pleaseFinishPayment);
		return false;
	}
};

/**
 * 결제 완료 여부 확인
 * @returns
 */
export const requestCheckPaymentCompleted = async () => {
	try {
		const result = await checkPaymentCompleted(checkOrderNo);
		if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
			if (result.data.status === API_RESULT_STATUS.SUCCESS) {
				//결제 성공;
				if (afterPaymentSuccess) await afterPaymentSuccess("text", result.data.result);
			} else {
				//결제 실패;
				if (afterPaymentFail) await afterPaymentFail("text", result.data.message);
			}
		} else {
			window.linmeLog.log(result);
			if (afterPaymentFail) await afterPaymentFail("text", result.data.message);
			return false;
		}
	} catch (err) {
		//결제창을 열었다가 그냥 닫은 경우 400 (Bad Request);
		if (afterPaymentFail) await afterPaymentFail("html", htmlInfo.payment.pleaseFinishPayment);
		return false;
	}
};

/**
 * 결제창이 닫혔는지 확인하는 타이머 설정
 * @param {Function} afterSuccess
 * @param {Function} afterFail
 */
export const checkPaymentProcessing = async (afterSuccess, afterFail, paymentNo) => {
	if (afterSuccess) afterPaymentSuccess = afterSuccess;
	if (afterFail) afterPaymentFail = afterFail;
	window.processTimerId = await window.setTimeout(handlerTimer, 1000);
};

/**
 * 리워드 전액 적용
 * @param {Event} e
 * @param {Number} finalPrice
 * @param {Number} usedReward
 * @param {Number} availableReward
 * @param {Object} refReward
 * @param {Object} refApplyAll
 * @param {Object} refCancelAll
 * @returns {Object}
 * <code>
 * {
 * 		result: false
 * 		, text: ""
 * }
 * </code>
 */
export const handlerClickSetRewardAll = async (
	e,
	finalPrice,
	usedReward,
	availableReward,
	refReward,
	refApplyAll,
	refCancelAll
) => {
	if (e) {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();
	}

	finalPrice = finalPrice || 0;
	usedReward = usedReward || 0;
	const priceWithoutReward = finalPrice + usedReward;
	let result = 0;

	// 리워드가 더 큰경우
	if (availableReward > priceWithoutReward) {
		result = priceWithoutReward;
	} else {
		// 상품 금액이 더 큰 경우
		result = availableReward;
	}

	refReward.current.value = await result.toLocaleString();
	await refApplyAll.current.classList.remove("on");
	await refCancelAll.current.classList.add("on");

	const resultValidation = await validationReward(
		finalPrice,
		usedReward,
		availableReward,
		refReward
	);
	return resultValidation;
};

/**
 * 리워드 전액 취소
 * @param {Event} e
 * @param {Number} price
 * @param {Number} availableReward
 * @param {Object} refReward
 * @param {Object} refApplyAll
 * @param {Object} refCancelAll
 * @returns {Object}
 * <code>
 * {
 * 		result: false
 * 		, text: ""
 * }
 * </code>
 */
export const handlerClickCancelRewardAll = async (
	e,
	price,
	availableReward,
	refReward,
	refApplyAll,
	refCancelAll
) => {
	if (e) {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();
	}

	refReward.current.value = 0;
	await refApplyAll.current.classList.add("on");
	await refCancelAll.current.classList.remove("on");

	const resultValidation = await validationReward(0, 0, availableReward, refReward);
	return resultValidation;
};

// export const handlerKeyUpReward = async( e, price, availableReward, refReward ) => {
// 	//버블링 막기;
// 	e.preventDefault();
// 	e.stopPropagation();

// 	let key = e.key || e.keyCode;

// 	if (key === "Enter" || key === 13) {
// 		const resultValidation = await validationReward( price, null, availableReward, refReward );
// 		return resultValidation;
// 	}
// };

/**
 * validation 리워드
 * @param {Number} finalPrice
 * @param {Number} usedReward
 * @param {Number} availableReward
 * @param {Object} refReward
 * @returns {Object}
 * <code>
 * {
 * 		result: false
 * 		, text: ""
 * }
 * </code>
 */
export const validationReward = async (finalPrice, usedReward, availableReward, refReward) => {
	finalPrice = finalPrice || 0;
	usedReward = usedReward || 0;

	const ct = refReward.current;
	const num = ct.value.replace(/[^0-9]/g, ""); //숫자만 적용;
	const priceWithoutReward = finalPrice + usedReward;

	if (num === "") {
		ct.value = 0;
		return false;
	}

	const val = parseInt(num);
	if (availableReward) {
		//리워드가 존재하는 경우;
		if (val > priceWithoutReward) {
			//최종 결제금액보다 큰 숫자인 경우;
			ct.value = usedReward.toLocaleString();
			return {
				result: false,
				text: textInfo.alert.greaterThanFinalPrice,
			};
		}

		if (val > availableReward) {
			//가용 리워드보다 큰 숫자인 경우;
			ct.value = usedReward.toLocaleString();
			return {
				result: false,
				text: textInfo.alert.greaterThanAvailableAmount,
			};
		}

		ct.value = val.toLocaleString();
		return {
			result: true,
			text: null,
		};
	} else {
		return {
			result: false,
			text: textInfo.alert.noRewardsAvailable,
		};
	}
};
