import useDeviceType from "src/hooks/useDeviceType";

import Toggle from "src/pages/component/toggle";
import TopMainMobileToggle from "src/pages/component/layout/topMain/topMainMobileToggle";

const textInfo = require("src/data/textInfo.json");
import { COLOR, DEVICE_TYPE } from "src/data/constEnum";

const TopMainHeaderToggle = ({ mainSelectedMenuType, afterToggle }) => {
	const { mobileType } = useDeviceType();

	if (mobileType !== DEVICE_TYPE.PC) {
		return (
			<TopMainMobileToggle mainSelectedMenuType={mainSelectedMenuType} afterToggle={afterToggle} />
		);
	}
	return (
		<Toggle
			// isWithoutCircle={device === DEVICE_TYPE.PC ? false : true}
			str1={textInfo.menu.productType2}
			str2={textInfo.menu.productType1}
			isOn={mainSelectedMenuType !== "effect"}
			afterToggle={afterToggle}
			styles={{
				container: {
					width: "99px",
					height: "32px",
					boxShadow: "unset",
					backgroundColor: COLOR.PINK02,
					checkedBackgroundColor: COLOR.LINME,
					border: "1px solid " + COLOR.PINK05,
					checkedBorder: "1px solid " + COLOR.LINME,
				},
				circle: {
					width: "24px",
					height: "24px",
					top: "5px",
					left: "5px",
					backgroundColor: COLOR.LINME,
					checkedRight: "3px",
					checkedBackgroundColor: COLOR.WHITE,
				},
				label: {
					color: COLOR.LINME,
					checkedColor: COLOR.WHITE,
				},
			}}
		/>
	);
};

export default TopMainHeaderToggle;
