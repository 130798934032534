import StarScore from "src/pages/component/review/starScore";
import Image from "src/pages/component/common/image";

const textInfo = require("src/data/textInfo.json");

const ProductDetailInfoReview = (props) => {
	const { averageRate, count } = props;

	// 리뷰 스크롤 이동 함수
	const handleScrollToReview = (e) => {
		e.preventDefault();

		const targetElement = document.getElementById("review");

		if (targetElement) {
			const offset = 220; // 요소의 위치에서 추가로 위로 스크롤할 오프셋(픽셀 단위)
			const y = targetElement.getBoundingClientRect().top + window.scrollY - offset;

			window.scrollTo({
				top: y,
				behavior: "smooth",
			});
		}
	};
	return (
		<li className="productInfoItem reviewInfo">
			<StarScore {...{ score: averageRate }} />
			<div className="scoreStatus">
				<span className="nowScore">{averageRate}</span>/
				<span className="perfectScore">{textInfo.product.detail.reviewPerfectScore}</span>
			</div>
			<div className="reviewCount" onClick={handleScrollToReview}>
				{textInfo.product.detail.reviewSummary}&nbsp;<span>{count}</span>
				<div className="showMore">
					<Image
						className="showMoreIcon"
						srcSet="
                    ../../../images/topic/arrow_showMore.png 1x
                    , ../../../images/topic/arrow_showMore@2x.png 2x
                    , ../../../images/topic/arrow_showMore@3x.png 3x
                "
						alt={textInfo.button.moveToShowMore}
					/>
				</div>
			</div>
		</li>
	);
};

export default ProductDetailInfoReview;
