import { useEffect, useState, useCallback } from "react";
import { CLIENT_SCREEN_WIDTH, DEVICE_TYPE } from "src/data/constEnum";

const determineDeviceType = () => {
	const clientWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

	if (
		clientWidth >= CLIENT_SCREEN_WIDTH.MOBILE_MINI.MIN &&
		clientWidth <= CLIENT_SCREEN_WIDTH.MOBILE_MINI.MAX
	) {
		return DEVICE_TYPE.MOBILE;
	}
	if (
		clientWidth >= CLIENT_SCREEN_WIDTH.MOBILE.MIN &&
		clientWidth <= CLIENT_SCREEN_WIDTH.MOBILE.MAX
	) {
		return DEVICE_TYPE.MOBILE;
	}
	if (
		clientWidth >= CLIENT_SCREEN_WIDTH.TABLET.MIN &&
		clientWidth <= CLIENT_SCREEN_WIDTH.TABLET.MAX
	) {
		return DEVICE_TYPE.TABLET;
	}
	return DEVICE_TYPE.PC;
};

const useDeviceType = () => {
	const [mobileType, setMobileType] = useState(determineDeviceType);

	const updateDeviceType = useCallback(() => {
		setMobileType(determineDeviceType());
	}, []);

	useEffect(() => {
		window.addEventListener("resize", updateDeviceType);
		return () => {
			window.removeEventListener("resize", updateDeviceType);
		};
	}, [updateDeviceType]);

	useEffect(() => {
		updateDeviceType(); // Initial check
	}, [updateDeviceType]);

	return { mobileType };
};

export default useDeviceType;
