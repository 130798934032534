/**
 * 출석체크 캘린더
 */

import clsx from "clsx";
import moment from "moment";
import { useParams } from "react-router-dom";
import Calendar from "react-calendar";

import {
	getIsComplete,
	isCurrentMonth,
	useChallengeAttendance,
	usePostChallengeAttendance,
} from "src/hooks/challenge/useChallenge";

import styles from "src/styles/reward/detail.module.scss";
import "src/styles/common/linmeCalendar.scss";

const textInfo = require("src/data/textInfo.json");

const AttendanceCalendar = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const { isIn } = props;

	const { challengeNo } = useParams();
	const { data: challengeAttendanceData, isLoading, refetch } = useChallengeAttendance(challengeNo);
	const { mutate } = usePostChallengeAttendance();

	const today = new Date();
	const formattedDate = moment(today).format("DD"); // 두 자리 숫자로 날짜 포맷
	const formattedDay = moment(today).format("dddd"); // 요일 포맷

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// 출석 체크
	const handleAttendanceCheck = () => {
		if (getIsComplete(challengeAttendanceData, today) || !isIn) return;

		mutate(challengeNo, {
			onSuccess: () => refetch(),
		});
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// 일별 타일 컨텐츠
	const tileContentDisplay = ({ date }) => {
		let isComplete = false;
		let notThisMonth = false;

		if (!isCurrentMonth(date)) notThisMonth = true;
		if (getIsComplete(challengeAttendanceData, date)) isComplete = true;

		return (
			<div
				className={clsx([
					"dayTileContent",
					isComplete && "complete",
					notThisMonth && "notThisMonth",
				])}
			>
				{isComplete ? "" : moment(date).format("DD")}
			</div>
		);
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	if (isLoading) return null;

	return (
		<div className={styles.attendanceContainer}>
			<div className={styles.attendanceBackground}>
				<div className={styles.attendanceContent}>
					<div className={styles.attendanceInfo}>
						{/* 날짜 정보 */}
						<div>
							<div className={styles.infoMonth}>
								{challengeAttendanceData?.targetYear}.
								{challengeAttendanceData?.targetMonth?.toString()?.padStart(2, "0")}
							</div>
							<div className={styles.infoDay}>
								<span>{formattedDate}</span>
								{textInfo.common.day} {formattedDay}
							</div>
						</div>
						{/* 나의 출석일 */}
						<div className={styles.infoMyDay}>
							{textInfo.reward.challenge.attendance.myDay}
							<span>{challengeAttendanceData?.attendanceDays?.length}</span>
							{textInfo.common.day}
						</div>
					</div>
					{/* 캘린더 */}
					<Calendar
						className="rewardAttendanceCalendar"
						showNavigation={false}
						calendarType="gregory" // 일요일 부터 시작
						showNeighboringMonth={true} // 전달, 다음달 날짜 보이기;
						tileDisabled={() => true} // 타일 클릭 비활성화
						tileContent={tileContentDisplay}
					/>
					{/* 출석 체크 버튼 */}
					<div
						className={clsx([
							styles.attendanceButton,
							(getIsComplete(challengeAttendanceData, today) || !isIn) && styles.disabled,
						])}
						onClick={() => handleAttendanceCheck()}
					>
						{textInfo.reward.challenge.attendance.checkButton}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AttendanceCalendar;
