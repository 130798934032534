/**
 * 마이페이지 요약
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import useAuth from "src/hooks/useAuth";

import { AppContext } from "src/App";
import { DEVICE_TYPE, EMPTY_USER_PROFILE_IMAGE } from "src/data/constEnum";
import Image from "src/pages/component/common/image";
import MoveToBack from "src/pages/component/moveToBack";
import { API_RESULT_STATUS } from "src/data/constEnum";
import { getRewardStatus } from "src/api/member/reward";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const MyPageSummary = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const auth = useAuth();
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const { device } = useContext(AppContext);

	const [reward, setReward] = useState();

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 레벨 클릭
	 * @param {Event} e
	 */
	const handlerClickLevel = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		navigate(pathList.myPage.level);
	};

	/**
	 * 내 정보 관리 클릭
	 * @param {Event} e
	 */
	const handlerClickMyInfo = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		navigate(pathList.myPage.myInfo, {
			state: {
				type:
					auth.isLogin && auth.user && auth.user.loginType ? auth.user.loginType.toLowerCase() : "",
			},
		});
	};

	/**
	 * 프로필 클릭
	 * @param {Event} e
	 */
	const handlerClickProfile = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		navigate(pathList.myPage.profile);
	};

	/**
	 * 리워드 클릭
	 * @param {Event} e
	 */
	const handlerClickReward = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		navigate(pathList.reward.index);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		/**
		 * 리워드 기본 정보 호출
		 */
		const getData = async () => {
			try {
				const result = await getRewardStatus();
				if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
					setReward(result.data?.availableReward);
				} else {
					window.linmeLog.log(result);
				}
			} catch (error) {
				window.linmeLog.log(error);
			}
		};
		getData();
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="contents">
			<div className="titleWrapper">
				<MoveToBack />
				<div
					className={
						"title " +
						(pathname != pathList.myPage.index && device !== DEVICE_TYPE.PC ? "backOn" : "")
					}
				>
					{textInfo.myPage.title}
				</div>
			</div>
			<div className="myPageSummary">
				<div className="myInfo">
					<div className="profile" onClick={(e) => handlerClickProfile(e)}>
						<Image
							src={
								auth.isLogin && auth.user && auth.user.image && auth.user.image.webPath
									? auth.user.image.webPath
									: EMPTY_USER_PROFILE_IMAGE
							}
							alt={auth.isLogin && auth.user && auth.user.nickname ? auth.user.nickname : ""}
						/>
					</div>
					{auth.isLogin ? (
						<>
							<div className="data">
								<div className="infoAndButton">
									<div className="info">
										<div
											className={
												"nickname " +
												(auth.isLogin && auth.user && auth.user.loginType
													? auth.user.loginType.toLowerCase()
													: "")
											}
											onClick={(e) => handlerClickProfile(e)}
										>
											<div
												className={
													"nicknameText " +
													(auth.isLogin && auth.user.nickname && auth.user.nickname.length > 12
														? "long"
														: "")
												}
											>
												{auth.isLogin ? auth.user.nickname : ""}
											</div>
										</div>
										<div
											className={"level " + (auth.isLogin ? auth.user.levelName.toLowerCase() : "")}
											onClick={(e) => handlerClickLevel(e)}
										>
											{auth.isLogin ? auth.user.levelName : ""}&nbsp;{textInfo.myPage.level}
										</div>
									</div>
									<div
										className="button clickable myInfoModify"
										onClick={(e) => handlerClickMyInfo(e)}
									>
										{textInfo.myPage.myInfoModify.title}
									</div>
								</div>
								<div className="reward" onClick={(e) => handlerClickReward(e)}>
									<span>
										<span>{textInfo.reward.title}</span>
										<span className="rewardValue">{reward ? reward.toLocaleString() : "-"}</span>
										<span className="rewardUnit">{textInfo.common.rewardUnit}</span>
									</span>
									<div className="moveIcon" />
								</div>
							</div>
						</>
					) : (
						<>
							<Link to={pathList.member.login}>
								<div className="button clickable signIn">{textInfo.button.intoLinme}</div>
							</Link>
						</>
					)}
				</div>
				<ul className="myShopping">
					<li className={pathname.indexOf("/shopping") > -1 ? "on" : ""}>
						<Link to={pathList.myPage.shoppingOrder}>
							<div className="icon shopping"></div>
							<div className="text">{textInfo.myPage.myShopping.title}</div>
							<div className="count">{auth.isLogin ? auth.user.orderCount : 0}</div>
						</Link>
					</li>
					<li className={pathname.indexOf("/coupon") > -1 ? "on" : ""}>
						<Link to={pathList.myPage.coupon}>
							<div className="icon coupon"></div>
							<div className="text">{textInfo.myPage.myCoupon.title}</div>
							<div className="count">{auth.isLogin ? auth.user.couponCount : 0}</div>
						</Link>
					</li>
					<li className={pathname.indexOf("/review") > -1 ? "on" : ""}>
						<Link to={pathList.myPage.review}>
							<div className="icon review"></div>
							<div className="text">{textInfo.myPage.myReview.title}</div>
							<div className="count">{auth.isLogin ? auth.user.reviewCount : 0}</div>
						</Link>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default MyPageSummary;
