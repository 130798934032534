/**
 * 상품 상세 - 간략 정보
 */

import { useState, useRef, useCallback } from "react";
import Image from "src/pages/component/common/image";

import { IMAGE_ERROR_SRC, DEFAULT_ERROR_IMAGE_LIST, DEVICE_TYPE } from "src/data";

import Swiper from "../swiper";
import { SwiperSlide } from "swiper/react";
import { Thumbs } from "swiper/modules";
import useDeviceType from "src/hooks/useDeviceType";
import ProductDetailTimer from "../product/detail/productDetailTimer";
import styles from "src/styles/product/detail/productDetailImageList.module.scss";
import "src/styles/swiperThumbs.scss";
import { clsx } from "clsx";

const ProductDetailImagePaging = (props) => {
	const { images, topicItem } = props;
	const imageList = images || DEFAULT_ERROR_IMAGE_LIST;
	const [currentIndex, setCurrentIndex] = useState(0);
	const { mobileType } = useDeviceType();
	const [thumbsSwiper, setThumbsSwiper] = useState(null);

	const swiperRef = useRef(null);

	const handlePrev = useCallback(() => {
		if (swiperRef.current && swiperRef.current.swiper) {
			swiperRef.current.swiper.slidePrev();
		}
	}, []);

	const handleNext = useCallback(() => {
		if (swiperRef.current && swiperRef.current.swiper) {
			swiperRef.current.swiper.slideNext();
		}
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className={styles.container}>
			{/* 썸네일 */}
			<Swiper
				onSwiper={setThumbsSwiper}
				spaceBetween={10}
				slidesPerView={3.5}
				watchSlidesProgress={true}
				modules={[Thumbs]}
				className={clsx(["thumbs", styles.thumbs])}
				direction={mobileType === DEVICE_TYPE.TABLET ? "horizontal" : "vertical"}
			>
				{imageList?.map((image) => {
					return (
						<SwiperSlide key={image.fileNo}>
							{image && image.webPath ? (
								<Image className={styles.thumbImage} src={image.webPath} alt={2} />
							) : (
								<Image className={styles.thumbImage} src={IMAGE_ERROR_SRC.large} alt={2} />
							)}
						</SwiperSlide>
					);
				})}
			</Swiper>
			{/* 메인 */}
			<div className={styles.mainContainer}>
				{topicItem && topicItem?.startAt && topicItem?.endAt && (
					<ProductDetailTimer
						startAt={topicItem.startAt}
						endAt={topicItem.endAt}
						isShowTimer={true}
					/>
				)}
				<Swiper
					ref={swiperRef}
					spaceBetween={10}
					thumbs={{ swiper: thumbsSwiper }}
					modules={[Thumbs]}
					className={clsx([styles.main])}
					onSlideChange={(swiper) => setCurrentIndex(swiper.activeIndex)}
				>
					{imageList?.map((image) => {
						return (
							<SwiperSlide key={image.fileNo}>
								{image && image.webPath ? (
									<Image className={styles.image} src={image.webPath} alt={2} />
								) : (
									<Image className={styles.image} src={IMAGE_ERROR_SRC.large} alt={2} />
								)}
							</SwiperSlide>
						);
					})}
					<div className={styles.navigationContainer}>
						<div className={styles.prev} onClick={handlePrev} />
						<div className={styles.next} onClick={handleNext} />
					</div>
					<div className={styles.pageInfoContainer}>
						<div className={styles.pageIndex}>
							<span>{currentIndex + 1}</span> |<span>{imageList?.length || 0}</span>
						</div>
					</div>
				</Swiper>
			</div>
		</div>
	);
};

export default ProductDetailImagePaging;
