/**
 * object로 만들어진 모달객체를 전부 그려내기 위한 Container ( Dimmer 포함 )
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import React, { useContext } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

import { ModalStacks } from "src/context/modalStackProvider";
import Modal from "src/pages/component/modal/modal";
import { Z_INDEX } from "src/data/constEnum";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

const ModalDimmer = styled.div`
	position: fixed;
	display: block;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.25);
	z-index: ${Z_INDEX.DIMMER};
`;

const ModalStackContainer = () => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const { modalStack } = useContext(ModalStacks);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	const drawModals = () => {
		let r = [];
		//modalStack.map((modal) => {
		for (let id in modalStack) {
			const modal = modalStack[id];
			r.push(
				<ModalDimmer className="modalDimmer" key={modal.id}>
					<Modal {...modal} />
				</ModalDimmer>
			);
			// });
		}
		return r;
	};

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return <>{createPortal(drawModals(), window.document.querySelector("#modal"))}</>;
};

export default ModalStackContainer;
