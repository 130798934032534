/**
 * Verify
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect } from "react";

import useModalStack from "src/hooks/useModalStack";
import { API_RESULT_STATUS } from "src/data/constEnum";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/member/modal/authVerifyIframe.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
// const textInfo = require( "src/data/textInfo.json" );

const AuthVerifyIframe = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 본인인증 후처리
	 * @param {Object} result
	 * @returns
	 */
	const afterVerify = (result) => {
		if (result.origin === window.origin) return false;

		if (!result.data) return false;
		if (result.data === "FAIL") {
			//제한 횟수 초과, 제한시간 만료 등 실패의 경우;
			modalStack.removeModal(props.id);
		}
		if (!result.data.status) return false;
		if (result.data.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
			if (props.setDataAfterVerify) {
				props.setDataAfterVerify(result.data);
			}
			modalStack.removeModal(props.id);
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		window.addEventListener("message", afterVerify);
		return () => {
			window.removeEventListener("message", afterVerify);
		};
	}, [props]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="authVerifyContainer">
			<iframe src={props.authUrl} title="verify" target="_blank" />
		</div>
	);
};

export default AuthVerifyIframe;
