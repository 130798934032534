/**
 * 메인화면
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useContext, useEffect, useState } from "react";

import { AppContext } from "src/App";
import { productListTopicSummary, productListTopicSummaryTimeSale } from "src/api/product/topic";
import useDeviceType from "src/hooks/useDeviceType";
import useTopicTimeSaleList from "src/hooks/main/useTopicTimeSaleList";

import BannerMain from "src/pages//main/bannerMain";
import HealthIssue from "src/pages/main/healthIssue";
import SubBanner from "src/pages/main/subBanner";
import TopicTimer from "src/pages/component/topicSection/topicTimer";
import TopicPeriod from "src/pages/component/topicSection/topicPeriod";
import AllProductList from "src/pages/main/AllProductList";
import {
	API_RESULT_STATUS,
	DEEP_LINK_PATH,
	DEVICE_TYPE,
	PRODUCT_TYPE_NO,
	TOPIC_LIMIT,
} from "src/data";

import "src/styles/main.scss";
import "src/styles/productList.scss";

const Main = () => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const { mainSelectedMenuType } = useContext(AppContext);
	const [topicTimeSaleList, setTopicTimeSaleList] = useState(null);
	const [topicList, setTopicList] = useState([]);
	const { setSchemePath } = useContext(AppContext);
	const { mobileType } = useDeviceType();
	const topicPeriodLimit = mobileType === DEVICE_TYPE.PC ? TOPIC_LIMIT.PC : TOPIC_LIMIT.NOT_PC;

	/**
	 * 기획전 - 타임특가 목록 가져오기 (상품 최대 개수 5개)
	 */
	const getReqTopicTimeSaleList = async () => {
		try {
			let d = {
				typeNo: PRODUCT_TYPE_NO[mainSelectedMenuType],
			};

			const result = await productListTopicSummaryTimeSale(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				setTopicTimeSaleList(result.data);
			} else {
				window.linmeLog.log(result);
			}
		} catch (err) {
			window.linmeLog.error(err);
		}
	};

	/**
	 * 기획전 목록 가져오기 (상품 최대 개수 5개)
	 */
	const getReqTopicList = async () => {
		try {
			const requestData = {
				page: 1,
				offset: 999,
				typeNo: PRODUCT_TYPE_NO[mainSelectedMenuType],
			};

			const result = await productListTopicSummary(requestData);

			if (result.status.toUpperCase() !== API_RESULT_STATUS.SUCCESS) {
				window.linmeLog.log(result);
				return;
			}

			setTopicList(result?.data?.content);
		} catch (err) {
			window.linmeLog.error(err);
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		const getData = async () => {
			await setSchemePath(DEEP_LINK_PATH.MAIN);
			await getReqTopicTimeSaleList();
			await getReqTopicList();
		};
		getData();
	}, [mainSelectedMenuType]);

	const { data: timeSaleInfo } = useTopicTimeSaleList(mainSelectedMenuType);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className={"container main " + mainSelectedMenuType}>
			<BannerMain />
			<HealthIssue />
			{/* 토픽 - 타임특가 */}
			{topicTimeSaleList?.items && <TopicTimer timeSaleInfo={timeSaleInfo} />}
			{/* 띠배너 */}
			<SubBanner />
			{/* 토픽 - 일반기획전 */}
			{topicList?.map((topic) => (
				<TopicPeriod
					key={topic?.listNo}
					topic={topic}
					isShowTopicImage={true}
					limit={topicPeriodLimit}
				/>
			))}
			{/* 전체 상품 목록 */}
			<AllProductList />
		</div>
	);
};

export default Main;
