/**
 * 상품 상세 - 간략정보 - 선택된 옵션
 */

import { PRODUCT_OPTIONS_TYPE } from "src/data/constEnum";
import CustomNumberStepper from "src/pages/component/common/customNumberStepper";

const textInfo = require("src/data/textInfo.json");
import styles from "src/styles/product/detail/productDetailSelectedOptions.module.scss";
import { clsx } from "clsx";

const ProductDetailSelectedOptions = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const {
		data,
		selectedResultRequired,
		setSelectedResultRequired,
		selectedResultAdditional,
		setSelectedResultAdditional,
	} = props;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 필수 옵션 삭제
	 */
	const handleDeleteRequiredOption = (optionData) => {
		if (!optionData || !optionData?.optionNo) return;
		setSelectedResultRequired((prev) =>
			prev.filter((item) => item.optionNo !== optionData.optionNo)
		);
	};

	/**
	 * 선택 옵션 삭제
	 */
	const handleDeleteAdditionalOption = (optionData) => {
		if (!optionData || !optionData?.optionNo) return;
		setSelectedResultAdditional((prev) =>
			prev.filter((item) => item.optionNo !== optionData.optionNo)
		);
	};

	/**
	 * 옵션 수량 및 금액 변경
	 */
	const handlerUpdateQuantity = (type, num, optionData) => {
		if (!optionData || !optionData?.optionNo) return;

		const isRequired = type === PRODUCT_OPTIONS_TYPE?.REQUIRED;

		// 옵션 총금액, 수량 업데이트
		if (isRequired) {
			const requiredTotalPrice = (data.salePrice + optionData.addPrice) * num || 0;
			setSelectedResultRequired((prev) =>
				prev.map((item) =>
					item.optionNo === optionData.optionNo
						? { ...item, quantity: num, totalPrice: requiredTotalPrice }
						: item
				)
			);
		} else {
			const additionalTotalPrice = optionData.addPrice * num || 0;
			setSelectedResultAdditional((prev) =>
				prev.map((item) =>
					item.optionNo === optionData.optionNo
						? { ...item, quantity: num, totalPrice: additionalTotalPrice }
						: item
				)
			);
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<>
			{/* 선택한 필수 옵션 */}
			{selectedResultRequired?.length > 0 && (
				<div className={clsx([styles.productBuyInfoItem, styles.productOptionSelected])}>
					<div className={styles.selectedRequiredOptions}>
						<span
							className={clsx([
								styles.selectedOptionType,
								styles[PRODUCT_OPTIONS_TYPE.REQUIRED.toLowerCase()],
							])}
						>
							{
								textInfo.product.detail.option.optionType[
									PRODUCT_OPTIONS_TYPE.REQUIRED.toLowerCase()
								]
							}
						</span>
						{selectedResultRequired.map((item, index) => {
							return (
								<div className={styles.selectedOptionCover} key={index}>
									<div className={styles.selectedOptionTop}>
										<div className={styles.selectedOptionName}>
											{item?.fullName}
											{item?.addPrice ? ` (+${item?.addPrice}원)` : ""}
										</div>
										<div
											className={clsx([
												styles.button,
												styles.clickable,
												styles.deleteSelectedOption,
											])}
											onClick={() => handleDeleteRequiredOption(item)}
										></div>
									</div>
									<div className={styles.selectedOptionBottom}>
										<CustomNumberStepper
											min={1}
											max={item?.stock}
											count={item?.quantity}
											setCount={(num) =>
												handlerUpdateQuantity(PRODUCT_OPTIONS_TYPE?.REQUIRED, num, item)
											}
										/>
										<div className={styles.selectedOptionPrice}>
											{item?.totalPrice.toLocaleString()}
											<span>{textInfo.common.currencyUnit}</span>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			)}

			{/* 선택한 선택 옵션 */}
			{selectedResultAdditional?.length > 0 && (
				<div className={clsx([styles.productBuyInfoItem, styles.productOptionSelected])}>
					<div className={styles.selectedRequiredOptions}>
						<span
							className={clsx([
								styles.selectedOptionType,
								styles[PRODUCT_OPTIONS_TYPE.ADDITIONAL.toLowerCase()],
							])}
						>
							{
								textInfo.product.detail.option.optionType[
									PRODUCT_OPTIONS_TYPE.ADDITIONAL.toLowerCase()
								]
							}
						</span>
						{selectedResultAdditional.map((item, index) => {
							return (
								<div className={styles.selectedOptionCover} key={index}>
									<div className={styles.selectedOptionTop}>
										<div className={styles.selectedOptionName}>
											{item?.fullName}
											{item?.addPrice ? ` (+${item?.addPrice}원)` : ""}
										</div>
										<div
											className={clsx([
												styles.button,
												styles.clickable,
												styles.deleteSelectedOption,
											])}
											onClick={() => handleDeleteAdditionalOption(item)}
										></div>
									</div>
									<div className={clsx([styles.selectedOptionBottom])}>
										<CustomNumberStepper
											min={1}
											max={item?.stock}
											count={item?.quantity}
											setCount={(num) =>
												handlerUpdateQuantity(PRODUCT_OPTIONS_TYPE?.ADDITIONAL, num, item)
											}
										/>
										<div className={styles.selectedOptionPrice}>
											{item?.totalPrice.toLocaleString()}
											<span>{textInfo.common.currencyUnit}</span>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			)}
		</>
	);
};

export default ProductDetailSelectedOptions;
