/**
 * 리워드 - 랜딩
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useState } from "react";

import useAuth from "src/hooks/useAuth";

import ShouldBeLogin from "src/pages/component/shouldBeLogin";
import RewardChallengeList from "src/pages/component/reward/rewardChallengeList";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/reward/index.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */

// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const Reward = () => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const auth = useAuth();
	const [isProceedingListOn, setIsProceedingListOn] = useState(true);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 활성화 메뉴 변경
	 * @param {Event} e
	 */
	const handlerClickListType = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const ct = e.currentTarget;
		if (isProceedingListOn && ct.classList.contains("proceeding")) return;
		else if (!isProceedingListOn && ct.classList.contains("finished")) return;
		else setIsProceedingListOn(!isProceedingListOn);
	};

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<>
			{auth.user === null && !auth.isLogin ? (
				<ShouldBeLogin />
			) : (
				<>
					<ul className="challengeTypeList">
						<li
							className={"challengeTypeItem proceeding " + (isProceedingListOn ? "on" : "")}
							onClick={(e) => handlerClickListType(e)}
						>
							{textInfo.reward.challenge.in}
						</li>
						<li
							className={"challengeTypeItem finished " + (!isProceedingListOn ? "on" : "")}
							onClick={(e) => handlerClickListType(e)}
						>
							{textInfo.reward.challenge.finish}
						</li>
					</ul>
					<div className={`challengeContents ${isProceedingListOn ? "proceeding" : "finished"} on`}>
						<RewardChallengeList challengeStatus={isProceedingListOn ? "IN" : "FINISH"} />
					</div>
				</>
			)}
		</>
	);
};

export default Reward;
