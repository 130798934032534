/**
 * 마이린미 메인 - MBTI 테스트 진행 상태바
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect } from "react";

import { COLOR } from "src/data/constEnum";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const TestStatusRange = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {}, [props]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="rangeCover ">
			<div
				className="statusRange"
				style={{
					background: `linear-gradient(to right, ${COLOR.LINME} ${props.percent}%, ${COLOR.PINK01} ${props.percent}%, ${COLOR.PINK01} 100%)`,
				}}
			>
				<div className="rangePill" style={{ left: `${props.percent}%` }}></div>
				<div className="percent" style={{ left: `${props.percent}%` }}>
					{props.percent}
					<span className="percentUnit">{textInfo.common.percentUnit}</span>
				</div>
			</div>
		</div>
	);
};
export default TestStatusRange;
