import styles from "src/styles/main/topicSection/topicSectionContainer.module.scss";

const TopicSectionContainer = ({ children }) => {
	return (
		<div className={styles.container}>
			<div className={styles.innerContainer}>{children}</div>
		</div>
	);
};

export default TopicSectionContainer;
