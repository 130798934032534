import moment from "moment";

/**
 * 두 날짜 사이의 날짜들을 배열에 담아 반환
 * @param {String} d_start
 * @param {String} d_end
 * @return {String} a
 * @example
 * <code>
	getDateRange( "2017-02-01", "2017-02-05" );
 * </code>
 */
export const getDateRange = (d_start, d_end) => {
	let a = [];
	let dateMove = new Date(d_start);
	let strDate;

	if (d_start === d_end) {
		strDate = dateMove.toISOString().slice(0, 10);
		a.push(strDate);
	} else {
		while (dateMove < new Date(d_end)) {
			strDate = dateMove.toISOString().slice(0, 10);
			a.push(strDate);
			dateMove.setDate(dateMove.getDate() + 1);
		}
	}
	return a;
};

/**
 * 시각에 대하여 "오전/오후" 반환
 * @param {Date|Null} date
 * @param {String} t "00:00"
 */
export const getFormatApm = (date, t) => {
	let result = "";
	let targetDate = date ? moment(date) : moment();
	let d = null;
	if (t) {
		d = moment(targetDate.format("YYYY-MM-DD") + " " + t);
	} else {
		d = moment(targetDate);
	}
	result = d.format("HH") < 12 ? "오전 " : "오후 ";
	return result;
};

/**
 * 시각에 대하여 +n 시간 후 "오전/오후" 반환
 * @param {Date|Null} date
 * @param {String} t "00:00"
 */
export const getFormatApmNextHour = (date, t, nHours) => {
	let result = "";
	let targetDate = date ? moment(date) : moment();
	targetDate.add(nHours, "hour");
	let d = null;
	if (t) {
		d = moment(targetDate.format("YYYY-MM-DD") + " " + t);
	} else {
		d = moment(targetDate);
	}
	result = d.format("HH") < 12 ? "오전 " : "오후 ";
	return result;
};

/**
 * 시각에 대하여 "오전/오후" 한글 적용된 포맷 반환
 * @param {Date|Null} date
 * @param {String} t "00:00"
 */
export const getFormatTimeApm = (date, t) => {
	let result = "";
	let targetDate = date ? moment(date) : moment();
	let d = null;
	if (t) {
		d = moment(targetDate.format("YYYY-MM-DD") + " " + t);
	} else {
		d = moment(targetDate);
	}
	result = (d.format("HH") < 12 ? "오전 " : "오후 ") + d.format("hh:mm");
	return result;
};

/**
 * 시각에 대하여 적용된 포맷 반환
 * @param {Date|Null} date
 * @param {String} t "00:00"
 */
export const getFormatTime = (date, t) => {
	let result = "";
	let targetDate = date ? moment(date) : moment();
	let d = null;
	if (t) {
		d = moment(targetDate.format("YYYY-MM-DD") + " " + t);
	} else {
		d = moment(targetDate);
	}
	result = d.format("hh:mm");
	return result;
};

/**
 * 시각에 대하여 적용된 포맷 반환
 * @param {Date|Null} date
 * @param {String} t "00:00"
 */
export const getFormatTimeNextHour = (date, t, nHours) => {
	let result = "";
	let targetDate = date ? moment(date) : moment();
	targetDate.add(nHours, "hour");
	let d = null;
	if (t) {
		d = moment(targetDate.format("YYYY-MM-DD") + " " + t);
	} else {
		targetDate.set("minute", 0);
		d = moment(targetDate);
	}
	result = d.format("hh:mm");
	return result;
};

/**
 * 00시 기준 24시간을 간격시간 단위로 배열에 담아 반환
 * @param {Number} periodMinutes 분단위
 * @param {Boolean} bApmKo 오전/오후 한글 추가 여부
 * @param {String} timeStart "00:00"
 * @param {String} timeEnd "00:00"
 */
export const getHoursForDay = (periodMinutes, bApmKo, timeStart, timeEnd) => {
	let r = [];
	let d = new Date();
	d.setDate(1);
	d.setHours(0);
	d.setMinutes(0);
	d.setSeconds(0);
	d.setMilliseconds(0);

	if (!timeEnd) {
		while (d.getDate() === 1) {
			d.setMinutes(d.getMinutes() + periodMinutes);
			if (bApmKo === true) {
				r.push((moment(d).format("HH") < 12 ? "오전 " : "오후 ") + moment(d).format("hh:mm"));
			} else {
				r.push(moment(d).format("HH:mm"));
			}
		}
		//00시를 가장 앞으로 보내기 위함;
		r.unshift(r[r.length - 1]);
		r.pop();
	} else {
		if (timeStart) {
			let dStart = new Date(moment(d).format("YYYY-MM-DD") + " " + timeStart);
			d.setHours(dStart.getHours());
			d.setMinutes(dStart.getMinutes());
		}
		let dEnd = new Date(d);
		if (timeEnd) {
			let dTemp = new Date(moment(d).format("YYYY-MM-DD") + " " + timeEnd);
			dEnd.setHours(dTemp.getHours());
			dEnd.setMinutes(dTemp.getMinutes());
		}

		while (d < dEnd) {
			d.setMinutes(d.getMinutes() + periodMinutes);
			if (bApmKo === true) {
				r.push((moment(d).format("HH") < 12 ? "오전 " : "오후 ") + moment(d).format("hh:mm"));
			} else {
				r.push(moment(d).format("HH:mm"));
			}
		}
	}

	return r;
};

/**
 * 00시 기준 24시간을 간격시간 단위로 배열에 담아 반환
 * @param {Number} periodMinutes 분단위
 * @param {Boolean} bApmKo 오전/오후 한글 추가 여부
 * @param {String} timeStart "00:00"
 * @param {String} timeEnd "00:00"
 */
export const getHoursForDaySelectTime = (periodMinutes, bApmKo, timeStart, timeEnd) => {
	let r = [];
	let d = new Date();
	d.setDate(1);
	d.setHours(0);
	d.setMinutes(0);
	d.setSeconds(0);
	d.setMilliseconds(0);

	if (!timeEnd) {
		while (d.getDate() === 1) {
			d.setMinutes(d.getMinutes() + periodMinutes);
			if (bApmKo === true) {
				r.push({
					label: (moment(d).format("HH") < 12 ? "오전 " : "오후 ") + moment(d).format("hh:mm"),
					value: moment(d).format("HH:mm"),
				});
			} else {
				r.push({
					label: moment(d).format("HH:mm"),
					value: moment(d).format("HH:mm"),
				});
			}
		}
		//00시를 가장 앞으로 보내기 위함;
		r.unshift(r[r.length - 1]);
		r.pop();
	} else {
		if (timeStart) {
			let dStart = new Date(moment(d).format("YYYY-MM-DD") + " " + timeStart);
			d.setHours(dStart.getHours());
			d.setMinutes(dStart.getMinutes());
		}
		let dEnd = new Date(d);
		if (timeEnd) {
			let dTemp = new Date(moment(d).format("YYYY-MM-DD") + " " + timeEnd);
			dEnd.setHours(dTemp.getHours());
			dEnd.setMinutes(dTemp.getMinutes());
		}

		d.setMinutes(d.getMinutes() - periodMinutes);
		while (d < dEnd) {
			d.setMinutes(d.getMinutes() + periodMinutes);
			if (bApmKo === true) {
				r.push({
					label: (moment(d).format("HH") < 12 ? "오전 " : "오후 ") + moment(d).format("hh:mm"),
					value: moment(d).format("HH:mm"),
				});
			} else {
				r.push({
					label: moment(d).format("HH:mm"),
					value: moment(d).format("HH:mm"),
				});
			}
		}
	}

	return r;
};

/**
 * 시간을 포맷 적용하여 ( am/pm 구분이 있는 경우 오전/오후 적용하여 ) 반환
 * @param {String} d
 * @param {String} format
 * @returns {String}
 * @example
 * <code>
	getTimeStampMomentKo( "2024-04-12 15:01:52", "YYYY년 MM월 DD일 a hh:mm" );
 * </code>
 */
export const getTimeStampMomentKo = (d, format) => {
	if (!d) d = moment();
	const str = moment(d).format(format);
	return str.toLowerCase().replace(/am/, "오전").replace(/pm/, "오후");
};

/**
 * 시간을 포맷 적용하여 반환 ( 시간 입력 안될 시 현재 시각 )
 * @param {Date} d
 * @param {String} format
 * <code>
 * 	format sample : 'YYYY-MM-DD hh:mm:ss'
 * </code>
 * @return {String}
 * @example
 * <code>
   getTimeStamp( null, "YYYYMMDDhhmmss" );
 * </code>
 */
export const getTimeStamp = (d, format) => {
	format = format ? format : "YYYY-MM-DD hh:mm:ss";
	const leadingZeros = function (value, digit) {
		var result = "";
		value = value.toString();

		if (value.length < digit) {
			var i = 0,
				iLen = digit - value.length;
			for (; i < iLen; ++i) result += "0";
		}
		return result + value;
	};

	if (!d) d = new Date();
	else {
		if (typeof d == "string") {
			d = new Date(d);
		}
	}
	const f = leadingZeros;

	const y = f(d.getFullYear(), 4); //년;
	const m = f(d.getMonth() + 1, 2); //월;
	const dd = f(d.getDate(), 2); //일;

	const hh = f(d.getHours(), 2); //시;
	const mm = f(d.getMinutes(), 2); //분;
	const ss = f(d.getSeconds(), 2); //초;

	let now =
		format.indexOf("YYYY") > -1
			? format.replace("YYYY", y)
			: format.replace("YY", y.substring(2, 4));
	now = now.replace("MM", m);
	now = now.replace("DD", dd);
	now = now.replace("hh", hh);
	now = now.replace("mm", mm);
	now = now.replace("ss", ss);

	return now;
};

/**
 * 만나이 계산
 * @param {String} birthday
 * @returns
 */
export const getWesternAge = (birthday) => {
	if (birthday.length === 8) {
		birthday =
			birthday.substring(0, 4) + "-" + birthday.substring(4, 6) + "-" + birthday.substring(6);
	}
	let today = new Date();
	let birthDay = new Date(birthday);
	let age = today.getFullYear() - birthDay.getFullYear();

	let todayMonth = today.getMonth() + 1;
	let birthMonth = birthDay.getMonth() + 1;

	if (
		birthMonth > todayMonth ||
		(birthMonth === todayMonth && birthDay.getDate() >= today.getDate())
	) {
		age--;
	}
	return age;
};

/**
 * 알림 내역에서 전송일자 표출을 위한 포맷 적용하여 반환
 * 방금 전, n일 전, n주 전, 1달 전, 일자
 * @param {String} t
 * @return {String} result
 */
export const setDateFormatForNotification = (t) => {
	const d = moment(t);
	const now = moment();

	// 1달 혹은 이상인 경우
	const monthsDiff = now.diff(d, "months");
	if (monthsDiff >= 1) {
		return monthsDiff === 1 ? "1달 전" : d.format("YY.MM.DD");
	}

	// 7일 이상인 경우 주 단위 표기
	const daysDiff = now.diff(d, "days");
	if (daysDiff >= 7) {
		return Math.ceil(daysDiff / 7) + "주 전";
	}

	// 1일 이상인 경우 일 단위 표기
	if (daysDiff >= 1) {
		return daysDiff + "일 전";
	}

	// 1시간 이상인 경우 시간 단위 표기
	const hoursDiff = now.diff(d, "hours");
	if (hoursDiff >= 1) {
		return hoursDiff + "시간 전";
	}

	// 10분 이상인 경우 분 단위 표기
	const minutesDiff = now.diff(d, "minutes");
	if (minutesDiff >= 10) {
		return minutesDiff + "분 전";
	}

	// 10분 미만인 경우 "방금 전" 표기
	return "방금 전";
};
