/**
 * 아이디/비밀번호 찾기 및 재설정
 */

import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/**
 * 아이디 ( 이메일 ) 찾기
 * @param {Object} d
 * <code>
 * {
 * 		ci: ""
 * }
 * </code>
 */
export const requestFindId = async (d) => {
	const url = `${API_PATHS.MEMBER_FIND_ID}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 본인인증 후 회원여부 확인
 * @param {Object} d
 * <code>
 * {
 * 		ci: ""
 * }
 * </code>
 * @returns
 */
export const requestFindMemberByCi = async (d) => {
	const url = `${API_PATHS.MEMBER_FIND_BY_CI}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 비밀번호 재설정
 * @param {Object} d
 * <code>
 * {
 * 		ci: ""
 * 		, newPassword: ""
 * }
 * </code>
 */
export const requestResetPw = async (d) => {
	const url = `${API_PATHS.MEMBER_FIND_PW}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
