import { useContext } from "react";
import { AppContext } from "src/App";
import useModalStack from "src/hooks/useModalStack";
import useProductPhotoReviewList from "src/hooks/product/useProductPhotoReview";

import ReviewPhoto from "src/pages/product/modal/reviewPhoto";
import ReviewPhotoList from "src/pages/product/modal/reviewPhotoList";
import Image from "src/pages/component/common/image";
import { DEVICE_TYPE } from "src/data/index";
import "src/styles/review/photoReviewList.scss";

const PhotoReviewList = ({ productNo }) => {
	const { data } = useProductPhotoReviewList(productNo);
	const modalStack = useModalStack();
	const { device } = useContext(AppContext);

	const showReviewPhotoList = () => {
		modalStack.addModal({
			id: "modalReviewPhotoList",
			type: "alert",
			component: <ReviewPhotoList />,
			button: { iconClose: true },
			productNo,
			showReview: handlerClickOpenModalReviewPhoto,
		});
	};

	const handlerClickOpenModalReviewPhoto = (e, item) => {
		e.preventDefault();
		e.stopPropagation();
		modalStack.addModal({
			id: "modalReviewPhoto",
			type: "none",
			component: <ReviewPhoto {...item} />,
			productNo,
			showList: showReviewPhotoList,
			button: { iconClose: true },
		});
	};

	const handlerClickPhoto = (e, index, item) => {
		e.preventDefault();
		e.stopPropagation();
		if ((device === DEVICE_TYPE.PC || device === DEVICE_TYPE.TABLET) && index === 6) {
			showReviewPhotoList();
		} else if (device === DEVICE_TYPE.MOBILE && index === 3) {
			showReviewPhotoList();
		} else {
			handlerClickOpenModalReviewPhoto(e, item);
		}
	};

	if (!data?.content || data?.content?.length === 0) return;

	return (
		<div className="summaryPhoto">
			<ul className="photoReviewList">
				{data?.content?.map((item, index) => (
					<li
						className="photoReviewItem"
						key={index}
						onClick={(e) => handlerClickPhoto(e, index, item)}
					>
						<Image src={item.image.webPath} alt="리뷰" />
						{/*
							PC 또는 TABLET 디바이스일 때, 인덱스가 6인 경우 showMorePhotoCover.
							MOBILE && 인덱스가 3인 경우에만 showMorePhotoCover.
							'더보기' 표시.
						*/}
						{((device === DEVICE_TYPE.PC || device === DEVICE_TYPE.TABLET) && index === 6) ||
						(device === DEVICE_TYPE.MOBILE && index === 3) ? (
							<div className="showMorePhotoCover"></div>
						) : null}
					</li>
				))}
			</ul>
		</div>
	);
};

export default PhotoReviewList;
