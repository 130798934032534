import axios from "axios";
import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";
import { LOCAL_STORAGE_KEY } from "src/data/key";

const pathList = require("src/path/pathJSON.json");

// Axios 인스턴스 생성
const apiMyLinme = axios.create({
	baseURL: `${process.env.REACT_APP_LINME_CLIENT_API_MYLINME}${process.env.REACT_APP_LINME_CLIENT_API_VERSION1_MYLINME}`,
	headers: { "Content-type": "application/json" },
	withCredentials: true,
});

// 파라미터 직렬화 설정
apiMyLinme.defaults.paramsSerializer = function (paramObj) {
	const params = new URLSearchParams();
	for (const key in paramObj) params.append(key, paramObj[key]);
	return params.toString();
};

// 요청 인터셉터 설정
apiMyLinme.interceptors.request.use(
	function (config) {
		const token = window.localStorage.getItem(`${LOCAL_STORAGE_KEY.ACCESS_TOKEN}`);

		// 토큰이 없는 경우
		if (!token) {
			config.headers.accessToken = null;
			return config;
		}

		// 토큰이 있는 경우
		if (config.headers && token) {
			config.headers.Authorization = `${token}`;
			return config;
		}
	},
	function (error) {
		return Promise.reject(error);
	}
);

let isRefreshing = false; // 리프레시 토큰 요청 중인지 여부
let refreshSubscribers = []; // 리프레시 토큰 요청 후 재시도할 요청 리스트

// 토큰이 갱신되면 모든 구독자에게 알림
function onRefreshed(token) {
	refreshSubscribers.map((callback) => callback(token));
	refreshSubscribers = [];
}

// 리프레시 토큰 요청 중인 다른 요청을 구독자로 추가
function addRefreshSubscriber(callback) {
	refreshSubscribers.push(callback);
}

// 응답 인터셉터 설정
apiMyLinme.interceptors.response.use(null, (error) => {
	const {
		config,
		response: { status },
	} = error;
	const originalRequest = config;

	// 401 에러 처리
	if (status === 401) {
		// 리프레시 토큰 요청 자체가 401인 경우
		if (config.url === `${API_PATHS.MEMBER_REFRESH}`) {
			window.localStorage.removeItem(`${LOCAL_STORAGE_KEY.ACCESS_TOKEN}`);
			window.localStorage.removeItem(`${LOCAL_STORAGE_KEY.REFRESH_TOKEN}`);
			window.localStorage.removeItem(`${LOCAL_STORAGE_KEY.EMAIL}`);
			if (window.location.pathname !== pathList.member.login) {
				window.location.href = pathList.member.login;
			}
			return Promise.reject(error);
		}

		// 현재 리프레시 토큰 요청 중이 아닌 경우
		if (!isRefreshing) {
			isRefreshing = true;

			const refreshToken = window.localStorage.getItem(`${LOCAL_STORAGE_KEY.REFRESH_TOKEN}`);
			const url = `${API_PATHS.MEMBER_REFRESH}`;
			const data = { refreshToken: refreshToken };

			if (!refreshToken) {
				window.localStorage.removeItem(`${LOCAL_STORAGE_KEY.ACCESS_TOKEN}`);
				window.localStorage.removeItem(`${LOCAL_STORAGE_KEY.REFRESH_TOKEN}`);
				window.localStorage.removeItem(`${LOCAL_STORAGE_KEY.EMAIL}`);
				return Promise.reject(error);
			}

			// 새로운 리프레시 토큰 요청
			return new Promise((resolve, reject) => {
				api.post(url, data).then(({ data }) => {
					const { accessToken, email, refreshToken } = data.data;
					if (accessToken)
						window.localStorage.setItem(`${LOCAL_STORAGE_KEY.ACCESS_TOKEN}`, accessToken);
					if (email) window.localStorage.setItem(`${LOCAL_STORAGE_KEY.EMAIL}`, email);
					if (refreshToken)
						window.localStorage.setItem(`${LOCAL_STORAGE_KEY.REFRESH_TOKEN}`, refreshToken);
					apiMyLinme.defaults.headers.common["Authorization"] = accessToken;
					onRefreshed(accessToken); // 모든 구독자에게 알림
					isRefreshing = false;
					resolve(apiMyLinme(originalRequest));
				});
			});
		}

		// 리프레시 토큰 요청 중인 경우 요청을 구독자로 추가
		return new Promise((resolve) => {
			addRefreshSubscriber((token) => {
				originalRequest.headers["Authorization"] = token;
				resolve(apiMyLinme(originalRequest));
			});
		});
	}

	return Promise.reject(error);
});

export default apiMyLinme;
