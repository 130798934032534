/**
 * 개인정보 처리방침
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";
import axios from "axios";
import API_PATHS from "src/api/apiPaths";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/notFound.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
// const textInfo = require( "src/data/textInfo.json" );

const Privacy = () => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const [content, setContent] = useState("");
	const url = `${process.env.REACT_APP_LINME_CLIENT_API}${API_PATHS.UI_TERMS_PRIVACY}`;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 데이터 불러오기
	 */
	const getData = async () => {
		await axios
			.get(url)
			.then((res) => {
				setContent(res.data.replace(/<p>&nbsp;/g, "<p>"));
			})
			.catch((err) => {
				window.linmeLog.error(err);
			});
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		getData();
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return <div dangerouslySetInnerHTML={{ __html: content }} />;
};

export default Privacy;
