/**
 * 마이린미 - 건강상태 증상불편
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";

import { getTopicInfo } from "src/api/myLinme/survey";
import { API_RESULT_STATUS } from "src/data/constEnum";
import Image from "src/pages/component/common/image";
import { removeInnerSpinner, setInnerSpinner } from "src/utils/linmeDisplay";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myLinme/survey/survey.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const MyLinmeSurveyTopic1 = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const [questionData, setQuestionData] = useState([]);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 설문 이전페이지로 이동
	 */
	const handlerClickPrev = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		props.handlerClickPrev(e);
	};

	/**
	 * 설문 다음페이지로 이동
	 */
	const handlerClickNext = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (!validation()) return;

		props.handlerClickNext(e);
	};

	/**
	 * 설문 선택 토글
	 */
	const handlerChangeData = (item) => {
		if (props?.topicInfoSelectedData?.length >= 3 && !getIsSelected(item)) return;

		if (getIsSelected(item)) {
			props?.setTopicInfoSelectedData((prev) => prev.filter((el) => el !== item.subCode));
		} else {
			props?.setTopicInfoSelectedData((prev) => [...prev, item?.subCode]);
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 질문 목록 가져오기 API
	 */
	const _getTopicInfo = async () => {
		if (
			!props.myInfoData &&
			(!props?.defaultSelectedData2?.subCode ||
				!props?.defaultSelectedData3 ||
				!props?.defaultSelectedData4)
		) {
			return;
		}

		const d = {};
		if (props.myInfoData) {
			d.parentId = props.myInfoData?.parentId;
			d.subCode = props.myInfoData?.subCode;
			d.codeGroupId = props.myInfoData?.codeGroupId;
			d.height = Number(props.myInfoData?.height);
			d.weight = Number(props.myInfoData?.weight);
		} else {
			d.parentId = props?.defaultSelectedData2?.parentId;
			d.subCode = props?.defaultSelectedData2?.subCode;
			d.codeGroupId = props?.defaultSelectedData2?.codeGroupId;
			d.height = Number(props?.defaultSelectedData4);
			d.weight = Number(props?.defaultSelectedData3);
		}

		try {
			await setInnerSpinner();

			const result = await getTopicInfo(d);

			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				setQuestionData(result?.data?.topicInfo);
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		} finally {
			await removeInnerSpinner();
		}
	};

	/**
	 * 설문 선택 여부 체크
	 */
	const getIsSelected = (item) => {
		return props?.topicInfoSelectedData?.find((el) => el === item.subCode);
	};

	/**
	 * validation
	 */
	const validation = () => {
		if (!props?.topicInfoSelectedData || props.topicInfoSelectedData?.length < 1) return;
		return true;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		_getTopicInfo();
	}, [
		props?.myInfoData,
		props?.defaultSelectedData2,
		props?.defaultSelectedData3,
		props?.defaultSelectedData4,
	]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="container underTopMenu myLinmeContainer survey">
			<div className="contents">
				{/* todo: header 컴포넌트 */}
				<div className="surveyContainer">
					<div className="surveyContainerHeader">
						<div className="stepList">
							<div className="step">1</div>
							<div className="selectedStep">{textInfo.myLinme.survey.step.second}</div>
							<div className="step">3</div>
							<div className="step">4</div>
						</div>
						<div className="closeButton" onClick={props.handlerClickClose} />
					</div>

					<div className="defaultContainer">
						<div className="issueIcon_5" />
						<div className="surveyTitle">
							<div>{textInfo.myLinme.survey.topic.title}</div>
							<div>
								<span>{textInfo.myLinme.survey.topic.title2}</span>
								{textInfo.myLinme.survey.topic.title3}
							</div>
						</div>
						<div className="surveyAnswerContainer_5">
							{questionData.map((item, idx) => {
								return (
									<div
										className={`surveyAnswer_5 ${getIsSelected(item) ? "on" : ""}`}
										key={idx}
										onClick={() => handlerChangeData(item)}
									>
										<div className="surveyAnswerImageContainer">
											<div className="surveyAnswerImage_5">
												<Image src={item.rsvVal4} alt={item.rsvVal1} />
											</div>
											<div className="check" />
										</div>
										<div className="surveyAnswerName_5">{item.rsvVal1}</div>
									</div>
								);
							})}
						</div>
					</div>
					<div className="buttonContainer">
						{!props.myInfoData && (
							<div className="button prev" onClick={handlerClickPrev}>
								{textInfo.myLinme.survey.button.prev}
							</div>
						)}
						<div
							className={`button next ${validation() ? "" : "disable"} ${
								props.myInfoData ? "full" : ""
							}`}
							onClick={handlerClickNext}
						>
							{textInfo.myLinme.survey.button.next}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MyLinmeSurveyTopic1;
