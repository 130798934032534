// apiPaths.js

const API_PATHS = {
	// ---------------------------------------------------------------------------------------------------;
	// 개발용
	// ----------------------------------------------------------------------------------------------------;
	// 로그인
	DEV_____MEMBER_SIGN_IN: "/dev/sign-in",
	// 회원 삭제
	DEV_____MEMBER_DELETE: "/dev/member",
	// 결제 완료 상태의 더미데이터 생성
	DEV_____ORDER_SAMPLE: "/dev/order",
	// 주문건에 대한 배송완료 처리
	DEV_____ORDER_STATUS_DELIVERY_COMPLETE: "/dev/order/delivery",

	// ---------------------------------------------------------------------------------------------------;
	// 검색;
	// ----------------------------------------------------------------------------------------------------;
	// 검색;
	SEARCH: "/product/search",
	// 인기 검색어 목록;
	SEARCH_KEYWORD_POPULAR: "/product/popular-search",

	// ---------------------------------------------------------------------------------------------------;
	// 카테고리
	// ----------------------------------------------------------------------------------------------------;
	// 카테고리
	CATEGORY_LIST: "/common/category/depth",

	// ---------------------------------------------------------------------------------------------------;
	// 상품
	// ----------------------------------------------------------------------------------------------------;
	// 상품 상세
	PRODUCT_DETAIL: "/product/:productNo",
	// 상품 좋아요 설정
	PRODUCT_DETAIL_LIKE: "/product/:productNo/like",
	// 상품 재고 확인
	PRODUCT_DETAIL_CHECK_STOCK: "/product/:productNo/stock",
	// 최근 본 상품 목록
	PRODUCT_RECENT: "/product/recent",
	// 카테고리별 상품 목록
	PRODUCT_CATEGORY: "/product/category",
	// 신상품 상품 목록
	PRODUCT_NEW: "/product/new",
	// 베스트 상품 목록
	PRODUCT_BEST: "/product/best",
	// 특가상품 상품 목록
	PRODUCT_SPECIAL: "/product/special",

	// ---------------------------------------------------------------------------------------------------;
	// 리뷰;
	// ----------------------------------------------------------------------------------------------------;
	// 리뷰 목록
	PRODUCT_DETAIL_REVIEW: "/review/:productNo",
	// 포토리뷰 목록
	PRODUCT_DETAIL_REVIEW_PHOTO: "/review/:productNo/photo",
	// 로그인한 회원의 경우, 리뷰 작성 여부 및 리뷰 평균점수
	PRODUCT_DETAIL_REVIEW_CHECK_WRITE: "/review/:productNo/check",
	// 리뷰 작성
	PRODUCT_DETAIL_REVIEW_WRITE: "/review/:itemNo",
	// 리뷰 좋아요/좋아요 해제
	PRODUCT_DETAIL_REVIEW_LIKE: "/review/:reviewNo/like",
	// 리뷰 수정
	PRODUCT_DETAIL_REVIEW_MODIFY: "/review/:itemNo/:reviewNo",
	// 리뷰 삭제
	PRODUCT_DETAIL_REVIEW_DELETE: "/review/:reviewNo",
	// 리뷰 단건 조회
	PRODUCT_DETAIL_REVIEW_DETAIL: "/review/:productNo/:reviewNo",

	// ---------------------------------------------------------------------------------------------------;
	// 문의;
	// ----------------------------------------------------------------------------------------------------;
	// 상품 문의 목록
	PRODUCT_INQUIRY: "/product/:productNo/inquiry",
	// 1:1 문의 목록/작성
	INQUIRY: "/support/inquiry",
	// 1:1 문의 수정/삭제
	INQUIRY_MODIFY: "/support/inquiry/:inquiryNo",
	// 상품 문의 목록/작성
	MY_PRODUCT_INQUIRY: "/support/inquiry/product",
	// 상품 문의 수정/삭제
	MY_PRODUCT_INQUIRY_MODIFY: "/support/inquiry/product/:inquiryProductNo",

	// ---------------------------------------------------------------------------------------------------;
	// 장바구니;
	// ----------------------------------------------------------------------------------------------------;
	// 장바구니 담기 (POST) / 목록 조회 (GET)
	CART: "/cart",
	// 장바구니 금액 조회
	CART_PRICE: "/cart/price",
	// 장바구니 개수 조회
	CART_COUNT: "/cart/count",
	// 장바구니 상품별 삭제
	CART_PRODUCT: "/cart/product",

	// ---------------------------------------------------------------------------------------------------;
	// 주문;
	// ----------------------------------------------------------------------------------------------------;
	// 신규 주문 생성 (바로구매/장바구니구매), 주문 목록 조회
	ORDER: "/order",
	// 주문 정보 변경 (배송지 변경, 쿠폰 사용, 리워드 사용 등)
	ORDER_MODIFY: "/order/verify/",
	// 주문에 사용 가능한 쿠폰 조회
	ORDER_COUPON_LIST: "/order/coupon/",
	// 안심번호 서비스 가능 여부 조회
	ORDER_CHECK_VIRTUAL_NUMBER: "/order/call/check",
	// 주문 상세
	ORDER_DETAIL: "/order/:orderNo",
	// 구매확정
	ORDER_CONFIRM: "/order/confirm/:itemNo",
	// 배송 조회
	ORDER_DELIVERY_DETAIL: "/order/delivery/tracking/:itemNo",

	// ---------------------------------------------------------------------------------------------------;
	// 클레임;
	// ----------------------------------------------------------------------------------------------------;
	// 클레임 목록 조회
	CLAIM_LIST: "/claim",
	// 주문 취소
	ORDER_CANCEL: "/claim/cancel",
	// 주문 취소 시 반환 예상 금액 조회
	ORDER_CANCEL_EXPECTED_REFUND_AMOUNT: "/claim/cancel/tobe",
	// 주문 취소건 상세 조회
	ORDER_CANCEL_DETAIL: "/claim/cancel/:claimNo",
	// 주문 교환 요청
	ORDER_CHANGE_REQUEST: "/claim/exchange",
	// 주문 교환 배송비 조회
	ORDER_CHANGE_DELIVERY_PRICE: "/claim/exchange/delivery-fee",
	// 주문 교환건 상세 조회
	ORDER_CHANGE_DETAIL: "/claim/exchange/:claimProductNo",
	// 주문 교환 요청 철회
	ORDER_CHANGE_CANCEL: "/claim/exchange/cancel",
	// 주문 교환 요청 철회
	ORDER_CHANGE_COMPLETE: "/claim/exchange/complete",
	// 주문 환불 가능 상품 조회
	ORDER_REFUND_AVAILABLE: "/order/claim/refund/:itemNo",
	// 주문 환불 요청
	ORDER_REFUND_REQUEST: "/claim/refund",
	// 주문 환불 시 반환 예상 금액 조회
	ORDER_REFUND_EXPECTED_REFUND_AMOUNT: "/claim/refund/tobe",
	// 주문 환불건 상세 조회
	ORDER_REFUND_DETAIL: "/claim/refund/:claimProductNo",
	// 주문 환불 요청 철회
	ORDER_REFUND_CANCEL: "/claim/refund/cancel",
	// 클레임 사유
	CLAIM_REASON: "/claim/claim/reason",
	// 클레임 반송 정보 상세 입력
	CLAIM_DELIVERY_PICKUP_DETAIL: "/claim/return-delivery",

	// 주문 취소 가능 상품 조회
	ORDER_CANCEL_AVAILABLE: "/order/claim/cancel/:orderNo",
	// 클레임 배송비 결제 완료 여부 조회 - 결제창 팝업 관련
	CLAIM_PAYMENT_CHECK_COMPLETED: "/payment/result/claim/web/:paymentNo",

	// ---------------------------------------------------------------------------------------------------;
	// 결제;
	// ----------------------------------------------------------------------------------------------------;
	// 주문 결제
	PAYMENT: "/payment/order/",
	// 주문 결제 완료 여부 조회 - 결제창 팝업 관련
	PAYMENT_CHECK_COMPLETED: "/payment/result/web/",
	// 주문 결제 결과
	PAYMENT_RESULT: "/payment/result/",

	// ---------------------------------------------------------------------------------------------------;
	// 고민;
	// ----------------------------------------------------------------------------------------------------;
	// 고민목록
	ISSUE_LIST: "/common/issue",
	// 추천 상품 목록
	PRODUCT_LIST_ISSUE: "/product/issue",

	// ---------------------------------------------------------------------------------------------------;
	// 마이린미;
	// ----------------------------------------------------------------------------------------------------;
	// 건강상태 현황
	CONDITION: "/condition",

	// 회원 정보
	MEMBER_INFO: "/member/info",
	// 회원 정보 수정
	MEMBER_MODIFY: "/member/modify",

	// 영양제 섭취 관리(달력)
	MAIN_MONTH: "/main/month",
	// 일일 알람 내역
	MAIN_DAY: "/main/day",
	// 건강조합추천
	MAIN_PRODUCT: "/main/product",
	// 건강조합추천 더보기
	MAIN_PRODUCT_DETAIL: "/main/product/detail",

	// mbti 질문
	MBTI_QUESTION: "/mbti/question",
	// mbti 결과
	MBTI_SUMMARY: "/mbti/summary",
	// mbti 결과 상세
	MBTI_DETAIL: "/mbti/detail",

	// 심플 건강 체크 목록
	SIMPLE_CATEGORY: "/simple/category",
	// 심플 건강 체크 전송
	SIMPLE_CHECK: "/simple/check",
	// 심플 건강 상품 추천
	SIMPLE_PRODUCT: "/simple/product",
	// 심플 건강 상품 추천 더보기
	SIMPLE_PRODUCT_DETAIL: "/simple/product/detail",

	// 최초질문 (남, 여)
	SURVEY_DEFAULT: "/survey/default",
	// 질문화면 (나이)
	SURVEY_QUESTION: "/survey/question",
	// 질문화면 (BMI 후)
	SURVEY_TOPIC_INFO: "/survey/topicInfo",
	// 질문화면 (최종 질문)
	SURVEY_ANSWER: "/survey/answer",
	// 설문저장
	SURVEY_SAVE_RESULT: "/survey/result",
	// 결과 화면
	SURVEY_GET_RESULT: "/survey/existing/result",

	// 알람 리스트 조회
	ALARM_LIST: "/alarm/list",
	// 알람 등록
	ALARM_CREDIT: "/alarm/credit",
	// 알람 수정
	ALARM_MODIFY: "/alarm/modify",
	// 알람 삭제
	ALARM_DELETE: "/alarm/delete",
	// 알람 on/off
	ALARM_ONOFF: "/alarm/onOff",
	// 알람 액션 (건너뛰기 / 섭취완료)
	ALARM_ACTION: "/alarm/action",
	// 알람 상품 리스트
	ALARM_PRODUCT_LIST: "/alarm/product",
	// 구매확정 후 알람 등록
	ALARM_CONFIRM_CREDIT: "/alarm/confirm/credit",

	// ---------------------------------------------------------------------------------------------------;
	// 기획전;
	// ----------------------------------------------------------------------------------------------------;
	// 기획전 목록
	PRODUCT_LIST_TOPIC_SUMMARY: "/product/topic",
	// 기획전 - 타임특가
	PRODUCT_LIST_TOPIC_SUMMARY_TIME_SALE: "/product/topic/time-sale",
	// 기획전 내 상품 목록
	PRODUCT_LIST_TOPIC: "/product/topic/item",
	// 기획전 정보 조회 (title, subTitle, ...)
	PRODUCT_LIST_TOPIC_INFO: "/product/topic/:listNo",

	// ---------------------------------------------------------------------------------------------------;
	// 챌린지;
	// ----------------------------------------------------------------------------------------------------;
	// 챌린지 목록
	CHALLENGE: "/challenge",
	// 챌린지 상세 조회
	CHALLENGE_INFO: "/challenge/:challengeNo",
	// 챌린지 출석체크
	CHALLENGE_ATTENDANCE: "/challenge/attendance/:challengeNo",

	// ---------------------------------------------------------------------------------------------------;
	// 회원;
	// ----------------------------------------------------------------------------------------------------;
	// 로그인
	MEMBER_SIGN_IN: "/auth/sign-in",
	// 소셜로그인
	MEMBER_SIGN_IN_SOCIAL: "/auth/sign-in/social",
	// 로그아웃
	MEMBER_SIGN_OUT: "/auth/sign-out",
	// 토큰 갱신
	MEMBER_REFRESH: "/auth/refresh-token",

	// 회원가입
	MEMBER_SIGNUP: "/auth/sign-up",
	// 본인인증 요청
	MEMBER_VERIFY: "/auth/verify/request",
	// 중복 체크 - 이메일 (ID)
	MEMBER_DUPLICATE_CHECK_EMAIL: "/auth/email/exist",
	// 중복 체크 - 닉네임
	MEMBER_DUPLICATE_CHECK_NICKNAME: "/auth/nickname/exist",
	// 중복 체크 - 본인인증 ci
	MEMBER_DUPLICATE_CHECK_VERIFY_CI: "/auth/ci/exist",
	// 추가 정보 입력 (소셜 등 회원의 경우 본인인증, 닉네임 등)
	MEMBER_BASE_INFO: "/auth/sign-up/base-info",

	// 아이디 찾기
	MEMBER_FIND_ID: "/auth/email/find",
	// 회원여부 확인 (본인인증 후 회원여부 확인)
	MEMBER_FIND_BY_CI: "/auth/ci/exist",
	// 비밀번호 변경
	MEMBER_CHANGE_PW: "/auth/password",
	// 비밀번호 찾기 (본인인증 후 새 비밀번호 설정)
	MEMBER_FIND_PW: "/auth/password/find",

	// 회원 정보 조회
	MEMBER_PROFILE: "/member/profile",
	// 등급별 혜택 조회
	MEMBER_LEVEL_BENEFIT: "/support/level",
	// 회원 환불 계좌 조회
	MEMBER_REFUND_ACCOUNT: "/member/refund",
	// 회원 배송지 조회
	MEMBER_ADDRESS: "/member/address",
	// 회원 휴대폰 번호 수정
	MEMBER_MODIFY_PHONE: "/auth/phone",
	// 회원 쿠폰 목록 조회
	MEMBER_COUPON: "/member/coupon",
	// 회원 리뷰 목록 조회
	MEMBER_REVIEW: "/member/review",
	// 회원 주문/쿠폰/리뷰 수 조회
	MEMBER_STATISTICS: "/member/statistics",

	// 회원 읽지 않은 알림 존재 여부 조회
	MEMBER_NOTIFICATION_UNREAD: "/member/notification/unread",
	// 회원 알림 목록 조회
	MEMBER_NOTIFICATION: "/member/notification",
	// 회원 알림 읽음 처리
	MEMBER_NOTIFICATION_READ: "/member/notification/read",
	// 회원 알림 설정 조회
	MEMBER_NOTIFICATION_SETTING: "/member/notification/setting",

	// ---------------------------------------------------------------------------------------------------;
	// 회원 - 리워드;
	// ----------------------------------------------------------------------------------------------------;
	// 리워드 상태 조회
	MEMBER_REWARD_STATUS: "/member/reward",
	// 리워드 사용 내역 조회
	MEMBER_REWARD_HISTORY_STATUS: "/member/reward/history",

	// ---------------------------------------------------------------------------------------------------;
	// 기타;
	// ----------------------------------------------------------------------------------------------------;
	// 배너 목록
	BANNER_LIST: "/common/banner",
	VIEW_BANNER: "/banner/:itemNo/views",
	CLICK_BANNER: "/banner/:itemNo/clicks",

	// 은행 목록
	BANK_LIST: "/common/bank",
	// 택배사 목록
	DELIVERY_COMPANY: "/common/delivery",

	// 파일 업로드
	FILE_UPLOAD: "/common/file/upload",
	// 파일 삭제
	FILE_DELETE: "/common/file",

	// ---------------------------------------------------------------------------------------------------;
	// CS;
	// ----------------------------------------------------------------------------------------------------;
	// 공지사항 목록
	NOTICE: "/support/notice",
	// FAQ 카테고리 목록
	FAQ_CATEGORY: "/support/faq/category",
	// FAQ 목록
	FAQ: "/support/faq",

	// ---------------------------------------------------------------------------------------------------;
	// 약관;
	// ----------------------------------------------------------------------------------------------------;
	// 서비스 이용 약관
	UI_TERMS_SERVICE: "/term/service",
	// 개인정보 처리방침
	UI_TERMS_PRIVACY: "/term/privacy",
	// 마케팅
	UI_TERMS_MARKETING: "/term/marketing",
	// 전자결제대행 서비스 이용약관
	UI_TERMS_ELECTRONIC_PAYMENT_AGENCY_SERVICE: "/term/payment",
	// 개인정보 제3자 이용 동의
	UI_TERMS_PERSONAL_INFORMATION_TO_THIRD_PARTIES: "/term/third",
};

export default API_PATHS;
