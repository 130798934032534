/**
 * 마이린미 메인 - 건강유형 카드
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCondition } from "src/api/myLinme/main";
import useModalStack from "src/hooks/useModalStack";
import useAuth from "src/hooks/useAuth";

import HealthTypeInfo from "src/pages/component/myLinme/healthTypeInfo";
import HealthTypeDetail from "src/pages/component/myLinme/healthTypeDetail";
import MoveToDetail from "src/pages/component/myLinme/moveToDetail";
import HealthTypeTitle from "src/pages/component/myLinme/healthTypeTitle";
import InfoModify from "src/pages/component/myLinme/modal/infoModify";

import { removeSpinner, setSpinner } from "src/utils/linmeDisplay";
import { API_RESULT_STATUS } from "src/data/constEnum";
const pathList = require("src/path/pathJSON.json");
import "src/styles/myLinme/healthTypeCard.scss";

const HealthTypeCard = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const auth = useAuth();
	const modalStack = useModalStack();
	const navigate = useNavigate();
	const [data, setData] = useState({});

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	const handlerClickHealthTypeDetail = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (data?.healthType) {
			//결과 보러 가기;
			navigate(pathList.myLinme.MBTIResult);
		} else {
			//테스트 하러 가기;
			navigate(pathList.myLinme.MBTITest);
		}
	};

	/**
	 * 내 정보 수정
	 * @param {Event} e
	 */
	const handlerClickInfoModify = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await modalStack.addModal({
			id: "modalInfoModify",
			type: "confirm",
			component: <InfoModify />,
			infoData: data?.information,
			handlerClickConfirm: () => _getCondition(),
			button: {},
		});
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 건강 상태 현황 조회
	 */
	const _getCondition = async () => {
		try {
			await setSpinner();

			const result = await getCondition();

			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				setData(result?.data);
				props?.setIsFinishMBTI(!!result?.data?.healthType);
				props?.setIsFinishSurvey(!!result?.data?.information);
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		} finally {
			await removeSpinner();
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		_getCondition();
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="healthType">
			{/* 타이틀 */}
			<HealthTypeTitle auth={auth} />
			{/* 카드 */}
			<div className="healthTypeCard">
				{/* 기본 정보 */}
				<HealthTypeInfo information={data?.information} onModify={handlerClickInfoModify} />
				<HealthTypeDetail
					healthTopic={data?.healthTopic}
					nutrient={data?.nutrient}
					healthType={data?.healthType}
				/>
				{/* 건강 유형 보러가기 */}
				<MoveToDetail
					healthType={data?.healthType}
					handlerClickHealthTypeDetail={handlerClickHealthTypeDetail}
				/>
			</div>
		</div>
	);
};
export default HealthTypeCard;
