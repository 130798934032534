/**
 * SelectBox Time
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useState } from "react";
import styled from "styled-components";

import { COLOR, Z_INDEX } from "src/data/constEnum";
import { getHoursForDaySelectTime } from "src/utils/date";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

const SelectBoxCover = styled.div`
	position: relative;
	width: 242px;
	height: 30px;
`;

const Selected = styled.div`
	position: relative;
	display: block !important;
	width: calc(100% - 20px);
	height: calc(100% - 11px);
	color: ${COLOR.FONT};
	font-size: 16px;
	letter-spacing: 2px;
	background: ${COLOR.WHITE};
	cursor: pointer;
	padding: 6px 10px 5px 7px;
	&.placeholder {
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.43;
		letter-spacing: -0.28px;
		text-align: left;
		color: ${COLOR.GRAY01};
	}
	&::after {
		position: relative;
		display: block;
		width: 100%;
		height: 21px;
		font-size: 11px;
		font-weight: 500;
		line-height: 2.45;
		letter-spacing: -0.22px;
		text-align: center;
		color: ${COLOR.DARK_GRAY01};
		border: solid 0.5px ${COLOR.GRAY02};
		background-color: ${COLOR.GRAY05};
		padding-bottom: 5px;
		margin: 6px 0 0 -1px;
		content: "Time";
	}
`;

const OptionsList = styled.ul`
	position: absolute;
	display: block;
	width: calc(100%);
	height: 300px;
	overflow-y: auto;
	top: -8px;
	left: -1px;
	border-radius: 3px;
	border: solid 1px ${COLOR.GRAY03} !important;
	background-color: ${COLOR.WHITE};
	margin-top: 42px;
	z-index: ${Z_INDEX.COMMON};
	&.on {
		display: block;
	}
`;

const Option = styled.li`
	position: relative;
	display: flex;
	width: calc(100% - 6px) !important;
	height: fit-content;
	color: ${COLOR.FONT};
	cursor: pointer;
	letter-spacing: 2px;
	background-color: ${COLOR.WHITE};
	// border-bottom: solid 1px rgba( 190, 190, 190, 0.4 ) !important;
	padding: 3px;
	&:last-child {
		// border-bottom: unset !important;
	}
	&:hover {
		// border: solid 1px ${COLOR.LINME};
		background-color: ${COLOR.GRAY06};
	}
`;

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */

const SelectBoxTime = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const [isListOpen, setIsListOpen] = useState(false);
	const [label, setLabel] = useState(props.value);
	const [value, setValue] = useState(props.value);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * option 클릭 시 적용
	 * @param {Event} e
	 */
	const handlerClickOption = (e) => {
		const ct = e.currentTarget;
		// const index = ct.dataset.index;
		// if (value === ct.dataset.value) {
		// } else {
		// 	setLabel(ct.innerText);
		// 	setValue(ct.dataset.value);
		// }
		if (value !== ct.dataset.value) {
			setLabel(ct.innerText);
			setValue(ct.dataset.value);
		}
		setIsListOpen(!isListOpen);
		props.apply(ct.dataset.value);
		props.toggle(e);
	};

	/**
	 * SelectBox 클릭 시 - optionsList 열기
	 * @param {Event} e
	 */
	const handlerClickSelectBox = (e) => {
		setIsListOpen(!isListOpen);
		props.toggle(e);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 옵션노드 생성 후 반환
	 * @returns {Array} ReactNode Array
	 */
	const makeOptionList = () => {
		const d = getHoursForDaySelectTime(props.periodMinutes, true, props.timeStart, props.timeEnd);
		let result = [];
		const iLen = d.length;
		for (let i = 0; i < iLen; ++i) {
			result.push(
				<Option
					key={i}
					data-index={i}
					data-value={d[i].value}
					onClick={(e) => handlerClickOption(e)}
					style={props.optionsStyle ? props.optionsStyle : {}}
				>
					{d[i].label}
				</Option>
			);
		}
		return result;
	};

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<SelectBoxCover
			className={"selectBoxTimeCover " + props.className}
			style={props.style ? props.style : {}}
		>
			<Selected
				className={"selected " + (value ? "" : "placeholder")}
				style={props.selectedValueStyle ? props.selectedValueStyle : {}}
				onClick={(e) => handlerClickSelectBox(e)}
			>
				{label}
			</Selected>
			<OptionsList
				className={"optionsList"}
				style={props.optionsListStyle ? props.optionsListStyle : {}}
			>
				{makeOptionList()}
			</OptionsList>
		</SelectBoxCover>
	);
};

export default SelectBoxTime;
