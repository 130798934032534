/**
 * 주문/결제 - 쿠폰 카드 목록
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import CouponCard from "./couponCard";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
// const textInfo = require( "src/data/textInfo.json" );

const CouponList = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<ul className={`couponList ${!props.list || !props.list.length ? "noCoupon" : ""}`}>
			{props.list && props.list.length ? (
				props.list.map((item, index) => (
					<li
						className={
							"couponItem " +
							(item.hasOwnProperty("isCheckAvailable") && item.isCheckAvailable === false
								? "disabled"
								: "")
						}
						key={index}
					>
						<CouponCard
							{...props}
							index={index}
							content={item}
							inputRadioElementsName={props.inputRadioElementsName}
						/>
					</li>
				))
			) : (
				<li className="noData" />
			)}
		</ul>
	);
};

export default CouponList;
