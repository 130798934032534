import { useNavigate } from "react-router-dom";
import useModalStack from "src/hooks/useModalStack";
import { orderConfirm } from "src/api/myShopping/order";
import { checkOrderRefundProduct } from "src/api/myShopping/claim";
import { devMakeStatusOrderDeliveryComplete } from "src/api/dev";
import { checkOrderCancelProduct } from "src/api/myShopping/claim";
import { createOrderProduct } from "src/api/order/order";
import { checkProductStock } from "src/api/product/detail";
import { confirmCredit } from "src/api/myLinme/alarm";
import {
	ConfirmOrderCancel,
	ConfirmOrderRefund,
	SelectCancelTarget,
	ConfirmAlarm,
	ConfirmAlarmResult,
	DeliveryDetail,
} from "src/pages/component/order";
import SelectRefundTarget from "src/pages/component/order/selectRefundTarget";
import ReviewWriteConfirm from "src/pages/product/modal/reviewWriteConfirm";
import ProductModalInquiryWrite from "src/pages/component/inquiry/productInquiryWrite";
import ModalCommonAlert from "src/pages/common/modalAlert";

import { API_RESULT_STATUS, LOCAL_STORAGE_KEY, ORDER_TYPE } from "src/data";
const pathList = require("src/path/pathJSON.json");
const htmlInfo = require("src/data/htmlInfo.json");
const textInfo = require("src/data/textInfo.json");

/**
 * 주문 관련 버튼 액션
 */
const useOrderButtonActions = ({ refetchOrderData }) => {
	const modalStack = useModalStack();
	const navigate = useNavigate();

	/**
	 * 상품 재고 확인
	 * @param {Number} productNo
	 * @param {Object} options
	 * @returns
	 */
	const requestCheckProductStock = async (productNo, options) => {
		try {
			const result = await checkProductStock(productNo, options);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				const data = result.data;
				return data;
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	/**
	 * 주문서 생성
	 * @param {Number} productNo
	 * @param {Array} optionList
	 * @returns
	 */
	const requestCreateOrderProduct = async (productNo, optionList) => {
		try {
			let d = {
				type: ORDER_TYPE.PRODUCT,
				productNo,
				optionList,
			};
			const result = await createOrderProduct(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				const data = result.data;
				await navigate(pathList.payment.index, { state: { data: result.data } });
				return data;
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	/**
	 * 환불 상품 선택 모달에서 "완료" 클릭 시
	 * @param {Array} items
	 * @param {Array} itemNoList
	 * @param {Object} order
	 */
	const handlerClickConfirmSelectRefundTarget = async (items, itemNoList, order) => {
		if (items && items.length && itemNoList && itemNoList.length) {
			await navigate(pathList.claim.refund, { state: { items, itemNoList, order } });
		}
	};

	/**
	 * 환불하기 컨펌 완료 시
	 * @param {Object} item
	 * @param {Object} order
	 * @returns
	 */
	const handlerClickConfirmOrderRefund = async (item, order) => {
		await modalStack.removeModal("modalConfirmOrderRefund");

		//환불 가능 상품 정보 확인;
		const checkData = await requestCheckOrderRefundProduct(item.itemNo);
		if (!checkData) {
			return await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.noExistsAvailableRefund,
			});
		}

		await modalStack.addModal({
			id: "modalSelectRefundTarget",
			type: "confirm",
			component: <SelectRefundTarget />,
			list: checkData,
			order: order,
			handlerClickConfirm: handlerClickConfirmSelectRefundTarget,
		});

		/*/
		if( checkData.length === 1 ) {
			if( checkData[0].product.productNo === item.product.productNo
				&& checkData[0].itemNo === item.itemNo
				&& checkData[0].product.option.optionCategoryNo === item.product.option.optionCategoryNo
				&& checkData[0].product.option.optionNo === item.product.option.optionNo
				&& checkData[0].quantity === item.quantity
			) {
				await navigate( pathList.claim.refund, { state: { items: [ item ], itemNoList: [ item.itemNo ], order } });
			}
		}
		//*/
	};

	/**
	 * 섭취 알람 컨펌 완료 시
	 */
	const handlerClickConfirmAlarm = async (item) => {
		try {
			const d = {
				orderItemNo: item.itemNo,
			};

			const result = await confirmCredit(d);

			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				window.localStorage.setItem(`${LOCAL_STORAGE_KEY.USE_ALARM}`, "true");
				await modalStack.addModal({
					id: "modalConfirmAlarmResult",
					type: "confirm",
					component: <ConfirmAlarmResult />,
					handlerClickConfirm: () => navigate(pathList.myLinme.alarm),
					handlerClickClose: () => refetchOrderData(),
				});
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (err) {
			window.linmeLog.error(err);
		}
	};

	/**
	 * 배송완료 상태로 변경하기 ( 개발용 API 적용 )
	 * @param {Event} e
	 * @param {Object} item
	 * @param {Object} order
	 */
	const handlerClickChangeStatusDeliveryComplete = async (e, item, order) => {
		const result = await devMakeStatusOrderDeliveryComplete({
			invoiceNo: "0000000000",
			orderNo: order.orderNo,
		});
		if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
			await refetchOrderData();
			return result.data;
		} else {
			window.linmeLog.log(result);
			return false;
		}
	};

	/**
	 * 상품번호로 주문 환불 가능 상품 확인 (옵션별)
	 * @param {Number} itemNo
	 */
	const requestCheckOrderRefundProduct = async (itemNo) => {
		try {
			const result = await checkOrderRefundProduct(itemNo);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				return result.data;
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (error) {
			window.linmeLog.log(error);
			return false;
		}
	};

	/**
	 * 섭취 알람 컨펌 취소 시
	 */
	const handlerClickCloseAlarm = async () => {
		window.localStorage.setItem(`${LOCAL_STORAGE_KEY.USE_ALARM}`, "false");
		await refetchOrderData();
	};

	// 구매 확정 후 섭취 알람 설정 하기
	const afterOrderConfirm = async (item, isCheckAlarmAvailable) => {
		await modalStack.removeModal("modalCommonAlert");

		// 로컬스토리지에서 섭취알람 사용여부 확인;
		const useAlarm = window.localStorage.getItem(
			`${LOCAL_STORAGE_KEY.REACT_APP_LINME_KEY_USE_ALARM}`
		);

		// 로컬스토리지 및 섭취 알람 등록 가능한 상품인지 확인;
		if (useAlarm === "false" || isCheckAlarmAvailable !== true) {
			await refetchOrderData();
			return;
		}
		if (useAlarm === "true") {
			handlerClickConfirmAlarm(item);
			return;
		}
		await modalStack.addModal({
			id: "modalConfirmAlarm",
			type: "confirm",
			component: <ConfirmAlarm />,
			item: item,
			handlerClickConfirm: handlerClickConfirmAlarm,
			handlerClickClose: handlerClickCloseAlarm,
		});

		return;
	};

	/**
	 * 주문번호로 주문 취소 가능 상품 확인
	 * @param {Number} orderNo
	 */
	const requestCheckOrderCancelProduct = async (orderNo) => {
		try {
			const result = await checkOrderCancelProduct(orderNo);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				return result.data;
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (error) {
			window.linmeLog.log(error);
			return false;
		}
	};

	/**
	 * 취소하기 컨펌 완료 시
	 * @param {Object} item
	 * @param {Object} order
	 * @returns
	 */
	const handlerClickConfirmOrderCancel = async (item, order) => {
		await modalStack.removeModal("modalConfirmOrderCancel");

		//주문 취소 가능 상품 정보 확인;
		const checkData = await requestCheckOrderCancelProduct(order.orderNo);
		if (!checkData) {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.noExistsAvailableCancel,
			});
			return false;
		}

		if (checkData.length === 1) {
			if (
				checkData[0].product.productNo === item.product.productNo &&
				checkData[0].itemNo === item.itemNo &&
				checkData[0].product.option.optionNo === item.product.option.optionNo &&
				checkData[0].quantity === item.quantity
			) {
				navigate(pathList.claim.cancel, {
					state: { items: [item], itemNoList: [item.itemNo], order },
				});
			}
		} else {
			await modalStack.addModal({
				id: "modalSelectCancelTarget",
				type: "confirm",
				component: <SelectCancelTarget />,
				list: checkData,
				order: order,
				handlerClickConfirm: handlerClickConfirmSelectCancelTarget,
			});
			return false;
		}
	};

	const handlerButtonClick = {
		/**
		 * 취소하기
		 * @param {Event} e
		 * @param {Object} item
		 * @param {Object} order
		 */
		claimCancel: async (e, item, order) => {
			await modalStack.addModal({
				id: "modalConfirmOrderCancel",
				type: "confirm",
				component: <ConfirmOrderCancel />,
				item: item,
				order: order,
				handlerClickConfirm: handlerClickConfirmOrderCancel,
			});
		},

		/**
		 * 구매확정
		 * @param {Event} e
		 * @param {Object} item
		 * @param {Object} order
		 */
		orderConfirm: async (e, item, order) => {
			try {
				const result = await orderConfirm(item.itemNo);
				if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
					if (result.data) {
						const content = htmlInfo.order.rewardAccumulated;
						await modalStack.addModal({
							id: "modalCommonAlert",
							type: "alert",
							component: <ModalCommonAlert />,
							html: content.replace("___nowReward___", result.data.saveReward.toLocaleString()),
							button: {
								ok: {
									text: textInfo.button.complete,
									fn: () => afterOrderConfirm(item, result.data?.isCheckAlarmAvailable),
								},
							},
						});
					}
					return result.data;
				} else {
					window.linmeLog.log(result);
					return false;
				}
			} catch (err) {
				window.linmeLog.error(err);
			}
		},
		/**
		 * 배송조회
		 * @param {Event} e
		 * @param {Object} item
		 * @param {Object} order
		 */
		deliveryTrace: async (e, item, order) => {
			await modalStack.addModal({
				id: "modalDeliveryDetail",
				type: "alert",
				component: <DeliveryDetail />,
				item,
			});
		},
		/**
		 * 교환요청
		 * @param {Event} e
		 * @param {Object} item
		 * @param {Object} order
		 */
		claimChange: async (e, item, order) => {
			await navigate(pathList.claim.change, {
				state: { items: [item], itemNoList: [item.itemNo], order },
			});
		},
		/**
		 * 환불요청
		 * @param {Event} e
		 * @param {Object} item
		 * @param {Object} order
		 */
		claimRefund: async (e, item, order) => {
			//confirm;
			//
			await modalStack.addModal({
				id: "modalConfirmOrderRefund",
				type: "confirm",
				component: <ConfirmOrderRefund />,
				item: item,
				order: order,
				handlerClickConfirm: handlerClickConfirmOrderRefund,
			});
		},
		/**
		 * 리뷰작성
		 * @param {Event} e
		 * @param {Object} item
		 * @param {Object} order
		 */
		writeReview: async (e, item, order) => {
			modalStack.addModal({
				id: "modalReviewWriteConfirm",
				type: "confirm",
				component: <ReviewWriteConfirm />,
				item: item,
				order: order,
				product: null,
				itemNo: item.itemNo,
				redraw: refetchOrderData,
			});
		},
		/**
		 * 리뷰작성완료
		 * @param {Event} e
		 * @param {Object} item
		 * @param {Object} order
		 */
		completeReview: async (e, item, order) => {
			return false;
		},
		/**
		 * 재구매
		 * @param {Event} e
		 * @param {Object} item
		 * @param {Object} order
		 */
		repurchase: async (e, item, order) => {
			const productNo = item.product.productNo;
			const options = [
				{
					optionNo: item.product.option.optionNo,
					quantity: item.quantity,
				},
			];
			//재고 확인;
			const checkStock = await requestCheckProductStock(productNo, { options: options });
			if (checkStock && checkStock.isCheckBuy) {
				//*/
				//주문서 작성 ( 바로 구매 );
				await requestCreateOrderProduct(productNo, options);
				/*/
				//장바구니 담기;
				await requestAddToCart( productNo, options )
				.then( async (data) => {
					if( data.cartNoList && data.cartNoList.length > 0 ) {
						await modalStack.addModal({
							id: modalId.afterAddToCart
							, type: "confirm"
							, component: <ModalCommonAlert />
							, html: htmlInfo.product.afterAddToCart
							, button: {
								iconClose: true
								, confirm: {
									text: textInfo.button.move
									, style: { width: "fit-content", padding: "3px 10px" }
									, fn: handlerClickModalCheckCartConfirm
								}
								, cancel: {
									text: textInfo.button.close
									, style: { width: "fit-content", padding: "3px 10px", border: "unset" }
									, fn: handlerClickModalCheckCartClose
								}
							}
						});
					}//end if;
					if( data.hasOwnProperty( "count" ) ) auth.modifyUserData({ cartCount: data.count });
				});
				//*/
			}
		},
		/**
		 * 문의하기
		 * @param {Event} e
		 * @param {Object} item
		 * @param {Object} order
		 */
		inquiry: async (e, item, order) => {
			await modalStack.addModal({
				id: "modalInquiryWrite",
				type: "confirm",
				component: <ProductModalInquiryWrite />,
				item: item,
				order: order,
				button: {
					iconClose: true,
					confirm: {
						text: textInfo.button.complete,
					},
					cancel: {
						text: textInfo.button.cancel,
					},
				},
			});
		},
	};

	/**
	 * 취소 상품 선택 모달에서 "완료" 클릭 시
	 * @param {Array} items
	 * @param {Array} itemNoList
	 * @param {Object} order
	 */
	const handlerClickConfirmSelectCancelTarget = async (items, itemNoList, order) => {
		if (items && items.length && itemNoList && itemNoList.length) {
			navigate(pathList.claim.cancel, { state: { items, itemNoList, order } });
		}
	};

	/**
	 * 버튼별 핸들러 설정
	 * @param {String} type
	 * @returns
	 */
	const setHandlerForButton = (type) => {
		switch (type) {
			case "claimCancel":
				return handlerButtonClick.claimCancel;
			case "orderConfirm":
				return handlerButtonClick.orderConfirm;
			// break;
			case "deliveryTrace":
				return handlerButtonClick.deliveryTrace;
			// break;
			case "claimChange":
				return handlerButtonClick.claimChange;
			// break;
			case "claimRefund":
				return handlerButtonClick.claimRefund;
			// break;
			case "writeReview":
				return handlerButtonClick.writeReview;
			case "completeReview":
				return handlerButtonClick.completeReview;
			// break;
			case "repurchase":
				return handlerButtonClick.repurchase;
			// break;
			case "inquiry":
				return handlerButtonClick.inquiry;
			// break;
			default:
				return null;
			// break;
		}
	};

	return {
		setHandlerForButton,
		handlerClickChangeStatusDeliveryComplete,
	};
};

export default useOrderButtonActions;
