/**
 * 챌린지
 */

import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/**
 * 챌린지 목록 조회
 * @param {Object} d
 * <code>
 * {
 * 		offset: 0
 * 		, page: 0
 * 		, status: {String} //IN: 진행중, FINISH: 종료된;
 * }
 * </code>
 * @returns
 */
export const getChallenge = async (d) => {
	const url = `${API_PATHS.CHALLENGE}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 챌린지 상세 조회
 * @param {String} challengeNo
 * @returns
 */
export const getChallengeInfo = async (challengeNo) => {
	const url = `${API_PATHS.CHALLENGE_INFO}`;
	try {
		const result = await api.get(url.replace(":challengeNo", challengeNo));
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 챌린지 출석체크 이력 조회
 * @param {String} challengeNo
 * @returns
 */
export const getChallengeAttendance = async (challengeNo) => {
	const url = `${API_PATHS.CHALLENGE_ATTENDANCE}`;
	try {
		const result = await api.get(url.replace(":challengeNo", challengeNo));
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 챌린지 출석체크
 * @param {String} challengeNo
 * @returns
 */
export const postChallengeAttendance = async (challengeNo) => {
	const url = `${API_PATHS.CHALLENGE_ATTENDANCE}`;
	try {
		const result = await api.post(url.replace(":challengeNo", challengeNo));
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
