/**
 * 마이린미 - 건강상태 설문 결과
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useAuth from "src/hooks/useAuth";
import { API_RESULT_STATUS } from "src/data/constEnum";
import { getSurveyResult } from "src/api/myLinme/survey";
import { removeSpinner, setSpinner } from "src/utils/linmeDisplay";

import Image from "src/pages/component/common/image";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myLinme/healthCheckResult.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const MyLinmeSurveyResult = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const navigate = useNavigate();
	const auth = useAuth();

	const [resultData, setResultData] = useState({});
	const [firstHalf, setFirstHalf] = useState([]);
	const [secondHalf, setSecondHalf] = useState([]);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 다시 분석하기 클릭 시
	 * @param {Event} e
	 */
	const handlerTestAgain = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		navigate(pathList.myLinme.survey);
	};

	/**
	 * 린미 홈으로 돌아가기 클릭 시
	 * @param {Event} e
	 */
	const handlerMoveToHome = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		navigate(pathList.myLinme.index);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 결과 가져오기 API
	 */
	const _getSurveyResult = async () => {
		try {
			await setSpinner();

			const result = await getSurveyResult();

			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				const half = Math.ceil(result?.data?.result?.length / 2);
				const firstHalf1 = result?.data?.result?.slice(0, half);
				const secondHalf1 = result?.data?.result?.slice(half, result?.data?.result?.length);

				setResultData(result?.data);
				setFirstHalf(firstHalf1);
				setSecondHalf(secondHalf1);
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		} finally {
			await removeSpinner();
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		_getSurveyResult();
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="container underTopMenu myLinmeContainer healthCheckResult">
			<div className="contents">
				<div className="cardContainer">
					<div className="infoCard">
						{resultData ? (
							<>
								<div>
									<span>{auth?.user?.nickname || textInfo.healthIssue.notLoginMember}</span>
									{textInfo.myLinme.survey.result.title}
								</div>
								<div>{resultData?.information?.memberInfo}</div>
								<div className="tagContainer">
									{resultData?.information?.info?.map((item, index) => {
										return (
											<div className="tag" key={index}>
												{item}
											</div>
										);
									})}
								</div>
							</>
						) : (
							<>
								<div className="infoCardNoData">
									{auth?.user?.nickname || textInfo.healthIssue.notLoginMember}
									{textInfo.myLinme.survey.result.noData1}
								</div>
							</>
						)}
					</div>
					<div className="buttonContainer desktop">
						{resultData && (
							<div className="testAgain" onClick={handlerTestAgain}>
								<div className="testAgainIcon" />
								{textInfo.myLinme.survey.result.testAgain}
							</div>
						)}
						<div className="moveToHome" onClick={handlerMoveToHome}>
							{textInfo.myLinme.survey.result.moveToHome}
						</div>
					</div>
				</div>

				<div className="resultBoxContainer">
					<div className="resultItem">
						{firstHalf?.map((item, index) => (
							<div key={index} className="resultBox">
								<div className="resultTitle">
									<Image src={item?.imagePath} alt={item?.title} />
									{item?.title}
								</div>
								<div className="resultValue">{item?.content}</div>
							</div>
						))}
					</div>
					<div className="resultItem">
						{secondHalf?.map((item, index) => (
							<div key={index} className="resultBox">
								<div className="resultTitle">
									<Image src={item?.imagePath} alt={item?.title} />
									{item?.title}
								</div>
								<div className="resultValue">{item?.content}</div>
							</div>
						))}
					</div>
				</div>
				<div className="buttonContainer mobile">
					{resultData && (
						<div className="testAgain" onClick={handlerTestAgain}>
							<div className="testAgainIcon" />
							{textInfo.myLinme.survey.result.testAgain}
						</div>
					)}
					<div className="moveToHome" onClick={handlerMoveToHome}>
						{textInfo.myLinme.survey.result.moveToHome}
					</div>
				</div>
			</div>
		</div>
	);
};

export default MyLinmeSurveyResult;
