import { SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper/modules";
import Swiper from "src/pages/component/swiper";
import ProductItem from "src/pages/component/product/productItem";

import useDeviceType from "src/hooks/useDeviceType";
import { DEVICE_TYPE, TOPIC_LIMIT } from "src/data";

const TopicProductList = (props) => {
	const { topicProductList } = props;
	const { mobileType } = useDeviceType();
	const topicPeriodLimit = [DEVICE_TYPE.PC, DEVICE_TYPE.TABLET].includes(mobileType)
		? TOPIC_LIMIT.PC
		: TOPIC_LIMIT.NOT_PC;

	return (
		<Swiper
			modules={[FreeMode]}
			slidesPerView={topicPeriodLimit}
			freeMode={true}
			spaceBetween={30}
			grabCursor={true}
		>
			{topicProductList?.map((product) => {
				return (
					<SwiperSlide key={product.itemNo}>
						<ProductItem product={product.product} />
					</SwiperSlide>
				);
			})}
		</Swiper>
	);
};

export default TopicProductList;
