/**
 * 장바구니 - 상품 카드
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { setProductQuantityFromCart } from "src/api/order/cart";
import { createOrderProductFromCart } from "src/api/order/order";
import {
	API_RESULT_STATUS,
	COLOR,
	IMAGE_ERROR_SRC,
	ORDER_TYPE,
	PRODUCT_OPTIONS_TYPE,
	PRODUCT_STATUS,
} from "src/data/constEnum";
import useModalStack from "src/hooks/useModalStack";
import { copyCollection } from "src/utils/arrayCollection";
import { removeInnerSpinner, setInnerSpinner } from "src/utils/linmeDisplay";

import ModalCommonAlert from "src/pages/common/modalAlert";
import Image from "src/pages/component/common/image";
import CustomNumberStepper from "src/pages/component/common/customNumberStepper";
import ProductOptionsEdit from "src/pages/component/order/productOptionsEdit";
import { getProductDetailType } from "src/utils/linmeSearch";
import clsx from "clsx";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const CartProductCard = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();
	const navigate = useNavigate();
	const ref = {
		select: useRef(null),
		price: useRef(null),
		salePrice: useRef(null),
		deliveryPrice: useRef(null),
	};

	const [soldout, setSoldout] = useState(false);
	const [saleEnd, setSaleEnd] = useState(false);
	const [isSingleOption, setIsSingleOption] = useState(true); // 옵션 설정 여부
	const [price, setPrice] = useState(0);
	const [salePrice, setSalePrice] = useState(0);
	const [optionRequiredInfo, setOptionRequiredInfo] = useState([]);
	const [optionAdditionalInfo, setOptionAdditionalInfo] = useState([]);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 옵션없는 상품의 수량 변경 API
	 * @param {Object} target
	 * @param {Number} count
	 * @param {Number} targetIndex
	 */
	const handlerChangeOptionInfo = async (count) => {
		const o = optionRequiredInfo[0];

		if (count === 0 || count === o?.quantity) return false;

		try {
			await setInnerSpinner();

			await requestSetProductCount(o?.cartNo, count).then(async (data) => {
				await props.afterOptionChanged(data);
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.changedProductCount,
				});
			});
		} catch (error) {
			window.linmeLog.log(error);
		} finally {
			await removeInnerSpinner();
		}
	};

	/**
	 * 상품 삭제 클릭 시
	 * @param {Event} e
	 */
	const handlerClickDelete = async (e) => {
		//버블링 막기;
		e?.preventDefault();
		e?.stopPropagation();

		await modalStack.addModal({
			id: "modalCommonConfirm",
			type: "confirm",
			component: <ModalCommonAlert />,
			text: textInfo.alert.areYouWantToDeleteSelectedProduct,
			button: {
				iconClose: false,
				confirm: {
					text: textInfo.button.confirm,
					fn: handlerClickModalDeleteProduct,
					style: {
						width: "120px",
						border: "1px solid " + COLOR.LINME,
						marginLeft: "10px",
					},
				},
				cancel: {
					text: textInfo.button.cancel,
					style: {
						width: "120px",
					},
				},
			},
		});
	};

	/**
	 * 삭제 가능여부 관련 모달에서 확인 클릭 시
	 * @param {Event} e
	 */
	const handlerClickModalDeleteProduct = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.removeModal("modalCommonConfirm");
		props?.requestDeleteProductFromCart([props.productNo]);
	};

	/**
	 * 주문하기 ( 바로 구매 ) 클릭 시
	 * @param {Event} e
	 */
	const handlerClickOrderProduct = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		//데이터 설정;
		let cartNoList = [];
		if (optionRequiredInfo && optionRequiredInfo.length) {
			for (let i = 0; i < optionRequiredInfo.length; ++i) {
				cartNoList.push(optionRequiredInfo[i].cartNo);
			}
		}
		if (optionAdditionalInfo && optionAdditionalInfo.length) {
			for (let i = 0; i < optionAdditionalInfo.length; ++i) {
				cartNoList.push(optionAdditionalInfo[i].cartNo);
			}
		}

		//주문서 생성 요청;
		await requestOrder(cartNoList);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 옵션별 상품 수량 변경 요청 API
	 * @param {Number} cartNo
	 * @param {Number} count
	 * @returns
	 */
	const requestSetProductCount = async (cartNo, count) => {
		try {
			const d = {
				cartNo: cartNo,
				quantity: count,
			};

			const result = await setProductQuantityFromCart(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				const data = result.data;
				return data;
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	/**
	 * 상품 옵션 포함 총금액 계산
	 * @returns {Number}
	 */
	const calcTotalPrice = async (dRequired, dAdditional) => {
		if ((!dRequired || !dRequired?.length) && (!dAdditional || !dAdditional?.length)) {
			dRequired = await copyCollection(props.required);
			await setOptionRequiredInfo(dRequired);
			dAdditional = await copyCollection(props.additional);
			await setOptionAdditionalInfo(dAdditional);
		} else {
			if (dRequired) await setOptionRequiredInfo(dRequired);
			if (dAdditional) await setOptionAdditionalInfo(dAdditional);
		}
		let price = 0;
		let salePrice = 0;
		// let deliveryPrice = 0;
		let io = null;

		if (
			(dAdditional && dAdditional?.length) ||
			dRequired?.length > 1 ||
			dRequired?.[0]?.optionCategoryName ||
			dRequired?.[0]?.optionName
		) {
			//if( withOption ) {
			if (dRequired && dRequired.length) {
				for (let i = 0; i < dRequired.length; ++i) {
					io = dRequired[i];
					price += (props.price + io.addPrice) * io.quantity;
					salePrice += (props.salePrice + io.addPrice) * io.quantity;
					// deliveryPrice += io.deliveryPrice * io.quantity;
				}
			}
			if (dAdditional && dAdditional.length) {
				for (let i = 0; i < dAdditional.length; ++i) {
					io = dAdditional[i];
					price += io.addPrice * io.quantity;
					salePrice += io.addPrice * io.quantity;
					// deliveryPrice += io.deliveryPrice * io.quantity;
				}
			}
		} else {
			if (dRequired && dRequired.length) {
				io = dRequired[0];
				price = (props.price + io.addPrice) * io.quantity;
				salePrice = (props.salePrice + io.addPrice) * io.quantity;
			}
		}

		ref.price.current.innerText = price.toLocaleString();
		ref.salePrice.current.innerText = salePrice.toLocaleString();

		await setPrice(price);
		await setSalePrice(salePrice);
	};

	/**
	 * 판매 종료, SOLDOUT 여부 등 확인
	 * @returns {Boolean}
	 */
	const checkStatus = () => {
		//SOLDOUT;
		if (props.status === PRODUCT_STATUS.SOLDOUT) setSoldout(true);

		//판매 종료;
		if (props.status === PRODUCT_STATUS.DEAD) setSaleEnd(true);
	};

	/**
	 * 주문서 생성
	 * @param {Array} cartNoList
	 * @returns
	 */
	const requestOrder = async (cartNoList) => {
		try {
			let d = {
				type: ORDER_TYPE.CART,
				cartNoList: cartNoList,
			};

			const result = await createOrderProductFromCart(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				navigate(pathList.payment.index, { state: { data: result.data } });
				return result.data;
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	/**
	 * validation - 옵션 삭제
	 * 필수옵션이 1개만 존재하며 선택옵션을 포함하고 있는 경우 - 필수옵션 삭제 시 선택옵션 전부 삭제;
	 * @param {Object} option
	 */
	const validationDeleteOption = async (option) => {
		if (option.type === PRODUCT_OPTIONS_TYPE.REQUIRED) {
			if (!isSingleOption && props.require?.length === 1 && props.additional.length > 0) {
				await handlerClickDelete();
				return false;
			}
		}
		return true;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		const setData = async () => {
			// 옵션 미설정 여부
			if (props)
				setIsSingleOption(
					!props?.required?.[0]?.categoryName1 && !props?.required?.[0]?.optionName1
				);

			await setOptionRequiredInfo(props.required);
			await setOptionAdditionalInfo(props.additional);

			await calcTotalPrice();
			await checkStatus();
		};
		setData();
	}, [props]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<>
			<div className={clsx(["productData", "pc", { soldout: soldout }, { saleEnd: saleEnd }])}>
				<div className="dataEach select">
					<input
						className="itemSelection"
						name="itemSelection"
						id={"itemSelection" + props.productNo}
						ref={ref.select}
						type="checkbox"
						value={props.productNo}
						data-index={props.index}
						disabled={soldout || saleEnd ? true : false}
						checked={props.checkedProduct.indexOf(props.productNo) > -1 ? true : false}
						onChange={props.handlerChangeSelected}
					/>
					<label htmlFor={"itemSelection" + props.productNo}></label>
				</div>
				<div className="dataEach product">
					<Link
						to={pathList.product.detail + `${getProductDetailType(props?.type)}/` + props.productNo}
					>
						<div className="productImageCover">
							{props.thumbnail && props.thumbnail.webPath ? (
								<Image src={props.thumbnail.webPath} alt={props.name} type="large" />
							) : (
								<Image src={IMAGE_ERROR_SRC.large} alt={props.name} type="large" />
							)}
						</div>
					</Link>
					<div className="productInfo">
						{/* 브랜드명 */}
						<div className="productName">
							<Link
								to={
									pathList?.product?.detail +
									`${getProductDetailType(props?.type)}/` +
									props?.productNo
								}
							>
								{props?.brand?.name}
							</Link>
						</div>
						{/* 상품명 */}
						<div className="productName">
							<Link
								to={
									pathList?.product?.detail +
									`${getProductDetailType(props?.type)}/` +
									props?.productNo
								}
							>
								{props.name}
							</Link>
						</div>
						{/* 옵션 미설정 */}
						{isSingleOption === true ? (
							<div className="productNoOption">
								<CustomNumberStepper
									min={1}
									max={9999}
									count={optionRequiredInfo?.[0]?.quantity}
									setCount={handlerChangeOptionInfo}
								/>
							</div>
						) : (
							<>
								{/* 필수옵션 */}
								{optionRequiredInfo?.length > 0 && (
									<>
										<div className="productOptionType">
											{textInfo.order.optionType[PRODUCT_OPTIONS_TYPE.REQUIRED.toLowerCase()]}
										</div>
										<ProductOptionsEdit
											list={optionRequiredInfo}
											type={PRODUCT_OPTIONS_TYPE.REQUIRED}
											afterChange={props.afterOptionChanged}
											validationDeleteOption={validationDeleteOption}
											requestDeleteOptionFromCart={props.requestDeleteOptionFromCart}
										/>
									</>
								)}
								{/* 선택옵션 */}
								{optionAdditionalInfo?.length > 0 && (
									<>
										<div className="productOptionType">
											{textInfo.order.optionType[PRODUCT_OPTIONS_TYPE.ADDITIONAL.toLowerCase()]}
										</div>
										<ProductOptionsEdit
											list={optionAdditionalInfo}
											type={PRODUCT_OPTIONS_TYPE.ADDITIONAL}
											afterChange={props.afterOptionChanged}
											validationDeleteOption={validationDeleteOption}
											requestDeleteOptionFromCart={props.requestDeleteOptionFromCart}
										/>
									</>
								)}
							</>
						)}
					</div>
				</div>
				<div className="dataEach priceInfo">
					<div className="salePrice">
						<span ref={ref.salePrice}>{salePrice.toLocaleString()}</span>
						<span>{textInfo.common.currencyUnit}</span>
					</div>
					<div className={"price " + (salePrice !== price ? "on" : "")}>
						<span ref={ref.price}>{price.toLocaleString()}</span>
						<span>{textInfo.common.currencyUnit}</span>
					</div>
					{!soldout && !saleEnd && (
						<div
							className="button clickable orderProduct"
							onClick={(e) => {
								handlerClickOrderProduct(e);
							}}
						>
							{textInfo.button.order}
						</div>
					)}
				</div>
				<div className="dataEach deliveryPriceInfo">
					<div className={`deliveryPrice ${props?.isFirstProduct ? "" : "gray"}`}>
						<span ref={ref.deliveryPrice}>{props.deliveryPrice.toLocaleString()}</span>
						<span>{textInfo.common.currencyUnit}</span>
					</div>
					{/* <div className="description">5만원 이상 구매 시 배송비 무료</div> */}
				</div>
				<div className="dataEach delete">
					<div
						className="button clickable deleteFromCart"
						onClick={(e) => {
							handlerClickDelete(e);
						}}
					></div>
				</div>
			</div>
			{/* 모바일 */}
			<div className={clsx(["productData", "mobile", { soldout: soldout }, { saleEnd: saleEnd }])}>
				<div className="productHeader">
					<div className="productHeaderLeft">
						<div className="select">
							<input
								className="itemSelection"
								name="itemSelection"
								id={"itemSelection" + props.productNo}
								ref={ref.select}
								type="checkbox"
								value={props.productNo}
								data-index={props.index}
								disabled={soldout || saleEnd ? true : false}
								checked={props.checkedProduct.indexOf(props.productNo) > -1 ? true : false}
								onChange={props.handlerChangeSelected}
							/>
							<label htmlFor={"itemSelection" + props.productNo}></label>
						</div>
						{!soldout && !saleEnd && (
							<div
								className="button clickable orderProduct"
								onClick={(e) => {
									handlerClickOrderProduct(e);
								}}
							>
								{textInfo.button.order}
							</div>
						)}
					</div>
					<div className="dataEach delete">
						<div
							className="button clickable deleteFromCart"
							onClick={(e) => {
								handlerClickDelete(e);
							}}
						></div>
					</div>
				</div>

				<div className="productInfoContainer">
					<div className="productImageCover">
						<Link
							to={
								pathList.product.detail + `${getProductDetailType(props?.type)}/` + props.productNo
							}
						>
							{props.thumbnail && props.thumbnail.webPath ? (
								<Image src={props.thumbnail.webPath} alt={props.name} type="large" />
							) : (
								<Image src={IMAGE_ERROR_SRC.large} alt={props.name} type="large" />
							)}
						</Link>
					</div>
					<div className="productInfo">
						<div className="productNameContainer">
							<div className="productName">
								<Link
									to={
										pathList?.product?.detail +
										`${getProductDetailType(props?.type)}/` +
										props?.productNo
									}
								>
									{props?.brand?.name}
								</Link>
							</div>
							<div className="productName">
								<Link
									to={
										pathList?.product?.detail +
										`${getProductDetailType(props?.type)}/` +
										props?.productNo
									}
								>
									{props.name}
								</Link>
							</div>
						</div>

						<div className="priceInfo">
							<div className="salePrice">
								<span ref={ref.salePrice}>{salePrice.toLocaleString()}</span>
								<span>{textInfo.common.currencyUnit}</span>
							</div>
							<div className={`deliveryPrice ${props?.isFirstProduct ? "" : "gray"}`}>
								<span ref={ref.deliveryPrice}>배송비 {props.deliveryPrice.toLocaleString()}</span>
								<span>{textInfo.common.currencyUnit}</span>
							</div>
						</div>
					</div>
				</div>

				<div className="optionContainer">
					{isSingleOption === true ? (
						<div className="productNoOption">
							<CustomNumberStepper
								min={1}
								max={9999}
								count={optionRequiredInfo?.[0]?.quantity}
								setCount={handlerChangeOptionInfo}
							/>
						</div>
					) : (
						<>
							{/* 필수옵션 */}
							{optionRequiredInfo?.length > 0 && (
								<>
									<div className="productOptionType">
										{textInfo.order.optionType[PRODUCT_OPTIONS_TYPE.REQUIRED.toLowerCase()]}
									</div>
									<ProductOptionsEdit
										list={optionRequiredInfo}
										type={PRODUCT_OPTIONS_TYPE.REQUIRED}
										afterChange={props.afterOptionChanged}
										validationDeleteOption={validationDeleteOption}
										requestDeleteOptionFromCart={props.requestDeleteOptionFromCart}
									/>
								</>
							)}
							{/* 선택옵션 */}
							{optionAdditionalInfo?.length > 0 && (
								<>
									<div className="productOptionType">
										{textInfo.order.optionType[PRODUCT_OPTIONS_TYPE.ADDITIONAL.toLowerCase()]}
									</div>
									<ProductOptionsEdit
										list={optionAdditionalInfo}
										type={PRODUCT_OPTIONS_TYPE.ADDITIONAL}
										afterChange={props.afterOptionChanged}
										validationDeleteOption={validationDeleteOption}
										requestDeleteOptionFromCart={props.requestDeleteOptionFromCart}
									/>
								</>
							)}
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default CartProductCard;
