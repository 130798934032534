/**
 * 상품 목록 정렬
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useState } from "react";

import { PRODUCT_LIST_ORDER } from "../../../data/constEnum";

/* ---------------------------------------------------------------------------------------------------- */
/* import styles */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */

const ProductListOrder = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const [listOrderSelected, setListOrderSelected] = useState(
		props.selected || PRODUCT_LIST_ORDER[0].value
	);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 정렬 선택 시
	 * @param {Event} e
	 */
	const handlerClickListOrder = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const t = e.target;
		const value = t.dataset.value;
		if (value && props.afterSelected) {
			await setListOrderSelected(value);
			await props.afterSelected(value);
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<ul className="productDisplayOrder" onClick={(e) => handlerClickListOrder(e)}>
			{PRODUCT_LIST_ORDER.map((item, index) => (
				<li className={listOrderSelected === item.value ? "on" : ""} key={item.value}>
					<span className="clickable" data-value={item.value}>
						{item.label}
					</span>
				</li>
			))}
		</ul>
	);
};

export default ProductListOrder;
