/**
 * 상품 상세 관련
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */
import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/* ---------------------------------------------------------------------------------------------------- */
/* FUNCTION */
/* ---------------------------------------------------------------------------------------------------- */

/**
 * 상품 재고 확인
 * @param {Number} productNo//상품번호;
 * @param {Object} d
 * <code>
 * {
 * 		options: [
 * 			{
 * 				optionsCategoryNo: 0
 * 				, optionsNo: 0
 * 				, quantity: 0
 * 			}
 * 			, {
 * 				optionsCategoryNo: 0
 * 				, optionsNo: 0
 * 				, quantity: 0
 * 			}
 * 			, ...
 * 		]
 * }
 * </code>
 * @returns
 */
export const checkProductStock = async (productNo, d) => {
	const url = `${API_PATHS.PRODUCT_DETAIL_CHECK_STOCK}`;
	try {
		const result = await api.post(url.replace(":productNo", productNo), d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 상품 상세
 * @param {Number} productNo//상품번호;
 * @returns
 */
export const getProductDetail = async (productNo) => {
	const url = `${API_PATHS.PRODUCT_DETAIL}`;
	try {
		const result = await api.get(url.replace(":productNo", productNo));
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 상품 포토 리뷰 조회 ( 작성일 최신순 )
 * @param {Object} d
 * <code>
 * {
 * 		page: 1
 * 		, offset: 1
 * 		, productNo: 1
 * }
 * </code>
 * @returns
 */
export const getProductPhotoReviewList = async (d) => {
	const url = `${API_PATHS.PRODUCT_DETAIL_REVIEW_PHOTO}`;
	try {
		const result = await api.get(url.replace(":productNo", d.productNo), { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 로그인한 회원의 경우, 리뷰 작성여부 및 리뷰 평균점수 조회
 * @param {Number} productNo
 * @returns
 */
export const getProductReviewCheckWrite = async (productNo) => {
	const url = `${API_PATHS.PRODUCT_DETAIL_REVIEW_CHECK_WRITE}`;
	try {
		const result = await api.get(url.replace(":productNo", productNo));
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 상품 리뷰 조회
 * @param {Object} d
 * <code>
 * {
 * 		page: 1
 * 		, offset: 1
 * 		, productNo: 1
 * 		, sort: ""//BEST:인기순, FAST:최근순
 * }
 * </code>
 * @returns
 */
export const getProductReviewList = async (d) => {
	const url = `${API_PATHS.PRODUCT_DETAIL_REVIEW}`;
	try {
		const result = await api.get(url.replace(":productNo", d.productNo), { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 상품 좋아요/좋아요 취소
 * @param {Number} productNo//상품번호;
 * @returns
 */
export const setProductLike = async (productNo) => {
	const url = `${API_PATHS.PRODUCT_DETAIL_LIKE}`;
	try {
		const result = await api.post(url.replace(":productNo", productNo));
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
