/**
 * 알림 관련 유틸
 */

import { API_RESULT_STATUS, NOTIFICATION_PAGE_MOVE } from "src/data/constEnum";
import { productTopicInfo } from "src/api/product/topic";
import { getProductDetailType } from "src/utils/linmeSearch";

const pathList = require("src/path/pathJSON.json");

/**
 * 알림 내용에 따른 페이지 이동 관련 오브젝트 반환
 * @param {Object} item
 * @returns {Object|Null}
 */
export const getPathInfoFromNotification = async (item) => {
	let result = {};

	const host = item.landing.host;
	const id = item.landing.id;
	const type = item?.landing?.type;

	switch (host) {
		case NOTIFICATION_PAGE_MOVE.ORDER_LIST: //주문내역;
			result.path = pathList.myPage.shoppingOrder;
			break;
		case NOTIFICATION_PAGE_MOVE.ORDER_DETAIL: //주문상세;
			result.path = pathList.myPage.shoppingOrder;
			result.state = {
				orderNo: id,
			};
			break;
		case NOTIFICATION_PAGE_MOVE.CANCEL_DETAIL: //취소상세;
			result.path = pathList.myPage.shoppingClaim;
			result.state = {
				orderNo: id,
				type: "cancel",
			};
			break;
		case NOTIFICATION_PAGE_MOVE.CHANGE_DETAIL: //교환상세;
			result.path = pathList.myPage.shoppingClaim;
			result.state = {
				claimProductNo: id,
				type: "change",
			};
			break;
		case NOTIFICATION_PAGE_MOVE.REFUND_DETAIL: //환불상세;
			result.path = pathList.myPage.shoppingClaim;
			result.state = {
				claimProductNo: id,
				type: "refund",
			};
			break;
		case NOTIFICATION_PAGE_MOVE.PRODUCT_DETAIL: //상품 상세;
			result.path = pathList.product.detail + `${getProductDetailType(type)}/` + id;
			break;
		case NOTIFICATION_PAGE_MOVE.INQUIRE: //문의;
			result.path = pathList.myPage.inquiry;
			break;
		case NOTIFICATION_PAGE_MOVE.TOPIC: //기획전 상품목록;
			try {
				const topicDetail = await productTopicInfo(id);
				if (topicDetail.status === API_RESULT_STATUS.SUCCESS) {
					if (topicDetail.data) {
						result.state = {
							listNo: id,
							title: topicDetail.data.title,
							subTitle: topicDetail.data.subTitle,
							image:
								topicDetail.data.image && topicDetail.data.image.webPath
									? topicDetail.data.image.webPath
									: null,
						};
						if (topicDetail.data.isCheckTimeSale) {
							//타임특가;
							result.path = pathList.product.topicTimer;
						} else {
							//일반 기획전;
							result.path = pathList.product.topicPeriod;
						}
					} else {
						result = null;
					}
				} else {
					result = null;
				}
			} catch (err) {
				window.linmeLog.error(err);
				result = null;
			}
			break;
		case NOTIFICATION_PAGE_MOVE.NOTICE: //공지사항;
			result.path = pathList.myPage.notice;
			result.state = {
				noticeNo: id,
			};
			break;

		case NOTIFICATION_PAGE_MOVE.INTAKE_ALARM: //이벤트;
			result.path = pathList.myLinme.alarm;
			result.state = {
				id: id,
			};
			break;
		default:
			result = null;
			break;
	}

	return result;
};
