/**
 * 상품 목록 - 무한스크롤
 */

import { useEffect, useState } from "react";

import useInfiniteScroll from "src/hooks/useInfiniteScroll";

import { PRODUCT_LIST_ORDER } from "src/data/constEnum";

import ProductListOrder from "src/pages/component/product/productListOrder";
import ProductList from "src/pages/component/product/productList";
import InfiniteTrigger from "src/pages/component/infiniteTrigger";

const ProductListInfinityScroll = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const [list, setList] = useState([]);
	const [listOrderSelected, setListOrderSelected] = useState(
		props?.param?.sort || PRODUCT_LIST_ORDER[0].value
	);

	// totalCount 를 가져오기 위한 함수
	const getDataList = async (params) => {
		const result = await props.getDataList(params);
		if (props.setResultTotalCount) props.setResultTotalCount(result?.data?.totalCount);
		return result;
	};

	const infiniteScroll = useInfiniteScroll({
		fetchFn: getDataList,
		param: { ...props.param, sort: listOrderSelected },
		setDataList: setList,
	});

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		infiniteScroll.setInitData(false);
	}, [props.param, props.getDataList, listOrderSelected]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="productDisplayContainer">
			<ProductListOrder
				{...{
					selected: listOrderSelected,
					afterSelected: setListOrderSelected,
				}}
			/>
			<div className="productListContainer">
				{list && list.length ? (
					<>
						<ProductList {...{ items: list }} />
						<InfiniteTrigger ref={infiniteScroll.triggerRef} />
					</>
				) : (
					<div className="noData"></div>
				)}
			</div>
		</div>
	);
};

export default ProductListInfinityScroll;
