import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import useAuth from "src/hooks/useAuth";
import { getRecentProductList } from "src/api/product/recent";
import { getProductDetailType } from "src/utils/linmeSearch";
import { PAGINATION_DEFAULT_OFFSET, API_RESULT_STATUS } from "src/data/constEnum";
import { queryKeys } from "src/data";
const pathList = require("src/path/pathJSON.json");

/**
 * 최근 본 상품 목록 조회
 */
const requestGetRecentProductList = async (pageNo) => {
	try {
		const d = {
			offset: PAGINATION_DEFAULT_OFFSET.RECENT_PRODUCT,
			page: pageNo,
		};
		const result = await getRecentProductList(d);
		if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
			return result.data.content;
		} else {
			window.linmeLog.log(result);
		}
	} catch (error) {
		window.linmeLog.log(error);
	}
};

/** 최근 본 상품 목록 조회 및 로직 훅 */
const useRecentlyViewed = () => {
	const auth = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const [pageNo, setPageNo] = useState(1);
	const { data, isLoading } = useQuery({
		queryKey: [queryKeys.RECENTLY_VIEWED, pageNo, location.pathname],
		queryFn: () => requestGetRecentProductList(pageNo),
		enabled: auth.isLogin,
		refetchOnMount: false,
	});

	/**
	 * 이전 페이지 클릭 시
	 * @param {Event} e
	 */
	const handlerClickPrev = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();
		setPageNo(pageNo - 1);
	};

	/**
	 * 다음 페이지 클릭 시
	 * @param {Event} e
	 */
	const handlerClickNext = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();
		setPageNo(pageNo + 1);
	};

	/** 상세페이지로 이동
	 * @param {Object} product 상품 정보
	 */
	const moveToProductDetail = (product) => {
		const productDetailPath = `${pathList.product.detail}${getProductDetailType(product.type)}/${
			product?.productNo
		}`;
		navigate(productDetailPath, {
			replace: true,
		});
	};

	return { data, isLoading, handlerClickPrev, handlerClickNext, moveToProductDetail };
};

export default useRecentlyViewed;
