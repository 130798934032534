/**
 * 마이린미 메인 - MBTI 테스트 질문 목록
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useModalStack from "src/hooks/useModalStack";

import { getMbtiQuestion, getMbtiSummary } from "src/api/myLinme/mbti";
import TestQuestionCard from "src/pages/component/myLinme/MBTI/testQuestionCard";
import MyLinmeMBTIResultSummary from "src/pages/myLinme/MBTI/MBTIResultSummary";
import { API_RESULT_STATUS } from "src/data/constEnum";
import { removeSpinner, setSpinner } from "src/utils/linmeDisplay";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myLinme/testQuestionList.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const TestQuestionList = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();
	const navigate = useNavigate();

	const [list, setList] = useState(null);
	const [nowProcess, setNowProcess] = useState(0);
	const [isActiveButton, setIsActiveButton] = useState(false);
	const [resultList, setResultList] = useState([]);

	const modalId = {
		resultSummary: "modalResultSummary",
	};

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 결과 보러 가기 클릭 시
	 * @param {Event} e
	 */
	const handlerClickMoveToResult = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		// 모든 질문에 답변했는지 확인
		if (resultList?.length !== list?.length) {
			return;
		}

		const memberSurveyResults = resultList.map(({ questionNumber, ...rest }) => rest);

		// 결과 API 호출
		_getMbtiSummary(memberSurveyResults);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 질문 목록 가져오기 API
	 */
	const getQuestionList = async () => {
		try {
			await setSpinner();
			const result = await getMbtiQuestion();

			if (result?.status?.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				setList(result?.data);
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		} finally {
			await removeSpinner();
		}
	};

	/**
	 * MBTI 결과 요약 API
	 */
	const _getMbtiSummary = async (memberSurveyResults) => {
		try {
			const result = await getMbtiSummary(memberSurveyResults);

			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				// 결과 요약 모달 열기;
				modalStack.addModal({
					id: modalId.resultSummary,
					type: "alert",
					component: <MyLinmeMBTIResultSummary />,
					resultSummaryData: result?.data,
					moveToDetail: moveToResultDetail,
				});
			} else {
				// 실패 모달 열기;
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	/**
	 * 답변 선택 후 실행
	 */
	const afterSelected = async (questionData, val, questionNumber) => {
		// resultList에 값이 있으면 수정, 없으면 추가;
		setResultList((prevList) => {
			const index = prevList.findIndex((item) => item.questionNumber === questionNumber);
			const newItem = {
				healthStyleCd: questionData?.healthStyleCd,
				surveySeq: questionData?.surveySeq,
				score: val,
				questionNumber: questionNumber,
			};

			if (index !== -1) {
				const updatedList = [...prevList];
				updatedList[index] = newItem;
				return updatedList;
			} else {
				return [...prevList, newItem];
			}
		});

		//이후 진행할 카드 열기;
		//기존 질문에 대해 수정할 수도 있으므로 이후 카드에 대해서만 수행하도록 함;
		if (nowProcess == questionNumber - 1) {
			await setNowProcess(questionNumber);
			const percent = Math.ceil((questionNumber / list.length) * 100);
			if (percent === 100) setIsActiveButton(true);
			else setIsActiveButton(false);
			if (props.setTestStatusPercent) await props.setTestStatusPercent(percent);
		}
	};

	/**
	 * 결과 모달에서 "자세히 알아보기" 클릭 시 모달 닫고 이동
	 * @param {Object} state
	 */
	const moveToResultDetail = (state) => {
		modalStack.removeModal(modalId.resultSummary);
		navigate(pathList.myLinme.MBTIResult, { state: state });
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		getQuestionList();
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<>
			<div className="questionList">
				{list && list.length ? (
					list.map((item, index) => (
						<TestQuestionCard
							key={index + 1}
							questionNumber={index + 1}
							questionContent={item.content}
							active={nowProcess >= index ? true : false}
							afterSelected={afterSelected}
							data={item}
						/>
					))
				) : (
					<></>
				)}
			</div>
			<div
				className={"button clickable moveToResult " + (isActiveButton ? "on" : "")}
				onClick={(e) => handlerClickMoveToResult(e)}
			>
				{textInfo.button.moveToResult}
			</div>
		</>
	);
};
export default TestQuestionList;
