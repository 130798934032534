/**
 * 교환 상세 보기 모달
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
// import moment from "moment";

import useModalStack from "src/hooks/useModalStack";
import { copyStructure } from "src/utils/obj";
import { setClaimDeliveryPickupDetail } from "src/api/myShopping/claim";
import {
	orderChangeCancel,
	orderChangeDetail,
	orderChangeComplete,
} from "src/api/myShopping/change";
import { getDeliveryCompany } from "src/api/etc/delivery";
import {
	API_RESULT_STATUS,
	CLAIM_STATUS,
	COLOR,
	PAYMENT_METHOD_TYPE,
	RETURN_DELIVERY_TYPE,
	POSSIBLE_EXCH_COMPLETE,
	Z_INDEX,
} from "src/data/constEnum";
import { makeDeliveryCompanySelectOptions } from "src/utils/linmeClaim";
import { setInnerSpinner, removeInnerSpinner } from "src/utils/linmeDisplay";

import ModalCommonAlert from "src/pages/common/modalAlert";
import CustomSelectBox from "src/pages/component/common/customSelectBox";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/claim/orderChangeDetail.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

const htmlInfo = require("src/data/htmlInfo.json");
const textInfo = require("src/data/textInfo.json");

const OrderChangeDetail = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();
	const navigate = useNavigate();
	const [data, setData] = useState(null);
	const [totalPrice, setTotalPrice] = useState(null);
	const [deliveryCompanyList, setDeliveryCompanyList] = useState(null);
	const [deliveryCompanyNo, setDeliveryCompanyNo] = useState(-1);
	const ref = {
		deliveryCompany: useRef(null),
		invoiceNo: useRef(null),
	};
	const modalId = {
		changeCancel: "modalCommonConfirm", //교환 철회;
		changeCancelComplete: "modalCommonAlert", //교환 철회 완료;
		changeCancelFail: "modalCommonAlert", //교환 철회 실패;
		changeComplete: "modalCommonConfirm", // 교환 완료
		dataCallFail: "modalCommonAlert", //교환 데이터 호출 실패;
		deliveryCompanyListFail: "modalCommonAlert", //택배사 목록 호출 실패;
		deliveryInfoSelectDeliveryCompany: "modalCommonAlert", //반송정보 저장 시 택배사명 선택;
		deliveryInfoInvoiceNo: "modalCommonAlert", //반송정보 저장 시 송장번호 입력;
		deliveryInfoComplete: "modalCommonAlert", //반송정보 적용 완료;
		deliveryInfoFail: "modalCommonAlert", //반송정보 적용 실패;
	};

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 모달 닫기
	 * @param {Event} e
	 */
	const handlerClickClose = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.removeModal(props.id);
	};

	/**
	 * 교환 철회 모달 확인 시
	 * @param {Event} e
	 */
	const handlerClickModalOrderChangeCancel = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await modalStack.removeModal(modalId.changeCancel);
		await setInnerSpinner();
		await requestOrderChangeCancel(data.claimProductNo);
	};

	/**
	 * 교환 철회 모달 취소 시
	 * @param {Event} e
	 */
	const handlerClickModalOrderChangeClose = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.removeModal(modalId.changeCancel);
	};

	/**
	 * 교환 철회 클릭 시
	 * @param {Event} e
	 */
	const handlerClickOrderChangeCancel = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		//컨펌창 출력;
		await modalStack.addModal({
			id: modalId.changeCancel,
			type: "confirm",
			component: <ModalCommonAlert />,
			text: textInfo.alert.confirmOrderChangeCancel,
			button: {
				iconClose: false,
				confirm: {
					text: textInfo.button.confirm,
					style: { width: "fit-content", padding: "3px 10px" },
					fn: handlerClickModalOrderChangeCancel,
				},
				cancel: {
					text: textInfo.button.cancel,
					style: { width: "fit-content", padding: "3px 10px" },
					fn: handlerClickModalOrderChangeClose,
				},
			},
		});
	};

	/**
	 * 교환 철회 완료
	 * @param {Event} e
	 */
	const handlerClickModalChangeCancelComplete = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await modalStack.removeModal(modalId.changeCancelComplete);
		await modalStack.removeModal(props.id);
		navigate(pathList.myPage.shoppingOrder);
	};

	/**
	 * 교환 철회 실패
	 * @param {Event} e
	 */
	const handlerClickModalChangeCancelFail = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await modalStack.removeModal(modalId.changeCancelFail);
		await modalStack.removeModal(props.id);
		await navigate(pathList.myPage.shoppingClaim);
	};

	/** 교환 완료 **/
	const handleClickComplete = async (e) => {
		e.preventDefault();
		e.stopPropagation();

		const Complete = ``;

		const handlerClickModalOrderChangeComplete = async (e) => {
			//버블링 막기;
			e.preventDefault();
			e.stopPropagation();

			await modalStack.removeModal(modalId.changeComplete);
			await setInnerSpinner();
			await requestOrderChangeComplete(data.claimProductNo);
		};

		await modalStack.addModal({
			id: modalId.changeCancel,
			type: "confirm",
			component: (
				<ModalCommonAlert
					title={textInfo.alert.confirmOrderChangeComplete}
					html={htmlInfo.claim.completeConfirm}
				/>
			),
			button: {
				iconClose: false,
				confirm: {
					text: textInfo.button.confirm,
					style: { width: "fit-content", padding: "3px 10px" },
					fn: handlerClickModalOrderChangeComplete,
				},
				cancel: {
					text: textInfo.button.cancel,
					style: { width: "fit-content", padding: "3px 10px", border: "unset" },
					fn: handlerClickModalOrderChangeClose,
				},
			},
		});
	};

	/**
	 * 반송정보입력 "저장" 클릭 시
	 * @param {Event} e
	 */
	const handlerClickSaveDeliveryInfo = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (deliveryCompanyNo === -1) {
			await modalStack.addModal({
				id: modalId.deliveryInfoSelectDeliveryCompany,
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.pleaseSelectDeliveryCompany,
			});
			return false;
		}
		if (!ref.invoiceNo.current.value) {
			await modalStack.addModal({
				id: modalId.deliveryInfoInvoiceNo,
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.pleaseInputInvoiceNo,
			});
			return false;
		}

		await requestSetDeliveryPickupDetail().then(async (d) => {
			/*/
			const o = await copyStructure( data );
			o.delivery.deliveryType = RETURN_DELIVERY_TYPE.DIRECT;
			o.delivery.companyName = ref.deliveryCompany.current.querySelector( ".selectedValue" ).innerText;
			o.delivery.invoiceCode = ref.invoiceNo.current.value;
			await setData( o );
			//*/
			await requestOrderChangeDetail(d.claimProductNo);
		});
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 택배사 목록 요청
	 * @returns
	 */
	const requestGetDeliveryCompany = async () => {
		try {
			const result = await getDeliveryCompany();
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				const list = makeDeliveryCompanySelectOptions(result.data);
				setDeliveryCompanyList(list);
				return result.data;
			} else {
				window.linmeLog.log(result);
				await modalStack.addModal({
					id: modalId.deliveryCompanyListFail,
					type: "alert",
					component: <ModalCommonAlert />,
					html: htmlInfo.error.dataCall,
				});
				return false;
			}
		} catch (err) {
			window.linmeLog.error(err);
			await modalStack.addModal({
				id: modalId.deliveryCompanyListFail,
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.error.dataCall,
			});
			return false;
		}
	};

	/**
	 * 교환상품번호로 교환 정보 호출
	 * @param {Number} orderNo
	 * @returns
	 */
	const requestOrderChangeDetail = async (claimProductNo) => {
		try {
			await setInnerSpinner();
			const result = await orderChangeDetail(claimProductNo);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (result.data) {
					await setData(result.data);
					const o = await copyStructure(totalPrice ? totalPrice : {});
					o.deliveryPrice =
						result.data.price && result.data.price.deliveryFee ? result.data.price.deliveryFee : 0; //추가 배송비;
					await setTotalPrice(o);
					return result.data;
				} else {
					window.linmeLog.log(result);
					await modalStack.addModal({
						id: modalId.dataCallFail,
						type: "alert",
						component: <ModalCommonAlert />,
						html: result?.response?.data?.message || htmlInfo.error.dataCall,
					});
					return false;
				}
			} else {
				window.linmeLog.log(result);
				await modalStack.addModal({
					id: modalId.dataCallFail,
					type: "alert",
					component: <ModalCommonAlert />,
					html: result?.response?.data?.message || htmlInfo.error.dataCall,
				});
				return false;
			}
		} catch (err) {
			window.linmeLog.error(err);
			await modalStack.addModal({
				id: modalId.dataCallFail,
				type: "alert",
				component: <ModalCommonAlert />,
				html: err?.response?.data?.message || htmlInfo.error.dataCall,
			});
			return false;
		} finally {
			await removeInnerSpinner();
		}
	};

	/**
	 * 교환 철회 ( 교환 요청을 취소 )
	 * @param {Number} claimProductNo
	 * @returns
	 */
	const requestOrderChangeCancel = async (claimProductNo) => {
		try {
			const d = { claimProductNo };
			const result = await orderChangeCancel(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				await removeInnerSpinner();
				await modalStack.addModal({
					id: modalId.changeCancelComplete,
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.completeOrderChangeCancelRequest,
					button: {
						ok: {
							text: textInfo.button.complete,
							fn: handlerClickModalChangeCancelComplete,
						},
					},
				});
				return result.data;
			} else {
				window.linmeLog.log(result);
				await removeInnerSpinner();
				await modalStack.addModal({
					id: modalId.changeCancelFail,
					type: "alert",
					component: <ModalCommonAlert />,
					html: htmlInfo.error.refundCancel,
					button: {
						ok: {
							text: textInfo.button.complete,
							fn: handlerClickModalChangeCancelFail,
						},
					},
				});
				return false;
			}
		} catch (err) {
			window.linmeLog.error(err);
			await removeInnerSpinner();
			await modalStack.addModal({
				id: modalId.changeCancelFail,
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.error.refundCancel,
				button: {
					ok: {
						text: textInfo.button.complete,
						fn: handlerClickModalChangeCancelFail,
					},
				},
			});
			return false;
		}
	};

	/**
	 * 교환 완료
	 * @param {Number} claimProductNo
	 * @returns
	 */
	const requestOrderChangeComplete = async (claimProductNo) => {
		try {
			const req = { claimProductNo };
			const result = await orderChangeComplete(req);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				await modalStack.addModal({
					id: modalId.changeCancelComplete,
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.completeOrderChangeCompleteRequest,
					button: {
						ok: {
							text: textInfo.button.complete,
							fn: handlerClickModalChangeCancelComplete,
						},
					},
				});
				return result.data;
			}
		} catch (err) {
			window.linmeLog.error(err);
			await modalStack.addModal({
				id: modalId.changeCancelFail,
				type: "alert",
				component: <ModalCommonAlert />,
				html: err?.response?.data?.message || htmlInfo.error.completeCancel,
				button: {
					ok: {
						text: textInfo.button.complete,
						fn: handlerClickModalChangeCancelFail,
					},
				},
			});
			return false;
		} finally {
			await removeInnerSpinner();
		}
	};

	/**
	 * 반송 정보 적용 요청
	 * @returns
	 */
	const requestSetDeliveryPickupDetail = async () => {
		try {
			const d = {
				claimProductNo: props.claimProductNo,
				companyNo: deliveryCompanyNo,
				invoiceCode: ref.invoiceNo.current.value,
			};
			const result = await setClaimDeliveryPickupDetail(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (result.data && result.data.hasOwnProperty("claimProductNo")) {
					await modalStack.addModal({
						id: modalId.deliveryInfoComplete,
						type: "alert",
						component: <ModalCommonAlert />,
						text: textInfo.alert.pickupInfoChanged,
					});
				} else {
					window.linmeLog.log(result);
					await modalStack.addModal({
						id: modalId.deliveryInfoFail,
						type: "alert",
						component: <ModalCommonAlert />,
						html: htmlInfo.error.laterDeliveryInfoFail,
					});
					return false;
				}
				return result.data;
			} else {
				window.linmeLog.log(result);
				await modalStack.addModal({
					id: modalId.deliveryInfoFail,
					type: "alert",
					component: <ModalCommonAlert />,
					html: htmlInfo.error.laterDeliveryInfoFail,
				});
				return false;
			}
		} catch (err) {
			window.linmeLog.error(err);
			await modalStack.addModal({
				id: modalId.deliveryInfoFail,
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.error.laterDeliveryInfoFail,
			});
			return false;
		}
	};

	/**
	 * 택배사 선택 후 적용
	 * @param {Object} o
	 */
	const setSelectedDeliveryCompany = async (o) => {
		setDeliveryCompanyNo(o.value);
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		const getData = async () => {
			await requestGetDeliveryCompany(); //택배사 목록;
			if (props.claimProductNo) {
				const claimProductNo = props.claimProductNo;
				await requestOrderChangeDetail(claimProductNo);
			}
		};
		getData();
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="orderChangeDetailContainer">
			<div className="title">{textInfo.claim.type.detailOrderChange}</div>
			{/* 교환 사유 */}
			<ul className="changeDataList reason">
				<li className="changeDataItem title">
					<div className="label">{textInfo.orderChange.orderChangeReason}</div>
					<div className="data">{data && data.reason ? data.reason : "-"}</div>
				</li>
				<li className="changeDataItem reasonDetail">
					<div className="label">{textInfo.orderChange.orderChangeReasonDetail}</div>
					<div className="data">{data && data.content ? data.content : "-"}</div>
				</li>
			</ul>
			{/* 교환 배송비 정보 */}
			{/* { data && data.deliveryPayment ? <ul className="changeDataList"> */}
			{data && data.price && data.price.deliveryFee > 0 ? (
				<ul className="changeDataList">
					<li className="changeDataItem title">
						<div className="label">{textInfo.claim.deliveryPrice.orderChangeInfo}</div>
					</li>
					<li className="changeDataItem">
						<div className="label">{textInfo.claim.deliveryPrice.orderChange}</div>
						<div className="data price">
							{totalPrice.deliveryPrice ? totalPrice.deliveryPrice.toLocaleString() : 0}
							<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
						</div>
					</li>
					{data && data.deliveryPayment ? (
						<li className="changeDataItem">
							<div className="label">{textInfo.order.payment.method}</div>
							<div className="data method">
								{data &&
								data.deliveryPayment &&
								data.deliveryPayment.method === PAYMENT_METHOD_TYPE.CARD ? (
									<>
										<span>
											{textInfo.payment.payMethod.creditCard} {textInfo.order.payment.pay}
										</span>
										<span>
											{data.deliveryPayment.cardInfo.issuer} ({data.deliveryPayment.cardInfo.cardNo}
											) /{" "}
											{data.deliveryPayment.cardInfo.installment === 0
												? textInfo.payment.paid.card.once
												: data.deliveryPayment.cardInfo.installment +
												  textInfo.payment.paid.card.installment}
										</span>
									</>
								) : (
									<></>
								)}
								{data &&
								data.deliveryPayment &&
								data.deliveryPayment.method === PAYMENT_METHOD_TYPE.REWARD ? (
									<>
										<span>{textInfo.payment.paid.reward}</span>
									</>
								) : (
									<></>
								)}
							</div>
						</li>
					) : (
						<></>
					)}
				</ul>
			) : (
				<></>
			)}
			{/* 회수 정보 "나중에 입력"일 때 택배사 및 송장번호 입력 */}
			{data &&
			data.delivery &&
			data.delivery.deliveryType &&
			data.delivery.deliveryType === RETURN_DELIVERY_TYPE.LATER ? (
				<>
					{deliveryCompanyList !== null ? (
						<div className="deliveryCompanyList" ref={ref.deliveryCompany}>
							<ul className="changeDataList">
								<li className="changeDataItem title">
									<div className="label">{textInfo.claim.pickup.enterDeliveryInfoDetail}</div>
								</li>
								<li className="changeDataItem userInput">
									<div className="label">{textInfo.claim.pickup.companyName}</div>
									<CustomSelectBox
										options={deliveryCompanyList}
										value={deliveryCompanyNo}
										placeholder={textInfo.claim.pickup.pleaseSelectDeliveryCompany}
										onChange={(item) => setSelectedDeliveryCompany(item)}
									/>
								</li>
								<li className="changeDataItem userInput">
									<div className="label invoice">{textInfo.claim.pickup.invoiceNo}</div>
									<input
										type="text"
										className="invoiceNo"
										name="invoiceNo"
										ref={ref.invoiceNo}
										placeholder={textInfo.claim.pickup.placeholder.invoiceNo}
									/>
								</li>
								<li className="changeDataItem">
									<div
										className="button clickable save"
										onClick={(e) => handlerClickSaveDeliveryInfo(e)}
									>
										{textInfo.button.save}
									</div>
								</li>
							</ul>
						</div>
					) : (
						<></>
					)}
				</>
			) : (
				<></>
			)}
			{/* 회수 정보 - 직접입력 */}
			{data &&
			data.delivery &&
			data.delivery.deliveryType &&
			data.delivery.deliveryType === RETURN_DELIVERY_TYPE.DIRECT ? (
				<ul className="changeDataList direct">
					<li className="changeDataItem title">
						<div className="label">{textInfo.claim.pickup.deliveryInfoDetail}</div>
					</li>
					<li className="changeDataItem">
						<div className="label">{textInfo.claim.pickup.companyName}</div>
						<div className="data">
							{data && data.delivery && data.delivery.companyName ? data.delivery.companyName : "-"}
						</div>
					</li>
					<li className="changeDataItem">
						<div className="label">{textInfo.claim.pickup.invoiceNo}</div>
						<div className="data">
							{data && data.delivery && data.delivery.invoiceCode ? data.delivery.invoiceCode : "-"}
						</div>
					</li>
				</ul>
			) : (
				<></>
			)}
			{/* 회수지 정보 */}
			{data &&
			data.delivery &&
			data.delivery.deliveryType &&
			data.delivery.deliveryType === RETURN_DELIVERY_TYPE.COMPANY ? (
				<ul className="changeDataList">
					<li className="changeDataItem title">
						<div className="label">{textInfo.claim.collectionAreaInfo.title}</div>
					</li>
					<li className="changeDataItem">
						<div className="label">{textInfo.claim.collectionAreaInfo.sender}</div>
						<div className="data">
							{data && data.delivery && data.delivery.collectAddress
								? data.delivery.collectAddress.name
								: "-"}
						</div>
					</li>
					<li className="changeDataItem">
						<div className="label">{textInfo.claim.collectionAreaInfo.phone}</div>
						<div className="data">
							{data && data.delivery && data.delivery.collectAddress
								? data.delivery.collectAddress.postcode
								: "-"}
						</div>
					</li>
					<li className="changeDataItem">
						<div className="label">{textInfo.claim.collectionAreaInfo.address}</div>
						<div className="data">
							{data && data.delivery && data.delivery.collectAddress
								? data.delivery.collectAddress.address1
								: "-"}
						</div>
					</li>
					<li className="changeDataItem onlyData">
						<div className="data">
							{data && data.delivery && data.delivery.collectAddress
								? data.delivery.collectAddress.address2
								: "-"}
						</div>
					</li>
					{data &&
					data.delivery &&
					data.delivery.collectAddress &&
					data.delivery.collectAddress.memo ? (
						<li className="changeDataItem">
							<div className="label">{textInfo.claim.collectionAreaInfo.memo}</div>
							<div className="data">{data.delivery.collectAddress.memo}</div>
						</li>
					) : (
						<></>
					)}
				</ul>
			) : (
				<></>
			)}
			{/* 상품 수령 정보 */}
			<ul className="changeDataList">
				<li className="changeDataItem title">
					<div className="label">{textInfo.claim.receive.title}</div>
				</li>
				<li className="changeDataItem">
					{/* <div className="label">{textInfo.claim.receiveAreaInfo.receiver}</div> */}
					<div className="label">{textInfo.claim.receive.receiveAddress}</div>
					<div className="data">
						{data && data.delivery && data.delivery.receiveAddress
							? data.delivery.receiveAddress.name
							: "-"}
					</div>
				</li>
				<li className="changeDataItem onlyData">
					{/* <div className="label">{textInfo.claim.receiveAreaInfo.phone}</div> */}
					<div className="data">
						{data && data.delivery && data.delivery.receiveAddress
							? data.delivery.receiveAddress.postcode
							: "-"}
					</div>
				</li>
				<li className="changeDataItem onlyData">
					{/* <div className="label">{textInfo.claim.receiveAreaInfo.address}</div> */}
					<div className="data">
						{data && data.delivery && data.delivery.receiveAddress
							? data.delivery.receiveAddress.address1
							: "-"}
					</div>
				</li>
				<li className="changeDataItem onlyData">
					<div className="data">
						{data && data.delivery && data.delivery.receiveAddress
							? data.delivery.receiveAddress.address2
							: "-"}
					</div>
				</li>
				{data &&
				data.delivery &&
				data.delivery.receiveAddress &&
				data.delivery.receiveAddress.memo ? (
					<li className="changeDataItem">
						{/* <div className="label">{textInfo.claim.receiveAreaInfo.memo}</div> */}
						<div className="data">{data.delivery.receiveAddress.memo}</div>
					</li>
				) : (
					<></>
				)}
			</ul>
			<div className="buttonCover">
				{/* 교환 요청 */}
				{data && data?.status === CLAIM_STATUS.EXCH_REQ.value ? (
					<>
						<div
							className="button clickable exchCancel"
							onClick={(e) => handlerClickOrderChangeCancel(e)}
						>
							{textInfo.button.shopping.exchCancel}
						</div>
						<div className="button clickable close" onClick={(e) => handlerClickClose(e)}>
							{textInfo.button.confirm}
						</div>
					</>
				) : POSSIBLE_EXCH_COMPLETE.includes(data?.status) ? (
					<>
						<div className="button clickable exchCancel" onClick={(e) => handleClickComplete(e)}>
							{textInfo.button.shopping.exchComplete}
						</div>
						<div className="button clickable close" onClick={(e) => handlerClickClose(e)}>
							{textInfo.button.confirm}
						</div>
					</>
				) : (
					<div className="button clickable close" onClick={(e) => handlerClickClose(e)}>
						{textInfo.button.confirm}
					</div>
				)}
			</div>
		</div>
	);
};

export default OrderChangeDetail;

