import { useQuery } from "@tanstack/react-query";
import { REVIEW_LIST_ORDER, PAGINATION_DEFAULT_OFFSET } from "src/data/constEnum";
import { getProductReviewList } from "./../../api/product/detail";
import { useState } from "react";
import { queryKeys } from "src/data";

/**
 * 리뷰 목록 가져오기
 * @param {Number} pageNo
 */
const getReviewList = async (productNo, pageNo) => {
	try {
		const d = {
			productNo: productNo,
			offset: PAGINATION_DEFAULT_OFFSET.REVIEW_LIST,
			page: pageNo,
			sort: REVIEW_LIST_ORDER[0].value,
		};
		const result = await getProductReviewList(d);
		return result.data;
	} catch (err) {
		window.linmeLog.error(err);
		return false;
	}
};

const useProductReview = ({ productNo }) => {
	const [pageNo, setPageNo] = useState(1);
	const { data } = useQuery({
		queryKey: [queryKeys.PRODUCT_REVIEW, productNo, pageNo],
		queryFn: () => getReviewList(productNo, pageNo),
	});

	const handlePageNo = (pageNo) => {
		setPageNo(pageNo);
	};

	return { data, handlePageNo };
};

export default useProductReview;
