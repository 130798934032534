import React, { memo } from "react";
import clsx from "clsx";

import {
	OrderExpandCover,
	OrderInfoContainer,
	OrderProductInfo,
	OrderStatusButton,
} from "src/pages/component/order";

/**
 * 주문 카드
 * @param {object} props.order 주문 정보
 * @param {object} props.refetchOrderData 주문 데이터 새로고침
 */
const OrderCard = (props) => {
	const { order, refetchOrderData } = props;
	return (
		<li className="orderContent" key={order.orderNo}>
			<OrderInfoContainer order={order} />
			<ul className="orderProductList">
				{order.orderItems && order.orderItems.length ? (
					order.orderItems.map((item, index) => (
						<li className={clsx(["orderProductItem", index === 0 ? "on" : ""])} key={index}>
							<OrderProductInfo item={item} />
							<OrderStatusButton item={item} order={order} refetchOrderData={refetchOrderData} />
						</li>
					))
				) : (
					<li className="noData"></li>
				)}
			</ul>
			{order.orderItems.length > 1 && <OrderExpandCover order={order} />}
		</li>
	);
};

export default memo(OrderCard);
