import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/**
 * 신상품 상품 목록
 * @param {Object} d
 * <code>
 * {
 * 		typeNo: 1 // 1:기능식품, 2:맞춤식품;
 * 		, offset: 20
 * 		, page: 1
 * 		, sort: "" //정렬 ( PRODUCT_LIST_ORDER );
 * }
 * </code>
 */
export const getNewProductList = async (d) => {
	const url = `${API_PATHS.PRODUCT_NEW}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 베스트 상품 목록
 * @param {Object} d
 * <code>
 * {
 * 		typeNo: 1 // 1:기능식품, 2:맞춤식품;
 * 		, offset: 20
 * 		, page: 1
 * 		, sort: "" //정렬 ( PRODUCT_LIST_ORDER );
 * }
 * </code>
 */
export const getBestProductList = async (d) => {
	const url = `${API_PATHS.PRODUCT_BEST}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 특가상품 상품 목록
 * @param {Object} d
 * <code>
 * {
 * 		typeNo: 1 // 1:기능식품, 2:맞춤식품;
 * 		, offset: 20
 * 		, page: 1
 * 		, sort: "" //정렬 ( PRODUCT_LIST_ORDER );
 * }
 * </code>
 */
export const getSpecialProductList = async (d) => {
	const url = `${API_PATHS.PRODUCT_SPECIAL}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
