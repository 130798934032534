/**
 * 네이버 전환 스크립트
 */

/**
 * 페이지 뷰 이벤트 전송
 */
export const sendNaverPV = () => {
	if (!window?.wcs) return;
	if (!window.wcs_add) window.wcs_add = {};
	window.wcs_add["wa"] = process.env.REACT_APP_NAVER_WCS; // 네이버 광고 공통키
	if (!_nasa) var _nasa = {};
	window.wcs.inflow(); // 쿠키 도메인
	window.wcs_do(); // PV 이벤트 전송
};

/**
 * 회원가입 완료
 */
export const sendNaverSignUp = () => {
	if (!window?.wcs) return;
	const _conv = { type: "sign_up" };
	window.wcs.trans(_conv); // 전환이벤트 정보를 담은 object를 서버에 전송
};

/**
 * 장바구니 담기 완료
 * @param {Object} props - 장바구니 데이터
 * @param {number} props.totalPrice - 총 가격
 * @param {number} props.productNo - 상품 번호
 * @param {string} props.productName - 상품 이름
 * @param {number} props.quantity - 수량
 */
export const sendNaverAddToCart = (props) => {
	if (!window?.wcs) return;
	const { totalPrice, productNo, productName, quantity } = props;
	const _conv = {
		type: "add_to_cart",
		id: "", // 전환 ID, 없을 경우 빈 문자열로 설정
		value: totalPrice, // 복수개의 재화/용역의 전체 금액 (배송비 제외 권장)
		items: [
			{
				id: productNo, //string 상품 id (필수)
				name: productName, //string 상품 명 (필수)
				quantity: quantity, //number 결제 수량 (필수)
				payAmount: totalPrice, //number 결제 금액 (필수)
			},
		],
	};
	window.wcs.trans(_conv); // 전환이벤트 정보를 담은 object를 서버에 전송
};

/**
 * 구매하기 완료
 * @param {Object} orderData - 주문 완료 데이터
 */
export const sendNaverPurchase = (orderData) => {
	if (!window?.wcs) return;
	const _conv = {
		type: "purchase",
		id: "", // 전환 ID, 없을 경우 빈 문자열로 설정
		value: orderData?.price?.productPrice, // 복수개의 재화/용역의 전체 금액 (배송비 제외 권장)
		items: getItemsFromOrderData(orderData),
	};
	window.wcs.trans(_conv); // 전환이벤트 정보를 담은 object를 서버에 전송
};

/**
 * 주문 데이터에서 아이템 정보를 추출
 * @param {Object} orderData - 주문 데이터
 * @returns {Array} - 아이템 정보 배열
 */
const getItemsFromOrderData = (orderData) => {
	return orderData.combinedShip.flatMap((shipment) =>
		shipment.products.map((product) => ({
			id: product.productNo, //string 상품 id (필수)
			name: product.name, //string 상품 명 (필수)
			quantity: product.required.reduce((acc, cur) => acc + cur.quantity, 0), //number 결제 수량 (필수)
			payAmount: product.totalSalePrice, //number 결제 금액 (필수)
		}))
	);
};
