// HealthTypeDetail.jsx
import React from "react";
const textInfo = require("src/data/textInfo.json");

const HealthTypeDetail = ({ healthTopic, nutrient, healthType }) => {
	const renderInfoItem = (title, items, noDataText) => (
		<div className="healthTypeCardInfoItem">
			<div className="healthTypeCardInfoTitle">{title}</div>
			<div className="healthTypeInfoValueContainer">
				{items?.length > 0 ? (
					items.map((item, index) => (
						<div className="healthTypeInfoValue" key={index}>
							{item}
						</div>
					))
				) : (
					<div className="healthTypeInfoValue noData">{noDataText}</div>
				)}
			</div>
		</div>
	);

	return (
		<div className="healthTypeCardInfoContainer">
			{renderInfoItem(
				textInfo.myLinme.healthTopic,
				healthTopic,
				textInfo.myLinme.healthTopicNoData
			)}
			{renderInfoItem(textInfo.myLinme.nutrient, nutrient, textInfo.myLinme.nutrientNoData)}
			<div className="healthTypeCardInfoItem noBorder">
				<div className="healthTypeCardInfoTitle">{textInfo.myLinme.healthType}</div>
				<div className="healthTypeInfoValueContainer">
					{healthType ? (
						<div className="healthTypeInfoValue">{healthType}</div>
					) : (
						<div className="healthTypeInfoValue noData">{textInfo.myLinme.healthTypeNoData}</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default HealthTypeDetail;
