import apiMyLinme from "src/api/axiosMyLinme";
import API_PATHS from "src/api/apiPaths";

/**
 * 설문 최초 질문 (남/여)
 * @returns
 */
export const getSurveyDefault = async () => {
	const url = `${API_PATHS.SURVEY_DEFAULT}`;
	try {
		const result = await apiMyLinme.get(url);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 설문 두번째 질문 (나이)
 * @param {Object} d
 * <code>
	{
		"subCode" : "0002",
		"codeGroupId" : "0001"
	}
 * </code>
 * @returns
 */
export const getSurveyQuestion = async (d) => {
	const url = `${API_PATHS.SURVEY_QUESTION}`;
	try {
		const result = await apiMyLinme.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 설문 BMI 후 최종 질문
	{
		"parentId": 2,
		"subCode" : "0002",
		"codeGroupId" : "0002",
		"height" : 177.8,
		"weight" : 50.2
	}
 * @returns
 */
export const getTopicInfo = async (d) => {
	const url = `${API_PATHS.SURVEY_TOPIC_INFO}`;
	try {
		const result = await apiMyLinme.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 설문 최종 질문
	{
		"parentId": 2,
		"subCode" : "0002",
		"codeGroupId" : "0002",
		"height" : 177.8,
		"weight" : 50.2
	}
 * @returns
 */
export const getSurveyAnswer = async (d) => {
	const url = `${API_PATHS.SURVEY_ANSWER}`;
	try {
		const result = await apiMyLinme.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 설문 결과 저장
 * @returns
 */
export const saveSurveyResult = async (d) => {
	const url = `${API_PATHS.SURVEY_SAVE_RESULT}`;
	try {
		const result = await apiMyLinme.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 설문 결과 조회
 * @returns
 */
export const getSurveyResult = async () => {
	const url = `${API_PATHS.SURVEY_GET_RESULT}`;
	try {
		const result = await apiMyLinme.get(url);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
