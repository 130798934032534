import { forwardRef } from "react";
import styles from "src/styles/infiniteTrigger.module.scss";
import { clsx } from "clsx";

const InfiniteTrigger = forwardRef((props, ref) => {
	return <div ref={ref} className={clsx([styles.trigger, props.className])} {...props} />;
});

InfiniteTrigger.displayName = "InfiniteTrigger";

export default InfiniteTrigger;
