/**
 * 로그아웃
 */

import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/**
 * 로그아웃
 * @param {Object} d
 * <code>
 * {
 * 		refreshToken: ""
 * }
 * </code>
 */
export const signOut = async (d) => {
	const url = `${API_PATHS.MEMBER_SIGN_OUT}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
