/**
 * 마이린미 - 건강상태 설문
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useModalStack from "src/hooks/useModalStack";
import { getCondition } from "src/api/myLinme/main";
import { removeSpinner, setSpinner } from "src/utils/linmeDisplay";
import { API_RESULT_STATUS } from "src/data/constEnum";

import MyLinmeSurveyMain from "src/pages/myLinme/survey/main";
import MyLinmeSurveyDefault1 from "src/pages/myLinme/survey/survey_default_1";
import MyLinmeSurveyDefault2 from "src/pages/myLinme/survey/survey_default_2";
import MyLinmeSurveyDefault3 from "src/pages/myLinme/survey/survey_default_3";
import MyLinmeSurveyDefault4 from "src/pages/myLinme/survey/survey_default_4";
import MyLinmeSurveyTopic1 from "src/pages/myLinme/survey/survey_topic_1";
import MyLinmeSurveyAnswer1 from "src/pages/myLinme/survey/survey_answer_1";
import CloseConfirm from "src/pages/component/myLinme/survey/closeConfirm";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myLinme/survey/survey.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

// const htmlInfo = require( "src/data/htmlInfo.json" );
// const textInfo = require( "src/data/textInfo.json" );

const MyLinmeSurvey = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();
	const navigate = useNavigate();

	const [myInfoData, setMyInfoData] = useState(); // 내 정보

	const [surveyStep, setSurveyStep] = useState(1); // 설문 단계

	const [defaultSelectedData1, setDefaultSelectedData1] = useState([]); // 남/여
	const [defaultSelectedData2, setDefaultSelectedData2] = useState([]); // 나이
	const [defaultSelectedData3, setDefaultSelectedData3] = useState(""); // 몸무게
	const [defaultSelectedData4, setDefaultSelectedData4] = useState(""); // 키
	const [topicInfoSelectedData, setTopicInfoSelectedData] = useState([]); // 증상/불편

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 설문 종료
	 * @param {Event} e
	 */
	const handlerClickClose = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await modalStack.addModal({
			id: "modalCloseConfirm",
			component: <CloseConfirm />,
			button: {
				iconClose: false,
			},
		});
	};

	/**
	 * 설문 이전페이지로 이동
	 */
	const handlerClickPrev = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (surveyStep <= 1) return;

		setSurveyStep((prev) => prev - 1);
	};

	/**
	 * 설문 다음페이지로 이동
	 */
	const handlerClickNext = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		// 마지막 페이지일 경우 결과 페이지로 이동
		if (surveyStep > 6) {
			navigate(pathList.myLinme.surveyResult);
			return;
		}

		setSurveyStep((prev) => prev + 1);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 건강 상태 현황 조회
	 */
	const _getCondition = async () => {
		try {
			await setSpinner();

			const result = await getCondition();

			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				setMyInfoData(result?.data?.information);
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		} finally {
			removeSpinner();
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		_getCondition();
	}, []);

	useEffect(() => {
		if (myInfoData) setSurveyStep(6);
	}, [myInfoData]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<>
			{/* 메인 */}
			{surveyStep === 1 && (
				<MyLinmeSurveyMain
					handlerClickClose={handlerClickClose}
					handlerClickPrev={handlerClickPrev}
					handlerClickNext={handlerClickNext}
				/>
			)}
			{/* 1. 기본정보 - 남/여 */}
			{surveyStep === 2 && (
				<MyLinmeSurveyDefault1
					handlerClickClose={handlerClickClose}
					handlerClickPrev={handlerClickPrev}
					handlerClickNext={handlerClickNext}
					defaultSelectedData1={defaultSelectedData1}
					setDefaultSelectedData1={setDefaultSelectedData1}
				/>
			)}
			{/* 1. 기본정보 - 나이 */}
			{surveyStep === 3 && (
				<MyLinmeSurveyDefault2
					handlerClickClose={handlerClickClose}
					handlerClickPrev={handlerClickPrev}
					handlerClickNext={handlerClickNext}
					defaultSelectedData1={defaultSelectedData1}
					defaultSelectedData2={defaultSelectedData2}
					setDefaultSelectedData2={setDefaultSelectedData2}
				/>
			)}
			{/* 1. 기본정보 - 몸무게 */}
			{surveyStep === 4 && (
				<MyLinmeSurveyDefault3
					handlerClickClose={handlerClickClose}
					handlerClickPrev={handlerClickPrev}
					handlerClickNext={handlerClickNext}
					defaultSelectedData3={defaultSelectedData3}
					setDefaultSelectedData3={setDefaultSelectedData3}
				/>
			)}
			{/* 1. 기본정보 - 키 */}
			{surveyStep === 5 && (
				<MyLinmeSurveyDefault4
					handlerClickClose={handlerClickClose}
					handlerClickPrev={handlerClickPrev}
					handlerClickNext={handlerClickNext}
					defaultSelectedData4={defaultSelectedData4}
					setDefaultSelectedData4={setDefaultSelectedData4}
				/>
			)}
			{/* 2. 증상/불편 */}
			{surveyStep === 6 && (
				<MyLinmeSurveyTopic1
					handlerClickClose={handlerClickClose}
					handlerClickPrev={handlerClickPrev}
					handlerClickNext={handlerClickNext}
					defaultSelectedData2={defaultSelectedData2}
					defaultSelectedData3={defaultSelectedData3}
					defaultSelectedData4={defaultSelectedData4}
					topicInfoSelectedData={topicInfoSelectedData}
					myInfoData={myInfoData}
					setTopicInfoSelectedData={setTopicInfoSelectedData}
				/>
			)}
			{/* 3. 상세설문 */}
			{surveyStep === 7 && (
				<MyLinmeSurveyAnswer1
					handlerClickClose={handlerClickClose}
					handlerClickPrev={handlerClickPrev}
					handlerClickNext={handlerClickNext}
					topicInfoSelectedData={topicInfoSelectedData}
				/>
			)}
		</>
	);
};

export default MyLinmeSurvey;
