import { isIOS, isMobile as isMobileOrTablet } from "react-device-detect";
import { CLIENT_SCREEN_WIDTH, DEVICE_TYPE } from "src/data/constEnum";
const pathList = require("src/path/pathJSON.json");

/**
 * linme Device 관련 함수
 */

//----------------------------------------------------------------------------------------------------;

/**
 * 디바이스 확인
 * @returns {Boolean}
 */
export const checkDevice = () => {
	let clientWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
	if (
		clientWidth >= CLIENT_SCREEN_WIDTH.MOBILE_MINI.MIN &&
		clientWidth <= CLIENT_SCREEN_WIDTH.MOBILE_MINI.MAX
	) {
		return DEVICE_TYPE.MOBILE;
	}
	if (
		clientWidth >= CLIENT_SCREEN_WIDTH.MOBILE.MIN &&
		clientWidth <= CLIENT_SCREEN_WIDTH.MOBILE.MAX
	) {
		return DEVICE_TYPE.MOBILE;
	}
	if (
		clientWidth >= CLIENT_SCREEN_WIDTH.TABLET.MIN &&
		clientWidth <= CLIENT_SCREEN_WIDTH.TABLET.MAX
	) {
		return DEVICE_TYPE.TABLET;
	}
	return DEVICE_TYPE.PC;
};

/**
 * 모바일 여부 확인
 * @returns {Boolean}
 */
export const isMobile = () => {
	let clientWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
	if (
		clientWidth >= CLIENT_SCREEN_WIDTH.MOBILE_MINI.MIN &&
		clientWidth <= CLIENT_SCREEN_WIDTH.MOBILE_MINI.MAX
	) {
		return true;
	}
	if (
		clientWidth >= CLIENT_SCREEN_WIDTH.MOBILE.MIN &&
		clientWidth <= CLIENT_SCREEN_WIDTH.MOBILE.MAX
	) {
		return true;
	}
	if (
		clientWidth >= CLIENT_SCREEN_WIDTH.TABLET.MIN &&
		clientWidth <= CLIENT_SCREEN_WIDTH.TABLET.MAX
	) {
		return true;
	}
	return false;
};

/**
 * iOS URL 생성
 * @returns {String}
 */
const getIOSUrl = () => {
	const { scheme, appId, manual } = pathList.appDownload.ios;
	return isMobileOrTablet ? `${scheme}${appId}` : `${manual}${appId}`;
};

/**
 * AOS URL 생성
 * @returns {String}
 */
const getAOSUrl = () => {
	const { scheme, packageName, manual } = pathList.appDownload.aos;
	return isMobileOrTablet ? `${scheme}${packageName}` : `${manual}${packageName}`;
};

/**
 * URL 이동
 * @param {String} url
 */
const navigateToUrl = (url) => {
	if (isMobileOrTablet) {
		window.location.href = url;
	} else {
		window.open(url, "_blank");
	}
};

/**
 * 마켓 이동
 */
export const moveAppDownload = (platform) => {
	const isIOSPlatform = platform === "ios" || (!platform && isIOS);
	const isAOSPlatform = platform === "aos" || (!platform && !isIOS);

	if (isIOSPlatform) {
		navigateToUrl(getIOSUrl());
	} else if (isAOSPlatform) {
		navigateToUrl(getAOSUrl());
	}
};

//----------------------------------------------------------------------------------------------------;
