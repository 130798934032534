/**
 * 쇼핑내역 - 주문/배송 - 구매확정 후 알람 설정 팝업
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import useModalStack from "src/hooks/useModalStack";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myPage/order/confirmAlarm.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const ConfirmAlarm = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 취소 클릭 시
	 * @param {Event} e
	 */
	const handlerClickClose = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.removeModal(props.id);
		if (props.handlerClickClose) await props.handlerClickClose();
	};

	/**
	 * 완료 클릭 시
	 * @param {Event} e
	 */
	const handlerClickComplete = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.removeModal(props.id);
		if (props.handlerClickConfirm) await props.handlerClickConfirm(props.item);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="modalConfirmAlarmContainer">
			<div className="title">{textInfo.orderAlarm.title}</div>
			<div className="data">{textInfo.orderAlarm.desc1}</div>
			<div className="data2">{textInfo.orderAlarm.desc2}</div>
			<div className="buttonCover">
				<div className="button clickable close" onClick={(e) => handlerClickClose(e)}>
					{textInfo.orderAlarm.close}
				</div>
				<div className="button clickable confirm" onClick={(e) => handlerClickComplete(e)}>
					{textInfo.orderAlarm.confirm}
				</div>
			</div>
		</div>
	);
};

export default ConfirmAlarm;
