/**
 * 리워드 - 챌린지 목록
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import useAuth from "src/hooks/useAuth";
import { getChallenge } from "src/api/challenge/challenge";
import { API_RESULT_STATUS, PAGINATION_DEFAULT_OFFSET } from "src/data/constEnum";
import { removeInnerSpinner, setInnerSpinner, setScrollTop } from "src/utils/linmeDisplay";
import { getLastPageNumber } from "src/utils/number";

import Pagination from "src/pages/component/pagination";
import RewardChallengeCard from "src/pages/component/reward/rewardChallengeCard";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */

const pathList = require("src/path/pathJSON.json");

// const htmlInfo = require( "src/data/htmlInfo.json" );
// const textInfo = require( "src/data/textInfo.json" );

const RewardChallengeList = ({ challengeStatus }) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const auth = useAuth();

	const [challengeData, setChallengeData] = useState([]);
	const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);
	const [paginationOffset, setPaginationOffset] = useState(
		PAGINATION_DEFAULT_OFFSET.REWARD_CHALLENGE
	);
	const [paginationTotalCount, setPaginationTotalCount] = useState(0);
	const [paginationLastPage, setPaginationLastPage] = useState(0);
	const [paginationIsLastPage, setPaginationIsLastPage] = useState(false);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 다시 그리기
	 * @param {Number} pageNo
	 */
	const redraw = async (pageNo) => {
		await setInnerSpinner();
		await requestChallenge(pageNo, challengeStatus);
		await setScrollTop();
		await removeInnerSpinner();
	};

	/**
	 * 챌린지 목록 조회
	 * @param {Number} pageNo
	 * @param {String} status
	 */
	const requestChallenge = async (pageNo, status) => {
		let d = {
			offset: paginationOffset,
			page: pageNo,
			status: status,
		};

		try {
			const result = await getChallenge(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (result?.data?.content) {
					setChallengeData(result.data.content);

					setPaginationCurrentPage(result.data.page);
					setPaginationTotalCount(result.data.totalCount);
					setPaginationIsLastPage(result.data.isLastPage);
					setPaginationLastPage(getLastPageNumber(result.data.totalCount, paginationOffset));
				}
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		if (!auth.isLogin) return;

		const getData = async () => {
			await setInnerSpinner();
			await requestChallenge(1, challengeStatus);
			await removeInnerSpinner();
		};
		getData();
	}, [challengeStatus, auth.isLogin]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<>
			<ul className="challengeList">
				{challengeData && challengeData.length ? (
					challengeData.map((item) => (
						<li className="challengeItem" key={item.challengeNo}>
							<Link to={pathList.reward.detail + item.challengeNo}>
								<RewardChallengeCard {...item} />
							</Link>
						</li>
					))
				) : (
					<li className="challengeItem noData" />
				)}
			</ul>
			{challengeData && challengeData.length ? (
				<Pagination
					{...{
						currentPage: paginationCurrentPage,
						offset: paginationOffset,
						totalCount: paginationTotalCount,
						lastPage: paginationLastPage,
						isLastPage: paginationIsLastPage,
						redraw: redraw,
					}}
				/>
			) : (
				<></>
			)}
		</>
	);
};

export default RewardChallengeList;
