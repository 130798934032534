/**
 * 마이린미 - 건강 설문 - 닫기 모달
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useNavigate } from "react-router-dom";

import useModalStack from "src/hooks/useModalStack";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myLinme/survey/closeConfirm.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const CloseConfirm = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();
	const navigate = useNavigate();

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 아니오 클릭 시 - 모달 닫기
	 * @param {Event} e
	 */
	const handlerClickNo = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.removeModal(props.id);
	};

	/**
	 * 예 클릭 시 - 마이린미 메인으로 이동
	 * @param {Event} e
	 */
	const handlerClickYes = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await modalStack.removeModal(props.id);
		await navigate(pathList.myLinme.index);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="closeConfirmContainer">
			<div className="closeTitle">{textInfo.myLinme.survey.modal.closeTitle}</div>
			<div className="closeSubTitle">{textInfo.myLinme.survey.modal.closeSubTitle}</div>
			<div className="modalCloseButtonCover">
				<div className="cancel" onClick={(e) => handlerClickNo(e)}>
					{textInfo.button.cancel}
				</div>
				<div className="confirm" onClick={(e) => handlerClickYes(e)}>
					{textInfo.button.confirm}
				</div>
			</div>
		</div>
	);
};

export default CloseConfirm;
