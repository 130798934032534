/**
 * 상품 상세
 */

import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { AppContext } from "src/App";
import useModalStack from "src/hooks/useModalStack";
import { API_RESULT_STATUS, DEEP_LINK_PATH } from "src/data/constEnum";
import { getProductDetail } from "src/api/product/detail";
import { setSpinner, removeSpinner } from "src/utils/linmeDisplay";

import ProductDetailInfo from "src/pages/component/product/productDetailInfo";
import ProductDetailTab from "src/pages/component/product/productDetailTab";
import ModalCommonAlert from "src/pages/common/modalAlert";
const htmlInfo = require("src/data/htmlInfo.json");
import "src/styles/product/productDetail.scss";

const ProductDetail = () => {
	const location = useLocation();
	const modalStack = useModalStack();
	const [productData, setProductData] = useState(null);
	const { setSchemePath } = useContext(AppContext);

	/**
	 * 상품 상세 정보
	 * @param {Number} productNo
	 */
	const requestGetProductDetail = async (productNo) => {
		try {
			const result = await getProductDetail(productNo);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				const data = result.data;
				setProductData(data);
				return data;
			} else {
				window.linmeLog.log(result);
				modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					html: htmlInfo.error.dataCall,
				});
				return false;
			}
		} catch (err) {
			window.linmeLog.error(err);
			modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				html: err?.response?.data?.message || htmlInfo.error.dataCall,
			});
			return false;
		}
	};

	useEffect(() => {
		const getData = async () => {
			await setSpinner();
			setProductData(null);
			const productNumber = location.pathname.substring(
				location.pathname.lastIndexOf("/") + 1,
				location.pathname.length
			);
			await setSchemePath(DEEP_LINK_PATH.PRODUCT_DETAIL + productNumber);
			await requestGetProductDetail(productNumber);
			await removeSpinner();
		};
		getData();
	}, [location?.pathname]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="container underTopMenu productDetail">
			<div className="contents">{productData && <ProductDetailInfo {...productData} />}</div>
			<div className="cuttingLine" />
			<div className="continue">{productData && <ProductDetailTab {...productData} />}</div>
		</div>
	);
};

export default ProductDetail;
