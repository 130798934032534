import { useState, useRef, useEffect, useCallback } from "react";

const useInView = (options = {}) => {
	const [isIntersecting, setIsIntersecting] = useState(false);
	const ref = useRef(null);

	const callback = useCallback((entries) => {
		const [entry] = entries;
		setIsIntersecting(entry.isIntersecting);
	}, []);

	useEffect(() => {
		const observer = new IntersectionObserver(callback, options);
		if (ref.current) observer.observe(ref.current);

		return () => {
			if (ref.current) observer.unobserve(ref.current);
		};
	}, [ref, options, callback]);

	return [ref, isIntersecting];
};

export default useInView;
