/**
 * 마이페이지 - 배송지 관리 - 배송지 추가
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useModalStack from "src/hooks/useModalStack";

import { execDaumPostCode } from "src/common/postCodeDaum";
import {
	API_RESULT_STATUS,
	COLOR,
	DELIVERY_MEMO_DIRECT_INPUT,
	DELIVERY_MEMO_LIST,
	REG_EXP,
} from "src/data/constEnum";
import { getIndexFromArrayByKeyAndValue } from "src/utils/arrayCollection";
import { handlerKeyUpPhone } from "src/utils/linmeMember";
import { addAddress, modifyAddress } from "src/api/member/addressBook";

import ModalCommonAlert from "src/pages/common/modalAlert";
import CustomSelectBox from "src/pages/component/common/customSelectBox";
import { removeInnerSpinner, setInnerSpinner } from "src/utils/linmeDisplay";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myPage/addressBookAdd.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

const htmlInfo = require("src/data/htmlInfo.json");
const textInfo = require("src/data/textInfo.json");

const MyPageAddressBookAdd = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const [deliveryMemo, setDeliveryMemo] = useState("");
	const [defaultMemo, setDefaultMemo] = useState({});
	const location = useLocation();
	const modalStack = useModalStack();
	const navigate = useNavigate();
	// let defaultValue = {};
	const [defaultValue, setDefaultValue] = useState({});
	const ref = {
		nickname: useRef(null), //배송지명;
		name: useRef(null), //수취인;
		phone: useRef(null), //연락처;
		postcode: useRef(null), //우편번호;
		address1: useRef(null), //주소1;
		address2: useRef(null), //주소2;
		memo: useRef(null), //배송요청사항;
		checkDefaultAddress: useRef(null), //기본 배송지 설정;
		button: useRef(null), //저장 버튼;
	};

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 입력 변경 시
	 * @param {Event} e
	 */
	const handlerChangeInput = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const checkValidation = await validation();
		const button = ref.button.current;

		if (checkValidation === true) {
			button.classList.add("on");
			button.classList.add("clickable");
		} else {
			button.classList.remove("on");
			button.classList.remove("clickable");
		}
	};

	/**
	 * 추가 후 ModalAlert 확인 클릭 시
	 * @param {Event} e
	 */
	const handlerClickAddModalOK = async (e) => {
		modalStack.removeModal("modalCommonAlert");
		navigate(pathList.myPage.addressBook);
	};

	/**
	 * 추가 모달에서 확인 클릭 시
	 * @param {Event} e
	 */
	const handlerClickAddOKFromModal = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await modalStack.removeModal("modalCommonAlert");
		await modalStack.removeModal(props.id);
		if (props.afterAddAddressFromModal) await props.afterAddAddressFromModal();
	};

	/**
	 * 수정 모달에서 확인 클릭 시
	 * @param {Event} e
	 */
	const handlerClickModifyOKFromModal = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await modalStack.removeModal("modalCommonAlert");
		await modalStack.removeModal(props.id);
		if (props.afterAddAddressFromModal) await props.afterAddAddressFromModal();
	};

	/**
	 * 저장 버튼 클릭 시
	 * @param {Event} e
	 */
	const handlerClickSave = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const ct = e.currentTarget;

		if (ct.classList.contains("on")) {
			if (defaultValue.addressNo) {
				//수정;
				await setInnerSpinner();
				await requestModifyAddress()
					.then(async (data) => {
						if (props.isModal === true) {
							await modalStack.addModal({
								id: "modalCommonAlert",
								type: "alert",
								component: <ModalCommonAlert />,
								text: textInfo.alert.modified,
								button: {
									ok: {
										text: textInfo.button.confirm,
										fn: handlerClickModifyOKFromModal,
										// , fn: handlerClickAddModalOK
									},
								},
							});
						} else {
							await modalStack.addModal({
								id: "modalCommonAlert",
								type: "alert",
								component: <ModalCommonAlert />,
								text: textInfo.alert.modified,
							});
						}
					})
					.catch(async (error) => {
						window.linmeLog.log(error);
						await modalStack.addModal({
							id: "modalCommonAlert",
							type: "alert",
							component: <ModalCommonAlert />,
							text: error?.response?.data?.message || textInfo.alert.error,
						});
					})
					.finally(() => {
						removeInnerSpinner();
					});
			} else {
				//추가;
				await setInnerSpinner();
				await requestAddAddress()
					.then((data) => {
						if (data) {
							if (props.isModal === true) {
								modalStack.addModal({
									id: "modalCommonAlert",
									type: "alert",
									component: <ModalCommonAlert />,
									text: textInfo.alert.added,
									button: {
										ok: {
											text: textInfo.button.confirm,
											fn: handlerClickAddOKFromModal,
											// , fn: handlerClickAddModalOK
										},
									},
								});
							} else {
								modalStack.addModal({
									id: "modalCommonAlert",
									type: "alert",
									component: <ModalCommonAlert />,
									// , text: textInfo.alert.added
									html: htmlInfo.alert.added,
									button: {
										ok: {
											text: textInfo.button.confirm,
											fn: handlerClickAddModalOK,
										},
									},
								});
							}
						}
					})
					.catch(async (error) => {
						window.linmeLog.log(error);
						await modalStack.addModal({
							id: "modalCommonAlert",
							type: "alert",
							component: <ModalCommonAlert />,
							text: error?.response?.data?.message || textInfo.alert.error,
						});
					})
					.finally(() => {
						removeInnerSpinner();
					});
			}
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 배송지 추가
	 * @returns
	 */
	const requestAddAddress = async () => {
		const d = {
			nickname: ref.nickname.current.value, //배송지명;
			name: ref.name.current.value, //수취인;
			phone: ref.phone.current.value, //연락처;
			postcode: ref.postcode.current.value, //우편번호;
			address1: ref.address1.current.value, //주소;
			address2: ref.address2.current.value, //상세주소;
			memo: ref.memo.current.type === "hidden" ? deliveryMemo : ref.memo.current.value, //배송요청사항;
			isCheckRep: ref.checkDefaultAddress.current.checked, //기본 배송지 설정;
		};

		const result = await addAddress(d);
		if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
			return result.data;
		} else {
			window.linmeLog.log(result);
			return false;
		}
	};

	/**
	 * 배송지 수정
	 * @returns
	 */
	const requestModifyAddress = async () => {
		try {
			const d = {
				addressNo: defaultValue.addressNo,
				nickname: ref.nickname.current.value, //배송지명;
				name: ref.name.current.value, //수취인;
				phone: ref.phone.current.value, //연락처;
				postcode: ref.postcode.current.value, //우편번호;
				address1: ref.address1.current.value, //주소;
				address2: ref.address2.current.value, //상세주소;
				memo: ref.memo.current.type === "hidden" ? deliveryMemo : ref.memo.current.value, //배송요청사항;
				isCheckRep: ref.checkDefaultAddress.current.checked, //기본 배송지 설정;
			};

			const result = await modifyAddress(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				return result.data;
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (error) {
			window.linmeLog.log(error);
			return false;
		}
	};

	/**
	 * 배송요청사항 선택 후 적용
	 * @param {Object} d
	 */
	const setSelectedDeliveryMemo = async (o) => {
		setDeliveryMemo(o.value);
	};

	/**
	 * validation
	 * @returns {Boolean}
	 */
	const validation = async () => {
		if (
			(await validationNickname()) === true &&
			(await validationName()) === true &&
			(await validationPhone()) === true &&
			(await validationAddress1()) === true &&
			(await validationAddress2()) === true &&
			(await validationMemo()) === true
		)
			return true;
		else return false;
	};

	/**
	 * 배송지명 validation
	 * @returns {Boolean}
	 */
	const validationNickname = () => {
		const ct = ref.nickname.current;
		const elUserInput = ct.closest(".userInput");
		const elGuideWarn = elUserInput.querySelector(".inputGuideWarn");

		if (!ct.value) {
			elGuideWarn.innerText = textInfo.warn.required;
			return false;
		}

		if (!REG_EXP.address.nickname.test(ct.value)) {
			elGuideWarn.innerText = textInfo.myPage.myAddressBook.add.warn.nickname.character;
			return false;
		}

		elGuideWarn.innerText = "";
		return true;
	};

	/**
	 * 수취인 validation
	 * @returns {Boolean}
	 */
	const validationName = () => {
		const ct = ref.name.current;
		const elUserInput = ct.closest(".userInput");
		const elGuideWarn = elUserInput.querySelector(".inputGuideWarn");

		if (!ct.value) {
			elGuideWarn.innerText = textInfo.warn.required;
			return false;
		}

		if (!REG_EXP.address.name.test(ct.value)) {
			elGuideWarn.innerText = textInfo.myPage.myAddressBook.add.warn.name.character;
			return false;
		}

		elGuideWarn.innerText = "";
		return true;
	};

	/**
	 * 연락처 validation
	 * @returns {Boolean}
	 */
	const validationPhone = () => {
		const ct = ref.phone.current;
		const elUserInput = ct.closest(".userInput");
		const elGuideWarn = elUserInput.querySelector(".inputGuideWarn");

		if (!ct.value) {
			elGuideWarn.innerText = textInfo.warn.required;
			return false;
		}

		if (!REG_EXP.member.phone.test(ct.value)) {
			elGuideWarn.innerText = textInfo.member.warn.phone.character;
			return false;
		}

		elGuideWarn.innerText = "";
		return true;
	};

	/**
	 * 주소 validation
	 * @returns {Boolean}
	 */
	const validationAddress1 = () => {
		const ct = ref.address1.current;
		const elUserInput = ct.closest(".userInput");
		const elGuideWarn = elUserInput.querySelector(".inputGuideWarn");

		if (!ct.value) {
			elGuideWarn.innerText = textInfo.warn.required;
			return false;
		}

		elGuideWarn.innerText = "";
		return true;
	};

	/**
	 * 상세주소 validation
	 * @returns {Boolean}
	 */
	const validationAddress2 = () => {
		const ct = ref.address2.current;
		const elUserInput = ct.closest(".userInput");
		const elGuideWarn = elUserInput.querySelector(".inputGuideWarn");

		if (!ct.value) {
			elGuideWarn.innerText = textInfo.warn.required;
			return false;
		}

		if (!REG_EXP.address.address2.test(ct.value)) {
			elGuideWarn.innerText = textInfo.myPage.myAddressBook.add.warn.address2.character;
			return false;
		}

		elGuideWarn.innerText = "";
		return true;
	};

	/**
	 * 배송 요청사항 (직접입력) validation
	 * @returns {Boolean}
	 */
	const validationMemo = () => {
		const ct = ref.memo.current;
		const elUserInput = ct.closest(".userInput");
		const elGuideWarn = elUserInput.querySelector(".inputGuideWarn");

		if (ct.value && !REG_EXP.address.memo.test(ct.value)) {
			elGuideWarn.innerText = textInfo.myPage.myAddressBook.add.warn.memo.character;
			return false;
		}

		elGuideWarn.innerText = "";
		return true;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		const setData = async () => {
			if (props.isModal === true) {
				if (props.data) {
					const deliveryMemo = props?.data?.memo;
					const addressMemo = DELIVERY_MEMO_LIST.map((memo) => memo.value);
					const isHasMemo = addressMemo.includes(deliveryMemo);
					const isDirectMemo = !isHasMemo && deliveryMemo !== "";

					if (isHasMemo) {
						setDeliveryMemo(deliveryMemo);
					} else if (isDirectMemo) {
						setDeliveryMemo(DELIVERY_MEMO_DIRECT_INPUT);
					} else {
						setDeliveryMemo("");
					}

					setDefaultValue({
						addressNo: props.data.addressNo,
						nickname: props.data.nickname,
						name: props.data.name,
						phone: props.data.phone,
						postcode: props.data.postcode,
						address1: props.data.address1,
						address2: props.data.address2,
						memo: props.data.memo,
						isCheckRep: props.data.isCheckRep,
					});
				}
			} else {
				setDefaultValue({
					addressNo: location.state?.addressNo,
					nickname: location.state?.nickname,
					name: location.state?.name,
					phone: location.state?.phone,
					postcode: location.state?.postcode,
					address1: location.state?.address1,
					address2: location.state?.address2,
					memo: location.state?.memo,
					isCheckRep: location.state?.isCheckRep,
				});
			}
		};
		setData();
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<>
			<div className={"title " + (props && props.addressType ? props.addressType : "")}>
				{textInfo.myPage.myAddressBook.add.title}
			</div>
			<div className="addressBookAddCover">
				<ul className="deliveryInfoList">
					<li className="deliveryInfoItem">
						<div className="label required">{textInfo.myPage.myAddressBook.add.nickname}</div>
						<div className="userInput">
							<input
								type="text"
								id="addressBookNickname"
								placeholder={textInfo.myPage.myAddressBook.add.nickname}
								onChange={(e) => handlerChangeInput(e)}
								ref={ref.nickname}
								defaultValue={defaultValue.nickname}
							/>
							<div className="guide">
								<div className="inputGuideWarn"></div>
							</div>
						</div>
					</li>
					<li className="deliveryInfoItem">
						<div className="label required">{textInfo.myPage.myAddressBook.add.name}</div>
						<div className="userInput">
							<input
								type="text"
								id="addressBookName"
								placeholder={textInfo.myPage.myAddressBook.add.placeholder.name}
								onChange={(e) => handlerChangeInput(e)}
								ref={ref.name}
								defaultValue={defaultValue.name}
							/>
							<div className="guide">
								<div className="inputGuideWarn"></div>
							</div>
						</div>
					</li>
					<li className="deliveryInfoItem">
						<div className="label required">{textInfo.myPage.myAddressBook.add.phone}</div>
						<div className="userInput phone">
							<input
								type="text"
								id="addressBookPhone"
								placeholder={textInfo.myPage.myAddressBook.add.placeholder.phone}
								onChange={(e) => handlerChangeInput(e)}
								onKeyUp={(e) => handlerKeyUpPhone(e)}
								ref={ref.phone}
								defaultValue={defaultValue.phone}
							/>
							<div className="guide">
								<div className="inputGuideWarn"></div>
							</div>
						</div>
					</li>
					<li className="deliveryInfoItem">
						<div className="label required">{textInfo.myPage.myAddressBook.add.address}</div>
						<div className="userInput">
							<div>
								<input
									type="text"
									id="addressBookPostcode"
									ref={ref.postcode}
									className="postcode"
									name="postcode"
									readOnly
									placeholder={textInfo.myPage.myAddressBook.add.placeholder.postcode}
									defaultValue={defaultValue.postcode}
									onChange={(e) => handlerChangeInput(e)}
								/>
								<button
									className="button findPostCode"
									onClick={(e) =>
										execDaumPostCode(
											{
												postcode: "addressBookPostcode",
												address1: "addressBookAddress1",
												address2: "addressBookAddress2",
												addressExtra: "addressBookAddressExtra",
											},
											window.document.getElementById("layerPostCodeContent"),
											window.document.getElementById("layerPostCode")
										)
									}
								>
									{textInfo.myPage.myAddressBook.add.postcode}
								</button>
							</div>
							<input
								type="text"
								id="addressBookAddress1"
								ref={ref.address1}
								className="address"
								name="address1"
								readOnly={true}
								placeholder={textInfo.myPage.myAddressBook.add.placeholder.address1}
								defaultValue={defaultValue.address1}
								onChange={(e) => handlerChangeInput(e)}
							/>
							<input
								type="text"
								ref={ref.address2}
								id="addressBookAddress2"
								className="address2"
								name="address2"
								readOnly={true}
								placeholder={textInfo.myPage.myAddressBook.add.placeholder.address2}
								defaultValue={defaultValue.address2}
								onChange={(e) => handlerChangeInput(e)}
							/>
							<input
								type="hidden"
								id="addressBookAddressExtra"
								className="addressExtra"
								name="addressExtra"
							/>
							<div className="guide">
								<div className="inputGuideWarn"></div>
							</div>
						</div>
					</li>
					<li className="deliveryInfoItem">
						<div className="label">{textInfo.myPage.myAddressBook.add.memo}</div>
						<div className="userInput">
							<CustomSelectBox
								options={DELIVERY_MEMO_LIST}
								value={deliveryMemo}
								placeholder="배송시 요청사항을 선택해주세요."
								onChange={(item) => setSelectedDeliveryMemo(item)}
							/>
							<input
								type={
									deliveryMemo === DELIVERY_MEMO_DIRECT_INPUT ||
									defaultValue?.value === DELIVERY_MEMO_DIRECT_INPUT
										? "text"
										: "hidden"
								}
								id="addressBookMemo"
								className="memo"
								name="directInput"
								ref={ref.memo}
								defaultValue={defaultValue.memo}
								placeholder={textInfo.myPage.myAddressBook.add.memoList.directInput}
								onChange={(e) => handlerChangeInput(e)}
							/>
							<div className="guide">
								<div className="inputGuideWarn"></div>
							</div>
						</div>
					</li>
					<li className="deliveryInfoItem">
						<div className="checkDefaultAddressCover">
							<input
								type="checkbox"
								className="checkDefaultAddress"
								name="checkDefaultAddress"
								id="checkDefaultAddress"
								ref={ref.checkDefaultAddress}
								defaultChecked={defaultValue.isCheckRep}
							/>
							<label htmlFor="checkDefaultAddress">
								{textInfo.myPage.myAddressBook.add.setDefaultAddress}
							</label>
						</div>
					</li>
				</ul>
				<div
					className={"button saveAddress " + (defaultValue.addressNo ? "clickable on" : "")}
					onClick={(e) => handlerClickSave(e)}
					ref={ref.button}
				>
					{textInfo.button.save}
				</div>
			</div>
		</>
	);
};

export default MyPageAddressBookAdd;
