/**
 * 상품 상세 - 리뷰
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { memo, useRef } from "react";

import StarScore from "src/pages/component/review/starScore";
import PhotoReviewList from "src/pages/component/review/photoReviewList";
import ReviewList from "src/pages/component/review/reviewList";
import WriteReviewButton from "./detail/review/writeReviewButton";
import useMemberReviewCheck from "src/hooks/product/useMemberReviewCheck";

const textInfo = require("src/data/textInfo.json");

const ProductTabContentProductReview = (props) => {
	const { productNo, review } = props;
	const { data: checkData, isReviewWritable } = useMemberReviewCheck({ productNo });
	const ref = {
		aTag: useRef(null),
		content: useRef(null),
	};

	return (
		<div className="productReview" ref={ref.content}>
			<a
				id="tabContentProductReview"
				ref={ref.aTag}
				href="{()=>false}"
				onClick={(e) => e.preventDefault()}
				style={{ cursor: "unset" }}
			>
				<div className="title">
					{textInfo.product.review.title}
					<span className="reviewCount">{review.count.toLocaleString()}</span>
				</div>
				<div className="reviewInfo">
					<div className="scoreStatus">
						<span className="nowScore">{review?.averageRate}</span>/
						<span className="perfectScore">{textInfo.product.detail.reviewPerfectScore}</span>
					</div>
					<StarScore {...{ score: review.averageRate }} />
				</div>
				{/* 제품후기 작성하기 */}
				{isReviewWritable && <WriteReviewButton checkData={checkData} {...props} />}
				<PhotoReviewList productNo={productNo} />
				<ReviewList {...props} from={"productDetail"} />
			</a>
		</div>
	);
};

export default memo(ProductTabContentProductReview);
