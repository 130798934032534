/**
 * 로그인
 */

import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/**
 * 장바구니 개수 조회
 * @returns
 */
export const getCartCount = async () => {
	const url = `${API_PATHS.CART_COUNT}`;
	try {
		const result = await api.get(url);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 쿠폰 개수 조회
 * @returns
 */
export const getCouponCount = async () => {
	const d = { offset: 1, page: 1 };
	const url = `${API_PATHS.MEMBER_COUPON}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 등급별 혜택 조회
 * @returns
 */
export const getLevelBenefit = async () => {
	const url = `${API_PATHS.MEMBER_LEVEL_BENEFIT}`;
	try {
		const result = await api.get(url);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 회원 정보 조회
 * @returns
 */
export const getProfile = async () => {
	const url = `${API_PATHS.MEMBER_PROFILE}`;
	try {
		const result = await api.get(url);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 리뷰 개수 조회
 * @returns
 */
export const getReviewCount = async () => {
	const d = { offset: 1, page: 1 };
	const url = `${API_PATHS.MEMBER_REVIEW}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 각종 통계 조회 ( 주문 수, 리뷰 수, 쿠폰 수 )
 * @returns
 */
export const getStatistics = async () => {
	const url = `${API_PATHS.MEMBER_STATISTICS}`;
	try {
		const result = await api.get(url);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 추가 정보 입력 ( 소셜 등 회원의 경우 본인인증, 닉네임 등 )
 * @param {Object} d
 * <code>
 * {
 * 		address1: ""
 * 		, address2: ""
 * 		, birth: ""
 * 		, ci: ""
 * 		, gender: ""
 * 		, isCheckAdEmail: false
 * 		, isCheckAdSms: false
 * 		, name: ""
 * 		, nickname: ""
 * 		, phone: ""
 * 		, postcode: ""
 * }
 * </code>
 * @returns
 */
export const setBaseInfoAfterSignup = async (d) => {
	const url = `${API_PATHS.MEMBER_BASE_INFO}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 이메일 로그인
 * @param {Object} d
 * <code>
 * {
 * 		email: ""
 * 		, password: ""
 * }
 * </code>
 */
export const signIn = async (d) => {
	const url = `${API_PATHS.MEMBER_SIGN_IN}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 소셜 로그인
 * @param {Object} d
 * @returns
 */
export const signInSocial = async (d) => {
	const url = `${API_PATHS.MEMBER_SIGN_IN_SOCIAL}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
