/**
 * 마이페이지 - 등급별 혜택 안내
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useAuth from "src/hooks/useAuth";
import { API_RESULT_STATUS } from "src/data/constEnum";
import { getLevelBenefit } from "src/api/member/signIn";

import MoveToBack from "src/pages/component/moveToBack";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myPage/levelInfo.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const MyPageLevelInfo = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const auth = useAuth();
	const navigate = useNavigate();
	const [data, setData] = useState(null);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 뒤로가기 화살표 클릭 시 ( 모바일 )
	 * @param {Event} e
	 */
	const handlerClickMoveToBack = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		navigate(-1);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	const requestGetLevelBenefit = async () => {
		try {
			const result = await getLevelBenefit();
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				await setData(result.data);
				return result.data;
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (error) {
			window.linmeLog.log(error);
			return false;
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		const getData = async () => {
			await requestGetLevelBenefit();
		};
		getData();
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<>
			<div className="container underTopMenu membershipLevelInfo">
				<div className="contents">
					<div className="titleWrapper">
						<MoveToBack />
						<div className="title">{textInfo.myPage.levelInfo.title}</div>
					</div>
					<div className="contentLeft">
						<div className={"memberCard " + (data && data.name ? data.name.toLowerCase() : "")}>
							<span className="yourLevel">
								<span className="nickname">
									{auth.isLogin && auth.user && auth.user.nickname ? auth.user.nickname : "-"}
								</span>
								{textInfo.myPage.levelInfo.yourLevel}
							</span>
							<span className="memberLevel">{data && data.name ? data.name : ""}</span>
							<span className="lastQuarterText">{textInfo.myPage.levelInfo.lastQuarter}</span>
							<span className="lastQuarterAmount">
								{data && data.lastQuarter ? data.lastQuarter : 0}
								<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
							</span>
						</div>
						<ul className="memberLevelDescriptionList">
							<li className="memberLevelDescriptionItem">
								{textInfo.myPage.levelInfo.description.levelDescription1}
								<span>{textInfo.myPage.levelInfo.description.levelDescription1_1}</span>
							</li>
							<li className="memberLevelDescriptionItem">
								{textInfo.myPage.levelInfo.description.levelDescription2}
							</li>
						</ul>
					</div>
					<div className="contentRight">
						<div className="benefit">{textInfo.myPage.levelInfo.benefit}</div>
						<ul className="levelsBenefitList">
							{data && data.levels ? (
								data.levels.map((level, index) => (
									<li key={index} className={"levelsBenefitItem " + level.name.toLowerCase()}>
										<div className="levelName">{level.name}</div>
										<div className="levelMainBenefit">
											구매금액의 <span>{level.benefit.reward}%</span>적립
										</div>
										<div className="levelCondition">{level.benefit.condition}</div>
										<ul className="levelCouponList">
											{level.benefit.coupon ? (
												level.benefit.coupon.map((coupon, i) => (
													<li key={i} className="levelCouponItem">
														{coupon}
													</li>
												))
											) : (
												<></>
											)}
										</ul>
									</li>
								))
							) : (
								<></>
							)}
						</ul>
						<ul className="memberLevelGuideList">
							{textInfo.myPage.levelInfo.membershipGuide.map((guide, index) => (
								<li key={index} className="memberLevelGuideItem">
									{guide}
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};

export default MyPageLevelInfo;
