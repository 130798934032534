/**
 * 기획전
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */
import { Link } from "react-router-dom";
import Image from "src/pages/component/common/image";
import TopicHeader from "src/pages/component/topicSection/topicHeader";
import TopicSectionContainer from "src/pages/component/topicSection/topicSectionContainer";
import TopicProductList from "src/pages/component/topicSection/topicProductList";
import Timer from "src/pages/component/topicSection/timer";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

const pathList = require("src/path/pathJSON.json");

const TopicPeriod = (props) => {
	const { topic } = props;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	if (!topic) return;

	return (
		<TopicSectionContainer>
			<TopicHeader topic={topic} href={`${pathList.product.topicPeriod}?listNo=${topic?.listNo}`} />
			<TopicProductList topicProductList={topic?.items || []} />
		</TopicSectionContainer>
	);
};

export default TopicPeriod;
