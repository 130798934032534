/**
 * 결제창
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { isMobile } from "react-device-detect";

import useModalStack from "src/hooks/useModalStack";
import { API_RESULT_STATUS, PAYMENT_RESULT_STATUS } from "src/data/constEnum";
import { removeInnerSpinner } from "src/utils/linmeDisplay";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/payment/modal/paymentIframe.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */

const PaymentIframe = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const [searchParams, setSearchParams] = useSearchParams();
	const modalStack = useModalStack();
	const [url, setUrl] = useState(null);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 결제 후처리
	 * @param {Object} result
	 * @returns
	 */
	const afterPayment = async (result) => {
		if (result.origin === window.origin) return false;

		await modalStack.forceUpdate();

		try {
			if (result.data && result.data === PAYMENT_RESULT_STATUS.CLOSE) {
				//사용자 닫기;
				await removeInnerSpinner();
				await modalStack.removeModal(props.id);
			} else if (
				result.data &&
				result.data.result &&
				result.data.result === PAYMENT_RESULT_STATUS.SUCCESS
			) {
				if (props.setDataAfterPayment) {
					props.setDataAfterPayment(result.data);
				} else {
					await removeInnerSpinner();
					await modalStack.removeModal(props.id);
				}
			} else if (
				result.data &&
				result.data.result &&
				result.data.result === PAYMENT_RESULT_STATUS.FAIL
			) {
				if (props.setDataAfterPayment) {
					props.setDataAfterPayment(result.data);
				} else {
					await removeInnerSpinner();
					await modalStack.removeModal(props.id);
				}
			} else {
				await removeInnerSpinner();
				await modalStack.removeModal(props.id);
			}
		} catch (err) {
			window.linmeLog.error(err);
			await modalStack.removeModal(props.id);
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		window.addEventListener("message", afterPayment);

		if (props.url) setUrl(props.url);
		else {
			setUrl(searchParams.get("url"));
		}

		return () => {
			window.removeEventListener("message", afterPayment);
		};
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className={`paymentProcessWrapper ${isMobile ? "mobile" : ""}`}>
			<div className="paymentProcessContainer">
				{url ? (
					<iframe src={url} title="payment" name="linmeServicePayment" target="_blank" />
				) : (
					<></>
				)}
			</div>
		</div>
	);
};

export default PaymentIframe;
