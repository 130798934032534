/**
 * 환불/교환/취소에 대한 주문 상품 확인 컴포넌트
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import React, { memo } from "react";
import { Link } from "react-router-dom";
import { CLAIM_TYPE, PRODUCT_OPTIONS_TYPE } from "src/data/constEnum";
import Image from "src/pages/component/common/image";
import NumberStepper from "src/pages/component/common/numberStepper";
import ProductOptionBox from "src/pages/component/order/productOptionBox";
import { getProductDetailType } from "src/utils/linmeSearch";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/claim/checkOrderProduct.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */

const pathList = require("src/path/pathJSON.json");
const textInfo = require("src/data/textInfo.json");

const CheckOrderProduct = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;
	const { claimType, dataList, handlerChangeOptionQuantity } = props;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="checkOrderProduct">
			<div className="checkOrderProductTitle">
				{props.claimType === "CANCEL"
					? textInfo.orderCancel.items
					: props.claimType === "REFUND"
					? textInfo.orderRefund.items
					: textInfo.orderChange.items}
			</div>
			{/* PC & TABLET */}
			<div className="checkOrderProductList grid">
				{/* 표 제목 */}
				<div className="checkOrderProductItem title">
					{textInfo.payment.checkOrderProduct.productInfo}
				</div>
				<div className="checkOrderProductItem title">
					{textInfo.payment.checkOrderProduct.salePrice}
				</div>
				<div className="checkOrderProductItem title">
					{textInfo.payment.checkOrderProduct.price}
				</div>
				<div className="checkOrderProductItem title">
					{textInfo.payment.checkOrderProduct.deliveryPrice}
				</div>
				{/* 표 데이터 */}
				{dataList && dataList.length ? (
					dataList.map((item, index) => {
						const detailLink =
							pathList?.product?.detail +
							`${getProductDetailType(item?.product.type)}/` +
							item?.product?.productNo;
						return (
							<React.Fragment key={index}>
								<div className="checkOrderProductItem value productInfo">
									{/* 이미지 */}
									<Link to={detailLink}>
										<div className="imageWrapper">
											<Image src={item.product.thumbnail.webPath} alt={item.name} />
										</div>
									</Link>
									{/* 정보 */}
									<div className="infoWrapper">
										<div className="brandName">
											<Link to={detailLink}>{item.product.brand.name}</Link>
										</div>
										<div className="productName">
											<Link to={detailLink}>{item.product.name}</Link>
										</div>
										{/* 옵션 */}
										<div className="option">
											{item?.product?.option?.type === PRODUCT_OPTIONS_TYPE.REQUIRED &&
											item?.product?.option?.categoryName1 === "" &&
											item?.product?.option?.optionName1 === "" ? (
												<div className="quantity">
													{item.quantity}
													<span className="quantityUnit">{textInfo.common.quantityUnit}</span>
												</div>
											) : claimType === CLAIM_TYPE.REFUND ? (
												<>
													<div className="optionTitle">
														{item?.product?.option?.type === PRODUCT_OPTIONS_TYPE.REQUIRED
															? textInfo.product.detail.option.optionType.required
															: textInfo.product.detail.option.optionType.additional}
													</div>
													<div className="optionValue refund">
														<div>
															<span>{item.product.option.optionFullName}</span>
															<br />
															<span className="productOptionPrice">
																{(
																	item.product.price + item.product.option.addPrice
																).toLocaleString()}
																<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
															</span>
														</div>
														<NumberStepper
															min={1}
															max={item.quantity}
															count={item.quantity}
															setCount={handlerChangeOptionQuantity}
															targetIndex={index}
														/>
													</div>
												</>
											) : (
												<>
													<div className="optionTitle">
														{item?.product?.option?.type === PRODUCT_OPTIONS_TYPE.REQUIRED
															? textInfo.product.detail.option.optionType.required
															: textInfo.product.detail.option.optionType.additional}
													</div>
													<div className="optionValue">
														<ProductOptionBox
															option={item.product.option}
															productData={item?.product}
															quantity={item.quantity}
														/>
													</div>
												</>
											)}
										</div>
									</div>
								</div>
								{/* 판매가 */}
								<div className="checkOrderProductItem value sellingPrice">
									{(item.product.price + item.product.option.addPrice).toLocaleString()}
									<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
								</div>
								{/* 구매가 */}
								<div className="checkOrderProductItem value price">
									{/* 원가 */}
									{item.product.price !== item.product.salePrice && (
										<span className="basePrice">
											{(
												(item.product.price + item?.product?.option.addPrice) *
												item.quantity
											).toLocaleString()}
											<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
										</span>
									)}
									{/* 할인가 */}
									<span className="salePrice">
										{(
											(item.product.salePrice + item.product.option.addPrice) *
											item.quantity
										).toLocaleString()}
										<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
									</span>
								</div>
								{/* 배송비 */}
								<div className="checkOrderProductItem value deliveryPrice">
									{item?.product?.delivery?.deliveryPrice.toLocaleString()}
									<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
								</div>
							</React.Fragment>
						);
					})
				) : (
					<></>
				)}
			</div>
			{/* MOBILE & MOBILE MINI */}
			<div className="checkOrderProductList flex">
				{/* 표 제목 */}
				<div className="checkOrderProductItem title">
					{textInfo.payment.checkOrderProduct.productInfo}
				</div>
				{/* 표 데이터 */}
				{dataList && dataList.length ? (
					dataList.map((item, index) => {
						const detailLink =
							pathList?.product?.detail +
							`${getProductDetailType(item?.product.type)}/` +
							item?.product?.productNo;
						return (
							<div className="checkOrderProductItem value" key={index}>
								{/* 이미지 */}
								<Link to={detailLink}>
									<div className="imageWrapper">
										<Image src={item.product.thumbnail.webPath} alt={item.name} />
									</div>
								</Link>
								{/* 정보 */}
								<div className="infoWrapper">
									<div className="brandName">
										<Link to={detailLink}>{item.product.brand.name}</Link>
									</div>
									<div className="productName">
										<Link to={detailLink}>{item.product.name}</Link>
									</div>
									{/* 옵션 */}
									<div className="option">
										{item?.product?.option?.type === PRODUCT_OPTIONS_TYPE.REQUIRED &&
										item?.product?.option?.categoryName1 === "" &&
										item?.product?.option?.optionName1 === "" ? (
											<div className="quantity">
												{item.quantity}
												<span className="quantityUnit">{textInfo.common.quantityUnit}</span>
											</div>
										) : claimType === CLAIM_TYPE.REFUND ? (
											<>
												<div className="optionTitle">
													{item?.product?.option?.type === PRODUCT_OPTIONS_TYPE.REQUIRED
														? textInfo.product.detail.option.optionType.required
														: textInfo.product.detail.option.optionType.additional}
												</div>
												<div className="optionValue refund">
													<div>
														<span>{item.product.option.optionFullName}</span>
														<br />
														<span className="productOptionPrice">
															{(item.product.price + item.product.option.addPrice).toLocaleString()}
															<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
														</span>
													</div>
													<NumberStepper
														min={1}
														max={item.quantity}
														count={item.quantity}
														setCount={handlerChangeOptionQuantity}
														targetIndex={index}
													/>
												</div>
											</>
										) : (
											<>
												<div className="optionTitle">
													{item?.product?.option?.type === PRODUCT_OPTIONS_TYPE.REQUIRED
														? textInfo.product.detail.option.optionType.required
														: textInfo.product.detail.option.optionType.additional}
												</div>
												<div className="optionValue">
													<ProductOptionBox
														option={item.product.option}
														productData={item?.product}
														quantity={item.quantity}
													/>
												</div>
											</>
										)}
									</div>
									{/* 배송비 */}
									<div className="deliveryPrice">
										배송비: {item?.product?.delivery?.deliveryPrice.toLocaleString()}
										<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
									</div>
									{/* 구매가 */}
									<div className="price">
										{/* 할인가 */}
										<span className="salePrice">
											{(
												(item.product.salePrice + item.product.option.addPrice) *
												item.quantity
											).toLocaleString()}
											<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
										</span>
										{/* 원가 */}
										{item.product.price !== item.product.salePrice && (
											<span className="basePrice">
												{(
													(item.product.price + item?.product?.option.addPrice) *
													item.quantity
												).toLocaleString()}
												<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
											</span>
										)}
									</div>
								</div>
							</div>
						);
					})
				) : (
					<></>
				)}
			</div>
		</div>
	);
};

export default memo(CheckOrderProduct);
