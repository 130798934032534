/**
 * 마이린미 메인 - MBTI 테스트 질문 카드
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect } from "react";

import { COLOR } from "src/data/constEnum";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myLinme/testQuestionCard.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const TestQuestionCard = (props) => {
	const { data, questionNumber } = props;

	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 선택 변경 시
	 * @param {Event} e
	 * @param {Number} i
	 */
	const onChangeSelect = (item) => {
		if (props.afterSelected) props.afterSelected(data, item?.rsvVal2, questionNumber);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	const makeSelectBox = () => {
		return data?.status.map((item, index) => {
			return (
				<div className="selectCover" key={index}>
					<input
						type="radio"
						name={"select" + props.questionNumber}
						id={"select" + props.questionNumber + "_" + (index + 1)}
						value={index + 1}
						onChange={(e) => onChangeSelect(item)}
					/>
					<label htmlFor={"select" + props.questionNumber + "_" + (index + 1)} />
				</div>
			);
		});

		// const result = [];
		// let i = 0;
		// const iLen = 7;

		// for ( ; i < iLen; ++i ) {
		// 	result.push( <div className="selectCover" key={i}>
		// 		<input type="radio"
		// 			name={"select" + props.questionNumber}
		// 			id={"select" + props.questionNumber + "_" + ( i + 1 )}
		// 			value={( i + 1 )}
		// 			onChange={( e ) => onChangeSelect( e, i )}
		// 		/>
		// 		<label htmlFor={"select" + props.questionNumber + "_" + ( i + 1 )} />
		// 	</div> );
		// }
		// return result;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className={"questionCard " + (props.active ? "active" : "")}>
			<div className="question">
				<span className="questionNumber">{questionNumber}.</span>
				<span className="questionContent">{data?.content}</span>
			</div>
			<div className="selectAnswerList">{makeSelectBox()}</div>
		</div>
	);
};
export default TestQuestionCard;
