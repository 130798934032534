/**
 * 마이린미 - 랜딩
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useState } from "react";
import useAuth from "src/hooks/useAuth";

import Intro from "src/pages/main/intro";
import HealthTypeCard from "src/pages/component/myLinme/healthTypeCard";
import ShouldBeLogin from "src/pages/component/shouldBeLogin";
import RecommendedProductList from "src/pages/component/myLinme/recommendedProduct";
import SupplementManageCard from "src/pages/component/myLinme/alarm/supplementManageCard";
import MyLinmeMenuSlider from "src/pages/myLinme/menuSlider";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myLinme/index.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
// const textInfo = require( "src/data/textInfo.json" );

const MyLinmePage = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const auth = useAuth();

	const [isFinishMBTI, setIsFinishMBTI] = useState(false);
	const [isFinishSurvey, setIsFinishSurvey] = useState(false);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<>
			<div className="container main">
				<Intro {...{ isMain: false }} />
			</div>
			<div className="container myLinme">
				<div className="contents">
					{auth.isLogin === true ? (
						<>
							<div className="myHealthInfo">
								{/* <MyLinmeHealthStatus/> */}
								<HealthTypeCard
									setIsFinishMBTI={setIsFinishMBTI}
									setIsFinishSurvey={setIsFinishSurvey}
								/>
								<MyLinmeMenuSlider isFinishMBTI={isFinishMBTI} isFinishSurvey={isFinishSurvey} />
							</div>
							<SupplementManageCard />
							<RecommendedProductList />
						</>
					) : (
						<div className="shouldBeLoginCover">
							<ShouldBeLogin />
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default MyLinmePage;
