/**
 * 로그인
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import useAuth from "src/hooks/useAuth";
import useModalStack from "src/hooks/useModalStack";
import { handlerClickTogglePwType } from "src/utils/linmeMember";
import {
	removeInnerSpinner,
	setFooterFixed,
	setFooterRelative,
	setInnerSpinner,
} from "src/utils/linmeDisplay";
import { REG_EXP } from "src/data/constEnum";
import { apple } from "src/utils/social";
import { encryptPassword } from "src/utils/linmeSession";

import Image from "src/pages/component/common/image";
import ModalCommonAlert from "src/pages/common/modalAlert";
import { COOKIE_KEY } from "src/data/key";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/member/login.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const Login = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const auth = useAuth();
	const location = useLocation();
	const navigate = useNavigate();
	const modalStack = useModalStack();
	const cookieName = COOKIE_KEY.REMEMBER_EMAIL;
	const [cookies, setCookie, removeCookie] = useCookies([cookieName]);
	const [email, setEmail] = useState("");
	const [isRemember, setIsRemember] = useState(false);
	const ref = {
		email: useRef(null),
		pw: useRef(null),
		isRemember: useRef(null),
	};
	const modalId = {
		alert: "modalCommonAlert",
	};

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 아이디 저장 변경 시
	 * @param {Event} e
	 */
	const handlerChangeIsRemember = (e) => {
		const ct = e.currentTarget;
		setIsRemember(ct.checked);
		if (ct.checked) {
			setCookie(cookieName, ref.email.current.value);
		} else {
			removeCookie(cookieName);
		}
	};

	/**
	 * 로그인 클릭 시
	 * @param {Event} e
	 */
	const handlerClickLogin = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const check = await validation();
		if (check) {
			await setInnerSpinner();
			if (ref.isRemember.current.checked) {
				await setCookie(cookieName, ref.email.current.value);
			}

			const hashedPassword = await encryptPassword(ref.pw.current.value);

			await auth.login(ref.email.current.value, hashedPassword).then(async (isLogin) => {
				await removeInnerSpinner();
				if (isLogin === true) {
					if (!props.isModal) {
						if (location.state?.lastPath) navigate(location.state?.lastPath);
						else navigate(pathList.main);
					} else {
						if (props.afterLogin) await props.afterLogin();
						else if (props.close) await props.close();
					}
				} else if (isLogin.message) {
					let o = {
						id: modalId.alert,
						type: "alert",
						component: <ModalCommonAlert />,
					};
					if (isLogin.type === "text")
						o.text =
							isLogin.message.indexOf("Exception") > -1
								? textInfo.alert.pleaseCheckYourData
								: isLogin.message;
					else o.html = isLogin.message;
					modalStack.addModal(o);
				}
			});
		}
	};

	/**
	 * 아이디 찾기로 이동
	 * @param {Event} e
	 */
	const handlerClickMoveToFindId = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (props.isModal) props.close();
		navigate(pathList.member.findId);
	};

	/**
	 * 비밀번호 찾기로 이동
	 * @param {Event} e
	 */
	const handlerClickMoveToFindPw = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (props.isModal) props.close();
		navigate(pathList.member.findPw);
	};

	/**
	 * 회원가입으로 이동
	 * @param {Event} e
	 */
	const handlerClickMoveToSignup = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (props.isModal) props.close();
		navigate(pathList.member.signUp);
	};

	/**
	 * SNS 로그인 클릭 시
	 * @param {Event} e
	 */
	const handlerClickSocialLogin = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const elLi = e.target.closest("li");
		if (!elLi) return false;

		const snsType = elLi.classList[elLi.classList.length - 1].toUpperCase();
		if (snsType === "KAKAO") {
			window.open(
				pathList.member.socialLogin.kakao,
				"socialLoginKakao",
				"width=400,height=800,top=20,left=100"
			);
		} else if (snsType === "NAVER") {
			window.open(
				pathList.member.socialLogin.naver,
				"socialLoginNaver",
				"width=620,height=800,top=20,left=100"
			);
		} else if (snsType === "APPLE") {
			const state = `${Math.random().toString(36).substring(2, 15)}${Date.now()}`;
			await window.AppleID.auth.init({
				clientId: `${process.env.REACT_APP_SOCIAL_APPLE_CLIENT_ID}`,
				scope: `${process.env.REACT_APP_SOCIAL_APPLE_SCOPE}`,
				redirectURI: `${process.env.REACT_APP_SOCIAL_APPLE_REDIRECT_URI}`,
				state: state,
				nonce: state,
				usePopup: true,
			});
			try {
				const res = await window.AppleID.auth.signIn();
				if (res && res.authorization && res.authorization.id_token) {
					await requestSocialLogin(res.authorization.id_token, snsType);
				}
				// window.linmeLog.log(res);
			} catch (error) {
				window.linmeLog.log(error);
			}
		}
	};

	/**
	 * 비밀번호 키 업 이벤트 발생 시
	 * @param {KeyboardEvent} e
	 */
	const handlerKeyUpUserPw = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		let key = e.key || e.keyCode;

		if (key === "Enter" || key === 13) {
			//엔터 입력 시;
			handlerClickLogin(e);
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 소셜 로그인 후 데이터 확인
	 * @param {Object} result
	 */
	const afterSocialLogin = async (result) => {
		if (result.data.access_token && result.data.snsType) {
			await requestSocialLogin(result.data.access_token, result.data.snsType);
		}
	};

	/**
	 * 소셜 로그인 후 access_token으로 로그인
	 * @param {String} access_token
	 * @param {String} type
	 */
	const requestSocialLogin = async (access_token, type) => {
		await auth.loginSocial(access_token, type.toUpperCase()).then(async (isLogin) => {
			if (isLogin === true) {
				if (!props.isModal) {
					navigate(pathList.main);
				} else {
					if (props.close) await props.close();
					if (props.afterLogin) await props.afterLogin();
				}
			} else if (isLogin.message) {
				modalStack.addModal({
					id: modalId.alert,
					type: "alert",
					component: <ModalCommonAlert />,
					text: isLogin.message,
				});
			}
		});
	};

	/**
	 * validation
	 * @returns {Boolean}
	 */
	const validation = () => {
		if (validationEmail() && validationPw()) {
			return true;
		}
		return false;
	};

	/**
	 * validation 이메일
	 * @returns {Boolean}
	 */
	const validationEmail = () => {
		const ct = ref.email.current;

		if (!ct.value) {
			modalStack.addModal({
				id: modalId.alert,
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.pleaseInputEmail,
			});
			return false;
		}

		if (ct.value.match(REG_EXP.blank)) {
			//공백 포함 시;
			modalStack.addModal({
				id: modalId.alert,
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.noUseBlankEmail,
			});
			ct.value = "";
			return false;
		}

		if (!REG_EXP.member.email.test(ct.value)) {
			modalStack.addModal({
				id: modalId.alert,
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.checkCharacterEmail,
			});
			ct.value = "";
			return false;
		}

		return true;
	};

	/**
	 * validation 비밀번호
	 * @returns {Boolean}
	 */
	const validationPw = () => {
		const ct = ref.pw.current;

		if (!ct.value) {
			modalStack.addModal({
				id: modalId.alert,
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.pleaseInputPassword,
			});
			return false;
		}

		if (ct.value.match(REG_EXP.blank)) {
			//공백 포함 시;
			modalStack.addModal({
				id: modalId.alert,
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.noUseBlankPw,
			});
			ct.value = "";
			return false;
		}

		if (!REG_EXP.member.pw.test(ct.value)) {
			modalStack.addModal({
				id: modalId.alert,
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.checkCharacterPw,
			});
			ct.value = "";
			return false;
		}

		return true;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		// if ( !props.isModal ) {
		// 	//푸터 위치 관련;
		// 	setFooterFixed();
		// }
		//메시지 이벤트 설정;
		window.addEventListener("message", afterSocialLogin);
		//쿠키 설정;
		if (cookies[cookieName] !== undefined) {
			setEmail(cookies[cookieName]);
			ref.email.current.value = cookies[cookieName];
			setIsRemember(true);
			ref.isRemember.current.checked = true;
		}
		//애플아이디 관련 스크립트 삽입;
		apple.insertScript();
		return () => {
			// if ( !props.isModal ) {
			// 	//푸터 위치 관련 해제;
			// 	setFooterRelative();
			// }
			//메시지 이벤트 해제;
			window.removeEventListener("message", afterSocialLogin);
		};
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="container underTopMenu login">
			<div className="contents">
				<div className="loginCover">
					<div className="title">{textInfo.member.signIn.title}</div>
					<ul className="inputList">
						<li className="userInput">
							<input
								className="userID"
								name="userID"
								id="userID"
								type="text"
								placeholder={textInfo.member.signIn.placeholder.email}
								defaultValue={email}
								ref={ref.email}
							/>
						</li>
						<li className="userInput">
							<input
								className="userPW"
								name="userPW"
								id="userPW"
								type="password"
								placeholder={textInfo.member.signIn.placeholder.pw}
								onKeyUp={(e) => {
									handlerKeyUpUserPw(e);
								}}
								ref={ref.pw}
							/>
							<div className="pwShowHide close" onClick={(e) => handlerClickTogglePwType(e)} />
						</li>
					</ul>
					<div className="otherCover">
						<span>
							<input
								className="checkSaveUserId"
								name="checkSaveUserId"
								id="checkSaveUserId"
								type="checkbox"
								defaultChecked={isRemember}
								onChange={(e) => handlerChangeIsRemember(e)}
								ref={ref.isRemember}
							/>
							<label htmlFor="checkSaveUserId">{textInfo.member.signIn.saveId}</label>
						</span>
						<ul className="findAndSignIn">
							<li className="clickable findId" onClick={(e) => handlerClickMoveToFindId(e)}>
								{textInfo.member.signIn.findId}
							</li>
							<li>｜</li>
							<li className="clickable findPW" onClick={(e) => handlerClickMoveToFindPw(e)}>
								{textInfo.member.signIn.findPw}
							</li>
							<li>｜</li>
							<li className="clickable signIn" onClick={(e) => handlerClickMoveToSignup(e)}>
								{textInfo.member.signIn.signUp}
							</li>
						</ul>
					</div>
					<div className="buttonCover">
						<div className="buttonLogin" onClick={(e) => handlerClickLogin(e)}>
							{textInfo.button.signIn}
						</div>
					</div>
					<div className="snsLoginCover">
						<div className="snsLoginTitle">{textInfo.member.signIn.signInSNS}</div>
						<ul className="snsLogin" onClick={(e) => handlerClickSocialLogin(e)}>
							<li className="clickable kakao">
								<Image
									className="snsLoginKakao"
									srcSet="
										../../images/login/sns_kakao.png 1x
										, ../../images/login/sns_kakao@2x.png 2x
										, ../../images/login/sns_kakao@3x.png 3x"
									alt={textInfo.member.signIn.sns.kakao.description}
								/>
								<div className="snsName">{textInfo.member.signIn.sns.kakao.title}</div>
							</li>
							<li className="clickable naver">
								<Image
									className="snsLoginNaver"
									srcSet="
										../../images/login/sns_naver.png 1x
										, ../../images/login/sns_naver@2x.png 2x
										, ../../images/login/sns_naver@3x.png 3x"
									alt={textInfo.member.signIn.sns.naver.description}
								/>
								<div className="snsName">{textInfo.member.signIn.sns.naver.title}</div>
							</li>
							<li className="clickable apple">
								<Image
									className="snsLoginApple"
									srcSet="
										../../images/login/sns_apple.png 1x
										, ../../images/login/sns_apple@2x.png 2x
										, ../../images/login/sns_apple@3x.png 3x"
									alt={textInfo.member.signIn.sns.apple.description}
								/>
								<div className="snsName">{textInfo.member.signIn.sns.apple.title}</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;
