/**
 * 섭취관리 알람 목록
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/ko"; //한글로 설정;

import { API_RESULT_STATUS } from "src/data/constEnum";
import useAuth from "src/hooks/useAuth";
import { getFormatApmNextHour, getFormatTimeNextHour } from "src/utils/date";
import { getMainDay } from "src/api/myLinme/main";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myLinme/supplementManageCard.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const DailyAlarmMain = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const { targetDate, alarmData } = props;

	const auth = useAuth();
	const navigate = useNavigate();

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * + 클릭 시 설정화면으로 이동
	 * @param {Event} e
	 */
	const handlerClickSupplementSetting = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		navigate(pathList.myLinme.alarm);
	};

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="todaySupplementListContainer">
			{targetDate ? (
				<div className="date">
					<span>{moment(targetDate).format("DD")}</span>
					&nbsp;&nbsp;&nbsp;{moment(targetDate).format("dddd")}
				</div>
			) : (
				<></>
			)}
			<div
				className="button clickable settings"
				onClick={(e) => handlerClickSupplementSetting(e)}
			/>
			<ul className="intakeList">
				{alarmData && alarmData?.length > 0 ? (
					<>
						{alarmData.map((item, index) => {
							let cName = "";
							if (item?.actionFlag === "true") cName = "checked"; // 섭취완료
							else if (item?.actionFlag === "false") cName = "skip"; // 건너뛰기
							else cName = "expected"; // 액션없음
							return (
								<li key={index} className={`intakeItem ${cName}`}>
									<div className="supplementName">{item.productName}</div>
									<div className="supplementTime">
										<span className="apm">{moment(item.time, "HH:mm").format("A")}</span>
										<span className="time">{moment(item.time, "HH:mm").format("hh:mm")}</span>
									</div>
								</li>
							);
						})}
					</>
				) : (
					<li className="intakeItem expected">
						<div className="supplementName">
							{textInfo.myLinme.supplementManage.pleaseEnterTodaySupplement}
						</div>
						<div className="supplementTime">
							<span className="apm">{getFormatApmNextHour(null, null, 1)}</span>
							<span className="time">{getFormatTimeNextHour(null, null, 1)}</span>
						</div>
					</li>
				)}
			</ul>
		</div>
	);
};
export default DailyAlarmMain;
