import apiMyLinme from "src/api/axiosMyLinme";
import API_PATHS from "src/api/apiPaths";

/**
 * 심플 건강 체크 리스트 조회
 * @returns
 */
export const getSimpleCategory = async () => {
	const url = `${API_PATHS.SIMPLE_CATEGORY}`;
	try {
		const result = await apiMyLinme.get(url);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 심플 건강 체크 선택
 * @param {Object} d
 * <code>
 * {
 *     "subCode" : "0002",
 *     "codeGroupId" : "0004",
 *     "check" : true
 * }
 * </code>
 * @returns
 */
export const checkSimpleCategory = async (d) => {
	const url = `${API_PATHS.SIMPLE_CHECK}`;
	try {
		const result = await apiMyLinme.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 심플 건강 상품 추천
 * @returns
 */
export const getSimpleProduct = async () => {
	const url = `${API_PATHS.SIMPLE_PRODUCT}`;
	try {
		const result = await apiMyLinme.get(url);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 심플 건강 상품 추천 (페이징)
 * @returns
 */
export const getSimpleProductDetail = async (d) => {
	const url = `${API_PATHS.SIMPLE_PRODUCT_DETAIL}`;
	try {
		const result = await apiMyLinme.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
