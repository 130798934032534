// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.categoryListMobile_categoryContainer__DckVt {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/styles/categoryListMobile.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;AACD","sourcesContent":[".categoryContainer {\n\tdisplay: flex;\n\tflex-direction: column;\n\twidth: auto;\n\theight: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"categoryContainer": `categoryListMobile_categoryContainer__DckVt`
};
export default ___CSS_LOADER_EXPORT___;
