/**
 * 마이린미 - 건강상태 설문 메인
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myLinme/survey/survey.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const MyLinmeSurveyMain = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="container underTopMenu myLinmeContainer survey">
			<div className="contents">
				<div className="surveyContainer">
					<div className="surveyContainerHeader">
						<div></div>
						<div className="closeButton" onClick={props?.handlerClickClose} />
					</div>
					<div className="mainContainer">
						<div className="surveyTitle">
							<div>{textInfo.myLinme.survey.title}</div>
							<div className="bold">{textInfo.myLinme.survey.title2}</div>
						</div>
						<div className="mainImage" />
					</div>
					<div className="startButton" onClick={props?.handlerClickNext}>
						{textInfo.myLinme.survey.button.start}
						<div className="startButtonDesc" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default MyLinmeSurveyMain;
