/**
 * 포토리뷰 이미지 컨테이너
 */

import { useCallback, useRef, useState, memo } from "react";
import Swiper from "src/pages/component/swiper";
import { SwiperSlide } from "swiper/react";
import { Thumbs } from "swiper/modules";
import Image from "src/pages/component/common/image";
import { IMAGE_ERROR_SRC } from "src/data/constEnum";
import styles from "src/styles/product/detail/review/reviewImagePaging.module.scss";

const ReviewImagePaging = (props) => {
	const imageList = props.images || [];
	const [thumbsSwiper, setThumbsSwiper] = useState(null);
	const swiperRef = useRef(null);

	const handlePrev = useCallback(() => {
		if (swiperRef.current && swiperRef.current.swiper) {
			swiperRef.current.swiper.slidePrev();
		}
	}, []);

	const handleNext = useCallback(() => {
		if (swiperRef.current && swiperRef.current.swiper) {
			swiperRef.current.swiper.slideNext();
		}
	}, []);
	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className={styles.container}>
			<div className={styles.mainContainer}>
				<Swiper
					ref={swiperRef}
					spaceBetween={10}
					thumbs={{ swiper: thumbsSwiper }}
					modules={[Thumbs]}
					className={styles.main}
				>
					{imageList?.map((image) => {
						return (
							<SwiperSlide key={image.fileNo}>
								{image && image.webPath ? (
									<Image src={image.webPath} alt={2} />
								) : (
									<Image src={IMAGE_ERROR_SRC.large} alt={2} />
								)}
							</SwiperSlide>
						);
					})}
				</Swiper>
				<div className={styles.navigationContainer}>
					<div className={styles.prev} onClick={handlePrev} />
					<div className={styles.next} onClick={handleNext} />
				</div>
			</div>

			<Swiper
				onSwiper={setThumbsSwiper}
				spaceBetween={5}
				slidesPerView={3.5}
				modules={[Thumbs]}
				className={styles.thumbs}
			>
				{imageList?.map((image) => {
					return (
						<SwiperSlide key={image.fileNo} style={{ width: "auto" }}>
							{image && image.webPath ? (
								<Image src={image.webPath} alt={2} />
							) : (
								<Image src={IMAGE_ERROR_SRC.large} alt={2} />
							)}
						</SwiperSlide>
					);
				})}
			</Swiper>
		</div>
	);
};

export default memo(ReviewImagePaging);
