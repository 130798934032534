/**
 * 환불/교환에 대한 상품 수거 방법 컴포넌트
 */

import React from "react";
import { RETURN_DELIVERY_TYPE, RETURN_DELIVERY_LIST } from "src/data/constEnum";
import CustomSelectBox from "src/pages/component/common/customSelectBox";

import "src/styles/claim/pickupMethod.scss";

const textInfo = require("src/data/textInfo.json");
const htmlInfo = require("src/data/htmlInfo.json");

const PickupMethod = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const {
		refObject,
		handlerChangePickupMethod,
		handlerClickOpenModalVirtualNumber,
		handlerUseVirtualNumber,
		handlerChangeAddress,
		deliveryCompanyList,
		deliveryCompanyNo,
		setSelectedDeliveryCompany,
	} = props;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="pickupMethod">
			{/* 타이틀 */}
			<div className="title">{textInfo.claim.pickup.type}</div>
			{/* 표 리스트 */}
			<div className="pickupMethodList">
				{/* 수거 방법 */}
				<div className="pickupMethodItem type">
					{/* 라벨 */}
					<div className="label">{textInfo.claim.pickup.selectType}</div>
					{/* 데이터 */}
					<div className="data">
						{/* 수거 방법 유형 */}
						{RETURN_DELIVERY_LIST.map((type, index) => (
							<span className="typeWrapper" key={index}>
								<input
									className="type"
									name="deliveryType"
									id={"deliveryType" + type.value.toLowerCase()}
									type="radio"
									value={type.value}
									defaultChecked={type.value === RETURN_DELIVERY_TYPE.COMPANY}
									onChange={(e) => handlerChangePickupMethod(e)}
								/>
								<label className="type" htmlFor={"deliveryType" + type.value.toLowerCase()}>
									{type.label}
								</label>
							</span>
						))}
					</div>
				</div>
				{/* 상품 회수지 */}
				<div className="pickupMethodItem pickupLocation on" ref={refObject.pickupAddress}>
					{/* 라벨 */}
					<div className="label">{textInfo.claim.pickup.address}</div>
					{/* 데이터 */}
					<div className="data">
						<div className="info">
							{/* 기본 정보 */}
							<span ref={refObject.address.name}></span>
							<span ref={refObject.address.postcode}></span>
							<span ref={refObject.address.address1}></span>
							<span ref={refObject.address.address2}></span>
							<span ref={refObject.address.phone}></span>
							{/* 안심번호 서비스 */}
							<div className="virtualNumberService">
								<span
									className="virtualNumberServiceText"
									onClick={(e) => handlerClickOpenModalVirtualNumber(e)}
								>
									{textInfo.payment.deliveryInfo.safeNumberService}
								</span>
								<input
									className="useVirtualNumber"
									name="useVirtualNumberForOrderChange"
									id="useVirtualNumberForOrderChange"
									type="checkbox"
									defaultChecked={false}
									onChange={(e) => {
										handlerUseVirtualNumber(e);
									}}
									ref={refObject.checkVirtualNumber}
								/>
								<label htmlFor="useVirtualNumberForOrderChange">&nbsp;</label>
							</div>
							{/* 안내문구 */}
							<div
								className="guide"
								dangerouslySetInnerHTML={{ __html: htmlInfo.claim.pickupGuide.guide1 }}
							/>
						</div>
						<div className="button">
							{/* 변경하기 버튼 */}
							<div
								className="changeDeliveryAddress"
								onClick={(e) => handlerChangeAddress(e, "pickup")}
							>
								{textInfo.button.changeInfo}
							</div>
						</div>
					</div>
				</div>
				{/* 반송 정보 */}
				<div className="pickupMethodItem deliveryInfoDetail" ref={refObject.deliveryInfoDetail}>
					{/* 라벨 */}
					<div className="label">{textInfo.claim.pickup.deliveryInfoDetail}</div>
					{/* 데이터 */}
					<div className="data">
						{/* 택배사 */}
						<div className="pickupCompanyName">{textInfo.claim.pickup.companyName}</div>
						{deliveryCompanyList !== null && (
							<div className="companyList" ref={refObject.deliveryCompany}>
								<CustomSelectBox
									options={deliveryCompanyList}
									value={deliveryCompanyNo}
									placeholder={textInfo.claim.pickup.pleaseSelectDeliveryCompany}
									onChange={(item) => setSelectedDeliveryCompany(item)}
								/>
							</div>
						)}
						{/* 운송장 번호 */}
						<div className="pickupInvoiceNo">{textInfo.claim.pickup.invoiceNo}</div>
						<input
							type="text"
							className="invoiceNo"
							name="invoiceNo"
							ref={refObject.invoiceNo}
							placeholder={textInfo.claim.pickup.placeholder.invoiceNo}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PickupMethod;
