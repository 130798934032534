import clsx from "clsx";
import styles from "src/styles/rightArrowButton.module.scss";

const RightArrowButton = ({ text, className, ...rest }) => {
	return (
		<span className={clsx([styles.showMoreText, className])} {...rest}>
			{text}
			<svg xmlns="http://www.w3.org/2000/svg" width="6" height="12" viewBox="0 0 6 12">
				<path
					d="m14.37 9.182-3.839-3.934a.822.822 0 0 0-1.179 0 .872.872 0 0 0 0 1.217l3.848 3.926a.872.872 0 0 1 0 1.217l-3.848 3.925a.873.873 0 0 0 0 1.213.823.823 0 0 0 1.184 0l3.839-3.934a2.616 2.616 0 0 0-.005-3.63z"
					transform="translate(-9.104 -4.999)"
				/>
			</svg>
		</span>
	);
};

export default RightArrowButton;
