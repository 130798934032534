/**
 * 상품 상세 - 필수표기정보 ( 상품고시정보 )
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import React from "react";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/product/modal/requiredNotationInformation.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );
const textInfo = require("src/data/textInfo.json");

const RequiredNotationInformation = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="requiredNotationInformationContainer">
			<div className="title">{textInfo.button.requiredNotationInformationTitle}</div>
			<div className="dataContainer">
				<ul className="dataCover">
					{props.legal && props.legal.length ? (
						props.legal.map((item, index) => (
							<li className="dataItem" key={index}>
								<div className="label">{item.name}</div>
								<div className="data">{item.content}</div>
							</li>
						))
					) : (
						<></>
					)}
				</ul>
			</div>
		</div>
	);
};

export default RequiredNotationInformation;
