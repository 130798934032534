/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { useEffect } from "react";

import ScrollToTop from "src/pages/component/common/scrollToTop";
import { LOCAL_STORAGE_KEY } from "src/data/key";
import { sendNaverPV } from "src/utils/linmeNaverScript";

// ----------;
// Layout;
import LayoutLinme from "src/pages/layout/layoutLinme";
import LayoutBlank from "src/pages/layout/layoutBlank";
import LayoutMyPage from "src/pages/layout/layoutMyPage";
import LayoutReward from "src/pages/layout/layoutReward";

// ----------;
// 장바구니, 주문/결제;
import Cart from "src/pages/cart/cart";
import Payment from "src/pages/payment/payment";
import PaymentComplete from "src/pages/payment/paymentComplete";
import PaymentFailure from "src/pages/payment/paymentFailure";
import PaymentIframe from "src/pages/payment/modal/paymentIframe";

// ----------;
// 클레임 - 주문취소;
import ClaimOrderCancel from "src/pages/claim/orderCancel";

// ----------;
// 클레임 - 주문교환;
import ClaimOrderChange from "src/pages/claim/orderChange";

// ----------;
// 클레임 - 주문환불;
import ClaimOrderRefund from "src/pages/claim/orderRefund";

// ----------;
// 로그인/회원가입;
import FindId from "src/pages/member/findId";
import FindPw from "src/pages/member/findPw";
import Login from "src/pages/member/login";
import SignUp from "src/pages/member/signUp";
import NeedLogin from "src/pages/member/needLogin";
import AuthVerifyIframe from "src/pages/member/modal/authVerifyIframe";
// ----------;
// 소셜로그인;
import SocialLoginKakao from "src/pages/member/modal/socialLoginKakao";
import RedirectSocialLoginKakao from "src/pages/member/redirectSocialLoginKakao";
import SocialLoginNaver from "src/pages/member/modal/socialLoginNaver";
import RedirectSocialLoginNaver from "src/pages/member/redirectSocialLoginNaver";

// ----------;
// 랜딩/상품진열;
import Main from "src/pages/main";
import ProductDisplay from "src/pages/product/productDisplay";
import CategoryProductDisplay from "src/pages/product/categoryProductDisplay";
import MainProductDisplay from "src/pages/product/mainProductDisplay";
import TopicTimerProductDisplay from "src/pages/product/topicTimerProductDisplay";
import TopicPeriodProductDisplay from "src/pages/product/topicPeriodProductDisplay";
import ProductDetail from "src/pages/product/productDetail";

// ----------;
// 건강고민;
import HealthIssueRecommendedProductDisplay from "src/pages/product/healthIssueRecommendedProductDisplay";

// ----------;
// 마이린미;
import MyLinme from "src/pages/myLinme/myLinmePage";
import MyLinmeRecommendedProductList from "src/pages/myLinme/recommendedProductList";
import MyLinmeSurvey from "src/pages/myLinme/survey/index";
import MyLinmeSurveyResult from "src/pages/myLinme/survey/result";
import MyLinmeSimpleHealthCheck from "src/pages/myLinme/simpleHealthCheck";
import MyLinmeSimpleHealthCheckProduct from "src/pages/myLinme/simpleHealthCheckProductList";
import MyLinmeAlarm from "src/pages/myLinme/alarm/myLinmePage";
import MyLinmeMBTITest from "src/pages/myLinme/MBTI/MBTITest";
import MyLinmeMBTIResult from "src/pages/myLinme/MBTI/MBTIResult";

// ----------;
// 리워드;
import Reward from "src/pages/reward";
import RewardDetail from "src/pages/reward/detail";
import RewardHistory from "src/pages/reward/history";

// ----------;
// MyPage;
import MyPageIndex from "src/pages/myPage";
import MyPageProfile from "src/pages/myPage/profile";
import MyPageLevelInfo from "src/pages/myPage/levelInfo";

import MyPageMyInfo from "src/pages/myPage/myInfo";
import MyPageMyInfoModify from "src/pages/myPage/myInfo/myInfoModify";
import MyPageMyInfoPasswordModify from "src/pages/myPage/myInfo/passwordModify";
import MyPageMyInfoNotification from "src/pages/myPage/myInfo/notification";
import MyPageMyInfoWithdrawal from "src/pages/myPage/myInfo/withdrawal";

import MyPageInquiry from "src/pages/myPage/inquiry";
import MyPageProductInquiry from "src/pages/myPage/productInquiry";
import MyPageRefundAccount from "src/pages/myPage/refundAccount ";
import MyPageNotice from "src/pages/myPage/notice";
import MyPageFAQ from "src/pages/myPage/faq";
import MyPageAddressBook from "src/pages/myPage/addressBook";
import MyPageAddressBookAdd from "src/pages/myPage/addressBookAdd";
import MyPageShoppingOrder from "src/pages/myPage/shopping/order";
import MyPageShoppingClaim from "src/pages/myPage/shopping/claim";
import MyPageShoppingOrderDetail from "src/pages/myPage/shopping/orderDetail";
import MyPageCoupon from "src/pages/myPage/coupon";
import MyPageReview from "src/pages/myPage/review";

// ----------;
// 검색 결과;
import SearchResult from "src/pages/search/searchResult";

// ----------;
// NotFound;
import NotFound from "src/pages/notFound";

// 개인정보처리방침;
import Privacy from "src/pages/privacy";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */

const pathList = require("src/path/pathJSON.json");

// auth 체크 HOC
const withAuth = (WrappedComponent) => {
	const AuthComponent = (props) => {
		// 로컬 스토리지에서 토큰 가져오기
		const token = window.localStorage.getItem(`${LOCAL_STORAGE_KEY.ACCESS_TOKEN}`);

		// 토큰이 없으면 로그인 페이지로 리다이렉트
		if (!token) {
			return <Navigate to="/login" />;
		}

		return <WrappedComponent {...props} />;
	};

	return AuthComponent;
};

// 기존 컴포넌트에 HOC 적용
// LayoutLinme
const RewardDetailWithAuth = withAuth(RewardDetail);
const CartWithAuth = withAuth(Cart);
const PaymentWithAuth = withAuth(Payment);
const PaymentCompleteWithAuth = withAuth(PaymentComplete);
const PaymentFailureWithAuth = withAuth(PaymentFailure);
const ClaimOrderCancelWithAuth = withAuth(ClaimOrderCancel);
const ClaimOrderChangeWithAuth = withAuth(ClaimOrderChange);
const ClaimOrderRefundWithAuth = withAuth(ClaimOrderRefund);
const RewardWithAuth = withAuth(Reward);
const RewardHistoryWithAuth = withAuth(RewardHistory);
const MyLinmeWithAuth = withAuth(MyLinme);
const MyLinmeRecommendedProductListWithAuth = withAuth(MyLinmeRecommendedProductList);
const MyLinmeSurveyWithAuth = withAuth(MyLinmeSurvey);
const MyLinmeSurveyResultWithAuth = withAuth(MyLinmeSurveyResult);
const MyLinmeSimpleHealthCheckWithAuth = withAuth(MyLinmeSimpleHealthCheck);
const MyLinmeSimpleHealthCheckProductWithAuth = withAuth(MyLinmeSimpleHealthCheckProduct);
const MyLinmeAlarmWithAuth = withAuth(MyLinmeAlarm);
const MyLinmeMBTITestWithAuth = withAuth(MyLinmeMBTITest);
const MyLinmeMBTIResultWithAuth = withAuth(MyLinmeMBTIResult);

// LayoutMyPage
const LayoutMyPageWithAuth = withAuth(LayoutMyPage);

const PathList = () => {
	const location = useLocation();

	useEffect(() => {
		// 페이지가 변경될 때마다 네이버 광고 PV(page view) 이벤트 전송
		sendNaverPV();
	}, [location]);

	return (
		<>
			<ScrollToTop />
			<Routes>
				<Route element={<LayoutLinme />}>
					<Route path={pathList.main} index element={<Main />} />
					<Route path={pathList.member.login} element={<Login />} />
					<Route path={pathList.member.findId} element={<FindId />} />
					<Route path={pathList.member.findPw} element={<FindPw />} />
					<Route path={pathList.member.needLogin} element={<NeedLogin />} />
					<Route path={pathList.member.signUp} element={<SignUp />} />
					<Route path={pathList.myLinme.index} element={<MyLinmeWithAuth />} /> {/* 마이린미 */}
					{/* 마이린미 - 추천 상품 목록 */}
					<Route
						path={pathList.myLinme.recommendedProductList}
						element={<MyLinmeRecommendedProductListWithAuth />}
					/>
					{/* 마이린미 - 건강상태 분석 */}
					<Route path={pathList.myLinme.survey} element={<MyLinmeSurveyWithAuth />} />
					{/* 마이린미 - 건강상태 분석 */}
					<Route path={pathList.myLinme.surveyResult} element={<MyLinmeSurveyResultWithAuth />} />
					{/* 마이린미 - 심플 건강 체크 */}
					<Route
						path={pathList.myLinme.simpleHealthCheck}
						element={<MyLinmeSimpleHealthCheckWithAuth />}
					/>
					{/* 마이린미 - 심플 건강 체크 추천 상품 목록 */}
					<Route
						path={pathList.myLinme.simpleHealthCheckProduct}
						element={<MyLinmeSimpleHealthCheckProductWithAuth />}
					/>
					{/* 마이린미 - 나의 섭취일정 */}
					<Route path={pathList.myLinme.alarm} element={<MyLinmeAlarmWithAuth />} />
					{/* 마이린미 - 건강 MBTI 테스트 */}
					<Route path={pathList.myLinme.MBTITest} element={<MyLinmeMBTITestWithAuth />} />
					{/* 마이린미 - 건강 MBTI 테스트 결과 */}
					<Route path={pathList.myLinme.MBTIResult} element={<MyLinmeMBTIResultWithAuth />} />
					{/* 리워드 상세 */}
					<Route path={pathList.reward.detailOriginal} element={<RewardDetailWithAuth />} />
					{/* 상품 목록 */}
					<Route path={pathList.product.list} element={<ProductDisplay />} />
					{/* 카테고리 상품 목록 */}
					<Route path={pathList.product.category} element={<CategoryProductDisplay />} />
					{/* 신상품 상품 목록 */}
					<Route path={pathList.product.new} element={<MainProductDisplay />} />
					{/* 베스트 상품 목록 */}
					<Route path={pathList.product.best} element={<MainProductDisplay />} />
					{/* 특가상품 상품 목록 */}
					<Route path={pathList.product.special} element={<MainProductDisplay />} />
					{/* 기획전 - 타임특가 상품 목록 */}
					<Route path={pathList.product.topicTimer} element={<TopicTimerProductDisplay />} />
					{/* 기획전 - 상품 목록 */}
					<Route path={pathList.product.topicPeriod} element={<TopicPeriodProductDisplay />} />
					{/* 건강고민 선택 시 추천 상품 목록 */}
					<Route
						path={pathList.product.healthIssueRecommended}
						element={<HealthIssueRecommendedProductDisplay />}
					/>
					{/* 상품 상세 */}
					<Route path={pathList.product.detailOriginal} element={<ProductDetail />} />
					{/* 상품 상세 */}
					<Route path={pathList.product.sample} element={<ProductDetail />} />
					{/* 장바구니 */}
					<Route path={pathList.cart} element={<CartWithAuth />} />
					{/* 주문/결제 */}
					<Route path={pathList.payment.index} element={<PaymentWithAuth />} />
					{/* 결제 완료 */}
					<Route path={pathList.payment.complete} element={<PaymentCompleteWithAuth />} />
					{/* 결제 실패 */}
					<Route path={pathList.payment.failure} element={<PaymentFailureWithAuth />} />
					{/* 주문 취소 요청*/}
					<Route path={pathList.claim.cancel} element={<ClaimOrderCancelWithAuth />} />
					{/* 주문 교환 요청 */}
					<Route path={pathList.claim.change} element={<ClaimOrderChangeWithAuth />} />
					{/* 주문 환불 요청 */}
					<Route path={pathList.claim.refund} element={<ClaimOrderRefundWithAuth />} />
					{/* 검색 결과 */}
					<Route path={pathList.search.result} element={<SearchResult />} />
					{/* 등급별 혜택 안내 */}
					<Route path={pathList.myPage.level} element={<MyPageLevelInfo />} />
				</Route>

				{/* 리워드 */}
				<Route element={<LayoutReward />}>
					{/* 리워드 */}
					<Route path={pathList.reward.index} element={<RewardWithAuth />} />
					{/* 리워드 사용내역 */}
					<Route path={pathList.reward.history} element={<RewardHistoryWithAuth />} />
				</Route>

				{/* 마이페이지 */}
				<Route element={<LayoutMyPageWithAuth />}>
					{/* 마이페이지 진입 */}
					<Route path={pathList.myPage.index} element={<MyPageIndex />} />
					{/* 프로필 관리 */}
					<Route path={pathList.myPage.profile} element={<MyPageProfile />} />
					{/* 내 정보관리 진입 */}
					<Route path={pathList.myPage.myInfo} element={<MyPageMyInfo />} />
					{/* 회원 정보 수정 */}
					<Route path={pathList.myPage.myInfoModify} element={<MyPageMyInfoModify />} />
					{/* 비밀번호 수정 */}
					<Route
						path={pathList.myPage.myInfoPasswordModify}
						element={<MyPageMyInfoPasswordModify />}
					/>
					{/* 알림 설정 */}
					<Route path={pathList.myPage.myInfoNotification} element={<MyPageMyInfoNotification />} />
					{/* 회원 탈퇴 */}
					<Route path={pathList.myPage.myInfoWithdrawal} element={<MyPageMyInfoWithdrawal />} />
					{/* 1:1 문의 내역 */}
					<Route path={pathList.myPage.inquiry} element={<MyPageInquiry />} />
					{/* 상품 문의 내역 */}
					<Route path={pathList.myPage.productInquiry} element={<MyPageProductInquiry />} />
					{/* 환불계좌관리 */}
					<Route path={pathList.myPage.refundAccount} element={<MyPageRefundAccount />} />
					{/* 공지사항 */}
					<Route path={pathList.myPage.notice} element={<MyPageNotice />} />
					{/* FAQ */}
					<Route path={pathList.myPage.faq} element={<MyPageFAQ />} />
					{/* 배송지관리 */}
					<Route path={pathList.myPage.addressBook} element={<MyPageAddressBook />} />
					{/* 배송지 추가/수정 */}
					<Route path={pathList.myPage.addressBookAdd} element={<MyPageAddressBookAdd />} />
					{/* 쇼핑내역 - 주문/배송 */}
					<Route path={pathList.myPage.shoppingOrder} element={<MyPageShoppingOrder />} />
					{/* 쇼핑내역 - 취소/교환/환불 */}
					<Route path={pathList.myPage.shoppingClaim} element={<MyPageShoppingClaim />} />
					{/* 쇼핑내역 상세 */}
					<Route path={pathList.myPage.detailOriginal} element={<MyPageShoppingOrderDetail />} />
					{/* 내 쿠폰 */}
					<Route path={pathList.myPage.coupon} element={<MyPageCoupon />} />
					{/* 내 리뷰 */}
					<Route path={pathList.myPage.review} element={<MyPageReview />} />
				</Route>

				<Route element={<LayoutBlank />}>
					{/* 본인인증 */}
					<Route path={pathList.member.verify} element={<AuthVerifyIframe />} />
					{/* 카카오 로그인 */}
					<Route path={pathList.member.socialLogin.kakao} element={<SocialLoginKakao />} />
					<Route
						path={pathList.member.socialRedirect.kakao}
						element={<RedirectSocialLoginKakao />}
					/>
					{/* 네이버 로그인 */}
					<Route path={pathList.member.socialLogin.naver} element={<SocialLoginNaver />} />
					<Route
						path={pathList.member.socialRedirect.naver}
						element={<RedirectSocialLoginNaver />}
					/>
					<Route path={pathList.payment.process} element={<PaymentIframe />} />
					{/* 개인정보 처리방침 */}
					<Route path={pathList.privacy} element={<Privacy />} />
					<Route path={pathList.error} element={<NotFound />} />
				</Route>
				<Route path="*" element={<Navigate replace to={pathList.error} />} />
			</Routes>
		</>
	);
};

export default PathList;
