/**
 * 로그인 필요 시
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import Intro from "../main/intro";
import ShouldBeLogin from "../component/shouldBeLogin";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "./../../styles/member/needLogin.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */

const NeedLogin = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<>
			<div className="container main">
				<Intro {...{ isMain: false }} />
			</div>
			<div className="container underTopMenu needLogin">
				<div className="contents">
					<ShouldBeLogin />
				</div>
			</div>
		</>
	);
};

export default NeedLogin;
