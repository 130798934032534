import { useCallback } from "react";
import { requestViewBanner, requestClickBanner } from "src/api/etc/banner";

const useBannerAction = (bannerList) => {
	// 배너 노출시 서버에 뷰 api 호출
	const onViewBanner = useCallback(
		async (bannerIndex) => {
			const viewBanner = bannerList[bannerIndex];
			if (!viewBanner) return;

			try {
				await requestViewBanner(viewBanner.itemNo);
			} catch (error) {
				console.error("view banner: ", error);
			}
		},
		[bannerList]
	);

	// 배너 클릭시
	const onClickBanner = useCallback(async (itemNo) => {
		try {
			await requestClickBanner(itemNo);
		} catch (error) {
			console.error("click banner: ", error);
		}
	}, []);

	return { onViewBanner, onClickBanner };
};

export default useBannerAction;
