const textInfo = require("src/data/textInfo.json");

const MoveToDetail = ({ healthType, handlerClickHealthTypeDetail }) => {
	return (
		<div className="moveToDetail" onClick={handlerClickHealthTypeDetail}>
			<div className="moveToDetailItem">
				{healthType ? textInfo.button.healthTypeDetail : textInfo.button.moveToHealthTypeTest}
			</div>
			<div className="moveToDetailItem icon" />
		</div>
	);
};

export default MoveToDetail;
