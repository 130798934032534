/**
 * 상품 포토 리뷰 전체 보기
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useRef, useState } from "react";

import useModalStack from "src/hooks/useModalStack";
import { PAGINATION_DEFAULT_OFFSET } from "src/data/constEnum";
import { getProductPhotoReviewList } from "src/api/product/detail";
import useInfiniteScroll from "src/hooks/useInfiniteScroll";

import Image from "src/pages/component/common/image";

import "src/styles/product/modal/reviewPhotoList.scss";
import InfiniteTrigger from "src/pages/component/infiniteTrigger";

const textInfo = require("src/data/textInfo.json");

const ReviewPhotoList = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();

	const [list, setList] = useState(null);

	const ref = { list: useRef(null) };
	const infiniteScroll = useInfiniteScroll({
		fetchFn: getProductPhotoReviewList,
		param: { productNo: props.productNo, offset: PAGINATION_DEFAULT_OFFSET.PHOTO_REVIEW },
		setDataList: setList,
		ref: ref.list,
	});

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 닫기 클릭 시
	 * @param {Event} e
	 */
	const handlerClickClose = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.removeModal(props.id);
	};

	/**
	 * 이미지 1개 클릭
	 * @param {Event} e
	 * @param {Object} item
	 */
	const handlerClickPhoto = async (e, item) => {
		await modalStack.removeModal(props.id);
		await modalStack.forceUpdate();
		if (props.showReview) await props.showReview(e, item);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		infiniteScroll.setInitData();
	}, [props]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="modalContentsContainer reviewPhotoListContainer">
			<div className="title">{textInfo.review.photoReviewAll}</div>
			<button className="modalButton close" onClick={(e) => handlerClickClose(e)}></button>
			<ul ref={ref.list}>
				{list && list.length ? (
					<>
						{list.map((item, index) => (
							<li
								className="photoReviewItem"
								key={index}
								onClick={(e) => handlerClickPhoto(e, item)}
							>
								<Image src={item.image.webPath} alt="" />
							</li>
						))}
						{!infiniteScroll.isLastPage && <InfiniteTrigger ref={infiniteScroll.triggerRef} />}
					</>
				) : (
					<></>
				)}
			</ul>
		</div>
	);
};

export default ReviewPhotoList;
