import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/**
 * FAQ 카테고리 목록
 */
export const getFaqCategory = async () => {
	const url = `${API_PATHS.FAQ_CATEGORY}`;
	try {
		const result = await api.get(url);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * FAQ 목록
 * @param {Object} d
 * <code>
 * {
 * 		page: {Number}
 * 		, offset: {Number}
 * 		, categoryNo: {Number|Null}
 * }
 * </code>
 */
export const getFaqList = async (d) => {
	const url = `${API_PATHS.FAQ}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
