/**
 * 캘린더 - 날짜 선택
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useState } from "react";
import Calendar from "react-calendar";
import moment from "moment";

import useModalStack from "src/hooks/useModalStack";

import ModalCommonAlert from "src/pages/common/modalAlert";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "react-calendar/dist/Calendar.css";
import "src/styles/myLinme/alarmCalendarSelectRange.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
// const textInfo = require( "src/data/textInfo.json" );

const AlarmCalendarSelectDate = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const [selectedDate, setSelectedDate] = useState("");

	const modalStack = useModalStack();

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 기간 선택 시
	 * @param {Array}
	 */
	const handlerChangeDate = (date) => {
		setSelectedDate(moment(date).format("YYYY-MM-DD"));
	};

	/**
	 * "적용" 버튼 클릭 시
	 * @param {Event} e
	 */
	const handlerClickApply = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (selectedDate !== "") {
			props.setSelectedDate(selectedDate);
		} else {
			modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: "날짜를 선택하세요.",
			});
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="calendarSelectRangeContainer">
			<Calendar
				className={
					"linmeCalenderSelectRange " + (props.excludingHolidays ? "excludingHolidaysCalendar" : "")
				}
				onChange={handlerChangeDate}
				formatDay={(locale, date) => moment(date).format("D")} //"일" 제거. 숫자만 보이게;
				formatYear={(locale, date) => moment(date).format("YYYY")} // 네비게이션 눌렀을때 숫자 년도만 보이게;
				formatMonthYear={(locale, date) => moment(date).format("M월 YYYY")} // 네비게이션 년월 출력 방식;
				calendarType="gregory" // 일요일 부터 시작
				// selectRange={true} //기간 선택;
				allowPartialRange={true}
				showNeighboringMonth={true} // 전달, 다음달 날짜 보이기;
				next2Label={null} //+1년 & +10년 이동 버튼 숨기기;
				prev2Label={null} //-1년 & -10년 이동 버튼 숨기기;
				minDetail="month" //년월 선택하여 이동하는 기능 막기;
				minDate={new Date()} //오늘 이전 날짜 선택 막기;
			/>
			<div className="button apply" onClick={(e) => handlerClickApply(e)}>
				적용
			</div>
		</div>
	);
};
export default AlarmCalendarSelectDate;
