/**
 * 모달창을 중첩으로 사용하기 위한 Custom Hook
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useContext } from "react";

import { copyStructure } from "src/utils/obj";
import { ModalStacks, ModalStacksSetter } from "src/context/modalStackProvider";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

const useModalStack = () => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const { modalStack, modalTempValue } = useContext(ModalStacks);
	const { setModalStack, setModalCount, setModalTempValue } = useContext(ModalStacksSetter);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 모달 추가
	 * @param {Object} o
	 * @returns
	 */
	const addModal = async (o) => {
		//*/
		let n = modalStack;
		/*/
		let n = await copyStructure( modalStack );
		//*/
		if (n[o.id]) {
			return false;
		} else {
			n[o.id] = o;
			await setModalStack(n);
			await setModalCount(Object.keys(n).length);
			return true;
		}
	};

	const addModalAfterRemove = async (addO, removeId) => {
		//*/
		let n = modalStack;
		/*/
		let n = await copyStructure( modalStack );
		//*/
		if (n[removeId]) delete n[removeId];
		if (!n[addO.id]) {
			n[addO.id] = addO;
			await setModalStack(n);
			await setModalCount(Object.keys(n).length);
			return true;
		}
		return false;
	};

	/**
	 * 강제 업데이트를 위한 temp값 수정
	 */
	const forceUpdate = async () => {
		await setModalTempValue(!modalTempValue);
	};

	/**
	 * 모달 삭제
	 * @param {Object} o
	 * @returns
	 */
	const removeModal = async (id) => {
		try {
			//*/
			const n = modalStack;
			/*/
			const n = await copyStructure( modalStack );
			//*/
			if (n[id]) {
				delete n[id];
				await setModalStack(n);
				await setModalCount(Object.keys(n).length);
				return true;
			}
			return false;
		} catch (err) {
			window.linmeLog.error(err);
			return false;
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RETURN;
	// ----------------------------------------------------------------------------------------------------;

	return {
		addModal,
		addModalAfterRemove,
		forceUpdate,
		removeModal,
	};
};

export default useModalStack;
