import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import { AppContext } from "src/App";
import useAuth from "src/hooks/useAuth";
import useModalStack from "src/hooks/useModalStack";
import { logout } from "src/utils/linmeSession";
import { removeInnerSpinner, setInnerSpinner } from "src/utils/linmeDisplay";

import ModalCommonAlert from "src/pages/common/modalAlert";
import TopLayer from "src/pages/main/topLayer";
import { LOCAL_STORAGE_KEY } from "src/data/key";

const pathList = require("src/path/pathJSON.json");
const textInfo = require("src/data/textInfo.json");

const htmlInfo = require("src/data/htmlInfo.json");

const TopMainMemberInfo = () => {
	const auth = useAuth();
	const modalStack = useModalStack();
	const [isShowTopLayer, setIsShowTopLayer] = useState(false);
	const [isShowCSLayer, setIsShowCSLayer] = useState(false);
	const navigate = useNavigate();
	const { setIsLogin } = useContext(AppContext);

	/**
	 * 로그아웃 클릭 시
	 * @param {Event} e
	 */
	const handlerClickLogout = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		setIsShowTopLayer(false);
		setIsShowCSLayer(false);

		const refreshToken = window.localStorage.getItem(`${LOCAL_STORAGE_KEY.REFRESH_TOKEN}`);

		if (!refreshToken) {
			modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.error.refresh,
			});
			return;
		}

		try {
			await setInnerSpinner();
			await logout(auth, navigate, setIsLogin);
		} catch (error) {
			window.linmeLog.error(error);
		} finally {
			await removeInnerSpinner();
		}
	};

	return (
		<div className="headerContainer memberInfo">
			{!auth.user && !auth.isLogin ? (
				<>
					{/* 로그아웃 상태 */}
					<Link to={pathList.member.login}>
						<div className="infoItem">{textInfo.button.signIn}</div>
					</Link>
					<div className="infoItem divider">|</div>
					<Link to={pathList.member.signUp}>
						<div className="infoItem">{textInfo.button.moveToSignUp}</div>
					</Link>
				</>
			) : (
				<>
					{/* 로그인 상태 */}
					<div
						className="infoItem"
						onClick={(e) => {
							setIsShowTopLayer(!isShowTopLayer);
							setIsShowCSLayer(false);
						}}
					>
						<span>{auth?.user?.nickname}</span>
						{textInfo.common.member}
						<div className="dropdownIcon" />
						<TopLayer
							isShow={isShowTopLayer}
							setIsShow={setIsShowTopLayer}
							data={[
								{ label: textInfo.menu.myPage, path: pathList.myPage.index },
								{ label: textInfo.button.modifyMyInfo, path: pathList.myPage.myInfo },
								{ label: textInfo.button.signOut, onClick: handlerClickLogout },
							]}
						/>
					</div>
				</>
			)}
			<div className="infoItem divider">|</div>
			<div
				className="infoItem"
				onClick={(e) => {
					setIsShowTopLayer(false);
					setIsShowCSLayer(!isShowCSLayer);
				}}
			>
				{textInfo.myPage.cs}
				<div className="dropdownIcon" />
				<TopLayer
					isShow={isShowCSLayer}
					setIsShow={setIsShowCSLayer}
					data={[
						{ label: textInfo.myPage.notice, path: pathList.myPage.notice },
						{ label: textInfo.myPage.faq, path: pathList.myPage.faq },
						{ label: textInfo.myPage.myInquiry.title, path: pathList.myPage.inquiry },
					]}
				/>
			</div>
		</div>
	);
};

export default TopMainMemberInfo;
