/**
 * DAUM 카카오 주소 API
 */
export const execDaumPostCode = (o, elLayer, elCover) => {
	if (typeof window.daum === "undefined") {
		var script = window.document.createElement("script");
		script.type = "text/javascript";
		script.src = "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
		window.document.getElementsByTagName("body")[0].appendChild(script);
	}

	if (elLayer && elCover) {
		elCover.classList.add("on");
		// 외부 클릭시 모달 닫기
		elCover.onclick = () => elCover.classList.remove("on");
	}

	new window.daum.Postcode({
		oncomplete: function (data) {
			// 팝업에서 검색결과 항목을 클릭 시.

			// 각 주소의 노출 규칙에 따라 주소를 조합한다.
			// 내려오는 변수가 값이 없는 경우엔 공백("")값을 가지므로, 이를 참고하여 분기 한다.
			let addr = ""; // 주소 변수
			let extraAddr = ""; // 참고항목 변수

			let elPostcode = window.document.getElementById(o.postcode);
			if (!elPostcode) elPostcode = window.document.getElementsByName(o.postcode);

			let elExtra = window.document.getElementById(o.addressExtra);
			if (!elExtra) elExtra = window.document.getElementsByName(o.addressExtra);

			let elAddr1 = window.document.getElementById(o.address1);
			if (!elAddr1) elAddr1 = window.document.getElementsByName(o.address1);

			let elAddr2 = window.document.getElementById(o.address2);
			if (!elAddr2) elAddr2 = window.document.getElementsByName(o.address2);

			//사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
			if (data.userSelectedType === "R") {
				// 사용자가 도로명 주소를 선택했을 경우
				addr = data.roadAddress;
			} else {
				// 사용자가 지번 주소를 선택했을 경우(J)
				addr = data.jibunAddress;
			}

			// 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
			if (data.userSelectedType === "R") {
				// 법정동명이 있을 경우 추가한다. (법정리는 제외)
				// 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
				if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
					extraAddr += data.bname;
				}
				// 건물명이 있으면 추가;
				if (data.buildingName !== "") {
					extraAddr += extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
				}
				// 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열;
				if (extraAddr !== "") {
					extraAddr = " (" + extraAddr + ")";
				}
			}

			// 참고항목;
			if (elExtra.length !== undefined) {
				for (let i = 0; i < elExtra.length; ++i) {
					elExtra[i].value = extraAddr;
				}
			} else {
				elExtra.value = extraAddr;
			}

			//우편번호;
			if (elPostcode.length !== undefined) {
				for (let i = 0; i < elPostcode.length; ++i) {
					elPostcode[i].value = data.zonecode;
				}
			} else {
				elPostcode.value = data.zonecode;
			}

			//주소;
			if (elAddr1.length !== undefined) {
				for (let i = 0; i < elAddr1.length; ++i) {
					elAddr1[i].value = addr + extraAddr;
				}
			} else {
				elAddr1.value = addr + extraAddr;
			}

			if (elLayer && elCover) {
				elCover.classList.remove("on");
			}

			// 커서를 상세주소 필드로 이동한다.
			if (elAddr2.length !== undefined) {
				for (let i = 0; i < elAddr2.length; ++i) {
					elAddr2[i].readOnly = false;
				}
			} else {
				elAddr2.readOnly = false;
				elAddr2.focus();
			}
		},
		//}).open();// 팝업;
	}).embed(elLayer); // 레이어;
};
