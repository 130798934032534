import { useState } from "react";

const textInfo = require("src/data/textInfo.json");
import "src/styles/layout/topMainMobileToggle.scss";

const MAIN_MENU_TYPE = [
	{ key: "effect", value: textInfo.menu.productType1 },
	{ key: "ingredients", value: textInfo.menu.productType2 },
];

const TopMainMobileToggle = ({ mainSelectedMenuType, afterToggle }) => {
	const [active, setActive] = useState(mainSelectedMenuType);
	const handleChange = (type) => {
		setActive(type);
		afterToggle(type === "effect");
	};

	return (
		<div className="topMainMobileToggle">
			{MAIN_MENU_TYPE.map((item, index) => {
				return (
					<span
						key={item.key}
						className={active === item.key ? "mobileToggleItem on" : "mobileToggleItem"}
						onClick={() => handleChange(item.key)}
					>
						{item.value}
					</span>
				);
			})}
		</div>
	);
};

export default TopMainMobileToggle;
