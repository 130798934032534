/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { createContext, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { checkDevice } from "src/utils/linmeDevice";

import Toast from "src/pages/component/common/Toast";
import PathList from "src/path/pathList";
import ModalStackProvider from "src/context/modalStackProvider";
import MoveToApp from "src/pages/moveToApp";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/common.scss";
import "src/styles/productList.scss";
import "src/styles/modal.scss";

export const AppContext = createContext();

const App = () => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const [mainSelectedMenuType, setMainSelectedMenuType] = useState("effect");
	const [isLogin, setIsLogin] = useState(false);
	const [isProductDetail, setIsProductDetail] = useState(false);
	const [device, setDevice] = useState(null);
	const [schemePath, setSchemePath] = useState(null);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 리사이즈 발생 시
	 * @param {Event} e
	 */
	const handlerResize = (e) => {
		setDevice(checkDevice());
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	window.pxToVw = function (px) {
		const percent = window.outerWidth / 100;
		const vw = px / percent;
		return vw;
	};

	window.vwToPx = function (vw) {
		const px = (window.outerWidth / 100) * vw;
		return px;
	};

	window.pxToVh = function (px) {
		const percent = window.outerHeight / 100;
		const vh = px / percent;
		return vh;
	};

	window.vhToPx = function (vh) {
		const px = (window.outerHeight / 100) * vh;
		return px;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		window.addEventListener("resize", handlerResize);
		setDevice(checkDevice());
		//언마운트 시 실행;
		return () => {
			window.removeEventListener("resize", handlerResize);
		};
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="App">
			<BrowserRouter>
				<AppContext.Provider
					value={{
						mainSelectedMenuType,
						setMainSelectedMenuType,
						isLogin,
						setIsLogin,
						isProductDetail,
						setIsProductDetail,
						device,
						setSchemePath,
					}}
				>
					<ModalStackProvider>
						<Toast />
						<PathList />
						<MoveToApp path={schemePath} />
					</ModalStackProvider>
				</AppContext.Provider>
			</BrowserRouter>
		</div>
	);
};

export default App;
