/**
 * 건강고민 추천 상품 목록
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */
import { useRef, useEffect, useCallback } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { search } from "src/api/product/search";
import useQueryParams from "src/hooks/useQueryParams";
import useSearchByKeyword from "src/hooks/search/useSearchByKeyword";
import useDeviceType from "src/hooks/useDeviceType";

import ProductSortList from "src/pages/component/product/productSortList";

import SearchProductItem from "src/pages/component/product/searchProductItem";
import InfiniteTrigger from "src/pages/component/infiniteTrigger";
import MobileSearchProductList from "src/pages/search/mobileSearchProductList";
import ProductList from "src/pages/component/product/productList";
import PaginationWithQuery from "src/pages/component/paginationWithQuery";
import { DEVICE_TYPE, PAGINATION_DEFAULT_OFFSET, PRODUCT_LIST_ORDER } from "src/data";
import "src/styles/search/searchResult.scss";

const SearchResult = () => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;
	const query = useQueryParams({ isDirectPush: true });
	const queryObj = query.queryStringToObject();
	const { keyword, sort = PRODUCT_LIST_ORDER[0].value } = queryObj;
	const { productList, totalPage, totalCount } = useSearchByKeyword();
	const observerTarget = useRef(null);
	const { mobileType } = useDeviceType();

	const {
		data: productListMobile,
		hasNextPage,
		fetchNextPage,
		isFetchingNextPage,
	} = useInfiniteQuery({
		queryKey: ["searchByKeyword", keyword, sort],
		queryFn: async ({ pageParam = 1 }) => {
			const response = await search({
				keyword: keyword,
				page: pageParam,
				sort,
				offset: PAGINATION_DEFAULT_OFFSET.PRODUCT_LIST,
			});
			return response.data;
		},
		initialPageParam: 1,
		getNextPageParam: (result) => {
			if (result?.isLastPage) return undefined;
			return result?.page + 1;
		},
		refetchOnMount: false,
		staleTime: 1000 * 60 * 5, // 5분
		cacheTime: 1000 * 60 * 5, // 5분
	});

	const handleObserver = useCallback(
		(entries) => {
			const [target] = entries;
			if (target.isIntersecting && hasNextPage && !isFetchingNextPage) {
				fetchNextPage();
			}
		},
		[fetchNextPage, hasNextPage, isFetchingNextPage]
	);

	useEffect(() => {
		const observer = new IntersectionObserver(handleObserver, {
			threshold: 1.0,
		});

		if (observerTarget.current) {
			observer.observe(observerTarget.current);
		}

		return () => {
			if (observerTarget.current) {
				observer.unobserve(observerTarget.current);
			}
		};
	}, [handleObserver, mobileType]);
	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="container underTopMenu searchResultDisplay">
			<div className="contents">
				<div className="title">
					<span className="searchKeyword">{`'${keyword || ""}'`}</span> 에 대한 검색 결과{" "}
					<span className="resultCount">
						<span className="resultCountNumber">{totalCount.toLocaleString()}</span>건
					</span>
				</div>
				<ProductSortList />
				{[DEVICE_TYPE.MOBILE, DEVICE_TYPE.MOBILE_MINI].includes(mobileType) ? (
					<>
						<MobileSearchProductList>
							{productListMobile?.pages?.map((product) => {
								return product.content.map((item) => (
									<SearchProductItem key={item.id} product={item} />
								));
							})}
						</MobileSearchProductList>
						<InfiniteTrigger ref={observerTarget} />
					</>
				) : (
					<div className="productDisplayContainer">
						<div className="productListContainer">
							{productList && productList.length > 0 ? (
								<ProductList
									{...{ items: productList }}
									limit={PAGINATION_DEFAULT_OFFSET.PRODUCT_LIST}
								/>
							) : (
								<div className="noData"></div>
							)}
						</div>
						{productList && productList.length > 0 && <PaginationWithQuery totalPage={totalPage} />}
					</div>
				)}
			</div>
		</div>
	);
};

export default SearchResult;
