import Image from "src/pages/component/common/image";
import { EMPTY_USER_PROFILE_IMAGE } from "src/data";

const textInfo = require("src/data/textInfo.json");

const HealthTypeTitle = ({ auth }) => {
	return (
		<div className="healthTypeTitle">
			<div className="healthTypeTitleImg">
				<Image
					src={auth?.user?.image?.webPath || EMPTY_USER_PROFILE_IMAGE}
					alt={auth?.user?.nickname || ""}
				/>
			</div>
			<div className="healthTypeTitleText">
				<div>
					<span>{auth?.user?.nickname}</span>
					{textInfo.myLinme.desc1}
				</div>
				<div>{textInfo.myLinme.desc2}</div>
			</div>
		</div>
	);
};

export default HealthTypeTitle;
