/**
 * 아이디찾기
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import useModalStack from "src/hooks/useModalStack";
import AuthVerifyIframe from "./modal/authVerifyIframe";

import {
	handlerKeyUpUserPW,
	handlerKeyUpUserPWCheck,
	validationPw,
	validationPwCheck,
} from "src/utils/linmeMember";
import { requestVerify } from "src/api/member/signUp";
import { requestFindMemberByCi, requestResetPw } from "src/api/member/find";
import { handlerClickTogglePwType } from "src/utils/linmeMember";
import {
	API_RESULT_STATUS,
	FIND_RESULT_TYPE,
	REG_EXP,
	RESET_RESULT_TYPE,
	VERIFY_ENV,
	VERIFY_TYPE,
} from "src/data/constEnum";
import { encryptPassword } from "src/utils/linmeSession";
import ModalCommonAlert from "src/pages/common/modalAlert";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */
import "src/styles/member/findPw.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/*
---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

const htmlInfo = require("src/data/htmlInfo.json");
const textInfo = require("src/data/textInfo.json");

const FindPw = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();

	const [ci, setCi] = useState("");
	const location = useLocation();
	const type = location.state?.type;
	const [resultType, setResultType] = useState("");
	const ref = {
		pw: useRef(null),
		pwCheck: useRef(null),
	};

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 신규 비밀번호 입력 후 확인 클릭 시
	 * @param {Event} e
	 */
	const handlerClickResetPw = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await postRequestResetPw(ci);
	};

	/**
	 * 본인인증 클릭 시
	 * @param {Event} e
	 */
	const handlerClickVerify = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await postRequestVerify().then((data) => {
			modalStack.addModal({
				id: "modalVerify",
				type: "none",
				component: <AuthVerifyIframe {...data} />,
				setDataAfterVerify: setDataAfterVerify,
				button: {
					iconClose: true,
				},
			});
		});
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 본인인증 결과로 회원여부 판단
	 * @param {String} verifyCi
	 * @returns
	 */
	const getRequestFindMemberByCi = async (verifyCi) => {
		try {
			const d = { ci: verifyCi };
			const result = await requestFindMemberByCi(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (result.data.isCheckExist === true) {
					setResultType(FIND_RESULT_TYPE.SUCCESS);
				} else {
					setResultType(FIND_RESULT_TYPE.FAIL);
				}
				return result.data;
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	/**
	 * 비밀번호 변경
	 * @param {String} verifyCi
	 */
	const postRequestResetPw = async (verifyCi) => {
		if (validation() === false) return;

		const hashedPassword = await encryptPassword(ref.pw.current.value);

		try {
			const d = {
				ci: verifyCi,
				newPassword: hashedPassword,
			};
			const result = await requestResetPw(d);

			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				setResultType(RESET_RESULT_TYPE.SUCCESS);
				return result.data;
			} else {
				window.linmeLog.log(result);
				modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					html: htmlInfo.error.dataCall,
				});
			}
		} catch (error) {
			window.linmeLog.log(error);
			modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				html: error?.response?.data?.message || htmlInfo.error.dataCall,
			});
		}
	};

	/**
	 * 본인인증
	 */
	const postRequestVerify = async () => {
		try {
			const d = {
				env: isMobile ? VERIFY_ENV.WEB.MOBILE : VERIFY_ENV.WEB.PC,
				memberNo: 0,
				type: VERIFY_TYPE.FIND_ID,
			};
			const result = await requestVerify(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				return result.data;
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	/**
	 * 본인인증 후처리
	 * @param {Object} d
	 */
	const setDataAfterVerify = async (d) => {
		if (!d.hasOwnProperty("status")) return;
		if (d.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
			setCi(d.ci);
			await getRequestFindMemberByCi(d.ci); //회원여부 판단;
		} else {
			// setResultType( FIND_RESULT_TYPE.FAIL );
		}
	};

	/**
	 * validation
	 * @returns
	 */
	const validation = () => {
		if (!validationPw(ref.pw.current)) return false;
		if (!validationPwCheck(ref.pwCheck.current, ref.pw.current)) return false;
		return true;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		if (location.state?.ci) {
			setResultType(FIND_RESULT_TYPE.SUCCESS); //아이디 찾기 후 넘어왔으므로 아이디 존재 확인 완료;
			setCi(location.state?.ci);
		}
	}, [location.state?.ci]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="container underTopMenu findPw">
			<div className="contents">
				<div className="title">{textInfo.member.findPw.title}</div>
				<div className={"find " + (resultType === "" ? "on" : "")}>
					<div className="description">{textInfo.member.findPw.pleaseVerify}</div>
					<div className="button certification" onClick={(e) => handlerClickVerify(e)}>
						{textInfo.button.verify}
					</div>
				</div>
				<div className={"noResult " + (resultType === FIND_RESULT_TYPE.FAIL ? "on" : "")}>
					<div className="description">{textInfo.member.findPw.notFound}</div>
					<Link to={pathList.member.login}>
						<div className="button moveToSignUp">{textInfo.button.moveToSignUp}</div>
					</Link>
				</div>
				<div className={"resetPw " + (resultType === FIND_RESULT_TYPE.SUCCESS ? "on" : "")}>
					<div className="result">
						{/* <div className="description">회원님의 본인인증이 확인되어 비밀번호를 재설정합니다.</div> */}
						<div className="label">
							<span>{textInfo.member.findPw.newPassword}</span>
						</div>
						<div className="userInput">
							<input
								type="password"
								className="pw"
								name="pw"
								id="pw"
								minLength={8}
								maxLength={20}
								placeholder={textInfo.member.findPw.placeholder.newPassword}
								ref={ref.pw}
								onKeyUp={(e) => handlerKeyUpUserPW(e)}
							/>
							<div className="pwShowHide close" onClick={(e) => handlerClickTogglePwType(e)} />
							<div className="guide">
								<div className="inputGuide">{textInfo.member.guide.password.characterLength}</div>
								<div className="inputGuide">{textInfo.member.guide.password.character}</div>
								<div className="inputGuideWarn"></div>
							</div>
						</div>
						<div className="label">
							<span>{textInfo.member.findPw.newPasswordCheck}</span>
						</div>
						<div className="userInput">
							<input
								type="password"
								className="pwCheck"
								name="pwCheck"
								id="pwCheck"
								placeholder={textInfo.member.findPw.placeholder.newPasswordCheck}
								ref={ref.pwCheck}
								onKeyUp={(e) => handlerKeyUpUserPWCheck(e, ref.pw)}
							/>
							<div className="pwShowHide close" onClick={(e) => handlerClickTogglePwType(e)} />
							<div className="guide">
								<div className="inputGuideWarn"></div>
							</div>
						</div>
					</div>
					<div className="button resetPWConfirm" onClick={(e) => handlerClickResetPw(e)}>
						{textInfo.button.confirm}
					</div>
				</div>
				<div className={"resetCompleted " + (resultType === RESET_RESULT_TYPE.SUCCESS ? "on" : "")}>
					<div className="description">
						<span>{textInfo.member.findPw.resetSuccess}</span>
						<span>{textInfo.member.findPw.moveToSignIn}</span>
					</div>
					<Link to={pathList.main}>
						<div className="button moveToMain">{textInfo.button.moveToHome}</div>
					</Link>
					<Link to={pathList.member.login}>
						<div className="button login">{textInfo.button.memberLogin}</div>
					</Link>
				</div>
				<div className={"resetFailed " + (resultType === RESET_RESULT_TYPE.FAIL ? "on" : "")}>
					<div className="description">
						<span>{textInfo.member.findPw.resetFail}</span>
						<span>{textInfo.member.findPw.pleaseRetry}</span>
					</div>
					<div className="csInfo">
						({textInfo.cs.name} {textInfo.cs.tel})
					</div>
					<div className="button resetPW">
						<Link
							to={pathList.member.findPw}
							state={{
								type: "resetPw",
								ci: { ci },
							}}
						>
							{textInfo.button.memberResetPw}
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FindPw;
