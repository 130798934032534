/**
 * 쇼핑내역 - 취소/교환/환불
 */

import { useEffect, useState } from "react";

import useInfiniteScroll from "src/hooks/useInfiniteScroll";

import { getClaimList } from "src/api/myShopping/claim";
import ClaimCard from "src/pages/component/claim/claimCard";
import InfiniteTrigger from "src/pages/component/infiniteTrigger";

import { PAGINATION_DEFAULT_OFFSET } from "src/data/constEnum";

import "src/styles/myPage/claim/claimList.scss";

const textInfo = require("src/data/textInfo.json");

const ClaimList = (props) => {
	const [claimList, setClaimList] = useState([]);

	const infiniteScroll = useInfiniteScroll({
		fetchFn: getClaimList,
		param: { offset: PAGINATION_DEFAULT_OFFSET.DEFAULT },
		setDataList: setClaimList,
	});

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	//최초 실행;
	useEffect(() => {
		infiniteScroll.setInitData();
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<ul className={`claimList on ${!claimList.length ? "noClaim" : ""}`}>
			{claimList && claimList.length ? (
				<>
					{claimList.map((claim, claimSeq) => (
						<ClaimCard key={claimSeq} {...{ claim }} data={props.data || null} />
					))}
					{!infiniteScroll.isLastPage && <InfiniteTrigger ref={infiniteScroll.triggerRef} />}
				</>
			) : (
				<li className="noData">
					<div className="noOrderList">{textInfo.myPage.myShopping.claim.noData}</div>
				</li>
			)}
		</ul>
	);
};

export default ClaimList;
