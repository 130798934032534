/**
 * 마이페이지 - 배송지 관리
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import useModalStack from "src/hooks/useModalStack";
import { API_RESULT_STATUS, PAGINATION_DEFAULT_OFFSET } from "src/data/constEnum";
import { deleteAddress, getAddressList } from "src/api/member/addressBook";
import useInfiniteScroll from "src/hooks/useInfiniteScroll";

import ModalCommonAlert from "src/pages/common/modalAlert";
import MyPageAddressBookAdd from "src/pages/myPage/addressBookAdd";
import InfiniteTrigger from "src/pages/component/infiniteTrigger";

import "src/styles/myPage/addressBook.scss";

const pathList = require("src/path/pathJSON.json");
const htmlInfo = require("src/data/htmlInfo.json");
const textInfo = require("src/data/textInfo.json");

const MyPageAddressBook = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const [addressList, setAddressList] = useState([]);

	const ref = { list: useRef(null) };
	const navigate = useNavigate();
	const modalStack = useModalStack();
	const infiniteScroll = useInfiniteScroll({
		fetchFn: getAddressList,
		param: { offset: PAGINATION_DEFAULT_OFFSET.ADDRESS_BOOK },
		setDataList: setAddressList,
		ref: props.id ? ref.list : null,
	});

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 배송지 추가
	 * @param {Event} e
	 */
	const handlerClickAdd = (e) => {
		//버블링 막기;
		e.preventDefault();
		// e.stopPropagation();

		if (props.isModal === true) {
			let id = "modalAddAddress";
			if (props.id.indexOf("Pickup") > -1) id = "modalAddPickupAddress";
			modalStack.addModal({
				id: id,
				type: "alert",
				component: <MyPageAddressBookAdd />,
				isModal: true,
				afterAddAddressFromModal: afterAddAddressFromModal,
				addressType: props.addressType,
				button: { iconClose: true },
			});
		} else navigate(pathList.myPage.addressBookAdd);
	};

	/**
	 * 배송지 삭제
	 * @param {Event} e
	 * @param {Number} addressNo
	 */
	const handlerClickDelete = async (e, addressNo) => {
		//버블링 막기;
		e.preventDefault();
		// e.stopPropagation();

		await requestDeleteAddress(addressNo);
	};

	/**
	 * 배송지 수정
	 * @param {Event} e
	 */
	const handlerClickModify = (e, item) => {
		//버블링 막기;
		e.preventDefault();
		// e.stopPropagation();

		if (props.isModal === true) {
			let id = "modalModifyAddress";
			if (props.id.indexOf("Pickup") > -1) id = "modalModifyPickupAddress";
			modalStack.addModal({
				id: id,
				type: "alert",
				component: <MyPageAddressBookAdd />,
				isModal: true,
				data: item,
				addressType: props.addressType,
				afterAddAddressFromModal: afterAddAddressFromModal,
				button: { iconClose: true },
			});
		} else {
			navigate(pathList.myPage.addressBookAdd, { state: item });
		}
	};

	/**
	 * 배송지 선택
	 * @param {Event} e
	 * @param {Number} addressNo
	 * @param {Number} index
	 */
	const handlerClickSelect = async (e, addressNo, index) => {
		//버블링 막기;
		e.preventDefault();
		// e.stopPropagation();

		if (addressNo === props.selectedAddressNo) {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.sameAsExistingData,
			});
			return false;
		}
		if (props.afterSelected)
			await props.afterSelected(props.addressType, addressNo, addressList[index]);
	};

	/**
	 * 삭제 후 ModalAlert 확인 클릭 시
	 * @param {Event} e
	 */
	const handlerClickDeleteModalOK = async (e) => {
		await modalStack.removeModal("modalCommonAlert");
		infiniteScroll.setInitData();
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 추가/수정 모달에서 확인 클릭 후 데이터 새로 부르기
	 */
	const afterAddAddressFromModal = async () => {
		infiniteScroll.setInitData();
		if (props.requestChangeOrder)
			await props.requestChangeOrder(props?.selectedAddressNo, null, null);
	};

	/**
	 * 삭제 요청
	 * @param {Number} addressNo
	 * @returns
	 */
	const requestDeleteAddress = async (addressNo) => {
		try {
			const d = { addressNo: addressNo };
			const result = await deleteAddress(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.deleted,
					button: {
						ok: {
							text: textInfo.button.confirm,
							fn: handlerClickDeleteModalOK,
						},
					},
				});
				return result.data;
			} else {
				window.linmeLog.log(result);
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: result.message || htmlInfo.error.dataCall,
				});
				return false;
			}
		} catch (error) {
			window.linmeLog.log(error);
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: error.message || htmlInfo.error.dataCall,
			});
			return false;
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		infiniteScroll.setInitData();
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<>
			<div className={"title " + (props && props.addressType ? props.addressType : "")}>
				{textInfo.myPage.myAddressBook.title}
			</div>
			<div className="addressBookCover">
				<ul className="addressList" ref={ref.list}>
					<li className="addressItem addAddress" onClick={(e) => handlerClickAdd(e)}></li>
					{addressList && addressList.length ? (
						<>
							{addressList.map((item, index) => (
								<li className="addressItem" key={item.addressNo}>
									<div className={"addressNameAndSelector " + (item.isCheckRep ? "default" : "")}>
										<div className="addressName">
											{item.nickname}
											{item.isCheckRep ? <span className="default"></span> : <span />}
										</div>
										<div
											className={
												"button clickable selectAddress " +
												(props.selectedAddressNo && props.selectedAddressNo === item.addressNo
													? ""
													: "on")
											}
											onClick={(e) => handlerClickSelect(e, item.addressNo, index)}
										>
											{textInfo.button.select}
										</div>
									</div>
									<div className="address">
										{item.address1}, {item.address2}
									</div>
									<div className="detail">
										{item.name} ({item.phone})
									</div>
									<div className="buttonCover">
										<div
											className="button clickable modifyAddress"
											onClick={(e) => handlerClickModify(e, item)}
										>
											{textInfo.button.modify}
										</div>
										{item.isCheckRep || props.selectedAddressNo === item.addressNo ? (
											<></>
										) : (
											<div
												className="button clickable deleteAddress"
												onClick={(e) => handlerClickDelete(e, item.addressNo, index)}
											>
												{textInfo.button.delete}
											</div>
										)}
									</div>
								</li>
							))}
							<InfiniteTrigger ref={infiniteScroll.triggerRef} />
						</>
					) : (
						<li className="noData" />
					)}
				</ul>
				<div
					className={
						"button clickable addAddress " + (props && props.addressType ? props.addressType : "")
					}
					onClick={(e) => handlerClickAdd(e)}
				>
					{textInfo.button.addAddress}
				</div>
				{/* <div className="button clickable addAddress" onClick={(e)=>handlerClickAdd(e)}>{textInfo.button.addAddress}</div> */}
			</div>
		</>
	);
};

export default MyPageAddressBook;
