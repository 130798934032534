/**
 * 마이린미 - 나의 섭취 일정
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */
import queryClient from "src/api/queryClient";
import useAlarmCalenderMonth from "src/hooks/myLinme/useAlarmCalenderMonth";
import useAlarmCalenderDate from "src/hooks/myLinme/useAlarmCalenderDate";

import AlarmCalendar from "src/pages/component/myLinme/alarm/alarmCalendar";
import AlarmList from "src/pages/component/myLinme/alarm/alarmList";
import DailyAlarm from "src/pages/component/myLinme/alarm/dailyAlarm";
import { queryKeys } from "src/data";
const textInfo = require("src/data/textInfo.json");
import "src/styles/myLinme/alarm.scss";

const MyLinmeAlarmPage = () => {
	const { data: alarmDates, handleMonth } = useAlarmCalenderMonth();
	const { data: dailyList, handleDate } = useAlarmCalenderDate();

	// 데이터 불러오기
	const reloadData = async () => {
		queryClient.invalidateQueries([queryKeys.ALARM_DATE, queryKeys.ALARM_MONTH]);
	};

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="container myLinmeContainer alarm">
			<div className="contents">
				<div className="title">{textInfo.myLinme.alarm.title}</div>
				<div className="mainContainer">
					<div className="leftContainer">
						<AlarmCalendar
							alarmDates={alarmDates}
							afterSelected={handleDate}
							handleChangeMonth={handleMonth}
						/>
						<DailyAlarm alarmData={dailyList} />
					</div>
					<AlarmList reloadData={reloadData} />
				</div>
			</div>
		</div>
	);
};

export default MyLinmeAlarmPage;
