/**
 * 결제 실패
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ORDER_TYPE } from "src/data/constEnum";

import CartProgressStage from "src/pages/component/cart/cartProgressStage";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/payment/paymentFailure.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

const htmlInfo = require("src/data/htmlInfo.json");
const textInfo = require("src/data/textInfo.json");

const PaymentFailure = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const location = useLocation();
	const [message, setMessage] = useState(null);
	const [fromPage, setFromPage] = useState(null);
	const [failGuide, setFailGuide] = useState(null);
	const [productInfo, setProductInfo] = useState(null);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		// const orderNo = location.state?.orderNo;
		const txt = location.state?.message;
		if (txt) setMessage(txt);

		const from = location.state?.from;
		if (from) {
			setFromPage(from);
			let html = "";
			if (fromPage && fromPage === ORDER_TYPE.CART) {
				html += htmlInfo.payment.fail.cart1;
				html += textInfo.cs.tel;
				html += htmlInfo.payment.fail.cart2;
				html += htmlInfo.payment.fail.cart3;
			} else {
				html += htmlInfo.payment.fail.product;
			}
			setFailGuide(html);
		}

		const product = location.state?.product;
		if (product) {
			setProductInfo(product);
		}
	}, [location.state?.message]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="container underTopMenu paymentFailureContainer">
			<div className="contentsBackground">
				<div className="contents">
					<CartProgressStage {...{ on: "step3" }} />
				</div>
				<div className="contents orderCover">
					<div className="failWarn">{textInfo.payment.failure.warn}</div>
					<div className="failReason">
						{textInfo.payment.failure.reason}
						<span>{message ? message : "-"}</span>
					</div>
					<div
						className="failGuide"
						dangerouslySetInnerHTML={{ __html: failGuide ? failGuide : "-" }}
					></div>
					{/* <Link to={pathList.cart}><div className="button moveToCart">{textInfo.button.moveToCart}</div></Link> */}
					{fromPage && fromPage === ORDER_TYPE.CART ? (
						<Link to={pathList.main}>
							<div className="button moveToCart">{textInfo.button.moveToMain}</div>
						</Link>
					) : (
						<></>
					)}
					{fromPage && fromPage !== ORDER_TYPE.CART && productInfo && productInfo.length ? (
						<Link
							to={
								pathList.product.detail + `${productInfo?.[0]?.type}/` + productInfo?.[0].productNo
							}
						>
							<div className="button moveToCart">{textInfo.button.moveToProduct}</div>
						</Link>
					) : (
						<></>
					)}
				</div>
			</div>
		</div>
	);
};

export default PaymentFailure;
