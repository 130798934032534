import { useQuery } from "@tanstack/react-query";
import { getProductReviewCheckWrite } from "src/api/product/detail";
import { queryKeys } from "src/data";
import useAuth from "src/hooks/useAuth";

/**
 * useMemberReviewCheck
 *
 * 로그인된 사용자가 특정 제품에 대해 리뷰를 작성할 수 있는지 확인
 * @param {number} params.productNo - 리뷰 가능 여부를 확인할 제품 번호.
 *
 * @returns {Object} - 리턴 값:
 *    - `data`: itemNo, isCheckWrite, averageRate
 *    - `isReviewWritable`: 해당 제품에 대해 리뷰를 작성할 수 있는지 여부(boolean).
 */
const useMemberReviewCheck = ({ productNo }) => {
	const auth = useAuth();
	const { data } = useQuery({
		queryKey: [queryKeys.MEMBER_REVIEW_CHECK, productNo],
		queryFn: () => getProductReviewCheckWrite(productNo),
		select: (data) => data?.data,
		enabled: auth.isLogin,
	});

	return { data, isReviewWritable: !!data?.itemNo };
};

export default useMemberReviewCheck;
