import { useQuery } from "@tanstack/react-query";
import { getBannerList } from "src/api/etc/banner";
import useDeviceType from "src/hooks/useDeviceType";
import { API_RESULT_STATUS, DEVICE_TYPE, CLIENT_TYPE, queryKeys } from "src/data";

/**
 * 배너 목록 조회
 */
const getBannerListByDeviceType = async (clientType, type) => {
	try {
		const request = {
			clientType: clientType,
			type: type,
		};
		const result = await getBannerList(request);
		if (result?.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
			return result.data;
		} else {
			window.linmeLog.log(result);
			return [];
		}
	} catch (err) {
		window.linmeLog.error(err);
		throw err;
	}
};

const useBannerList = (type) => {
	const { mobileType: deviceType } = useDeviceType();
	const clientType =
		deviceType === DEVICE_TYPE.PC || deviceType === DEVICE_TYPE.TABLET
			? CLIENT_TYPE.WEB_PC
			: CLIENT_TYPE.WEB_MOBILE;

	return useQuery({
		queryKey: [queryKeys.BANNER_LIST, clientType, deviceType, type],
		queryFn: () => getBannerListByDeviceType(clientType, type),
		select: (bannerList) => bannerList?.sort((a, b) => a.ORDER - b.ORDER),
	});
};

export default useBannerList;
