import { useCallback, useRef } from "react";

import clsx from "clsx";
import styles from "src/styles/product/detail/fixedPurchaseButton.module.scss";

const FixedPurchaseButton = ({ isOpen = false, handleOpen, children }) => {
	const outerContainerRef = useRef(null);

	// outerContainer 클릭 시 isOpen을 false로 변경
	const handleOuterContainerClick = useCallback((e) => {
		if (e.target === outerContainerRef.current) {
			handleOpen(false);
		}
	}, []);

	// 구매하기 버튼 클릭 시 isOpen 변경
	const handleClickPurchaseButton = useCallback((e) => {
		e.stopPropagation();
		handleOpen((prev) => !prev);
	}, []);

	return (
		<div
			ref={outerContainerRef}
			className={clsx([styles.outerContainer, isOpen && styles.open])}
			onClick={handleOuterContainerClick}
		>
			<div className={clsx([styles.container])}>
				<div className={styles.innerContainer}>
					<div className={styles.purchaseButtonContainer} onClick={handleClickPurchaseButton}>
						<span>구매하기</span>
						<svg
							className={clsx([styles.arrowIcon, !isOpen && styles.close])}
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="11"
							viewBox="0 0 20 11"
						>
							<path
								data-name="패스 9572"
								d="M18.828 0 9.972 8.692 1.172.056 0 1.213 9.972 11 20 1.157z"
								transform="rotate(180 10 5.5)"
							/>
						</svg>
					</div>

					{isOpen && <div className={styles.detailContainer}>{children}</div>}
				</div>
			</div>
		</div>
	);
};

export default FixedPurchaseButton;
