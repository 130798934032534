/**
 * 마이페이지 - 공지사항
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useRef, useState } from "react";
import moment from "moment";

import { API_RESULT_STATUS, PAGINATION_DEFAULT_OFFSET } from "src/data/constEnum";
import { getNoticeList } from "src/api/cs/notice";
import { getLastPageNumber } from "src/utils/number";
import { setScrollTop } from "src/utils/linmeDisplay";

import Pagination from "src/pages/component/pagination";
import Image from "src/pages/component/common/image";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myPage/notice.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const MyPageNotice = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const [noticeList, setNoticeList] = useState(null);
	const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);
	const [paginationOffset, setPaginationOffset] = useState(PAGINATION_DEFAULT_OFFSET.DEFAULT);
	const [paginationTotalCount, setPaginationTotalCount] = useState(0);
	const [paginationLastPage, setPaginationLastPage] = useState(0);
	const [paginationIsLastPage, setPaginationIsLastPage] = useState(false);
	const ref = {
		noticeList: useRef(null),
	};

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 공지사항 1건 Toggle
	 * @param {Event} e
	 */
	const handlerClickToggleContent = (e) => {
		const ct = e.currentTarget;
		const elLi = ct.closest("li");
		if (elLi.classList.contains("on")) elLi.classList.remove("on");
		else elLi.classList.add("on");
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 다시 그리기
	 * @param {Number} pageNo
	 */
	const redraw = async (pageNo) => {
		await requestNoticeList(pageNo);
		await setLayoutForRedraw();
	};

	/**
	 * 공지사항 목록 조회
	 * @param {Number} pageNo
	 * @returns
	 */
	const requestNoticeList = async (pageNo) => {
		try {
			let d = {
				offset: paginationOffset,
				page: pageNo,
			};
			const result = await getNoticeList(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (result.data.content) {
					setNoticeList(result.data.content);

					setPaginationCurrentPage(result.data.page);
					setPaginationTotalCount(result.data.totalCount);
					setPaginationIsLastPage(result.data.isLastPage);
					setPaginationLastPage(getLastPageNumber(result.data.totalCount, paginationOffset));
				}
				return result.data;
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (error) {
			window.linmeLog.log(error);
			return false;
		}
	};

	/**
	 * 페이지 변경 전 css 및 스크롤 원위치
	 */
	const setLayoutForRedraw = async () => {
		const ct = ref.noticeList.current;
		const a = ct.querySelectorAll(".noticeItem.data");
		if (a && a.length) {
			for (let i = 0; i < a.length; ++i) {
				a[i].classList.remove("on");
			}
		}
		await setScrollTop();
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		const getData = async () => {
			await requestNoticeList(1);
		};
		getData();
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<>
			<div className="title">{textInfo.myPage.notice}</div>
			<div className="noticeCover">
				<ul className="noticeList" ref={ref.noticeList}>
					<li className="noticeItem label">
						<div className="noticeTitle">{textInfo.common.title}</div>
						<div className="noticeWriteDate">{textInfo.common.writeDate}</div>
					</li>
					{noticeList && noticeList.length ? (
						noticeList.map((item, index) => (
							<li className="noticeItem data" key={index}>
								<div className="noticeTitle" onClick={(e) => handlerClickToggleContent(e)}>
									{item.title}
								</div>
								<div className="noticeWriteDate">
									{moment(item.registered).format("YYYY-MM-DD")}
								</div>
								<div className="noticeContent" dangerouslySetInnerHTML={{ __html: item?.content }}>
									{/* {item.content}
									{item.file ? (
										<ul className="noticeImageContainer">
											<li className="noticeImageCover">
												<Image src={item.file.webPath} alt={item.title} />
											</li>
										</ul>
									) : (
										<></>
									)} */}
								</div>
							</li>
						))
					) : (
						<li className="noData" />
					)}
				</ul>
				{noticeList && noticeList.length ? (
					<Pagination
						{...{
							currentPage: paginationCurrentPage,
							offset: paginationOffset,
							totalCount: paginationTotalCount,
							lastPage: paginationLastPage,
							isLastPage: paginationIsLastPage,
							redraw: redraw,
						}}
					/>
				) : (
					<></>
				)}
			</div>
		</>
	);
};

export default MyPageNotice;
