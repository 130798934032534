/**
 * 상품 옵션 목록 (필수,옵션)
 * 장바구니
 *
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/order/productOptionsEdit.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const productOptionBox = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const { option, productData, quantity } = props;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/*
	 * 옵션 가격 계산
	 */
	const getOptionAddPrice = () => {
		if (option?.type === "ADDITIONAL") return option?.addPrice || 0;
		if (option?.type === "REQUIRED") return option?.addPrice + productData?.salePrice || 0;
		return 0;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	if (!option) return <></>;

	return (
		<div className="productOptionEditContainer">
			<div className="productOptionEditItem">
				<div className="productOptionEditItemInfo">
					<div className="productOptionEditItemInfoName">
						{option.optionFullName} | {quantity}개
					</div>
					<div className="productOptionEditItemInfoPrice">
						{(getOptionAddPrice() * quantity)?.toLocaleString() || 0}
						{textInfo.common.currencyUnit}
					</div>
				</div>
			</div>
		</div>
	);
};

export default productOptionBox;
