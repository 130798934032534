/**
 * 404 Not Found
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import React from "react";
import { Link } from "react-router-dom";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/notFound.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const NotFound = () => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * "이전 페이지" 버튼 클릭 시
	 * @param {MouseEvent} e
	 */
	const handleClickHistoryBack = (e) => {
		window.history.back();
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="container notFound">
			<div className="warn">{textInfo.error404.warn}</div>
			<div className="reason">{textInfo.error404.reason}</div>
			<div className="buttonHistoryBack clickable" onClick={(e) => handleClickHistoryBack(e)}>
				{textInfo.button.moveToBack}
			</div>
			<Link to={pathList.main}>
				<div className="buttonGoToMain clickable">{textInfo.button.moveToMain}</div>
			</Link>
		</div>
	);
};

export default NotFound;
