/**
 * 쿠키 설정 ( 로그인 아이디 저장 / 최근 검색어 )
 */

// const textInfo = require( "src/data/textInfo.json" );

export const getCookieSearchKeyword = (cookieName) => {
	if (window.localStorage[cookieName] !== undefined) {
		return JSON.parse(window.localStorage[cookieName]).sort(
			(a, b) => new Date(b.register) - new Date(a.register)
		);
	} else return null;
};

/**
 * 검색어 삭제
 * @param {String} cookieName
 * @param {Number} index
 * @param {String} value
 */
export const removeCookieSearchKeyword = async (cookieName, index, value) => {
	let list = getCookieSearchKeyword(cookieName);
	if (list[index].content === value) {
		await list.splice(index, 1);
		window.localStorage.setItem(cookieName, JSON.stringify(list));
	}
};

/**
 * 모든 검색어 삭제
 * @param {String} cookieName
 */
export const removeCookieAllSearchKeyword = async (cookieName) => {
	await window.localStorage.removeItem(cookieName);
};

/**
 * 검색어 추가
 * @param {String} cookieName
 * @param {String} value
 */
export const setCookieSearchKeyword = async (cookieName, value) => {
	let list = getCookieSearchKeyword(cookieName);

	if (!list) list = [];

	if (
		list.length &&
		list.filter((item) => {
			return item.content === value;
		}).length
	) {
		//기존에 동일 검색어가 존재하는 경우 삭제 -> 새로 추가;
		list = await list.filter((item) => {
			return item.content !== value;
		});
		await list.push({ content: value, register: new Date() });
	} else {
		await list.push({ content: value, register: new Date() });
	}

	window.localStorage.setItem(cookieName, JSON.stringify(list));
};
