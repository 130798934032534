/**
 * 회원 탈퇴
 */

import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/**
 * 회원 탈퇴
 * @returns
 */
export const deleteMember = async () => {
	const url = `${API_PATHS.MEMBER_PROFILE}`;
	try {
		const result = await api.delete(url);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
