/**
 * 쇼핑내역 - 취소/교환/환불 - 클레임 1건
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

import useModalStack from "src/hooks/useModalStack";
import { CLAIM_TYPE, CLAIM_STATUS, OPTIONS_TYPE, PRODUCT_OPTIONS_TYPE } from "src/data/constEnum";

import Image from "src/pages/component/common/image";
import OrderCancelDetail from "src/pages/claim/modalOrderCancelDetail";
import OrderChangeDetail from "src/pages/claim/modalOrderChangeDetail";
import OrderRefundDetail from "src/pages/claim/modalOrderRefundDetail";
import ModalCommonAlert from "src/pages/common/modalAlert";
import { getProductDetailType } from "src/utils/linmeSearch";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const ClaimCard = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();
	const navigate = useNavigate();
	const modalId = {
		claimDetailCancel: "modalClaimDetailCancel",
		claimDetailChange: "modalClaimDetailChange",
		claimDetailRefund: "modalClaimDetailRefund",
		dataNotExists: "modalCommonAlert",
	};

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 상세보기로 이동
	 * @param {Event} e
	 */
	const handlerClickMoveToDetail = (e) => {
		if (e) {
			//버블링 막기;
			e.preventDefault();
			e.stopPropagation();
		}

		const type = props.claim.type;
		let o = {
			type: "alert",
			component: null,
		};
		switch (type) {
			case CLAIM_TYPE.CANCEL:
				o.id = modalId.claimDetailCancel;
				o.component = <OrderCancelDetail />;
				o.claimNo = props.claim.claimNo;
				break;
			case CLAIM_TYPE.CHANGE:
				o.id = modalId.claimDetailChange;
				o.component = <OrderChangeDetail />;
				o.claimProductNo = props.claim.orderItems[0].claimProductNo;
				break;
			case CLAIM_TYPE.REFUND:
				o.id = modalId.claimDetailRefund;
				o.component = <OrderRefundDetail />;
				o.claimProductNo = props.claim.orderItems[0].claimProductNo;
				break;
			default:
				break;
		}

		modalStack.addModal(o);
	};

	/**
	 * 상품 이미지 클릭 시 상품 상세로 이동
	 * @param {Event} e
	 * @param {Object} item
	 */
	const handlerClickProduct = (e, item) => {
		navigate(
			pathList.product.detail +
				`${getProductDetailType(item?.product?.type)}/` +
				item.product.productNo
		);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		if (props.data) {
			if (props.data.orderNo === props.claim.orderNo) handlerClickMoveToDetail();
			else if (props.data.claimProductNo === props.claim.orderItems[0].claimProductNo)
				handlerClickMoveToDetail();
			else {
				/*/
				modalStack.addModal({
					id: modalId.dataNotExists
					, type: "alert"
					, component: <ModalCommonAlert />
					, text: textInfo.alert.dataNotExists
				});
				//*/
			}
		}
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<li className="claimItem" key={"claim" + props.claim.claimNo}>
			<div className="dateInfo">
				<div className="requestDate">
					{textInfo.myPage.myShopping.claim.type[props.claim.type.toLowerCase()]}&nbsp;
					{textInfo.myPage.myShopping.claim.requestDate} :{" "}
					<span>{moment(props.claim.claimRegistered).format("YYYY/MM/DD")}</span>
				</div>
				<div className="orderDate">
					{textInfo.order.orderDate} :{" "}
					<span>{moment(props.claim.orderRegistered).format("YYYY/MM/DD")}</span>
				</div>
			</div>
			<div className="orderCodeAndButton">
				<div className="orderCode">
					{textInfo.order.orderNo} : <span>{props.claim.orderCode}</span>
				</div>
				<div className="moveToDetail" onClick={(e) => handlerClickMoveToDetail(e)}>
					{textInfo.myPage.myShopping.claim.type[props.claim.type.toLowerCase()]}{" "}
					{textInfo.button.moveToDetail}
				</div>
			</div>
			{props?.claim?.orderItems?.map((item, itemSeq) => (
				<div className="claimOrderItem" key={"item" + itemSeq}>
					{/* 상품정보 */}
					<div className="claimOrderItemInfoContainer">
						<div className="claimOrderItemInfoBox">
							<div className="productImageCover" onClick={(e) => handlerClickProduct(e, item)}>
								<Image src={item?.product?.thumbnail?.webPath} alt={item?.product?.name} />
							</div>
							<div className="claimOrderItemInfo">
								{/* 브랜드명 */}
								<div className="brandName">{item.product?.brand?.name}</div>
								{/* 상품명 */}
								<div className="productName">
									<Link
										to={
											pathList?.product?.detail +
											`${getProductDetailType(item?.product.type)}/` +
											item?.product?.productNo
										}
									>
										{item.product?.name}
									</Link>
								</div>
								{/* 옵션 */}
								{item?.product?.option?.type === PRODUCT_OPTIONS_TYPE.REQUIRED &&
								item?.product?.option?.categoryName1 === "" &&
								item?.product?.option?.optionName1 === "" ? (
									<div className="productQuantity">
										{/* 옵션이 없는 경우 */}
										<span className="option">
											{item?.product?.option?.addPrice > 0
												? "(+" + item?.product?.option?.addPrice + ")"
												: ""}
										</span>
										{item?.quantity}
										<span className="quantityUnit">{textInfo.common.quantityUnit}</span>
									</div>
								) : (
									<>
										{/* 옵션이 있는 경우 */}
										<div className="productOptionAndQuantity">
											<span className="optionType">
												{
													textInfo.product.detail.option.optionType[
														item?.product?.option?.type?.toLowerCase()
													]
												}
											</span>
											<div className="optionList">
												{/* 옵션에 대한 map 함수 삽입하면 되는 부분 */}
												<div className="optionAndQuantity">
													<span className="option">
														{item.product.option.optionFullName +
															(item.product.option.addPrice
																? " (+" + item.product.option.addPrice.toLocaleString() + ")"
																: "")}
													</span>
													<span className="quantity">
														{item.quantity}
														{textInfo.common.quantityUnit}
													</span>
												</div>
											</div>
										</div>
									</>
								)}
							</div>
						</div>
						{/* 총 상품금액 */}
						<div className="price">
							<span>
								{item?.totalPrice?.toLocaleString()}
								<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
							</span>
						</div>
					</div>
					<div className="statusAndDetail" key={"status" + itemSeq}>
						<div className="status">{CLAIM_STATUS?.[item?.status]?.name}</div>
						{/* <div className="abcd" onClick={(e)=>handlerClickCancelRefund(e, item.claimProductNo, item.itemNo )}>환불 요청 취소</div> */}
					</div>
				</div>
			))}
		</li>
	);
};

export default ClaimCard;
