/**
 * 상품 리뷰 쓰기
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useRef, useState } from "react";

import useModalStack from "src/hooks/useModalStack";
import queryClient from "src/api/queryClient";

import {
	API_RESULT_STATUS,
	CHARACTER_LIMIT,
	FILE_TYPE,
	REVIEW_SCORE_MIN,
	REVIEW_SCORE_MAX,
	queryKeys,
} from "src/data/index";
import { modifyReview, writeReview } from "src/api/product/review";
import { requestDeleteFile, requestUploadFile } from "src/utils/linmeFile";
import DrawStarScore from "src/pages/component/product/drawStarScore";
import UploadPhotoBox from "src/pages/component/common/uploadPhotoBox";
import Image from "src/pages/component/common/image";
import ModalCommonAlert from "src/pages/common/modalAlert";
import WhenLeaveConfirmWrite from "src/pages/product/modal/whenLeaveConfirmWrite";
import { removeInnerSpinner, setInnerSpinner } from "src/utils/linmeDisplay";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/product/modal/reviewWriteConfirm.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* 
---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

const htmlInfo = require("src/data/htmlInfo.json");
const textInfo = require("src/data/textInfo.json");

const ReviewWriteConfirm = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();

	const [productInfo, setProductInfo] = useState(null);
	const [fileList, setFileList] = useState(null);
	const [delFileNoList, setDelFileNoList] = useState(null);
	const [fileNoList, setFileNoList] = useState(null);
	const [score, setScore] = useState(5);

	const ref = {
		content: useRef(null),
		contentNow: useRef(null),
		buttonComplete: useRef(null),
	};

	const modalId = {
		cancelConfirm: "modalWhenLeaveConfirmWrite",
		errorFileUpload: "modalCommonAlert",
		modified: "modalCommonAlert",
		added: "modalCommonAlert",
		inputLimitExceeded: "modalCommonAlert",
		inputScore: "modalCommonAlert",
	};

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 사용자 입력 시
	 * @param {Event} e
	 */
	const handlerInputChange = async (e) => {
		if (e) {
			//버블링 막기;
			e.preventDefault();
			e.stopPropagation();
		}

		ref.contentNow.current.innerText = ref.content.current.value.length;

		if (ref.content.current.value.length > CHARACTER_LIMIT.REVIEW) {
			await modalStack.addModal({
				id: modalId.inputLimitExceeded,
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.alert.inputLimitExceeded.replace(
					"___characterLimit___",
					CHARACTER_LIMIT.REVIEW + textInfo.common.characterUnit
				),
			});
			ref.content.current.value = ref.content.current.value.substr(0, CHARACTER_LIMIT.REVIEW);
			ref.contentNow.current.innerText = ref.content.current.value.length;
		}

		if (validation()) {
			ref.buttonComplete.current.classList.add("on");
		} else {
			ref.buttonComplete.current.classList.remove("on");
		}
	};

	/**
	 * 취소 클릭 시
	 * @param {Event} e
	 */
	const handlerClickCancel = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		//modalStack.removeModal( props.id );
		modalStack.addModal({
			id: modalId.cancelConfirm,
			type: "confirm",
			component: <WhenLeaveConfirmWrite />,
			html: htmlInfo.review.cancelWriteConfirm,
			handlerCancel: handlerClickModalCancel,
			handlerExit: handlerClickModalConfirm,
		});
		return false;
	};

	/**
	 * 완료 클릭 시
	 * @param {Event} e
	 */
	const handlerClickComplete = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (ref.buttonComplete.current.classList.contains("on")) {
			const resultValidationContent = await validationContent();
			const resultValidationScore = await validationScore();
			if (resultValidationContent && resultValidationScore) {
				if (props.id.indexOf("Modify") > -1) {
					//리뷰 수정;
					await reviewModify();
				} else {
					//리뷰 등록;
					await reviewWrite();
				}
			}
		}
		return false;
	};

	/**
	 * 취소 클릭 시 뜨는 모달에서 "나가기" 클릭
	 * @param {Event} e
	 */
	const handlerClickModalConfirm = (e) => {
		modalStack.removeModal(modalId.cancelConfirm);
		modalStack.removeModal(props.id);
	};

	/**
	 * 등록/수정 완료 시
	 * @param {Event} e
	 */
	const handlerClickModalComplete = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (props.redraw) await props.redraw();
		if (props.redrawList) await props.redrawList();

		await modalStack.removeModal(modalId.added);
		await modalStack.removeModal(modalId.modified);
		await modalStack.removeModal(props.id);
	};

	/**
	 * 등록/수정 실패 시
	 * @param {Event} e
	 */
	const handlerClickModalFail = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await modalStack.removeModal(modalId.added);
		await modalStack.removeModal(modalId.modified);
		await modalStack.removeModal(props.id);
	};

	/**
	 * 취소 클릭 시 뜨는 모달에서 "취소" 클릭
	 * @param {Event} e
	 */
	const handlerClickModalCancel = (e) => {
		modalStack.removeModal(modalId.cancelConfirm);
	};

	/**
	 * 리뷰 작성 완료 모달 확인 클릭 시
	 * @param {Event} e
	 */
	// const handlerClickModalWriteReviewComplete = async ( e ) => {
	// 	//버블링 막기;
	// 	e.preventDefault();
	// 	e.stopPropagation();

	// 	await modalStack.removeModal( modalId.added );
	// 	await modalStack.removeModal( props.id );
	// };

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 별점 적용 후
	 * @param {Number} score
	 */
	const afterDrawStarScore = async (score) => {
		await setScore(score);
		await handlerInputChange();
	};

	/**
	 * 파일 적용 후
	 * @param {Array} aUploadTarget
	 * @param {Array} aRemoveTarget
	 */
	const afterFileUpload = (aUploadTarget, aRemoveTarget) => {
		setFileList(aUploadTarget);
		if (aRemoveTarget && aRemoveTarget.length) {
			setDelFileNoList(aRemoveTarget);
		}
		handlerInputChange();
	};

	/**
	 * 리뷰 수정 요청
	 * @param {Array} aFileNo
	 */
	const requestModifyReview = async (aFileNo) => {
		try {
			await setInnerSpinner();

			const reviewNo = props.modifyData.reviewItem.reviewNo;
			const itemNo = props.modifyData.reviewItem.itemNo;

			const d = {
				content: ref.content.current.value,
				rate: score,
			};
			if (aFileNo) d.fileNoList = aFileNo;
			if (delFileNoList && delFileNoList.length) d.delFileNoList = delFileNoList;
			const result = await modifyReview(reviewNo, itemNo, d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (result.data && result.data.hasOwnProperty("reviewNo")) {
					// await modalStack.removeModal( props.id );
					await modalStack.addModal({
						id: modalId.modified,
						type: "alert",
						component: <ModalCommonAlert />,
						text: textInfo.alert.modifiedReview,
						button: {
							ok: {
								text: textInfo.button.confirm,
								fn: handlerClickModalComplete,
							},
						},
					});
				} else {
					//실패;
					await modalStack.addModal({
						id: modalId.modified,
						type: "alert",
						component: <ModalCommonAlert />,
						html: htmlInfo.error.reviewModify,
						button: {
							ok: {
								text: textInfo.button.confirm,
								fn: handlerClickModalFail,
							},
						},
					});
				}
			} else {
				window.linmeLog.log(result);
				await modalStack.addModal({
					id: modalId.modified,
					type: "alert",
					component: <ModalCommonAlert />,
					html: htmlInfo.error.reviewModify,
					button: {
						ok: {
							text: textInfo.button.confirm,
							fn: handlerClickModalFail,
						},
					},
				});
			}
		} catch (err) {
			window.linmeLog.error(err);
			return false;
		} finally {
			await removeInnerSpinner();
		}
	};

	/**
	 * 리뷰 작성 요청
	 * @param {Array} aFileNo
	 */
	const requestWriteReview = async (aFileNo) => {
		try {
			await setInnerSpinner();

			const d = {
				content: ref.content.current.value,
				rate: score,
			};
			if (aFileNo) d.fileNoList = aFileNo;
			if (d.rate === -1) d.rate = 0;
			const result = await writeReview(props.itemNo, d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				await modalStack.addModal({
					id: modalId.added,
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.addedReview,
					button: {
						ok: {
							text: textInfo.button.complete,
							fn: handlerClickModalComplete,
						},
					},
				});
				queryClient.invalidateQueries(queryKeys.PRODUCT_REVIEW);
			} else {
				window.linmeLog.log(result);
				const o = {
					id: modalId.modified,
					type: "alert",
					component: <ModalCommonAlert />,
					button: {
						ok: {
							text: textInfo.button.confirm,
							fn: handlerClickModalFail,
						},
					},
				};
				if (result.message) o.text = result.message;
				else o.html = htmlInfo.error.reviewWrite;
				await modalStack.addModal(o);
			}
		} catch (err) {
			window.linmeLog.error(err);
			await modalStack.addModal({
				id: modalId.modified,
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.error.reviewWrite,
				button: {
					ok: {
						text: textInfo.button.confirm,
						fn: handlerClickModalFail,
					},
				},
			});
			return false;
		} finally {
			await removeInnerSpinner();
		}
	};

	/**
	 * 리뷰 수정
	 * @returns
	 */
	const reviewModify = async (aFileNo) => {
		if (fileList && fileList.length) {
			//파일 업로드 및 파일번호 설정;
			const result_fileUpload = await requestUploadFile(FILE_TYPE.REVIEW, fileList).catch(
				async (error) => {
					await modalStack.addModal({
						id: modalId.errorFileUpload,
						type: "alert",
						component: <ModalCommonAlert />,
						html: htmlInfo.error.fileUpload,
					});
					return false;
				}
			);

			if (result_fileUpload && result_fileUpload.length) {
				//파일 정보 설정;
				await setFileNoList(result_fileUpload);
				//리뷰 수정;
				await requestModifyReview(result_fileUpload);
			} else {
				await requestModifyReview();
			}
		} else {
			//파일 수정 없는 경우;
			await requestModifyReview();
		}
	};

	/**
	 * 리뷰 작성
	 * @returns
	 */
	const reviewWrite = async () => {
		if (fileList && fileList.length) {
			//파일 업로드 및 파일번호 설정;
			const result_fileUpload = await requestUploadFile(FILE_TYPE.REVIEW, fileList).catch(
				async (error) => {
					await modalStack.addModal({
						id: modalId.errorFileUpload,
						type: "alert",
						component: <ModalCommonAlert />,
						html: htmlInfo.error.fileUpload,
					});
					return false;
				}
			);

			if (result_fileUpload && result_fileUpload.length) {
				//파일 정보 설정;
				await setFileNoList(result_fileUpload);

				//리뷰 등록;
				await requestWriteReview(result_fileUpload);
			} else {
				await modalStack.addModal({
					id: modalId.errorFileUpload,
					type: "alert",
					component: <ModalCommonAlert />,
					html: htmlInfo.error.fileUpload,
				});
				return false;
			}
		} else {
			//리뷰 등록;
			await requestWriteReview();
		}
	};

	/**
	 * validation
	 * @returns
	 */
	const validation = () => {
		const content = ref.content.current.value;
		if (!content || !content.trim()) {
			return false;
		}

		if (score === -1) {
			return false;
		}
		return true;
	};

	/**
	 * validation - 리뷰 내용
	 * @returns {Boolean}
	 */
	const validationContent = () => {
		const content = ref.content.current.value;
		if (!content || !content.trim()) {
			modalStack.addModal({
				id: modalId.inputScore,
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.pleaseInputReviewContent,
			});
			return false;
		}
		return true;
	};

	/**
	 * validation - 별점
	 * @returns {Boolean}
	 */
	const validationScore = () => {
		if (score === -1) {
			modalStack.addModal({
				id: modalId.inputScore,
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.review.scoreIsUnderMin.replace("___min___", REVIEW_SCORE_MIN),
			});
			return false;
		}
		return true;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		if (props.item) {
			setProductInfo(props.item.product);
		} else if (props.data) {
			setProductInfo(props.data);
		} else if (
			props.modifyData &&
			props.modifyData.reviewItem &&
			props.modifyData.reviewItem.product
		) {
			setProductInfo(props.modifyData.reviewItem.product);
			setScore(props.modifyData.reviewItem.rate);
		}
	}, [props]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="modalContentsContainer reviewWriteContainer">
			<div className="title">{textInfo.review.title}</div>
			{productInfo ? (
				<div className="product">
					<Image src={productInfo?.thumbnail?.webPath} alt={productInfo.name} />
					<div className="productInfo">
						{productInfo.brand && productInfo.brand.name ? (
							<div className="brand">{productInfo.brand.name}</div>
						) : (
							<></>
						)}
						<div className="productName">{productInfo.name}</div>
						{/* <div className="productOption on">
						<div className="optionIcon">옵션</div>
						<div className="selectedOptionName">구성: 뉴트립 콜레스테롤케어 홍국 500 mg 30정 1개 / 추가구성: 50정 1개</div>
					</div> */}
						<div className="starScoreContainer">
							<span>{textInfo.product.review.pleaseEnterStarScore}</span>
							<DrawStarScore
								{...{ afterDrawStarScore: afterDrawStarScore }}
								value={
									props.modifyData &&
									props.modifyData.reviewItem &&
									props.modifyData.reviewItem.rate
										? props.modifyData.reviewItem.rate
										: REVIEW_SCORE_MAX
								}
							/>
						</div>
					</div>
				</div>
			) : (
				<></>
			)}
			<UploadPhotoBox
				afterFileUpload={afterFileUpload}
				data={
					props.modifyData && props.modifyData.reviewItem && props.modifyData.reviewItem.images
						? props.modifyData.reviewItem.images
						: null
				}
			/>
			<div className="userInputContent">
				<div className="label">{textInfo.product.review.pleaseEnterReviewContent}</div>
				<textarea
					className="reviewContent"
					onChange={(e) => handlerInputChange(e)}
					placeholder={textInfo.product.review.pleaseEnterReviewContent}
					ref={ref.content}
					defaultValue={
						props.modifyData && props.modifyData.reviewItem && props.modifyData.reviewItem.content
							? props.modifyData.reviewItem.content
							: ""
					}
				/>
				<div className="contentLimit">
					<span className="contentNow" ref={ref.contentNow}>
						{props.modifyData && props.modifyData.reviewItem && props.modifyData.reviewItem.content
							? props.modifyData.reviewItem.content.length
							: "0"}
					</span>
					&nbsp;/&nbsp;{CHARACTER_LIMIT.REVIEW}
					<span className="characterUnit">{textInfo.common.characterUnit}</span>
				</div>
			</div>
			<ul className="guide">
				<li>{textInfo.product.review.guide}</li>
			</ul>
			<div className="buttonCover">
				<div className="button clickable cancel" onClick={(e) => handlerClickCancel(e)}>
					{textInfo.button.cancel}
				</div>
				<div
					className="button clickable complete"
					onClick={(e) => handlerClickComplete(e)}
					ref={ref.buttonComplete}
				>
					{textInfo.button.complete}
				</div>
			</div>
		</div>
	);
};

export default ReviewWriteConfirm;
