/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import React, { useState } from "react";
import ModalStackContainer from "src/pages/component/modal/modalStackContainer";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

export const ModalStacksSetter = React.createContext();
export const ModalStacks = React.createContext();

function ModalStackProvider({ children }) {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const [modalStack, setModalStack] = useState({});
	const [modalCount, setModalCount] = useState(0);
	const [modalTempValue, setModalTempValue] = useState(false);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	//Array, Object 를 담아서 모달창을 그리는 경우, 제대로 인식이 되지 않아 리렌더가 안되는 현상이 발생.;
	//참조가 아닌 값 자체가 변화되는 count를 추가하여 작동시킴.;
	return (
		<ModalStacksSetter.Provider value={{ setModalStack, setModalCount, setModalTempValue }}>
			<ModalStacks.Provider value={{ modalStack, modalCount, modalTempValue }}>
				{children}
				<ModalStackContainer />
			</ModalStacks.Provider>
		</ModalStacksSetter.Provider>
	);
}

export default ModalStackProvider;
