import { getTimeStamp } from "src/utils/date";

/**
 * linme Log 관련 함수 ( window.console.log )
 */

//----------------------------------------------------------------------------------------------------;

export const linmeLog = {
	info: function () {
		const message = makeMessage(arguments);
		window.console.info(message);
	},
	log: function () {
		const message = makeMessage(arguments);
		window.console.log("%c " + message, "color: #8388cb");
	},
	error: function () {
		const message = makeMessage(arguments);
		window.console.error(message);
	},
	warn: function () {
		const message = makeMessage(arguments);
		window.console.warn(message);
	},
	clear: () => {
		window.console.clear();
	},
};

const makeMessage = (a) => {
	if (a.length) {
		let i = 0;
		const iLen = a.length;
		let io;
		let message = `${getTimeStamp()} ---------- `;
		for (; i < iLen; ++i) {
			io = a[i];
			message += io;
			if (i < iLen - 1) message += ", ";
		}
		return message;
	}
};

//----------------------------------------------------------------------------------------------------;

window.linmeLog = linmeLog;

//----------------------------------------------------------------------------------------------------;

window.console.log(
	`%c

lllllll        iiii
l:::::l       i::::i
l:::::l        iiii
l:::::l
 l::::l      iiiiiii      nnnn  nnnnnnnn            mmmmmmm    mmmmmmm            eeeeeeeeeeee
 l::::l      i:::::i      n:::nn::::::::nn        mm:::::::m  m:::::::mm        ee::::::::::::ee
 l::::l       i::::i      n::::::::::::::nn      m::::::::::mm::::::::::m      e::::::eeeee:::::ee
 l::::l       i::::i      nn:::::::::::::::n     m::::::::::::::::::::::m     e::::::e     e:::::e
 l::::l       i::::i        n:::::nnnn:::::n     m:::::mmm::::::mmm:::::m     e:::::::eeeee::::::e
 l::::l       i::::i        n::::n    n::::n     m::::m   m::::m   m::::m     e:::::::::::::::::e
 l::::l       i::::i        n::::n    n::::n     m::::m   m::::m   m::::m     e::::::eeeeeeeeeee
 l::::l       i::::i        n::::n    n::::n     m::::m   m::::m   m::::m     e:::::::e
l::::::l     i::::::i       n::::n    n::::n     m::::m   m::::m   m::::m     e::::::::e
l::::::l     i::::::i       n::::n    n::::n     m::::m   m::::m   m::::m      e::::::::eeeeeeee
l::::::l     i::::::i       n::::n    n::::n     m::::m   m::::m   m::::m       ee:::::::::::::e
llllllll     iiiiiiii       nnnnnn    nnnnnn     mmmmmm   mmmmmm   mmmmmm         eeeeeeeeeeeeee

`,
	"color: #8388cb"
);

//----------------------------------------------------------------------------------------------------;
