import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/**
 * 결제 완료 여부 확인
 * @param {Number} orderNo
 * @returns
 */
export const checkPaymentCompleted = async (orderNo) => {
	const url = `${API_PATHS.PAYMENT_CHECK_COMPLETED}${orderNo}`;
	try {
		const result = await api.post(url);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 주문에 대한 결제 요청
 * @param {Number} orderNo
 * @param {Object} d
 * <code>
 * {
 * 		deliveryMemo: ""
 * 		, env: "" //MOBILE, PC, APP;
 * 		, isCheckVirtualNumber: true
 * 		, memberAddressNo: 0
 * 		, memberCouponNo: 0
 * 		, paymentMethod: "" //CARD, VBANK, PHONE, REWARD;
 * 		, usedReward: 0
 * }
 * </code>
 * @returns
 */
export const orderPayment = async (orderNo, d) => {
	const url = `${API_PATHS.PAYMENT}${orderNo}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 결제창에서의 결제 결과 데이터 요청 ( 리워드 확정 등 )
 * @param {Number} orderNo
 * @returns
 */
export const orderPaymentResult = async (orderNo) => {
	const url = `${API_PATHS.PAYMENT_RESULT}${orderNo}`;
	try {
		const result = await api.post(url);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
