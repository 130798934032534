/**
 * 건강고민 추천 상품 목록
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */
import useSearchByKeyword from "src/hooks/search/useSearchByKeyword";
import useQueryParams from "src/hooks/useQueryParams";
import PaginationWithQuery from "src/pages/component/paginationWithQuery";
import ProductSortList from "src/pages/component/product/productSortList";
import ProductList from "src/pages/component/product/productList";

import { PAGINATION_DEFAULT_OFFSET } from "src/data/constEnum";
import "src/styles/search/searchResult.scss";

const SearchResult = () => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;
	const query = useQueryParams();
	const searchKeyword = query.get("keyword");
	const { productList, totalPage, totalCount } = useSearchByKeyword();

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="container underTopMenu searchResultDisplay">
			<div className="contents">
				<div className="title">
					<span className="searchKeyword">{`'${searchKeyword || ""}'`}</span> 에 대한 검색 결과{" "}
					<span className="resultCount">
						<span className="resultCountNumber">{totalCount.toLocaleString()}</span>건
					</span>
				</div>
				<ProductSortList />
				<div className="productDisplayContainer">
					<div className="productListContainer">
						{productList && productList.length > 0 ? (
							<ProductList
								{...{ items: productList }}
								limit={PAGINATION_DEFAULT_OFFSET.PRODUCT_LIST}
							/>
						) : (
							<div className="noData"></div>
						)}
					</div>
					{productList && productList.length > 0 && <PaginationWithQuery totalPage={totalPage} />}
				</div>
			</div>
		</div>
	);
};

export default SearchResult;
