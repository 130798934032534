/**
 * 알림 목록
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useAuth from "src/hooks/useAuth";
import {
	setMemberNotificationReadEach,
	setMemberNotificationReadAll,
	getMemberNotificationList,
} from "src/api/member/notification";
import useModalStack from "src/hooks/useModalStack";
import useInfiniteScroll from "src/hooks/useInfiniteScroll";
import ModalCommonAlert from "src/pages/common/modalAlert";
import InfiniteTrigger from "src/pages/component/infiniteTrigger";
import {
	API_RESULT_STATUS,
	DEVICE_TYPE,
	NOTIFICATION_STATUS_TYPE,
	PAGINATION_DEFAULT_OFFSET,
} from "src/data/constEnum";

import { setDateFormatForNotification } from "src/utils/date";
import { getPathInfoFromNotification } from "src/utils/linmeNotification";
import { setBodyScroll } from "src/utils/linmeDisplay";

import { AppContext } from "src/App";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/notification/notificationListDisplay.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");
const textInfo = require("src/data/textInfo.json");

const NotificationListDisplay = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const [notificationList, setNotificationList] = useState([]);

	const auth = useAuth();
	const ref = { list: useRef(null) };
	const modalId = {
		dataCallFail: "modalCommonAlert",
		dataNotExists: "modalCommonAlert",
	};

	const { device } = useContext(AppContext);
	const modalStack = useModalStack();
	const navigate = useNavigate();
	const location = useLocation();

	const infiniteScroll = useInfiniteScroll({
		fetchFn: getMemberNotificationList,
		param: { offset: PAGINATION_DEFAULT_OFFSET.DEFAULT },
		setDataList: setNotificationList,
		ref: ref.list,
	});

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 닫기 버튼 클릭 시
	 * @param {Event} e
	 */
	const handleClickClose = (e) => {
		// //버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (props.handlerClickToggle) props.handlerClickToggle(e);
	};

	/**
	 * 알림 1건 클릭 시
	 * @param {Event} e
	 * @param {Object} item
	 */
	const handlerClickNotification = async (e, item) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		//상태값이 "읽지 않음"인 경우 "읽음" 처리;
		if (item.status === NOTIFICATION_STATUS_TYPE.WAIT.value) {
			requestSetMemberNotificationReadEach(item.notificationNo);
		}

		if (props.handlerClickToggle) props.handlerClickToggle(e);

		//페이지 이동;
		const pageInfo = await getPathInfoFromNotification(item);
		navigate(pageInfo.path, { state: pageInfo.state });
	};

	/**
	 * 알림 모두 읽음 처리
	 * @param {Event} e
	 */
	const handlerClickSetReadAll = async (e) => {
		const unreadList = notificationList.filter((item) => {
			return item.status === NOTIFICATION_STATUS_TYPE.WAIT.value;
		});
		if (unreadList.length === 0) {
			await modalStack.addModal({
				id: modalId.dataNotExists,
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.notificationReadAll,
			});
			return false;
		}

		await requestSetMemberNotificationReadAll();
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 알림 단건에 대하여 읽음 처리 요청
	 * @param {Number} notificationNo
	 */
	const requestSetMemberNotificationReadEach = async (notificationNo) => {
		try {
			const result = await setMemberNotificationReadEach(notificationNo);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				const refetchPage = infiniteScroll.getPageNumber(
					notificationList,
					"notificationNo",
					notificationNo
				);
				await infiniteScroll.refetch(refetchPage);
				return true;
			} else {
				return false;
			}
		} catch (err) {
			window.linmeLog.error(err);
		}
	};

	/**
	 * 모든 알림에 대하여 읽음 처리 요청
	 * @returns
	 */
	const requestSetMemberNotificationReadAll = async () => {
		try {
			const result = await setMemberNotificationReadAll();
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				const elsTarget = ref.list.current.querySelectorAll(
					"." + NOTIFICATION_STATUS_TYPE.WAIT.value.toLowerCase()
				);
				elsTarget.map((item) =>
					item.classList.remove(NOTIFICATION_STATUS_TYPE.WAIT.value.toLowerCase())
				);
				return true;
			} else {
				return false;
			}
		} catch (err) {
			window.linmeLog.log(err);
			return false;
		}
	};

	const handleClickSettings = (e) => {
		if (location.pathname === pathList.myPage.myInfoNotification) {
			handleClickClose(e);
		}
		navigate(pathList.myPage.myInfoNotification);
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		setBodyScroll(!props.isShow);
		if (auth?.isLogin) {
			infiniteScroll.setInitData();
		}
	}, [props.isShow, auth?.isLogin]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className={"notificationListBackground " + (props.isShow ? "on " : "")}>
			<div
				className="button clickable notificationClose"
				onClick={(e) => handleClickClose(e)}
			></div>
			<div className="notificationListCover">
				<div className="title">
					{device === DEVICE_TYPE.MOBILE ? (
						<div className="moveToBack" onClick={(e) => handleClickClose(e)} />
					) : (
						<span></span>
					)}
					{textInfo.notification.title}
					<div className="button clickable settings" onClick={handleClickSettings}></div>
				</div>
				<ul className="notificationList" ref={ref.list}>
					{notificationList && notificationList.length > 0 ? (
						<>
							{notificationList.map((item, index) => (
								<li
									className={
										"notificationItem " +
										(item.status === NOTIFICATION_STATUS_TYPE.WAIT.value
											? item.status.toLowerCase()
											: "")
									}
									key={index}
									onClick={(e) => handlerClickNotification(e, item)}
								>
									<div className="notificationContentContainer">
										<span className="notificationLogo" />
										<span className="notificationContent">{item.content}</span>
									</div>
									<span className="sendTime">{setDateFormatForNotification(item.sendTime)}</span>
								</li>
							))}
							{!infiniteScroll.isLastPage && <InfiniteTrigger ref={infiniteScroll.triggerRef} />}
						</>
					) : (
						<li className="noData">{textInfo.notification.noData}</li>
					)}
				</ul>
			</div>
		</div>
	);
};

export default NotificationListDisplay;
