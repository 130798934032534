/**
 * 주문/결제 - 쿠폰 카드
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { getTimeStamp } from "src/utils/date";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const CouponCard = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="couponCard">
			<div className="couponSelectCover">
				{props.useSelect ? (
					<>
						<input
							className={props.inputRadioElementsName}
							name={props.inputRadioElementsName}
							id={props.inputRadioElementsName + props.content.couponNo}
							type="radio"
							value={props.index}
							disabled={
								props.content.hasOwnProperty("isCheckAvailable") &&
								props.content.isCheckAvailable === false
									? true
									: false
							}
						/>
						<label
							className="couponRadioLabel"
							htmlFor={props.inputRadioElementsName + props.content.couponNo}
						>
							{props.content.couponValue.toLocaleString()}
							<span>
								{textInfo.myPage.myCoupon.unitType[props.content.couponType.toLowerCase()]}
							</span>
						</label>
					</>
				) : (
					<div className="couponDiscountPercent">
						{props.content.couponValue.toLocaleString()}
						<span>{textInfo.myPage.myCoupon.unitType[props.content.couponType.toLowerCase()]}</span>
					</div>
				)}
			</div>
			<div className="couponContents">
				<div className="couponTitle">{props.content.name}</div>
				<div className="couponUseCondition">
					{props.content.desc}{" "}
					{props.content && props.content.maxDiscount && props.content.maxDiscount > -1 ? (
						<span>
							({textInfo.payment.coupon.max} {props.content.maxDiscount.toLocaleString()}
							<span>
								{textInfo.myPage.myCoupon.unitType[props.content.couponType.toLowerCase()]}
							</span>
							)
						</span>
					) : (
						""
					)}
				</div>
				<div className="expirationInfo">
					{getTimeStamp(props.content.endAt, "YYYY-MM-DD hh:mm")}
					{textInfo.myPage.myCoupon.period.end}
				</div>
			</div>
		</div>
	);
};

export default CouponCard;
