/**
 * 아이디찾기
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import useModalStack from "src/hooks/useModalStack";
import { API_RESULT_STATUS, FIND_RESULT_TYPE, VERIFY_ENV, VERIFY_TYPE } from "src/data/constEnum";
import { requestVerify } from "src/api/member/signUp";
import { requestFindId } from "src/api/member/find";
import { isMobile } from "react-device-detect";

import AuthVerifyIframe from "./modal/authVerifyIframe";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/member/findId.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const FindId = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();

	const [ci, setCi] = useState("");
	const [resultType, setResultType] = useState("");
	const [foundEmail, setFoundEmail] = useState("");

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 본인인증 클릭 시
	 * @param {Event} e
	 */
	const handlerClickVerify = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await postRequestVerify().then((data) => {
			modalStack.addModal({
				id: "modalVerify",
				type: "none",
				component: <AuthVerifyIframe {...data} />,
				setDataAfterVerify: setDataAfterVerify,
			});
		});
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 본인인증 후 CI값으로 이메일 조회
	 * @param {String} ci
	 * @returns
	 */
	const postRequestFindId = async (ci) => {
		try {
			const d = { ci: ci };
			const result = await requestFindId(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				return result.data;
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	/**
	 * 본인인증
	 */
	const postRequestVerify = async () => {
		try {
			const d = {
				env: isMobile ? VERIFY_ENV.WEB.MOBILE : VERIFY_ENV.WEB.PC,
				memberNo: 0,
				type: VERIFY_TYPE.FIND_ID,
			};
			const result = await requestVerify(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				return result.data;
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	/**
	 * 본인인증 후처리
	 * @param {Object} d
	 */
	const setDataAfterVerify = async (d) => {
		if (!d.hasOwnProperty("status")) return;
		if (d.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
			setCi(d.ci);
			const result = await postRequestFindId(d.ci);
			if (!result) {
				setResultType(FIND_RESULT_TYPE.FAIL);
			} else {
				if (result.email) {
					setResultType(FIND_RESULT_TYPE.SUCCESS);
					setFoundEmail(result.email);
				}
			}
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		// setFooterFixed();
		return () => {
			// setFooterRelative();
		};
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="container underTopMenu findId">
			<div className="contents">
				<div className="title">{textInfo.member.findId.title}</div>
				<div className={"find " + (resultType === "" ? "on" : "")}>
					<div className="description">{textInfo.member.findId.pleaseVerify}</div>
					<div className="button certification" onClick={(e) => handlerClickVerify(e)}>
						{textInfo.button.verify}
					</div>
				</div>
				<div className={"noResult " + (resultType === FIND_RESULT_TYPE.FAIL ? "on" : "")}>
					<div className="description">{textInfo.member.findId.notFound}</div>
					<Link to={pathList.member.signUp}>
						<div className="button moveToSignUp">{textInfo.button.moveToSignUp}</div>
					</Link>
				</div>
				<div className={"findResult " + (resultType === FIND_RESULT_TYPE.SUCCESS ? "on" : "")}>
					<div className="result">{textInfo.member.findId.idFounded}</div>
					<div className="description">{textInfo.member.findId.pleaseSignIn}</div>
					<input
						type="text"
						className="email"
						name="email"
						id="email"
						value={foundEmail}
						readOnly
					/>
					<div className="button resetPW">
						<Link
							to={pathList.member.findPw}
							state={{
								type: "resetPw",
								ci: ci,
							}}
						>
							{textInfo.button.memberResetPw}
						</Link>
					</div>
					<div className="button login">
						<Link to={pathList.member.login}>{textInfo.button.memberLogin}</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FindId;
