/**
 * 마이페이지 - 내 정보 관리 - 회원 탈퇴
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import useAuth from "src/hooks/useAuth";
import useModalStack from "src/hooks/useModalStack";
import { deleteMember } from "src/api/member/withdrawal";
import { API_RESULT_STATUS } from "src/data/constEnum";
import { setFooterFixed, setFooterRelative } from "src/utils/linmeDisplay";

import ModalCommonAlert from "src/pages/common/modalAlert";
import MoveToBack from "src/pages/component/moveToBack";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myPage/myInfo/myInfoWithdrawal.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

const htmlInfo = require("src/data/htmlInfo.json");
const textInfo = require("src/data/textInfo.json");

const MyPageMyInfoWithdrawal = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const auth = useAuth();
	const navigate = useNavigate();
	const modalStack = useModalStack();
	const ref = {
		check: useRef(null),
		button: useRef(null),
	};

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 체크박스 클릭 시
	 * @param {Event} e
	 */
	const handlerClickCheck = (e) => {
		/*/
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();
		//*/

		const ct = e.currentTarget;
		if (ct.checked) {
			ref.button.current.classList.add("on");
		} else {
			ref.button.current.classList.remove("on");
		}
	};

	/**
	 * 컨펌창에서 취소 클릭
	 * @param {Event} e
	 */
	const handlerClickModalWithdrawalClose = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await modalStack.removeModal("modalCommonConfirm");
		await reset();
	};

	/**
	 * 컨펌창에서 확인 클릭
	 * @param {Event} e
	 */
	const handlerClickModalWithdrawalConfirm = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await requestDeleteMember();
	};

	/**
	 * 탈퇴하기 클릭 시
	 * @param {Event} e
	 */
	const handlerClickWithdrawal = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const ct = e.currentTarget;
		if (ct.classList.contains("on")) {
			await modalStack.addModal({
				id: "modalCommonConfirm",
				type: "confirm",
				component: <ModalCommonAlert />,
				text: textInfo.confirm.areYouSureAboutWithdrawal,
				button: {
					confirm: {
						text: textInfo.button.confirm,
						style: { width: "fit-content", padding: "3px 10px" },
						fn: handlerClickModalWithdrawalConfirm,
					},
					cancel: {
						text: textInfo.button.cancel,
						style: { width: "fit-content", padding: "3px 10px", border: "unset" },
						fn: handlerClickModalWithdrawalClose,
					},
				},
			});
		} else {
			return false;
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 회원 탈퇴 요청
	 * @returns
	 */
	const requestDeleteMember = async () => {
		try {
			const result = await deleteMember();
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				await auth.clear();
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.completedWithdrawal,
				});
				//메인으로 이동;
				await navigate(pathList.main);
				return result.data;
			} else {
				window.linmeLog.log(result);
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: result.message || htmlInfo.error.dataCall,
				});
				return false;
			}
		} catch (error) {
			window.linmeLog.log(error);
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: error?.message || htmlInfo.error.dataCall,
			});
			return false;
		}
	};

	/**
	 * 선택 조건 클리어
	 */
	const reset = () => {
		ref.check.current.checked = false;
		ref.button.current.classList.remove("on");
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		//푸터 위치 관련;
		// setFooterFixed();
		return () => {
			//푸터 위치 관련 해제;
			// setFooterRelative();
		};
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<>
			<div className="myInfoWithdrawalCover">
				<div className="titleWrapper">
					<MoveToBack />
					<div className="title">{textInfo.myPage.withdrawal.title}</div>
				</div>
				<div className="farewellGreetings">
					{auth.isLogin ? (auth.user.nickname ? auth.user.nickname : auth.user.email) : ""}
					{textInfo.myPage.withdrawal.farewellGreetings}
				</div>
				<div className="checkPrecautions">{textInfo.myPage.withdrawal.checkPrecautions}</div>
				<ul className="withdrawalCheckList">
					<li className="withdrawalCheckItem">
						<div className="checkPoint">{textInfo.myPage.withdrawal.checkPoint1}</div>
						<div className="description">{textInfo.myPage.withdrawal.checkPoint1Description}</div>
					</li>
					<li className="withdrawalCheckItem">
						<div className="checkPoint">{textInfo.myPage.withdrawal.checkPoint2}</div>
						<div className="description">{textInfo.myPage.withdrawal.checkPoint2Description}</div>
					</li>
					<li className="withdrawalCheckItem">
						<div className="checkPoint">{textInfo.myPage.withdrawal.checkPoint3}</div>
						<div className="description">{textInfo.myPage.withdrawal.checkPoint3Description}</div>
					</li>
					<li className="withdrawalCheckItem">
						<div className="checkPoint">{textInfo.myPage.withdrawal.checkPoint4}</div>
						<div className="description">{textInfo.myPage.withdrawal.checkPoint4Description1}</div>
						<div className="description">{textInfo.myPage.withdrawal.checkPoint4Description2}</div>
					</li>
				</ul>
				<div className="checkWithdrawalCover">
					<input
						className="checkWithdrawal"
						name="checkWithdrawal"
						id="checkWithdrawal"
						type="checkbox"
						ref={ref.check}
						onClick={(e) => handlerClickCheck(e)}
					/>
					<label htmlFor="checkWithdrawal">{textInfo.myPage.withdrawal.checkWithdrawal}</label>
				</div>
				<div
					className="button clickable withdrawal"
					ref={ref.button}
					onClick={(e) => handlerClickWithdrawal(e)}
				>
					{textInfo.button.withdrawal}
				</div>
			</div>
		</>
	);
};

export default MyPageMyInfoWithdrawal;
