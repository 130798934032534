/**
 * 마이페이지 - 쇼핑 내역 상세
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import moment from "moment";
import { Fragment, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { AppContext } from "src/App";
import {
	API_RESULT_STATUS,
	CLAIM_TYPE,
	PAYMENT_METHOD_TYPE,
	DEVICE_TYPE,
} from "src/data/constEnum";
import useModalStack from "src/hooks/useModalStack";
import Image from "src/pages/component/common/image";
import ProductOptionBox from "src/pages/component/order/productOptionBox";
import { removeSpinner, setSpinner } from "src/utils/linmeDisplay";

import { orderDetail } from "src/api/myShopping/order";
import ModalCommonAlert from "src/pages/common/modalAlert";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myPage/shoppingDetail.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

const htmlInfo = require("src/data/htmlInfo.json");
const textInfo = require("src/data/textInfo.json");

const MyPageShoppingOrderDetail = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const { orderNo } = useParams();
	const modalStack = useModalStack();
	const modalId = {
		error: "modalCommonAlert",
	};
	const { device } = useContext(AppContext);

	const [data, setData] = useState();
	const [convertedData, setConvertedData] = useState([]);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 데이터 호출 실패 시 모달 확인 클릭
	 * @param {Event} e
	 */
	const handlerClickModalErrorConfirm = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.removeModal(modalId.error);
		modalStack.removeModal(props.id);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 주문번호로 정보 호출
	 * @param {Number} orderNo
	 * @returns
	 */
	const requestOrderDetail = async (orderNo) => {
		try {
			await setSpinner();

			const result = await orderDetail(orderNo);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				await setData(result.data);
				await classifyOptions(result.data);
			} else {
				window.linmeLog.error(result);
				await modalStack.addModal({
					id: modalId.error,
					type: "alert",
					component: <ModalCommonAlert />,
					html: htmlInfo.error.dataCall,
					button: {
						ok: {
							text: textInfo.button.confirm,
							fn: handlerClickModalErrorConfirm,
						},
					},
				});
			}
		} catch (err) {
			window.linmeLog.error(err);
			await modalStack.addModal({
				id: modalId.error,
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.error.dataCall,
				button: {
					ok: {
						text: textInfo.button.confirm,
						fn: handlerClickModalErrorConfirm,
					},
				},
			});
		} finally {
			await removeSpinner();
		}
	};

	/**
	 * productNo가 같은 상품을 묶고, 각 상품의 옵션을 분류.
	 * @param {Array} data
	 * @returns {Array}
	 */
	const classifyOptions = (data) => {
		if (!data?.orderItems || data?.orderItems?.length < 1) return;

		const result = [];

		data?.orderItems.forEach((item) => {
			const { productNo, option } = item.product;
			// 현재 처리 중인 제품 번호가 결과 배열에 있는지 확인
			let productIndex = result.findIndex((p) => p.productNo === productNo);

			// 제품 번호가 결과 배열에 없으면 새로운 항목 추가
			if (productIndex === -1) {
				result.push({
					...item,
					productNo,
					newOption: { [option.type]: [] },
				});
				productIndex = result.length - 1;
			}

			// 해당 제품의 옵션 객체에 현재 옵션 타입이 없으면 초기화
			if (!result[productIndex].newOption[option.type]) {
				result[productIndex].newOption[option.type] = [];
			}

			// 옵션 타입 배열에 현재 옵션 추가
			result[productIndex].newOption[option.type].push({
				...option,
				quantity: item.quantity,
				totalPrice: getOptionPrice(item, option),
			});
		});

		setConvertedData(result);
	};

	/**
	 * 옵션 가격 계산
	 */
	const getOptionPrice = (orderData, optionData) => {
		// 필수옵션
		if (optionData?.type === "REQUIRED")
			return orderData.quantity * (orderData.product.salePrice + optionData.addPrice);
		// 선택옵션
		if (optionData?.type === "ADDITIONAL") return orderData.quantity * optionData.addPrice;
	};

	/**
	 * 상품별 구매금액
	 */
	const getTotalPrice = (productData) => {
		let requireTotalPrice = 0;
		let additionalTotalPrice = 0;

		//필수옵션
		productData?.newOption?.REQUIRED?.forEach((option) => {
			requireTotalPrice += (productData?.product?.price + option.addPrice) * option.quantity;
		});

		//선택옵션
		productData?.newOption?.ADDITIONAL?.forEach((option) => {
			additionalTotalPrice += option.addPrice * option.quantity;
		});

		return requireTotalPrice + additionalTotalPrice || 0;
	};

	/**
	 * 상품별 할인구매금액
	 */
	const getTotalSalePrice = (productData) => {
		let requireTotalPrice = 0;
		let additionalTotalPrice = 0;

		//필수옵션
		productData?.newOption?.REQUIRED?.forEach((option) => {
			requireTotalPrice += (productData?.product?.salePrice + option.addPrice) * option.quantity;
		});

		//선택옵션
		productData?.newOption?.ADDITIONAL?.forEach((option) => {
			additionalTotalPrice += option.addPrice * option.quantity;
		});

		return requireTotalPrice + additionalTotalPrice || 0;
	};

	// 주문 금액 합계 ( 상품금액 + 배송비 )
	const getTotalOrderPrice = (data) => {
		return (data?.price?.productPrice || 0) + (data?.price?.deliveryPrice || 0);
	};

	// 할인 금액 합계
	const getTotalDiscountPrice = (data) => {
		if (!data?.price?.discount) return 0;
		return Object?.values(data?.price?.discount)?.reduce((sum, value) => sum + value, 0);
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		if (orderNo) requestOrderDetail(orderNo);
	}, [orderNo]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="shoppingDetailContainer">
			<div className="shoppingDetailTitle">{textInfo.orderDetail.title}</div>
			{/* 주문 정보 */}
			<div className="shoppingDetailOrderInfo">
				<div className="shoppingDetailOrderItem">
					<div className="shoppingDetailOrderInfoTitle">{textInfo.order.orderNo} :</div>
					<div className="shoppingDetailOrderInfoValue number">{data?.orderCode}</div>
				</div>
				<div className="shoppingDetailOrderItem">
					<div className="shoppingDetailOrderInfoTitle">{textInfo.order.orderDate} :</div>
					<div className="shoppingDetailOrderInfoValue">
						{moment(data?.registered).format("YYYY년 MM월 DD일 A hh:mm")}
					</div>
				</div>
			</div>

			{/* 배송지 정보 */}
			<div className="shoppingDetailDelivery">
				<div className="shoppingDetailDeliveryTitle">{textInfo.order.deliveryAddress}</div>
				<div className="shoppingDetailDeliveryContainer">
					{/* 배송지 */}
					<div className="shoppingDetailDeliveryItemTitle">
						{textInfo.orderDetail.address.title}
					</div>
					<div className="shoppingDetailDeliveryItemValue">{data?.address?.name}</div>
					{/* 우편번호 */}
					<div className="shoppingDetailDeliveryItemTitle">
						{textInfo.orderDetail.address.postcode}
					</div>
					<div className="shoppingDetailDeliveryItemValue">{data?.address?.postcode}</div>
					{/* 주소 */}
					<div className="shoppingDetailDeliveryItemTitle">
						{textInfo.orderDetail.address.address1}
					</div>
					<div className="shoppingDetailDeliveryItemValue">{data?.address?.address1}</div>
					{/* 상세주소 */}
					<div className="shoppingDetailDeliveryItemTitle">
						{textInfo.orderDetail.address.address2}
					</div>
					<div className="shoppingDetailDeliveryItemValue">{data?.address?.address2}</div>
					{/* 안심번호 || 전화번호 */}
					<div className="shoppingDetailDeliveryItemTitle">
						{data?.address?.virtualNumber
							? textInfo.orderDetail.address.virtualNumber
							: textInfo.orderDetail.address.phone}
					</div>
					<div className="shoppingDetailDeliveryItemValue">
						{data?.address?.virtualNumber || data?.address?.phone || ""}
					</div>
					{/* 배송 메모 */}
					<div className="shoppingDetailDeliveryItemTitle">{textInfo.orderDetail.address.memo}</div>
					<div className="shoppingDetailDeliveryItemValue">{data?.address?.memo}</div>
				</div>
			</div>

			{/* 주문 상품 */}
			<div className="shoppingDetailProduct">
				<div className="shoppingDetailProductTitle">
					{textInfo.order.orderItems}
					<span>
						{data?.orderItems?.length}
						{textInfo.common.orderCountUnit}
					</span>
				</div>
				{device === DEVICE_TYPE.PC || device === DEVICE_TYPE.TABLET ? (
					<div className="shoppingDetailProductContainer pc">
						{/* 헤더 */}
						<div className="shoppingDetailProductItemTitle">{textInfo.orderDetail.productInfo}</div>
						<div className="shoppingDetailProductItemTitle">
							{textInfo.orderDetail.price.orderPrice}
						</div>
						<div className="shoppingDetailProductItemTitle">
							{textInfo.orderDetail.price.deliveryPrice}
						</div>
						{/* 상품 */}
						{convertedData?.map((item, index) => {
							const productData = item?.product;
							const newOption = item?.newOption;
							return (
								<Fragment key={index}>
									{/* 상품정보 */}
									<div className="shoppingDetailProductItemValue productInfo">
										<div className="shoppingDetailProductItemThumbnail">
											<Image
												src={productData?.thumbnail?.webPath}
												alt={productData?.name}
												type="large"
											/>
										</div>
										<div className="shoppingDetailProductItemInfo">
											<div className="shoppingDetailProductItemBrand">
												{productData?.brand?.name}
											</div>
											<div className="shoppingDetailProductItemName">{productData?.name}</div>
											{/* 옵션 없음 */}
											{newOption?.REQUIRED?.length === 1 &&
											newOption?.REQUIRED?.[0]?.categoryName1 === "" &&
											newOption?.REQUIRED?.[0]?.optionName1 === "" ? (
												<div className="shoppingDetailProductItemQuantity">
													{item?.quantity}
													<span className="quantityUnit">{textInfo.common.quantityUnit}</span>
												</div>
											) : (
												<>
													{/* 필수 옵션 */}
													{newOption?.REQUIRED?.length > 0 && (
														<div className="shoppingDetailProductItemOption">
															<div className="shoppingDetailProductItemOptionTitle">
																{textInfo.product.detail.option.optionType.required}
															</div>
															<div className="shoppingDetailProductItemOptionValue">
																{newOption?.REQUIRED.map((requiredOption, index) => (
																	<ProductOptionBox
																		key={index}
																		option={requiredOption}
																		productData={productData}
																		quantity={item?.quantity}
																	/>
																))}
															</div>
														</div>
													)}
													{/* 선택 옵션 */}
													{newOption?.ADDITIONAL?.length > 0 && (
														<div className="shoppingDetailProductItemOption">
															<div className="shoppingDetailProductItemOptionTitle">
																{textInfo.product.detail.option.optionType.additional}
															</div>
															<div className="shoppingDetailProductItemOptionValue">
																{newOption?.ADDITIONAL.map((additionalOption, index) => (
																	<ProductOptionBox
																		key={index}
																		option={additionalOption}
																		productData={productData}
																		quantity={item?.quantity}
																	/>
																))}
															</div>
														</div>
													)}
												</>
											)}
										</div>
									</div>
									{/* 구매금액 */}
									<div className="shoppingDetailProductItemValue price">
										{getTotalPrice(item) !== getTotalSalePrice(item) && (
											<div className="shoppingDetailProductItemSalePrice">
												<span>{getTotalPrice(item)?.toLocaleString()}</span>
												<span>{textInfo.common.currencyUnit}</span>
											</div>
										)}
										<div className="shoppingDetailProductItemPriceValue">
											<span>{getTotalSalePrice(item)?.toLocaleString()}</span>
											<span>{textInfo.common.currencyUnit}</span>
										</div>
									</div>
									{/* 배송비 */}
									<div className="shoppingDetailProductItemValue deliveryPrice">
										{productData.delivery?.deliveryFeeType === "FREE" ? (
											textInfo.payment.deliveryInfo.free
										) : (
											<>
												{item?.product?.delivery?.deliveryPrice?.toLocaleString()}
												<span>{textInfo.common.currencyUnit}</span>
											</>
										)}
									</div>
								</Fragment>
							);
						})}
					</div>
				) : (
					<div className="shoppingDetailProductContainer mobile">
						{/* 헤더 */}
						<div className="shoppingDetailProductItemTitle">{textInfo.orderDetail.productInfo}</div>
						{/* 상품 */}
						{convertedData?.map((item, index) => {
							const productData = item?.product;
							const newOption = item?.newOption;
							return (
								<div key={index} className="shoppingDetailProductItemValue">
									{/* 상품정보 */}
									<div className="productInfo">
										<div className="shoppingDetailProductItemThumbnail">
											<Image
												src={productData?.thumbnail?.webPath}
												alt={productData?.name}
												type="large"
											/>
										</div>
										<div className="shoppingDetailProductItemInfo">
											<div className="shoppingDetailProductItemBrand">
												{productData?.brand?.name}
											</div>
											<div className="shoppingDetailProductItemName">{productData?.name}</div>
											{newOption?.REQUIRED?.length === 1 &&
												newOption?.REQUIRED?.[0]?.categoryName1 === "" &&
												newOption?.REQUIRED?.[0]?.optionName1 === "" && (
													<div className="shoppingDetailProductItemQuantity">
														{item?.quantity}
														<span className="quantityUnit">{textInfo.common.quantityUnit}</span>
													</div>
												)}
											{/* 구매금액 */}
											<div className="price">
												<div className="shoppingDetailProductItemPriceValue">
													<span>{getTotalSalePrice(item)?.toLocaleString()}</span>
													<span>{textInfo.common.currencyUnit}</span>
												</div>
											</div>
											{/* 배송비 */}
											<div className="deliveryPrice">
												{productData.delivery?.deliveryFeeType === "FREE" ? (
													textInfo.payment.deliveryInfo.free
												) : (
													<>
														{item?.product?.delivery?.deliveryPrice?.toLocaleString()}
														<span>{textInfo.common.currencyUnit}</span>
													</>
												)}
											</div>
										</div>
									</div>
									{/* 옵션 없음 */}
									{!(
										newOption?.REQUIRED?.length === 1 &&
										newOption?.REQUIRED?.[0]?.categoryName1 === "" &&
										newOption?.REQUIRED?.[0]?.optionName1 === ""
									) && (
										<>
											{/* 필수 옵션 */}
											{newOption?.REQUIRED?.length > 0 && (
												<div className="shoppingDetailProductItemOption">
													<div className="shoppingDetailProductItemOptionTitle">
														{textInfo.product.detail.option.optionType.required}
													</div>
													<div className="shoppingDetailProductItemOptionValue">
														{newOption?.REQUIRED.map((requiredOption, index) => (
															<ProductOptionBox
																key={index}
																option={requiredOption}
																productData={productData}
																quantity={item?.quantity}
															/>
														))}
													</div>
												</div>
											)}
											{/* 선택 옵션 */}
											{newOption?.ADDITIONAL?.length > 0 && (
												<div className="shoppingDetailProductItemOption">
													<div className="shoppingDetailProductItemOptionTitle">
														{textInfo.product.detail.option.optionType.additional}
													</div>
													<div className="shoppingDetailProductItemOptionValue">
														{newOption?.ADDITIONAL.map((additionalOption, index) => (
															<ProductOptionBox
																key={index}
																option={additionalOption}
																productData={productData}
																quantity={item?.quantity}
															/>
														))}
													</div>
												</div>
											)}
										</>
									)}
								</div>
							);
						})}
					</div>
				)}
			</div>

			{/* 결제 정보 */}
			<div className="shoppingDetailPayment">
				<div className="shoppingDetailPaymentTitle">{textInfo.orderDetail.payment.title}</div>
				<div className="shoppingDetailPaymentContainer">
					{/* 헤더 */}
					<div className="shoppingDetailPaymentHeader">{textInfo.orderDetail.payment.title}</div>
					<div className="shoppingDetailPaymentGrid">
						{/* 전체 주문금액 */}
						<div className="shoppingDetailPaymentGridItem">
							<div className="shoppingDetailPaymentGridItemTitle">
								{textInfo.orderDetail.payment.totalOrderPrice}
							</div>
							<div className="shoppingDetailPaymentGridItemValue">
								{getTotalOrderPrice(data)?.toLocaleString()}
								{textInfo.common.currencyUnit}
							</div>
						</div>
						{/* 총 할인금액 */}
						<div className="shoppingDetailPaymentGridItem">
							<div className="minusImage" />
							<div className="shoppingDetailPaymentGridItemTitle">
								{textInfo.orderDetail.payment.totalDiscountPrice}
							</div>
							<div className="shoppingDetailPaymentGridItemValue">
								{getTotalDiscountPrice(data)?.toLocaleString()}
								{textInfo.common.currencyUnit}
							</div>
						</div>
						{/* 최종 결제 금액 */}
						<div className="shoppingDetailPaymentGridItem">
							<div className="equalImage" />
							<div className="shoppingDetailPaymentGridItemTitle pink">
								{textInfo.orderDetail.payment.finalPrice}
							</div>
							<div className="shoppingDetailPaymentGridItemValue pink">
								{data?.price?.finalPrice?.toLocaleString() || 0}
								{textInfo.common.currencyUnit}
							</div>
						</div>
						{/* 전체 주문금액 상세 */}
						<div className="shoppingDetailPaymentGridItemDetail">
							{/* 상품금액 */}
							<div className="shoppingDetailPaymentGridItemDetailContainer">
								<div className="shoppingDetailPaymentGridItemDetailTitle">
									{textInfo.orderDetail.payment.productPrice}
								</div>
								<div className="shoppingDetailPaymentGridItemDetailValue">
									{data?.price?.productPrice?.toLocaleString() || 0}
									{textInfo.common.currencyUnit}
								</div>
							</div>
							{/* 총 배송비 */}
							<div className="shoppingDetailPaymentGridItemDetailContainer">
								<div className="shoppingDetailPaymentGridItemDetailTitle">
									{textInfo.orderDetail.payment.deliveryPrice}
								</div>
								<div className="shoppingDetailPaymentGridItemDetailValue">
									<div>
										{data?.price?.deliveryPrice?.toLocaleString()}
										{textInfo.common.currencyUnit}
									</div>
									{data?.price?.isCombinedShip === true && (
										<div className="combinedDeliveryDesc">
											( {textInfo.orderDetail.payment.combinedDeliveryDesc} )
										</div>
									)}
								</div>
							</div>
						</div>
						{/* 총 할인금액 상세 */}
						<div className="shoppingDetailPaymentGridItemDetail">
							{/* 할인액 */}
							<div className="shoppingDetailPaymentGridItemDetailContainer">
								<div className="shoppingDetailPaymentGridItemDetailTitle">
									<div>{textInfo.order.discountCoupon}</div>
									{/* <div className="salePriceInfo">└ 블랙회원 전용 20% 쿠폰</div> */}
								</div>
								<div className="shoppingDetailPaymentGridItemDetailValue">
									{data?.price?.discount?.couponDiscountPrice?.toLocaleString() || 0}
									{textInfo.common.currencyUnit}
								</div>
							</div>
							{/* 리워드 사용액 */}
							<div className="shoppingDetailPaymentGridItemDetailContainer">
								<div className="shoppingDetailPaymentGridItemDetailTitle">
									{textInfo.order.discountReward}
								</div>
								<div className="shoppingDetailPaymentGridItemDetailValue">
									{data?.price?.discount?.rewardDiscountPrice?.toLocaleString() || 0}
									{textInfo.common.rewardUnit}
								</div>
							</div>
						</div>
						{/* 전체 주문금액 상세 */}
						<div className="shoppingDetailPaymentGridItemDetail">
							{/* 결제 수단 */}
							<div className="shoppingDetailPaymentGridItemDetailContainer">
								<div className="shoppingDetailPaymentGridItemDetailTitle bold">
									{textInfo.order.payment.method}
								</div>
								<div className="shoppingDetailPaymentGridItemDetailValue">
									{/* 신용카드 결제 */}
									{data?.payment?.method === PAYMENT_METHOD_TYPE.CARD && (
										<>
											<div>
												{textInfo.payment.payMethod.creditCard} {textInfo.order.payment.pay}
											</div>
											<div>
												<span>
													{data?.payment?.cardInfo?.issuer} ({data.payment.cardInfo.cardNo}) |{" "}
													{data.payment.cardInfo.installment === 0
														? textInfo.payment.paid.card.once
														: data.payment.cardInfo.installment +
														  textInfo.payment.paid.card.installment}
												</span>
											</div>
										</>
									)}
									{/* 리워드 결제 */}
									{data?.payment?.method === PAYMENT_METHOD_TYPE.REWARD && (
										<>
											<div>{textInfo.payment.paid.reward}</div>
										</>
									)}
								</div>
							</div>
							{/* 결제 일시 */}
							<div className="shoppingDetailPaymentGridItemDetailContainer">
								<div className="shoppingDetailPaymentGridItemDetailTitle bold">
									{textInfo.orderDetail.payment.approvalAt}
								</div>
								<div className="shoppingDetailPaymentGridItemDetailValue">
									{moment(data?.registered).format("YYYY.MM.DD HH:mm:ss")}
								</div>
							</div>
							{/* 리워드 혜택 */}
							<div className="shoppingDetailPaymentGridItemDetailReward">
								<div className="shoppingDetailPaymentGridItemDetailRewardContainer">
									<div className="shoppingDetailPaymentGridItemDetailTitle bold">
										{textInfo.orderDetail.benefit.title}
									</div>
									<div className="shoppingDetailPaymentGridItemDetailValue pink">
										{data?.reward?.totalReward?.toLocaleString() || 0}
										{textInfo.common.rewardUnit}
									</div>
								</div>
								<div className="shoppingDetailPaymentGridItemDetailRewardContainer">
									<div className="shoppingDetailPaymentGridItemDetailTitle">
										{textInfo.orderDetail.benefit.confirmReward}
									</div>
									<div className="shoppingDetailPaymentGridItemDetailValue">
										{data?.reward?.confirmReward?.toLocaleString() || 0}
										{textInfo.common.rewardUnit}
									</div>
								</div>
								<div className="shoppingDetailPaymentGridItemDetailRewardContainer">
									<div className="shoppingDetailPaymentGridItemDetailTitle">
										{textInfo.orderDetail.benefit.nutrientReward}
									</div>
									<div className="shoppingDetailPaymentGridItemDetailValue">
										{data?.reward?.nutrientReward?.toLocaleString() || 0}
										{textInfo.common.rewardUnit}
									</div>
								</div>
								<div className="shoppingDetailPaymentGridItemDetailRewardContainer">
									<div className="shoppingDetailPaymentGridItemDetailTitle">
										{textInfo.orderDetail.benefit.reviewReward}
									</div>
									<div className="shoppingDetailPaymentGridItemDetailValue">
										{data?.reward?.reviewReward?.toLocaleString() || 0}
										{textInfo.common.rewardUnit}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* 클레임 */}
			{data?.claimReasons &&
				data?.claimReasons?.length > 0 &&
				data?.claimReasons.map((claim, index) => (
					<div className="shoppingDetailClaim" key={index}>
						<div className="shoppingDetailClaimItem date">
							{moment(claim.registered).format("YYYY.MM.DD HH:mm:ss")}
						</div>
						<div className="shoppingDetailClaimItem productName">{claim.productName}</div>
						<div className="shoppingDetailClaimItem desc">
							{claim?.type === CLAIM_TYPE.CANCEL ? textInfo.orderDetail.claim.cancel : ""}
							{claim?.type === CLAIM_TYPE.CHANGE ? textInfo.orderDetail.claim.change : ""}
							{claim?.type === CLAIM_TYPE.REFUND ? textInfo.orderDetail.claim.refund : ""}
						</div>
						<div className="shoppingDetailClaimItem reason">
							<span className="bold">{textInfo.orderDetail.claim.reason}</span> | {claim.reason}
						</div>
					</div>
				))}
		</div>
	);
};

export default MyPageShoppingOrderDetail;
