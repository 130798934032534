import React, { createContext, useContext } from "react";
import { PRODUCT_STATUS } from "src/data";
import styles from "src/styles/product/detail/productByInfoList.module.scss";
import clsx from "clsx";

const textInfo = require("src/data/textInfo.json");

const ProductBuyInfoContext = createContext();

const ProductBuyInfoMain = ({ children, status }) => {
	return (
		<ProductBuyInfoContext.Provider value={{ status }}>
			<ul className={styles.productBuyInfoList}>{children}</ul>
		</ProductBuyInfoContext.Provider>
	);
};

const TotalPrice = ({ totalSelectedProductPrice }) => {
	if (totalSelectedProductPrice <= 0) return null;

	return (
		<li className={clsx([styles.productBuyInfoItem, styles.totalPriceInfo])}>
			<span>{textInfo.product.detail.totalPriceProduct}</span>
			<div className={styles.totalPrice}>
				<span>{totalSelectedProductPrice.toLocaleString()}</span>
				{textInfo.common.currencyUnit}
			</div>
		</li>
	);
};

const TotalPriceDivider = () => {
	return <div className={styles.totalPriceDivider}></div>;
};

const BuyOptions = ({ onAddToCart, onBuyNow }) => {
	const { status } = useContext(ProductBuyInfoContext);

	return (
		<li
			className={clsx([
				styles.productBuyInfoItem,
				styles.buyType,
				status === PRODUCT_STATUS.DEAD && styles.saleEnd,
				status === PRODUCT_STATUS.SOLDOUT && styles.soldout,
			])}
		>
			{status === PRODUCT_STATUS.SALE && (
				<div className={clsx([styles.buttonCover, styles.buy, styles.on])}>
					<button className={styles.addToCart} onClick={onAddToCart}>
						{textInfo.button.addToCart}
					</button>
					<button className={styles.buyNow} onClick={onBuyNow}>
						{textInfo.button.buyNow}
					</button>
				</div>
			)}
			{status === PRODUCT_STATUS.SOLDOUT && (
				<div className={clsx([styles.buttonCover, styles.soldout, styles.on])}>
					<button className="soldout">{textInfo.product.detail.soldout}</button>
				</div>
			)}
		</li>
	);
};

const BottomInfoContainer = ({ children }) => {
	return <div className={styles.bottomInfoContainer}>{children}</div>;
};

export const ProductBuyInfo = Object.assign(ProductBuyInfoMain, {
	TotalPrice,
	BuyOptions,
	TotalPriceDivider,
	BottomInfoContainer,
});
