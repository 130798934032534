/**
 * 리뷰 관리
 */

import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/**
 * 리뷰 목록
 * @param {Object} d
 * <code>
 * {
 * 		page: {Number}
 * 		, offset: {Number}
 * 		,  sort: {String}//BEST: 인기순, FAST: 최근순;
 * }
 * </code>
 */
export const getReviewList = async (d) => {
	const url = `${API_PATHS.MEMBER_REVIEW}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
