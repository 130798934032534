/**
 * infinite scroll hook
 */

import { useEffect, useState } from "react";

import useModalStack from "src/hooks/useModalStack";

import ModalCommonAlert from "src/pages/common/modalAlert";
import { removeInnerSpinner, setInnerSpinner } from "src/utils/linmeDisplay";

import { API_RESULT_STATUS, PAGINATION_DEFAULT_OFFSET } from "src/data/constEnum";
import useInView from "src/hooks/useInView";

const htmlInfo = require("src/data/htmlInfo.json");

const useInfiniteScroll = (props) => {
	const { fetchFn, param, setDataList, ref } = props;

	const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);
	const [paginationIsLastPage, setPaginationIsLastPage] = useState(false);

	const modalStack = useModalStack();
	const [page, setPage] = useState(1);
	// const [page, setPage] = useScroll(paginationIsLastPage, ref);

	const [triggerRef, inView] = useInView({ threshold: 0.5 });

	/**
	 * 데이터 가져오기
	 * @param {number} pageNo
	 */
	const getDataList = async (pageNo) => {
		try {
			await setInnerSpinner();

			const d = param;
			d.page = pageNo || 1;

			const result = await fetchFn(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (result?.data?.content) {
					setDataList((prevList) =>
						result?.data?.page === 1 ? result.data.content : [...prevList, ...result.data.content]
					);

					setPaginationCurrentPage(result.data.page);
					setPaginationIsLastPage(result.data.isLastPage);
				} else {
					await setDataList([]);
				}
			} else {
				setPaginationIsLastPage(true);
				await window.linmeLog.error(result);
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					html: htmlInfo.error.dataCall,
				});
				return false;
			}
		} catch (err) {
			setPaginationIsLastPage(true);
			await window.linmeLog.error(err);
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.error.dataCall,
			});
			return false;
		} finally {
			await removeInnerSpinner();
		}
	};

	/**
	 * 특정 페이지 데이터 새로고침
	 * @param {number} pageNo
	 */
	const refetch = async (pageNo) => {
		try {
			await setInnerSpinner();

			const d = param;
			const offset = d.offset || PAGINATION_DEFAULT_OFFSET.DEFAULT;
			d.page = pageNo || 1;

			const result = await fetchFn(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (result?.data?.content) {
					setDataList((prevList) => {
						const newList = [...prevList];
						const startIndex = (pageNo - 1) * offset;
						// 기존 리스트 제거 후 새로운 리스트 삽입;
						newList.splice(startIndex, result?.data?.content?.length, ...result.data.content);
						return newList;
					});
				}
			} else {
				await window.linmeLog.error(result);
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					html: htmlInfo.error.dataCall,
				});
				return false;
			}
		} catch (err) {
			await window.linmeLog.error(err);
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.error.dataCall,
			});
			return false;
		} finally {
			await removeInnerSpinner();
		}
	};

	/**
	 * 특정 데이터의 페이지 번호 가져오기
	 * @param {Array} dataList
	 * @param {string} key
	 * @param {string} value
	 * @returns {number}
	 * @example const pageNo = getPageNumber(dataList, "orderNo", 1234);
	 */
	const getPageNumber = (dataList, key, value) => {
		const index = dataList.findIndex((order) => order?.[key] === value);
		return Math.ceil((index + 1) / (param.offset || PAGINATION_DEFAULT_OFFSET.DEFAULT));
	};

	/**
	 * 초기 데이터 설정
	 */
	const setInitData = (scrollTop = true) => {
		if (scrollTop === true) {
			if (ref?.current) ref.current.scrollTo(0, 0);
			else window.scrollTo(0, 0);
		}

		setPaginationCurrentPage(1);
		setPaginationIsLastPage(false);
		setPage(1);
		getDataList(1);
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		if (inView) {
			setPage((prev) => prev + 1);
		}
	}, [inView]);

	//페이지 변경 시 실행;
	useEffect(() => {
		if (paginationIsLastPage !== true && page > paginationCurrentPage) {
			getDataList(paginationCurrentPage + 1);
		}
	}, [page]);

	return {
		setInitData,
		refetch,
		getPageNumber,
		triggerRef,
		isLastPage: paginationIsLastPage,
	};
};

export default useInfiniteScroll;
