/**
 * 상품 상세 - 간략정보 - 옵션 선택
 */

import { useEffect, useState } from "react";
import _ from "lodash";

import useModalStack from "src/hooks/useModalStack";

import { PRODUCT_OPTIONS_TYPE } from "src/data/constEnum";

import CustomSelectBox from "src/pages/component/common/customSelectBox";
import ModalCommonAlert from "src/pages/common/modalAlert";
import CustomNumberStepper from "src/pages/component/common/customNumberStepper";
import styles from "src/styles/product/detail/productDetailOptions.module.scss";
import clsx from "clsx";

const textInfo = require("src/data/textInfo.json");

const ProductDetailOptions = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const {
		data,
		selectedResultRequired,
		setSelectedResultRequired,
		selectedResultAdditional,
		setSelectedResultAdditional,
		optionCount,
		setOptionCount,
		isSingleOption,
		isReverse,
	} = props;

	const modalStack = useModalStack();

	const [requireOption, setRequireOption] = useState([]); // 필수 옵션 선택 목록
	const [additionalOption, setAdditionalOption] = useState([]); // 선택 옵션 선택 목록

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 옵션 값 선택 시 다음 옵션 설정
	 */
	const handlerChangeOption = (type, id, index) => {
		const isRequired = type === PRODUCT_OPTIONS_TYPE?.REQUIRED;
		const optionState = [...(isRequired ? requireOption : additionalOption)];

		// optionNo로 해당하는 option 가져오기
		const checkedOption = data.options.find((option) => String(option.optionNo) === String(id));
		// 선택한 옵션의 다음 옵션들 가져오기
		const filteredOptions = data.options.filter(
			(option) => option?.[`optionName${index + 1}`] === checkedOption?.[`optionName${index + 1}`]
		);
		// 다음 옵션들 중복 제거
		const uniqOptions = _.uniqBy(filteredOptions, `optionName${index + 2}`);

		// 업데이트
		const nextState = [...optionState];
		const nowOption = nextState[index];
		const nextOption = nextState[index + 1];

		nowOption.checkedName = checkedOption?.[`optionName${index + 1}`];
		nowOption.checkedOptionNo = checkedOption.optionNo;
		nowOption.checkedAddPrice = checkedOption.addPrice;
		nowOption.checkedStock = checkedOption.stock;

		// 마지막 옵션일 경우
		if (optionState[index]?.isLastDepth) {
			handlerAddOption(type, nextState);
		} else {
			if (nowOption?.isLastDepth !== true) nextOption.options = uniqOptions;
			if (isRequired) {
				setRequireOption(nextState);
			} else {
				setAdditionalOption(nextState);
			}
		}
	};

	/**
	 * 옵션 선택 완료 시 result 추가
	 */
	const handlerAddOption = async (type, newOption) => {
		const isRequired = type === PRODUCT_OPTIONS_TYPE?.REQUIRED;
		const selectedResultState = [
			...(isRequired ? selectedResultRequired : selectedResultAdditional),
		];

		const finalOption = newOption[newOption?.length - 1];
		const targetOptionIndex = selectedResultState.findIndex(
			(item) => item.optionNo === finalOption?.checkedOptionNo
		);

		// 옵션이 이미 선택되었으면 수량 증가
		if (targetOptionIndex !== -1) {
			// 재고 확인
			if (
				selectedResultState[targetOptionIndex].quantity + 1 >
				selectedResultState[targetOptionIndex].stock
			) {
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.numberStepper.overMax,
				});
				initData();
				return;
			}

			const updatedResultState = [...selectedResultState];
			updatedResultState[targetOptionIndex] = {
				...updatedResultState[targetOptionIndex],
				quantity: updatedResultState[targetOptionIndex].quantity + 1,
			};

			if (isRequired) {
				setSelectedResultRequired(updatedResultState);
			} else {
				setSelectedResultAdditional(updatedResultState);
			}
		} else {
			const newData = {
				optionNo: finalOption?.checkedOptionNo,
				stock: finalOption?.checkedStock,
				addPrice: finalOption?.checkedAddPrice,
				fullName: newOption.map((item) => `${item.name} : ${item.checkedName}`).join(" / "),
				quantity: 1,
			};

			// 선택한 옵션을 결과에 추가
			if (isRequired) {
				// 필수옵션 : 할인금액 + 옵션별 추가금
				newData.totalPrice = data.salePrice + finalOption?.checkedAddPrice;
				setSelectedResultRequired((prevState) => [...prevState, newData]);
			} else {
				// 선택옵션 : 옵션별 추가금
				newData.totalPrice = finalOption?.checkedAddPrice;
				setSelectedResultAdditional((prevState) => [...prevState, newData]);
			}
		}
		// 선택한 옵션 초기화
		initData();
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 초기값 설정
	 */
	const initData = () => {
		// 필수 옵션
		const convertedRequiredOption = convertOption(PRODUCT_OPTIONS_TYPE?.REQUIRED);
		// 선택 옵션
		const convertedAdditionalOption = convertOption(PRODUCT_OPTIONS_TYPE?.ADDITIONAL);

		setRequireOption(convertedRequiredOption);
		setAdditionalOption(convertedAdditionalOption);
	};

	/**
	 * 옵션 convert
	 */
	const convertOption = (optionType) => {
		// 필수/선택 옵션 추출
		const optionCategoryData = data?.optionCategory.filter(
			(item) => item.type?.toUpperCase() === optionType
		);
		// 옵션 데이터 변환
		const convertedOption = optionCategoryData.map((item, index) => {
			// 첫번째 옵션 가져오기
			const getFirstOption = () => {
				const optionsData = data?.options.filter((item) => item.type?.toUpperCase() === optionType);
				return _.uniqBy(optionsData, "optionName1");
			};

			const result = {
				name: item.name,
				checkedName: null,
				checkedOptionNo: null,
				checkedAddPrice: 0,
				checkedStock: 0,
				isLastDepth: optionCategoryData.length === index + 1,
			};

			// 첫번째 옵션일 경우 옵션 데이터 추가
			if (index === 0) result.options = getFirstOption();
			return result;
		});

		return convertedOption;
	};

	/**
	 * 옵션 미설정 상품 재고 개수
	 */
	const getMaxStockCount = () => {
		return data?.options?.[0]?.stock;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		initData();
	}, [data]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className={styles.productDetailOptionSelector}>
			<div className={styles.productOptionsContainer}>
				{isSingleOption ? (
					//  옵션 미설정
					<div className={styles.productBuyInfoItem}>
						<CustomNumberStepper
							min={1}
							max={getMaxStockCount() || null}
							count={optionCount}
							setCount={(num) => setOptionCount(num)}
						/>
					</div>
				) : (
					<>
						{/* 필수 옵션 */}
						{requireOption?.map((optionData, index) => {
							return (
								<div className={styles.productOptionCover} key={index}>
									<div className={clsx([styles.productOptionTitle, require])}>
										{textInfo.product.detail.option.optionType.required} | {optionData.name}
									</div>
									<CustomSelectBox
										options={optionData?.options?.map((option) => ({
											value: option.optionNo,
											label: option?.[`optionName${index + 1}`],
											addPrice: option?.addPrice,
											stock: option?.stock,
											disabled: option.stock < 1,
										}))}
										value={optionData?.checkedOptionNo}
										placeholder={textInfo.product.detail.option.selectOption}
										onChange={(item) =>
											handlerChangeOption(PRODUCT_OPTIONS_TYPE?.REQUIRED, item.value, index)
										}
										isReverse={isReverse}
									/>
								</div>
							);
						})}
						{/* 선택 옵션 */}
						{additionalOption?.map((optionData, index) => {
							return (
								<div className={styles.productOptionCover} key={index}>
									<div className={styles.productOptionTitle}>
										{textInfo.product.detail.option.optionType.additional} | {optionData.name}
									</div>
									<CustomSelectBox
										options={optionData?.options?.map((option) => ({
											value: option.optionNo,
											label: option?.[`optionName${index + 1}`],
											addPrice: option?.addPrice,
											stock: option?.stock,
											disabled: option.stock < 1,
										}))}
										value={optionData?.checkedOptionNo}
										placeholder={textInfo.product.detail.option.selectOption}
										onChange={(item) =>
											handlerChangeOption(PRODUCT_OPTIONS_TYPE?.ADDITIONAL, item.value, index)
										}
										isReverse={isReverse}
									/>
								</div>
							);
						})}
					</>
				)}
			</div>
		</div>
	);
};

export default ProductDetailOptions;
