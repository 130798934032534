/**
 * 건강고민 추천 상품 목록
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";

import useModalStack from "src/hooks/useModalStack";
import {
	API_RESULT_STATUS,
	PAGINATION_DEFAULT_OFFSET,
	PRODUCT_LIST_ORDER,
} from "src/data/constEnum";
import { getLastPageNumber } from "src/utils/number";
import { setScrollTop, setInnerSpinner, removeInnerSpinner } from "src/utils/linmeDisplay";

import ProductListOrder from "src/pages/component/product/productListOrder";
import ProductList from "src/pages/component/product/productList";
import Pagination from "src/pages/component/pagination";
import ModalCommonAlert from "src/pages/common/modalAlert";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

const htmlInfo = require("src/data/htmlInfo.json");
// const textInfo = require( "src/data/textInfo.json" );

const ProductListPagination = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();

	const [listOrderSelected, setListOrderSelected] = useState(
		props && props.param && props.param.sort ? props.param.sort : PRODUCT_LIST_ORDER[0].value
	);
	const [list, setList] = useState(null);

	const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);
	const [paginationOffset, setPaginationOffset] = useState(
		props && props.param && props.param.offset
			? props.param.offset
			: PAGINATION_DEFAULT_OFFSET.PRODUCT_LIST
	);
	const [paginationTotalCount, setPaginationTotalCount] = useState(0);
	const [paginationLastPage, setPaginationLastPage] = useState(0);
	const [paginationIsLastPage, setPaginationIsLastPage] = useState(false);
	// const [ paginationType, setPaginationType ] = useState( PAGINATION_TYPE.PAGE );

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 데이터 가져오기
	 * @param {Object} p
	 */
	const getDataList = async (p) => {
		try {
			if (!props.getDataList) return false;
			await setInnerSpinner();
			let d = props.param;
			d.page = p && p.pageNo ? p.pageNo : 1;
			d.sort = listOrderSelected ? listOrderSelected : PRODUCT_LIST_ORDER[0].value;
			const result = await props.getDataList(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (result.data.content) {
					await setList(result.data.content);
					await setPaginationCurrentPage(result.data.page);
					await setPaginationTotalCount(result.data.totalCount);
					await setPaginationIsLastPage(result.data.isLastPage);
					await setPaginationLastPage(getLastPageNumber(result.data.totalCount, paginationOffset));

					if (props.setResultTotalCount) {
						props.setResultTotalCount(result.data.totalCount);
					}
				}
			} else {
				await window.linmeLog.error(result);
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					html: htmlInfo.error.dataCall,
				});
				return false;
			}
		} catch (err) {
			await window.linmeLog.error(err);
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.error.dataCall,
			});
			return false;
		} finally {
			await removeInnerSpinner();
		}
	};

	/**
	 * 다시 그리기
	 * @param {Number} pageNo
	 */
	const redraw = async (pageNo) => {
		await getDataList({ pageNo });
		await setScrollTop();
	};

	/**
	 * 상품 목록 정렬 선택 결과
	 * @param {String} value
	 */
	const setProductListOrderSelected = (value) => {
		setListOrderSelected(value);
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		getDataList({ pageNo: 1 });
	}, [props.param, props.getDataList, listOrderSelected]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="productDisplayContainer">
			<ProductListOrder
				{...{
					selected: listOrderSelected,
					afterSelected: setProductListOrderSelected,
				}}
			/>
			<div className="productListContainer">
				{list && list.length ? (
					<>
						<ProductList
							{...{ items: list }}
							limit={props?.param?.offset || PAGINATION_DEFAULT_OFFSET.PRODUCT_LIST}
						/>
						<Pagination
							{...{
								currentPage: paginationCurrentPage,
								offset: paginationOffset,
								totalCount: paginationTotalCount,
								lastPage: paginationLastPage,
								isLastPage: paginationIsLastPage,
								redraw: redraw,
							}}
						/>
					</>
				) : (
					<div className="noData"></div>
				)}
			</div>
		</div>
	);
};

export default ProductListPagination;
