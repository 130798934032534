/**
 * 커스텀 셀렉트 박스
	<CustomSelectBox
		options={[{ value: 1, label: "옵션", disabled: false }]}
		disabled={false}
		value={1}
		placeholder="옵션 선택"
		onChange={( item ) => setData(item.value)}
	/>
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import clsx from "clsx";
import { useState, useRef, useEffect } from "react";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/customSelectBox.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
// const textInfo = require( "src/data/textInfo.json" );

const CustomSelectBox = ({
	options,
	disabled,
	value,
	placeholder,
	onChange,
	isReverse = false,
}) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const [isOpen, setIsOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState({});

	const selectBoxRef = useRef(null);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 옵션 클릭 시
	 */
	const handleOptionClick = (option) => {
		setSelectedOption(option);
		setIsOpen(false);

		if (onChange) {
			onChange(option);
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 외부 클릭 시 셀렉트 박스 닫기
	 */
	const handleClickOutside = (event) => {
		if (selectBoxRef.current && !selectBoxRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 외부 클릭 감지
	 */
	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	/**
	 * value 전달 시 selectedOption 설정
	 */
	useEffect(() => {
		if (value) {
			setSelectedOption(options?.find((option) => option.value === value));
		} else {
			setSelectedOption({});
		}
	}, [options, value]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="select-container" ref={selectBoxRef}>
			<div
				className={`selected-option ${isOpen === true ? "open" : ""} ${
					!selectedOption?.label && placeholder ? "placeholder" : ""
				} ${disabled === true ? "disabled" : ""}`}
				onClick={() => {
					if (disabled !== true) setIsOpen(!isOpen);
				}}
			>
				<div className="select-option-value">
					{selectedOption?.label ? selectedOption?.label : placeholder ? placeholder : ""}
				</div>
				<div className={`select-arrow ${isOpen === true ? "open" : ""}`} />
			</div>
			{isOpen && options?.length > 0 && (
				<ul className={clsx(["options-list", isReverse && "reverse"])}>
					{options?.map((option, index) => {
						// 비활성화 옵션일 경우
						if (option?.disabled === true) {
							return (
								<li key={index} className="options-list-option disabled">
									{option.label}
									{option?.addPrice ? ` (+${option?.addPrice}원)` : ""}
									{option?.stock ? ` (재고 : ${option?.stock}개)` : ""}
								</li>
							);
						}

						// 활성화 옵션일 경우
						return (
							<li
								key={index}
								className="options-list-option"
								onClick={() => handleOptionClick(option)}
							>
								{option.label}
								{option?.addPrice ? ` (+${option?.addPrice}원)` : ""}
								{option?.stock ? ` (재고 : ${option?.stock}개)` : ""}
							</li>
						);
					})}
				</ul>
			)}
		</div>
	);
};

export default CustomSelectBox;
