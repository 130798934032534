/**
 * 마이린미 - 메뉴 슬라이더
 */

import { useNavigate } from "react-router-dom";
import Swiper from "../component/swiper";
import { SwiperSlide } from "swiper/react";
import useDeviceType from "src/hooks/useDeviceType";

import { DEVICE_TYPE } from "src/data";
const pathList = require("src/path/pathJSON.json");
const textInfo = require("src/data/textInfo.json");
import "src/styles/myLinme/menuSlider.scss";

const MyLinmeMenuSlider = (props) => {
	const { mobileType } = useDeviceType();
	const navigate = useNavigate();

	const handlerClickMenu = (e, menu) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		switch (menu) {
			case "simpleHealthCheck":
				//심플 건강 체크하기;
				navigate(pathList.myLinme.simpleHealthCheck);
				break;
			case "mbti":
				//나의 건강유형 분석하기;
				if (props?.isFinishMBTI) {
					//결과 보러 가기;
					navigate(pathList.myLinme.MBTIResult);
				} else {
					//테스트 하러 가기;
					navigate(pathList.myLinme.MBTITest);
				}
				break;
			case "survey":
				//나의 건강상태 분석하기;
				if (props?.isFinishSurvey) {
					//결과 보러 가기;
					navigate(pathList.myLinme.surveyResult);
				} else {
					//테스트 하러 가기;
					navigate(pathList.myLinme.survey);
				}
				break;
			default:
				break;
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="myLinme-slider-container">
			<Swiper slidesPerView={mobileType === DEVICE_TYPE.PC ? 2.5 : "auto"} spaceBetween={30}>
				<SwiperSlide>
					<div
						className="myLinmeSlideItem"
						onClick={(e) => {
							handlerClickMenu(e, "simpleHealthCheck");
						}}
					>
						<div className="myLinmeImage" />
						<div className="myLinmeTitle">{textInfo.myLinme.menu.simpleHealthCheck.title}</div>
						<div className="myLinmeSubTitle">
							{textInfo.myLinme.menu.simpleHealthCheck.description}
						</div>
						<div className="myLinmeMoveWrapper">
							<div className="myLinmeMove" />
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div
						className="myLinmeSlideItem"
						onClick={(e) => {
							handlerClickMenu(e, "mbti");
						}}
					>
						<div className="myLinmeImage" />
						<div className="myLinmeTitle">{textInfo.myLinme.menu.mbti.title}</div>
						<div className="myLinmeSubTitle">{textInfo.myLinme.menu.mbti.description}</div>
						<div className="myLinmeMoveWrapper">
							<div className="myLinmeMove" />
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div
						className="myLinmeSlideItem"
						onClick={(e) => {
							handlerClickMenu(e, "survey");
						}}
					>
						<div className="myLinmeImage" />
						<div className="myLinmeTitle">{textInfo.myLinme.menu.survey.title}</div>
						<div className="myLinmeSubTitle">{textInfo.myLinme.menu.survey.description}</div>
						<div className="myLinmeMoveWrapper">
							<div className="myLinmeMove" />
						</div>
					</div>
				</SwiperSlide>
			</Swiper>
		</div>
	);
};

export default MyLinmeMenuSlider;
