/**
 * 리워드 - 카드
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import useAuth from "src/hooks/useAuth";
import { API_RESULT_STATUS } from "src/data/constEnum";
import { getRewardStatus } from "src/api/member/reward";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */

const pathList = require("src/path/pathJSON.json");

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const RewardCard = () => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const auth = useAuth();

	const [rewardData, setRewardData] = useState({});

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		/**
		 * 리워드 기본 정보 호출
		 */
		const getData = async () => {
			if (auth.isLogin === true) {
				try {
					const result = await getRewardStatus();
					if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
						setRewardData(result.data);
					} else {
						window.linmeLog.log(result);
					}
				} catch (error) {
					window.linmeLog.log(error);
				}
			}
		};
		getData();
	}, [auth.isLogin]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="rewardCardCover">
			<div className="rewardCard">
				<div className="rewardTopInfo">
					<div className="rewardRepresentativeImage"></div>
					<div className="rewordValueContainer">
						<div className="rewardCurrentValue">
							{rewardData?.availableReward ? rewardData.availableReward.toLocaleString() : "-"}
						</div>
						<div className="rewardUnit">{textInfo.reward.reward}</div>
					</div>
				</div>
				<ul className="rewardStatusList">
					<li className="rewardStatusItem">
						<div className="label">{textInfo.reward.availableReward}</div>
						<div className="data">
							<span className="rewardValue available">
								{rewardData?.availableReward ? rewardData.availableReward.toLocaleString() : "-"}
							</span>
							<span className="unit">{textInfo.common.rewardUnit}</span>
						</div>
					</li>
					<li className="rewardStatusItem">
						<div className="label">{textInfo.reward.tobeReward}</div>
						<div className="data">
							<span className="rewardValue beAccumulated">
								{rewardData?.tobeReward ? rewardData.tobeReward.toLocaleString() : "-"}
							</span>
							<span className="unit">{textInfo.common.rewardUnit}</span>
						</div>
					</li>
					<li className="rewardStatusItem">
						<div className="label">{textInfo.reward.usedReward}</div>
						<div className="data">
							<span className="rewardValue totalUsage">
								{rewardData?.usedReward ? rewardData.usedReward.toLocaleString() : "-"}
							</span>
							<span className="unit">{textInfo.common.rewardUnit}</span>
						</div>
					</li>
					<li className="rewardStatusItem">
						<div className="label">{textInfo.reward.savedReward}</div>
						<div className="data">
							<span className="rewardValue totalAccumulatedAmount">
								{rewardData?.savedReward ? rewardData.savedReward.toLocaleString() : "-"}
							</span>
							<span className="unit">{textInfo.common.rewardUnit}</span>
						</div>
					</li>
				</ul>
				<div className="moveToRewardHistory">
					<Link to={pathList.reward.history}>{textInfo.reward.moveToHistory}</Link>
					<span className="arrowIcon" />
				</div>
			</div>
			<ul className="rewardDescriptionList">
				<li className="rewardDescriptionItem">{textInfo.reward.content1}</li>
				<li className="rewardDescriptionItem">{textInfo.reward.content2}</li>
				<li className="rewardDescriptionItem">{textInfo.reward.content3}</li>
				<li className="rewardDescriptionItem">{textInfo.reward.content4}</li>
			</ul>
		</div>
	);
};

export default RewardCard;
