/**
 * 주문 취소
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useModalStack from "src/hooks/useModalStack";
import { COLOR } from "src/data/constEnum";
import { orderPaymentResult } from "src/api/order/payment";
import { getClaimReason } from "src/api/myShopping/claim";
import { expectedRefundAmount, orderCancel } from "src/api/myShopping/cancel";
import { makeClaimReasonSelectOptions } from "src/utils/linmeClaim";
import { API_RESULT_STATUS, CLAIM_TYPE, PRODUCT_OPTIONS_TYPE } from "src/data/constEnum";

import ClaimProgressStage from "src/pages/component/claim/claimProgressStage";
import ProductOptionBox from "src/pages/component/order/productOptionBox";
import ModalCommonAlert from "src/pages/common/modalAlert";
import Image from "src/pages/component/common/image";

import CheckOrderProduct from "src/pages/claim/checkOrderProduct";
import Reason from "src/pages/claim/reason";
import { orderDetail } from "src/api/myShopping/order";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/claim/claimOrderCancel.scss";
import { removeSpinner, setSpinner } from "src/utils/linmeDisplay";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ;
---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

const htmlInfo = require("src/data/htmlInfo.json");
const textInfo = require("src/data/textInfo.json");

const ClaimOrderCancel = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const location = useLocation();
	const modalStack = useModalStack();
	const navigate = useNavigate();
	const [order, setOrder] = useState(null);
	const [dataList, setDataList] = useState(null);
	// const [ paymentResult, setPaymentResult ] = useState(null);
	const [cancelReasonNo, setCancelReasonNo] = useState(null);
	const [cancelReasonList, setCancelReasonList] = useState(null);
	const [expectedRefundInfo, setExpectedRefundInfo] = useState(null);
	const [totalPrice, setTotalPrice] = useState(null);
	const [itemNoList, setItemNoList] = useState(null);
	const ref = {
		reason: useRef(null),
		reasonDetail: useRef(null),
	};

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 취소요청 클릭 시
	 * @param {Event} e
	 */
	const handlerClickCancel = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const check = await validation();
		if (check) {
			await requestOrderCancel();
		}
	};

	/**
	 * 취소 오류 시
	 * @param {Event} e
	 */
	const handlerClickErrorModalConfirm = async () => {
		await modalStack.removeModal("modalCommonAlert");
		navigate(pathList.myPage.shoppingOrder);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 총 상품 금액, 총 배송비 계산
	 * @param {Array} items
	 */
	const calcData = async (items) => {
		let i = 0;
		const iLen = items.length;
		let io; //items[ i ];
		let productPrice = 0;
		let deliveryPrice = 0;
		for (; i < iLen; ++i) {
			io = items[i];
			productPrice += io.totalPrice; //수량*금액;
			deliveryPrice += io.product?.delivery?.deliveryPrice; //상품당 배송비;
		}
		await setTotalPrice({
			productPrice,
			deliveryPrice,
		});
	};

	/**
	 * 취소 예정 금액 조회
	 * @param {Number} orderNo
	 * @param {Array} itemNoList
	 */
	const requestExpectedRefundAmount = async (orderNo, itemNoList) => {
		try {
			const d = {
				orderNo,
				itemNoList,
			};
			const result = await expectedRefundAmount(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				await setExpectedRefundInfo(result.data);
				return result.data;
			} else {
				window.linmeLog.log(result);
				modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					html: result?.message || htmlInfo.error.dataCall,
					button: {
						ok: {
							text: textInfo.button.confirm,
							fn: handlerClickErrorModalConfirm,
						},
					},
				});
				return false;
			}
		} catch (error) {
			window.linmeLog.log(error);
			modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				html: error?.response?.data?.message || htmlInfo.error.dataCall,
				button: {
					ok: {
						text: textInfo.button.confirm,
						fn: handlerClickErrorModalConfirm,
					},
				},
			});
			return false;
		}
	};

	/**
	 * 클레임 사유 조회
	 * @returns
	 */
	const requestGetClaimReason = async () => {
		try {
			const d = {
				type: CLAIM_TYPE.CANCEL,
			};
			const result = await getClaimReason(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				const list = makeClaimReasonSelectOptions(CLAIM_TYPE.CANCEL, result.data);
				await setCancelReasonList(list);
				return result.data;
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (error) {
			window.linmeLog.log(error);
			return false;
		}
	};

	/**
	 * 주문 취소 요청
	 * @returns
	 */
	const requestOrderCancel = async () => {
		setSpinner();
		try {
			const d = {
				content: ref.reasonDetail.current.value || "",
				itemNoList: itemNoList,
				orderNo: order.orderNo,
				reasonNo: cancelReasonNo,
			};
			const result = await orderCancel(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.completeOrderCancelRequest,
				});
				await navigate(pathList.myPage.shoppingClaim);
				return result.data;
			} else {
				window.linmeLog.log(result);
				modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					html: htmlInfo.error.dataCall,
				});
				return false;
			}
		} catch (error) {
			window.linmeLog.log(error);
			modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				html: error?.response?.data?.message || htmlInfo.error.dataCall,
			});
			return false;
		} finally {
			removeSpinner();
		}
	};

	/**
	 * 주문번호로 결제 결과 정보 호출
	 * @param {Number} orderNo
	 * @returns
	 */
	// const requestOrderPaymentResult = async ( orderNo ) => {
	// 	const result = await orderPaymentResult( orderNo );
	// 	if( result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS ) {
	// 		await setPaymentResult( result.data );
	// 		return result.data;
	// 	} else {
	// 		window.linmeLog.log( result );
	// 		return false;
	// 	}
	// }

	/**
	 * 주문 취소 사유 선택 후 적용
	 * @param {Object} o
	 */
	const setSelectedReason = async (o) => {
		const ct = ref.reason.current;
		ct.value = "";

		if (o.value) await setCancelReasonNo(parseInt(o.value));

		if (o.label.indexOf(textInfo.orderCancel.orderCancelReasonList.directInput) > -1) {
			ct.type = "text";
		} else {
			ct.type = "hidden";
		}
	};

	/**
	 * validation
	 * @returns
	 */
	const validation = async () => {
		if (cancelReasonNo === null) {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.pleaseSelectReasonOrderCancel,
			});
			return false;
		}
		if (ref.reason.current.type === "text" && !ref.reason.current.type.value) {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.pleaseInputReasonOrderCancel,
			});
			return false;
		}
		return true;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		const setData = async () => {
			const items = location.state?.items;
			const order = location.state?.order;
			const itemNoList = location.state?.itemNoList;
			if (!items && !order && !itemNoList) {
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.errorNoExistsData,
				});
				await navigate(pathList.main);
				return false;
			}
			await setDataList(items); //취소상품정보;
			await setOrder(order); //주문정보;
			await setItemNoList(itemNoList);
			await requestExpectedRefundAmount(order.orderNo, itemNoList); //주문 취소 예정 금액;
			// await requestOrderPaymentResult( order.orderNo );//주문 결제 결과;
			await requestGetClaimReason(); //주문취소사유;
			await calcData(items);
		};
		setData();
	}, [location.state]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="container underTopMenu claimContainer">
			<div className="contentsBackground">
				{/* <div className="contents">
					<ClaimProgressStage {...{
						on: "step1"
						, text: {
							step1: textInfo.orderCancel.stage.step1
							, step2: textInfo.orderCancel.stage.step2
						}
					}}/>
				</div> */}
				<div className="contents">
					<div className="cancelData">
						<div className="title">
							{textInfo.common.order}&nbsp;{textInfo.claim.title.cancel}
						</div>
						<CheckOrderProduct claimType={CLAIM_TYPE.CANCEL} dataList={dataList} />
						<Reason
							refObject={ref}
							type={CLAIM_TYPE.CANCEL}
							reasonList={cancelReasonList}
							reasonNo={cancelReasonNo}
							setSelectedReason={setSelectedReason}
						/>
					</div>
					{/* 주문취소 정보 */}
					<div className="cancelInfoContainer">
						<div className="cancelInfo">
							<div className="title">{textInfo.orderCancel.orderCancelInfo}</div>
							<ul className="cancelInfoList">
								{/* 상품 금액 */}
								<li className="cancelInfoItem">
									<div className="label">{textInfo.order.productPrice}</div>
									<div className="price productPrice">
										{totalPrice ? totalPrice.productPrice.toLocaleString() : 0}
										<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
									</div>
								</li>
								{/* 배송비 */}
								<li className="cancelInfoItem">
									<div className="label">{textInfo.order.deliveryPrice}</div>
									<div className="price deliveryPrice">
										{expectedRefundInfo ? expectedRefundInfo?.deliveryFee?.toLocaleString() : 0}
										<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
									</div>
								</li>
								{/* 할인금액 */}
								{expectedRefundInfo && expectedRefundInfo.returnDiscount ? (
									<li className="cancelInfoItem coupon">
										<div className="label">{textInfo.order.discountPrice}</div>
										<div className="price discountPrice">
											{expectedRefundInfo ? expectedRefundInfo?.returnDiscount.toLocaleString() : 0}
											<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
										</div>
									</li>
								) : (
									<></>
								)}
								{/* 리워드사용액 */}
								{expectedRefundInfo &&
								(expectedRefundInfo.directReward || expectedRefundInfo.tobeReward) ? (
									<>
										<li className="cancelInfoItem reward">
											<div className="label">{textInfo.order.discountReward}</div>
											<div className="price discountReward">
												{(
													expectedRefundInfo.directReward + expectedRefundInfo.tobeReward
												).toLocaleString()}
												<span className="rewardUnit">{textInfo.common.rewardUnit}</span>
											</div>
										</li>
									</>
								) : (
									<></>
								)}
								{expectedRefundInfo &&
								(expectedRefundInfo.directPrice || expectedRefundInfo.tobePrice) ? (
									<>
										{/* 취소 예정 금액 */}
										<li className="cancelInfoItem cancelUsedItem">
											<div className="label">{textInfo.orderCancel.cancelUsedPrice}</div>
											<div className="price cancelUsedReward">
												{(
													expectedRefundInfo.directPrice + expectedRefundInfo.tobePrice
												).toLocaleString()}
												<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
											</div>
										</li>
										{/* 즉시 반환 금액 */}
										<li className="cancelInfoItem discountDetail">
											<div className="label">{textInfo.orderCancel.cancelUsedPriceDirect}</div>
											<div className="price">
												{expectedRefundInfo.directPrice.toLocaleString()}
												<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
											</div>
										</li>
										{/* 반환 예정 금액 */}
										<li className="cancelInfoItem discountDetail">
											<div className="label">{textInfo.orderCancel.cancelUsedPriceTobe}</div>
											<div className="price">
												{expectedRefundInfo.tobePrice.toLocaleString()}
												<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
											</div>
										</li>
									</>
								) : (
									<></>
								)}
								{expectedRefundInfo &&
								(expectedRefundInfo.directReward || expectedRefundInfo.tobeReward) ? (
									<>
										{/* 취소 예정 리워드 */}
										<li className="cancelInfoItem cancelUsedItem">
											<div className="label">{textInfo.orderCancel.cancelUsedReward}</div>
											<div className="price cancelUsedReward">
												{(
													expectedRefundInfo.directReward + expectedRefundInfo.tobeReward
												).toLocaleString()}
												<span className="rewardUnit">{textInfo.common.rewardUnit}</span>
											</div>
										</li>
										{/* 즉시 반환 리워드 */}
										<li className="cancelInfoItem discountDetail">
											<div className="label">{textInfo.orderCancel.cancelUsedRewardDirect}</div>
											<div className="price">
												{expectedRefundInfo.directReward.toLocaleString()}
												<span className="rewardUnit">{textInfo.common.rewardUnit}</span>
											</div>
										</li>
										{/* 반환 예정 리워드 */}
										<li className="cancelInfoItem discountDetail">
											<div className="label">{textInfo.orderCancel.cancelUsedRewardTobe}</div>
											<div className="price">
												{expectedRefundInfo.tobeReward.toLocaleString()}
												<span className="rewardUnit">{textInfo.common.rewardUnit}</span>
											</div>
										</li>
									</>
								) : (
									<></>
								)}
								{/* 복구 예정 쿠폰 */}
								{expectedRefundInfo && expectedRefundInfo.isCheckTobeCoupon ? (
									<li className="cancelInfoItem cancelUsedItem">
										<div className="label">{textInfo.orderCancel.cancelUsedCoupon}</div>
										<div className="price cancelUsedCoupon">
											{expectedRefundInfo &&
											expectedRefundInfo?.coupon &&
											expectedRefundInfo?.coupon?.name &&
											expectedRefundInfo?.isCheckTobeCoupon &&
											!expectedRefundInfo?.isCheckTobeCouponAvailable
												? expectedRefundInfo.coupon.name
												: textInfo.common.noExists}
										</div>
									</li>
								) : (
									<></>
								)}
								{/* 미복구 쿠폰에 대한 상세 */}
								{expectedRefundInfo?.isCheckTobeCouponAvailable &&
									expectedRefundInfo?.isCheckTobeCoupon && (
										<li className="cancelInfoItem couponDetail">
											<span className="expires">기간만료</span>
											<span className="couponName">{expectedRefundInfo?.coupon?.name}</span>
										</li>
									)}
							</ul>
							{/* 취소 예정 금액 */}
							<div className="cancelTotal">
								<div className="label">{textInfo.orderCancel.totalTobeCancelPrice}</div>
								<div className="price orderPriceTotal">
									{expectedRefundInfo && expectedRefundInfo.hasOwnProperty("tobePrice")
										? (
												expectedRefundInfo.directPrice +
												expectedRefundInfo.tobePrice +
												expectedRefundInfo.directReward +
												expectedRefundInfo.tobeReward
										  ).toLocaleString()
										: 0}
									<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
								</div>
							</div>
							<div className="button clickable cancel cover" onClick={(e) => handlerClickCancel(e)}>
								<div className="buttonInner">
									<div className="label">{textInfo.button.claimRequestCancel}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ClaimOrderCancel;
