/**
 * 마지막 페이지 번호 구하여 반환
 * @param {Number} total//총 데이터 개수;
 * @param {Number} offset//1페이지당 표출 개수;
 * @returns
 */
export const getLastPageNumber = (total, offset) => {
	let calPage = 0;
	if (typeof total != "number" || typeof offset != "number") {
		return calPage;
	} else {
		calPage = parseInt(total / offset);
		if (total % offset > 0) calPage = calPage + 1;
		return calPage;
	}
};
