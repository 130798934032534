// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infiniteTrigger_trigger__ZtPN0 {
  box-sizing: border-box;
  width: 100%;
  height: 10px;
}`, "",{"version":3,"sources":["webpack://./src/styles/infiniteTrigger.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EACA,WAAA;EACA,YAAA;AACD","sourcesContent":[".trigger {\n\tbox-sizing: border-box;\n\twidth: 100%;\n\theight: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"trigger": `infiniteTrigger_trigger__ZtPN0`
};
export default ___CSS_LOADER_EXPORT___;
