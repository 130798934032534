/**
 * 쇼핑내역 - 주문/배송 - 취소하기 컨펌 모달
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import useModalStack from "src/hooks/useModalStack";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myPage/order/confirmOrderCancel.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

const htmlInfo = require("src/data/htmlInfo.json");
const textInfo = require("src/data/textInfo.json");

const ConfirmOrderCancel = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 취소 클릭 시
	 * @param {Event} e
	 */
	const handlerClickClose = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.removeModal(props.id);
	};

	/**
	 * 완료 클릭 시
	 * @param {Event} e
	 */
	const handlerClickComplete = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (props.handlerClickConfirm) await props.handlerClickConfirm(props.item, props.order);

		modalStack.removeModal(props.id);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="confirmOrderCancelContainer">
			<div className="title">{textInfo.orderCancel.title}</div>
			<div
				className="data"
				dangerouslySetInnerHTML={{ __html: htmlInfo.claim.cancelConfirm }}
			></div>
			<div className="buttonCover">
				<div className="button clickable close" onClick={(e) => handlerClickClose(e)}>
					{textInfo.button.cancel}
				</div>
				<div className="button clickable confirm" onClick={(e) => handlerClickComplete(e)}>
					{textInfo.button.complete}
				</div>
			</div>
		</div>
	);
};

export default ConfirmOrderCancel;
