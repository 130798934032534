/**
 * 주문/결제 - 쿠폰 적용
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";

import useModalStack from "src/hooks/useModalStack";
import { API_RESULT_STATUS, PAGINATION_DEFAULT_OFFSET } from "src/data/constEnum";
import { getCouponListForOrder } from "src/api/order/order";
import { getLastPageNumber } from "src/utils/number";
import { setScrollTop } from "src/utils/linmeDisplay";

import CouponList from "src/pages/component/payment/couponList";
import ModalCommonAlert from "src/pages/common/modalAlert";
import Pagination from "src/pages/component/pagination";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/payment/modal/selectCoupon.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const SelectCoupon = (props, o) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();

	const [couponList, setCouponList] = useState(null);
	const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);
	const [paginationOffset, setPaginationOffset] = useState(PAGINATION_DEFAULT_OFFSET.DEFAULT);
	const [paginationTotalCount, setPaginationTotalCount] = useState(0);
	const [paginationLastPage, setPaginationLastPage] = useState(0);
	const [paginationIsLastPage, setPaginationIsLastPage] = useState(false);

	const inputRadioElementsName = "couponSelect";

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 닫기 클릭 시
	 * @param {Event} e
	 */
	const handlerClickClose = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.removeModal(props.id);
	};

	/**
	 * 선택한 쿠폰 정보 설정
	 * @param {Number} couponNo
	 * @param {String} couponName
	 */
	const handlerClickSelectedApply = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const el = await window.document.querySelector(
			`input[type=radio][name=${inputRadioElementsName}]:checked`
		);

		if (!el) {
			//쿠폰 목록이 존재하는데 선택하지 않은 경우;
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.selectCoupon,
			});
		} else {
			const d = couponList[parseInt(el.value)];
			await props.button.ok.fn(d);
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 다시 그리기
	 * @param {Number} pageNo
	 */
	const redraw = async (pageNo) => {
		await requestGetAvailableCouponList(pageNo);
		await setScrollTop();
	};

	/**
	 * 해당 주문에 적용 가능한 쿠폰 목록 요청
	 * @returns
	 */
	const requestGetAvailableCouponList = async (pageNo) => {
		try {
			const d = {
				offset: paginationOffset,
				page: pageNo,
			};
			const result = await getCouponListForOrder(props.orderNo, d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (result.data.content && result.data.content.length > 0) {
					await setCouponList(result.data.content);

					await setPaginationCurrentPage(result.data.page);
					await setPaginationTotalCount(result.data.totalCount);
					await setPaginationIsLastPage(result.data.isLastPage);
					await setPaginationLastPage(
						await getLastPageNumber(result.data.totalCount, paginationOffset)
					);
				}
				return result.data;
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (error) {
			window.linmeLog.log(error);
			return false;
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		const getData = async () => {
			if (props.orderNo) {
				await requestGetAvailableCouponList(1);
			}
		};
		getData();
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="modalContentsContainer selectCouponContainer">
			<div className="title">{textInfo.payment.coupon.selectCoupon}</div>
			{/* <div className="iconClose" onClick={(e)=>handlerClickClose(e)}></div> */}
			<CouponList
				{...{ list: couponList }}
				inputRadioElementsName={inputRadioElementsName}
				useSelect={true}
			/>
			{couponList && couponList.length > 0 && (
				<Pagination
					{...{
						currentPage: paginationCurrentPage,
						offset: paginationOffset,
						totalCount: paginationTotalCount,
						lastPage: paginationLastPage,
						isLastPage: paginationIsLastPage,
						redraw: redraw,
					}}
				/>
			)}
			{couponList && couponList.length > 0 ? (
				<div className="button selectedApply" onClick={(e) => handlerClickSelectedApply(e)}>
					{textInfo.button.select2}
				</div>
			) : (
				<div className="button layerClose" onClick={(e) => handlerClickClose(e)}>
					{textInfo.button.confirm}
				</div>
			)}
		</div>
	);
};

export default SelectCoupon;
