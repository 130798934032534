/**
 * 기획전 ( 타임특가 ) 상품 목록
 */

import { useContext } from "react";
import { AppContext } from "src/App";
import { useQuery } from "@tanstack/react-query";
import useQueryParams from "src/hooks/useQueryParams";
import useTopicTimeSaleList from "src/hooks/main/useTopicTimeSaleList";
import { productListTopic } from "src/api/product/topic";
import {
	API_RESULT_STATUS,
	PAGINATION_DEFAULT_OFFSET,
	PRODUCT_LIST_ORDER,
} from "src/data/constEnum";
import PaginationWithQuery from "src/pages/component/paginationWithQuery";
import Image from "src/pages/component/common/image";
import ProductList from "src/pages/component/product/productList";
import ProductSortList from "src/pages/component/product/productSortList";
import { setSpinner, removeSpinner } from "src/utils/linmeDisplay";
import { queryKeys } from "src/data";
import "src/styles/product/topicTimerProductDisplay.scss";

/**
 * 상품 목록 가져오기
 * @param {Number} pageNo
 */
const getProductList = async (listNo, pageNo, sort) => {
	await setSpinner();
	try {
		const request = {
			listNo: listNo, //기획전 번호;
			page: pageNo,
			offset: PAGINATION_DEFAULT_OFFSET.PRODUCT_LIST,
			sort: sort,
		};

		const result = await productListTopic(request);
		if (result.status?.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
			return result?.data;
		} else {
			window.linmeLog.log(result);
		}
	} catch (error) {
		window.linmeLog.log(error);
	} finally {
		await removeSpinner();
	}
};

const TopicTimerProductDisplay = () => {
	const { mainSelectedMenuType } = useContext(AppContext);
	const query = useQueryParams();
	const { listNo, page = 1, sort = PRODUCT_LIST_ORDER[0].value } = query.queryStringToObject();
	const { data: timeSaleInfo } = useTopicTimeSaleList(mainSelectedMenuType);
	const { data: productList } = useQuery({
		queryKey: [queryKeys.PRODUCT_LIST_TOPIC_TIME_SALE, listNo, page, sort],
		queryFn: () => getProductList(listNo, page, sort),
		enabled: !!listNo && !!page && !!sort,
	});

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="container underTopMenu productDisplay topicTimerProductDisplay">
			<div className="contents">
				{timeSaleInfo?.image && (
					<div className="bannerCover">
						<Image className="banner" src={timeSaleInfo.image} alt={timeSaleInfo.title} />
					</div>
				)}
				<div className="productDisplayContainer">
					{timeSaleInfo?.title && <div className="title">{timeSaleInfo.title}</div>}
					{timeSaleInfo?.subTitle && <div className="subtitle">{timeSaleInfo.subTitle}</div>}
					{/* <TimerProductCard
						product={topicListData?.[0]}
						isShowTimer={true}
						startAt={startAt}
						endAt={endAt}
					/> */}
					<ProductSortList />
					<div className="productListContainer">
						{productList && (
							<ProductList
								items={productList?.content}
								limit={productList?.offset || PAGINATION_DEFAULT_OFFSET.PRODUCT_LIST}
							/>
						)}
					</div>
					<PaginationWithQuery
						totalPage={Math.ceil(productList?.totalCount / productList?.offset) || 0}
					/>
				</div>
			</div>
			<div className="divisionTransparent70" />
		</div>
	);
};

export default TopicTimerProductDisplay;
