/**
 * 기획전 ( 타임특가 ) 상품 목록
 */

import { useQuery } from "@tanstack/react-query";

import { productListTopic, productTopicInfo } from "src/api/product/topic";
import useQueryParams from "src/hooks/useQueryParams";

import ProductList from "src/pages/component/product/productList";
import Image from "src/pages/component/common/image";
import PaginationWithQuery from "src/pages/component/paginationWithQuery";
import ProductSortList from "src/pages/component/product/productSortList";

import {
	PAGINATION_DEFAULT_OFFSET,
	queryKeys,
	API_RESULT_STATUS,
	PRODUCT_LIST_ORDER,
} from "src/data";
import "src/styles/productList.scss";
import "src/styles/product/topicPeriodProductDisplay.scss";

/**
 * 상품 목록 가져오기
 * @param {Number} listNo
 * @param {Number} pageNo
 * @param {String} sort
 */
const getProductList = async (listNo, pageNo, sort) => {
	try {
		let d = {
			listNo: listNo, //기획전 번호;
			page: pageNo,
			offset: PAGINATION_DEFAULT_OFFSET.PRODUCT_LIST,
			sort: sort,
		};

		const result = await productListTopic(d);
		if (result.status?.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
			return result?.data;
		}
	} catch (error) {
		window.linmeLog.log(error);
	}
};

const TopicPeriodProductDisplay = () => {
	const query = useQueryParams();
	const { listNo, page = 1, sort = PRODUCT_LIST_ORDER[0].value } = query.queryStringToObject();

	const { data: topicInfo } = useQuery({
		queryKey: [queryKeys.TOPIC_INFO, listNo],
		queryFn: () => productTopicInfo(listNo),
		select: (data) => data?.data,
		enabled: !!listNo,
	});
	const { data: topicList } = useQuery({
		queryKey: [queryKeys.PRODUCT_LIST_TOPIC, listNo, sort, page],
		queryFn: () => getProductList(listNo, page, sort),
		enabled: !!listNo && !!page && !!sort,
	});

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="container underTopMenu productDisplay periodProductDisplay">
			<div className="contents">
				{topicInfo?.image && (
					<div className="bannerCover">
						<Image className="banner" src={topicInfo?.image.webPath} alt={topicInfo?.title} />
					</div>
				)}
				<div className="productDisplayContainer">
					{topicInfo?.title && <div className="title">{topicInfo?.title}</div>}
					{topicInfo?.subTitle && <div className="subtitle">{topicInfo?.subTitle}</div>}
					<ProductSortList />
					{topicList?.content && (
						<div className="productListContainer">
							<ProductList
								items={topicList.content}
								limit={topicList?.offset || PAGINATION_DEFAULT_OFFSET.PRODUCT_LIST}
							/>
						</div>
					)}
					<PaginationWithQuery
						totalPage={Math.ceil(topicList?.totalCount / topicList?.offset) || 0}
					/>
				</div>
			</div>
			<div className="divisionTransparent70" />
		</div>
	);
};

export default TopicPeriodProductDisplay;
