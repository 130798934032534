/**
 * 리뷰 별점
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

const StarScore = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	/*/
	const score = Math.ceil( props.score / 0.5 ) * 0.5;
	/*/
	//2024.03.22 16:52 - 소수점이 존재하는 경우 0.5 적용;
	const score = Math.floor(props.score);
	//*/

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 점수대로 별 그려서 반환
	 * @returns {*}
	 */
	const makeStarList = () => {
		let result = [];
		/*/
		const iLen = 5;
		let className = "";
		for( let i = 0; i<iLen; i++ ) {
			className = "iconStar ";
			if( score - i > 0.5) {
				className += "score1";
			} else if( score - i === 0.5 ) {
				className += "score0_5";
			} else {
				className += "score0";
			}
			result.push(<li key={i} className={className}></li>);
		}
		/*/
		//2024.03.22 16:52 - 소수점이 존재하는 경우 0.5 적용;
		for (let i = 0; i < score; i++) {
			result.push(<li key={i} className="iconStar score1"></li>);
		}
		if (props.score - score > 0) {
			//소수점이 존재하는 경우 0.5로 설정;
			result.push(<li key={result.length} className="iconStar score0_5"></li>);
		}
		const zeroCount = Math.floor(5 - props.score);
		if (zeroCount > 0) {
			for (let i = 0; i < zeroCount; i++) {
				result.push(<li key={result.length} className="iconStar score0"></li>);
			}
		}
		//*/
		return result;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="starScoreCover">
			<ul className="starScore">{makeStarList()}</ul>
		</div>
	);
};

export default StarScore;
