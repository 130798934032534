/**
 * 환불/교환/취소에 대한 이유 입력 컴포넌트
 */

import React from "react";

import CustomSelectBox from "src/pages/component/common/customSelectBox";
import { CLAIM_TYPE } from "src/data/constEnum";

import "src/styles/claim/reason.scss";

const textInfo = require("src/data/textInfo.json");

const Reason = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const { refObject, type, reasonList, reasonNo, setSelectedReason } = props;
	const label =
		type === CLAIM_TYPE.CHANGE
			? textInfo.orderChange.selectReasonOrderChange
			: type === CLAIM_TYPE.REFUND
			? textInfo.orderRefund.selectReasonOrderRefund
			: type === CLAIM_TYPE.CANCEL
			? textInfo.orderCancel.selectReasonOrderCancel
			: textInfo.myPage.myShopping.selectReason;
	const selectBoxPlaceholder =
		type === CLAIM_TYPE.CHANGE
			? textInfo.orderChange.orderChangeReasonList.empty
			: type === CLAIM_TYPE.REFUND
			? textInfo.orderRefund.orderRefundReasonList.empty
			: type === CLAIM_TYPE.CANCEL
			? textInfo.orderCancel.orderCancelReasonList.empty
			: textInfo.myPage.myShopping.selectReason;
	const textareaPlaceholder =
		type === CLAIM_TYPE.CHANGE
			? textInfo.claim.placeholder.changeReasonDetail
			: type === CLAIM_TYPE.REFUND
			? textInfo.claim.placeholder.refundReasonDetail
			: type === CLAIM_TYPE.CANCEL
			? textInfo.claim.placeholder.cancelReasonDetail
			: textInfo.myPage.myShopping.inputReason;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="reasonContainer">
			{/* 라벨 */}
			<div className="label">{label}</div>
			{/* 데이터 */}
			<div className="data">
				{/* selectBox */}
				{reasonList !== null && (
					<CustomSelectBox
						options={reasonList}
						value={reasonNo}
						placeholder={selectBoxPlaceholder}
						onChange={(item) => setSelectedReason(item)}
					/>
				)}
				<input
					type="hidden"
					className="ReasonText"
					name="directInput"
					ref={refObject.reason}
					placeholder={
						textInfo.myPage.myShopping.claim.type.cancel +
						textInfo.myPage.myShopping.claim.inputReason
					}
				/>
				{/* 사유 상세 입력란 */}
				<textarea
					className="reasonDetail"
					ref={refObject.reasonDetail}
					placeholder={textareaPlaceholder}
				/>
			</div>
		</div>
	);
};

export default Reason;
