/**
 * 문의하기
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useRef, useState } from "react";

import useModalStack from "src/hooks/useModalStack";
import {
	API_RESULT_STATUS,
	CHARACTER_LIMIT,
	COLOR,
	FILE_TYPE,
	INQUIRY_PRODUCT_TYPE,
	INQUIRY_TYPE_LIST__PRODUCT,
} from "src/data/constEnum";
import {
	deleteInquiry,
	modifyMyProductInquiry,
	writeMyProductInquiry,
} from "src/api/product/inquiry";
import { requestDeleteFileList, requestUploadFile } from "src/utils/linmeFile";
import { removeInnerSpinner, setInnerSpinner } from "src/utils/linmeDisplay";

import UploadPhotoBox from "src/pages/component/common/uploadPhotoBox";
import CustomSelectBox from "src/pages/component/common/customSelectBox";
import Image from "src/pages/component/common/image";
import ModalCommonAlert from "src/pages/common/modalAlert";
import WhenLeaveConfirmWrite from "src/pages/product/modal/whenLeaveConfirmWrite";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myPage/modal/myPageModalInquiryWrite.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

const htmlInfo = require("src/data/htmlInfo.json");
const textInfo = require("src/data/textInfo.json");

const ProductModalInquiryWrite = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();

	const [productInfo, setProductInfo] = useState(null);
	const [fileList, setFileList] = useState(null);
	const [delFileNoList, setDelFileNoList] = useState(null);
	const [fileNoList, setFileNoList] = useState(null);
	const [inquiryType, setInquiryType] = useState(null);

	const ref = {
		content: useRef(null),
		contentNow: useRef(null),
		isPrivate: useRef(null),
		buttonComplete: useRef(null),
	};

	const modalId = {
		cancelConfirm: "modalWhenLeaveConfirmWrite",
		errorFileUpload: "modalCommonAlert",
		errorFileDelete: "modalCommonAlert",
		modified: "modalCommonAlert",
		added: "modalCommonAlert",
		inputLimitExceeded: "modalCommonAlert",
		inputContent: "modalCommonAlert",
		inputInquiryType: "modalCommonAlert",
	};

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 사용자 입력 시
	 * @param {Event} e
	 */
	const handlerInputChange = async (e) => {
		if (e) {
			//버블링 막기;
			e.preventDefault();
			e.stopPropagation();
		}

		ref.contentNow.current.innerText = ref.content.current.value.length;

		if (ref.content.current.value.length > CHARACTER_LIMIT.INQUIRY) {
			await modalStack.addModal({
				id: modalId.inputLimitExceeded,
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.alert.inputLimitExceeded.replace(
					"___characterLimit___",
					CHARACTER_LIMIT.INQUIRY + textInfo.common.characterUnit
				),
			});
			ref.content.current.value = ref.content.current.value.substr(0, CHARACTER_LIMIT.INQUIRY);
			ref.contentNow.current.innerText = ref.content.current.value.length;
		}

		if (validation()) {
			ref.buttonComplete.current.classList.add("on");
		} else {
			ref.buttonComplete.current.classList.remove("on");
		}
	};

	/**
	 * 취소 클릭 시
	 * @param {Event} e
	 */
	const handlerClickCancel = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		//modalStack.removeModal( props.id );
		modalStack.addModal({
			id: modalId.cancelConfirm,
			type: "confirm",
			component: <WhenLeaveConfirmWrite />,
			html: htmlInfo.inquiry.cancelWriteConfirm,
			handlerCancel: handlerClickModalCancel,
			handlerExit: handlerClickModalConfirm,
		});
		return false;
	};

	/**
	 * 완료 클릭 시
	 * @param {Event} e
	 */
	const handlerClickComplete = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (ref.buttonComplete.current.classList.contains("on")) {
			const resultValidationContent = await validationContent();
			const resultValidationInquiryType = await validationInquiryType();
			if (resultValidationContent && resultValidationInquiryType) {
				if (props.id.indexOf("Modify") > -1) {
					//문의 수정;
					await inquiryModify();
				} else {
					//문의 등록;
					await inquiryWrite();
				}
			}
		}
		return false;
	};

	/**
	 * 취소 클릭 시 뜨는 모달에서 "취소" 클릭
	 * @param {Event} e
	 */
	const handlerClickModalCancel = (e) => {
		/*/
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();
		//*/

		modalStack.removeModal(modalId.cancelConfirm);
	};

	/**
	 * 등록/수정 완료 시
	 * @param {Event} e
	 */
	const handlerClickModalComplete = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (props.redraw) await props.redraw(1);

		await modalStack.removeModal(modalId.added);
		await modalStack.removeModal(modalId.modified);
		await modalStack.removeModal(props.id);
	};

	/**
	 * 취소 클릭 시 뜨는 모달에서 "나가기" 클릭
	 * @param {Event} e
	 */
	const handlerClickModalConfirm = (e) => {
		modalStack.removeModal(modalId.cancelConfirm);
		modalStack.removeModal(props.id);
	};

	/**
	 * 등록/수정 실패 시
	 * @param {Event} e
	 */
	const handlerClickModalFail = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await modalStack.removeModal(modalId.added);
		await modalStack.removeModal(modalId.modified);
		await modalStack.removeModal(props.id);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 파일 적용 후
	 * @param {Array} aUploadTarget
	 * @param {Array} aRemoveTarget
	 */
	const afterFileUpload = async (aUploadTarget, aRemoveTarget) => {
		await setFileList(aUploadTarget);
		if (aRemoveTarget && aRemoveTarget.length) {
			await setDelFileNoList(aRemoveTarget);
		}
	};

	/**
	 * 문의 수정
	 * @returns
	 */
	const inquiryModify = async () => {
		await setInnerSpinner();
		const inquiryFiles = await setInquiryFile();

		if (inquiryFiles) {
			//파일 정보 설정;
			await setFileNoList(inquiryFiles);

			//문의 수정;
			await requestModifyInquiry(inquiryFiles);
		} else {
			//문의 수정;
			await requestModifyInquiry();
		}
	};

	/**
	 * 문의 작성
	 * @returns
	 */
	const inquiryWrite = async () => {
		await setInnerSpinner();
		if (fileList && fileList.length) {
			//파일 업로드 및 파일번호 설정;
			const result_fileUpload = await requestUploadFile(FILE_TYPE.INQUIRY, fileList).catch(
				async (error) => {
					await modalStack.addModal({
						id: modalId.errorFileUpload,
						type: "alert",
						component: <ModalCommonAlert />,
						html: htmlInfo.error.fileUpload,
					});
					return false;
				}
			);

			if (result_fileUpload && result_fileUpload.length) {
				//파일 정보 설정;
				await setFileNoList(result_fileUpload);

				//문의 등록;
				await requestWriteInquiry(result_fileUpload);
			} else {
				await modalStack.addModal({
					id: modalId.errorFileUpload,
					type: "alert",
					component: <ModalCommonAlert />,
					html: htmlInfo.error.fileUpload,
				});
				return false;
			}
		} else {
			//문의 등록;
			await requestWriteInquiry();
		}
	};

	/**
	 * 문의 수정 요청
	 * @param {Array} aFileNo
	 */
	const requestModifyInquiry = async (aFileNo) => {
		try {
			const inquiryProductNo = props.modifyData.inquiryProductNo;

			const d = {
				content: ref.content.current.value,
				isCheckSecret: props.modifyData.isCheckSecret,
				type: inquiryType,
			};
			if (aFileNo) d.fileNoList = aFileNo;
			const result = await modifyMyProductInquiry(inquiryProductNo, d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				await removeInnerSpinner();
				if (result.data && result.data.hasOwnProperty("inquiryProductNo")) {
					// await modalStack.removeModal( props.id );
					await modalStack.addModal({
						id: modalId.modified,
						type: "alert",
						component: <ModalCommonAlert />,
						text: textInfo.alert.modifiedInquiry,
						button: {
							ok: {
								text: textInfo.button.confirm,
								fn: handlerClickModalComplete,
							},
						},
					});
				} else {
					//실패;
					await removeInnerSpinner();
					await modalStack.addModal({
						id: modalId.modified,
						type: "alert",
						component: <ModalCommonAlert />,
						html: htmlInfo.error.inquiryModify,
						button: {
							ok: {
								text: textInfo.button.confirm,
								fn: handlerClickModalFail,
							},
						},
					});
				}
			} else {
				await removeInnerSpinner();
				window.linmeLog.log(result);
				await modalStack.addModal({
					id: modalId.modified,
					type: "alert",
					component: <ModalCommonAlert />,
					html: htmlInfo.error.inquiryModify,
					button: {
						ok: {
							text: textInfo.button.confirm,
							fn: handlerClickModalFail,
						},
					},
				});
			}
		} catch (err) {
			await removeInnerSpinner();
			window.linmeLog.error(err);
			return false;
		}
	};

	/**
	 * 문의 작성 요청
	 * @param {Array} aFileNo
	 */
	const requestWriteInquiry = async (aFileNo) => {
		try {
			let request = {
				content: ref.content.current.value,
				type: inquiryType,
				isCheckSecret: ref.isPrivate.current.checked,
				productNo: productInfo?.productNo,
			};
			//파일;
			if (aFileNo) request.fileNoList = aFileNo;

			const result = await writeMyProductInquiry(request);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				await removeInnerSpinner();
				await modalStack.addModal({
					id: modalId.added,
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.addedInquiry,
					button: {
						ok: {
							text: textInfo.button.complete,
							fn: handlerClickModalComplete,
						},
					},
				});
			} else {
				await removeInnerSpinner();
				window.linmeLog.log(result);
				const o = {
					id: modalId.modified,
					type: "alert",
					component: <ModalCommonAlert />,
					button: {
						ok: {
							text: textInfo.button.confirm,
							fn: handlerClickModalFail,
						},
					},
				};
				if (result.message) o.text = result.message;
				else o.html = htmlInfo.error.inquiryWrite;
				await modalStack.addModal(o);
			}
		} catch (err) {
			await removeInnerSpinner();
			window.linmeLog.error(err);
			await modalStack.addModal({
				id: modalId.modified,
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.error.inquiryWrite,
				button: {
					ok: {
						text: textInfo.button.confirm,
						fn: handlerClickModalFail,
					},
				},
			});
			return false;
		}
	};

	/**
	 * 파일 설정 - 기존에 있던 파일 - 삭제할 파일 + 새로 업로드한 파일
	 * @returns
	 */
	const setInquiryFile = async () => {
		//fileNo만 별도의 배열로 담기;
		let inquiryFiles = null;

		//기존에 파일이 있으면 파일 번호만 담기;
		if (props.modifyData.reqFile && props.modifyData.reqFile.length) {
			inquiryFiles = [...new Set(props.modifyData.reqFile.map((f) => f.fileNo))];
		}

		//삭제할 파일이 있는 경우;
		if (delFileNoList && delFileNoList.length) {
			const result_fileDelete = await requestDeleteFileList(delFileNoList).catch(async (err) => {
				window.linmeLog.error(err);
				await modalStack.addModal({
					id: modalId.errorFileDelete,
					type: "alert",
					component: <ModalCommonAlert />,
					html: htmlInfo.error.fileUpload,
				});
				return false;
			});

			//삭제 대상 파일 제외;
			inquiryFiles = inquiryFiles.filter((f) => !delFileNoList.includes(f));
		}

		//업로드할 파일이 있는 경우;
		if (fileList && fileList.length) {
			//파일 업로드 및 파일번호 설정;
			const result_fileUpload = await requestUploadFile(FILE_TYPE.INQUIRY, fileList).catch(
				async (err) => {
					window.linmeLog.error(err);
					await modalStack.addModal({
						id: modalId.errorFileUpload,
						type: "alert",
						component: <ModalCommonAlert />,
						html: htmlInfo.error.fileUpload,
					});
					return false;
				}
			);

			if (result_fileUpload && result_fileUpload.length) {
				//신규 등록 파일 설정;
				if (!inquiryFiles) inquiryFiles = [];
				inquiryFiles = inquiryFiles.concat(result_fileUpload);
			}
		}

		return inquiryFiles;
	};

	/**
	 * 문의 유형 선택 후 적용
	 * @param {Object} o
	 */
	const setSelectedInquiryType = async (o) => {
		await setInquiryType(o.value);
	};

	/**
	 * validation
	 * @returns
	 */
	const validation = () => {
		const content = ref.content.current.value;
		if (!content || !content.trim()) {
			return false;
		}
		if (!inquiryType) {
			return false;
		}
		return true;
	};

	/**
	 * validation - 문의 내용
	 * @returns {Boolean}
	 */
	const validationContent = () => {
		const content = ref.content.current.value;
		if (!content || !content.trim()) {
			modalStack.addModal({
				id: modalId.inputContent,
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.pleaseInputInquiryContent,
			});
			return false;
		}
		return true;
	};

	/**
	 * validation - 문의 유형
	 */
	const validationInquiryType = () => {
		if (!inquiryType) {
			modalStack.addModal({
				id: modalId.inputInquiryType,
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.pleaseSelectInquiryType,
			});
			return false;
		}
		return true;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		if (props.data) {
			setProductInfo(props.data);
		} else if (props.modifyData && props.modifyData.product) {
			setProductInfo(props.modifyData.product);
		} else if (props.item && props.item.product) {
			setProductInfo(props.item.product);
		}
	}, [props]);

	useEffect(() => {
		if (validation()) {
			ref.buttonComplete.current.classList.add("on");
		} else {
			ref.buttonComplete.current.classList.remove("on");
		}
	}, [inquiryType, fileList]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className={"modalInquiryRegisterContainer inquiryProduct"}>
			<div className="title">{textInfo.inquiry.productInquiryWriteTitle}</div>

			<div className="description">{textInfo.inquiry.inquiryProductDesc}</div>

			<div className="product">
				<Image src={productInfo?.thumbnail?.webPath} alt={productInfo?.name} />
				<div className="productInfo">
					<div className="brand">{productInfo?.brand?.name}</div>
					<div className="productName">{productInfo?.name}</div>
				</div>
			</div>

			{/* <div className="inputData inputTitle">
				<div className="label">제목</div>
				<input type="text" className="inputInquiryTitle" placeholder="제목을 입력해 주세요." />
			</div> */}
			<div className="inputData">
				<div className="label">{"문의 유형"}</div>
				<CustomSelectBox
					options={INQUIRY_TYPE_LIST__PRODUCT}
					value={inquiryType}
					disabled={false}
					placeholder="선택"
					onChange={(item) => setSelectedInquiryType(item)}
				/>
				<div className="label">{textInfo.inquiry.inquiryContent}</div>
				<textarea
					className="inputInquiryContent"
					onChange={(e) => handlerInputChange(e)}
					placeholder={textInfo.inquiry.placeholder.content}
					ref={ref.content}
					defaultValue={
						props.modifyData && props.modifyData.content ? props.modifyData.content : ""
					}
				/>
				<div className="contentLimit">
					<span className="contentNow" ref={ref.contentNow}>
						{props.modifyData && props.modifyData.content ? props.modifyData.content.length : "0"}
					</span>
					&nbsp;/&nbsp;{CHARACTER_LIMIT.INQUIRY}
					<span className="characterUnit">{textInfo.common.characterUnit}</span>
				</div>
			</div>

			<div className="setPrivate">
				<input
					className="checkPrivate"
					name="checkPrivate"
					id="checkPrivate"
					type="checkbox"
					ref={ref.isPrivate}
					defaultChecked={
						props.modifyData && props.modifyData.isCheckSecret
							? props.modifyData.isCheckSecret
							: false
					}
				/>
				<label htmlFor="checkPrivate">{textInfo.inquiry.setPrivate}</label>
			</div>

			<div className="inputData">
				<UploadPhotoBox
					afterFileUpload={afterFileUpload}
					data={props.modifyData && props.modifyData.reqFile ? props.modifyData.reqFile : null}
				/>
			</div>
			{/* <ul className="guideList">
				<li className="guideItem">{textInfo.inquiry.guide.product1}</li>
				<li className="guideItem">{textInfo.inquiry.guide.product2}</li>
				<li className="guideItem">{textInfo.inquiry.guide.product3}</li>
			</ul> */}
			<div className="buttonCover">
				<div className="button clickable cancel" onClick={(e) => handlerClickCancel(e)}>
					{textInfo.button.cancel}
				</div>
				<div
					className="button clickable complete"
					onClick={(e) => handlerClickComplete(e)}
					ref={ref.buttonComplete}
				>
					{textInfo.button.complete}
				</div>
			</div>
		</div>
	);
};

export default ProductModalInquiryWrite;
