import apiMyLinme from "src/api/axiosMyLinme";
import API_PATHS from "src/api/apiPaths";

/**
 * MBTI 테스트 질문 목록
 * @returns
 */
export const getMbtiQuestion = async (d) => {
	const url = `${API_PATHS.MBTI_QUESTION}`;
	try {
		const result = await apiMyLinme.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * MBTI 결과 요약
 * @param {Object} d
 * <code>
 * [{
 * 		healthStyleCd: ""
 * 		, score: 1
 * }]
 * </code>
 * @returns
 */
export const getMbtiSummary = async (d) => {
	const url = `${API_PATHS.MBTI_SUMMARY}`;
	try {
		const result = await apiMyLinme.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * MBTI 결과 상세
 * env = PC | MOBILE | TABLET | MOBILE_MINI
 * @returns
 */
export const getMbtiDetail = async (d) => {
	const url = `${API_PATHS.MBTI_DETAIL}`;
	try {
		const result = await apiMyLinme.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
