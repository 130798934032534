/**
 * 상품 상세 - 상세 정보 탭
 */

import { memo, useEffect, useRef, useState } from "react";

import ProductTabContentProductDescription from "src/pages/component/product/productTabContentProductDescription";
import ProductTabContentProductReview from "src/pages/component/product/productTabContentProductReview";
import ProductTabContentProductInquiry from "src/pages/component/product/productTabContentProductInquiry";
import clsx from "clsx";

const textInfo = require("src/data/textInfo.json");

const ProductDetailTab = (props) => {
	const [activeSection, setActiveSection] = useState("description");
	const ref = {
		description: useRef(null),
		review: useRef(null),
		inquiry: useRef(null),
	};

	const handleMoveToSection = (sectionRef) => {
		if (sectionRef && sectionRef.current) {
			const offset = 220; // 요소를 상단에서 100px 아래에 위치시키기 위해 추가할 오프셋 값
			const elementPosition = sectionRef.current.getBoundingClientRect().top + window.scrollY;
			const offsetPosition = elementPosition - offset;

			setActiveSection(sectionRef.current.id);

			window.scrollTo({
				top: offsetPosition,
				behavior: "smooth",
			});
		}
	};

	// 요소가 화면에 보이는지 감지하는 IntersectionObserver 콜백 함수
	const handleIntersection = (entries) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				setActiveSection(entry.target.id);
			}
		});
	};

	useEffect(() => {
		const descriptionObserver = new IntersectionObserver(handleIntersection, {
			root: null,
			rootMargin: "-300px 0px 0px 0px",
			threshold: [0, 0.5], // Description 요소는 1%만 보이면 감지
		});

		if (ref.description.current) descriptionObserver.observe(ref.description.current);

		// 다른 요소들에 대한 IntersectionObserver 설정 (threshold: 0.5)
		const defaultObserver = new IntersectionObserver(handleIntersection, {
			root: null,
			rootMargin: "-300px 0px 0px 0px",
			threshold: 0.05, // 다른 요소들은 10% 이상 보이면 감지
		});

		if (ref.review.current) defaultObserver.observe(ref.review.current);
		if (ref.inquiry.current) defaultObserver.observe(ref.inquiry.current);

		return () => {
			if (ref.description.current) descriptionObserver.unobserve(ref.description.current);
			if (ref.review.current) defaultObserver.unobserve(ref.review.current);
			if (ref.inquiry.current) defaultObserver.unobserve(ref.inquiry.current);
		};
	}, []);
	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="productDetailTab">
			<ul className="tab" id="productDetailTabList">
				{TABS.map((tab) => (
					<li
						key={tab.key}
						className={clsx({ on: activeSection === tab.key })}
						onClick={() => handleMoveToSection(ref[tab.key])}
					>
						{tab.label}
					</li>
				))}
			</ul>
			<div className="tabContent">
				{/* 상품 설명 */}
				<div id="description" ref={ref.description}>
					<ProductTabContentProductDescription
						isCheckEditor={props.isCheckEditor}
						healthData={props.data}
						desc={props.desc}
					/>
				</div>
				{/* 리뷰 */}
				<div id="review" ref={ref.review}>
					<div className="division"></div>
					<ProductTabContentProductReview {...props} />
					<div className="division"></div>
				</div>
				{/* 상품문의 */}
				<div id="inquiry" ref={ref.inquiry}>
					<ProductTabContentProductInquiry {...props} />
				</div>
			</div>
		</div>
	);
};

export default memo(ProductDetailTab);

const TABS = [
	{ key: "description", label: textInfo.product.detail.productDescription },
	{ key: "review", label: textInfo.product.detail.productReview },
	{ key: "inquiry", label: textInfo.product.detail.productInquiry },
];
