import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/**
 * 기획전 정보 조회 ( title, subTitle, ... )
 * @param {Number} listNo
 * @returns
 */
export const productTopicInfo = async (listNo) => {
	const url = `${API_PATHS.PRODUCT_LIST_TOPIC_INFO}`;
	try {
		const result = await api.get(url.replace(":listNo", listNo));
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 기획전 - 상품 목록 요약본 ( 상품 최대 갯수 5개 ) ( 메인 등에서 호출용 )
 * @param {Object} d
 * <code>
 * {
 * 		page: 1
 * 		, offset: 1
 * 		, typeNo: 1 // 1:기능식품, 2:맞춤식품;
 * 		, isMainEvent: 1
 * }
 * </code>
 */
export const productListTopicSummary = async (d) => {
	const url = `${API_PATHS.PRODUCT_LIST_TOPIC_SUMMARY}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 기획전 - 타임특가 상품 목록 ( 상품 최대 갯수 5개 ) ( 메인 등에서 호출용 )
 * @param {Object} d
 * <code>
 * {
 * 		typeNo: 1 // 1:기능식품, 2:맞춤식품;
 * }
 * </code>
 * @returns
 */
export const productListTopicSummaryTimeSale = async (d) => {
	const url = `${API_PATHS.PRODUCT_LIST_TOPIC_SUMMARY_TIME_SALE}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 기획전 내 상품목록
 * @param {Object} d
 * <code>
 * {
 * 		listNo: 63 //기획전 번호;
 * 		, page: 1
 * 		, offset: 1
 * 		, sort: "" //정렬 ( PRODUCT_LIST_ORDER );
 * }
 * </code>
 * @returns
 */
export const productListTopic = async (d) => {
	const url = `${API_PATHS.PRODUCT_LIST_TOPIC}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
