import { useLocation, useNavigate } from "react-router-dom";
import { useMemo } from "react";

/**
 * query string을 다루는 커스텀 훅
 * @param {Object} options - 옵션.
 * @param {string} [options.path=""] - 업데이트 후 이동할 경로를 지정. 기본값은 빈 문자열로, 현재 경로를 유지.
 * @param {boolean} [options.isDirectPush=true] - 쿼리 파라미터 업데이트 후 페이지 이동을 할지 여부를 결정. `true`이면 페이지를 이동. 기본값 `true`
 * @param {boolean} [options.isReplace=false] - 페이지 이동 시 히스토리를 대체할지 여부를 결정. `true`이면 히스토리를 대체하고, `false`이면 새 항목을 추가. 기본값 `false`
 * @param {boolean} [options.isSetPageFirst=true] - 쿼리 파라미터를 업데이트할 때, `page=1`을 추가할지 여부를 결정. 기본값 `true`
 */

const useQueryParams = ({
	path = "",
	isDirectPush = true,
	isReplace = false,
	isSetPageFirst = true,
} = {}) => {
	const navigate = useNavigate();
	const location = useLocation();

	// 현재 URL의 query string을 파싱하여 URLSearchParams 객체로 반환.
	const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

	/**
	 * 쿼리 파라미터를 업데이트하고, isDirectPush 옵션에 따라 페이지 이동을 처리.
	 */
	const updateQueryAndNavigate = () => {
		if (isSetPageFirst) {
			searchParams.set("page", "1");
		}
		const queryString = `${path}?${searchParams.toString()}`;

		if (isDirectPush) {
			navigate(queryString, { replace: isReplace });
		}
		return queryString;
	};

	/**
	 * 특정 쿼리 파라미터 값을 가져오는 함수.
	 * @param {string} key - 가져올 쿼리 파라미터의 키
	 * @returns {string|null} - 해당 키의 값 또는 null
	 */
	const get = (key) => {
		return searchParams.get(key);
	};

	/**
	 * 모든 쿼리 파라미터를 문자열로 반환.
	 * @returns {string} - 모든 쿼리 파라미터를 포함한 문자열
	 */
	const getAll = searchParams.toString();

	/**
	 * 특정 쿼리 파라미터를 설정
	 * @param {string} key - 설정할 쿼리 파라미터의 키
	 * @param {string} value - 설정할 쿼리 파라미터의 값
	 * @returns {string} - 업데이트된 쿼리 문자열
	 */
	const set = (key, value) => {
		searchParams.set(key, value);
		return updateQueryAndNavigate();
	};

	/**
	 * 다수의 쿼리 파라미터를 설정.
	 * @param {Object} params - 설정할 키-값 쌍 객체
	 * @returns {string} - 업데이트된 쿼리 문자열
	 */
	const setAll = (params) => {
		Object.entries(params).forEach(([key, value]) => {
			if (value !== "" && !(Array.isArray(value) && value.length === 0)) {
				searchParams.set(key, value.toString());
			}
		});
		return updateQueryAndNavigate();
	};

	/**
	 * 특정 쿼리 파라미터를 삭제.
	 * @param {string} key - 삭제할 쿼리 파라미터의 키
	 * @returns {string} - 업데이트된 쿼리 문자열
	 */
	const remove = (key) => {
		searchParams.delete(key);
		return updateQueryAndNavigate();
	};

	/**
	 * 주어진 URL로 페이지를 이동.
	 * @param {string} url - 이동할 URL
	 */
	const push = (url) => {
		navigate(url);
	};

	/**
	 * 쿼리 문자열을 객체로 변환.
	 * @param {string} [queryString=getAll] - 변환할 쿼리 문자열 (기본값: 현재 쿼리 문자열)
	 * @returns {Object} - 쿼리 파라미터의 키-값 쌍을 포함한 객체
	 */
	const queryStringToObject = (queryString = getAll) => {
		const result = {};
		const pairs = queryString.slice(0).split("&");

		pairs.forEach((pair) => {
			const [key, value] = pair.split("=");
			result[key] = decodeURIComponent(value || "");
		});

		return result;
	};

	return {
		get,
		getAll,
		set,
		setAll,
		remove,
		push,
		queryStringToObject,
	};
};

export default useQueryParams;
