/**
 * 탑메뉴
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "src/App";
import useAuth from "src/hooks/useAuth";
import TopMainMemberInfo from "src/pages/component/layout/topMain/topMainMemberInfo";
import TopMainHeader from "src/pages/component/layout/topMain/topMainHeader";
import TopMainUnderHeader from "src/pages/component/layout/topMain/topMainUnderHeader";
import CategoryList from "src/pages/component/categoryList";
import NotificationListDisplay from "src/pages/notification/notificationListDisplay";

const pathList = require("src/path/pathJSON.json");
import { TOP_MENU_ACTIVE_TYPE } from "src/data/constEnum";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/layout/topMain.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */

const TopMain = () => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const auth = useAuth();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const [isShowSearchLayer, setIsShowSearchLayer] = useState(false);
	const [isShowCategoryLayer, setIsShowCategoryLayer] = useState(false);
	const [isShowNotificationLayer, setIsShowNotificationLayer] = useState(false);
	const [menuType, setMenuType] = useState("");
	const { setIsLogin } = useContext(AppContext);
	const { setIsProductDetail } = useContext(AppContext);
	const { device } = useContext(AppContext);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 카테고리 클릭 시
	 * @param {Event} e
	 */
	const handlerClickCategory = (e) => {
		if (e) {
			//버블링 막기;
			e.preventDefault();
			e.stopPropagation();
		}

		setIsShowCategoryLayer(!isShowCategoryLayer);
	};

	/**
	 * 알림 클릭 시
	 * @param {Event} e
	 */
	const handlerClickNotification = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (auth?.isLogin) setIsShowNotificationLayer(!isShowNotificationLayer);
		else navigate(pathList.member.login);
	};

	/**
	 * 카테고리 목록 박스에서 mouse leave
	 * @param {Event} e
	 */
	const handlerMouseOutCategory = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		setIsShowCategoryLayer(!isShowCategoryLayer);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * URL에 따른 메뉴 활성화 여부 설정 및 검색어 존재 시 클리어
	 */
	const checkMenuActive = () => {
		if (pathname === pathList.main) {
			setMenuType(TOP_MENU_ACTIVE_TYPE.PRODUCT);
		} else if (pathname.indexOf("/new") > -1) {
			setMenuType(TOP_MENU_ACTIVE_TYPE.NEW);
		} else if (pathname.indexOf("/best") > -1) {
			setMenuType(TOP_MENU_ACTIVE_TYPE.BEST);
		} else if (pathname.indexOf("/special") > -1) {
			setMenuType(TOP_MENU_ACTIVE_TYPE.SPECIAL);
		} else if (pathname.indexOf("/cart") > -1) {
			setMenuType(TOP_MENU_ACTIVE_TYPE.CART);
		} else if (pathname.indexOf("/myLinme") > -1) {
			setMenuType(TOP_MENU_ACTIVE_TYPE.MYLINME);
		} else if (pathname.indexOf("/myPage/shopping") > -1) {
			setMenuType(TOP_MENU_ACTIVE_TYPE.SHOPPING);
		} else {
			setMenuType(TOP_MENU_ACTIVE_TYPE.ETC);
		}

		if (pathname.indexOf(pathList.product.detail) > -1) {
			setIsProductDetail(true);
		} else {
			setIsProductDetail(false);
		}
	};

	/**
	 * 읽지 않은 알림 여부에 대해 재확인
	 */
	const updateNotificationUnreadCount = async () => {
		await auth.checkNotification();
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		const setPage = async () => {
			if (!auth.isLogin) await auth.loginCheck();

			if (auth.isLogin === true) {
				await setIsLogin(true);
				await updateNotificationUnreadCount();
			} else {
				await setIsLogin(false);

				if (pathname === pathList.payment.index) {
					navigate(pathList.main);
				}
			}
			await checkMenuActive();
		};
		setPage();

		//언마운트 시 실행;
		return () => {
			if (isShowCategoryLayer) setIsShowCategoryLayer(false);
			if (isShowSearchLayer) setIsShowSearchLayer(false);
		};
	}, [pathname, device]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<>
			<header className="container topMain">
				{/* 로그인 및 회원 정보 */}
				<TopMainMemberInfo />
				<TopMainHeader
					menuType={menuType}
					setIsShowNotificationLayer={setIsShowNotificationLayer}
				/>
				<TopMainUnderHeader menuType={menuType} handlerClickCategory={handlerClickCategory} />
			</header>
			<NotificationListDisplay
				isShow={isShowNotificationLayer}
				handlerClickToggle={handlerClickNotification}
			/>
			<CategoryList
				isShow={isShowCategoryLayer}
				handlerMouseOut={handlerMouseOutCategory}
				handlerClickToggle={handlerClickCategory}
			/>
		</>
	);
};

export default TopMain;
