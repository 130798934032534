// 건강 고민 슬라이드

import { useCallback, memo } from "react";
import { SwiperSlide } from "swiper/react";
import { Grid, Scrollbar } from "swiper/modules";
import clsx from "clsx";
import useDeviceType from "src/hooks/useDeviceType";
import Image from "src/pages/component/common/image";
import Swiper from "src/pages/component/swiper";

import "src/styles/healthIssueSlider.scss";

const HealthIssueSlider = (props) => {
	const { issueList, handlerClickHealthIssue } = props;
	const { mobileType } = useDeviceType();

	// 반응형 슬라이드 개수 설정
	const getResponsiveSlideToShow = useCallback(() => {
		if (mobileType === "MOBILE_MINI") {
			return 6;
		}
		if (mobileType === "MOBILE") {
			return 6;
		}
		if (mobileType === "TABLET") {
			return 10;
		}
		return 12;
	}, [mobileType]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	// 슬릭 버그 방지
	if (!issueList) return;

	return (
		<div className="contents">
			<div className="healthIssueIcons">
				<Swiper
					modules={[Grid, Scrollbar]}
					slidesPerView={getResponsiveSlideToShow()}
					scrollbar={{
						hide: false,
						dragSize: 30,
						horizontalClass: "swiperScrollbar",
					}}
					spaceBetween={10}
					grid={{
						rows: 2,
						fill: "row",
					}}
					className="swiperContainer"
				>
					{issueList?.map((issue) => {
						if (issue.name === "탈모/손톱") return;
						return (
							<SwiperSlide key={issue.issueNo} onClick={() => handlerClickHealthIssue(issue)}>
								<div className="healthIssueIcon">
									<Image
										className="healthIssueImage"
										src={issue?.file?.webPath}
										alt={issue?.name}
									/>
									<div
										className={clsx([
											"healthIssueName",
											{ on: issue.issueNo === props.selectedHealthIssueNo },
										])}
									>
										{issue?.name}
									</div>
								</div>
							</SwiperSlide>
						);
					})}
				</Swiper>
			</div>
		</div>
	);
};

export default memo(HealthIssueSlider);
