/**
 * 마이페이지 - 내 정보 관리 - 회원 정보 수정
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useRef } from "react";

import useAuth from "src/hooks/useAuth";
import useModalStack from "src/hooks/useModalStack";
import { API_RESULT_STATUS, VERIFY_ENV, VERIFY_TYPE } from "src/data/constEnum";
import { requestVerify } from "src/api/member/signUp";
import { modifyMemberPhone } from "src/api/member/modify";
import { isMobile } from "react-device-detect";

import MoveToBack from "src/pages/component/moveToBack";
import AuthVerifyIframe from "src/pages/member/modal/authVerifyIframe";
import ModalCommonAlert from "src/pages/common/modalAlert";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myPage/myInfo/myInfoModify.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const MyPageMyInfoModify = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const auth = useAuth();
	const modalStack = useModalStack();
	const ref = {
		checkVerify: useRef(null),
		ci: useRef(null),
		newPhone: useRef(null),
		buttonSave: useRef(null),
	};

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 저장 클릭 시
	 * @param {Event} e
	 */
	const handlerClickSave = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const ct = e.currentTarget;
		if (ct.classList.contains("on")) {
			if (auth.user.phone !== ref.newPhone.current.value) {
				//기존 휴대폰 정보와 다를 경우;
				await requestModifyMemberPhone();
			} else {
				//기존 휴대폰 정보와 동일한 경우;
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.sameAsExistingData,
				});
				await reset();
			}
		} else {
			modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.verifyFirst,
			});
			return false;
		}
	};

	/**
	 * 본인인증 클릭 시
	 * @param {Event} e
	 */
	const handlerClickVerify = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		// 기 인증 완료한 경우 실행하지 않음;
		if (ref.checkVerify.current.value) return false;

		await postRequestVerify().then((data) => {
			modalStack.addModal({
				id: "modalVerify",
				type: "none",
				component: <AuthVerifyIframe {...data} />,
				setDataAfterVerify: setDataAfterVerify,
			});
		});
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 본인인증
	 */
	const postRequestVerify = async () => {
		try {
			const d = {
				env: isMobile ? VERIFY_ENV.WEB.MOBILE : VERIFY_ENV.WEB.PC,
				memberNo: 0,
				type: VERIFY_TYPE.SIGN_UP,
			};
			const result = await requestVerify(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				return result.data;
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	/**
	 * 휴대폰 번호 변경 요청
	 * @returns
	 */
	const requestModifyMemberPhone = async () => {
		try {
			const d = {
				ci: ref.ci.current.value,
				newPhone: ref.newPhone.current.value,
			};
			const result = await modifyMemberPhone(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.changedMemberPhone,
				});
				reset();
				return result.data;
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	const reset = () => {
		ref.buttonSave.current.classList.remove("on");
		ref.checkVerify.current.value = false;
		ref.ci.current.value = "";
	};

	// ----------------------------------------------------------------------------------------------------;
	// SETTER;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 본인인증 후처리
	 * @param {Object} d
	 */
	const setDataAfterVerify = async (d) => {
		if (!d.hasOwnProperty("status")) return;
		if (d.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
			/*/
			//이름;
			ref.name.current.value = d.name;
			//성별;
			ref.gender.current.value = d.gender;
			//생일;
			ref.birth.current.value = d.birthday;
			/*/
			//휴대폰;
			ref.newPhone.current.value = d.phone;
			//*/
			//CI;
			ref.ci.current.value = d.ci;
			//본인인증 여부;
			ref.checkVerify.current.value = true;
			//버튼 on;
			ref.buttonSave.current.classList.add("on");
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	/*/
	useEffect(() => {
		return () => {
		};
	}, []);
	//*/

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="myInfoModifyContainer">
			<div className="titleWrapper">
				<MoveToBack />
				<div className="title">{textInfo.myPage.myProfileModify.title}</div>
			</div>
			<div className="myInfoModifyCover">
				<ul className="modifyList">
					<li className="modifyItem">
						<div className="label">{textInfo.member.data.name}</div>
						<div className="data">
							<input
								type="text"
								className="inputData name"
								defaultValue={auth.isLogin && auth.user.name ? auth.user.name : ""}
								placeholder={textInfo.member.placeholder.name}
								readOnly
							/>
						</div>
					</li>
					<li className="modifyItem">
						<div className="label">{textInfo.member.data.email}</div>
						<div className="data">
							<input
								type="text"
								className="inputData email"
								defaultValue={auth.isLogin && auth.user.email ? auth.user.email : ""}
								placeholder={textInfo.member.placeholder.email}
								readOnly
							/>
						</div>
					</li>
					<li className="modifyItem">
						<div className="label">{textInfo.member.data.phone}</div>
						<div className="data">
							<input type="hidden" ref={ref.ci} name="ci" />
							<input type="hidden" ref={ref.newPhone} name="newPhone" />
							<input type="hidden" ref={ref.checkVerify} name="checkVerify" />
							<div
								className="button clickable certification"
								onClick={(e) => handlerClickVerify(e)}
							>
								{textInfo.button.verify}
							</div>
						</div>
					</li>
				</ul>
				<div
					className="button save"
					ref={ref.buttonSave}
					onClick={(e) => {
						handlerClickSave(e);
					}}
				>
					{textInfo.button.save}
				</div>
			</div>
		</div>
	);
};

export default MyPageMyInfoModify;
