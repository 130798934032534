/**
 * JSON Array 새 객체로 복사하여 반환
 * @function
 * @param {Array} a
 * @return {Array}
 * @example
 * <code>
const a = [
	{ "id" : 0, "a" : "aaa", "b" : 3 }
	, { "id" : 1, "a" : "bbb", "b" : 5 }
	, { "id" : 2, "a" : "ccc", "b" : 7 }
];
const b = array_collection.copyCollection( a );
window.linmeLog.log( a == b );
* </code>
*/
export const copyCollection = function (a) {
	let r = null;
	if (a?.length > 0) {
		r = [];
		const keys = Object.keys(a[0]);
		let i = 0;
		const iLen = a.length;
		let io; //a[ i ];
		let j = 0;
		const jLen = keys.length;
		let jo; //keys[ j ];
		for (; i < iLen; ++i) {
			r[i] = {};
			io = a[i];
			j = 0;
			for (; j < jLen; ++j) {
				jo = keys[j];
				r[i][jo] = io[jo];
			}
		}
	}
	return r;
};

/**
 * Array에서 value 값의 인덱스를 반환
 * @function
 * @param {Array} a
 * @param {String} key
 * @param {*} val
 * @return {Number}
 */
export const getIndexFromArrayByKeyAndValue = (a, key, val) => {
	let r = null;
	let i = 0;
	const iLen = a.length;
	let io; //a[ i ];
	for (; i < iLen; ++i) {
		io = a[i];
		if (io[key] === val) {
			r = i;
			break;
		}
	}
	return r;
};

/**
 * JSON Array에서 Key 객체의 값을 기준으로 삭제
 * @param {Array} a
 * @param {String} key
 * @param {*} val
 * @returns {Array}
 */
export const removeObjectByKeyValue = (a, key, val) => {
	let i = 0;
	const iLen = a.length;
	let io; //a[ i ];
	for (; i < iLen; ++i) {
		io = a[i];
		if (io[key] === val) {
			a.splice(i, 1);
			break;
		}
	}
	return a;
};
