/**
 * 상품 카드
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { Link } from "react-router-dom";

import { PRODUCT_STATUS } from "src/data/constEnum";

import Image from "src/pages/component/common/image";
import { getProductDetailType } from "src/utils/linmeSearch";

/* ---------------------------------------------------------------------------------------------------- */
/* import styles */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const ProductCard = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// 배송비 정보 표시
	const getDeliveryPrice = (deliveryPrice) => {
		if (deliveryPrice && deliveryPrice > 0) {
			return `${
				textInfo.payment.checkOrderProduct.deliveryPrice
			} ${deliveryPrice?.toLocaleString()}${textInfo.common.currencyUnit}`;
		}
		return `${textInfo.payment.deliveryInfo.free}`;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div
			className={
				"productCard " +
				(props.status === PRODUCT_STATUS.DEAD ? "saleEnd" : "") +
				(props.status === PRODUCT_STATUS.SOLDOUT ? "soldout" : "")
			}
		>
			<Link
				className="productCardContainer"
				to={pathList.product.detail + `${getProductDetailType(props?.type)}/` + props.productNo}
			>
				{/* 상단 */}
				<div className="productCardImageContainer">
					{/* 상품 이미지 */}
					{props.thumbnail ? (
						<div className="productImageCover">
							<Image className="productImage " src={props.thumbnail.webPath} alt={props.name} />
						</div>
					) : (
						<></>
					)}
				</div>
				{/* 하단 */}
				<div className="productCardItem">
					{/* 상품명 */}
					<div className="productName">{props.name}</div>
					<div className="productPriceContainer">
						{props.salePercent > 0 && (
							<div className="productPriceInfo">
								{/* 할인율 */}
								<div className="discountRate">
									{props.salePercent?.toLocaleString()}
									<span className="percentUnit">{textInfo.common.percentUnit}</span>
								</div>
								{/* 할인 전 금액 */}
								<div className="price">
									{props.price?.toLocaleString()}
									{textInfo.common.currencyUnit}
								</div>
							</div>
						)}
						{/* 할인 금액 */}
						<div className="salePrice">
							{props.salePrice?.toLocaleString()}
							{textInfo.common.currencyUnit}
						</div>
						{/* 배송비 */}
						<div className="deliveryPrice">{getDeliveryPrice(props?.deliveryPrice)}</div>
					</div>
				</div>
			</Link>
		</div>
	);
};

export default ProductCard;
