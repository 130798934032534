/**
 * 타임특가 시계
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useRef, memo } from "react";
import moment from "moment";

import Image from "src/pages/component/common/image";
import styles from "src/styles/main/topicSection/timer.module.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

const Timer = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;
	const interval = 1000; //1초;
	const intervalRef = useRef(null);

	const ref = {
		hh: useRef(null),
		mm: useRef(null),
		ss: useRef(null),
	};

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 타이머 시간 계산
	 */
	const calcTime = () => {
		const now = moment();
		const endTime = moment(props.endAt);
		const duration = moment.duration(endTime.diff(now));

		if (ref.hh.current === null) return;
		if (ref.mm.current === null) return;
		if (ref.ss.current === null) return;

		const hours = Math.floor(duration.asHours()); // 남은 시간을 계산

		ref.hh.current.innerText = hours.toString().padStart(2, "0"); // HH 포맷으로 표현하기 위함
		ref.mm.current.innerText = moment(duration.minutes(), "m").format("mm");
		ref.ss.current.innerText = moment(duration.seconds(), "s").format("ss");
	};

	/**
	 * 타이머
	 * @returns {DOM}
	 */
	const showTimer = () => {
		// 문구 - 오픈까지의 시간이 남아있으면 "OPEN", 이미 열려있으면 "CLOSE";
		// 타임특가 : 데일리 ( 10시 ~ 12시 등 시간 별도 설정 );
		// 보통 1달 후의 내용까지 계획이 잡혀있음. 신청을 받아 운영단에서 스케줄링;
		if (props.isShowTimer === true) {
			let str = "OPEN";
			let now = new Date();
			const dateOpen = new Date(props.startAt);
			if (now.getTime() >= dateOpen.getTime()) str = "CLOSE";

			return (
				<div className={styles.timer}>
					<span className={styles.timerType}>{str}</span>
					<span className={styles.remainTime}>
						<span className={styles.hh} ref={ref.hh}></span>
						<span>:</span>
						<span className={styles.mm} ref={ref.mm}></span>
						<span>:</span>
						<span className={styles.ss} ref={ref.ss}></span>
					</span>
				</div>
			);
		} else return null;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		intervalRef.current = setInterval(() => {
			calcTime();
		}, interval);

		return () => {
			clearInterval(intervalRef.current);
		};
	}, [props]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className={styles.timerInfoContainer}>
			<Image
				className={styles.clockIcon}
				srcSet="
					../../../../images/topic/clock_topic.png 1x
					, ../../../../images/topic/clock_topic@2x.png 2x
					, ../../../../images/topic/clock_topic@3x.png 3x
				"
				alt="타임 특가"
			/>
			{
				//타이머;
				showTimer()
			}
		</div>
	);
};

export default memo(Timer);
