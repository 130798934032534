/**
 * 마이페이지 - 내 정보 관리
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { Link, useNavigate } from "react-router-dom";

import useAuth from "src/hooks/useAuth";
import { LOGIN_TYPE } from "src/data/constEnum";
import { logout } from "src/utils/linmeSession";

import MoveToBack from "src/pages/component/moveToBack";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myPage/myInfo/index.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const MyPageMyInfo = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const auth = useAuth();
	const navigate = useNavigate();

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 뒤로가기 화살표 클릭 시 ( 모바일 )
	 * @param {Event} e
	 */
	const handlerClickMoveToBack = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		navigate(-1);
	};

	/**
	 * 로그아웃 클릭 시
	 * @param {Event} e
	 */
	const handlerClickLogout = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		logout(auth, navigate);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="container myPage myInfoContainer">
			<div className="contents">
				<div className="titleWrapper">
					<MoveToBack />
					<div className="title">{textInfo.myPage.myInfoModify.title}</div>
				</div>
				<ul className="myInfoList">
					{auth.isLogin && auth.user.isBaseInfo === true ? (
						<li className="myInfoItem lock">
							<Link to={pathList.myPage.myInfoModify}>
								<div className="infoType">{textInfo.myPage.myProfileModify.title}</div>
								<div className="description">{textInfo.myPage.myProfileModify.description}</div>
							</Link>
						</li>
					) : (
						<></>
					)}
					{auth.isLogin && auth.user.loginType === LOGIN_TYPE.EMAIL ? (
						<li className="myInfoItem shield">
							<Link to={pathList.myPage.myInfoPasswordModify}>
								<div className="infoType">{textInfo.myPage.changePassword.title}</div>
								<div className="description">{textInfo.myPage.changePassword.description}</div>
							</Link>
						</li>
					) : (
						<></>
					)}
					<li className="myInfoItem alarm">
						<Link to={pathList.myPage.myInfoNotification}>
							<div className="infoType">{textInfo.myPage.myInfoNotification.title}</div>
							<div className="description">{textInfo.myPage.myInfoNotification.description}</div>
						</Link>
					</li>
					<li className="myInfoItem power">
						<a href="{()=>false}" onClick={(e) => handlerClickLogout(e)}>
							<div className="infoType">{textInfo.myPage.logout.title}</div>
							<div className="description">{textInfo.myPage.logout.description}</div>
						</a>
					</li>
					<li className="myInfoItem withdrawal">
						<Link to={pathList.myPage.myInfoWithdrawal}>
							<div className="infoType">{textInfo.myPage.withdrawal.title}</div>
							<div className="description">{textInfo.myPage.withdrawal.description}</div>
						</Link>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default MyPageMyInfo;
