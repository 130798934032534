/**
 * 마이린미 - 건강 MBTI 테스트 결과 ( 요약 모달 )
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import Image from "src/pages/component/common/image";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myLinme/MBTIResultSummary.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const MyLinmeMBTIResultSummary = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const { resultSummaryData } = props;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 자세히 알아보기 클릭 시
	 * @param {Event} e
	 */
	const handlerClickMoreDetail = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const state = {};

		if (props.moveToDetail) props.moveToDetail(state);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="modalResultSummaryContainer">
			<div className="title">{textInfo.myLinme.mbti.testResultSummary.title}</div>
			<div className="resultContainer">
				<div className="mbtiResult">
					<div className="mbtiTitle">{resultSummaryData?.rsvVal1} </div>
					<div className="mbtiDescription">{resultSummaryData?.rsvVal2}</div>
					<div className="mbtiContent">{resultSummaryData?.rsvVal3}</div>
				</div>
				<Image className="introduce" src={resultSummaryData?.rsvVal4} alt="mbtiResult" />
			</div>
			<div className="buttonContainer">
				<div className="button clickable moreDetail" onClick={(e) => handlerClickMoreDetail(e)}>
					{textInfo.button.moreDetail}
				</div>
			</div>
		</div>
	);
};

export default MyLinmeMBTIResultSummary;
