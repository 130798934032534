/**
 * 배송지 관리
 */

import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/**
 * 회원 배송지 입력
 * @param {Object} d
 * <code>
 * {
 * 		nickname: {String}//배송지명;
 * 		, name: {String}//수취인;
 * 		, phone: {String}//연락처;
 * 		, postcode: {String}//우편번호;
 * 		, address1: {String}//주소;
 * 		, address2: {String}//상세주소;
 * 		, memo: {String}//배송요청사항;
 * 		, isCheckRep: {Boolean}//기본 배송지 설정;
 * }
 * </code>
 * @returns
 */
export const addAddress = async (d) => {
	const url = `${API_PATHS.MEMBER_ADDRESS}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 회원 배송지 삭제
 * @param {Object} d
 * <code>
 * {
 * 		addressNo: {Number}//배송지 번호;
 * }
 * </code>
 * @returns
 */
export const deleteAddress = async (d) => {
	const url = `${API_PATHS.MEMBER_ADDRESS}`;
	try {
		const result = await api.delete(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 회원 배송지 목록 조회
 * @param {Object} d
 * <code>
 * {
 * 		offset: 0
 * 		, page: 0
 * }
 * </code>
 * @returns
 */
export const getAddressList = async (d) => {
	const url = `${API_PATHS.MEMBER_ADDRESS}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 회원 배송지 수정
 * @param {Object} d
 * <code>
 * {
 * 		addressNo: {Number}//배송지 번호;
 * 		, nickname: {String}//배송지명;
 * 		, name: {String}//수취인;
 * 		, phone: {String}//연락처;
 * 		, postcode: {String}//우편번호;
 * 		, address1: {String}//주소;
 * 		, address2: {String}//상세주소;
 * 		, memo: {String}//배송요청사항;
 * 		, isCheckRep: {Boolean}//기본 배송지 설정;
 * }
 * </code>
 * @returns
 */
export const modifyAddress = async (d) => {
	const url = `${API_PATHS.MEMBER_ADDRESS}`;
	try {
		const result = await api.put(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
