/**
 * 레이아웃 마이페이지
 */

import { useContext } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { SwiperSlide } from "swiper/react";

import useBannerList from "src/hooks/main/useBannerList";
import useAuth from "src/hooks/useAuth";
import { AppContext } from "src/App";

import TopMain from "src/pages/layout/topMain";
import Footer from "src/pages/layout/footer";
import FixedMenu from "src/pages/layout/fixedMenu";
import MyPageSummary from "src/pages/layout/myPage/myPageSummary";
import Swiper from "../component/swiper";
import BannerSlideItem from "../component/carousel/bannerSlideItem";

import { BANNER_TYPE, DEVICE_TYPE } from "src/data";
const pathList = require("src/path/pathJSON.json");
const textInfo = require("src/data/textInfo.json");
import "src/styles/common.scss";
import "src/styles/myPage/layoutMyPage.scss";

const LayoutMyPage = () => {
	const auth = useAuth();
	const { pathname } = useLocation();
	const { device } = useContext(AppContext);
	const { data: bannerList, isLoading } = useBannerList(BANNER_TYPE.MYPAGE);

	/**
	 * 레이아웃 설정
	 * @returns
	 */
	const checkMenuLayout = (a) => {
		let menuType = "";
		if (
			//마이페이지;
			pathname === pathList.myPage.index ||
			pathname === pathList.myPage.inquiry ||
			pathname === pathList.myPage.productInquiry ||
			pathname === pathList.myPage.refundAccount ||
			pathname === pathList.myPage.addressBook ||
			pathname === pathList.myPage.addressBookAdd ||
			pathname === pathList.myPage.notice ||
			pathname === pathList.myPage.faq
		) {
			menuType = "myPage";
		} else if (
			//쿠폰/리뷰;
			pathname === pathList.myPage.coupon ||
			pathname === pathList.myPage.review
		) {
			menuType = "myShopping";
		} else if (
			//내정보관리;
			pathname === pathList.myPage.myInfoModify ||
			pathname === pathList.myPage.myInfoPasswordModify ||
			pathname === pathList.myPage.myInfoNotification ||
			pathname === pathList.myPage.myInfoWithdrawal
		) {
			menuType = "myInfo";
		} else if (
			//프로필수정 및 내정보관리, 쇼핑내역 진입;
			pathname === pathList.myPage.profile ||
			pathname === pathList.myPage.myInfo ||
			pathname === pathList.myPage.shoppingOrder ||
			pathname === pathList.myPage.shoppingClaim ||
			pathname.includes(pathList.myPage.shoppingDetail)
		) {
			menuType = "noOtherMenu";
		}
		return menuType;
	};

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	if (isLoading) return;

	return (
		<div className="mainWrapper">
			<TopMain />
			<main>
				<div className="container underTopMenu myPage index">
					{checkMenuLayout() === "noOtherMenu" || checkMenuLayout() === "myInfo" ? (
						<></>
					) : (
						<MyPageSummary />
					)}
					<div className="contents">
						{/* {(checkMenuLayout() === "myPage" ||
							pathname === pathList.myPage.coupon ||
							pathname === pathList.myPage.review) &&
							bannerList.length > 0 && ( */}
						<div className="bannerBand">
							<Swiper>
								{bannerList?.map((banner) => {
									return (
										<SwiperSlide key={banner.itemNo}>
											<BannerSlideItem banner={banner} className="slide" />
										</SwiperSlide>
									);
								})}
							</Swiper>
						</div>
						{/* )} */}
						<div className={"myPageContents " + checkMenuLayout()}>
							{checkMenuLayout() === "myInfo" ? (
								<div className="myInfoMenu">
									<ul className="myInfoMenuList">
										{auth.isLogin && auth.user.isBaseInfo === true ? (
											<li
												className={
													"myInfoMenuItem " +
													(pathname === pathList.myPage.myInfoModify ? "on" : "")
												}
											>
												<Link to={pathList.myPage.myInfoModify}>
													<div>{textInfo.myPage.myProfileModify.title}</div>
												</Link>
											</li>
										) : (
											<></>
										)}
										<li
											className={
												"myInfoMenuItem " +
												(pathname === pathList.myPage.myInfoPasswordModify ? "on" : "")
											}
										>
											<Link to={pathList.myPage.myInfoPasswordModify}>
												<div>{textInfo.myPage.changePassword.title}</div>
											</Link>
										</li>
										<li
											className={
												"myInfoMenuItem " +
												(pathname === pathList.myPage.myInfoNotification ? "on" : "")
											}
										>
											<Link to={pathList.myPage.myInfoNotification}>
												<div>{textInfo.myPage.myInfoNotification.title}</div>
											</Link>
										</li>
										<li
											className={
												"myInfoMenuItem " +
												(pathname === pathList.myPage.myInfoWithdrawal ? "on" : "")
											}
										>
											<Link to={pathList.myPage.myInfoWithdrawal}>
												<div>{textInfo.myPage.withdrawal.title}</div>
											</Link>
										</li>
									</ul>
								</div>
							) : (
								<></>
							)}
							{checkMenuLayout() === "myPage" ? (
								<div
									className={
										"myPageMenu " +
										(pathname === pathList.myPage.index || device === DEVICE_TYPE.PC ? "on" : "")
									}
								>
									{auth.isLogin ? (
										<div className="myPageShopping">
											<div className="title">{textInfo.myPage.shopping}</div>
											<ul className="myShoppingList">
												{/* 상품 문의 내역 */}
												<li
													className={
														"myShoppingItem " +
														(pathname === pathList.myPage.productInquiry ? "on" : "")
													}
												>
													<Link to={pathList.myPage.productInquiry}>
														<div>{textInfo.myPage.productInquiry.title}</div>
													</Link>
												</li>
												{/* 환불계좌 관리 */}
												<li
													className={
														"myShoppingItem " +
														(pathname === pathList.myPage.refundAccount ? "on" : "")
													}
												>
													<Link to={pathList.myPage.refundAccount}>
														<div>{textInfo.myPage.myRefundAccount.title}</div>
													</Link>
												</li>
												{/* 배송지 관리 */}
												<li
													className={
														"myShoppingItem " +
														(pathname === pathList.myPage.addressBook ? "on" : "")
													}
												>
													<Link to={pathList.myPage.addressBook}>
														<div>{textInfo.myPage.myAddressBook.title}</div>
													</Link>
												</li>
											</ul>
										</div>
									) : (
										<></>
									)}
									<div className="myPageCS">
										<div className="title">{textInfo.myPage.cs}</div>
										<ul className="myCSList">
											{/* 공지사항 */}
											<li
												className={"myCSItem " + (pathname === pathList.myPage.notice ? "on" : "")}
											>
												<Link to={pathList.myPage.notice}>
													<div>{textInfo.myPage.notice}</div>
												</Link>
											</li>
											{/* FAQ */}
											<li className={"myCSItem " + (pathname === pathList.myPage.faq ? "on" : "")}>
												<Link to={pathList.myPage.faq}>
													<div>{textInfo.myPage.faq}</div>
												</Link>
											</li>
											{/* 1:1 문의 */}
											<li
												className={
													"myShoppingItem " + (pathname === pathList.myPage.inquiry ? "on" : "")
												}
											>
												<Link to={pathList.myPage.inquiry}>
													<div>{textInfo.myPage.myInquiry.title}</div>
												</Link>
											</li>
										</ul>
									</div>
								</div>
							) : (
								<></>
							)}
							<div className="myPageDetail">
								<Outlet />
							</div>
						</div>
					</div>
				</div>
			</main>
			<Footer />

			{/* <Top /> */}
			<FixedMenu />
		</div>
	);
};

export default LayoutMyPage;
