/**
 * Custom Number Stepper
	<CustomNumberStepper
		min={1}
		max={9999}
		count={count}
		setCount={setCount}
	/>
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";

import useModalStack from "src/hooks/useModalStack";

import ModalCommonAlert from "src/pages/common/modalAlert";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/customNumberStepper.scss";

const textInfo = require("src/data/textInfo.json");

const CustomNumberStepper = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const { count, min, max, readOnly, setCount } = props;
	const modalStack = useModalStack();

	const [number, setNumber] = useState(1); // 입력 시 사용할 state

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 빼기
	 */
	const handlerClickMinus = async () => {
		const inputValue = parseInt(count - 1);
		if ((await validation(inputValue)) !== true) return;
		setCount(inputValue);
	};

	/**
	 * 더하기
	 */
	const handlerClickPlus = async () => {
		const inputValue = parseInt(count + 1);
		if ((await validation(inputValue)) !== true) return;
		setCount(inputValue);
	};

	/**
	 * 입력 변경 시 - 자연수만 받기
	 * @param {Event}} e
	 */
	const handlerChangeNumber = async (e) => {
		const inputValue = e.target.value;
		setNumber(inputValue.replace(/[^0-9]/g, "")); //자연수만 입력 가능
	};

	/**
	 * 블러 시 - 최대값, 최소값 체크 후 부모에게 전달
	 * @param {Event} e
	 */
	const handlerBlurNumber = async (e) => {
		const inputValue = parseInt(e.target.value);
		if ((await validation(inputValue)) !== true) {
			setNumber(count);
			return;
		}
		setCount(inputValue);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 입력값 체크
	 */
	const validation = async (inputValue) => {
		// 최소값 미만일 경우
		if (inputValue < min) {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.numberStepper.belowMin,
			});
			return false;
		}

		// 최대값 초과일 경우
		if (inputValue > max) {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.numberStepper.overMax,
			});
			return false;
		}

		// 입력값이 없을 경우
		if (!inputValue) {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.numberStepper.noInput,
			});
			return false;
		}

		return true;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		setNumber(count);
	}, [count]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="customNumberStepperContainer">
			{/* 마이너스 */}
			<div className="customNumberStepperButton" onClick={handlerClickMinus}>
				<div className="minus" />
			</div>
			{/* 카운트 */}
			<input
				className="customNumberStepperInput"
				type="number"
				value={number || ""}
				min={min}
				max={max}
				readOnly={readOnly}
				onChange={(e) => handlerChangeNumber(e)}
				onBlur={(e) => handlerBlurNumber(e)}
				onKeyDown={(e) => {
					if (e.key === "Enter") handlerBlurNumber(e); // 엔터키가 눌렸을 때 블러 핸들러 호출
				}}
			/>
			{/* 플러스 */}
			<div className="customNumberStepperButton" onClick={handlerClickPlus}>
				<div className="plus" />
			</div>
		</div>
	);
};

export default CustomNumberStepper;
