/**
 * linme 세션 관련 함수
 */

import CryptoJS from "crypto-js";

import { LOCAL_STORAGE_KEY } from "src/data/key";

const pathList = require("src/path/pathJSON.json");

/**
 * 로그아웃
 * @param {Object} auth
 * @param {Object} navigate
 * @param {Function} setIsLogin
 */
export const logout = async (auth, navigate, setIsLogin) => {
	const isLogout = await auth.logout();
	if (!isLogout) {
		throw new Error("Logout failed");
	}
	await auth.setIsLogin(false);
	await navigate(pathList.main);
};

/**
 * 비밀번호 암호화
 * @param {Object} auth
 * @param {Object} navigate
 * @param {Function} setIsLogin
 */
export const encryptPassword = async (password) => {
	const salt = process.env.REACT_APP_LINME_KEY_SALT;
	const hashedPassword = CryptoJS.SHA256(password + salt);
	const base64EncodedHash = CryptoJS.enc.Base64.stringify(hashedPassword);
	return base64EncodedHash;
};

/**
 * 개발 환경 여부
 */
export const isLocalEnv = () => {
	return process.env.REACT_APP_LINME_NODE_ENV === "local";
};

/**
 * UUID 가져오기
 */
export const getOrCreateUUID = () => {
	const uuidKey = LOCAL_STORAGE_KEY.UUID;
	let uuid = window.localStorage.getItem(uuidKey);

	if (!uuid) {
		uuid = generateUUID();
		try {
			window.localStorage.setItem(uuidKey, uuid);
		} catch (error) {
			console.error("Failed to save UUID to localStorage:", error);
		}
	}

	return uuid;
};

/**
 * UUID 생성하기
 */
const generateUUID = () => {
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
		const r = (Math.random() * 16) | 0;
		const v = c === "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
};
