/**
 * 마이페이지 - 쇼핑 내역 - 주문/배송
 */

import { useLocation } from "react-router-dom";

import OrderList from "src/pages/component/order/orderList";
import MyPageShoppingTab from "src/pages/myPage/shopping/shoppingTab";

import "src/styles/myPage/shopping.scss";

const MyPageShoppingOrder = () => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const location = useLocation();

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<>
			<div className="shoppingCover">
				<MyPageShoppingTab />
				<OrderList data={location.state ? location.state : null} />
			</div>
		</>
	);
};

export default MyPageShoppingOrder;
