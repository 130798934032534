/**
 * 상품 포토 리뷰 1건 보기
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";
import moment from "moment";

import useModalStack from "src/hooks/useModalStack";
import { API_RESULT_STATUS, EMPTY_USER_PROFILE_IMAGE, REVIEW_SCORE_MIN } from "src/data/constEnum";
import { getReviewDetail } from "src/api/product/review";

import StarScore from "src/pages/component/review/starScore";
import ReviewLike from "src/pages/component/product/reviewLike";
import Image from "src/pages/component/common/image";
import ModalCommonAlert from "src/pages/common/modalAlert";
import ReviewImagePaging from "src/pages/component/carousel/reviewImagePaging";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/product/modal/reviewPhoto.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

const htmlInfo = require("src/data/htmlInfo.json");
const textInfo = require("src/data/textInfo.json");

const ReviewPhoto = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();
	const [data, setData] = useState(null);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 사진 목록 보기 클릭 시
	 * @param {Event} e
	 */
	const handlerClickPhotoList = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await modalStack.removeModal(props.id);
		await modalStack.forceUpdate();
		if (props.showList) await props.showList();
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	const getData = async () => {
		if (props && props.productNo && props.reviewNo) {
			try {
				const result = await getReviewDetail(props.productNo, props.reviewNo);
				if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
					setData(result.data);
				} else {
					modalStack.addModal({
						id: "modalCommonAlert",
						type: "alert",
						component: <ModalCommonAlert />,
						html: htmlInfo.error.dataCall,
					});
					return false;
				}
			} catch (err) {
				window.linmeLog.error(err);
				modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					html: htmlInfo.error.dataCall,
				});
				return false;
			}
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		getData();
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="modalContentsContainer reviewPhotoContainer">
			<div className="title">{textInfo.product.review.photoReviewTitle}</div>
			<div className="reviewCoverContainer">
				{data && data.images && data.images.length && <ReviewImagePaging images={data.images} />}
				{/* {( data && data.images && data.images.length ) ? <ul className="imageList">
				{ data.images.map(( imageItem, index ) => ( <li className="imageItem" key={index}><Image src={imageItem.webPath} alt="" /></li> )) }
				</ul> : <></> } */}
				<div className="reviewCover">
					<div className="user">
						<div className="userProfile">
							<Image
								src={
									data && data.member && data.member.image && data.member.image.webPath
										? data.member.image.webPath
										: EMPTY_USER_PROFILE_IMAGE
								}
								alt={data && data.member && data.member.nickname ? data.member.nickname : "-"}
							/>
						</div>
						<div className="userName">
							{data && data.member && data.member.nickname ? data.member.nickname : "-"}
						</div>
						<div className="writeDate">
							{data && data.registered ? moment(data.registered).format("YYYY. MM. DD") : "-"}
						</div>
					</div>
					<div className="scoreAndLike">
						<StarScore {...{ score: data && data.rate ? data.rate : REVIEW_SCORE_MIN }} />
						<ReviewLike
							{...{
								reviewNo: props.reviewNo,
								isCheckLike: data && data.isCheckLike ? data.isCheckLike : false,
								likeCount: data && data.likeCount ? data.likeCount : 0,
								refetch: getData,
							}}
						/>
					</div>
					<div className="reviewContent">{data && data.content ? data.content : "-"}</div>
				</div>
			</div>

			<div className="buttonContainer">
				<button className="moveToPhotoList" onClick={(e) => handlerClickPhotoList(e)}>
					{textInfo.button.moveToPhotoList}
				</button>
			</div>
		</div>
	);
};

export default ReviewPhoto;
