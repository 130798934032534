/**
 * Object 자료구조 복제 후 반환
 * @function
 * @param {Object} d
 * @param {Object|Null} t
 * @return {Object} r
 * @example
 * <code>
 	var d = copyStructure( { a : "123", b : [ 1, 2, 3 ], c : { a : 1, b : 2, c : 3 } } );
 	var d = copyStructure( { a : "123", b : [ 1, 2, 3 ], c : { a : 1, b : 2, c : 3 } }, { d : 4 } );
 * </code>
 */
export const copyStructure = (d, t) => {
	let r = t || {};
	let io;
	for (let s in d) {
		io = d[s];
		if ("object" == typeof io) {
			r[s] = copyStructure(io);
		} else r[s] = io;
	}
	return r;
};

/**
 * s 를 기준으로 t 합치기
 * @param {Object} s
 * @param {Object} t
 * @return {Object} r
 */
export const mergeObject = (s, t) => {
	for (let key in t) {
		if (!s.hasOwnProperty(key)) s[key] = t[key];
	}
	return s;
};
