import { useState, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";

import { getMainDay } from "src/api/myLinme/main";
import { queryKeys } from "src/data";

const useAlarmCalenderDate = () => {
	const [currentDate, setCurrentDate] = useState(new Date());
	const formattedDate = useMemo(() => moment(currentDate).format("YYYY-MM-DD"), [currentDate]);

	const { data } = useQuery({
		queryKey: [queryKeys.ALARM_DATE, formattedDate],
		queryFn: () => getMainDay({ nowDate: formattedDate }),
		select: (data) => data?.data,
		staleTime: 1000 * 60 * 5, // 5분
		cacheTime: 1000 * 60 * 30, // 30분
	});

	const handleDate = (date) => {
		setCurrentDate(date);
	};

	return { data, currentDate, handleDate };
};

export default useAlarmCalenderDate;
