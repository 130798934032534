import useOrderButtonActions from "src/hooks/order/useOrderButtonActions";
import { isLocalEnv } from "src/utils/linmeSession";
import { makeButtonByOrderStatus } from "src/utils/linmeOrder";

const textInfo = require("src/data/textInfo.json");

/**
 * 주문 상태 버튼 컴포넌트
 */
const OrderStatusButton = (props) => {
	const { item, order, refetchOrderData } = props;
	// 주문 상태 버튼 컴포넌트에서 사용할 함수 훅
	const { setHandlerForButton, handlerClickChangeStatusDeliveryComplete } = useOrderButtonActions({
		refetchOrderData,
	});

	/**
	 * 상품의 주문상태에 따른 버튼 설정
	 */
	const setButtonByOrderStatus = (item, order) => {
		const buttonList = makeButtonByOrderStatus(item, order);

		return buttonList.map((buttonType) => {
			const handleClick = setHandlerForButton(buttonType);

			return (
				<li
					className={`button clickable ${buttonType}`}
					key={buttonType}
					onClick={(e) => handleClick(e, item, order)}
				>
					{textInfo.button.shopping[buttonType]}
				</li>
			);
		});
	};

	return (
		<ul className="buttonCover">
			{isLocalEnv() && ["PAY_FIN", "DLVY_READY", "PRD_READY"].includes(item.status) && (
				<li
					className="button clickable changeStatusDeliveryComplete"
					style={{ color: "#0000ff" }}
					// 개발용
					onClick={(e) => handlerClickChangeStatusDeliveryComplete(e, item, order)}
				>
					배송완료로 변경
				</li>
			)}
			{setButtonByOrderStatus(item, order)}
		</ul>
	);
};

export default OrderStatusButton;
