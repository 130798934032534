import { useQuery } from "@tanstack/react-query";
import { getIssueList } from "src/api/product/issue";
import { queryKeys } from "src/data";

/** 이슈 목록 쿼리 훅*/
const useIssueList = ({ typeNo = 1, ...props }) => {
	return useQuery({
		queryKey: [queryKeys.ISSUE_LIST, typeNo],
		queryFn: () => getIssueList({ typeNo }),
		...props,
	});
};

export default useIssueList;
