/**
 * 마이린미 메인 - 건강 조합 추천 상품
 */

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { API_RESULT_STATUS } from "src/data/constEnum";

import { getMainProduct } from "src/api/myLinme/main";
import ProductList from "src/pages/component/product/productList";
const pathList = require("src/path/pathJSON.json");
const textInfo = require("src/data/textInfo.json");
import "src/styles/myLinme/recommendedProduct.scss";

const RecommendedProductList = (props) => {
	const [list, setList] = useState([]);

	/**
	 * 일별 섭취 알람 목록 가져오기 API
	 * Simple 건강 체크를 했다면 Simple 상품 추천
	 * Mbti 까지 했다면 Mbti 상품 추천
	 */
	const _getMainProduct = async () => {
		try {
			const result = await getMainProduct();

			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				setList(result?.data);
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	useEffect(() => {
		_getMainProduct();
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="recommendedList">
			<div className="title">{textInfo.myLinme.recommendedProduct.title}</div>
			<div className="subTitleContainer">
				<div className="subTitle">{textInfo.myLinme.recommendedProduct.subTitle}</div>
				<Link to={pathList.myLinme.recommendedProductList}>
					<div className="recommendedListMore">{textInfo.myLinme.recommendedProduct.more}</div>
				</Link>
			</div>
			<div className={"recommendedProductList " + (!list || list.length < 1 ? "noData" : "")}>
				<ProductList {...{ items: list, limit: 5 }} />
			</div>
		</div>
	);
};
export default RecommendedProductList;
