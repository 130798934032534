import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import App from "src/App";
import queryClient from "src/api/queryClient";
import reportWebVitals from "src/reportWebVitals";
import AuthProvider from "src/context/authProvider";
import { isLocalEnv } from "src/utils/linmeSession";

import "src/utils/linmeLog";

const root = ReactDOM.createRoot(document.getElementById("root"));

/*
  <React.StrictMode>
	<AuthProvider>
		<ModalStackProvider>
			<App />
		</ModalStackProvider>
	</AuthProvider>
  </React.StrictMode>
*/
root.render(
	<QueryClientProvider client={queryClient}>
		<AuthProvider>
			<App />
			{isLocalEnv() && <ReactQueryDevtools initialIsOpen={false} />}
		</AuthProvider>
	</QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
