import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/**
 * 배너 목록
 * @param {Object} d
 * <code>
 * {
 * 		*clientType: ""//APP, WEB;
 * 		, *type: "" //HOME, MYPAGE, PRODUCT1, PRODUCT2;
 * }
 * </code>
 */
export const getBannerList = async (d) => {
	const url = `${API_PATHS.BANNER_LIST}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 배너 노출시 호출 api
 * @param {*} itemNo
 * @returns
 */
export const requestViewBanner = async (itemNo) => {
	const url = `${API_PATHS.VIEW_BANNER}`;
	try {
		const result = await api.post(url.replace(":itemNo", itemNo));
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
/**
 * 배너 클릭시 호출 api
 * @param {*} itemNo
 * @returns
 */
export const requestClickBanner = async (itemNo) => {
	const url = `${API_PATHS.CLICK_BANNER}`;
	try {
		const result = await api.post(url.replace(":itemNo", itemNo));
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
