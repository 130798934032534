/**
 * 회원 알림 설정
 */

import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/**
 * 읽지 않은 알림 존재 여부 조회
 * @returns
 */
export const checkMemberNotificationUnread = async () => {
	const url = `${API_PATHS.MEMBER_NOTIFICATION_UNREAD}`;
	try {
		const result = await api.get(url);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 알림 목록 조회
 * @param {Object} d
 * <code>
 * {
 * 		offset: 0
 * 		, page: 0
 * }
 * </code>
 * @returns
 */
export const getMemberNotificationList = async (d) => {
	const url = `${API_PATHS.MEMBER_NOTIFICATION}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 알림 설정 조회 ( 약관 동의 정보 )
 * @returns
 */
export const getMemberNotificationSetting = async () => {
	const url = `${API_PATHS.MEMBER_NOTIFICATION_SETTING}`;
	try {
		const result = await api.get(url);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 읽지 않은 알림 1건에 대해 읽음 처리
 * @param {Number} notificationNo
 */
export const setMemberNotificationReadEach = async (notificationNo) => {
	const d = {
		notificationNoList: [notificationNo],
	};
	const url = `${API_PATHS.MEMBER_NOTIFICATION_READ}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 읽지 않은 모든 알림에 대해 읽음 처리
 */
export const setMemberNotificationReadAll = async () => {
	const d = {
		notificationNoList: [-1],
	};
	const url = `${API_PATHS.MEMBER_NOTIFICATION_READ}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 알림 설정 수정 ( 약관 동의 정보 )
 * @param {Object} d
 * <code>
 * {
 * 		isCheckAdEmail: false
 * 		, isCheckAdPush: false
 * 		, isCheckAdSms: false
 * 		, isCheckInfoPush: false
 * 		, isCheckNightPush: false
 * }
 * </code>
 * @returns
 */
export const setMemberNotificationSetting = async (d) => {
	const url = `${API_PATHS.MEMBER_NOTIFICATION_SETTING}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
