/**
 * 마이린미 - 섭취 알람 관리 목록
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import moment from "moment";
import { useEffect, useRef, useState } from "react";

import useModalStack from "src/hooks/useModalStack";
import useInfiniteScroll from "src/hooks/useInfiniteScroll";

import { removeInnerSpinner, setInnerSpinner } from "src/utils/linmeDisplay";
import { actionAlarm, deleteAlarm, getAlarmList, onOffAlarm } from "src/api/myLinme/alarm";
import { getTakeCycle, getTakePercent } from "src/utils/linmeAlarm";
import ModalCommonAlert from "src/pages/common/modalAlert";
import Image from "src/pages/component/common/image";
import Toggle from "src/pages/component/toggle";
import InfiniteTrigger from "src/pages/component/infiniteTrigger";
import MyLinmeAlarmCreateModify from "src/pages/myLinme/alarm/modal/alarmCreateModify";
import SelectProductModal from "src/pages/myLinme/alarm/modal/selectProduct";
import AlarmComplete from "src/pages/myLinme/alarm/modal/alarmComplete";
import { API_RESULT_STATUS, COLOR, PAGINATION_DEFAULT_OFFSET } from "src/data/constEnum";

const textInfo = require("src/data/textInfo.json");

const AlarmList = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const { reloadData } = props;

	const [alarmData, setAlarmData] = useState([]);

	const modalStack = useModalStack();
	const ref = { list: useRef(null) };
	const infiniteScroll = useInfiniteScroll({
		fetchFn: getAlarmList,
		param: { offset: PAGINATION_DEFAULT_OFFSET.ALARM_LIST },
		setDataList: setAlarmData,
		ref: ref.list,
	});

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 알람 추가 클릭 시 상품 선택 모달
	 * @param {Event} e
	 */
	const handlerClickOpenModalAlarmNew = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.addModal({
			id: "modalProductSelect",
			type: "confirm",
			component: (
				<SelectProductModal
					handlerAfterCreate={handlerAfterCreate}
					handlerAfterModify={handlerAfterModify}
				/>
			),
			button: {
				iconClose: false,
			},
		});
	};

	/**
	 * 알람 수정 클릭 시 섭취 알람 수정 모달
	 * @param {Event} e
	 */
	const handlerClickOpenModalAlarmModify = (e, item) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.addModal({
			id: "modalAlarmModify",
			type: "confirm",
			component: (
				<MyLinmeAlarmCreateModify
					handlerAfterCreate={handlerAfterCreate}
					handlerAfterModify={handlerAfterModify}
				/>
			),
			button: {
				iconClose: false,
			},
			productItem: item,
		});
	};

	/**
	 * 알람 삭제 클릭 시 알람 삭제 모달
	 * @param {Event} e
	 */
	const handleDeleteAlarm = async (e, item) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await modalStack.addModal({
			id: "modalCommonConfirm",
			type: "confirm",
			component: <ModalCommonAlert />,
			html: `<div style="line-height: 2; margin: 10px 0 10px; max-width: 450px;"><div style="color: ${COLOR.LINME};">${item?.productName}</div>${textInfo.alert.areYouWantToDeleteProduct}</div>`,
			button: {
				iconClose: false,
				confirm: {
					text: textInfo.button.confirm,
					fn: () => _deleteAlarm(item),
					style: {
						width: "120px",
						border: "1px solid " + COLOR.LINME,
						marginLeft: "10px",
					},
				},
				cancel: {
					text: textInfo.button.cancel,
					style: {
						width: "120px",
					},
				},
			},
		});
	};

	/**
	 * 알람 건너뛰기 / 섭취완료 클릭 시
	 * @param {Event} e
	 */
	const handleActionAlarm = async (item, action) => {
		if (item?.actionEnabledFlagCd !== true) return;

		let htmlContent = "";

		if (action) {
			htmlContent = `<div style="line-height: 1.4; margin: 20px 0;"><div style="color: ${COLOR.LINME};">${item?.productName}</div>${textInfo.myLinme.alarm.actionModal}</div>`;
		} else {
			htmlContent = `<div style="line-height: 1.4; margin: 20px 0;">${textInfo.myLinme.alarm.skipModal}<br/>${textInfo.myLinme.alarm.skipModal2}</div>`;
		}

		await modalStack.addModal({
			id: "modalCommonConfirm1",
			type: "confirm",
			component: <ModalCommonAlert />,
			html: htmlContent,
			button: {
				iconClose: false,
				confirm: {
					text: textInfo.button.confirm,
					fn: () => _actionAlarm(item, action),
					style: {
						width: "100%",
						border: "1px solid " + COLOR.LINME,
						marginLeft: "10px",
						backgroundColor: COLOR.LINME,
						color: COLOR.WHITE,
					},
				},
				cancel: {
					text: textInfo.button.cancel,
					style: {
						width: "100%",
						backgroundColor: COLOR.GRAY07,
						color: COLOR.GRAY01,
						border: 0,
					},
				},
			},
		});
	};

	/**
	 * 알람 건너뛰기 / 섭취완료 완료 시
	 */
	const handleActionAlarmFinish = async () => {
		modalStack.addModal({
			id: "modalCommonAlert1",
			type: "alert",
			component: <ModalCommonAlert />,
			html: `<div style="line-height: 2; margin: 50px 0 10px;">${textInfo.myLinme.alarm.actionModalResult1}<br/>${textInfo.myLinme.alarm.actionModalResult2}</div>`,
			button: {
				iconClose: false,
				ok: {
					text: textInfo.button.complete,
					style: {
						width: "280px",
						border: "1px solid " + COLOR.LINME,
						backgroundColor: COLOR.LINME,
						color: COLOR.WHITE,
					},
				},
			},
		});
	};

	/**
	 * 알람 건너뛰기 / 섭취완료 최종 완료 시
	 */
	const handleActionAlarmFinalFinish = async (result) => {
		modalStack.addModal({
			id: "modalAlarmComplete",
			type: "confirm",
			component: <AlarmComplete />,
			data: result?.data,
			button: {
				iconClose: false,
			},
		});
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 알람 ON / OFF
	 * @param {Object} d
	 * <code>
		{
		alarmNo = 1
		onOff = true
		}
	* </code>
	* @returns
	*/
	const _onOffAlarm = async (item) => {
		await setInnerSpinner();

		const d = {
			alarmNo: item?.alarmNo,
			onOff: !item?.activeFlagCd,
		};

		try {
			const result = await onOffAlarm(d);
			if (result?.status?.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				const refetchPage = infiniteScroll.getPageNumber(alarmData, "alarmNo", item?.alarmNo);
				await infiniteScroll.refetch(refetchPage);
				reloadData();
			} else {
				await window.linmeLog.log(result);
			}
		} catch (error) {
			await window.linmeLog.log(error);
		} finally {
			await removeInnerSpinner();
		}
	};

	/**
	 * 알람 건너뛰기 / 섭취완료
	 * @param {Object} d
	 * <code>
		{
		alarmNo = 1
		action = true
		}
	* </code>
	* @returns
	*/
	const _actionAlarm = async (item, action) => {
		await setInnerSpinner();

		// action true: 섭취, false: 건너뛰기;
		const request = {
			alarmNo: item?.alarmNo,
			action: action,
			time: item?.timeForNow,
		};
		await modalStack.removeModal("modalCommonConfirm1");
		try {
			const result = await actionAlarm(request);
			if (result?.status?.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				const refetchPage = infiniteScroll.getPageNumber(alarmData, "alarmNo", item?.alarmNo);
				await infiniteScroll.refetch(refetchPage);
				await reloadData();
				if (action) {
					if (result?.data?.timeForNow) {
						// 섭취완료 모달;
						await handleActionAlarmFinish();
					} else {
						// 섭취 최종완료 모달
						handleActionAlarmFinalFinish(result);
					}
				}
			} else {
				await window.linmeLog.log(result);
			}
		} catch (error) {
			await window.linmeLog.log(error);
		} finally {
			await removeInnerSpinner();
		}
	};

	/**
	 * 알람 삭제
	 */
	const _deleteAlarm = async (item) => {
		await setInnerSpinner();

		const d = {
			alarmNos: [item?.alarmNo],
		};

		try {
			const result = await deleteAlarm(d);
			if (result?.status?.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				setAlarmData(alarmData.filter((alarm) => alarm.alarmNo !== item?.alarmNo));
				reloadData();
				await modalStack.removeModal("modalCommonConfirm");
			} else {
				await window.linmeLog.log(result);
			}
		} catch (error) {
			await window.linmeLog.log(error);
		} finally {
			await removeInnerSpinner();
		}
	};

	// 알람 등록 후 재조회
	const handlerAfterCreate = () => {
		infiniteScroll.setInitData();
		reloadData();
	};

	// 알람 수정 후 재조회
	const handlerAfterModify = async (d) => {
		if (d?.alarmNo) {
			setAlarmData((prev) =>
				prev.map((item) => (item.alarmNo === d.alarmNo ? { ...item, ...d } : item))
			);
		}
		const refetchPage = infiniteScroll.getPageNumber(alarmData, "alarmNo", d?.alarmNo);
		await infiniteScroll.refetch(refetchPage);
		await reloadData();
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		infiniteScroll.setInitData();
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	/*/
	1 영양제당 1 카드.
	현재 시간 기준,
	앞으로 먹을 영양제순으로 출력. ( 시각은 가장 가까운 시각만 출력. )
	선택한 일자의 "앞으로 먹을" 영양제는 건너뛰기 on, 지난 영양제는 섭취완료 on.
	상품 등록 시 cycle과 아침/점심/저녁 존재.
	공휴일은 체크된 경우 "Y". ( 토/일 포함. )
	2024.03.20 현재 일요일만 제외하는 경우는 고려하지 않음.
	//*/

	return (
		<div className="manageIntakeListCover">
			<div className="title">{textInfo.myLinme.alarm.intakeList.title}</div>
			<div className="addAlarmContainer">
				<div className="addAlarm" onClick={(e) => handlerClickOpenModalAlarmNew(e)}>
					{textInfo.myLinme.alarm.intakeList.add}
					<span className="addAlarmIcon" />
				</div>
			</div>
			<ul className="manageIntakeList" ref={ref.list}>
				{alarmData && alarmData?.length > 0 ? (
					<>
						{alarmData.map((item, index) => (
							<li
								className={`manageIntakeItem ${item?.remainQty > 1 ? "" : "finished"}`}
								key={index}
							>
								<div className="buttonContainer">
									<div
										className="button edit"
										onClick={(e) => handlerClickOpenModalAlarmModify(e, item)}
									></div>
									<div className="button delete" onClick={(e) => handleDeleteAlarm(e, item)}></div>
								</div>
								<div className="productInfoContainer">
									<div className="productImageCover">
										<Image src={item?.productImagePath} alt="" />
									</div>
									<div className="takeInfo">
										<div className="productName">{item.productName}</div>
										<div className="takeTimeInfoContainer">
											<div className="takeTime">
												<span>{item?.timeForNow}</span>
												<span>{getTakeCycle(item)}</span>
											</div>
											<div className="excludingHolidays">
												{item.holidayFlagCd === true
													? textInfo.myLinme.alarm.intakeList.holidayFlag
													: ""}
											</div>
										</div>
									</div>
									<div className="setting">
										<Toggle
											str1=""
											str2=""
											styles={{
												container: {
													width: "40px",
													height: "20px",
													boxShadow: "unset",
													backgroundColor: COLOR.GRAY02,
													checkedBackgroundColor: COLOR.LINME,
												},
												circle: {
													width: "15px",
													height: "15px",
													top: "3px",
													left: "5px",
													backgroundColor: COLOR.WHITE,
													checkedRight: "3px",
													checkedBackgroundColor: COLOR.WHITE,
												},
												label: null,
											}}
											isOn={item.activeFlagCd}
											afterToggle={() => _onOffAlarm(item)}
										/>
									</div>
								</div>
								<div
									className="takeRange"
									style={{
										background: `linear-gradient(to right, ${COLOR.LINME} 0%, ${
											COLOR.LINME
										} ${getTakePercent(item)}%, ${COLOR.PINK01} ${getTakePercent(item)}%, ${
											COLOR.PINK01
										} 100%)`,
									}}
								/>
								<div className="takeDateQuantityContainer">
									<div className="startTakeDate">
										{moment(item.startDate).format("MM월 DD일")}{" "}
										{textInfo.myLinme.alarm.intakeList.start}
									</div>
									<div className="leftQuantity">
										{item.remainQty}
										{item.unit} {textInfo.myLinme.alarm.intakeList.remain}
									</div>
								</div>

								<div className="skipCompleteContainer">
									<div
										className={`button skip ${item.actionEnabledFlagCd === true ? "on" : ""}`}
										onClick={() => {
											handleActionAlarm(item, false);
										}}
									>
										{textInfo.myLinme.alarm.intakeList.skip}
									</div>
									<div
										className={`button complete ${item.actionEnabledFlagCd === true ? "on" : ""}`}
										onClick={() => {
											handleActionAlarm(item, true);
										}}
									>
										{textInfo.myLinme.alarm.intakeList.complete}
									</div>
								</div>
							</li>
						))}
						{!infiniteScroll.isLastPage && <InfiniteTrigger ref={infiniteScroll.triggerRef} />}
					</>
				) : (
					<li className="noData"></li>
				)}
			</ul>
		</div>
	);
};

export default AlarmList;
