import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/**
 * 장바구니 담기
 * @param {Object} d
 * <code>
 * {
 * 		additional: [
 * 			{
 * 				optionCategoryNo: 0
 * 				, optionNo: 0
 * 				, quantity: 1
 * 			}
 * 		]
 * 		, required: [
 * 			{
 * 				optionCategoryNo: 0
 * 				, optionNo: 0
 * 				, quantity: 1
 * 			}
 * 		]
 * 		, productNo: 0
 * }
 * </code>
 */
export const addToCart = async (d) => {
	const url = `${API_PATHS.CART}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 장바구니 상품 목록 - 페이징 없음
 */
export const cartProductList = async () => {
	const url = `${API_PATHS.CART}`;
	try {
		const result = await api.get(url);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 장바구니 상품 전체 삭제
 */
export const deleteAllProductFromCart = async () => {
	const d = {
		productNoList: [-1],
	};
	const url = `${API_PATHS.CART_PRODUCT}`;
	try {
		const result = await api.delete(url, { data: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 장바구니 상품 선택 삭제
 * @param {Object} d
 * <code>
 * {
 * 		productNo: [ 0, 0, ... ]
 * }
 * </code>
 */
export const deleteProductFromCart = async (d) => {
	const url = `${API_PATHS.CART_PRODUCT}`;
	try {
		const result = await api.delete(url, { data: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 장바구니 옵션 선택 삭제
 * @param {Number} cartNo
 * @returns
 */
export const deleteOptionFromCart = async (cartNo) => {
	const url = `${API_PATHS.CART}`;
	try {
		const result = await api.delete(url, { data: { cartNo } });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 장바구니 상품 목록 중 선택한 상품에 대한 금액 조회
 * @param {Object} d
 * <code>
 * {
 * 		cartNoList: []
 * }
 * </code>
 * @returns
 */
export const getSelectedProductPriceFromCart = async (d) => {
	const url = `${API_PATHS.CART_PRICE}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 상품 수량 변경
 * @param {Object} d
 * <code>
 * {
 * 		cartNo: 0
 * 		, quantity: 0
 * }
 * </code>
 */
export const setProductQuantityFromCart = async (d) => {
	const url = `${API_PATHS.CART}`;
	try {
		const result = await api.put(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
