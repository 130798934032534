import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

const FormData = require("form-data");

/**
 * 파일 삭제
 * @param {Object} d
 * <code>
 * {
 * 		fileNoList: []
 * }
 * </code>
 */
export const deleteFile = async (d) => {
	const url = `${API_PATHS.FILE_DELETE}`;
	try {
		const result = await api.delete(url, { data: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 파일 업로드
 * @param {Object} d
 * <code>
 * {
 * 		files: []
 * 		, type: ""//파일 유형(PRODUCT:상품, REVIEW:리뷰, INQUIRY:문의내역, MEMBER:회원, BANNER:배너);
 * }
 * </code>
 */
export const uploadFile = async (d) => {
	const url = `${API_PATHS.FILE_UPLOAD}`;
	const config = { headers: { "Content-type": "multipart/form-data" } };
	const formData = new FormData();
	formData.append("type", d.type);

	for (let i = 0; i < d.files.length; ++i) {
		formData.append("files[]", d.files[i]);
	}

	try {
		const result = await api.post(url, formData, config);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
