import useQueryParams from "src/hooks/useQueryParams";
import { PRODUCT_LIST_ORDER } from "src/data/constEnum";

/**
 * - 사용자가 선택한 정렬 기준에 따라 쿼리 파라미터를 업데이트.
 */
const ProductSortList = () => {
	const query = useQueryParams();
	// 현재 쿼리 파라미터에서 'sort' 값을 가져오거나, 기본 정렬 값을 설정
	const sort = query.get("sort") || PRODUCT_LIST_ORDER[0].value;

	/**
	 * 정렬 옵션 클릭 시 호출되는 핸들러
	 * - 선택된 정렬 기준을 쿼리 파라미터에 설정.
	 *
	 * @param {string} value - 선택된 정렬 기준의 값
	 */
	const handleClickSort = (value) => {
		query.set("sort", value);
	};

	return (
		<ul className="productDisplayOrder">
			{PRODUCT_LIST_ORDER.map((sortItem) => (
				<li
					onClick={() => handleClickSort(sortItem.value)}
					className={sort === sortItem.value ? "on" : ""}
					key={sortItem.value}
				>
					<span className="clickable" data-value={sortItem.value}>
						{sortItem.label}
					</span>
				</li>
			))}
		</ul>
	);
};

export default ProductSortList;
