import styles from "src/styles/product/healthIssueRecommendList.module.scss";
import { FreeMode } from "swiper/modules";
import Swiper from "../swiper";
import { SwiperSlide } from "swiper/react";
import ProductItem from "./productItem";
import RightArrowButton from "../rightArrowButton";
import { Link } from "react-router-dom";
const textInfo = require("src/data/textInfo.json");
const pathList = require("src/path/pathJSON.json");

const HealthIssueRecommendList = ({ productList, issue }) => {
	const limitProductList = productList?.slice(0, 6);

	return (
		<div className={styles.recommendListContainer}>
			<div className={styles.headerContainer}>
				<div className={styles.titleContainer}>
					<div className={styles.title}>{textInfo.healthIssue.resultTitle}</div>
					<div className={styles.subtitle}>#{issue?.name}</div>
				</div>
				<Link to={pathList.product.healthIssueRecommended + `?issueNo=${issue?.issueNo}`}>
					<RightArrowButton text={textInfo.common.showAll} />
				</Link>
			</div>
			<div className={styles.productListContainer}>
				{limitProductList.length > 0 ? (
					<Swiper
						modules={[FreeMode]}
						slidesPerView={3.2}
						freeMode={true}
						spaceBetween={10}
						grabCursor={true}
					>
						{limitProductList?.map((product) => {
							return (
								<SwiperSlide key={product.productNo}>
									<ProductItem product={product} />
								</SwiperSlide>
							);
						})}
					</Swiper>
				) : (
					<div className={styles.noData}>상품이 추가될 예정입니다.</div>
				)}
			</div>
		</div>
	);
};

export default HealthIssueRecommendList;
