import { COLOR, IMAGE_ERROR_SRC, Z_INDEX } from "src/data/constEnum";

// ----------------------------------------------------------------------------------------------------;
// IMAGE ERROR;
// ----------------------------------------------------------------------------------------------------;

/**
 * 이미지 불러오기 실패 시 대체 이미지 반영
 * @param {Event} e
 * @param {String} type //small, large;
 */
export const handlerErrorImage = (e, type) => {
	//버블링 막기;
	e.preventDefault();
	e.stopPropagation();

	const ct = e.currentTarget;
	ct.src = type ? IMAGE_ERROR_SRC[type] : IMAGE_ERROR_SRC.large;
};

// ----------------------------------------------------------------------------------------------------;
// SET BODY SCROLL;
// ----------------------------------------------------------------------------------------------------;

/**
 * 브라우저 전체 스크롤 설정
 * @param {Boolean} b
 */
export const setBodyScroll = async (b) => {
	if (b) {
		window.document.body.style.overflow = "unset";
	} else {
		window.document.body.style.overflow = "hidden";
	}
};

/**
 * 페이지 최상단으로 이동
 */
export const setScrollTop = () => {
	window.scrollTo(0, 0);
};

// ----------------------------------------------------------------------------------------------------;
// SPINNER;
// ----------------------------------------------------------------------------------------------------;

/**
 * 스피너 적용
 */
export const setSpinner = async () => {
	if (!window.document.querySelector("#spinner")) {
		const style = window.document.createElement("style");
		style.innerHTML = `
			#spinner {
				position: fixed;
				display: none;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				background-color: ${COLOR.WHITE};
				z-index: ${Z_INDEX.SPINNER};
			}
			#spinner.on {
				display: block;
			}
			#spinner::after {
				position: absolute;
				display: block;
				width: 127px;
				height: 133px;
				top: calc( 50% - 63px );
				left: calc( 50% - 66px );
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				background-image: image-set(
					url("/images/spinner.png") 1x,
					url("/images/spinner@2x.png") 2x,
					url("/images/spinner@3x.png") 3x
				);
				background-image: -webkit-image-set(
					url("/images/spinner.png") 1x,
					url("/images/spinner@2x.png") 2x,
					url("/images/spinner@3x.png") 3x
				);
				animation: rotate_image 2s linear infinite;
				transform-origin: 50% 50%;
				content: "";
			}
			@keyframes rotate_image{
				100% {
					transform: rotate(360deg);
				}
			}
		`;

		const head = window.document.querySelector("head");
		head.appendChild(style);
		const body = window.document.querySelector("body");
		const elNew = window.document.createElement("div");
		elNew.id = "spinner";
		elNew.classList.add("on");
		body.appendChild(elNew);
	} else {
		const el = window.document.querySelector("#spinner");
		el.classList.add("on");
	}
};

/**
 * 스피너 해제
 */
export const removeSpinner = async () => {
	const el = window.document.querySelector("#spinner");
	if (el) el.classList.remove("on");
};

/**
 * 투명도 배경 스피너 적용
 */
export const setInnerSpinner = async () => {
	if (!window.document.querySelector("#spinnerInner")) {
		const style = window.document.createElement("style");
		style.innerHTML = `
			#spinnerInner {
				position: fixed;
				display: none;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				background-color: rgba( 255, 255, 255, 0.6 );
				z-index: ${Z_INDEX.SPINNER};
			}
			#spinnerInner.on {
				display: block;
			}
			#spinnerInner::after {
				position: absolute;
				display: block;
				width: 127px;
				height: 133px;
				top: calc( 50% - 63px );
				left: calc( 50% - 66px );
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				background-image: image-set(
					url("/images/spinner.png") 1x,
					url("/images/spinner@2x.png") 2x,
					url("/images/spinner@3x.png") 3x
				);
				background-image: -webkit-image-set(
					url("/images/spinner.png") 1x,
					url("/images/spinner@2x.png") 2x,
					url("/images/spinner@3x.png") 3x
				);
				animation: rotate_image 2s linear infinite;
				transform-origin: 50% 50%;
				content: "";
			}
			@keyframes rotate_image{
				100% {
					transform: rotate(360deg);
				}
			}
		`;

		const head = window.document.querySelector("head");
		head.appendChild(style);
		const body = window.document.querySelector("body");
		const elNew = window.document.createElement("div");
		elNew.id = "spinnerInner";
		elNew.classList.add("on");
		body.appendChild(elNew);
	} else {
		const el = window.document.querySelector("#spinnerInner");
		el.classList.add("on");
	}
};

/**
 * 투명도 배경 스피너 해제
 */
export const removeInnerSpinner = async () => {
	const el = window.document.querySelector("#spinnerInner");
	if (el) el.classList.remove("on");
};

// ----------------------------------------------------------------------------------------------------;
// SET FOOTER;
// ----------------------------------------------------------------------------------------------------;

/**
 * footer 바닥 고정
 */
export const setFooterFixed = () => {
	const body = window.document.querySelector("body");
	body.style.height = "fit-content";
	const footer = window.document.querySelector("footer");
	if (footer) {
		footer.style.position = "fixed";
		footer.style.bottom = 0;
		footer.style.left = 0;
	}
};

/**
 * footer 바닥 고정 해제
 */
export const setFooterRelative = () => {
	const body = window.document.querySelector("body");
	body.style.height = "100%";
	const footer = window.document.querySelector("footer");
	if (footer) {
		footer.style.position = "relative";
		footer.style.bottom = "unset";
		footer.style.left = "unset";
	}
};
