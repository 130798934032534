/**
 * 마이린미 - 건강상태 상세설문/생활습관
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getSurveyAnswer, saveSurveyResult } from "src/api/myLinme/survey";
import { API_RESULT_STATUS } from "src/data/constEnum";
import Image from "src/pages/component/common/image";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myLinme/survey/survey.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const MyLinmeSurveyAnswer1 = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const navigate = useNavigate();

	const [page, setPage] = useState(1);
	const [questionData, setQuestionData] = useState([]);
	const [answerData, setAnswerData] = useState([]); // 상세설문
	const [answerTypeCd, setAnswerTypeCd] = useState(); // 상세설문 코드

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 설문 이전페이지로 이동
	 */
	const handlerClickPrev = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (page <= 1) {
			props.handlerClickPrev(e);
		} else {
			setPage(page - 1);
		}
	};

	/**
	 * 설문 다음페이지로 이동
	 */
	const handlerClickNext = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (!validation()) return;

		if (page >= questionData.length) {
			_saveSurveyResult();
		} else {
			setPage(page + 1);
		}
	};

	/**
	 * 답변 클릭 시 값이 있으면 수정 없으면 추가
	 */
	const handleSelectAnswer = (topicData, item) => {
		const d = {
			topicCd: topicData?.topicCd,
			surveyId: topicData?.surveyId,
			subCode: item?.subCode,
			score: item?.rsvVal2,
		};

		setAnswerData((prev) =>
			prev.some((el) => el.surveyId === d.surveyId)
				? prev.map((el) => (el.surveyId === d.surveyId ? d : el))
				: [...prev, d]
		);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 질문 목록 가져오기 API
	 */
	const _getSurveyAnswer = async () => {
		if (!props?.topicInfoSelectedData || props?.topicInfoSelectedData?.length < 1) return;

		try {
			const d = {
				subCode: props?.topicInfoSelectedData,
			};

			const result = await getSurveyAnswer(d);

			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				setQuestionData(result?.data?.topicAnswer);
				setAnswerTypeCd(result?.data?.answerTypeCd);
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	/**
	 * 설문 답변 저장 API
	 */
	const _saveSurveyResult = async () => {
		if (!answerTypeCd || answerData?.length < 1 || answerData?.length !== questionData?.length)
			return;

		try {
			const d = {
				answerTypeCd: answerTypeCd,
				surveyResult: answerData,
			};

			const result = await saveSurveyResult(d);

			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				navigate(pathList.myLinme.surveyResult);
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	/**
	 * 선택 여부 체크
	 */
	const getIsSelected = (topicData, item) => {
		return answerData.some(
			(el) => el.surveyId === topicData?.surveyId && el.subCode === item?.subCode
		);
	};

	/**
	 * validation
	 */
	const validation = () => {
		const surveyId = questionData[page - 1]?.topic?.surveyId;
		const foundObject = answerData.find((item) => item.surveyId === surveyId);
		if (!foundObject?.subCode) return false;
		return true;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		_getSurveyAnswer();
	}, [props?.topicInfoSelectedData]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="container underTopMenu myLinmeContainer survey">
			<div className="contents">
				<div className="surveyContainer">
					<div className="surveyContainerHeader">
						<div className="stepList">
							<div className="stepList">
								<div className="step">1</div>
								<div className="step">2</div>
								{questionData?.[page - 1]?.topic?.seq === 1 ? (
									<>
										<div className="selectedStep">{textInfo.myLinme.survey.step.third}</div>
										<div className="step">4</div>
									</>
								) : (
									<>
										<div className="step">3</div>
										<div className="selectedStep">{textInfo.myLinme.survey.step.fourth}</div>
									</>
								)}
							</div>
						</div>
						<div className="closeButton" onClick={props.handlerClickClose} />
					</div>

					<div className="defaultContainer surveyAnswer">
						<div className="titleContainer">
							<div className="issueImageContainer">
								<div className="issueIcon_6">
									<Image
										src={questionData?.[page - 1]?.topic?.rsvVal4}
										alt={questionData?.[page - 1]?.topic?.rsvVal1}
									/>
								</div>
								{questionData?.[page - 1]?.topic?.rsvVal3 && (
									<div className="issueDescVal3">{questionData?.[page - 1]?.topic?.rsvVal3}</div>
								)}
								<div className="issueDesc">{questionData?.[page - 1]?.topic?.rsvVal1}</div>
							</div>
							<div className="titleBubble">
								<div className="bubbleText">{questionData?.[page - 1]?.topic?.content}</div>
							</div>
						</div>

						<div className="surveyAnswerContainer_6">
							{questionData?.[page - 1]?.status?.map((item, idx) => {
								return (
									<div
										className={`surveyAnswer_6 ${getIsSelected(questionData?.[page - 1]?.topic, item) ? "on" : ""}`}
										key={idx}
										onClick={() => handleSelectAnswer(questionData?.[page - 1]?.topic, item)}
									>
										<div className="check" />
										<div className="value">{item?.rsvVal1}</div>
									</div>
								);
							})}
						</div>
					</div>
					<div className="buttonContainer">
						<div className="button prev" onClick={handlerClickPrev}>
							{textInfo.myLinme.survey.button.prev}
						</div>
						<div
							className={`button next ${validation() ? "" : "disable"}`}
							onClick={handlerClickNext}
						>
							{textInfo.myLinme.survey.button.next}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MyLinmeSurveyAnswer1;
