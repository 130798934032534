// 건강 고민 ( 기능식품 + 맞춤식품 )

import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { AppContext } from "src/App";

import { getIssueProductList } from "src/api/product/issue";
import useIssueList from "src/hooks/useIssueList";
import useDeviceType from "src/hooks/useDeviceType";

import ProductList from "src/pages/component/product/productList";
import HealthIssueSlider from "src/pages/main/healthIssueSlider";
import HealthIssueRecommendList from "src/pages/component/product/healthIssueRecommendList";
import { API_RESULT_STATUS, DEVICE_TYPE, PRODUCT_TYPE_NO } from "src/data";
const pathList = require("src/path/pathJSON.json");
const textInfo = require("src/data/textInfo.json");
import "src/styles/healthIssue.scss";

const HealthIssue = () => {
	const { mainSelectedMenuType } = useContext(AppContext);
	const { data: issueList } = useIssueList({
		typeNo: PRODUCT_TYPE_NO[mainSelectedMenuType],
		select: (data) => data?.data,
		staleTime: 1000 * 60 * 60,
	});

	const [recommendedProductList, setRecommendedProductList] = useState([]);
	const [selectedIssue, setSelectedIssue] = useState(null);
	const { mobileType } = useDeviceType();
	/**
	 * 선택한 고민에 해당하는 상품 목록 API
	 */
	const getProductList = async () => {
		if (!selectedIssue) {
			return setRecommendedProductList([]);
		}
		let d = {
			issueNoList: [selectedIssue.issueNo],
			page: 1,
			typeNo: PRODUCT_TYPE_NO[mainSelectedMenuType],
		};

		try {
			const result = await getIssueProductList(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				setRecommendedProductList(result?.data?.content || []);
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	/**
	 * 고민 클릭 시
	 * @param {object} issueData
	 */
	const handlerClickHealthIssue = (issueData) => {
		setSelectedIssue((prev) => (issueData === prev ? null : issueData));
	};

	useEffect(() => {
		getProductList();
	}, [selectedIssue]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	if (!issueList) return null;

	return (
		<div className="container healthIssue healthIssueEffect">
			<div className="contents">
				<div className="info">
					<div className="title">
						{mainSelectedMenuType === "effect"
							? textInfo.healthIssue.effectTitle
							: textInfo.healthIssue.ingredientsTitle}
					</div>
					<div className="subtitle">{textInfo.healthIssue.subtitle}</div>
					<HealthIssueSlider
						issueList={issueList}
						handlerClickHealthIssue={handlerClickHealthIssue}
						selectedHealthIssueNo={selectedIssue?.issueNo}
					/>
				</div>
			</div>
			{selectedIssue &&
				// PC
				([DEVICE_TYPE.PC].includes(mobileType) ? (
					<div className={clsx(["selectedHealthIssueCover", { on: !!selectedIssue }])}>
						<Link
							to={pathList.product.healthIssueRecommended + `?issueNo=${selectedIssue?.issueNo}`}
						>
							<div className="title">{textInfo.healthIssue.resultTitle}</div>
						</Link>
						{selectedIssue && <div className="description">{selectedIssue.name}</div>}
						<div className="recommendedProductsForMain">
							<div className="recommendedProductsContainer">
								{recommendedProductList.length > 0 ? (
									<ProductList items={recommendedProductList} limit={5} />
								) : (
									<div className="noData"></div>
								)}
							</div>
						</div>
					</div>
				) : (
					// MOBILE
					<HealthIssueRecommendList issue={selectedIssue} productList={recommendedProductList} />
				))}
		</div>
	);
};

export default HealthIssue;
