/**
 * 신상품/베스트/특가상품 상품 목록
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { AppContext } from "src/App";
import useAuth from "src/hooks/useAuth";
import {
	DEVICE_TYPE,
	PAGINATION_DEFAULT_OFFSET,
	PAGINATION_TYPE,
	PRODUCT_LIST_ORDER,
	PRODUCT_TYPE_NO,
} from "src/data/constEnum";
import { getBestProductList, getNewProductList, getSpecialProductList } from "src/api/product/main";

import ProductListPagination from "src/pages/component/product/productListPagination";
import ProductListInfinityScroll from "src/pages/component/product/productListInfinityScroll";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/product/mainProductDisplay.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

// const htmlInfo = require( "src/data/htmlInfo.json" );
// const textInfo = require( "src/data/textInfo.json" );

const MainProductDisplay = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const auth = useAuth();
	const { device, mainSelectedMenuType } = useContext(AppContext);

	const [resultTotalCount, setResultTotalCount] = useState(0);
	const [paginationType, setPaginationType] = useState(
		device === DEVICE_TYPE.PC ? PAGINATION_TYPE.PAGE : PAGINATION_TYPE.SCROLL
	);

	const location = useLocation();
	const [param, setParam] = useState({
		typeNo: PRODUCT_TYPE_NO[mainSelectedMenuType],
		offset: PAGINATION_DEFAULT_OFFSET.PRODUCT_LIST,
		page: 1,
		sort: PRODUCT_LIST_ORDER[0].value,
	});

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	//화면 resize 등;
	useEffect(() => {
		setPaginationType(device === DEVICE_TYPE.PC ? PAGINATION_TYPE.PAGE : PAGINATION_TYPE.SCROLL);
	}, [device]);

	useEffect(() => {
		setParam({ ...param, typeNo: PRODUCT_TYPE_NO[mainSelectedMenuType] });
	}, [mainSelectedMenuType]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="container underTopMenu mainProductDisplay">
			{/* <div className="contents">
				<div className="title">{auth.isLogin ? <span className="userInfo">'{auth.user.nickname}{textInfo.healthIssue.member}'</span> : ( textInfo.healthIssue.notLoginMember + textInfo.healthIssue.member ) }{textInfo.healthIssue.recommendedProductResult} <span className="resultCount"><span className="resultCountNumber">{resultTotalCount.toLocaleString()}</span>{textInfo.common.countUnit}</span></div>
				{selectedListName && selectedListName.length ? <div className="selectedHealthIssueNameList">
					{selectedListName.map(( item, index ) => ( <span key={index}>{item}</span> ))}
				</div> : <></> }
			</div>
			{ ( mainSelectedMenuType === "effect" ) ? //탑메뉴 토글에 따른 ( 기능식품 / 맞춤식품 ) 건강고민;
				<HealthIssueEffect {...{ foldable: true, selectedListNo, selectedListName, toggleAll, afterSelected: afterSelected }}/> :
				<HealthIssueIngredients {...{ foldable: true, selectedListNo, selectedListName, toggleAll, afterSelected: afterSelected }} />
			} */}
			<div className="contents">
				{paginationType === PAGINATION_TYPE.PAGE ? (
					<ProductListPagination
						getDataList={
							location.pathname === pathList.product.new
								? getNewProductList
								: location.pathname === pathList.product.best
								? getBestProductList
								: getSpecialProductList
						}
						setResultTotalCount={setResultTotalCount}
						param={param}
					/>
				) : (
					<ProductListInfinityScroll
						getDataList={
							location.pathname === pathList.product.new
								? getNewProductList
								: location.pathname === pathList.product.best
								? getBestProductList
								: getSpecialProductList
						}
						setResultTotalCount={setResultTotalCount}
						param={param}
					/>
				)}
			</div>
		</div>
	);
};

export default MainProductDisplay;
