/**
 * 마이페이지 - 환불계좌관리
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useRef, useState } from "react";

import useAuth from "src/hooks/useAuth";
import useModalStack from "src/hooks/useModalStack";
import { API_RESULT_STATUS, COLOR } from "src/data/constEnum";
import { getBankList, getRefundAccount, setRefundAccount } from "src/api/member/refundAccount";

import { removeInnerSpinner, setInnerSpinner } from "src/utils/linmeDisplay";
import CustomSelectBox from "src/pages/component/common/customSelectBox";
import ModalCommonAlert from "src/pages/common/modalAlert";
import AdditionalInformation from "../member/modal/additionalInformation";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myPage/refundAccount.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

const htmlInfo = require("src/data/htmlInfo.json");
const textInfo = require("src/data/textInfo.json");

const MyPageRefundAccount = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const auth = useAuth();
	const modalStack = useModalStack();
	const [bankInfo, setBankInfo] = useState({});
	const [bankList, setBankList] = useState([]);
	const [selectedBank, setSelectedBank] = useState({});

	const ref = {
		name: useRef(null),
		bankCover: useRef(null),
		refundAccount: useRef(null),
		button: useRef(null),
	};
	const modalId = {
		additionalInformation: "modalAdditionalInformation", //추가정보입력;
		refundAccountAddComplete: "modalCommonAlert", //환불계좌 등록 완료;
		refundAccountAddFail: "modalCommonAlert", //환불계좌 등록 실패;
		bankListCallFail: "modalCommonAlert", //은행 정보 호출 실패;
	};

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 입력 변경 시
	 * @param {Event} e
	 */
	const handlerChange = async (e) => {
		if (e) {
			//버블링 막기;
			e.preventDefault();
			e.stopPropagation();
		}

		const checkValidation = await validation();
		const button = ref.button.current;

		if (checkValidation === true) {
			button.classList.add("on");
			button.classList.add("clickable");
		} else {
			button.classList.remove("on");
			button.classList.remove("clickable");
		}
	};

	/**
	 * 환불계좌 등록/수정 클릭 시
	 * @param {Event} e
	 * @param {String} type
	 */
	const handlerClick = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const ct = e.currentTarget;
		if (ct.classList.contains("on")) {
			await requestSetRefundAccount();
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 은행 목록 만들기
	 * @param {Array} aList
	 */
	const makeBankList = (aList) => {
		if (aList && aList.length) {
			let a = [{ label: textInfo.myPage.myRefundAccount.pleaseSelectBank, value: "" }];
			let o = null;
			for (let i = 0; i < aList.length; ++i) {
				o = aList[i];
				a.push({ label: o.name, value: o.code });
			}
			setBankList(a);
		} else setBankList(null);
	};

	/**
	 * 은행 목록 조회
	 */
	const requestGetBankList = async () => {
		try {
			const result = await getBankList();
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				await makeBankList(result.data);
				return result.data;
			} else {
				window.linmeLog.log(result);
				await modalStack.addModal({
					id: modalId.bankListCallFail,
					type: "alert",
					component: <ModalCommonAlert />,
					html: htmlInfo.error.dataCall,
				});
				return false;
			}
		} catch (err) {
			window.linmeLog.error(err);
			await modalStack.addModal({
				id: modalId.bankListCallFail,
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.error.dataCall,
			});
			return false;
		}
	};

	/**
	 * 환불 계좌 조회
	 */
	const requestGetRefundAccount = async () => {
		try {
			const result = await getRefundAccount();
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (result.data) {
					await setBankInfo(result.data);
					await setSelectedBank({ value: result.data.code });
				}
				return result.data;
			} else {
				window.linmeLog.log(result);
				await modalStack.addModal({
					id: modalId.bankListCallFail,
					type: "alert",
					component: <ModalCommonAlert />,
					html: htmlInfo.error.dataCall,
				});
				return false;
			}
		} catch (err) {
			window.linmeLog.error(err);
			await modalStack.addModal({
				id: modalId.bankListCallFail,
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.error.dataCall,
			});
			return false;
		}
	};

	/**
	 * 환불 계좌 등록
	 * @returns
	 */
	const requestSetRefundAccount = async () => {
		try {
			setInnerSpinner();

			const d = {
				accountNo: ref.refundAccount.current.value,
				bankCode: selectedBank.value,
			};
			const result = await setRefundAccount(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				await modalStack.addModal({
					id: modalId.refundAccountAddComplete,
					type: "alert",
					title: textInfo.myPage.myRefundAccount.addComplete,
					component: <ModalCommonAlert />,
					text: textInfo.alert.addedRefundAccount,
					button: {
						ok: {
							text: textInfo.button.complete,
							style: { color: COLOR.WHITE, backgroundColor: COLOR.LINME, marginBottom: "17px" },
						},
					},
				});

				return result.data;
			} else {
				window.linmeLog.log(result);
				await modalStack.addModal({
					id: modalId.refundAccountAddFail,
					type: "alert",
					component: <ModalCommonAlert />,
					html: htmlInfo.error.refundAccount,
				});
				ref.refundAccount.current.value = bankInfo?.refundAccount || "";
				ref.button.current.classList.remove("on");
				ref.button.current.classList.remove("clickable");
				return false;
			}
		} catch (err) {
			window.linmeLog.error(err);
			await modalStack.addModal({
				id: modalId.refundAccountAddFail,
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.error.refundAccount,
			});
			ref.refundAccount.current.value = bankInfo?.refundAccount || "";
			ref.button.current.classList.remove("on");
			ref.button.current.classList.remove("clickable");
			return false;
		} finally {
			removeInnerSpinner();
		}
	};

	/**
	 * 사용자 입력 validation
	 * @returns {Boolean}
	 */
	const validation = async () => {
		if (validationRefundAccount() && !!selectedBank.value) {
			return true;
		}
		return false;
	};

	/**
	 * 계좌번호 validation
	 * @returns {Boolean}
	 */
	const validationRefundAccount = () => {
		const ct = ref.refundAccount.current;
		const elUserInput = ct.closest(".data");
		const elGuideWarn = elUserInput.querySelector(".inputGuideWarn");
		if (!ct.value) {
			elGuideWarn.innerText = textInfo.warn.required;
			return false;
		}

		elGuideWarn.innerText = "";
		return true;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		const getData = async () => {
			const loginCheck = await auth.loginCheck();
			if (loginCheck) {
				await requestGetRefundAccount();
				await requestGetBankList();

				if (auth && auth.user && !auth.user.isBaseInfo) {
					await modalStack.addModal({
						id: modalId.additionalInformation,
						type: "alert",
						component: <AdditionalInformation />,
					});
				}
			}
		};
		getData();
	}, []);

	useEffect(() => {
		handlerChange();
	}, [selectedBank]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	// render() {
	return (
		<>
			<div className="title">{textInfo.myPage.myRefundAccount.title}</div>
			<div className="refundAccountCover">
				<ul className="refundAccountInfoList">
					{/* <li className="refundAccountInfoItem">
							<div className="label">{textInfo.myPage.myRefundAccount.name}</div>
							<div className="data">
								<input type="text"
									defaultValue={(bankInfo && bankInfo.name) ? bankInfo.name : ""}
									ref={ref.name}
									onChange={(e)=>handlerChange(e)} />
								<div className="guide">
									<div className="inputGuideWarn"></div>
								</div>
							</div>
						</li> */}
					<li className="refundAccountInfoItem">
						<div className="label">{textInfo.myPage.myRefundAccount.bank}</div>
						<div className="inputContainer">
							{bankList && bankList.length !== 0 && (
								<CustomSelectBox
									options={bankList}
									value={selectedBank?.value}
									placeholder={textInfo.myPage.myRefundAccount.pleaseSelectBank}
									onChange={(item) => setSelectedBank(item)}
								/>
							)}
							{!selectedBank?.value && (
								<div className="data bankListCover" ref={ref.bankCover}>
									<div className="guide">
										<div className="inputGuideWarn">{textInfo.warn.required}</div>
									</div>
								</div>
							)}
						</div>
					</li>
					<li className="refundAccountInfoItem">
						<div className="label">{textInfo.myPage.myRefundAccount.account}</div>
						<div className="data">
							<input
								type="text"
								defaultValue={bankInfo && bankInfo.refundAccount ? bankInfo.refundAccount : ""}
								ref={ref.refundAccount}
								placeholder={textInfo.myPage.myRefundAccount.pleaseEnterAccount}
								onChange={(e) => handlerChange(e)}
							/>
							<div className="guide">
								<div className="inputGuideWarn"></div>
							</div>
						</div>
					</li>
					<li className="refundAccountInfoItem">
						<div
							className={`button applyRefundAccount`}
							onClick={(e) => handlerClick(e)}
							ref={ref.button}
						>
							{textInfo.button.applyRefundAccount}
						</div>
					</li>
				</ul>

				<div className="information">
					<ul className="guideList">
						<li className="guideItem">{textInfo.myPage.myRefundAccount.guide.title}</li>
						<li className="guideItem">{textInfo.myPage.myRefundAccount.guide.guide1}</li>
						<li className="guideItem emphasis">
							{/* {textInfo.myPage.myRefundAccount.guide.guideEmphasis} */}
							{textInfo.myPage.myRefundAccount.guide.guideEmphasisTemp1}
							<br />
							{textInfo.myPage.myRefundAccount.guide.guideEmphasisTemp2}
						</li>
						<li className="guideItem">{textInfo.myPage.myRefundAccount.guide.guide2}</li>
						<li className="guideItem">{textInfo.myPage.myRefundAccount.guide.guide2_detail1}</li>
						<li className="guideItem">{textInfo.myPage.myRefundAccount.guide.guide2_detail2}</li>
						<li className="guideItem">{textInfo.myPage.myRefundAccount.guide.guide2_detail3}</li>
					</ul>
				</div>
			</div>
		</>
	);
	// }
};

export default MyPageRefundAccount;
