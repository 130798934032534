import useQueryParams from "src/hooks/useQueryParams";
import clsx from "clsx";
import styles from "src/styles/categoryListMobileItem.module.scss";

/**
 * CategoryListMobileItem 컴포넌트
 * @param {Object} props.category - 표시할 개별 카테고리 객체
 * @param {Function} props.handlerClickToggle - 왼쪽 메뉴 토글 핸들러 함수
 * @param {boolean} props.isOpen - 카테고리가 현재 열려 있는지 여부를 나타내는 상태
 * @param {Function} props.toggleProductType - 카테고리 타입을 토글하는 핸들러 함수
 */
const CategoryListMobileItem = (props) => {
	const { category, handlerClickToggle, isOpen, toggleProductType } = props;
	const query = useQueryParams({ path: "/category", isDirectPush: true });

	/**
	 * Depth 2 카테고리를 클릭할 때 호출되는 핸들러
	 * - 선택된 카테고리의 제품 유형과 카테고리 번호를 쿼리 파라미터로 설정.
	 *
	 * @param {number} categoryNo - 클릭한 Depth 2 카테고리의 번호
	 */
	const handleClickDepth = (categoryNo) => {
		handlerClickToggle();
		query.setAll({ productType: category.categoryNo, categoryNo });
	};

	return (
		<div className={styles.categoryItemContainer}>
			<div className={styles.categoryItem} onClick={() => toggleProductType(category.categoryNo)}>
				{category.name}
				<span className={clsx([styles.arrow, isOpen && styles.on])} />
			</div>
			{isOpen && category.child && (
				<div className={styles.categoryChildContainer}>
					{category.child.map((child) => (
						<div
							key={child.categoryNo}
							onClick={() => handleClickDepth(child.categoryNo)}
							className={clsx([styles.categoryItem, styles.depth2])}
						>
							{child.name}
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default CategoryListMobileItem;
