import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "src/data";
import { getProductPhotoReviewList } from "src/api/product/detail";

/** 포토 리뷰 목록 조회 */
const useProductPhotoReviewList = (productNo) => {
	return useQuery({
		queryKey: [queryKeys.PRODUCT_REVIEW, productNo],
		queryFn: async () => {
			const req = {
				productNo,
				offset: 7,
				page: 1,
			};
			const result = await getProductPhotoReviewList(req);
			return result.data;
		},
	});
};

export default useProductPhotoReviewList;
