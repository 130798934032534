/**
 * 고정 메뉴 ( 최근 본 상품 / 최상단으로 등 )
 */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import useDeviceType from "src/hooks/useDeviceType";
import CategoryList from "src/pages/component/categoryList";
import RecentlyViewed from "src/pages/component/layout/fixedMenu/recentlyViewed";
import { setScrollTop } from "src/utils/linmeDisplay";
import { DEVICE_TYPE } from "src/data/constEnum";
const pathList = require("src/path/pathJSON.json");
import "src/styles/layout/fixedMenu.scss";

const FixedMenu = () => {
	const navigate = useNavigate();
	const auth = useAuth();
	const device = useDeviceType();
	const [isShowCategoryLayer, setIsShowCategoryLayer] = useState(false);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 최상단으로 올라가기
	 * @param {Event} e
	 */
	const handlerClickMoveToTop = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		setScrollTop();
	};

	/**
	 * 네비게이션 바 클릭 시
	 * @param {Event} e
	 */
	const handlerClickNavigation = async (key) => {
		switch (key) {
			case "category":
				setIsShowCategoryLayer(!isShowCategoryLayer);
				break;
			case "myLinme":
				navigate(pathList.myLinme.index, { replace: true });
				break;
			case "home":
				navigate(pathList.main, { replace: true });
				break;
			case "myPage":
				navigate(pathList.myPage.index, { replace: true });
				break;
			case "cart":
				navigate(pathList.cart, { replace: true });
				break;
			default:
				break;
		}
	};

	/**
	 * 카테고리 목록 박스에서 mouse leave
	 * @param {Event} e
	 */
	const handlerMouseOutCategory = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		setIsShowCategoryLayer(!isShowCategoryLayer);
	};

	/**
	 * 카테고리 클릭 시
	 * @param {Event} e
	 */
	const handlerClickCategory = (e) => {
		if (e) {
			//버블링 막기;
			e.preventDefault();
			e.stopPropagation();
		}

		setIsShowCategoryLayer(!isShowCategoryLayer);
	};

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<>
			{device.mobileType === DEVICE_TYPE.PC && <RecentlyViewed />}

			<div className="fixedMenu moveToTop" onClick={(e) => handlerClickMoveToTop(e)} />
			<div className="fixedMenu bottomMenuList">
				<div
					className="bottomMenuItem category"
					onClick={() => handlerClickNavigation("category")}
				/>
				<div className="bottomMenuItem myLinme" onClick={() => handlerClickNavigation("myLinme")} />
				<div className="bottomMenuItem home" onClick={() => handlerClickNavigation("home")} />
				<div className="bottomMenuItem cart" onClick={() => handlerClickNavigation("cart")}>
					{auth.isLogin && auth?.user?.cartCount > 0 && (
						<div className="cartCount">
							{auth?.user?.cartCount > 99 ? "99+" : auth?.user?.cartCount}
						</div>
					)}
				</div>
				<div className="bottomMenuItem myPage" onClick={() => handlerClickNavigation("myPage")} />
			</div>
			<CategoryList
				{...{
					isShow: isShowCategoryLayer,
					handlerMouseOut: handlerMouseOutCategory,
					handlerClickToggle: handlerClickCategory,
				}}
			/>
		</>
	);
};

export default FixedMenu;
