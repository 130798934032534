import React from "react";
const textInfo = require("src/data/textInfo.json");

const HealthTypeInfo = ({ information, onModify }) => {
	return (
		<>
			{information ? (
				<div className="healthTypeCardInfo">
					{information.memberInfo}
					<div className="healthTypeCardInfo icon" onClick={onModify} />
				</div>
			) : (
				<div className="healthTypeCardInfo noData">
					<div>{textInfo.myLinme.informationNoData}</div>
					<div className="healthTypeCardInfoSubtitle">{textInfo.myLinme.informationNoData2}</div>
				</div>
			)}
			{information?.info?.length > 0 && (
				<div className="healthTypeCardTag">
					{information.info.map((item, index) => (
						<div className="healthTypeCardItem" key={index}>
							#{item}
						</div>
					))}
				</div>
			)}
		</>
	);
};

export default HealthTypeInfo;
