import textInfo from "src/data/textInfo.json";

/**
 * 섭취알람 CYCLE
 */
export const ALARM_CYCLE = {
	0: "매일",
	1: "1일",
	2: "격일",
};

/**
 * 알람 설정 정보
 */
export const ALARM_SETTING = {
	isCheckAdEmail: "광고성 정보 이메일 수신 동의여부",
	isCheckAdPush: "광고성 푸시알림 동의여부",
	isCheckAdSms: "광고성 정보 SMS 수신 동의여부",
	isCheckInfoPush: "정보성 푸시알림 동의여부",
	isCheckNightPush: "야간혜택 푸시알림 동의여부",
};

/**
 * 알람 설정 정보 - 웹 show/hide 처리 정보
 */
export const ALARM_SETTING_SHOW_FOR_WEB = {
	isCheckAdEmail: true,
	isCheckAdPush: false,
	isCheckAdSms: true,
	isCheckInfoPush: false,
	isCheckNightPush: false,
};

/**
 * API 호출 결과 STATUS
 */
export const API_RESULT_STATUS = {
	SUCCESS: "SUCCESS",
	FAIL: "FAIL",
};

/**
 * 배너 종류
 */
export const BANNER_TYPE = {
	MAIN: "HOME",
	MYPAGE: "MYPAGE",
	PRODUCT1: "PRODUCT1",
	PRODUCT2: "PRODUCT2",
	HOME_SUB: "HOME_SUB",
};

/**
 * 장바구니 상태 (ALIVE:구매가능, ORDER:구매중, INVALID:구매완료)
 */
export const CART_STATUS = {
	ALIVE: "ALIVE",
	ORDER: "ORDER",
	INVALID: "INVALID",
};

/**
 * 글자수 제한
 */
export const CHARACTER_LIMIT = {
	INQUIRY: 300,
	REVIEW: 50,
};

/**
 * 클라이언트 타입
 */
export const CLIENT_TYPE = {
	APP: "APP",
	WEB_PC: "WEB_PC",
	WEB_MOBILE: "WEB_MOBILE",
};

/**
 * 화면 구분;
 */
export const CLIENT_SCREEN_WIDTH = {
	MOBILE: { MIN: 414, MAX: 767 },
	MOBILE_MINI: { MIN: 320, MAX: 413 },
	TABLET: { MIN: 768, MAX: 1199 },
	PC: { MIN: 1200, MAX: null },
};

/**
 * 취소/교환/환불 상태
 */
export const CLAIM_STATUS = {
	REFUND_REQ: { value: "REFUND_REQ", desc: "환불요청", name: "환불요청", type: "환불" },
	REFUND_IN: { value: "REFUND_IN", desc: "환불 진행중", name: "환불 진행중", type: "환불" },
	REFUND_FIN: { value: "REFUND_FIN", desc: "환불완료", name: "환불완료", type: "환불" },
	EXCH_REQ: { value: "EXCH_REQ", desc: "교환요청", name: "교환요청", type: "교환" },
	EXCH_IN: { value: "EXCH_IN", desc: "교환 진행중", name: "교환 진행중", type: "교환" },
	EXCH_RECALLED: { value: "EXCH_RECALLED", desc: "교환 회수", name: "교환 진행중", type: "교환" },
	EXCH_INSPECTED: { value: "EXCH_INSPECTED", desc: "교환 검수", name: "교환 진행중", type: "교환" },
	EXCH_SHIPPED: { value: "EXCH_SHIPPED", desc: "교환 발송", name: "교환 진행중", type: "교환" },
	EXCH_FIN: { value: "EXCH_FIN", desc: "교환완료", name: "교환완료", type: "교환" },
	CANCEL_REQ: { value: "CANCEL_REQ", desc: "결제취소요청", name: "결제취소요청", type: "취소" },
	CANCEL_FIN: { value: "CANCEL_FIN", desc: "결제취소완료", name: "결제취소완료", type: "취소" },
	REFUND_CANCEL: { value: "REFUND_CANCEL", desc: "환불 취소", name: "환불 취소", type: "환불" },
	EXCH_CANCEL: { value: "EXCH_CANCEL", desc: "교환 취소", name: "교환 취소", type: "교환" },
	REFUND_WAIT: {
		value: "REFUND_WAIT",
		desc: "환불대기",
		name: "환불대기",
		type: "환불",
	},
	EXCH_WAIT: {
		value: "EXCH_WAIT",
		desc: "교환대기",
		name: "교환대기",
		type: "교환",
	},
	REFUND_REFUSE: { value: "REFUND_REFUSE", desc: "환불거절", name: "환불거절", type: "환불" },
	EXCH_REFUSE: { value: "EXCH_REFUSE", desc: "교환거절", name: "교환거절", type: "교환" },
};

/**
 * 클레임 유형 ( 취소, 교환, 환불 )
 */
export const CLAIM_TYPE = {
	CANCEL: "CANCEL",
	CHANGE: "CHANGE",
	REFUND: "REFUND",
};
/** 교환 완료 가능 상태 **/
export const POSSIBLE_EXCH_COMPLETE = [
	"EXCH_IN",
	"EXCH_RECALLED",
	"EXCH_INSPECTED",
	"EXCH_SHIPPED",
];

/**
 * 쿠폰 유형 ( AMOUNT: 원, PERCENT: % )
 */
export const COUPON_TYPE = {
	AMOUNT: "AMOUNT",
	PERCENT: "PERCENT",
};

/**
 * color palette
 */
export const COLOR = {
	LINME: "#ff697b",
	LINME2: "#ff8181",
	LINME3: "#ff3d54",
	FONT: "#333",
	WHITE: "#fff",
	BLACK: "#333",
	DARK_GRAY01: "#8d8d8d",
	DARK_GRAY02: "#606060",
	DARK_GRAY03: "#707070",
	DARK_GRAY04: "#575757",
	DARK_GRAY05: "#787878",
	DARK_GRAY06: "#808080",
	GRAY01: "#a5a5a5",
	GRAY02: "#b6b6b6",
	GRAY03: "#bebebe",
	GRAY04: "#d2d2d2",
	GRAY05: "#e6e6e6",
	GRAY06: "#f3f3f3",
	GRAY07: "#efefef",
	GRAY08: "#f8f8f8",
	GRAY09: "#aaa",
	GRAY10: "#fcfcfc",
	GRAY11: "#f5f5f9",
	GRAY12: "#eff2f8",
	GRAY13: "#c6cddb",
	GREEN01: "#1ec800",
	GREEN02: "#8ef19f",
	BLUE01: "#3485ff",
	BLUE02: "#6382f3",
	BLUE03: "#69a5ff",
	BLUE04: "#6b70ff",
	BLUE05: "#77a0ff",
	BLUE06: "#b5cbff",
	BLUE07: "#e6f1ff",
	PINK01: "#f6eaea",
	PINK02: "#fff6f6",
	PINK03: "#ff6969",
	PINK04: "#fff0f2",
	PINK05: "#ffd1d1",
	PINK06: "#ffe2e6",
	YELLOW01: "#ffc267",
	YELLOW02: "#ffd847",
	YELLOW03: "#fde500",
};

/**
 * z-index
 */
export const Z_INDEX = {
	COMMON: 1,
	SPINNER: 15,
	DIMMER: 15,
};

/**
 * 딥링크 스킴에 사용하는 패스
 */
export const DEEP_LINK_PATH = {
	MAIN: "main",
	PRODUCT_DETAIL: "product?id=",
	ORDER_DETAIL: "order_detail?id=",
	CANCEL_DETAIL: "cancel_detail?id=",
	CHANGE_DETAIL: "exchange_detail?id=",
	REFUND_DETAIL: "refund_detail?id=",
	ORDER_LIST: "order_list",
	TOPIC: "topic_detail?id=",
	INQUIRY: "inquiry",
	NOTICE: "notice?id=",
};

/**
 * 배송요청사항 직접입력
 */
export const DELIVERY_MEMO_DIRECT_INPUT = "directInput";

/**
 * 배송요청사항
 */
export const DELIVERY_MEMO_LIST = [
	// { label: textInfo.myPage.myAddressBook.add.memoList.empty, value: "" },
	{
		label: textInfo.myPage.myAddressBook.add.memoList.memo1,
		value: textInfo.myPage.myAddressBook.add.memoList.memo1,
	},
	{
		label: textInfo.myPage.myAddressBook.add.memoList.memo2,
		value: textInfo.myPage.myAddressBook.add.memoList.memo2,
	},
	{
		label: textInfo.myPage.myAddressBook.add.memoList.memo3,
		value: textInfo.myPage.myAddressBook.add.memoList.memo3,
	},
	{
		label: textInfo.myPage.myAddressBook.add.memoList.memo4,
		value: textInfo.myPage.myAddressBook.add.memoList.memo4,
	},
	{
		label: textInfo.myPage.myAddressBook.add.memoList.directInput,
		value: DELIVERY_MEMO_DIRECT_INPUT,
	},
];

/**
 * 디바이스 종류
 */
export const DEVICE_TYPE = {
	PC: "PC",
	TABLET: "TABLET",
	MOBILE: "MOBILE",
};

/**
 * 사용자 이미지 비어있는 경우 출력할 대체 이미지
 */
export const EMPTY_USER_PROFILE_IMAGE = "/images/userProfileEmpty.png";

/**
 * 파일 최대 크기 ( 30메가 );
 */
export const FILE_IMAGE_MAX_SIZE_BYTE = 1024 * 1024 * 30;
export const FILE_IMAGE_MAX_SIZE_MB = 30;

/**
 * 파일 유형
 */
export const FILE_TYPE = {
	PRODUCT: "PRODUCT", //상품;
	REVIEW: "REVIEW", //리뷰;
	INQUIRY: "INQUIRY", //문의;
	MEMBER: "MEMBER", //회원;
	BANNER: "BANNER", //배너;
};

/**
 * 파일 업로드 가능한 확장자 유형
 */
export const FILE_UPLOAD_ACCEPT = {
	// image: ".gif, .jpeg, .jpg, .png, .bmp, .heic, .heif, .webp"
	image: ".gif, .jpeg, .jpg, .png, .bmp, .heic, .heif", //2024.04.03 - 백엔드 요청으로 webp 제외. 추후 추가 예정;
};

/**
 * 찾기 결과 종류
 */
export const FIND_RESULT_TYPE = {
	SUCCESS: "findCompleted",
	FAIL: "doesNotExist",
};

/**
 * 건강고민 - 기능식품
 */
export const HEALTH_ISSUE_EFFECT = {
	혈액순환: "bloodCirculation",
	피부건강: "skin",
	"운동/근육": "muscle",
	남성건강: "mens",
	여성건강: "women",
	혈당: "bloodSugar",
	눈건강: "eye",
	간건강: "liver",
	뼈건강: "bone",
	다이어트: "diet",
	스트레스: "stress",
	기관지: "bronchi",
	"탈모/손톱": "hairLoss",
	면역력: "immunity",
	혈관: "bloodVessel",
	관절: "joint",
	소화기건강: "digestive",
	갱년기: "climacteric",
	임산부: "pregnant",
	"피로/활력": "fatigue",
	장건강: "intestine",
	"치아/잇몸": "teeth",
	빈혈: "anemia",
	항산화: "antioxidant",
	혈압: "bloodPressure",
};

/**
 * 건강고민 - 맞춤식품
 */
export const HEALTH_ISSUE_INGREDIENTS = {
	간편식: "convenientFood",
	베이커리: "bakery",
	스낵: "snacks",
	음료: "beverage",
	식재료: "ingredients",
	"식재료/양념": "ingredients",
	"냉동/냉장": "freezingAndCold",
	빵: "bread",
	"요거트/치즈": "dairyProducts",
	"커피/차": "coffeeAndTea",
	"곡물/면/떡": "grainsAndCottonRiceCakes",
	실온보관: "storageAtRoomTemperature",
	즉석밥: "instantRice",
	아이스크림: "iceCream",
	"잼/청/버터": "jamAndSyrupAndButter",
	"견과류/육포": "nutsAndSlices",
	"두유/선식": "soyMilkPreMeal",
	육류: "meat",
	시리얼: "cereal",
	설탕대체제: "sugarSubstitute",
	구움과자: "bakedGoods",
	"탄산/이온": "carbonicAcidAndSportsDrink",
	에너지바: "energyBar",
	"과자/쿠키": "cookies",
	케이크: "cake",
	"소스/양념": "sauce",
	"사탕/초콜릿": "candy",
};

/**
 * 이미지 불러오기 실패 시 대체 이미지
 */
export const IMAGE_ERROR_SRC = {
	small: "/images/error/imageError_74x58.png",
	large: "/images/error/imageError_244x202.png",
};

/**
 * 1:1 문의 유형
 */
export const INQUIRY_TYPE = {
	ACCOUNT: { label: "로그인/계정", value: "ACCOUNT" },
	DELIVERY: { label: "배송", value: "DELIVERY" },
	PRODUCT: { label: "상품", value: "PRODUCT" },
	ORDER: { label: "주문", value: "ORDER" },
	CLAIM: { label: "교환/환불/취소", value: "CLAIM" },
	SERVICE: { label: "서비스/오류", value: "SERVICE" },
	ELSE: { label: "기타", value: "ELSE" },
};

/**
 * 상품 문의 유형
 */
export const INQUIRY_PRODUCT_TYPE = {
	PRODUCT: { label: "상품 문의", value: "PRODUCT" },
	DELIVERY: { label: "배송 문의", value: "DELIVERY" },
	ELSE: { label: "기타", value: "ELSE" },
};

/**
 * 문의 유형 ( 마이페이지 문의 내역 selectBox용 )
 */
export const INQUIRY_TYPE_LIST = [
	{ order: 0, label: "전체", value: "ALL" },
	{ order: 1, label: "로그인/계정", value: "ACCOUNT" },
	{ order: 2, label: "상품", value: "PRODUCT" },
	{ order: 3, label: "주문/결제", value: "ORDER" },
	{ order: 4, label: "교환/환불/취소", value: "CLAIM" },
	{ order: 5, label: "서비스/오류", value: "SERVICE" },
	{ order: 6, label: "기타", value: "ELSE" },
];

/**
 * 1:1 문의 유형
 */
export const INQUIRY_TYPE_LIST__WRITE = [
	// { order: 0, label: "전체", value: "ALL" }
	{ order: 1, label: "로그인/계정", value: "ACCOUNT" },
	// { order: 2, label: "배송", value: "DELIVERY" },
	{ order: 3, label: "상품", value: "PRODUCT" },
	{ order: 4, label: "주문/결제", value: "ORDER" },
	{ order: 5, label: "교환/환불/취소", value: "CLAIM" },
	{ order: 6, label: "서비스/오류", value: "SERVICE" },
	{ order: 7, label: "기타", value: "ELSE" },
];

/**
 * 상품 문의 유형
 */
export const INQUIRY_TYPE_LIST__PRODUCT = [
	{ order: 0, label: "전체", value: "ALL" },
	{ order: 1, label: "상품 문의", value: "PRODUCT" },
	{ order: 2, label: "배송 문의", value: "DELIVERY" },
	{ order: 3, label: "기타", value: "ELSE" },
];

/**
 * 문의 상태
 */
export const INQUIRY_STATUS = {
	WAIT: { value: "WAIT", name: "답변대기" },
	FINISH: { value: "FINISH", name: "답변완료" },
	DEAD: { value: "DEAD", name: "삭제" },
};

/**
 * 로그인 유형
 */
export const LOGIN_TYPE = {
	KAKAO: "KAKAO",
	NAVER: "NAVER",
	APPLE: "APPLE",
	EMAIL: "EMAIL",
};

export const NOTIFICATION_PAGE_MOVE = {
	ORDER_LIST: "order_list",
	ORDER_DETAIL: "order_detail",
	CANCEL_DETAIL: "cancel_detail",
	CHANGE_DETAIL: "exchange_detail",
	REFUND_DETAIL: "refund_detail",
	PRODUCT_DETAIL: "product",
	INQUIRE: "inquiry",
	TOPIC: "topic_detail",
	NOTICE: "notice",
	INTAKE_ALARM: "intake_alarm",
};

/**
 * 알림 상태 유형
 */
export const NOTIFICATION_STATUS_TYPE = {
	WAIT: { value: "WAIT", name: "읽지 않음" },
	DONE: { value: "DONE", name: "읽음" },
};

/**
 * 상품 옵션 타입
 */
export const OPTIONS_TYPE = {
	required: "필수옵션",
	additional: "선택옵션",
};

/**
 * 주문 상태
 */
export const ORDER_STATUS = {
	INIT: { value: "INIT", desc: "주문 초기화 (주문내역 비노출)", name: "", type: "" },
	PAY_WAIT: { value: "PAY_WAIT", desc: "결제대기", name: "결제대기", type: "결제대기" },
	PAY_FIN: { value: "PAY_FIN", desc: "결제완료", name: "결제완료", type: "결제완료" },
	PAY_FAIL: { value: "PAY_FAIL", desc: "결제실패", name: "결제실패", type: "결제실패" },
	PRD_READY: { value: "PRD_READY", desc: "상품준비중", name: "상품준비중", type: "상품준비중" },
	DLVY_READY: { value: "DLVY_READY", desc: "배송준비중", name: "배송준비중", type: "배송준비중" },
	DLVY_IN: { value: "DLVY_IN", desc: "배송중", name: "배송중", type: "배송중" },
	DLVY_FIN: { value: "DLVY_FIN", desc: "배송완료", name: "배송완료", type: "배송완료" },
	CONFIRM: { value: "CONFIRM", desc: "구매확정", name: "구매확정", type: "구매확정" },
};

/**
 * moment 포맷
 */
export const FORMAT_MOMENT = {
	KO_YMD: "YYYY년 MM월 DD일",
	KO_YMD_HMS: "YYYY년 MM월 DD일 HH시mm분ss초",
	YMD: "YYYY.MM.DD",
};

/**
 * 주문 타입 ( CART: 장바구니, PRODUCT: 바로 구매 )
 */
export const ORDER_TYPE = {
	CART: "CART",
	PRODUCT: "PRODUCT",
};

/**
 * 페이지네이션 기본 offset
 */
export const PAGINATION_DEFAULT_OFFSET = {
	DEFAULT: 20,
	PRODUCT_LIST: 30,
	PHOTO_REVIEW: 42,
	REWARD_CHALLENGE: 10,
	REWARD_HISTORY: 10,
	RECENT_PRODUCT: 4,
	ADDRESS_BOOK: 19,
	ALARM_LIST: 10,
	REVIEW_LIST: 5,
};

/**
 * 페이지네이션 타입
 */
export const PAGINATION_TYPE = {
	PAGE: "PAGE",
	SCROLL: "SCROLL",
};

/**
 * 결제 방식
 */
export const PAYMENT_METHOD_TYPE = {
	CARD: "CARD", //신용카드
	VBANK: "VBANK", //가상계좌(무통장입금);
	PHONE: "PHONE", //휴대폰결제;
	REWARD: "REWARD", //리워드결제;
};

/**
 * 사용자에게 보여줄 결제 방식
 */
export const PAYMENT_METHOD_VISIBLE_LIST = [
	{ label: textInfo.payment.payMethod.creditCard, value: "CARD" },
	// , { label: textInfo.payment.payMethod.virtualAccount, value: "VBANK" }
	// , { label: textInfo.payment.payMethod.mobilePhone, value: "PHONE" }
	// , { label: textInfo.payment.payMethod.reward, value: "REWARD" }
];

/**
 * 결제 진행 시 상태
 */
export const PAYMENT_RESULT_STATUS = {
	SUCCESS: "SUCCESS",
	FAIL: "FAIL",
	CLOSE: "CLOSE",
};

/**
 * 상품 정렬 순서
 */
export const PRODUCT_LIST_ORDER = [
	{ label: "추천순", value: "LIKE", order: 1 },
	{ label: "혜택순", value: "DISCOUNT", order: 2 },
	{ label: "낮은가격순", value: "LOW", order: 3 },
	{ label: "높은가격순", value: "HIGH", order: 4 },
];

/**
 * 상품 옵션 종류
 */
export const PRODUCT_OPTIONS_TYPE = {
	REQUIRED: "REQUIRED",
	ADDITIONAL: "ADDITIONAL",
};

/**
 * 상품 판매 상태 (SALE:판매중, HOLD:판매대기, DEAD:판매종료, SOLDOUT:재고소진)
 */
export const PRODUCT_STATUS = {
	SALE: "SALE",
	HOLD: "HOLD",
	DEAD: "DEAD",
	SOLDOUT: "SOLDOUT",
};

/**
 * 상품 종류
 */
export const PRODUCT_TYPE_NO = {
	effect: 1, //기능식품;
	ingredients: 2, //맞춤식품;
};

/**
 * 정규표현식
 */
export const REG_EXP = {
	blank: /\s/g,
	member: {
		email:
			/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/, // 이메일
		nickname: /^[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z|A-Z|0-9]{2,12}$/, // 한글, 영문, 숫자 2~12자
		pw: /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$/, // 영문, 숫자, 특수문자 1개 이상 포함 8~16자
		// , phone: /^\\d{2,3}-\\d{3,4}-\\d{4}$/
		phone: /^[0-9]+$/, // 숫자만 입력
	},
	address: {
		nickname: /^[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z|A-Z|0-9|!@#$%^*+=-\s]{2,12}$/, //[배송지명] 한글, 영문, 숫자, 특수문자, 띄어쓰기 2~12자
		name: /^[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z|A-Z|0-9|!@#$%^*+=-\s]{2,12}$/, //[받는 사람] 한글, 영문, 숫자, 특수문자, 띄어쓰기 2~12자
		address2: /^[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z|A-Z|0-9|!@#$%^*+=-\s]{1,50}$/, //[상세주소] 한글, 영문, 숫자, 특수문자, 띄어쓰기 1~50자
		memo: /^(.{0,200})$/, //[배송 요청사항] 200자 이내
	},
	myLinme: {
		weight: /^(300|[1-9]|[1-9][0-9]|[1-2][0-9]{2})$/, //[몸무게] 1-300 사이의 정수만 허용
		height: /^(300|[1-9]|[1-9][0-9]|[1-2][0-9]{2})$/, //[키] 1-300 사이의 정수만 허용
	},
};

/**
 * 재설정 결과 종류
 */
export const RESET_RESULT_TYPE = {
	SUCCESS: "resetCompleted",
	FAIL: "resetFailed",
};

/**
 * 상품 반송 방법
 */
export const RETURN_DELIVERY_TYPE = {
	COMPANY: "COMPANY", //택배 수거 요청;
	DIRECT: "DIRECT", //구매자 직접 발송;
	LATER: "LATER", //나중에입력;
};

/**
 * 사용자에게 보여줄 상품 반송 방법
 */
export const RETURN_DELIVERY_LIST = [
	{ label: textInfo.claim.pickup.deliveryType.company, value: "COMPANY" },
	{ label: textInfo.claim.pickup.deliveryType.direct, value: "DIRECT" },
	{ label: textInfo.claim.pickup.deliveryType.later, value: "LATER" },
];

/**
 * 리뷰 정렬 순서
 */
export const REVIEW_LIST_ORDER = [
	{ label: "베스트순", value: "BEST", order: 1 },
	{ label: "최신순", value: "FAST", order: 2 },
];

/**
 * 리뷰 별점 최대값
 */
export const REVIEW_SCORE_MAX = 5;

/**
 * 리뷰 별점 최소값
 */
export const REVIEW_SCORE_MIN = 1;

/**
 * 리뷰 별점 구간값
 */
export const REVIEW_SCORE_STEP = 0.5;

/**
 * 리워드 챌린지 상태
 */
export const REWARD_CHALLENGE_STATUS = {
	IN: { label: "진행중", value: "IN" },
	FINISH: { label: "종료", value: "FINISH" },
};

/**
 * 리워드 타입
 */
export const REWARD_CHALLENGE_TYPE = {
	NOTICE: "NOTICE", // 공지사항
	ATTENDANCE: "ATTENDANCE", // 출석체크
};

/**
 * 공통 사용 ENUM
 */

export const TOP_MENU_ACTIVE_TYPE = {
	MYLINME: "myLinme",
	PRODUCT: "product",
	REWARD: "reward",
	CART: "cart",
	MYPAGE: "myPage",
	SHOPPING: "shopping",
	NEW: "new",
	BEST: "best",
	SPECIAL: "special",
	ETC: "",
};

/**
 * 본인인증/결제 호출 환경
 */
export const VERIFY_ENV = {
	WEB: {
		PC: "PC",
		MOBILE: "MOBILE",
	},
	APP: "APP",
};

/**
 * 본인인증 종류
 */
export const VERIFY_TYPE = {
	SIGN_UP: "NEW", //회원가입;
	FIND_ID: "EMAIL", //아이디찾기;
	FIND_PW: "PW", //비밀번호찾기;
	CHANGE_PW: "CHANGE", //비밀번호변경;
};

/**
 * YN 체크
 */
export const YN = {
	Y: true,
	N: false,
};

/**
 * 나이 유형
 */
export const AGE_TYPE = [
	{ value: "10", label: "10대" },
	{ value: "20", label: "20대" },
	{ value: "30", label: "30대" },
	{ value: "40", label: "40대" },
	{ value: "50", label: "50대" },
	{ value: "60", label: "60대" },
	{ value: "70", label: "70대 이상" },
];

/**
 * 나이 유형
 */
export const GENDER_TYPE = [
	{ value: "여성", label: "여성" },
	{ value: "남성", label: "남성" },
];

// 링크 타켓
export const BANNER_LANDING_TARGET = {
	NEW_BLANK: "_blank",
	NEW_SELF: "_self",
};

// 이미지 불러오기 실패 시 대체 이미지
export const DEFAULT_ERROR_IMAGE_LIST = [
	{ webPath: IMAGE_ERROR_SRC.large },
	{ webPath: IMAGE_ERROR_SRC.large },
	{ webPath: IMAGE_ERROR_SRC.large },
];

export const TOPIC_LIMIT = {
	PC: 5,
	NOT_PC: 2.5,
};
