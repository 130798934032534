/**
 * 리워드 - 랜딩
 */

import clsx from "clsx";
import { useParams } from "react-router-dom";

import { useChallengeDetail } from "src/hooks/challenge/useChallenge";

import MoveToBack from "src/pages/component/moveToBack";
import AttendanceCalendar from "src/pages/component/reward/attendanceCalendar";
import { getTimeStamp } from "src/utils/date";
import { REWARD_CHALLENGE_STATUS, REWARD_CHALLENGE_TYPE } from "src/data";

import styles from "src/styles/reward/detail.module.scss";
import "src/styles/common/linmeCalendar.scss";

const textInfo = require("src/data/textInfo.json");

const RewardDetail = () => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const { challengeNo } = useParams();
	const { data: challengeInfoData, isLoading } = useChallengeDetail(challengeNo);

	const isIn = challengeInfoData?.status === REWARD_CHALLENGE_STATUS.IN.value;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	if (isLoading) return null;

	return (
		<div className={clsx(["container", styles.rewardDetailContainer])}>
			<div className={clsx(["contents", styles.rewardDetailContent])}>
				{/* Title */}
				<div className={styles.titleContainer}>
					<MoveToBack />
					<div className={styles.title}>
						{isIn ? textInfo.reward.challenge.in : textInfo.reward.challenge.finish}
					</div>
				</div>
				{/* 리워드 */}
				{challengeInfoData?.type !== REWARD_CHALLENGE_TYPE.NOTICE && (
					<div className={clsx([styles.reward, !isIn && styles.finished])}>
						<span>{challengeInfoData?.reward?.toLocaleString()}</span>
						{textInfo.common.rewardUnit}
					</div>
				)}
				{/* 챌린지명 */}
				<div className={clsx([styles.rewardChallengeTitle, !isIn && styles.finished])}>
					{challengeInfoData?.title}
				</div>
				{/* 챌린지 기간 */}
				<div className={styles.rewardChallengePeriod}>
					{`${getTimeStamp(challengeInfoData?.startAt, "YY.MM.DD")} - ${getTimeStamp(
						challengeInfoData?.endAt,
						"YY.MM.DD"
					)}`}
				</div>
				{/* 챌린지 내용 */}
				<div
					className={styles.challengeContent}
					dangerouslySetInnerHTML={{ __html: challengeInfoData?.content }}
				/>
				{/* 출석체크 캘린더 */}
				{challengeInfoData?.type === REWARD_CHALLENGE_TYPE.ATTENDANCE && (
					<AttendanceCalendar isIn={isIn} />
				)}
			</div>
		</div>
	);
};

export default RewardDetail;
