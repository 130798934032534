/**
 * 마이페이지 - 쇼핑내역 - 취소/교환/환불
 */

import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";
import { PAGINATION_DEFAULT_OFFSET } from "src/data/constEnum";

/**
 * 교환/환불 배송비 결제 결과 조회
 * @param {String} paymentNo
 * @returns
 */
export const checkClaimPaymentCompleted = async (paymentNo) => {
	const url = `${API_PATHS.CLAIM_PAYMENT_CHECK_COMPLETED}`;
	try {
		const result = await api.get(url.replace(":paymentNo", paymentNo));
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 주문 취소 가능 상품 조회
 * @returns
 */
export const checkOrderCancelProduct = async (orderNo) => {
	const url = `${API_PATHS.ORDER_CANCEL_AVAILABLE}`;
	try {
		const result = await api.get(url.replace(":orderNo", orderNo));
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 주문 환불 가능 상품 조회
 * @returns
 */
export const checkOrderRefundProduct = async (itemNo) => {
	const url = `${API_PATHS.ORDER_REFUND_AVAILABLE}`;
	try {
		const result = await api.get(url.replace(":itemNo", itemNo));
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 클레임 목록 조회
 * @param {Object} d
 * <code>
 * {
 * 		offset: 0
 * 		, page: 0
 * }
 * </code>
 */
export const getClaimList = async (d) => {
	const url = `${API_PATHS.CLAIM_LIST}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 클레임 사유 조회
 * @param {Object} d
 * <code>
 * {
 * 		type: ""//CANCEL, CHANGE, REFUND;
 * }
 * </code>
 * @returns
 */
export const getClaimReason = async (d) => {
	const url = `${API_PATHS.CLAIM_REASON}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 클레임 반송 정보 입력 ( 클레임 요청 시 반송정보 "나중에 입력" 선택 시 )
 * @param {Object} d
 * <code>
 * {
 * 		*claimProductNo: 0//클레임 상품 식별자;
 * 		, *companyNo: 0//택배사 번호;
 * 		, *invoiceCode: ""//송장번호;
 * }
 * </code>
 * @returns
 */
export const setClaimDeliveryPickupDetail = async (d) => {
	const url = `${API_PATHS.CLAIM_DELIVERY_PICKUP_DETAIL}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
