/**
 * 페이지네이션
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useRef, useState } from "react";
import useModalStack from "src/hooks/useModalStack";

import ModalCommonAlert from "src/pages/common/modalAlert";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/pagination.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const Pagination = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const refPageNumber = useRef(null);
	const modalStack = useModalStack();

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 입력값 변경 시 ( 숫자만 입력 + enter 시 이동 )
	 * @param {Event} e
	 */
	const handlerChange = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		e.target.value = e.target.value.replace(/[^0-9]/g, ""); //숫자만 적용;

		if (e.target.value === "") e.target.value = props.currentPage;
	};

	/**
	 * "다음 페이지로" 화살표 클릭 시
	 * @param {Event} e
	 */
	const handlerClickNext = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (props.isLastPage) {
			modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.pagination.lastPage,
			});
			return;
		}
		redraw(props.currentPage + 1);
		refPageNumber.current.value = props.currentPage + 1;
	};

	/**
	 * "이전 페이지로" 화살표 클릭 시
	 * @param {Event} e
	 */
	const handlerClickPrev = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (props.currentPage > 1) {
			redraw(props.currentPage - 1);
			refPageNumber.current.value = props.currentPage - 1;
		} else {
			modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.pagination.firstPage,
			});
			return;
		}
	};

	/**
	 * 키 업 이벤트 발생 시
	 * @param {KeyboardEvent} e
	 */
	const handlerKeyUp = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		let key = e.key || e.keyCode;

		if (key === "Enter" || key === 13) {
			const inputValue = e.currentTarget.value;
			if (inputValue <= 0) {
				e.currentTarget.value = props.currentPage;
				modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					html: `<div style="height: fit-content; line-height: 1.58; margin: 20px 20px 10px; text-align: left;">입력 가능한 페이지 번호는<br/>1 부터 ${props.lastPage} 사이의 정수입니다.</div>`,
				});
				return;
			}
			if (inputValue > props.lastPage) {
				e.currentTarget.value = props.currentPage;
				modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					html: `<div style="height: fit-content; line-height: 1.58; margin: 20px 20px 10px; text-align: left;">입력 가능한 페이지 번호는<br/>1 부터 ${props.lastPage} 사이의 정수입니다.</div>`,
				});
				return;
			}
			redraw(inputValue);
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 부모에게 넘겨받은 다시그리기 함수가 있으면 다시 그리기
	 * @param {Number} pageNo
	 */
	const redraw = (pageNo) => {
		if (props.redraw) props.redraw(pageNo, refPageNumber);
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="pagination">
			<div className="button prev" onClick={(e) => handlerClickPrev(e)} />
			<input
				type="text"
				className="currentPage"
				name="currentPage"
				pattern="\d*"
				defaultValue={props.currentPage}
				key={props.currentPage}
				onChange={(e) => handlerChange(e)}
				onKeyUp={(e) => handlerKeyUp(e)}
				ref={refPageNumber}
			/>
			<div className="of" />
			<span className="lastPage">{Math.ceil(props.totalCount / props.offset)}</span>
			<div className="button next" onClick={(e) => handlerClickNext(e)} />
		</div>
	);
};

export default Pagination;
