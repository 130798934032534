/**
 * 회원가입
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useRef } from "react";
import { useNavigate } from "react-router-dom";

import useAuth from "src/hooks/useAuth";
import useModalStack from "src/hooks/useModalStack";
import { API_RESULT_STATUS, REG_EXP, VERIFY_ENV, VERIFY_TYPE } from "src/data/constEnum";
import { duplicateCheckEmail, requestVerify } from "src/api/member/signUp";
import {
	handlerClickTogglePwType,
	handlerKeyUpUserPW,
	handlerKeyUpUserPWCheck,
	handlerClickDuplicateCheckNickname,
	handlerKeyUpNickname,
	validationNickname,
	getDuplicateCheckCi,
	validationPhone,
	validationAddress1,
	validationAddress2,
	validationPwCheck,
	validationPw,
} from "src/utils/linmeMember";
import { execDaumPostCode } from "src/common/postCodeDaum";
import { getWesternAge } from "src/utils/date";
import { isMobile } from "react-device-detect";

import ModalCommonAlert from "src/pages/common/modalAlert";
import AuthVerifyIframe from "src/pages/member/modal/authVerifyIframe";
import TermsService from "src/pages/terms/termsService";
import TermsPrivacyPolicy from "src/pages/terms/termsPrivacyPolicy";
import TermsMarketingPurposes from "src/pages/terms/termsMarketingPurposes";
import Image from "src/pages/component/common/image";
import { encryptPassword } from "src/utils/linmeSession";
import { sendNaverSignUp } from "src/utils/linmeNaverScript";

import "src/styles/member/signUp.scss";

const pathList = require("src/path/pathJSON.json");
const textInfo = require("src/data/textInfo.json");

function SignUp() {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const auth = useAuth();
	const navigate = useNavigate();
	const modalStack = useModalStack();

	const ref = {
		name: useRef(null),
		nickname: useRef(null),
		checkNickname: useRef(null),
		email: useRef(null),
		checkEmail: useRef(null),
		pw: useRef(null),
		pwCheck: useRef(null),
		phone: useRef(null),
		checkVerify: useRef(null),
		buttonVerify: useRef(null),
		address1: useRef(null),
		address2: useRef(null),
		postcode: useRef(null),
		birth: useRef(null),
		gender: useRef(null),
		ci: useRef(null),
		termsAgreeAll: useRef(null),
		termsService: useRef(null),
		termsPrivacy: useRef(null),
		termsMarketing: useRef(null),
		termsBenefit: useRef(null),
		termsBenefitSMS: useRef(null),
		termsBenefitEMAIL: useRef(null),
		termsOlderThan14: useRef(null),
		buttonInputCompleted: useRef(null),
	};

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 서비스 이용약관 보기
	 * @param {Event} e
	 */
	const handlerClickOpenModalTermsService = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.addModal({
			id: "modalTermsService",
			type: "alert",
			component: <TermsService />,
		});
	};

	/**
	 * 개인정보 처리방침 약관 보기
	 * @param {Event} e
	 */
	const handlerClickOpenModalTermsPrivacyPolicy = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.addModal({
			id: "modalTermsPrivacyPolicy",
			type: "alert",
			component: <TermsPrivacyPolicy />,
		});
	};

	/**
	 * 마케팅 목적 개인정보 수집 이용 동의 약관 보기
	 * @param {Event} e
	 */
	const handlerClickOpenModalTermsMarketingPurposes = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.addModal({
			id: "modalTermsMarketingPurposes",
			type: "alert",
			component: <TermsMarketingPurposes />,
		});
	};

	/**
	 * 가입하기 클릭 시
	 * @param {Event} e
	 */
	const handlerClickSignUp = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (validation() === false) return;

		// if ( !validationTerms()) {
		// 	modalStack.addModal({
		// 		id: "modalCommonAlert"
		// 		, type: "alert"
		// 		, component: <ModalCommonAlert />
		// 		, text: textInfo.alert.agreeToRequiredTerms
		// 	});
		// 	return false;
		// }

		const hashedPassword = await encryptPassword(ref.pw.current.value);
		const hashedPasswordConfirm = await encryptPassword(ref.pwCheck.current.value);

		const d = {
			address1: ref.address1.current.value,
			address2: ref.address2.current.value,
			birth: ref.birth.current.value,
			ci: ref.ci.current.value,
			email: ref.email.current.value,
			gender: ref.gender.current.value,
			isCheckAdEmail: ref.termsBenefitEMAIL.current.checked,
			isCheckAdSms: ref.termsBenefitSMS.current.checked,
			name: ref.name.current.value,
			nickname: ref.nickname.current.value,
			password: hashedPassword,
			passwordConfirm: hashedPasswordConfirm,
			phone: ref.phone.current.value,
			postcode: ref.postcode.current.value,
		};
		await auth.signUp(d).then((isLogin) => {
			if (isLogin === true) {
				sendNaverSignUp();
				navigate(pathList.main);
			} else if (isLogin.message) {
				let o = {
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
				};
				if (isLogin.type === "text")
					o.text =
						isLogin.message.indexOf("Exception") > -1
							? textInfo.alert.pleaseCheckYourData
							: isLogin.message;
				else o.html = isLogin.message;
				modalStack.addModal(o);
			}
		});
	};

	/**
	 * 약관 - 전체동의
	 * @param {Event} e
	 */
	const handlerClickTermsAgreeAll = (e) => {
		/*/
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();
		//*/

		const check = ref.termsAgreeAll.current.checked;

		ref.termsService.current.checked = check;
		ref.termsPrivacy.current.checked = check;
		ref.termsMarketing.current.checked = check;
		ref.termsBenefit.current.checked = check;
		ref.termsBenefitSMS.current.checked = check;
		ref.termsBenefitEMAIL.current.checked = check;
		if (ref.termsOlderThan14.current.readOnly !== true) {
			ref.termsOlderThan14.current.checked = check;
		}
		handlerChangeInput(e);
	};

	/**
	 * 약관 - 서비스 이용 약관 ( 필수 )
	 * @param {Event} e
	 */
	const handlerClickTermsService = (e) => {
		/*/
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();
		//*/

		setTermsChecked();
		handlerChangeInput(e);
	};

	/**
	 * 약관 - 개인정보 수집 이용 동의 ( 필수 )
	 * @param {Event} e
	 */
	const handlerClickTermsPrivacy = (e) => {
		/*/
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();
		//*/

		setTermsChecked();
		handlerChangeInput(e);
	};

	/**
	 * 약관 - 마케팅 목적 개인정보 수집 이용 동의 ( 선택 )
	 * @param {Event} e
	 */
	const handlerClickTermsMarketing = (e) => {
		/*/
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();
		//*/

		setTermsChecked();
		handlerChangeInput();
	};

	/**
	 * 약관 - 무료배송, 할인쿠폰 등 혜택/정보 수신 동의 ( 선택 )
	 * @param {Event} e
	 */
	const handlerClickTermsBenefit = (e) => {
		/*/
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();
		//*/

		const ct = e.currentTarget;
		const checked = ct.checked;
		if (checked) {
			ref.termsBenefitSMS.current.checked = checked;
			ref.termsBenefitEMAIL.current.checked = checked;
		} else {
			ref.termsBenefitSMS.current.checked = false;
			ref.termsBenefitEMAIL.current.checked = false;
		}

		setTermsChecked();
		handlerChangeInput();
	};

	/**
	 * 약관 - 무료배송, 할인쿠폰 등 혜택/정보 수신 동의 ( 선택 ) - SMS, EMAIL 개별
	 * @param {Event} e
	 */
	const handlerClickTermsBenefitEach = (e) => {
		/*/
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();
		//*/

		if (ref.termsBenefitSMS.current.checked && ref.termsBenefitEMAIL.current.checked) {
			ref.termsBenefit.current.checked = true;
		}

		handlerChangeInput(e);
	};

	/**
	 * 약관 - 쇼핑몰 이용 - 만14세 이상 ( 필수 )
	 * @param {Event} e
	 */
	const handlerClickTermsOlderThan14 = (e) => {
		/*/
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();
		//*/

		setTermsChecked();
		handlerChangeInput(e);
	};

	/**
	 * 본인인증 클릭 시
	 * @param {Event} e
	 */
	const handlerClickVerify = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		// 기 인증 완료한 경우 실행하지 않음;
		if (ref.checkVerify.current.value) return false;

		await postRequestVerify().then((data) => {
			modalStack.addModal({
				id: "modalVerify",
				type: "none",
				component: <AuthVerifyIframe {...data} />,
				setDataAfterVerify: setDataAfterVerify,
			});
		});
	};

	/**
	 * 이메일 KeyUp
	 * @param {Event} e
	 */
	const handlerKeyUpUserEmail = (e) => {
		window.linmeLog.log("---------- signUp - handlerKeyUpUserEmail STA ---------- ");

		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const ct = e.currentTarget;
		const elUserInput = ct.closest(".userInput");
		const elGuideList = elUserInput.querySelectorAll(".inputGuide");
		const elGuideWarn = elUserInput.querySelector(".inputGuideWarn");
		const elGuideChecked = elUserInput.querySelector(".inputGuideChecked");
		elGuideChecked.innerText = "";
		ref.checkEmail.current.value = false;

		if (ct.value.match(REG_EXP.blank)) {
			//공백 포함 시;
			elGuideList.forEach((element) => {
				element.classList.add("off");
			});
			elGuideWarn.innerText = textInfo.member.warn.common.noUseBlank;
			return false;
		}

		if (REG_EXP.member.email.test(ct.value)) {
			elGuideList.forEach((element) => {
				element.classList.add("off");
			});
			elGuideWarn.innerText = textInfo.member.warn.email.checkDuplicate;
		} else {
			elGuideList.forEach((element) => {
				element.classList.add("off");
			});
			elGuideWarn.innerText = textInfo.member.warn.email.character;
		}

		handlerChangeInput();
		window.linmeLog.log("---------- signUp - handlerKeyUpUserEmail END ---------- ");
	};

	/**
	 * 필수입력항목 onBlur
	 * @param {Event} e
	 * @returns
	 */
	const handlerBlurRequired = (e) => {
		window.linmeLog.log("---------- signUp - handlerBlurRequired STA ---------- ");

		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const ct = e.currentTarget;
		const elUserInput = ct.closest(".userInput");
		const elGuideList = elUserInput.querySelectorAll(".inputGuide");
		const elGuideWarn = elUserInput.querySelector(".inputGuideWarn");
		if (ct.value.length === 0) {
			elGuideList.forEach((element) => {
				element.classList.add("off");
			});
			elGuideWarn.innerText = textInfo.member.warn.common.required;

			window.linmeLog.log("---------- signUp - handlerBlurRequired END ---------- ");
			return false;
		}

		window.linmeLog.log("---------- signUp - handlerBlurRequired END ---------- ");
	};

	/**
	 *
	 * @param {Event} e
	 */
	const handlerChangeInput = (e) => {
		window.linmeLog.log("---------- signUp - handlerChangeInput STA ---------- ");

		/*/
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();
		//*/

		if (validation() === true) {
			ref.buttonInputCompleted.current.classList.add("on");
		} else {
			ref.buttonInputCompleted.current.classList.remove("on");
		}

		window.linmeLog.log("---------- signUp - handlerChangeInput END ---------- ");
	};

	/**
	 * 이메일(아이디) 중복 확인
	 * @param {Event} e
	 */
	const handlerClickDuplicateCheckEmail = async (e) => {
		window.linmeLog.log("---------- signUp - handlerClickDuplicateCheckEmail STA ---------- ");

		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const ct = ref.email.current;
		const elUserInput = ct.closest(".userInput");
		const elGuideList = elUserInput.querySelectorAll(".inputGuide");
		const elGuideWarn = elUserInput.querySelector(".inputGuideWarn");
		const elGuideChecked = elUserInput.querySelector(".inputGuideChecked");
		elGuideWarn.innerText = "";
		elGuideChecked.innerText = "";

		if (ct.value.length === 0) {
			elGuideList.forEach((element) => {
				element.classList.add("off");
			});
			elGuideWarn.innerText = textInfo.member.warn.common.required;

			window.linmeLog.log("---------- signUp - handlerClickDuplicateCheckEmail END ---------- ");
			return false;
		}

		if (ct.value.match(REG_EXP.blank)) {
			//공백 포함 시;
			elGuideList.forEach((element) => {
				element.classList.add("off");
			});
			elGuideWarn.innerText = textInfo.member.warn.common.noUseBlank;
			return false;
		}

		if (!REG_EXP.member.email.test(ct.value)) {
			elGuideList.forEach((element) => {
				element.classList.add("off");
			});
			elGuideWarn.innerText = textInfo.member.warn.email.character;
			return false;
		}

		await getDuplicateCheckEmail().then((isExist) => {
			if (isExist) {
				elGuideWarn.innerText = textInfo.member.warn.email.inUse;
				ref.checkEmail.current.value = false;
			} else {
				elGuideChecked.innerText = textInfo.member.checked.email.available;
				ref.checkEmail.current.value = true;
				handlerChangeInput();
			}
		});

		window.linmeLog.log("---------- signUp - handlerClickDuplicateCheckEmail END ---------- ");
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 본인인증 후처리
	 * @param {Object} d
	 */
	const setDataAfterVerify = async (d) => {
		if (!d.hasOwnProperty("status")) return;
		if (d.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
			//이름;
			ref.name.current.value = d.name;
			//성별;
			ref.gender.current.value = d.gender;
			//생일;
			ref.birth.current.value = d.birthday;
			//휴대폰;
			ref.phone.current.value = d.phone;
			//CI;
			ref.ci.current.value = d.ci;
			//본인인증 여부;
			ref.checkVerify.current.value = true;

			const westernAge = await getWesternAge(d.birthday);
			const elUserInputAge = ref.termsOlderThan14.current.closest(".userInput");
			elUserInputAge.classList.add("readonly");
			ref.termsOlderThan14.current.readOnly = true;
			if (westernAge >= 14) {
				//만나이가 14세 이상인 경우;
				ref.termsOlderThan14.current.checked = true;
			} else {
				ref.termsOlderThan14.current.checked = false;
			}
			ref.buttonVerify.current.classList.add("off");

			const ct = ref.checkVerify.current;
			const elUserInput = ct.closest(".userInput");
			const elGuideList = elUserInput.querySelectorAll(".inputGuide");
			const elGuideWarn = elUserInput.querySelector(".inputGuideWarn");
			elGuideList.forEach((element) => {
				element.classList.add("off");
			});
			elGuideWarn.innerText = "";
			//d.ci;
			//d.di;
			//d.orderCode;

			const ciCheckResult = await getDuplicateCheckCi(d.ci);
			if (ciCheckResult) {
				modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.memberInfoExists,
				});
			}
		} else {
			const elUserInput = ref.termsOlderThan14.current.closest(".userInput");
			elUserInput.classList.remove("readonly");
			ref.termsOlderThan14.current.checked = true;
			ref.checkVerify.current.value = false;
		}
	};

	/**
	 * 이메일 중복 확인 요청
	 */
	const getDuplicateCheckEmail = async () => {
		try {
			const d = {
				email: ref.email.current.value,
			};
			const result = await duplicateCheckEmail(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				return result.data.isCheckExist;
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (error) {
			window.linmeLog.log(error);
			return false;
		}
	};

	/**
	 * 본인인증
	 */
	const postRequestVerify = async () => {
		try {
			const d = {
				env: isMobile ? VERIFY_ENV.WEB.MOBILE : VERIFY_ENV.WEB.PC,
				memberNo: 0,
				type: VERIFY_TYPE.SIGN_UP,
			};
			const result = await requestVerify(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				return result.data;
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	/**
	 * 약관 동의 설정
	 */
	const setTermsChecked = () => {
		if (ref.termsBenefitSMS.current.checked && ref.termsBenefitEMAIL.current.checked) {
			ref.termsBenefit.current.checked = true;
		}
		if (
			ref.termsService.current.checked && //서비스 이용약관 ( 필수 );
			ref.termsPrivacy.current.checked && //개인정보 수집 이용 동의 ( 필수 );
			ref.termsMarketing.current.checked && //마케팅 목적 개인정보 수집 이용 동의 ( 선택 );
			ref.termsBenefit.current.checked && //무료배송, 할인쿠폰 등 혜택/정보 수신 동의 ( 선택 );
			ref.termsOlderThan14.current.checked //쇼핑몰 이용 - 만14세 이상 ( 필수 );
		) {
			ref.termsAgreeAll.current.checked = true;
		} else ref.termsAgreeAll.current.checked = false;
	};

	/**
	 * validation
	 * @returns
	 */
	const validation = () => {
		if (!validationEmail()) return false;
		if (!validationPw(ref.pw.current)) return false;
		if (!validationPwCheck(ref.pwCheck.current, ref.pw.current)) return false;
		if (!validationNickname(ref.nickname, ref.checkNickname)) return false;
		if (!validationPhone(ref.checkVerify)) return false;
		if (!validationAddress1(ref.address1)) return false;
		if (!validationAddress2(ref.address2)) return false;
		if (!validationTerms()) return false;

		return true;
	};

	/**
	 * validation 아이디 ( 이메일 )
	 * @returns
	 */
	const validationEmail = () => {
		window.linmeLog.log("---------- signUp - [ STA ] - validationEmail  ---------- ");

		const ct = ref.email.current;
		const elUserInput = ct.closest(".userInput");
		const elGuideList = elUserInput.querySelectorAll(".inputGuide");
		const elGuideWarn = elUserInput.querySelector(".inputGuideWarn");
		if (ct.value.length === 0) {
			elGuideList.forEach((element) => {
				element.classList.add("off");
			});
			elGuideWarn.innerText = textInfo.member.warn.common.required;
			window.linmeLog.log("---------- signUp - [ END ] - validationEmail  ---------- ");
			return false;
		}

		if (ref.checkEmail.current.value !== "true") {
			elGuideList.forEach((element) => {
				element.classList.add("off");
			});
			window.linmeLog.log("---------- signUp - [ END ] - validationEmail  ---------- ");
			return false;
		}

		window.linmeLog.log("---------- signUp - [ END ] - validationEmail  ---------- ");
		return true;
	};

	/**
	 * validation - 필수 약관
	 * @returns
	 */
	const validationTerms = () => {
		if (
			!ref.termsService.current.checked || //서비스 이용약관 ( 필수 );
			!ref.termsPrivacy.current.checked || //개인정보 수집 이용 동의 ( 필수 );
			!ref.termsOlderThan14.current.checked //쇼핑몰 이용 - 만14세 이상 ( 필수 );
		) {
			return false;
		}
		return true;
	};

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="container underTopMenu signUp">
			<div className="contents">
				<div className="signUpCover">
					<div className="title">{textInfo.member.signUp}</div>
					<ul className="inputDataCover">
						<li className="title">
							<Image
								className="separator"
								srcSet="
								../../images/important.png 1x
								, ../../images/important@2x.png 2x
								, ../../images/important@3x.png 3x"
								alt={textInfo.member.data.required}
							/>
							<span className="text">{textInfo.member.data.input}</span>
						</li>
						<li className="inputDataItem">
							<div className="label">
								<span>{textInfo.member.data.id}</span>
							</div>
							<div className="userInput">
								<input
									type="text"
									ref={ref.email}
									className="email"
									name="email"
									id="email"
									placeholder={textInfo.member.placeholder.email}
									// onChange={( e ) => handlerChangeInput( e )}
									onKeyUp={(e) => handlerKeyUpUserEmail(e)}
									onBlur={(e) => handlerBlurRequired(e)}
								/>
								<input type="hidden" ref={ref.checkEmail} name="checkEmail" id="checkEmail" />
								<button
									className="button duplicateCheck duplicateCheckID"
									onClick={(e) => handlerClickDuplicateCheckEmail(e)}
								>
									{textInfo.button.duplicateCheck}
								</button>
								<div className="guide">
									<div className="inputGuide"></div>
									<div className="inputGuideWarn"></div>
									<div className="inputGuideChecked"></div>
								</div>
							</div>
						</li>
						<li className="inputDataItem">
							<div className="label">
								<span>{textInfo.member.data.nickname}</span>
							</div>
							<div className="userInput">
								<input
									type="text"
									ref={ref.nickname}
									className="nickname"
									name="nickname"
									id="nickname"
									placeholder={textInfo.member.placeholder.nickname}
									// onChange={( e ) => handlerChangeInput( e )}
									onKeyUp={(e) =>
										handlerKeyUpNickname(e, ref.checkNickname, null, handlerChangeInput)
									}
									onBlur={(e) => handlerBlurRequired(e)}
								/>
								<input
									type="hidden"
									ref={ref.checkNickname}
									name="checkNickname"
									id="checkNickname"
								/>
								<button
									className="button duplicateCheck duplicateCheckNickName"
									onClick={(e) =>
										handlerClickDuplicateCheckNickname(
											e,
											ref.nickname,
											ref.checkNickname,
											null,
											handlerChangeInput
										)
									}
								>
									{textInfo.button.duplicateCheck}
								</button>
								<div className="guide">
									<div className="inputGuide">{textInfo.member.guide.nickname.character}</div>
									<div className="inputGuideWarn"></div>
									<div className="inputGuideChecked"></div>
								</div>
							</div>
						</li>
						<li className="inputDataItem">
							<div className="label">
								<span>{textInfo.member.data.password}</span>
							</div>
							<div className="userInput">
								<input
									type="password"
									ref={ref.pw}
									className="pw"
									name="pw"
									id="pw"
									minLength={8}
									maxLength={20}
									pattern={REG_EXP.member.pw}
									onChange={(e) => handlerChangeInput(e)}
									onKeyUp={(e) => handlerKeyUpUserPW(e)}
									onBlur={(e) => handlerBlurRequired(e)}
									placeholder={textInfo.member.placeholder.password}
								/>
								<div className="pwShowHide close" onClick={(e) => handlerClickTogglePwType(e)} />
								<div className="guide">
									<div className="inputGuide">{textInfo.member.guide.password.characterLength}</div>
									<div className="inputGuide">{textInfo.member.guide.password.character}</div>
									<div className="inputGuideWarn"></div>
								</div>
							</div>
						</li>
						<li className="inputDataItem">
							<div className="label">
								<span>{textInfo.member.data.passwordCheck}</span>
							</div>
							<div className="userInput">
								<input
									type="password"
									ref={ref.pwCheck}
									className="pwCheck"
									name="pwCheck"
									id="pwCheck"
									onChange={(e) => handlerChangeInput(e)}
									onKeyUp={(e) => handlerKeyUpUserPWCheck(e, ref.pw)}
									onBlur={(e) => handlerBlurRequired(e)}
									placeholder={textInfo.member.placeholder.passwordCheck}
								/>
								<div className="pwShowHide close" onClick={(e) => handlerClickTogglePwType(e)} />
								<div className="guide">
									<div className="inputGuideWarn"></div>
								</div>
							</div>
						</li>
						<li className="inputDataItem">
							<div className="label">
								<span>{textInfo.member.data.phone}</span>
							</div>
							<div className="userInput">
								<input
									type="hidden"
									ref={ref.phone}
									className="phone"
									name="phone"
									id="phone"
									placeholder={textInfo.member.placeholder.phone}
								/>
								<input type="hidden" ref={ref.name} className="name" name="name" id="name" />
								<input type="hidden" className="birth" name="birth" id="birth" ref={ref.birth} />
								<input
									type="hidden"
									className="gender"
									name="gender"
									id="gender"
									ref={ref.gender}
								/>
								<input type="hidden" className="ci" name="ci" id="ci" ref={ref.ci} />
								<input type="hidden" ref={ref.checkVerify} name="checkVerify" id="checkVerify" />
								<button
									className="button verification"
									ref={ref.buttonVerify}
									onClick={(e) => handlerClickVerify(e)}
								>
									{textInfo.button.verify}
								</button>
								<div className="guide">
									<div className="inputGuideWarn"></div>
								</div>
							</div>
						</li>
						<li className="inputDataItem">
							<div className="label">
								<span>{textInfo.member.data.address}</span>
							</div>
							<div className="userInput">
								<div>
									<input
										type="text"
										ref={ref.postcode}
										className="postcode"
										name="postcode"
										id="postcode"
										readOnly={true}
										onChange={(e) => handlerChangeInput(e)}
										placeholder={textInfo.member.placeholder.postcode}
									/>
									<button
										className="button findPostCode"
										onClick={(e) =>
											execDaumPostCode(
												{
													postcode: "postcode",
													address1: "address1",
													address2: "address2",
													addressExtra: "addressExtra",
												},
												window.document.getElementById("layerPostCodeContent"),
												window.document.getElementById("layerPostCode")
											)
										}
									>
										{textInfo.button.findPostcode}
									</button>
								</div>
								<div>
									<input
										type="text"
										ref={ref.address1}
										className="address"
										name="address1"
										id="address1"
										readOnly={true}
										placeholder={textInfo.member.placeholder.address1}
									/>
								</div>
								<div>
									<input
										type="text"
										ref={ref.address2}
										className="address2"
										name="address2"
										id="address2"
										readOnly={true}
										placeholder={textInfo.member.placeholder.address2}
										onBlur={(e) => handlerChangeInput(e)}
									/>
									<div className="guide">
										<div className="inputGuideWarn"></div>
										<input
											type="hidden"
											className="addressExtra"
											name="addressExtra"
											id="addressExtra"
										/>
									</div>
								</div>
								<div className="guide">
									<div className="inputGuideWarn"></div>
								</div>
							</div>
						</li>
					</ul>
					<ul className="inputDataCover agreeTerms">
						<div className="title">
							<Image
								className="separator"
								srcSet="
								../../images/important.png 1x
								, ../../images/important@2x.png 2x
								, ../../images/important@3x.png 3x"
								alt={textInfo.member.data.required}
							/>
							<span className="text">{textInfo.member.data.agreeTerms}</span>
						</div>
						<li className="inputDataItem">
							<div className="label">
								<span>{textInfo.member.data.agreeTermsAndConditions}</span>
							</div>
							<div className="userInput">
								<input
									className="agreeAll"
									name="agreeAll"
									id="agreeAll"
									type="checkbox"
									ref={ref.termsAgreeAll}
									onClick={(e) => handlerClickTermsAgreeAll(e)}
								/>
								<label className="labelAgreeAll" htmlFor="agreeAll">
									{textInfo.member.data.terms.agreeAll}
								</label>
								<div className="description">{textInfo.member.data.terms.description}</div>
							</div>
						</li>
						<li className="inputDataItem termsDetail">
							<div className="label termLabel"></div>
							<div className="userInput">
								<input
									className="service"
									name="service"
									id="service"
									type="checkbox"
									ref={ref.termsService}
									onClick={(e) => handlerClickTermsService(e)}
									onChange={(e) => handlerChangeInput(e)}
								/>
								<label htmlFor="service">{textInfo.member.data.terms.termsService}</label>
								<button
									className="button showTerms service"
									onClick={(e) => handlerClickOpenModalTermsService(e)}
								>
									{textInfo.button.showTerms}
								</button>
							</div>
						</li>
						<li className="inputDataItem termsDetail">
							<div className="label termLabel"></div>
							<div className="userInput">
								<input
									className="privacy"
									name="privacy"
									id="privacy"
									type="checkbox"
									ref={ref.termsPrivacy}
									onClick={(e) => handlerClickTermsPrivacy(e)}
									onChange={(e) => handlerChangeInput(e)}
								/>
								<label htmlFor="privacy">{textInfo.member.data.terms.termsPrivacy}</label>
								<button
									className="button showTerms privacy"
									onClick={(e) => handlerClickOpenModalTermsPrivacyPolicy(e)}
								>
									{textInfo.button.showTerms}
								</button>
							</div>
						</li>
						<li className="inputDataItem termsDetail">
							<div className="label termLabel"></div>
							<div className="userInput">
								<input
									className="marketing"
									name="marketing"
									id="marketing"
									type="checkbox"
									ref={ref.termsMarketing}
									onClick={(e) => handlerClickTermsMarketing(e)}
									onChange={(e) => handlerChangeInput(e)}
								/>
								<label htmlFor="marketing">{textInfo.member.data.terms.termsMarketing}</label>
								<button
									className="button showTerms marketing"
									onClick={(e) => handlerClickOpenModalTermsMarketingPurposes(e)}
								>
									{textInfo.button.showTerms}
								</button>
							</div>
						</li>
						<li className="inputDataItem termsDetail">
							<div className="label termLabel"></div>
							<div className="userInput">
								<input
									className="benefit"
									name="benefit"
									id="benefit"
									type="checkbox"
									ref={ref.termsBenefit}
									onClick={(e) => handlerClickTermsBenefit(e)}
									onChange={(e) => handlerChangeInput(e)}
								/>
								<label htmlFor="benefit">{textInfo.member.data.terms.termsBenefit}</label>
							</div>
						</li>
						<li className="inputDataItem termsDetail childInput">
							<div className="label termLabel"></div>
							<div className="userInput checkboxInput">
								<input
									className="benefitSMS"
									name="benefitSMS"
									id="benefitSMS"
									type="checkbox"
									ref={ref.termsBenefitSMS}
									onClick={(e) => handlerClickTermsBenefitEach(e)}
									onChange={(e) => handlerChangeInput(e)}
								/>
								<label htmlFor="benefitSMS">{textInfo.member.data.terms.termsBenefitSMS}</label>
								<input
									className="benefitEMAIL"
									name="benefitEMAIL"
									id="benefitEMAIL"
									type="checkbox"
									ref={ref.termsBenefitEMAIL}
									onClick={(e) => handlerClickTermsBenefitEach(e)}
									onChange={(e) => handlerChangeInput(e)}
								/>
								<label htmlFor="benefitEMAIL">{textInfo.member.data.terms.termsBenefitEMAIL}</label>
							</div>
						</li>
						<li className="inputDataItem termsDetail">
							<div className="label termLabel"></div>
							<div className="userInput">
								<input
									className="olderThan14"
									name="olderThan14"
									id="olderThan14"
									type="checkbox"
									ref={ref.termsOlderThan14}
									onClick={(e) => handlerClickTermsOlderThan14(e)}
									onChange={(e) => handlerChangeInput(e)}
								/>
								<label htmlFor="olderThan14">{textInfo.member.data.terms.termsOlderThan14}</label>
							</div>
						</li>
					</ul>
					<button
						className="button inputCompleted"
						ref={ref.buttonInputCompleted}
						onClick={(e) => handlerClickSignUp(e)}
					>
						{textInfo.button.signUp}
					</button>
				</div>
			</div>
		</div>
	);
}

export default SignUp;
