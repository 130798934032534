/**
 * 섭취관리카드
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */
import useAlarmCalenderMonth from "src/hooks/myLinme/useAlarmCalenderMonth";
import useAlarmCalenderDate from "src/hooks/myLinme/useAlarmCalenderDate";
import AlarmCalendar from "src/pages/component/myLinme/alarm/alarmCalendar";
import DailyAlarmMain from "src/pages/component/myLinme/alarm/dailyAlarmMain";
const textInfo = require("src/data/textInfo.json");

import "moment/locale/ko"; //한글로 설정;
import "src/styles/myLinme/supplementManageCard.scss";

const SupplementManageCard = () => {
	const { data: alarmMonth, handleMonth } = useAlarmCalenderMonth();
	const { data: alarmDate, currentDate, handleDate } = useAlarmCalenderDate();

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="supplementManageCard">
			<div className="title">{textInfo.myLinme.supplementManage.title}</div>
			<div className="subTitle">{textInfo.myLinme.supplementManage.subTitle}</div>
			<div className="supplementManageContainer">
				<AlarmCalendar
					alarmDates={alarmMonth}
					afterSelected={handleDate}
					handleChangeMonth={(props) => handleMonth(props)}
				/>
				<DailyAlarmMain alarmData={alarmDate} targetDate={currentDate} />
			</div>
		</div>
	);
};
export default SupplementManageCard;
