/**
 * 마이페이지 - 공지사항
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";

import { API_RESULT_STATUS, PAGINATION_DEFAULT_OFFSET } from "src/data/constEnum";
import { getCouponList } from "src/api/member/coupon";
import { getLastPageNumber } from "src/utils/number";
import { setScrollTop } from "src/utils/linmeDisplay";

import CouponList from "src/pages/component/payment/couponList";
// import CouponCard from "src/pages/component/payment/couponCard";
import Pagination from "src/pages/component/pagination";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myPage/coupon.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const MyPageCoupon = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const [couponList, setCouponList] = useState(null);
	const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);
	const [paginationOffset, setPaginationOffset] = useState(PAGINATION_DEFAULT_OFFSET.DEFAULT);
	const [paginationTotalCount, setPaginationTotalCount] = useState(0);
	const [paginationLastPage, setPaginationLastPage] = useState(0);
	const [paginationIsLastPage, setPaginationIsLastPage] = useState(false);

	const inputRadioElementsName = "coupon";

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 다시 그리기
	 * @param {Number} pageNo
	 */
	const redraw = async (pageNo) => {
		await requestCouponList(pageNo);
		await setScrollTop();
	};

	/**
	 * 쿠폰 목록 조회
	 * @param {Number} pageNo
	 * @returns
	 */
	const requestCouponList = async (pageNo) => {
		try {
			let d = {
				offset: paginationOffset,
				page: pageNo,
			};
			const result = await getCouponList(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (result.data.content) {
					setCouponList(result.data.content);

					setPaginationCurrentPage(result.data.page);
					setPaginationTotalCount(result.data.totalCount);
					setPaginationIsLastPage(result.data.isLastPage);
					setPaginationLastPage(getLastPageNumber(result.data.totalCount, paginationOffset));
				}
				return result.data;
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (error) {
			window.linmeLog.log(error);
			return false;
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		const getData = async () => {
			await requestCouponList(1);
		};
		getData();
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<>
			<div className="couponCover">
				<div className="title">{textInfo.myPage.myCoupon.title}</div>
				<CouponList {...props} list={couponList} inputRadioElementsName={inputRadioElementsName} />
				{couponList && couponList.length && paginationLastPage > 1 ? (
					<Pagination
						{...{
							currentPage: paginationCurrentPage,
							offset: paginationOffset,
							totalCount: paginationTotalCount,
							lastPage: paginationLastPage,
							isLastPage: paginationIsLastPage,
							redraw: redraw,
						}}
					/>
				) : (
					<></>
				)}
			</div>
		</>
	);
};

export default MyPageCoupon;
