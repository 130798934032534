import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/**
 * 공지사항 목록
 * @param {Object} d
 * <code>
 * {
 * 		page: {Number}
 * 		, offset: {Number}
 * 		, keyword: {String|Null}
 * }
 * </code>
 */
export const getNoticeList = async (d) => {
	const url = `${API_PATHS.NOTICE}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
