/**
 * 마이페이지 - FAQ
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useRef, useState } from "react";

import { API_RESULT_STATUS, PAGINATION_DEFAULT_OFFSET } from "src/data/constEnum";
import { getFaqList } from "src/api/cs/faq";
import { getLastPageNumber } from "src/utils/number";
import { setScrollTop } from "src/utils/linmeDisplay";

import Pagination from "src/pages/component/pagination";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myPage/faq.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const MyPageFAQ = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const [faqList, setFaqList] = useState(null);
	const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);
	const [paginationOffset, setPaginationOffset] = useState(PAGINATION_DEFAULT_OFFSET.DEFAULT);
	const [paginationTotalCount, setPaginationTotalCount] = useState(0);
	const [paginationLastPage, setPaginationLastPage] = useState(0);
	const [paginationIsLastPage, setPaginationIsLastPage] = useState(false);
	const ref = {
		faqList: useRef(null),
	};

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * FAQ 1건 Toggle
	 * @param {Event} e
	 */
	const handlerClickToggleContent = (e) => {
		const ct = e.currentTarget;
		if (ct.classList.contains("on")) ct.classList.remove("on");
		else ct.classList.add("on");
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 다시 그리기
	 * @param {Number} pageNo
	 */
	const redraw = async (pageNo) => {
		await requestFaqList(pageNo);
		await setLayoutForRedraw();
	};

	/**
	 * FAQ 목록 조회
	 * @param {Number} pageNo
	 * @returns
	 */
	const requestFaqList = async (pageNo) => {
		try {
			let d = {
				offset: paginationOffset,
				page: pageNo,
			};
			const result = await getFaqList(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (result.data.content) {
					setFaqList(result.data.content);

					setPaginationCurrentPage(result.data.page);
					setPaginationTotalCount(result.data.totalCount);
					setPaginationIsLastPage(result.data.isLastPage);
					setPaginationLastPage(getLastPageNumber(result.data.totalCount, paginationOffset));
				}
				return result.data;
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (error) {
			window.linmeLog.log(error);
			return false;
		}
	};

	/**
	 * 페이지 변경 전 css 및 스크롤 원위치
	 */
	const setLayoutForRedraw = async () => {
		const ct = ref.faqList.current;
		const a = ct.querySelectorAll(".faqItem.on");
		if (a && a.length) {
			for (let i = 0; i < a.length; ++i) {
				a[i].classList.remove("on");
			}
		}
		await setScrollTop();
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		const getData = async () => {
			await requestFaqList(1);
		};
		getData();
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<>
			<div className="title">FAQ</div>
			<div className="faqCover">
				<ul className="faqList" ref={ref.faqList}>
					{faqList && faqList.length ? (
						faqList.map((item, index) => (
							<li className="faqItem" key={index} onClick={(e) => handlerClickToggleContent(e)}>
								<div className="faqQ">{item.title}</div>
								<div className="faqA">{item.content}</div>
							</li>
						))
					) : (
						<li className="noData" />
					)}
				</ul>
				{faqList && faqList.length ? (
					<Pagination
						{...{
							currentPage: paginationCurrentPage,
							offset: paginationOffset,
							totalCount: paginationTotalCount,
							lastPage: paginationLastPage,
							isLastPage: paginationIsLastPage,
							redraw: redraw,
						}}
					/>
				) : (
					<></>
				)}
			</div>
		</>
	);
};

export default MyPageFAQ;
