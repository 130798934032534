/**
 * 로그인 필요 시
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { Link } from "react-router-dom";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "./../../styles/member/shouldBeLogin.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

function ShouldBeLogin() {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="needLoginCover">
			<div className="title">로그인이 필요한 페이지입니다.</div>
			<div className="description">회원 가입하고 다양한 혜택을 받아보세요!</div>
			<Link to={pathList.member.login}>
				<div className="button clickable intoLinme">로그인/회원가입</div>
			</Link>
		</div>
	);
}

export default ShouldBeLogin;
