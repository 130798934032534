import { useEffect, useState } from "react";
import useModalStack from "src/hooks/useModalStack";
import useQueryParams from "src/hooks/useQueryParams";

import ModalCommonAlert from "src/pages/common/modalAlert";

import "src/styles/pagination.scss";

const textInfo = require("src/data/textInfo.json");

/**
 * 쿼리 파라미터를 사용하여 페이지 번호를 관리.
 *
 * @param {Object} props - 컴포넌트에 전달되는 props 객체
 * @param {number} props.totalPage - 전체 페이지 수
 */

const PaginationWithQuery = (props) => {
	const { totalPage } = props;

	const query = useQueryParams({ isSetPageFirst: false });
	const modalStack = useModalStack();

	// 현재 쿼리에서 페이지 번호를 가져오고, 숫자로 변환 (기본값은 1)
	const page = Number(query.get("page")) || 1;

	// 현재 페이지를 상태로 관리
	const [currentPage, setCurrentPage] = useState(page);

	/**
	 * 입력값 변경 시 호출되는 핸들러 (숫자만 입력 가능)
	 * @param {Event} e - 입력 이벤트
	 */
	const handlerChange = (e) => {
		if (e.target.value.trim() === "") {
			// 입력값이 비어있을 경우, 현재 페이지로 되돌림
			setCurrentPage(page);
		}
		// 숫자만 입력 가능하도록 설정
		e.target.value = e.target.value.replace(/[^0-9]/g, "");
		setCurrentPage(e.target.value);
	};

	/**
	 * 다음 페이지로 이동하는 핸들러
	 * - 마지막 페이지인 경우 알림 모달 표시
	 */
	const handlerClickNext = () => {
		if (totalPage === page) {
			// 마지막 페이지일 경우 알림 모달 표시
			modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.pagination.lastPage,
			});
			return;
		}
		// 다음 페이지로 이동
		setCurrentPage(page + 1);
		query.set("page", page + 1);
	};

	/**
	 * 이전 페이지로 이동하는 핸들러
	 * - 첫 페이지인 경우 알림 모달 표시
	 */
	const handlerClickPrev = () => {
		if (page === 1) {
			// 첫 페이지일 경우 알림 모달 표시
			return modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.pagination.firstPage,
			});
		}
		// 이전 페이지로 이동
		setCurrentPage(page - 1);
		query.set("page", page - 1);
	};

	/**
	 * 키보드 이벤트 핸들러 (Enter 키 입력 시 페이지 이동)
	 */
	const handlerKeyUp = (e) => {
		let key = e.key || e.keyCode;

		if (key === "Enter" || key === 13) {
			if (currentPage > totalPage) {
				// 입력된 페이지가 전체 페이지 수를 초과하는 경우 알림 모달 표시
				modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					html: `<div style="height: fit-content; line-height: 1.58; margin: 20px 20px 10px; text-align: left;">입력 가능한 페이지 번호는<br/>1 부터 ${totalPage} 사이의 정수입니다.</div>`,
				});

				return setCurrentPage(page);
			}
			// 입력된 페이지로 이동
			query.set("page", currentPage);
		}
	};

	/**
	 * 쿼리 파라미터에 따라 현재 페이지 상태를 업데이트
	 */
	useEffect(() => {
		setCurrentPage(page);
	}, [page]);

	return (
		<div className="pagination">
			<div className="button prev" onClick={(e) => handlerClickPrev(e)} />
			<input
				type="text"
				className="currentPage"
				name="currentPage"
				pattern="\d*"
				value={currentPage}
				onChange={(e) => handlerChange(e)}
				onKeyUp={(e) => handlerKeyUp(e)}
			/>
			<div className="of" />
			<span className="lastPage">{totalPage}</span>
			<div className="button next" onClick={(e) => handlerClickNext(e)} />
		</div>
	);
};

export default PaginationWithQuery;
