/**
 * 주문 교환
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useAuth from "src/hooks/useAuth";
import useModalStack from "src/hooks/useModalStack";
import { copyStructure } from "src/utils/obj";
import { checkAvailableVirtualNumber } from "src/api/order/order";
// import { getAddressList } from "src/api/member/addressBook";
// import { orderDetail } from "src/api/myShopping/order";
import { getDeliveryCompany } from "src/api/etc/delivery";
import { getClaimReason } from "src/api/myShopping/claim";
import { getChangeDeliveryPrice, orderChange } from "src/api/myShopping/change";
import { getRewardStatus } from "src/api/member/reward";
import {
	API_RESULT_STATUS,
	CLAIM_TYPE,
	COLOR,
	PAYMENT_METHOD_VISIBLE_LIST,
	PAYMENT_RESULT_STATUS,
	PRODUCT_OPTIONS_TYPE,
	RETURN_DELIVERY_TYPE,
	RETURN_DELIVERY_LIST,
	VERIFY_ENV,
} from "src/data/constEnum";
import {
	makeClaimReasonSelectOptions,
	makeDeliveryCompanySelectOptions,
} from "src/utils/linmeClaim";
import { setInnerSpinner, removeInnerSpinner } from "src/utils/linmeDisplay";
import {
	checkPaymentProcessing,
	handlerClickSetRewardAll,
	handlerClickCancelRewardAll,
	setCheckPaymentNo,
	validationReward,
} from "src/utils/linmePayment";
import { isMobile } from "react-device-detect";

import ProductOptionBox from "src/pages/component/order/productOptionBox";
import ModalCommonAlert from "src/pages/common/modalAlert";
import Image from "src/pages/component/common/image";
import MyPageAddressBook from "src/pages/myPage/addressBook";
import VirtualNumber from "src/pages/payment/modal/virtualNumber";
import PaymentIframe from "src/pages/payment/modal/paymentIframe";

import CheckOrderProduct from "src/pages/claim/checkOrderProduct";
import Reason from "src/pages/claim/reason";
import PickupMethod from "src/pages/claim/pickupMethod";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/claim/claimOrderChange.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

const htmlInfo = require("src/data/htmlInfo.json");
const textInfo = require("src/data/textInfo.json");

const ClaimOrderChange = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const auth = useAuth();
	const location = useLocation();
	const modalStack = useModalStack();
	const navigate = useNavigate();
	const [order, setOrder] = useState(null);
	const [dataList, setDataList] = useState(null);

	const [reasonNo, setReasonNo] = useState(-1);
	const [changeReasonList, setChangeReasonList] = useState(null);
	const [deliveryCompanyList, setDeliveryCompanyList] = useState(null);
	const [deliveryType, setDeliveryType] = useState(RETURN_DELIVERY_TYPE.COMPANY);
	const [deliveryCompanyNo, setDeliveryCompanyNo] = useState(-1);
	const [rewardData, setRewardData] = useState({});

	const [addressNo, setAddressNo] = useState(-1);
	const [receiveAddressNo, setReceiveAddressNo] = useState(-1);

	// const [ expectedRefundInfo, setExpectedRefundInfo ] = useState(null);
	const [totalPrice, setTotalPrice] = useState(null);
	const [itemNoList, setItemNoList] = useState(null);
	const [paymentNo, setPaymentNo] = useState(null);
	const ref = {
		address: {
			name: useRef(null),
			phone: useRef(null),
			postcode: useRef(null),
			address1: useRef(null),
			address2: useRef(null),
		},
		button: {
			applyRewardAll: useRef(null),
			cancelRewardAll: useRef(null),
		},
		checkVirtualNumber: useRef(null),
		checkVirtualNumberReceive: useRef(null),
		deliveryCompany: useRef(null),
		deliveryInfoDetail: useRef(null),
		invoiceNo: useRef(null),
		methodCover: useRef(null),
		paymentMethod: useRef(null),
		pickupAddress: useRef(null),
		reason: useRef(null),
		reasonDetail: useRef(null),
		receiveAddress: {
			name: useRef(null),
			phone: useRef(null),
			postcode: useRef(null),
			address1: useRef(null),
			address2: useRef(null),
		},
		receiveInfoDetail: useRef(null),
		reward: useRef(null),
	};
	const modalId = {
		payment: "modalClaimPayment", //결제모달;
		paymentSuccess: "modalCommonAlert", //결제 성공;
		paymentFail: "modalCommonAlert", //결제 실패;
	};

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 리워드 Blur
	 * @param {Event} e
	 */
	const handlerBlurReward = async (e) => {
		if (rewardData && rewardData.availableReward) {
			const resultValidation = await validationReward(
				totalPrice.deliveryPrice,
				null,
				rewardData.availableReward,
				ref.reward
			);
			await setRewardAmount();
			if (resultValidation.result === false) {
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: resultValidation.text,
				});
				return false;
			}
		}
	};

	/**
	 * 변경할 주소 선택 시
	 * @param {String} addressType//pickup, receive;
	 * @param {Number} changeAddressNo
	 * @param {Object} data
	 */
	const handlerChangeAddress = async (addressType, changeAddressNo, data) => {
		await modalStack.removeModal("modalChangePickupAddress");

		if (addressType === "pickup") {
			ref.address.name.current.innerText = data.name;
			ref.address.phone.current.innerText = "(" + data.phone + ")";
			ref.address.postcode.current.innerText = data.postcode;
			ref.address.address1.current.innerText = data.address1;
			ref.address.address2.current.innerText = data.address2;
			await setAddressNo(changeAddressNo);

			const result = await requestGetDeliveryPrice(null, changeAddressNo, null, null);
			if (result) {
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text:
						addressType === "pickup"
							? textInfo.alert.pickupAddressChanged
							: textInfo.alert.receiveAddressChanged,
				});
			}
		} else {
			ref.receiveAddress.name.current.innerText = data.name;
			ref.receiveAddress.phone.current.innerText = "(" + data.phone + ")";
			ref.receiveAddress.postcode.current.innerText = data.postcode;
			ref.receiveAddress.address1.current.innerText = data.address1;
			ref.receiveAddress.address2.current.innerText = data.address2;
			await setReceiveAddressNo(changeAddressNo);

			const result = await requestGetDeliveryPrice(null, null, changeAddressNo, null);
			if (result) {
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text:
						addressType === "pickup"
							? textInfo.alert.pickupAddressChanged
							: textInfo.alert.receiveAddressChanged,
				});
			}
		}
	};

	/**
	 * 수거방법 변경 시
	 * @param {Event} e
	 */
	const handlerChangeDeliveryType = async (e) => {
		const ct = e.currentTarget;
		await setDeliveryType(ct.value);
		switch (ct.value) {
			case RETURN_DELIVERY_TYPE.COMPANY: //택배수거요청;
				ref.pickupAddress.current.classList.add("on"); //회수지 정보;
				ref.deliveryCompany.current.classList.remove("on"); //택배사 목록;
				ref.deliveryInfoDetail.current.classList.remove("on"); //반송정보;
				ref.receiveInfoDetail.current.classList.remove("on"); //수령지 정보;
				break;
			case RETURN_DELIVERY_TYPE.DIRECT: //직접 발송;
				ref.pickupAddress.current.classList.remove("on"); //회수지 정보;
				ref.deliveryCompany.current.classList.add("on"); //택배사 목록;
				ref.deliveryInfoDetail.current.classList.add("on"); //반송정보;
				ref.receiveInfoDetail.current.classList.add("on"); //수령지 정보;
				break;
			case RETURN_DELIVERY_TYPE.LATER: //나중에 입력;
				ref.pickupAddress.current.classList.remove("on"); //회수지 정보;
				ref.deliveryCompany.current.classList.remove("on"); //택배사 목록;
				ref.deliveryInfoDetail.current.classList.remove("on"); //반송정보;
				ref.receiveInfoDetail.current.classList.add("on"); //수령지 정보;
				break;
			default:
				break;
		}

		await requestGetDeliveryPrice(ct.value, null, null, null);
	};

	/**
	 * 리워드 입력 시
	 * @param {Event} e
	 */
	const handlerChangeReward = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const resultValidation = await validationReward(
			totalPrice.deliveryPrice,
			null,
			rewardData.availableReward,
			ref.reward
		);
		await setRewardAmount();
		if (resultValidation.result === false) {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: resultValidation.text,
			});
			ref.paymentMethod.current.style.display = "block";
			return false;
		} else {
			if (ref.reward.current.value === totalPrice.deliveryPrice) {
				ref.paymentMethod.current.style.display = "none";
			}
		}
	};

	/**
	 * 안심번호 서비스 가능 여부 확인
	 * @param {Event} e
	 */
	const handlerChangeUseVirtualNumber = async (e) => {
		const ct = e.currentTarget;
		if (ct.checked) {
			//안심번호 서비스 가능 여부 확인;
			await requestCheckAvailableVirtualNumber();
		}
	};

	/**
	 * 주소지 변경 클릭 시
	 * @param {Event} e
	 * @param {String} addressType//pickup, receive;
	 */
	const handlerClickChangeAddress = (e, addressType) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.addModal({
			id: "modalChangePickupAddress",
			type: "alert",
			component: <MyPageAddressBook />,
			button: {
				iconClose: true,
			},
			isModal: true,
			addressType,
			selectedAddressNo: addressType === "pickup" ? addressNo : receiveAddressNo,
			afterSelected: handlerChangeAddress,
		});
	};

	/**
	 * 교환요청 클릭 시
	 * @param {Event} e
	 */
	const handlerClickChange = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const check = await validation();
		if (check) {
			await setInnerSpinner();
			const result = await requestOrderChange();
			if (result) {
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.completeOrderChangeRequest,
				});
				//클레임목록으로 이동;
				await navigate(pathList.myPage.shoppingClaim);
			}
		}
	};

	/**
	 * 안심번호 사용하지 못할 경우 출력 컨펌 확인 클릭 시
	 * @param {Event} e
	 */
	const handlerClickModalNotAvailableVirtualNumber = (e) => {
		ref.checkVirtualNumber.current.checked = false;
		ref.checkVirtualNumber.current.disabled = true;
		modalStack.removeModal("modalCommonConfirm");
	};

	/**
	 * 안심번호 사용하지 못할 경우 출력 컨펌 닫기 클릭 시
	 * @param {Event} e
	 */
	const handlerClickModalNotAvailableVirtualNumberClose = (e) => {
		modalStack.removeModal("modalCommonConfirm");
	};

	/**
	 * 교환 배송비 결제 실패 후 모달 확인 클릭 시
	 * @param {Event} e
	 */
	const handlerClickModalPaymentFail = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.removeModal(modalId.paymentFail);
		navigate(pathList.myPage.shoppingOrder);
	};

	/**
	 * 교환 배송비 결제 성공 후 모달 확인 클릭 시
	 * @param {Event} e
	 */
	const handlerClickModalPaymentSuccess = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.removeModal(modalId.paymentSuccess);
		navigate(pathList.myPage.shoppingClaim);
	};

	/**
	 * 안심번호 서비스 안내 보기
	 * @param {Event} e
	 */
	const handlerClickOpenModalVirtualNumber = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.addModal({
			id: "modalVirtualNumber",
			type: "alert",
			component: <VirtualNumber />,
		});
	};

	/**
	 * 리워드 전액 적용
	 * @param {Event} e
	 */
	const handlerClickRewardApplyAll = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const resultValidation = await handlerClickSetRewardAll(
			e,
			totalPrice.actualPrice,
			null,
			rewardData.availableReward,
			ref.reward,
			ref.button.applyRewardAll,
			ref.button.cancelRewardAll
		);
		await setRewardAmount();
		if (resultValidation.result === false) {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: resultValidation.text,
			});
			return false;
		}
	};

	/**
	 * 리워드 전액 취소
	 * @param {Event} e
	 */
	const handlerClickRewardCancelAll = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const resultValidation = await handlerClickCancelRewardAll(
			e,
			totalPrice.actualPrice,
			rewardData.availableReward,
			ref.reward,
			ref.button.applyRewardAll,
			ref.button.cancelRewardAll
		);
		await setRewardAmount();
		if (resultValidation.result === false) {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: resultValidation.text,
			});
			return false;
		}
	};

	/**
	 * 리워드 키 업
	 * @param {Event} e
	 */
	const handlerKeyUpReward = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		let key = e.key || e.keyCode;

		if (key === "Enter" || key === 13) {
			const resultValidation = await validationReward(
				totalPrice.deliveryPrice,
				null,
				rewardData.availableReward,
				ref.reward
			);
			await setRewardAmount();
			if (resultValidation.result === false) {
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: resultValidation.text,
				});
				return false;
			}
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 결제 실패 후 실행
	 * @param {String} messageType
	 * @param {String} message
	 */
	const afterPaymentFail = async (messageType, message) => {
		await removeInnerSpinner();
		if (message === textInfo.APIError.orderTimeExpires) {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.alert.orderTimeExpires,
			});
			await navigate(-1);
		} else {
			const o = {
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
			};
			if (messageType === "text") o.text = message;
			else o.html = message;
			await modalStack.addModal(o);
		}
	};

	/**
	 * 결제 완료 이후 실행
	 */
	const afterPaymentSuccess = async () => {
		await removeInnerSpinner();
		await modalStack.addModal({
			id: "modalCommonAlert",
			type: "alert",
			component: <ModalCommonAlert />,
			text: textInfo.alert.completeOrderChangeRequest,
		});
		await navigate(pathList.myPage.shoppingClaim);
	};

	/**
	 * 안심번호 사용 여부 확인 요청
	 * @returns
	 */
	const requestCheckAvailableVirtualNumber = async () => {
		try {
			const result = await checkAvailableVirtualNumber();
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (!result.data.isCheckAvailable) {
					await modalStack.addModal({
						id: "modalCommonConfirm",
						type: "confirm",
						component: <ModalCommonAlert />,
						html: htmlInfo.order.cannotUseVirtualNumber,
						button: {
							confirm: {
								text: textInfo.button.confirm,
								style: { width: "fit-content", padding: "3px 10px" },
								fn: handlerClickModalNotAvailableVirtualNumber,
							},
							cancel: {
								text: textInfo.button.cancel,
								style: { width: "fit-content", padding: "3px 10px", border: "unset" },
								fn: handlerClickModalNotAvailableVirtualNumberClose,
							},
						},
					});
				}
				return result.data;
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (error) {
			window.linmeLog.log(error);
			return false;
		}
	};

	/**
	 * 클레임 사유 조회
	 * @returns
	 */
	const requestGetClaimReason = async () => {
		try {
			const d = {
				type: CLAIM_TYPE.CHANGE,
			};
			const result = await getClaimReason(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				const list = makeClaimReasonSelectOptions(CLAIM_TYPE.CHANGE, result.data);
				await setChangeReasonList(list);
				return result.data;
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (error) {
			window.linmeLog.log(error);
			return false;
		}
	};

	/**
	 * 택배사 목록 요청
	 * @returns
	 */
	const requestGetDeliveryCompany = async () => {
		try {
			const result = await getDeliveryCompany();
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				const list = [
					{ label: textInfo.claim.pickup.pleaseSelectDeliveryCompany, value: "" },
				].concat(makeDeliveryCompanySelectOptions(result.data));
				// const list = await makeDeliveryCompanySelectOptions( result.data );
				await setDeliveryCompanyList(list);
				return result.data;
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (error) {
			window.linmeLog.log(error);
			return false;
		}
	};

	/**
	 * 주소, 요청사유( 과실여부 )에 따른 배송비 조회 요청
	 * @param {String|Null} changeDeliveryType
	 * @param {Number|Null} pickupAddressChangeNo//회수지 변경주소번호;
	 * @param {Number|Null} receiveAddressChangeNo//수령지 변경주소번호;
	 * @param {Number|Null} reasonNo
	 * @param {Number|Null} orderNo
	 * @param {Array|Null} items
	 */
	const requestGetDeliveryPrice = async (
		changeDeliveryType,
		pickupAddressChangeNo,
		receiveAddressChangeNo,
		changeReasonNo,
		orderNo,
		items
	) => {
		try {
			const d = {
				deliveryType: changeDeliveryType ? changeDeliveryType : deliveryType,
				itemNo: items ? items[0].itemNo : itemNoList[0],
				collectMemberAddressNo: pickupAddressChangeNo ? pickupAddressChangeNo : addressNo,
				receiveMemberAddressNo: receiveAddressChangeNo ? receiveAddressChangeNo : receiveAddressNo,
				orderNo: orderNo ? orderNo : order.orderNo,
				reasonNo: changeReasonNo ? changeReasonNo : reasonNo,
			};

			if (d.reasonNo === -1) {
				delete d.reasonNo;
			}

			const result = await getChangeDeliveryPrice(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				const o = await copyStructure(totalPrice ? totalPrice : {});
				o.deliveryPrice = result.data.amount;
				o.actualPrice = result.data.amount;
				await setTotalPrice(o);
				return result.data;
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (error) {
			window.linmeLog.log(error);
			return false;
		}
	};

	/**
	 * 회원 리워드 정보 요청
	 */
	const requestGetRewardStatus = async () => {
		const result = await getRewardStatus();
		if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
			await setRewardData(result.data);
		} else {
			window.linmeLog.log(result);
		}
	};

	/**
	 * 결제 후 message 로 받은 데이터로 후처리 ( alert )
	 * @param {Object} d
	 *
	 */
	const setDataAfterPayment = async (d) => {
		if (d.result === PAYMENT_RESULT_STATUS.SUCCESS) {
			await removeInnerSpinner();
			await modalStack.addModalAfterRemove(
				{
					id: modalId.paymentSuccess,
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.completeOrderChangeRequest,
					button: {
						ok: {
							text: textInfo.button.confirm,
							fn: handlerClickModalPaymentSuccess,
						},
					},
				},
				modalId.payment
			);
		} else if (d.result === PAYMENT_RESULT_STATUS.FAIL) {
			await removeInnerSpinner();
			if (d.message === textInfo.APIError.orderTimeExpires) {
				await modalStack.addModalAfterRemove(
					{
						id: modalId.paymentFail,
						type: "alert",
						component: <ModalCommonAlert />,
						html: htmlInfo.alert.orderTimeExpires,
						button: {
							ok: {
								text: textInfo.button.confirm,
								fn: handlerClickModalPaymentFail,
							},
						},
					},
					modalId.payment
				);
				// navigate( -1 );
			} else {
				await modalStack.addModalAfterRemove(
					{
						id: modalId.paymentFail,
						type: "alert",
						component: <ModalCommonAlert />,
						text: d.message,
						button: {
							ok: {
								text: textInfo.button.confirm,
								fn: handlerClickModalPaymentFail,
							},
						},
					},
					modalId.payment
				);
			}
		}
	};

	/**
	 * 교환 요청
	 */
	const requestOrderChange = async () => {
		const d = {
			env: isMobile ? VERIFY_ENV.WEB.MOBILE : VERIFY_ENV.WEB.PC, //결제환경;
			deliveryType: deliveryType, //수거방법;
			collectMemberAddressNo: addressNo, //회수지 주소 번호;
			isCheckCollectVirtualNumber: false, //회수지 안심번호 사용 여부;
			receiveMemberAddressNo: receiveAddressNo, //수령지 주소 번호;
			isCheckReceiveVirtualNumber: false, //수령지 안심번호 사용 여부;
			orderNo: order.orderNo, //주문번호;
			itemNo: itemNoList[0], //교환상품번호;
			reasonNo: reasonNo, //교환사유번호;
			quantity: dataList[0].quantity,
		};
		//결제 필요;
		if (
			totalPrice &&
			totalPrice.deliveryPrice &&
			ref.paymentMethod.current &&
			ref.paymentMethod.current.style.display !== "none"
		) {
			const method = ref.paymentMethod.current.querySelector(".orderChangeMethod:checked").value;
			d.paymentMethod = method; //결제유형;
		}
		if (ref.reasonDetail.current.value) d.content = ref.reasonDetail.current.value; //교환 사유 상세;
		if (deliveryType === RETURN_DELIVERY_TYPE.COMPANY) {
			//안심번호 사용여부를 "회수지 안심번호 사용여부"로 통일;
			d.isCheckCollectVirtualNumber = ref.checkVirtualNumber.current.checked;
			d.isCheckReceiveVirtualNumber = ref.checkVirtualNumber.current.checked;
			//수령지 주소 번호 삭제;
			delete d.receiveMemberAddressNo;
			//택배사 번호 삭제;
			delete d.companyNo;
			//송장번호 삭제;
			delete d.invoiceCode;
		} else if (deliveryType === RETURN_DELIVERY_TYPE.DIRECT) {
			//수령지 안심번호 사용여부;
			d.isCheckReceiveVirtualNumber = ref.checkVirtualNumberReceive.current.checked;
			//택배사 번호 설정;
			d.companyNo = deliveryCompanyNo;
			//송장번호 설정;
			d.invoiceCode = ref.invoiceNo.current.value;
			//회수지 주소 번호 삭제;
			// delete d.collectMemberAddressNo;
		} else if (deliveryType === RETURN_DELIVERY_TYPE.LATER) {
			//수령지 안심번호 사용여부;
			d.isCheckReceiveVirtualNumber = ref.checkVirtualNumberReceive.current.checked;
			//택배사 번호 삭제;
			delete d.companyNo;
			//송장번호 삭제;
			delete d.invoiceCode;
			//회수지 주소 번호 삭제;
			// delete d.collectMemberAddressNo;
		}

		try {
			const result = await orderChange(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (result.data.url) {
					await modalStack.addModal({
						id: modalId.payment,
						type: "none",
						component: <PaymentIframe {...result.data} />,
						setDataAfterPayment: setDataAfterPayment,
					});
					/*/
					const paymentPopup = window.open( pathList.payment.process + "?url=" + result.data.url, "paymentPopup", "width=820, height=620" );
					if ( !paymentPopup ) {
						await modalStack.addModal({
							id: "modalCommonAlert"
							, type: "alert"
							, component: <ModalCommonAlert />
							, text: textInfo.alert.pleaseDisablePopupBlocker
						});
						return false;
					}
					if ( paymentPopup.window ) {
						// paymentPopup.window.onbeforeunload = afterPayment;
						const form = window.document.createElement( "form" );
						const elUrl = window.document.createElement( "hidden" );
						elUrl.value = result.data.url;
						form.appendChild( elUrl );
						form.target = "paymentPopup";
						form.action = pathList.payment.process;
						// form.submit();

						window.paymentPopup = paymentPopup;
						await setCheckPaymentNo( result.data.paymentNo );
						await checkPaymentProcessing( afterPaymentSuccess, afterPaymentFail );//결제창이 닫혔는지 확인하는 타이머 설정;
					}
					//*/
				} else {
					//결제금액이 존재하지 않는 경우 url은 null임;
					// window.linmeLog.log( result );
					if (
						!d.paymentMethod &&
						result.data.hasOwnProperty("amount") &&
						result.data.amount === 0
					) {
						//결제 금액이 존재하지 않는 정상건;
						await modalStack.addModal({
							id: modalId.paymentSuccess,
							type: "alert",
							component: <ModalCommonAlert />,
							text: textInfo.alert.completeOrderChangeRequest,
							button: {
								ok: {
									text: textInfo.button.confirm,
									fn: handlerClickModalPaymentSuccess,
								},
							},
						});
						//클레임목록으로 이동;
						await navigate(pathList.myPage.shoppingClaim);
					} else {
						if (result.message === textInfo.APIError.orderTimeExpires) {
							await modalStack.addModal({
								id: "modalCommonAlert",
								type: "alert",
								component: <ModalCommonAlert />,
								html: htmlInfo.alert.orderTimeExpires,
							});
							//주문목록으로 이동;
							await navigate(pathList.myPage.shoppingOrder);
						} else {
							await modalStack.addModal({
								id: "modalCommonAlert",
								type: "alert",
								component: <ModalCommonAlert />,
								html: htmlInfo.error.payment,
							});
							//주문목록으로 이동;
							await navigate(pathList.myPage.shoppingOrder);
						}
					}
					return false;
				}
			}
		} catch (err) {
			window.linmeLog.error(err);
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.error.payment,
			});
			//주문목록으로 이동;
			await navigate(pathList.myPage.shoppingOrder);
			return false;
		} finally {
			await removeInnerSpinner();
		}
	};

	/**
	 * 상품 금액 설정
	 * @param {Array} a
	 */
	const setProductPrice = async (a) => {
		const o = await copyStructure(totalPrice ? totalPrice : {});
		let productPrice = 0;
		let i = 0;
		const iLen = a.length;
		let io; //a[ i ];
		for (; i < iLen; ++i) {
			io = a[i];
			productPrice += io.totalPrice;
		}
		o.productPrice = productPrice;
		await setTotalPrice(o);
	};

	/**
	 * 입력된 리워드 적용
	 * @returns {Number}
	 */
	const setRewardAmount = async () => {
		const reward = ref.reward.current.value.replace(/[^0-9]/g, ""); //숫자만 적용;

		const o = await copyStructure(totalPrice ? totalPrice : {});
		o.useReward = parseInt(reward);
		if (o.deliveryPrice) {
			o.actualPrice = o.deliveryPrice - o.useReward;

			if (o.actualPrice === 0) {
				ref.paymentMethod.current.style.display = "none";
			} else {
				ref.paymentMethod.current.style.display = "flex";
			}
		}
		await setTotalPrice(o);

		/*/
		const result = await requestChangeOrder( null, null, parseInt( reward ) );
		if( result ) {
			await modalStack.addModal({
				id: "modalCommonAlert"
				, type: "alert"
				, component: <ModalCommonAlert />
				, text: textInfo.alert.memberRewardApplied
			});
		}
		//*/
	};

	/**
	 * 택배사 선택 후 적용
	 * @param {Object} o
	 */
	const setSelectedDeliveryCompany = (o) => {
		setDeliveryCompanyNo(o.value);
	};

	/**
	 * 주문 교환 사유 선택 후 적용
	 * @param {Object} o
	 */
	const setSelectedReason = async (o) => {
		const ct = ref.reason.current;
		ct.value = "";

		if (o.value) {
			//교환 사유 설정;
			await setReasonNo(parseInt(o.value));
			//배송비 조회;
			await requestGetDeliveryPrice(null, null, null, parseInt(o.value));
		}
	};

	/**
	 * validation
	 * @returns {Boolean}
	 */
	const validation = async () => {
		//교환사유 선택 확인;
		if (reasonNo === -1) {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.pleaseSelectReasonOrderChange,
			});
			return false;
		}
		//수거방법 직접발송 시;
		if (deliveryType === RETURN_DELIVERY_TYPE.DIRECT) {
			//택배사 선택 확인;
			if (deliveryCompanyNo === -1) {
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.pleaseSelectDeliveryCompany,
				});
				return false;
			}
			//송장번호 입력 확인;
			if (!ref.invoiceNo.current.value) {
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.pleaseInputInvoiceNo,
				});
				return false;
			}
		}
		//결제금액 존재 시 결제방법 선택;
		if (totalPrice && totalPrice.actualPrice) {
			const elMethod = window.document.querySelector(".orderChangeMethod:checked");
			if (!elMethod) {
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.pleaseSelectPaymentMethod,
				});
				return false;
			}
		}

		return true;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		const setData = async () => {
			const loginCheck = await auth.loginCheck();
			if (loginCheck) {
				const items = location.state?.items;
				const order = location.state?.order;
				const itemNoList = location.state?.itemNoList;
				if (!items && !order && !itemNoList) {
					await modalStack.addModal({
						id: "modalCommonAlert",
						type: "alert",
						component: <ModalCommonAlert />,
						text: textInfo.alert.errorNoExistsData,
					});
					await navigate(pathList.main);
					return false;
				}
				// window.linmeLog.log(JSON.stringify( items, null, 4 ));
				await setDataList(items); //교환상품정보;
				await setOrder(order); //주문정보;
				await setItemNoList(itemNoList);
				await requestGetClaimReason(); //주문교환사유;
				// await requestGetAddress();//회원 대표 배송지;
				// await requestOrderDetail( order.orderNo );//주문 정보 - 배송지 정보를 설정하기 위함;
				const priceAndDeliveryData = await requestGetDeliveryPrice(
					null,
					null,
					null,
					null,
					order.orderNo,
					items
				);
				if (priceAndDeliveryData && priceAndDeliveryData.collectAddress && ref) {
					//회수지 정보;
					const o = priceAndDeliveryData.collectAddress;
					ref.address.name.current.innerText = o.name;
					ref.address.phone.current.innerText = "(" + o.phone + ")";
					ref.address.postcode.current.innerText = o.postcode;
					ref.address.address1.current.innerText = o.address1;
					ref.address.address2.current.innerText = o.address2;
					await setAddressNo(o.addressNo);

					//수령지 정보;
					ref.receiveAddress.name.current.innerText = o.name;
					ref.receiveAddress.phone.current.innerText = "(" + o.phone + ")";
					ref.receiveAddress.postcode.current.innerText = o.postcode;
					ref.receiveAddress.address1.current.innerText = o.address1;
					ref.receiveAddress.address2.current.innerText = o.address2;
					await setReceiveAddressNo(o.addressNo);
				}
				await requestGetDeliveryCompany(); //택배사 목록;
				// await requestGetRewardStatus();//리워드 정보;
				await setProductPrice(items);
			}
		};
		setData();
	}, [location.state]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="container underTopMenu claimContainer">
			<div className="contentsBackground">
				{/* <div className="contents">
					<ClaimProgressStage {...{
						on: "step1"
						, text: {
							step1: textInfo.orderChange.stage.step1
							, step2: textInfo.orderChange.stage.step2
						}
					}}/>
				</div> */}
				<div className="contents">
					<div className="changeData">
						<div className="title">{textInfo.orderChange.title}</div>
						<div>
							<CheckOrderProduct claimType={CLAIM_TYPE.CHANGE} dataList={dataList} />
							<Reason
								refObject={ref}
								type={CLAIM_TYPE.CHANGE}
								reasonList={changeReasonList}
								reasonNo={reasonNo}
								setSelectedReason={setSelectedReason}
							/>
							<PickupMethod
								refObject={ref}
								handlerChangePickupMethod={handlerChangeDeliveryType}
								handlerClickOpenModalVirtualNumber={handlerClickOpenModalVirtualNumber}
								handlerUseVirtualNumber={handlerChangeUseVirtualNumber}
								handlerChangeAddress={handlerClickChangeAddress}
								deliveryCompanyList={deliveryCompanyList}
								deliveryCompanyNo={deliveryCompanyNo}
								setSelectedDeliveryCompany={setSelectedDeliveryCompany}
							/>
							<ul className="returnedList receiveInfoDetail" ref={ref.receiveInfoDetail}>
								<li className="returnedItem title">{textInfo.claim.receive.title}</li>
								<li className="returnedItem">
									<div className="label">{textInfo.claim.receive.receiveAddress}</div>
									<div className="data receiveAddress">
										<div className="info">
											<span ref={ref.receiveAddress.name}></span>
											<span ref={ref.receiveAddress.postcode}></span>
											<span ref={ref.receiveAddress.address1}></span>
											<span ref={ref.receiveAddress.address2}></span>
											<span ref={ref.receiveAddress.phone}></span>
											<div className="virtualNumberService">
												<span
													className="virtualNumberServiceText"
													onClick={(e) => handlerClickOpenModalVirtualNumber(e)}
												>
													{textInfo.payment.deliveryInfo.safeNumberService}
												</span>
												<input
													className="useVirtualNumber"
													name="useVirtualNumberForOrderChange"
													id="useVirtualNumberForOrderChangeReceive"
													type="checkbox"
													defaultChecked={false}
													onChange={(e) => {
														handlerChangeUseVirtualNumber(e);
													}}
													ref={ref.checkVirtualNumberReceive}
												/>
												<label htmlFor="useVirtualNumberForOrderChangeReceive">&nbsp;</label>
											</div>
										</div>
										<div className="button">
											<div
												className="changeDeliveryInfo"
												onClick={(e) => handlerClickChangeAddress(e, "receive")}
											>
												{textInfo.button.changeInfo}
											</div>
										</div>
									</div>
								</li>
							</ul>
						</div>
						{totalPrice && totalPrice.deliveryPrice ? (
							<div className="contents paymentInfo" ref={ref.methodCover}>
								<div className="changeData paymentMethod">
									<div className="title">{textInfo.payment.payMethod.title}</div>
									{/* <ul className="paymentDiscountList">
								<li className="paymentDiscountItem">
									<div className="label">{textInfo.payment.reward.title}</div>
									<div className="data">
										<input type="text"
											className={ "discountReward " + (rewardData && rewardData.availableReward ? "" : "readOnly" ) }
											defaultValue="0"
											ref={ref.reward}
											readOnly={ rewardData && rewardData.availableReward ? false : true }
											onChange={(e)=>handlerChangeReward(e)}
											onKeyUp={(e)=>handlerKeyUpReward(e)}
											onBlur={(e)=>handlerBlurReward(e)}
											/>
										<span className="rewardUnit">{textInfo.common.rewardUnit}</span><span className="rewardAll">{ ( rewardData && rewardData.availableReward ? rewardData.availableReward.toLocaleString() : 0 )}<span className="rewardUnit">{textInfo.common.rewardUnit}</span></span>
										<div className="buttonRewardCover">
										{ ( rewardData && rewardData.availableReward ? <div className="button applyRewardAll on"
											ref={ref.button.applyRewardAll}
											onClick={(e)=>handlerClickRewardApplyAll(e)}
											>{textInfo.payment.reward.useAll}</div> : <></> )}
										<div className="button cancelRewardAll"
											ref={ref.button.cancelRewardAll}
											onClick={(e)=>handlerClickRewardCancelAll(e)}
											>{textInfo.payment.reward.cancelAll}</div>
										</div>
									</div>
								</li>
							</ul>
							<ul className="rewardDescriptionList">
								<li className="rewardDescriptionItem">{textInfo.claim.payment.useRewardGuide.guide1}</li>
							</ul> */}
									<ul className="paymentMethodList" ref={ref.paymentMethod}>
										{PAYMENT_METHOD_VISIBLE_LIST.map((method, index) => (
											<li className="paymentMethodItem methodBox" key={index}>
												<input
													className="orderChangeMethod"
													name="orderChangeMethod"
													id={"orderChangePayMethod" + method.value.toLowerCase()}
													type="radio"
													value={method.value}
													defaultChecked={method.value === "CARD"}
												/>
												<label
													className="orderChangeMethod"
													htmlFor={"orderChangePayMethod" + method.value.toLowerCase()}
												>
													{method.label}
												</label>
											</li>
										))}
									</ul>
								</div>
							</div>
						) : (
							<></>
						)}
					</div>
					<div className="changeInfoContainer">
						<div className="changeInfo">
							<div className="title">{textInfo.orderChange.orderChangeInfo}</div>
							<ul className="changeInfoList">
								<li className="changeInfoItem">
									<div className="label">{textInfo.order.productPrice}</div>
									<div className="price productPrice">
										{totalPrice && totalPrice.productPrice
											? totalPrice.productPrice.toLocaleString()
											: 0}
										<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
									</div>
								</li>
								{/* {expectedRefundInfo && expectedRefundInfo.coupon ? <li className="changeInfoItem coupon">
									<div className="label">{textInfo.order.discountPrice}</div>
									<div className="price discountPrice">{ 0 }<span className="currencyUnit">{textInfo.common.currencyUnit}</span></div>
								</li> : <></> }
								{expectedRefundInfo && ( expectedRefundInfo.directReward || expectedRefundInfo.tobeReward ) ? <>
								<li className="changeInfoItem reward">
									<div className="label">{textInfo.order.discountReward}</div>
									<div className="price discountReward">{(expectedRefundInfo.directReward + expectedRefundInfo.tobeReward).toLocaleString()}<span className="rewardUnit">{textInfo.common.rewardUnit}</span></div>
								</li>
								</> : <></> }
								{expectedRefundInfo && ( expectedRefundInfo.directReward || expectedRefundInfo.tobeReward ) ? <>
								<li className="changeInfoItem changeUsedItem">
									<div className="label">{textInfo.orderCancel.cancelUsedReward}</div>
									<div className="price cancelUsedReward">{(expectedRefundInfo.directReward + expectedRefundInfo.tobeReward).toLocaleString()}<span className="rewardUnit">{textInfo.common.rewardUnit}</span></div>
								</li>
								<li className="changeInfoItem discountDetail">
									<div className="label">{textInfo.orderCancel.cancelUsedRewardDirect}</div>
									<div className="price">{expectedRefundInfo.directReward.toLocaleString()}<span className="rewardUnit">{textInfo.common.rewardUnit}</span></div>
								</li>
								<li className="changeInfoItem discountDetail">
									<div className="label">{textInfo.orderCancel.cancelUsedRewardTobe}</div>
									<div className="price">{expectedRefundInfo.tobeReward.toLocaleString()}<span className="rewardUnit">{textInfo.common.rewardUnit}</span></div>
								</li>
								</> : <></> }
								{expectedRefundInfo && expectedRefundInfo.isCheckTobeCoupon ? <li className="changeInfoItem changeUsedItem">
									<div className="label">{textInfo.orderCancel.changeUsedCoupon}</div>
									<div className="price changeUsedCoupon">{"-"}</div>
								</li> : <></> } */}
								{totalPrice && totalPrice.deliveryPrice ? (
									<li className="changeInfoItem deliveryPrice">
										<div className="label">{textInfo.orderChange.deliveryPrice}</div>
										<div className="price deliveryPrice">
											<Image
												srcSet="
												/images/icon/signPlus.png 1x
												, /images/icon/signPlus@2x.png 2x
												, /images/icon/signPlus@3x.png 3x
											"
												alt={textInfo.orderChange.deliveryPrice}
											/>
											{totalPrice && totalPrice.deliveryPrice
												? totalPrice.deliveryPrice.toLocaleString()
												: 0}
											<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
										</div>
									</li>
								) : (
									<></>
								)}
								{totalPrice && totalPrice.useReward ? (
									<li className="changeInfoItem useReward">
										<div className="label">{textInfo.order.usedReward}</div>
										<div className="price useReward">
											<Image
												srcSet="
												/images/icon/signMinusDiscount.png 1x
												, /images/icon/signMinusDiscount@2x.png 2x
												, /images/icon/signMinusDiscount@3x.png 3x
											"
												alt={textInfo.order.usedReward}
											/>
											{totalPrice && totalPrice.useReward
												? totalPrice.useReward.toLocaleString()
												: 0}
											<span className="rewardUnit">{textInfo.common.rewardUnit}</span>
										</div>
									</li>
								) : (
									<></>
								)}
							</ul>
							<div className="changeTotal">
								<div className="label">{textInfo.claim.price.actualPaymentPrice}</div>
								<div className="price orderPriceTotal">
									{totalPrice && totalPrice.actualPrice
										? totalPrice.actualPrice.toLocaleString()
										: 0}
									<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
								</div>
							</div>
							<div className="button clickable change cover" onClick={(e) => handlerClickChange(e)}>
								<div className="buttonInner">
									<div className="label">{textInfo.button.claimRequestChange}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ClaimOrderChange;
