import { useEffect, useState } from "react";

import { productListCategory } from "src/api/product/category";
import useQueryParams from "src/hooks/useQueryParams";
import useModalStack from "src/hooks/useModalStack";

import ModalCommonAlert from "src/pages/common/modalAlert";
import { removeSpinner, setScrollTop, setSpinner } from "src/utils/linmeDisplay";
import { PAGINATION_DEFAULT_OFFSET, PRODUCT_LIST_ORDER } from "src/data/constEnum";
const htmlInfo = require("src/data/htmlInfo.json");

/**
 * useProductSearchByCategory 훅
 * - 특정 카테고리의 상품 목록을 검색하고 관리하는 커스텀 훅.
 * - 쿼리 파라미터를 기반으로 검색 요청을 수행하고, 검색 결과를 상태로 관리.
 *
 * @returns {Object} 검색된 상품 목록과 총 페이지 수를 반환.
 *  - productList: 검색된 상품 목록 배열
 *  - totalPage: 총 페이지 수
 */
const useProductSearchByCategory = () => {
	const [productList, setProductList] = useState([]); // 검색된 상품 목록
	const query = useQueryParams();
	const modalStack = useModalStack();
	const queryObj = query.queryStringToObject();

	const {
		productType = 1, // 상품 유형 (기본값: 1)
		categoryNo = -1, // 카테고리 번호 (기본값: -1)
		page = 1, // 현재 페이지 번호 (기본값: 1)
		sort = PRODUCT_LIST_ORDER[0].value, // 정렬 기준 (기본값: 첫 번째 정렬 옵션)
	} = queryObj;

	const offset = PAGINATION_DEFAULT_OFFSET.PRODUCT_LIST;
	const [totalPage, setTotalPage] = useState(1);

	/**
	 * 카테고리별 상품 목록을 요청하는 함수
	 */
	const requestProductListCategory = async () => {
		setSpinner();

		const req = {
			typeNo: productType, // 선택된 상품 유형
			categoryNoList: categoryNo, // 선택된 카테고리 번호
			page: page, // 현재 페이지
			offset: offset, // 페이지당 상품 수
			sort: sort, // 정렬 기준
		};

		try {
			// API 호출을 통해 상품 목록을 가져옴
			const result = await productListCategory(req);
			setProductList(result.data.content); // 가져온 상품 목록을 상태로 설정
			setTotalPage(Math.ceil(result?.data?.totalCount / offset)); // 총 페이지 수 계산
			setScrollTop(); // 화면을 최상단으로 스크롤
		} catch (err) {
			// 에러 발생 시 로그를 남기고 알림 모달을 표시
			window.linmeLog.error(err);
			modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.error.dataCall, // 오류 메시지
			});
			return false;
		} finally {
			removeSpinner(); // 로딩 스피너 제거
		}
	};

	/**
	 * 의존성 배열의 값이 변경될 때마다 상품 목록을 다시 요청
	 * - 상품 유형, 카테고리 번호, 페이지 번호, 정렬 기준이 변경될 때마다 실행.
	 */
	useEffect(() => {
		requestProductListCategory();
	}, [productType, categoryNo, page, sort]);

	// 검색된 상품 목록과 총 페이지 수를 반환
	return { productList, totalPage };
};

export default useProductSearchByCategory;
