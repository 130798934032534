/**
 * 장바구니
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import useAuth from "src/hooks/useAuth";

import {
	cartProductList,
	deleteOptionFromCart,
	deleteProductFromCart,
	getSelectedProductPriceFromCart,
} from "src/api/order/cart";
import { createOrderProductFromCart } from "src/api/order/order";
import { API_RESULT_STATUS, ORDER_TYPE } from "src/data/constEnum";
import useModalStack from "src/hooks/useModalStack";
import {
	removeInnerSpinner,
	removeSpinner,
	setInnerSpinner,
	setSpinner,
} from "src/utils/linmeDisplay";

import ModalCommonAlert from "src/pages/common/modalAlert";
import CartProductList from "src/pages/component/cart/cartProductList";
import CartProgressStage from "src/pages/component/cart/cartProgressStage";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/cart/cart.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const Cart = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const auth = useAuth();
	const navigate = useNavigate();
	const modalStack = useModalStack();
	const ref = { selectAll: useRef(null) };

	const [productList, setProductList] = useState(null);
	const [cartTotalContainerPosition, setCartTotalContainerPosition] = useState(0);
	const [layerDiscountDetail, setLayerDiscountDetail] = useState(false);
	const [checkedProduct, setCheckedProduct] = useState([]);
	const [totalInfo, setTotalInfo] = useState({
		productCount: 0, //주문 대상 상품 수;
		deliveryPrice: 0, //총 배송비;
		couponDiscount: 0, //쿠폰 할인금액;
		totalProductPrice: 0, //총 선택상품금액;
		totalDiscountPrice: 0, //총 할인금액;
		finalPrice: 0, //전체 주문금액;
		savedReward: 0, //적립 예정 리워드;
	});

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * "전체선택" 클릭 시
	 * @param {Event} e
	 */
	const handlerChangeSelectAll = (e) => {
		/*/
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();
		//*/

		// 판매중인 상품 목록
		const saleProductNos = productList
			?.flatMap((product) => product.products) // 모든 상품을 하나의 배열로 만들기
			?.filter((product) => product.status === "SALE") // 판매중인 상품만 필터링
			?.map((product) => product.productNo); // productNo만 추출

		//선택 가능 상품이 없는 경우;
		if (saleProductNos.length === 0) {
			modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.noExistsSelectTarget,
			});
			return false;
		}

		// 이미 전체 선택이 되어있는 경우 전체 선택 해제;
		if (checkedProduct?.length === saleProductNos.length) {
			setCheckedProduct([]);
			return false;
		}

		setCheckedProduct(saleProductNos);
		return;
	};

	/**
	 * 1개 상품 선택/해제 시
	 * @param {Event} e
	 */
	const handlerChangeSelected = (e) => {
		const el = e.currentTarget;
		const productNo = parseInt(el.value);
		const checked = el.checked;
		if (checked) {
			setCheckedProduct(checkedProduct.concat(productNo));
		} else {
			setCheckedProduct(checkedProduct.filter((item) => item !== productNo));
		}
	};

	/**
	 * 여러개 상품 선택/해제 시 (묶음배송)
	 */
	const handlerChangeSelectedMultiple = (checked, selectedList) => {
		if (checked) {
			setCheckedProduct(checkedProduct.concat(selectedList));
		} else {
			setCheckedProduct(checkedProduct.filter((item) => !selectedList.includes(item)));
		}
	};

	/**
	 * 선택 상품 삭제
	 * @param {Event} e
	 */
	const handlerClickDeleteSelected = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (checkedProduct.length === 0) {
			modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.selectTargetDelete,
			});
			return false;
		}

		await requestDeleteProductFromCart(checkedProduct);
	};

	/**
	 * 상품 정보 수정시 (옵션 수량)
	 */
	const afterOptionChanged = async (updatedProductData) => {
		const result = productList.map((item) => ({
			...item,
			products: item.products.map((product) =>
				product.productNo === updatedProductData.productNo ? updatedProductData : product
			),
		}));

		if (result?.length) await setProductList(result);
	};

	/**
	 * 주문하기 클릭 시
	 * @param {Event} e
	 */
	const handlerClickOrder = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (checkedProduct.length === 0) {
			//선택한 상품이 없는 경우;
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.selectTargetOrder,
			});
			return false;
		} else {
			let cartNoList = [];

			productList.forEach((order) => {
				// 묶음배송
				order.products.forEach((product) => {
					// 묶음배송 내 상품
					if (checkedProduct.includes(product.productNo)) {
						// 선택한 상품인 경우
						product.required.forEach((option) => {
							// 필수 옵션
							cartNoList.push(option.cartNo);
						});
						product.additional.forEach((option) => {
							// 추가 옵션
							cartNoList.push(option.cartNo);
						});
					}
				});
			});

			await requestOrder(cartNoList);
		}
	};

	/**
	 * 할인 상세보기 toggle
	 * @param {Event} e
	 */
	const handlerClickDiscountDetail = (e) => {
		setLayerDiscountDetail(!layerDiscountDetail);
	};

	/**
	 * 스크롤 이벤트 발생 시
	 * @param {ScrollEvent} e
	 */
	const handlerScroll = (e) => {
		const min = 300;
		const max = window.document.body?.scrollHeight - 300 - 385; //300: footer 높이, 385: 전체합계 박스 높이;
		let position = 0;
		if (window.scrollY > min && window.scrollY < max) {
			position = window.scrollY - 200;
		} else if (window.scrollY >= max) {
			position = max;
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 *  금액 정보 조회 API
	 * @param {Array} a
	 */
	const requestCartPrice = async (a) => {
		try {
			const d = { productNoList: a };
			const result = await getSelectedProductPriceFromCart(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				const data = result.data;
				return data;
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	/**
	 * 장바구니 상품 목록 조회 API
	 */
	const requestGetProductList = async () => {
		try {
			await setSpinner();

			const result = await cartProductList();
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				const data = result.data;
				await setProductList(data);
				// 장바구니 상품 개수 업데이트
				const totalProducts = data.reduce((acc, item) => acc + item.products?.length, 0);
				await auth.modifyUserData({ cartCount: totalProducts });
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			// navigate( pathList.member.login, { state: { lastPath: pathList.cart } });
			window.linmeLog.log(error);
		} finally {
			await removeSpinner();
		}
	};

	/**
	 * 장바구니 상품 삭제 API
	 * @param {Array} a
	 * @returns
	 */
	const requestDeleteProductFromCart = async (a) => {
		try {
			await setInnerSpinner();

			const d = { productNoList: a };
			const result = await deleteProductFromCart(d);

			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				await requestGetProductList(); // 상품목록 조회 호출
				await setCheckedProduct((prev) => prev.filter((item) => !a.includes(item))); // 체크해제
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.deleted,
				});
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		} finally {
			await removeInnerSpinner();
		}
	};

	/**
	 * 장바구니 상품 옵션 삭제 API
	 * @param {Number} cartNo
	 */
	const requestDeleteOptionFromCart = async (cartNo) => {
		try {
			const result = await deleteOptionFromCart(cartNo);

			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				const data = result.data;
				await requestGetProductList(); // 상품목록 조회 호출
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.changedProductOption,
				});
				return data;
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	/**
	 * 주문서 생성 API
	 * @param {Array} cartNoList
	 * @returns
	 */
	const requestOrder = async (cartNoList) => {
		try {
			let d = {
				type: ORDER_TYPE.CART,
				cartNoList: cartNoList,
			};

			const result = await createOrderProductFromCart(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				navigate(pathList.payment.index, { state: { data: result.data, from: ORDER_TYPE.CART } });
				return result.data;
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	/**
	 * 선택된 상품에 따라 금액 정보 반영
	 */
	const setSelectedProduct = async () => {
		if (checkedProduct && checkedProduct.length > 0) {
			//금액 조회;
			await requestCartPrice(checkedProduct).then((data) => {
				setTotalInfo((prev) => ({
					...prev,
					deliveryPrice: data.totalDeliveryPrice, //총 배송비;
					// , couponDiscount: data.couponDiscount//쿠폰 할인금액;
					totalProductPrice: data.totalProductPrice, //총 선택상품금액;
					totalDiscountPrice: data.totalDiscountPrice, //총 할인금액;
					finalPrice: data.finalPrice, //최종 주문 금액;
					// , savedReward: data.savedReward//적립 예정 리워드;
				}));
			});
		} else {
			setTotalInfo((prev) => ({
				...prev,
				deliveryPrice: 0, //총 배송비;
				// , couponDiscount: 0//쿠폰 할인금액;
				totalProductPrice: 0, //총 선택상품금액;
				totalDiscountPrice: 0, //총 할인금액;
				finalPrice: 0, //최종 주문 금액;
				// , savedReward: 0//적립 예정 리워드;
			}));
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// 초기 데이터 조회
	useEffect(() => {
		requestGetProductList();
	}, []);

	// 스크롤 이벤트 설정
	useEffect(() => {
		//스크롤 이벤트 설정 - 전체합계 박스 위치 조절;
		window.addEventListener("scroll", handlerScroll);

		return () => {
			//스크롤 이벤트 해제 - 전체합계 박스 위치 조절;
			window.removeEventListener("scroll", handlerScroll);
		};
	}, []);

	// 장바구니 정보 변경시 선택한 상품 금액 재계산;
	useEffect(() => {
		setSelectedProduct();
	}, [productList]);

	// 체크박스 변경시
	useEffect(() => {
		if (!productList) return;

		const saleProductNos = productList
			?.flatMap((product) => product.products) // 모든 상품을 하나의 배열로 만들기
			?.filter((product) => product.status === "SALE") // 판매중인 상품만 필터링
			?.map((product) => product.productNo); // productNo만 추출

		// 전체 선택 체크박스 상태 변경;
		if (checkedProduct.length === 0 || checkedProduct.length !== saleProductNos.length) {
			ref.selectAll.current.checked = false;
		} else {
			ref.selectAll.current.checked = true;
		}

		// 선택상품 변경시 금액 재계산;
		setSelectedProduct();
	}, [checkedProduct]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="container underTopMenu cartContainer">
			<div className="contents">
				<CartProgressStage {...{ on: "step1" }} />
			</div>
			<div className="contents">
				<div className="selectProduct">
					<input
						className="selectAll"
						name="selectAll"
						id="selectAll"
						type="checkbox"
						ref={ref.selectAll}
						onChange={(e) => handlerChangeSelectAll(e)}
					/>
					<label htmlFor="selectAll">
						{textInfo.button.selectAll}
						<div className="selectedProductCount">
							(<span>{checkedProduct?.length || 0}</span>)
						</div>
					</label>
					<div
						className="button deleteSelectedProduct"
						onClick={(e) => handlerClickDeleteSelected(e)}
					>
						{textInfo.button.deleteSelected}
					</div>
				</div>
			</div>
			<div className="contentsBackground">
				<div className="contents cardContents">
					<div className="cardLists">
						{productList && productList.length ? (
							<>
								{/* 좌측 장바구니 목록 - 묶음배송별 */}
								{productList?.map((item, index) => (
									<CartProductList
										key={index}
										index={index}
										data={item}
										setDataList={setProductList} // 상품목록 변경
										calcTotalPrice={setSelectedProduct} // 선택상품 금액 재계산
										afterOptionChanged={afterOptionChanged} // 상품 정보 수정시
										requestDeleteProductFromCart={requestDeleteProductFromCart} // 상품 삭제 요청
										requestDeleteOptionFromCart={requestDeleteOptionFromCart} // 상품 옵션 삭제 요청
										checkedProduct={checkedProduct} // 선택한 상품 목록
										setCheckedProduct={setCheckedProduct} // 선택한 상품 목록 변경
										handlerChangeSelected={handlerChangeSelected} // 체크박스 선택
										handlerChangeSelectedMultiple={handlerChangeSelectedMultiple} // 묶음배송 체크박스 선택
									/>
								))}
								{/* 우측 금액 합계 */}
							</>
						) : (
							<div className="noData">
								<Link to={pathList.main} className="continue">
									<button className="button clickable continueShopping">
										{textInfo.cart.continueShopping}
									</button>
								</Link>
							</div>
						)}
					</div>
					<div className="cartTotalContainer">
						<div className="title">{textInfo.order.totalInfo}</div>
						<ul className="cartTotalDataList">
							<li className="cartTotalDataItem productPrice">
								<div className="label">{textInfo.order.selectedProductPrice}</div>
								<div className="price selectedProductPrice">
									<span>{totalInfo?.totalProductPrice?.toLocaleString()}</span>
									<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
								</div>
							</li>
							<li className="cartTotalDataItem deliveryPrice">
								<div className="label">{textInfo.order.totalDeliveryPrice}</div>
								<div className="price selectedDeliveryPrice">
									<span>{totalInfo?.deliveryPrice?.toLocaleString()}</span>
									<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
								</div>
							</li>
							<li className="cartTotalDataItem discountPrice">
								<div className="label">{textInfo.order.totalDiscountPrice}</div>
								<div className="price selectedDiscountPrice">
									<span>{totalInfo?.totalDiscountPrice?.toLocaleString()}</span>
									<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
								</div>
							</li>
						</ul>
						<div className="orderTotal">
							<div className="label">{textInfo.order.totalOrderPrice}</div>
							<div className="price orderPriceTotal">
								<span>{totalInfo?.finalPrice?.toLocaleString()}</span>
								<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
							</div>
						</div>
						<div className="button clickable buy cover" onClick={(e) => handlerClickOrder(e)}>
							<div className="buttonInner">
								<div className="label">{textInfo.button.order}</div>
								{/* <div className="orderProductCountCover">
											<div className="orderProductCount">{totalInfo?.productCount?.toLocaleString()}</div>
										</div> */}
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <div className="contents">
				<div className="contents bannerBand">띠배너</div>
				<div className="recommendedProducts">
					<div className="title"><span>김린미님</span>에게 추천드리는 상품입니다.</div>
					<div className="pagination">
						<div>1/6</div>
						<Image
							src="/sample/paginationButton.png"
							alt=""
						/>
					</div>
					<ProductList
							{...{
								title : "행운을 가득 담은 먹거리"
								, subtitle : "당신의 찬란할 미래를 응원해요"
								,"totalCount": 37,
								"page": 1,
								"offset": 20,
								"items": [
									{
										"productNo": 309,
										"type": "건강식품",
										"brand": {
											"brandNo": 91,
											"name": "삭제용 브랜드 테스트"
										},
										"name": "상품검수테스트 19",
										"price": 123,
										"salePrice": 122,
										"salePercent": 1,
										"deliveryPrice": 123,
										"thumbnail": {
											"fileNo": 1144,
											"thumbPath": "http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_8c4522a7294c4fc4a79cddc62c2c321e.png",
											"webPath": "http://172.30.1.55:9000/linme/PRODUCT/LM_F_8c4522a7294c4fc4a79cddc62c2c321e.png"
										},
										"tag": null,
										"status": "SALE",
										"likeYn": "N",
										"registered": "2024-03-22 18:47:11"
									},
									{
										"productNo": 246,
										"type": "건강식품",
										"brand": {
											"brandNo": 90,
											"name": "동국제약"
										},
										"name": "테스트 상품명",
										"price": 1000,
										"salePrice": 1000,
										"salePercent": 0,
										"deliveryPrice": 0,
										"thumbnail": {
											"fileNo": 1025,
											"thumbPath": "http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_b08edb4fa2fa4cdf8c0806016c9d3380.png",
											"webPath": "http://172.30.1.55:9000/linme/PRODUCT/LM_F_b08edb4fa2fa4cdf8c0806016c9d3380.png"
										},
										"tag": null,
										"status": "SALE",
										"likeYn": "N",
										"registered": "2024-03-18 16:14:22"
									},
									{
										"productNo": 289,
										"type": "건강식품",
										"brand": {
											"brandNo": 91,
											"name": "삭제용 브랜드 테스트"
										},
										"name": "상품검수테스트 4",
										"price": 123,
										"salePrice": 122,
										"salePercent": 1,
										"deliveryPrice": 123,
										"thumbnail": {
											"fileNo": 1133,
											"thumbPath": "http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_b024e727c6cc45efa7c79ec9eef25c88.png",
											"webPath": "http://172.30.1.55:9000/linme/PRODUCT/LM_F_b024e727c6cc45efa7c79ec9eef25c88.png"
										},
										"tag": null,
										"status": "SALE",
										"likeYn": "N",
										"registered": "2024-03-22 16:59:31"
									},
									{
										"productNo": 290,
										"type": "건강식품",
										"brand": {
											"brandNo": 91,
											"name": "삭제용 브랜드 테스트"
										},
										"name": "상품검수테스트 5",
										"price": 123,
										"salePrice": 122,
										"salePercent": 1,
										"deliveryPrice": 123,
										"thumbnail": {
											"fileNo": 1134,
											"thumbPath": "http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_0aff33b58d014466ab13f0b4833ddd09.png",
											"webPath": "http://172.30.1.55:9000/linme/PRODUCT/LM_F_0aff33b58d014466ab13f0b4833ddd09.png"
										},
										"tag": null,
										"status": "SALE",
										"likeYn": "N",
										"registered": "2024-03-22 17:00:49"
									},
									{
										"productNo": 295,
										"type": "건강식품",
										"brand": {
											"brandNo": 91,
											"name": "삭제용 브랜드 테스트"
										},
										"name": "상품검수테스트 10 (관리자가 생성)",
										"price": 123,
										"salePrice": 122,
										"salePercent": 1,
										"deliveryPrice": 123,
										"thumbnail": {
											"fileNo": 1139,
											"thumbPath": "http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_d62dd23cebf4481bb1d1f4f39121fce2.png",
											"webPath": "http://172.30.1.55:9000/linme/PRODUCT/LM_F_d62dd23cebf4481bb1d1f4f39121fce2.png"
										},
										"tag": null,
										"status": "SALE",
										"likeYn": "N",
										"registered": "2024-03-22 17:29:44"
									},
									{
										"productNo": 298,
										"type": "건강식품",
										"brand": {
											"brandNo": 91,
											"name": "삭제용 브랜드 테스트"
										},
										"name": "상품검수테스트 6-1 (관리자가 생성)",
										"price": 123,
										"salePrice": 122,
										"salePercent": 1,
										"deliveryPrice": 123,
										"thumbnail": {
											"fileNo": 1135,
											"thumbPath": "http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_841ae8b59d174ddda130cd80c4323422.png",
											"webPath": "http://172.30.1.55:9000/linme/PRODUCT/LM_F_841ae8b59d174ddda130cd80c4323422.png"
										},
										"tag": null,
										"status": "SALE",
										"likeYn": "N",
										"registered": "2024-03-22 18:08:28"
									},
									{
										"productNo": 305,
										"type": "건강식품",
										"brand": {
											"brandNo": 91,
											"name": "삭제용 브랜드 테스트"
										},
										"name": "상품검수테스트 15",
										"price": 123,
										"salePrice": 122,
										"salePercent": 1,
										"deliveryPrice": 123,
										"thumbnail": {
											"fileNo": 1142,
											"thumbPath": "http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_5d4877d50ddf486d97ef72d77dcac2ff.png",
											"webPath": "http://172.30.1.55:9000/linme/PRODUCT/LM_F_5d4877d50ddf486d97ef72d77dcac2ff.png"
										},
										"tag": null,
										"status": "SALE",
										"likeYn": "N",
										"registered": "2024-03-22 18:26:33"
									},
									{
										"productNo": 306,
										"type": "건강식품",
										"brand": {
											"brandNo": 91,
											"name": "삭제용 브랜드 테스트"
										},
										"name": "상품검수테스트 16 (15에서 수정)",
										"price": 123,
										"salePrice": 122,
										"salePercent": 1,
										"deliveryPrice": 123,
										"thumbnail": {
											"fileNo": 1142,
											"thumbPath": "http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_5d4877d50ddf486d97ef72d77dcac2ff.png",
											"webPath": "http://172.30.1.55:9000/linme/PRODUCT/LM_F_5d4877d50ddf486d97ef72d77dcac2ff.png"
										},
										"tag": null,
										"status": "SALE",
										"likeYn": "N",
										"registered": "2024-03-22 18:27:29"
									},
									{
										"productNo": 307,
										"type": "건강식품",
										"brand": {
											"brandNo": 91,
											"name": "삭제용 브랜드 테스트"
										},
										"name": "상품검수테스트 17 ",
										"price": 123,
										"salePrice": 122,
										"salePercent": 1,
										"deliveryPrice": 123,
										"thumbnail": {
											"fileNo": 1143,
											"thumbPath": "http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_156a24892cef4b169c6be3d1b9d84dbc.png",
											"webPath": "http://172.30.1.55:9000/linme/PRODUCT/LM_F_156a24892cef4b169c6be3d1b9d84dbc.png"
										},
										"tag": null,
										"status": "SALE",
										"likeYn": "N",
										"registered": "2024-03-22 18:37:06"
									},
									{
										"productNo": 308,
										"type": "건강식품",
										"brand": {
											"brandNo": 91,
											"name": "삭제용 브랜드 테스트"
										},
										"name": "상품검수테스트 17 (17 수정)",
										"price": 123,
										"salePrice": 122,
										"salePercent": 1,
										"deliveryPrice": 123,
										"thumbnail": {
											"fileNo": 1143,
											"thumbPath": "http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_156a24892cef4b169c6be3d1b9d84dbc.png",
											"webPath": "http://172.30.1.55:9000/linme/PRODUCT/LM_F_156a24892cef4b169c6be3d1b9d84dbc.png"
										},
										"tag": null,
										"status": "SALE",
										"likeYn": "N",
										"registered": "2024-03-22 18:39:20"
									},
									{
										"productNo": 245,
										"type": "건강식품",
										"brand": {
											"brandNo": 90,
											"name": "동국제약"
										},
										"name": "이경제 더힘찬 녹용 블랙 2022년 최신상 66ml, 1.98L, 3개",
										"price": 5000000,
										"salePrice": 5000000,
										"salePercent": 0,
										"deliveryPrice": 0,
										"thumbnail": {
											"fileNo": 1018,
											"thumbPath": "http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_1d2d0d663c214a9380713db27ed7ce6d.jpeg",
											"webPath": "http://172.30.1.55:9000/linme/PRODUCT/LM_F_1d2d0d663c214a9380713db27ed7ce6d.jpeg"
										},
										"tag": null,
										"status": "SALE",
										"likeYn": "N",
										"registered": "2024-03-18 15:58:25"
									},
									{
										"productNo": 310,
										"type": "건강식품",
										"brand": {
											"brandNo": 91,
											"name": "삭제용 브랜드 테스트"
										},
										"name": "상품검수테스트 20",
										"price": 123,
										"salePrice": 122,
										"salePercent": 1,
										"deliveryPrice": 123,
										"thumbnail": {
											"fileNo": 1145,
											"thumbPath": "http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_6ead2c5758ae478caab7352e36780f13.png",
											"webPath": "http://172.30.1.55:9000/linme/PRODUCT/LM_F_6ead2c5758ae478caab7352e36780f13.png"
										},
										"tag": null,
										"status": "SALE",
										"likeYn": "N",
										"registered": "2024-03-22 18:55:15"
									},
									{
										"productNo": 311,
										"type": "건강식품",
										"brand": {
											"brandNo": 91,
											"name": "삭제용 브랜드 테스트"
										},
										"name": "상품검수테스트 21 (20수정)",
										"price": 123,
										"salePrice": 122,
										"salePercent": 1,
										"deliveryPrice": 123,
										"thumbnail": {
											"fileNo": 1145,
											"thumbPath": "http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_6ead2c5758ae478caab7352e36780f13.png",
											"webPath": "http://172.30.1.55:9000/linme/PRODUCT/LM_F_6ead2c5758ae478caab7352e36780f13.png"
										},
										"tag": null,
										"status": "SALE",
										"likeYn": "N",
										"registered": "2024-03-22 18:55:40"
									},
									{
										"productNo": 313,
										"type": "건강식품",
										"brand": {
											"brandNo": 91,
											"name": "삭제용 브랜드 테스트"
										},
										"name": "상품검수테스트 23 (22수정)",
										"price": 123,
										"salePrice": 122,
										"salePercent": 1,
										"deliveryPrice": 123,
										"thumbnail": {
											"fileNo": 1146,
											"thumbPath": "http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_2a821c15d9e444c69aa22ea5797089b7.png",
											"webPath": "http://172.30.1.55:9000/linme/PRODUCT/LM_F_2a821c15d9e444c69aa22ea5797089b7.png"
										},
										"tag": null,
										"status": "SALE",
										"likeYn": "N",
										"registered": "2024-03-22 18:57:38"
									},
									{
										"productNo": 315,
										"type": "건강식품",
										"brand": {
											"brandNo": 91,
											"name": "삭제용 브랜드 테스트"
										},
										"name": "상품검수테스트 24",
										"price": 123,
										"salePrice": 122,
										"salePercent": 1,
										"deliveryPrice": 123,
										"thumbnail": {
											"fileNo": 1147,
											"thumbPath": "http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_0899a4ecbde344978336ca3cf0062676.png",
											"webPath": "http://172.30.1.55:9000/linme/PRODUCT/LM_F_0899a4ecbde344978336ca3cf0062676.png"
										},
										"tag": null,
										"status": "SALE",
										"likeYn": "N",
										"registered": "2024-03-22 19:05:44"
									},
									{
										"productNo": 319,
										"type": "건강식품",
										"brand": {
											"brandNo": 91,
											"name": "삭제용 브랜드 테스트"
										},
										"name": "상품검수테스트 317 수정)",
										"price": 123,
										"salePrice": 122,
										"salePercent": 1,
										"deliveryPrice": 123,
										"thumbnail": {
											"fileNo": 1146,
											"thumbPath": "http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_2a821c15d9e444c69aa22ea5797089b7.png",
											"webPath": "http://172.30.1.55:9000/linme/PRODUCT/LM_F_2a821c15d9e444c69aa22ea5797089b7.png"
										},
										"tag": null,
										"status": "SALE",
										"likeYn": "N",
										"registered": "2024-03-22 19:15:32"
									},
									{
										"productNo": 320,
										"type": "건강식품",
										"brand": {
											"brandNo": 91,
											"name": "삭제용 브랜드 테스트"
										},
										"name": "상품검수테스트 26 (25 수정)",
										"price": 123,
										"salePrice": 122,
										"salePercent": 1,
										"deliveryPrice": 123,
										"thumbnail": {
											"fileNo": 1149,
											"thumbPath": "http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_838ddaeda94c4169af65c5bf6b399aad.png",
											"webPath": "http://172.30.1.55:9000/linme/PRODUCT/LM_F_838ddaeda94c4169af65c5bf6b399aad.png"
										},
										"tag": null,
										"status": "SALE",
										"likeYn": "N",
										"registered": "2024-03-22 19:15:42"
									},
									{
										"productNo": 322,
										"type": "건강식품",
										"brand": {
											"brandNo": 91,
											"name": "삭제용 브랜드 테스트"
										},
										"name": "상품검수테스트 322 test",
										"price": 123,
										"salePrice": 122,
										"salePercent": 1,
										"deliveryPrice": 123,
										"thumbnail": {
											"fileNo": 1151,
											"thumbPath": "http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_86a703bc430f40478060b42bc0ad1d80.jpg",
											"webPath": "http://172.30.1.55:9000/linme/PRODUCT/LM_F_86a703bc430f40478060b42bc0ad1d80.jpg"
										},
										"tag": null,
										"status": "SALE",
										"likeYn": "N",
										"registered": "2024-03-22 19:47:53"
									},
									{
										"productNo": 323,
										"type": "건강식품",
										"brand": {
											"brandNo": 91,
											"name": "삭제용 브랜드 테스트"
										},
										"name": "상품검수테스트 27_Jerry",
										"price": 123,
										"salePrice": 122,
										"salePercent": 1,
										"deliveryPrice": 123,
										"thumbnail": {
											"fileNo": 1152,
											"thumbPath": "http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_ac4aa5fbf7644d89b71d1085aa382a31.png",
											"webPath": "http://172.30.1.55:9000/linme/PRODUCT/LM_F_ac4aa5fbf7644d89b71d1085aa382a31.png"
										},
										"tag": null,
										"status": "SALE",
										"likeYn": "N",
										"registered": "2024-03-22 19:54:15"
									},
									{
										"productNo": 236,
										"type": "건강식품",
										"brand": {
											"brandNo": 69,
											"name": "엔썸잇"
										},
										"name": "엔썸잇 비오틴 10000mcg 판토텐산 맥주효모 L시스틴 바이오틴 100정",
										"price": 80000,
										"salePrice": 62100,
										"salePercent": 22,
										"deliveryPrice": 3000,
										"thumbnail": {
											"fileNo": 920,
											"thumbPath": "http://172.30.1.55:9000/linme/PRODUCT/THB_LM_F_cdbdd41102164a14bed06e69a156f2ba.png",
											"webPath": "http://172.30.1.55:9000/linme/PRODUCT/LM_F_cdbdd41102164a14bed06e69a156f2ba.png"
										},
										"tag": null,
										"status": "SALE",
										"likeYn": "N",
										"registered": "2024-03-18 15:34:23"
									}
								],
								"isLastPage": false
								, limit: 6
							}}
						/>
				</div>
			</div> */}
		</div>
	);
};

export default Cart;
