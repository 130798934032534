import { Link } from "react-router-dom";
import Image from "src/pages/component/common/image";
const pathList = require("src/path/pathJSON.json");

import styles from "src/styles/main/topicSection/topicHeader.module.scss";

const TopicHeader = (props) => {
	const { topic, href } = props;
	return (
		<div className={styles.headerContainer}>
			<div className={styles.titleContainer}>
				<div className={styles.title}>
					{topic?.title}{" "}
					<Link to={href} className={styles.showMore}>
						<Image
							className={styles.showMoreIcon}
							srcSet="
							../../../../images/topic/arrow_showMore.png 1x
							, ../../../../images/topic/arrow_showMore@2x.png 2x
							, ../../../../images/topic/arrow_showMore@3x.png 3x
						"
							alt="더 보러 가기"
						/>
						<span className={styles.showMoreText}>
							자세히 보기
							<svg xmlns="http://www.w3.org/2000/svg" width="6" height="12" viewBox="0 0 6 12">
								<path
									d="m14.37 9.182-3.839-3.934a.822.822 0 0 0-1.179 0 .872.872 0 0 0 0 1.217l3.848 3.926a.872.872 0 0 1 0 1.217l-3.848 3.925a.873.873 0 0 0 0 1.213.823.823 0 0 0 1.184 0l3.839-3.934a2.616 2.616 0 0 0-.005-3.63z"
									transform="translate(-9.104 -4.999)"
								/>
							</svg>
						</span>
					</Link>
				</div>
				<div className={styles.subtitle}>
					<span>{topic?.subTitle}</span>
				</div>
			</div>
			{props.children}
		</div>
	);
};

export default TopicHeader;
