import { forwardRef } from "react";
import { Swiper as SwiperCore } from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/grid";
import "swiper/css/scrollbar";
import "swiper/css/thumbs";

const Swiper = forwardRef((props, ref) => {
	const { children, ...rest } = props;
	return (
		<SwiperCore ref={ref} {...rest}>
			{children}
		</SwiperCore>
	);
});

Swiper.displayName = "Swiper";

export default Swiper;
