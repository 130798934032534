/**
 * 마이린미 - 섭취 알람 추가/수정
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import moment from "moment";
import { useEffect, useState } from "react";

import { API_RESULT_STATUS, COLOR, Z_INDEX } from "src/data/constEnum";
import useModalStack from "src/hooks/useModalStack";
import { getFormatTimeApm } from "src/utils/date";
import { getTakeCycle, getTakePercent } from "src/utils/linmeAlarm";
import { removeInnerSpinner, setInnerSpinner } from "src/utils/linmeDisplay";

import { creditAlarm, modifyAlarm } from "src/api/myLinme/alarm";
import Image from "src/pages/component/common/image";
import SelectBoxTime from "src/pages/component/common/selectBoxTime";
// import AlarmCalendarSelectRange from "src/pages/component/myLinme/alarm/alarmCalendarSelectRange";
import AlarmCalendarSelectDate from "src/pages/component/myLinme/alarm/alarmCalendarSelectDate";
import Toggle from "src/pages/component/toggle";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myLinme/alarmModify.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const MyLinmeAlarmCreateModify = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const { productItem } = props;

	const [startDate, setStartDate] = useState(""); //시작일자;
	const [endDate, setEndDate] = useState(""); //종료일자;

	const [activeFlagForMorningCd, setActiveFlagForMorningCd] = useState(
		!!productItem.activeFlagForMorngCd
	); //아침 알람 설정 여부
	const [activeFlagForAfternoonCd, setActiveFlagForAfternoonCd] = useState(
		!!productItem.activeFlagForAfternCd
	); //점심 알람 설정 여부
	const [activeFlagForEveningCd, setActiveFlagForEveningCd] = useState(
		!!productItem.activeFlagForEvengCd
	); //저녁 알람 설정 여부
	const [timeMorning, setTimeMorning] = useState(productItem?.timeForMorng || "07:00"); //아침 알람 시각;
	const [timeNoon, setTimeNoon] = useState(productItem?.timeForAftern || "12:00"); //점심 알람 시각;
	const [timeNight, setTimeNight] = useState(productItem?.timeForEveng || "18:00"); //저녁 알람 시각;
	const [holidayFlagCd, setHolidayFlagCd] = useState(false); //주말/공휴일 제외

	const [showCalendar, setShowCalendar] = useState(false);
	const [showSelectTimeMorning, setShowSelectTimeMorning] = useState(false);
	const [showSelectTimeNoon, setShowSelectTimeNoon] = useState(false);
	const [showSelectTimeNight, setShowSelectTimeNight] = useState(false);

	const modalStack = useModalStack();

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 아침 알람 시각 설정
	 * @param {Event} e
	 */
	const handlerClickSelectTimeMorning = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		setShowSelectTimeMorning(!showSelectTimeMorning);
	};

	/**
	 * 저녁 알람 시각 설정
	 * @param {Event} e
	 */
	const handlerClickSelectTimeNight = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		setShowSelectTimeNight(!showSelectTimeNight);
	};

	/**
	 * 점심 알람 시각 설정
	 * @param {Event} e
	 */
	const handlerClickSelectTimeNoon = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		setShowSelectTimeNoon(!showSelectTimeNoon);
	};

	/**
	 * "취소" 클릭 시
	 * @param {Event} e
	 */
	const handlerClickCancel = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.removeModal(props.id);
	};

	// 시간 설정 셀렉트박스 닫기
	const handleCloseTimeRange = () => {
		setShowSelectTimeMorning(false);
		setShowSelectTimeNoon(false);
		setShowSelectTimeNight(false);
	};

	/**
	 * 시작일/예상종료일 클릭 시
	 * @param {Event} e
	 */
	const handlerClickDateRange = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		setShowCalendar(!showCalendar); //캘린더 열기;
		handleCloseTimeRange(); // 캘린더 open시 시간설정 셀렉트박스 닫기
	};

	/**
	 * "저장하기" 클릭 시
	 * @param {Event} e
	 */
	const handlerClickSave = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		// 아침, 점심, 저녁 모두 해제 저장 불가
		if (!activeFlagForMorningCd && !activeFlagForAfternoonCd && !activeFlagForEveningCd) {
			return false;
		}

		const request = {
			orderItemNo: productItem?.orderItemNo,
			productNo: productItem?.productNo,
			cycle: productItem?.cycle,
			intake: productItem?.intake,
			unit: productItem?.unit,
			productName: productItem?.productName,
			productImagePath: productItem?.productImagePath,
			remainQty: productItem?.remainQty,
			totQty: productItem?.totQty,
			purchComfmDtm: productItem.confirmDate,
			startDate: startDate || moment().format("YYYY-MM-DD"),
			endDate: endDate || moment().format("YYYY-MM-DD"),
			holidayFlagCd: holidayFlagCd,
			timeForMorng: timeMorning,
			activeFlagForMorngCd: activeFlagForMorningCd,
			timeForAftern: timeNoon,
			activeFlagForAfternCd: activeFlagForAfternoonCd,
			timeForEveng: timeNight,
			activeFlagForEvengCd: activeFlagForEveningCd,
		};

		// alarmNo가 있으면 수정
		if (productItem.alarmNo) {
			request.alarmNo = productItem.alarmNo;
			return _modifyAlarm(request);
		} else {
			// 생성
			_creditAlarm(request);
		}
	};

	/**
	 * 알람 등록
	 * @returns
	 */
	const _creditAlarm = async (request) => {
		await setInnerSpinner();

		try {
			const result = await creditAlarm(request);

			if (result?.status?.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				await modalStack.removeModal(props.id);
				await props.handlerAfterCreate();
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		} finally {
			await removeInnerSpinner();
		}
	};

	/**
	 * 알람 수정
	 * @returns
	 */
	const _modifyAlarm = async (request) => {
		await setInnerSpinner();

		try {
			const result = await modifyAlarm(request);

			await modalStack.removeModal(props.id);
			await props.handlerAfterModify(request);

			window.linmeLog.log(result);
		} catch (error) {
			window.linmeLog.log(error);
		} finally {
			await removeInnerSpinner();
		}
	};

	/**
	 * 아침 - 선택한 시각 적용
	 * @param {String} d
	 */
	const setSelectedTimeMorning = (d) => {
		setTimeMorning(d);
	};

	/**
	 * 저녁 - 선택한 시각 적용
	 * @param {String} d
	 */
	const setSelectedTimeNight = (d) => {
		setTimeNight(d);
	};

	/**
	 * 점심 - 선택한 시각 적용
	 * @param {String} d
	 */
	const setSelectedTimeNoon = (d) => {
		setTimeNoon(d);
	};

	/**
	 * 캘린더에서 선택한 일자 적용
	 * @param {String} selectedStartDate "YYYY-MM-DD"
	 * @param {String} selectedEndDate "YYYY-MM-DD"
	 */
	const setSelectedDate = (selectedStartDate, selectedEndDate) => {
		setStartDate(selectedStartDate);

		setShowCalendar(!showCalendar); //캘린더 닫기;
	};

	/**
	 * 남은 약 개수, 섭취 주기, 하루 섭취량을 사용하여 종료일 계산
	 * @param {String} startDate "YYYY-MM-DD"
	 * @param {Number} remainQty 30
	 * @param {Number} intake 1
	 * @param {Number} cycle 1
	 */
	const calculateDaysToFinish = (startDate, remainQty, intake, cycle) => {
		// ( 남은 약개수 / 하루 섭취량 ) * 섭취 주기 = 종료일
		const daysToFinish = Math.ceil((remainQty / intake) * cycle);
		const end = new Date();
		const start = startDate ? new Date(startDate) : new Date();
		end.setDate(start.getDate() + daysToFinish);
		const formattedEndDate = end.toISOString().split("T")[0]; // "YYYY-MM-DD" 형식으로 변환
		return formattedEndDate;
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		setHolidayFlagCd(productItem?.holidayFlagCd || false);
		if (!productItem?.alarmNo) {
			// 생성
			if (productItem?.intake === 1) {
				setActiveFlagForAfternoonCd(true);
			} else if (productItem?.intake === 2) {
				setActiveFlagForMorningCd(true);
				setActiveFlagForAfternoonCd(true);
			} else if (productItem?.intake === 3) {
				setActiveFlagForMorningCd(true);
				setActiveFlagForAfternoonCd(true);
				setActiveFlagForEveningCd(true);
			}
		}
	}, [productItem]);

	useEffect(() => {
		// 아침점심저녁 플래그 모두 해제 시 종료일 계산 불가
		if (!activeFlagForMorningCd && !activeFlagForAfternoonCd && !activeFlagForEveningCd) {
			setEndDate("");
			return;
		}

		// 하루 섭취량
		const selectedIntake = [
			activeFlagForMorningCd,
			activeFlagForAfternoonCd,
			activeFlagForEveningCd,
		].reduce((acc, curr) => acc + Number(curr), 0);
		// ( 남은 약개수 / 하루 섭취량 ) * 섭취 주기 = 종료일
		const calculateDays = calculateDaysToFinish(
			startDate,
			productItem?.remainQty,
			selectedIntake,
			productItem?.cycle
		);
		setEndDate(calculateDays);
	}, [startDate, activeFlagForMorningCd, activeFlagForAfternoonCd, activeFlagForEveningCd]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<>
			{/* 상품 정보 */}
			<div className="productInfo">
				<div className="productImageCover">
					<Image src={productItem?.productImagePath} alt="" />
				</div>
				<div className="takeInfo">
					<div className="productName">{productItem?.productName || ""}</div>
					<div className="takeTimeContainer">
						<div className="takeTime">
							{productItem?.timeForNow && (
								<>
									<span>{productItem?.timeForNow}</span>
									<span className="divider">|</span>
								</>
							)}
							<span>{getTakeCycle(productItem || null)}</span>
						</div>
						<div className="excludingHolidays">{holidayFlagCd ? "공휴일 제외" : ""}</div>
					</div>
				</div>
			</div>
			{/* progress bar */}
			{productItem ? (
				<>
					<div
						className="takeRange"
						style={{
							background: `linear-gradient(to right, ${COLOR.LINME} 0%, ${
								COLOR.LINME
							} ${getTakePercent(productItem)}%, ${COLOR.PINK01} ${getTakePercent(productItem)}%, ${
								COLOR.PINK01
							} 100%)`,
						}}
					/>

					{/* <div className="startTakeDate">{productItem ? moment( productItem.startDate ).format( "MM월 DD일" ) + " 시작" : "" }</div> */}
					<div className="leftQuantity">
						{productItem?.remainQty}
						{productItem?.unit} {textInfo.myLinme.alarm.intakeList.remain}
					</div>
				</>
			) : (
				<></>
			)}

			{/* 날짜 설정 */}
			<div className="inputData date">
				<div className={"selectDate startDate"} onClick={(e) => handlerClickDateRange(e)}>
					{textInfo.myLinme.alarm.intakeList.startDate}:{" "}
					<span>
						{startDate
							? startDate
							: productItem
							? moment(productItem.startDate).format("YYYY-MM-DD")
							: ""}
					</span>
				</div>
				<div className="selectDate endDate disabled">
					{textInfo.myLinme.alarm.intakeList.endDate}:{" "}
					<span>
						{endDate
							? endDate
							: productItem
							? moment(productItem.endDate).format("YYYY-MM-DD")
							: ""}
					</span>
				</div>
				<div className={"selectDate range " + (showCalendar ? "on" : "")}>
					<AlarmCalendarSelectDate
						{...{
							excludingHolidays: true,
							setSelectedDate: setSelectedDate,
						}}
					/>
				</div>
			</div>
			<div className="checkHolidayCover">
				<input
					className="checkHoliday"
					name="checkHoliday"
					id="checkHoliday"
					type="checkbox"
					checked={holidayFlagCd}
					onChange={() => setHolidayFlagCd(!holidayFlagCd)}
				/>
				<label htmlFor="checkHoliday">{textInfo.myLinme.alarm.intakeList.holidayFlag2}</label>
			</div>

			{/* 시간 설정 */}
			<ul className="intervalTakingList">
				{/*
				아침 : 06~09시
				점심 : 11~14시
				저녁 : 16~19시
				*/}
				{productItem?.intake === 3 && (
					<li className="intervalTakingItem">
						<span>{textInfo.myLinme.alarm.intakeList.morning}</span>
						<div className="setTime" onClick={(e) => handlerClickSelectTimeMorning(e)}>
							{getFormatTimeApm(null, timeMorning)}
						</div>
						<div className="alarmToggleCover">
							<Toggle
								str1=""
								str2=""
								styles={{
									container: {
										width: "40px",
										height: "20px",
										boxShadow: "unset",
										backgroundColor: COLOR.GRAY02,
										checkedBackgroundColor: COLOR.LINME,
									},
									circle: {
										width: "15px",
										height: "15px",
										top: "3px",
										left: "5px",
										backgroundColor: COLOR.WHITE,
										checkedRight: "3px",
										checkedBackgroundColor: COLOR.WHITE,
									},
									label: null,
								}}
								isOn={activeFlagForMorningCd}
								afterToggle={() => setActiveFlagForMorningCd(!activeFlagForMorningCd)}
							/>
						</div>
					</li>
				)}

				<li className="intervalTakingItem">
					<span>{textInfo.myLinme.alarm.intakeList.afternoon}</span>
					<div className="setTime" onClick={(e) => handlerClickSelectTimeNoon(e)}>
						{getFormatTimeApm(null, timeNoon)}
					</div>
					<div className="alarmToggleCover">
						<Toggle
							str1=""
							str2=""
							styles={{
								container: {
									width: "40px",
									height: "20px",
									boxShadow: "unset",
									backgroundColor: COLOR.GRAY02,
									checkedBackgroundColor: COLOR.LINME,
								},
								circle: {
									width: "15px",
									height: "15px",
									top: "3px",
									left: "5px",
									backgroundColor: COLOR.WHITE,
									checkedRight: "3px",
									checkedBackgroundColor: COLOR.WHITE,
								},
								label: null,
							}}
							isOn={activeFlagForAfternoonCd}
							afterToggle={() => setActiveFlagForAfternoonCd(!activeFlagForAfternoonCd)}
						/>
					</div>
				</li>
				{productItem?.intake !== 1 && (
					<li className="intervalTakingItem">
						<span>{textInfo.myLinme.alarm.intakeList.evening}</span>
						<div className="setTime" onClick={(e) => handlerClickSelectTimeNight(e)}>
							{getFormatTimeApm(null, timeNight)}
						</div>
						<div className="alarmToggleCover">
							<Toggle
								str1=""
								str2=""
								styles={{
									container: {
										width: "40px",
										height: "20px",
										boxShadow: "unset",
										backgroundColor: COLOR.GRAY02,
										checkedBackgroundColor: COLOR.LINME,
									},
									circle: {
										width: "15px",
										height: "15px",
										top: "3px",
										left: "5px",
										backgroundColor: COLOR.WHITE,
										checkedRight: "3px",
										checkedBackgroundColor: COLOR.WHITE,
									},
									label: null,
								}}
								isOn={activeFlagForEveningCd}
								afterToggle={() => setActiveFlagForEveningCd(!activeFlagForEveningCd)}
							/>
						</div>
					</li>
				)}
			</ul>
			<SelectBoxTime
				{...{
					className: "setTimeMorning " + (showSelectTimeMorning ? "on" : ""),
					apply: setSelectedTimeMorning,
					toggle: handlerClickSelectTimeMorning,
					value: timeMorning,
					timeStart: "06:00",
					timeEnd: "09:00",
					periodMinutes: 30,
					optionsListStyle: {
						height: "122px",
						top: "14px",
						left: 0,
						borderTopLeftRadius: "unset",
						borderTopRightRadius: "unset",
					},
					optionsStyle: {
						display: "block",
						width: "100%",
						height: "fit-content",
						fontSize: "12px",
						fontWeight: "normal",
						lineHeight: 1.08,
						letterSpacing: "normal",
						textAlign: "center",
						color: COLOR.FONT,
						borderBottom: "unset",
					},
					selectedValueStyle: {
						display: "block",
						width: "100%",
						height: "22px",
						fontSize: "13px",
						fontWeight: 500,
						lineHeight: 1.15,
						letterSpacing: "-0.13px",
						color: COLOR.FONT,
						borderRadius: "3px",
						border: "solid 0.5px " + COLOR.GRAY02,
						margin: "unset",
						padding: "8px 0 0 0",
					},
					style: {
						display: "block",
						width: "114px",
						height: "29px",
						fontSize: "13px",
						fontWeight: 500,
						lineHeight: 1.15,
						letterSpacing: "-0.13px",
						textAlign: "center",
						padding: "unset",
						margin: "unset",
						zIndex: Z_INDEX.COMMON,
					},
				}}
			/>
			<SelectBoxTime
				{...{
					className: "setTimeNoon " + (showSelectTimeNoon ? "on" : ""),
					apply: setSelectedTimeNoon,
					toggle: handlerClickSelectTimeNoon,
					value: timeNoon,
					timeStart: "11:00",
					timeEnd: "14:00",
					periodMinutes: 30,
					optionsListStyle: {
						height: "122px",
						top: "14px",
						left: 0,
						borderTopLeftRadius: "unset",
						borderTopRightRadius: "unset",
					},
					optionsStyle: {
						display: "block",
						width: "100%",
						height: "fit-content",
						fontSize: "12px",
						fontWeight: "normal",
						lineHeight: 1.08,
						letterSpacing: "normal",
						textAlign: "center",
						color: COLOR.FONT,
						borderBottom: "unset",
					},
					selectedValueStyle: {
						display: "block",
						width: "100%",
						height: "22px",
						fontSize: "13px",
						fontWeight: 500,
						lineHeight: 1.15,
						letterSpacing: "-0.13px",
						color: COLOR.FONT,
						borderRadius: "3px",
						border: "solid 0.5px " + COLOR.GRAY02,
						margin: "unset",
						padding: "8px 0 0 0",
					},
					style: {
						display: "block",
						width: "114px",
						height: "29px",
						fontSize: "13px",
						fontWeight: 500,
						lineHeight: 1.15,
						letterSpacing: "-0.13px",
						textAlign: "center",
						padding: "unset",
						margin: "unset",
						zIndex: Z_INDEX.COMMON,
					},
				}}
			/>
			<SelectBoxTime
				{...{
					className: "setTimeNight " + (showSelectTimeNight ? "on" : ""),
					apply: setSelectedTimeNight,
					toggle: handlerClickSelectTimeNight,
					value: timeNight,
					timeStart: "16:00",
					timeEnd: "19:00",
					periodMinutes: 30,
					optionsListStyle: {
						width: "calc( 100% - 1px )",
						height: "122px",
						top: "14px",
						left: 0,
						borderTopLeftRadius: "unset",
						borderTopRightRadius: "unset",
					},
					optionsStyle: {
						display: "block",
						width: "100%",
						height: "fit-content",
						fontSize: "12px",
						fontWeight: "normal",
						lineHeight: 1.08,
						letterSpacing: "normal",
						textAlign: "center",
						color: COLOR.FONT,
						borderBottom: "unset",
					},
					selectedValueStyle: {
						display: "block",
						width: "100%",
						height: "22px",
						fontSize: "13px",
						fontWeight: 500,
						lineHeight: 1.15,
						letterSpacing: "-0.13px",
						color: COLOR.FONT,
						borderRadius: "3px",
						border: "solid 0.5px " + COLOR.GRAY02,
						margin: "unset",
						padding: "8px 0 0 0",
					},
					style: {
						display: "block",
						width: "114px",
						height: "29px",
						fontSize: "13px",
						fontWeight: 500,
						lineHeight: 1.15,
						letterSpacing: "-0.13px",
						textAlign: "center",
						padding: "unset",
						margin: "unset",
						zIndex: Z_INDEX.COMMON,
					},
				}}
			/>

			{false}
			<div className="buttonCover">
				<div className="button cancel" onClick={(e) => handlerClickCancel(e)}>
					{textInfo.button.cancel}
				</div>
				<div
					className={`button save ${
						!activeFlagForMorningCd &&
						!activeFlagForAfternoonCd &&
						!activeFlagForEveningCd &&
						"disabled"
					}`}
					onClick={(e) => handlerClickSave(e)}
				>
					{textInfo.button.save}
				</div>
			</div>
		</>
	);
};

export default MyLinmeAlarmCreateModify;
