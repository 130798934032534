/**
 * 사진 업로드 컨테이너
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import useModalStack from "src/hooks/useModalStack";
import { COLOR, FILE_IMAGE_MAX_SIZE_MB, FILE_UPLOAD_ACCEPT } from "src/data/constEnum";
import { checkFileSize, checkFileType, setPreview } from "src/utils/linmeFile";

import ModalCommonAlert from "src/pages/common/modalAlert";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

const DivUploadPhotoBox = styled.div`
	position: relative;
	display: block;
	width: 100%;
	height: fit-content;
	margin-top: 30px;
`;

const DivLabel = styled.div`
	width: fit-content;
	height: fit-content;
	font-size: 13px;
	font-weight: 600;
	line-height: 2;
	letter-spacing: -0.26px;
	color: $COLOR_FONT;
	span {
		font-size: 13px;
		font-weight: 500;
		color: ${COLOR.GRAY01};
	}
`;

const UlPhotoList = styled.ul`
	display: block;
	width: 100%;
	height: 83px;
	margin-top: 10px;
`;

const LiPhotoItem = styled.li`
	position: relative;
	display: block;
	width: 80px;
	height: 100%;
	float: left;
	margin-right: 13px;
	&:last-child {
		margin-right: unset;
	}

	input[type="file"] {
		position: absolute;
		width: 0;
		height: 0;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
	}
`;

const DivFileUpload = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 3px;
	background-color: ${COLOR.GRAY06};
	cursor: pointer;
	&::after {
		position: absolute;
		display: block;
		width: 22px;
		height: 22px;
		top: 53%;
		left: 50%;
		transform: translate(-50%, -50%);
		border: solid 1px ${COLOR.GRAY04};
		border-radius: 20px;
		color: ${COLOR.GRAY04};
		content: "";
	}
	div {
	}
`;

const DivButton = styled.div`
	display: block;
	width: auto;
	height: auto;
	line-height: 1;
	font-size: 30px;
	font-weight: 100;
	color: ${COLOR.GRAY04};
`;

const ImagePreview = styled.img`
	position: absolute;
	display: none;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	&.on {
		display: block;
	}
`;

const DivDeleteImage = styled.div`
	position: absolute;
	display: none;
	width: 24px;
	height: 24px;
	top: -12px;
	right: -12px;
	border-radius: 12px;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: ${COLOR.WHITE};
	background-image: image-set(
		url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiBAMAAADIaRbxAAAAKlBMVEVHcExwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHDHThsGAAAADXRSTlMA650rENDAQGBwUICwdrro9gAAARZJREFUKM9dkr9KA0EQxj8v0Yg2EmxUAgfaBi6NYCFEn8DiGosUQrBLJ1gJ6WwM5AWEPIKFryF40TNBmHdxv5m53J5fs8Nv5+/OAlTr5U7uT1FrbyTUybACnYmYjiryKpXGBpINkDJT0q+JHBLsRkBKJn+KiaSBTBrkK/TSAPIDbGs4s98qynDJI0+YdEZzjpnl60uOAc0bfPBYIimHrR7NTy+V4t1cZAW9CE4wl0C86pu7yBqb7n3glefJkfnETkKhrjt92w17Sc08sJ7nodASV9bHlhZgoTN9/wX2NZq9FDQKoN1rvMa6sQkbFNiJQcFltEcR+dXtDCIyVRI5HfuWr/+tNOjc007r73LBwO4CsR6fH9z6Aw5cSW4vcWV4AAAAAElFTkSuQmCC")
			1x,
		url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEMAAABDBAMAAAA15KVAAAAAJFBMVEVHcExwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHAQMeW7AAAAC3RSTlMAYr03EO3PoJ+FIBLWTooAAAHnSURBVEjHhZY9SwNBEIb3TOT8aAKiIDbxsLsmCBYhTUAIpEtImdbOxlauSW9zKrGxiT8hCZdE5895H7u3M3P7MeXx8N7uvO/srhC4+h8xZNF7T9jqJ4aqstuhmbgCXZuOAWgvAVdmYC6B1q6xoHvgdWDrCeYNBGYUeQNDkeWMTATs8W6ejQgsfCL5ijViEUEyJzYC1gq5sSKZ7F9rbkXgqUKO7AT8+v6TV/mn9tyFdAvk1EXAtkCOnciGLiVTwYQlXYxu7SxUHUN9SIU4w31KVAoe6q+TPEw4QqFqu5ZZ48YV+0tklHQ88uadE+tD6Z2Ox06IAbU+YSIAQ/ENTGbNMlZvQsswkVw0ZkFs8aCmBJFBpGnvCkOeR+BAShk2MhzZNtPBkXJGExdSDUXIkNgw6ETmhSCFyJjLpNiAyp0Fk+ngEancOTCZHnZausNk8AAoi6nMBqcuVY0tZGKUOpLdkXIqwP1uowlQ7izQJiZYEmbK4kNAu4mmsbYY9XPlnenyYA2cyJ/7vKxMLGrgILKhIR7GU9V1knXluTvw/ce1p219ByQ2RN9Ioe8KsMvgay30ijSvYHyNyGoZXLhj9/S4QUyF7y7fG14WlNmvTC+PPvJqujK/ToIvNTuf1jeOeLyOouj1gn78BwYFfcYl4t4WAAAAAElFTkSuQmCC")
			2x,
		url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGUAAABlBAMAAACmUjD8AAAAHlBMVEVHcExwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHB3Ono7AAAACXRSTlMAE8qiYDbwh+CQl5JdAAACw0lEQVRYw51YwW7TQBB1YkeFmxUQpbeAhIpvAQmJ3FIkVPkWKpSqt1aih97aqhffECChHLtuks7fEseus7szs55hjraen/ftvNmZjSIiBscf3wP8env3LRLGy8/Qxu8jCaL3BZz4O+smycAL00W1fwU4PughYdA+MMGDBgsOs5pyik2ADcOoN4dA/CAhexAMakm9LIyh/m4IHXGp0KzVbqYSoI4Hn+aqGwMe0WsBxFtRl2jUivogijc2ppBhSlsBEIaVqq+kmOUOM5FiHvW/BnCt2pw6LpSq2cr1QBEzkdncGCmVttQuNBhTL8e3gZOvxn+bVpjEt1bspOWc2qE+yikrLUzqu/G8whwgB8dO9s8Jhxc4dSc7GmT77a5m+DOxYzKPCGfByE50kxJ7PkOy1QkV217OkXCxJ9t4R2TIvdj8yTNgiUgauI+i58AR0TRVxp0BR0TTVMqiZ09EDA2sqaxuiBiaKrOJmlMTMTQVJguWy5ywEIlpiGiaCrMI1eWcxpAOHgdoOEwZoNkkKV0p0kAdZzDN4RSTL1NSg4aGIaK1bs/AWIxpaWgiEmMdtTF5bhUMTTJmiAy1B01ClwzRmvBc6xuG6AGVUcueDNES1xDLnjTRPf6OZU+aaIRy16kCJNE1qr1OFSCJqkNggXu0xPHREB90Be7RcttHXm+35T5DzWDiGHZInFl91Azm9lf9FvKCMv2l82A8pEqF37qsnAWWGdm8iLu3XU+BM07Qu8QazM//6MWm2na0XY6qGVsGq39HDxtlUsgq1Qw/aARK9L8mVs6oBkCvIxePZv4UOFcqIB4z/EE918+akhVNI/VQS0z2g0w7Om/ihWLOlMmw1l6HBC5E9njtbtiLF3ZJ7wIXPCc05E/wIulEy1LFKVrT6lPnxVji+a8U3cCdWhlh7lLZRV/v++0W9nj4VYh42mH+LvEfirFMSdHLy60AAAAASUVORK5CYII=")
			3x
	);
	background-image: -webkit-image-set(
		url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiBAMAAADIaRbxAAAAKlBMVEVHcExwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHDHThsGAAAADXRSTlMA650rENDAQGBwUICwdrro9gAAARZJREFUKM9dkr9KA0EQxj8v0Yg2EmxUAgfaBi6NYCFEn8DiGosUQrBLJ1gJ6WwM5AWEPIKFryF40TNBmHdxv5m53J5fs8Nv5+/OAlTr5U7uT1FrbyTUybACnYmYjiryKpXGBpINkDJT0q+JHBLsRkBKJn+KiaSBTBrkK/TSAPIDbGs4s98qynDJI0+YdEZzjpnl60uOAc0bfPBYIimHrR7NTy+V4t1cZAW9CE4wl0C86pu7yBqb7n3glefJkfnETkKhrjt92w17Sc08sJ7nodASV9bHlhZgoTN9/wX2NZq9FDQKoN1rvMa6sQkbFNiJQcFltEcR+dXtDCIyVRI5HfuWr/+tNOjc007r73LBwO4CsR6fH9z6Aw5cSW4vcWV4AAAAAElFTkSuQmCC")
			1x,
		url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEMAAABDBAMAAAA15KVAAAAAJFBMVEVHcExwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHAQMeW7AAAAC3RSTlMAYr03EO3PoJ+FIBLWTooAAAHnSURBVEjHhZY9SwNBEIb3TOT8aAKiIDbxsLsmCBYhTUAIpEtImdbOxlauSW9zKrGxiT8hCZdE5895H7u3M3P7MeXx8N7uvO/srhC4+h8xZNF7T9jqJ4aqstuhmbgCXZuOAWgvAVdmYC6B1q6xoHvgdWDrCeYNBGYUeQNDkeWMTATs8W6ejQgsfCL5ijViEUEyJzYC1gq5sSKZ7F9rbkXgqUKO7AT8+v6TV/mn9tyFdAvk1EXAtkCOnciGLiVTwYQlXYxu7SxUHUN9SIU4w31KVAoe6q+TPEw4QqFqu5ZZ48YV+0tklHQ88uadE+tD6Z2Ox06IAbU+YSIAQ/ENTGbNMlZvQsswkVw0ZkFs8aCmBJFBpGnvCkOeR+BAShk2MhzZNtPBkXJGExdSDUXIkNgw6ETmhSCFyJjLpNiAyp0Fk+ngEancOTCZHnZausNk8AAoi6nMBqcuVY0tZGKUOpLdkXIqwP1uowlQ7izQJiZYEmbK4kNAu4mmsbYY9XPlnenyYA2cyJ/7vKxMLGrgILKhIR7GU9V1knXluTvw/ce1p219ByQ2RN9Ioe8KsMvgay30ijSvYHyNyGoZXLhj9/S4QUyF7y7fG14WlNmvTC+PPvJqujK/ToIvNTuf1jeOeLyOouj1gn78BwYFfcYl4t4WAAAAAElFTkSuQmCC")
			2x,
		url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGUAAABlBAMAAACmUjD8AAAAHlBMVEVHcExwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHB3Ono7AAAACXRSTlMAE8qiYDbwh+CQl5JdAAACw0lEQVRYw51YwW7TQBB1YkeFmxUQpbeAhIpvAQmJ3FIkVPkWKpSqt1aih97aqhffECChHLtuks7fEseus7szs55hjraen/ftvNmZjSIiBscf3wP8env3LRLGy8/Qxu8jCaL3BZz4O+smycAL00W1fwU4PughYdA+MMGDBgsOs5pyik2ADcOoN4dA/CAhexAMakm9LIyh/m4IHXGp0KzVbqYSoI4Hn+aqGwMe0WsBxFtRl2jUivogijc2ppBhSlsBEIaVqq+kmOUOM5FiHvW/BnCt2pw6LpSq2cr1QBEzkdncGCmVttQuNBhTL8e3gZOvxn+bVpjEt1bspOWc2qE+yikrLUzqu/G8whwgB8dO9s8Jhxc4dSc7GmT77a5m+DOxYzKPCGfByE50kxJ7PkOy1QkV217OkXCxJ9t4R2TIvdj8yTNgiUgauI+i58AR0TRVxp0BR0TTVMqiZ09EDA2sqaxuiBiaKrOJmlMTMTQVJguWy5ywEIlpiGiaCrMI1eWcxpAOHgdoOEwZoNkkKV0p0kAdZzDN4RSTL1NSg4aGIaK1bs/AWIxpaWgiEmMdtTF5bhUMTTJmiAy1B01ClwzRmvBc6xuG6AGVUcueDNES1xDLnjTRPf6OZU+aaIRy16kCJNE1qr1OFSCJqkNggXu0xPHREB90Be7RcttHXm+35T5DzWDiGHZInFl91Azm9lf9FvKCMv2l82A8pEqF37qsnAWWGdm8iLu3XU+BM07Qu8QazM//6MWm2na0XY6qGVsGq39HDxtlUsgq1Qw/aARK9L8mVs6oBkCvIxePZv4UOFcqIB4z/EE918+akhVNI/VQS0z2g0w7Om/ihWLOlMmw1l6HBC5E9njtbtiLF3ZJ7wIXPCc05E/wIulEy1LFKVrT6lPnxVji+a8U3cCdWhlh7lLZRV/v++0W9nj4VYh42mH+LvEfirFMSdHLy60AAAAASUVORK5CYII=")
			3x
	);
	&.on {
		display: block;
	}
`;

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */

// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const UploadPhotoBox = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();
	const ref = {
		file1: {
			file: useRef(null),
			fileNo: useRef(null),
			image: useRef(null),
			deleteButton: useRef(null),
		},
		file2: {
			file: useRef(null),
			fileNo: useRef(null),
			image: useRef(null),
			deleteButton: useRef(null),
		},
		file3: {
			file: useRef(null),
			fileNo: useRef(null),
			image: useRef(null),
			deleteButton: useRef(null),
		},
		file4: {
			file: useRef(null),
			fileNo: useRef(null),
			image: useRef(null),
			deleteButton: useRef(null),
		},
		file5: {
			file: useRef(null),
			fileNo: useRef(null),
			image: useRef(null),
			deleteButton: useRef(null),
		},
		removeTarget: useRef(null),
	};
	const [removeTarget, setRemoveTarget] = useState(null);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 이미지 삭제
	 * @param {Event} e
	 * @param {Object} refObj
	 * @param {Number} index
	 */
	const handlerClickDeletePreview = async (e, refObj, index) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		refObj.file.current.value = null;
		refObj.image.current.classList.remove("on");
		refObj.deleteButton.current.classList.remove("on");
		await URL.revokeObjectURL(refObj.image.current.src); //메모리에서 해제;
		refObj.image.current.src = null;

		let removeTargetList = [];
		if ((typeof refObj.fileNo.current).toUpperCase() === "NUMBER") {
			removeTargetList = removeTarget || [];
			await removeTargetList.push(refObj.fileNo.current);
			await setRemoveTarget(removeTargetList);
		}

		if (props.afterFileUpload) props.afterFileUpload(getFileArray(), removeTargetList);
	};

	/**
	 * 파일 변경 시
	 * @param {Event} e
	 * @param {Object} refObj
	 * @param {Number} index
	 */
	const handlerFileChange = async (e, refObj, index) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const file = e.target.files[0];

		if (!file) return false;

		if ((await checkFileType(file)) !== true) {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.fileNotSupported,
			});
			return false;
		}
		if ((await checkFileSize(file)) !== true) {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: "파일 크기는 " + FILE_IMAGE_MAX_SIZE_MB + "MB 이하만 업로드 가능합니다.",
			});
			return false;
		}

		//미리보기 설정;
		setPreview(file, refObj.image);
		refObj.image.current.classList.add("on");
		refObj.deleteButton.current.classList.add("on");

		if (props.afterFileUpload) await props.afterFileUpload(await getFileArray());
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 설정된 파일을 배열에 담아 반환
	 * @returns {Array}
	 */
	const getFileArray = (index, file) => {
		let a = [];
		if (ref.file1.file.current.files && ref.file1.file.current.files.length)
			a.push(ref.file1.file.current.files[0]);
		if (ref.file2.file.current.files && ref.file2.file.current.files.length)
			a.push(ref.file2.file.current.files[0]);
		if (ref.file3.file.current.files && ref.file3.file.current.files.length)
			a.push(ref.file3.file.current.files[0]);
		if (ref.file4.file.current.files && ref.file4.file.current.files.length)
			a.push(ref.file4.file.current.files[0]);
		if (ref.file5.file.current.files && ref.file5.file.current.files.length)
			a.push(ref.file5.file.current.files[0]);

		return a;
	};

	/**
	 * 데이터 수정 시 기존 파일이 존재하는 경우 설정
	 * @param {Object} refObj
	 * @param {Number} index
	 */
	const setImage = (refObj, index) => {
		const o = props.data[index - 1];
		if (o.webPath && o.hasOwnProperty("fileNo")) {
			refObj.image.current.src = o.webPath;
			refObj.image.current.classList.add("on");
			refObj.fileNo.current = o.fileNo;
			refObj.deleteButton.current.classList.add("on");
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		if (props.data && props.data.length) {
			if (props.data.length >= 1) setImage(ref.file1, 1);
			if (props.data.length >= 2) setImage(ref.file2, 2);
			if (props.data.length >= 3) setImage(ref.file3, 3);
			if (props.data.length >= 4) setImage(ref.file4, 4);
			if (props.data.length >= 5) setImage(ref.file5, 5);
		}

		return () => {
			if (ref.file1.image.current && ref.file1.image.current.src)
				URL.revokeObjectURL(ref.file1.image.current.src);
			if (ref.file2.image.current && ref.file2.image.current.src)
				URL.revokeObjectURL(ref.file2.image.current.src);
			if (ref.file3.image.current && ref.file3.image.current.src)
				URL.revokeObjectURL(ref.file3.image.current.src);
			if (ref.file4.image.current && ref.file4.image.current.src)
				URL.revokeObjectURL(ref.file4.image.current.src);
			if (ref.file5.image.current && ref.file5.image.current.src)
				URL.revokeObjectURL(ref.file5.image.current.src);
		};
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<DivUploadPhotoBox className="uploadPhotoBox">
			<DivLabel className="label">
				{textInfo.common.imageFileUpload}
				<span>({textInfo.common.onlyNecessary})</span>
			</DivLabel>
			<UlPhotoList className="uploadPhotoList">
				<LiPhotoItem className="uploadPhotoItem">
					<input
						type="file"
						id="uploadPhoto1"
						accept={FILE_UPLOAD_ACCEPT.image}
						onChange={(e) => handlerFileChange(e, ref.file1, 1)}
						ref={ref.file1.file}
					/>
					<label htmlFor="uploadPhoto1">
						<DivFileUpload className="fileUpload">
							<DivButton>+</DivButton>
						</DivFileUpload>
					</label>
					<ImagePreview className="preview preview1" src="" alt="" ref={ref.file1.image} />
					<DivDeleteImage
						className="previewRemove preview1"
						onClick={(e) => handlerClickDeletePreview(e, ref.file1, 1)}
						ref={ref.file1.deleteButton}
					/>
				</LiPhotoItem>
				<LiPhotoItem className="uploadPhotoItem">
					<input
						type="file"
						id="uploadPhoto2"
						accept={FILE_UPLOAD_ACCEPT.image}
						onChange={(e) => handlerFileChange(e, ref.file2, 2)}
						ref={ref.file2.file}
					/>
					<label htmlFor="uploadPhoto2">
						<DivFileUpload className="fileUpload">
							<DivButton>+</DivButton>
						</DivFileUpload>
					</label>
					<ImagePreview className="preview preview2" src="" alt="" ref={ref.file2.image} />
					<DivDeleteImage
						className="previewRemove preview2"
						onClick={(e) => handlerClickDeletePreview(e, ref.file2, 2)}
						ref={ref.file2.deleteButton}
					/>
				</LiPhotoItem>
				<LiPhotoItem className="uploadPhotoItem">
					<input
						type="file"
						id="uploadPhoto3"
						accept={FILE_UPLOAD_ACCEPT.image}
						onChange={(e) => handlerFileChange(e, ref.file3, 3)}
						ref={ref.file3.file}
					/>
					<label htmlFor="uploadPhoto3">
						<DivFileUpload className="fileUpload">
							<DivButton>+</DivButton>
						</DivFileUpload>
					</label>
					<ImagePreview className="preview preview3" src="" alt="" ref={ref.file3.image} />
					<DivDeleteImage
						className="previewRemove preview3"
						onClick={(e) => handlerClickDeletePreview(e, ref.file3, 3)}
						ref={ref.file3.deleteButton}
					/>
				</LiPhotoItem>
				<LiPhotoItem className="uploadPhotoItem">
					<input
						type="file"
						id="uploadPhoto4"
						accept={FILE_UPLOAD_ACCEPT.image}
						onChange={(e) => handlerFileChange(e, ref.file4, 4)}
						ref={ref.file4.file}
					/>
					<label htmlFor="uploadPhoto4">
						<DivFileUpload className="fileUpload">
							<DivButton>+</DivButton>
						</DivFileUpload>
					</label>
					<ImagePreview className="preview preview4" src="" alt="" ref={ref.file4.image} />
					<DivDeleteImage
						className="previewRemove preview4"
						onClick={(e) => handlerClickDeletePreview(e, ref.file4, 4)}
						ref={ref.file4.deleteButton}
					/>
				</LiPhotoItem>
				<LiPhotoItem className="uploadPhotoItem">
					<input
						type="file"
						id="uploadPhoto5"
						accept={FILE_UPLOAD_ACCEPT.image}
						onChange={(e) => handlerFileChange(e, ref.file5, 5)}
						ref={ref.file5.file}
					/>
					<label htmlFor="uploadPhoto5">
						<DivFileUpload className="fileUpload">
							<DivButton>+</DivButton>
						</DivFileUpload>
					</label>
					<ImagePreview className="preview preview5" src="" alt="" ref={ref.file5.image} />
					<DivDeleteImage
						className="previewRemove preview5"
						onClick={(e) => handlerClickDeletePreview(e, ref.file5, 5)}
						ref={ref.file5.deleteButton}
					/>
				</LiPhotoItem>
			</UlPhotoList>
		</DivUploadPhotoBox>
	);
};

export default UploadPhotoBox;
