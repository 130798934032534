import useAuth from "src/hooks/useAuth";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { logout } from "src/utils/linmeSession";
import { AppContext } from "src/App";
import Image from "src/pages/component/common/image";
import { EMPTY_USER_PROFILE_IMAGE } from "src/data/constEnum";
const pathList = require("src/path/pathJSON.json");
const textInfo = require("src/data/textInfo.json");

const LeftMenuUserLogin = (props) => {
	const auth = useAuth();
	const navigate = useNavigate();
	const { setIsLogin } = useContext(AppContext);
	/**
	 * 로그아웃 클릭 시
	 * @param {Event} e
	 */
	const handlerClickLogout = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await logout(auth, navigate, setIsLogin);
	};
	/**
	 * 로그인 버튼 클릭 시
	 * @param {Event} e
	 */
	const handlerClickMoveToSignIn = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (props.handlerClickToggle) await props.handlerClickToggle();
		navigate(pathList.member.login);
	};

	/**
	 * 회원가입 버튼 클릭 시
	 * @param {Event} e
	 */
	const handlerClickMoveToSignup = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (props.handlerClickToggle) await props.handlerClickToggle();
		navigate(pathList.member.signUp);
	};

	/**
	 * 마이페이지 클릭 시
	 * @param {Event} e
	 */
	const handlerClickMoveToMyPage = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (props.handlerClickToggle) await props.handlerClickToggle();
		navigate(pathList.myPage.index);
	};

	/**
	 * 프로필 클릭
	 * @param {Event} e
	 */
	const handlerClickProfile = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (props.handlerClickToggle) await props.handlerClickToggle();
		navigate(pathList.myPage.profile);
	};

	return (
		<>
			{auth.isLogin ? (
				<>
					<div className="memberInfo">
						<div className="profile" onClick={(e) => handlerClickProfile(e)}>
							<Image
								src={
									auth.isLogin && auth.user && auth.user.image && auth.user.image.webPath
										? auth.user.image.webPath
										: EMPTY_USER_PROFILE_IMAGE
								}
								alt={auth.isLogin && auth.user && auth.user.nickname ? auth.user.nickname : ""}
							/>
						</div>
						<div
							className={
								"memberInfoContainer " +
								(auth.isLogin && auth.user && auth.user.loginType
									? auth.user.loginType.toLowerCase()
									: "")
							}
						>
							<div
								className={
									auth.isLogin && auth.user && auth.user.loginType
										? auth.user.loginType.toLowerCase()
										: ""
								}
							/>
							<div className={"nickname"} onClick={(e) => handlerClickProfile(e)}>
								<div
									className={
										"nicknameText " +
										(auth.isLogin && auth.user.nickname && auth.user.nickname.length > 12
											? "long"
											: "")
									}
								>
									{auth.isLogin ? auth.user.nickname : ""}
								</div>
								<div className="buttonCover">
									<div
										className="button clickable moveToMyPage"
										onClick={(e) => handlerClickMoveToMyPage(e)}
									>
										{textInfo.button.myPage}
									</div>
									<div className="button clickable logout" onClick={(e) => handlerClickLogout(e)}>
										{textInfo.button.signOut}
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			) : (
				<>
					<div className="needLogin">
						<div
							className="button clickable moveToSignIn"
							onClick={(e) => handlerClickMoveToSignIn(e)}
						>
							{textInfo.button.signIn}
						</div>
						<div
							className="button clickable moveToSignUp"
							onClick={(e) => handlerClickMoveToSignup(e)}
						>
							{textInfo.button.moveToSignUp}
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default LeftMenuUserLogin;
