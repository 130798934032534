/**
 * 리뷰 좋아요
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useContext } from "react";

import { AppContext } from "src/App";
import { setReviewLike } from "src/api/product/review";
import { API_RESULT_STATUS, DEVICE_TYPE } from "src/data/constEnum";
import { removeInnerSpinner, setInnerSpinner } from "src/utils/linmeDisplay";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/review/reviewLike.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const ReviewLike = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const { device } = useContext(AppContext);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 리뷰 좋아요 클릭 시
	 * @param {Event} e
	 */
	const handlerClickLike = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await requestSetReviewLike();
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 리뷰 좋아요/좋아요 취소 설정
	 */
	const requestSetReviewLike = async () => {
		try {
			await setInnerSpinner();
			const result = await setReviewLike(props.reviewNo);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (result.data) {
					if (props?.refetch) props.refetch();
					return result.data;
				}
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (err) {
			window.linmeLog.error(err);
			return false;
		} finally {
			await removeInnerSpinner();
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className={"like " + (props.isCheckLike ? "on" : "")}>
			<div
				className={"icon " + (props.isCheckLike ? "on" : "")}
				onClick={(e) => handlerClickLike(e)}
			></div>
			<div className="text">
				<span>{props.likeCount}</span>
				{device === DEVICE_TYPE.MOBILE
					? textInfo.product.review.likeCount_mobile
					: textInfo.product.review.likeCount}
			</div>
		</div>
	);
};

export default ReviewLike;
