// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobileSearchProductList_container__pbWs1 {
  display: flex;
  flex-direction: column;
  gap: 30px;
}`, "",{"version":3,"sources":["webpack://./src/styles/search/mobileSearchProductList.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,sBAAA;EACA,SAAA;AACD","sourcesContent":[".container {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `mobileSearchProductList_container__pbWs1`
};
export default ___CSS_LOADER_EXPORT___;
