import { useState, useEffect } from "react";

/**
 * 페이지가 보이는지 확인하는 훅 onMount
 * @returns 페이지가 보이는지 여부를 반환
 */
const usePageVisibility = () => {
	const [isVisible, setIsVisible] = useState(!document.hidden);

	useEffect(() => {
		const handleVisibilityChange = () => {
			setIsVisible(!document.hidden);
		};

		document.addEventListener("visibilitychange", handleVisibilityChange);

		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
		};
	}, []);

	return isVisible;
};

export default usePageVisibility;
