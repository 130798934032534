/**
 * 마이린미 - 건강 MBTI 테스트
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";

import TestStatusRange from "src/pages/component/myLinme/MBTI/testStatusRange";
import TestQuestionList from "src/pages/component/myLinme/MBTI/testQuestionList";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myLinme/MBTITest.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const MyLinmeMBTITest = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const [introClassName, setIntroClassName] = useState(null);
	const [percent, setPercent] = useState(0);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 스크롤 이벤트 발생 시 - 테스트 진행상태바 고정;
	 * @param {ScrollEvent} e
	 */
	const handlerScroll = (e) => {
		const min = 200;
		const max = window.document?.body?.scrollHeight - 685;
		if (window.scrollY > min && window.scrollY < max) {
			setIntroClassName("fixed");
		} else {
			setIntroClassName(null);
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 답변 후 진행률에 대해 반영
	 * @param {Number} percent
	 */
	const setTestStatusPercent = (percent) => {
		setPercent(percent);
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		//스크롤 이벤트 설정 - 진행바 위치 조절;
		window.addEventListener("scroll", handlerScroll);

		return () => {
			//스크롤 이벤트 해제 - 진행바 위치 조절;
			window.removeEventListener("scroll", handlerScroll);
		};
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div style={{ position: "relative", height: "100%" }}>
			<div
				className={
					"container myLinme healthMBTITestIntro " + (introClassName ? introClassName : "")
				}
			>
				<div className="mbtiIntro">
					<div className="title">
						<span>{textInfo.myLinme.mbti.test.title1}</span>
						<span>{textInfo.myLinme.mbti.test.title2}</span>
					</div>
					<ul className="descriptionList">
						<li className="descriptionItem">{textInfo.myLinme.mbti.test.guide1}</li>
						<li className="descriptionItem">{textInfo.myLinme.mbti.test.guide2}</li>
						<li className="descriptionItem">{textInfo.myLinme.mbti.test.guide3}</li>
					</ul>
				</div>
				<TestStatusRange percent={percent} />
			</div>
			<div
				className={
					`container myLinme healthMBTITestContainer ` + (introClassName ? introClassName : "")
				}
			>
				<div className="contents">
					<TestQuestionList setTestStatusPercent={setTestStatusPercent} />
				</div>
			</div>
		</div>
	);
};

export default MyLinmeMBTITest;
