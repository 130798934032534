/**
 * 모달창
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import React, { useEffect } from "react";
import styled from "styled-components";

import useModalStack from "src/hooks/useModalStack";
import { COLOR } from "src/data/constEnum";
import { setBodyScroll } from "src/utils/linmeDisplay";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

const textInfo = require("src/data/textInfo.json");

const ModalCover = styled.div`
	position: absolute;
	display: block;
	width: fit-content;
	height: fit-content;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: ${COLOR.WHITE};
`;

const Modal = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	const handlerBeforeUnloadEvent = (e) => {
		e.preventDefault();
		e.returnValue = ""; //deprecated;
	};

	//마운트시;
	useEffect(() => {
		setBodyScroll(false);

		// 모달 외 영역 (dimmer) 클릭 시 모달 제거;
		const handlerClick = (e) => {
			if (e.target.classList.contains("modalDimmer")) {
				modalStack.removeModal(props.id);
			}
		};
		window.addEventListener("click", handlerClick);
		// window.addEventListener( "beforeunload", handlerBeforeUnloadEvent );//창닫기;

		//언마운트 시;
		return () => {
			if (window.document.querySelector("#modal").childElementCount === 0) setBodyScroll(true);
			window.removeEventListener("click", handlerClick);
			// window.removeEventListener( "beforeunload", handlerBeforeUnloadEvent );
		};
	}, []);

	/**
	 * Confirm "확인" 클릭 시
	 * @param {Event} e
	 */
	const handlerClickConfirm = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (props.button && props.button.confirm && props.button.confirm.fn) {
			props.button.confirm.fn(e);
		} else {
			modalStack.removeModal(props.id);
		}
	};

	/**
	 * Alert "확인" 클릭 시
	 * @param {Event} e
	 */
	const handlerClickOk = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (props.button && props.button.ok && props.button.ok.fn) props.button.ok.fn(e);
		else {
			modalStack.removeModal(props.id);
		}
	};

	/**
	 * "취소" 클릭 시
	 * @param {Event} e
	 */
	const handlerClickCancel = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (props.button && props.button.cancel && props.button.cancel.fn) props.button.cancel.fn(e);
		else {
			modalStack.removeModal(props.id);
		}
	};

	/**
	 * 우상단 닫기 아이콘 클릭 시
	 * @param {Event} e
	 */
	const handlerClickClose = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.removeModal(props.id);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	const makeButton = (type, buttonText) => {
		if (props.button && props.button[type]) {
			const d = props.button[type];
			let handlerClick = handlerClickClose;
			if (d.fn) handlerClick = d.fn;
			return (
				<button
					className={"modalButton " + type}
					key={"modalButton " + type}
					style={d.style ? d.style : {}}
					onClick={(e) => handlerClick(e)}
				>
					{d.text ? d.text : buttonText}
				</button>
			);
		} else {
			return (
				<button
					className={"modalButton " + type}
					key={"modalButton " + type}
					onClick={(e) => handlerClickClose(e)}
				>
					{buttonText}
				</button>
			);
		}
	};

	const drawButton = () => {
		let r = [];
		if (props.button) {
			const dataButton = props.button;
			if (dataButton.iconClose)
				r.push(
					<button
						className="modalButton close"
						key="modalButton close"
						onClick={(e) => handlerClickClose(e)}
					/>
				);
			switch (props.type) {
				case "confirm":
					if (dataButton.cancel) r.push(makeButton("cancel", textInfo.button.cancel));
					if (dataButton.confirm) r.push(makeButton("confirm", textInfo.button.confirm));
					break;
				case "delete":
				case "alert":
					if (dataButton.ok) r.push(makeButton("ok", textInfo.button.confirm));
					break;
				case "custom":
					for (let key in dataButton) {
						r.push(makeButton(key, dataButton[key].text));
						/*/
						if( dataButton[ key ].fn ) r.push( <button className={"modalButton " + key} key={"modalButton " + key} onClick={(e) => dataButton[ key ].fn(e)}>{dataButton[ key ].text}</button> );
						else r.push( <button className={"modalButton " + key} key={"modalButton " + key}>{dataButton[ key ].text}</button> );
						//*/
					}
					break;
				default:
					break;
			}
		} else {
			switch (props.type) {
				case "confirm":
				case "delete":
				case "alert":
				case "custom":
				default:
					r.push(makeButton("ok", textInfo.button.confirm));
					break;
			}
		}
		return r;
	};

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<ModalCover className={"modalContents " + props.id + "Cover"}>
			{React.cloneElement(props.component, { ...props })}
			<div className="modalButtonCover">{drawButton()}</div>
		</ModalCover>
	);
};
export default Modal;
