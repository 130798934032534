/**
 * 상품 상세 - 상품 문의
 */

import moment from "moment";
import { useEffect, useRef, useState, memo } from "react";
import { useNavigate } from "react-router-dom";

import useAuth from "src/hooks/useAuth";
import useModalStack from "src/hooks/useModalStack";

import { getInquiryListProduct } from "src/api/product/inquiry";
import {
	API_RESULT_STATUS,
	FORMAT_MOMENT,
	INQUIRY_STATUS,
	PAGINATION_DEFAULT_OFFSET,
} from "src/data/constEnum";
import ProductModalInquiryWrite from "src/pages/component/inquiry/productInquiryWrite";
import { setScrollTop } from "src/utils/linmeDisplay";
import { getLastPageNumber } from "src/utils/number";
// import InquiryWriteConfirm from "src/pages/product/modal/inquiryWriteConfirm";
// import Image from "src/pages/component/common/image";
import ModalCommonAlert from "src/pages/common/modalAlert";
import Pagination from "src/pages/component/pagination";
import InquiryDetail from "src/pages/component/product/detail/inquiry/inquiryDetail";

const pathList = require("src/path/pathJSON.json");
const textInfo = require("src/data/textInfo.json");

const ProductTabContentProductInquiry = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const auth = useAuth();
	const modalStack = useModalStack();
	const navigate = useNavigate();
	const [list, setList] = useState(null);
	const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);
	const [paginationOffset, setPaginationOffset] = useState(PAGINATION_DEFAULT_OFFSET.DEFAULT);
	const [paginationTotalCount, setPaginationTotalCount] = useState(0);
	const [paginationLastPage, setPaginationLastPage] = useState(0);
	const [paginationIsLastPage, setPaginationIsLastPage] = useState(false);
	const ref = {
		aTag: useRef(null),
		content: useRef(null),
	};

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 탭 고정 및 className 변경을 위한 스크롤 체크
	 * 현재의 뷰포트에 targetElement가 존재하면 callCheckObserve 에 설정된 함수를 호출
	 */
	/*/
	useIsVisible({
		targetType: "inquiry"
		, targetRef: ref
		, options: {
			rootMargin: '0px',
			threshold: 1.0, // visibleRef가 모두 보였을 때만 true
		}
		, callCheckObserve : props.setActivateMenu
	});
	//*/

	/**
	 * 문의 1건 클릭 시
	 * @param {Event} e
	 */
	const handlerClickInquiry = (e, inquiryItem) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const ct = e.currentTarget;

		if (inquiryItem?.isCheckSecret && auth?.user?.memberNo !== inquiryItem?.memberNo) {
			return false;
		}

		const elDetail = ct.querySelector(".inquiryDetail");
		if (elDetail) {
			if (elDetail.classList.contains("on")) {
				elDetail.classList.remove("on");
			} else {
				elDetail.classList.add("on");
			}
		}
	};

	/**
	 * 문의하기 모달 내에서 확인 클릭 시
	 * @param {Event} e
	 */
	const handlerClickInquiryWrite = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();
	};

	/**
	 * 문의하기
	 * @param {Event} e
	 */
	const handlerClickOpenModalInquiryWrite = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		// 로그인 체크
		if (!auth.isLogin) {
			modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				html: textInfo.APIError.needLogin,
				button: {
					ok: {
						text: textInfo.button.confirm,
						fn: () => {
							modalStack.removeModal("modalCommonAlert");
							navigate(pathList.member.login);
						},
					},
				},
			});
			return;
		}

		modalStack.addModal({
			id: "modalInquiryWrite",
			type: "confirm",
			component: <ProductModalInquiryWrite />,
			data: props,
			redraw: redraw,
		});
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 문의 목록 다시 그리기 ( 페이징 등 )
	 * @param {Number} pageNo
	 */
	const redraw = async (pageNo) => {
		await requestGetInquiryListProduct(pageNo);
		await setScrollTop();
	};

	/**
	 * 문의 목록 요청
	 * @param {Number} pageNo
	 */
	const requestGetInquiryListProduct = async (pageNo) => {
		try {
			const d = {
				offset: paginationOffset,
				page: pageNo,
			};
			const result = await getInquiryListProduct(props.productNo, d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (result.data.content) {
					setList(result.data.content);

					setPaginationCurrentPage(result.data.page);
					setPaginationTotalCount(result.data.totalCount);
					setPaginationIsLastPage(result.data.isLastPage);

					setPaginationLastPage(getLastPageNumber(result.data.totalCount, paginationOffset));
				}
			} else {
				window.linmeLog.log(result);
				// modalStack.addModal({
				// 	id: "modalCommonAlert"
				// 	, type: "alert"
				// 	, component: <ModalCommonAlert />
				// 	, html: htmlInfo.error.dataCall
				// });
				return false;
			}
		} catch (err) {
			window.linmeLog.error(err);
			// modalStack.addModal({
			// 	id: "modalCommonAlert"
			// 	, type: "alert"
			// 	, component: <ModalCommonAlert />
			// 	, html: htmlInfo.error.dataCall
			// });
			return false;
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		const getData = async () => {
			if (props && props.productNo && !list) {
				await requestGetInquiryListProduct(1);
			}
		};
		getData();
	}, [props?.productNo]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="productInquiry" ref={ref.content}>
			<a
				id="tabContentProductInquiry"
				ref={ref.aTag}
				href="{()=>false}"
				onClick={(e) => e.preventDefault()}
				style={{ cursor: "unset" }}
			>
				<div className="title">
					{textInfo.inquiry.title}
					<span className="inquiryCount">{props.inquiryCount.toLocaleString()}</span>
				</div>
				<div
					className="button inquiry productInquiry"
					onClick={(e) => handlerClickOpenModalInquiryWrite(e)}
				>
					{textInfo.button.inquiryWrite}
				</div>
				<ul className="inquiryList">
					<li className="inquiry">
						<div className="inquiryCover">
							<div className="inquiryTitle">{textInfo.inquiry.contentTitle}</div>
							<div className="inquiryWriter">{textInfo.inquiry.contentWriter}</div>
							<div className="inquiryWriteDate">{textInfo.inquiry.contentWriteDate}</div>
							<div className="inquiryStatus">{textInfo.inquiry.contentStatus}</div>
						</div>
					</li>
					{list && list.length ? (
						list.map((item, index) => (
							<li className="inquiryItem" key={index} onClick={(e) => handlerClickInquiry(e, item)}>
								<div className={"inquiryCover " + (item.isCheckSecret ? "private" : "")}>
									<div className="inquiryTitle">{item.content}</div>
									<div className="inquiryWriter">{item?.writer?.replace(/(?<=.{1})./gi, "*")}</div>
									<div className="inquiryWriteDate">
										{moment(item.registered).format(FORMAT_MOMENT.YMD)}
									</div>
									<div className="inquiryStatus">
										<button
											className={
												"button inquiry status " + (item.status ? item.status.toLowerCase() : "")
											}
										>
											{item.status ? INQUIRY_STATUS[item.status].name : "-"}
										</button>
									</div>
								</div>
								<InquiryDetail inquiry={item} />
							</li>
						))
					) : (
						<li className="noData"></li>
					)}
				</ul>
				{list && list.length ? (
					<Pagination
						{...{
							currentPage: paginationCurrentPage,
							offset: paginationOffset,
							totalCount: paginationTotalCount,
							lastPage: paginationLastPage,
							isLastPage: paginationIsLastPage,
							redraw: redraw,
						}}
					/>
				) : (
					<></>
				)}
			</a>
		</div>
	);
};

export default memo(ProductTabContentProductInquiry);
