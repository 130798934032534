/**
 * 쇼핑내역 - 주문/배송 - 환불하기 시 환불 대상이 여러 건인 경우 선택 모달
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useRef, useState } from "react";

import useModalStack from "src/hooks/useModalStack";
import { PRODUCT_OPTIONS_TYPE } from "src/data/constEnum";
import { setInnerSpinner, removeInnerSpinner } from "src/utils/linmeDisplay";

import Image from "src/pages/component/common/image";
import ModalCommonAlert from "src/pages/common/modalAlert";
import ProductOptionBox from "src/pages/component/order/productOptionBox";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myPage/order/selectRefundTarget.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const SelectRefundTarget = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();
	const [list, setList] = useState(null);
	const ref = {
		list: useRef(null),
		selectAll: useRef(null),
		selectedCount: useRef(null),
	};

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 전체 선택
	 * @param {Event} e
	 */
	const handlerChangeSelectAll = (e) => {
		const ct = ref.selectAll.current;
		const elList = ref.list.current;
		const checked = ct.checked;
		const elsCheckbox = elList.querySelectorAll("input[type='checkbox']");

		let i = 0;
		const iLen = elsCheckbox.length;
		let io; //elsCheckbox[ i ];
		for (; i < iLen; ++i) {
			io = elsCheckbox[i];
			io.checked = checked;
		}

		const count = elList.querySelectorAll("input[type='checkbox']:checked").length;
		ref.selectedCount.current.innerText = count;
	};

	/**
	 * 각 아이템 선택
	 * @param {Event} e
	 * @param {Object} item
	 */
	const handlerChangeSelectItem = (e, item) => {
		const ct = e.currentTarget;
		const checked = ct.checked;
		const elList = ref.list.current;

		//선택한 상품이 필수옵션인 경우 선택옵션 자동 선택;
		if (item.product.option.type === PRODUCT_OPTIONS_TYPE.REQUIRED) {
			const productNo = item.product.productNo;
			//동일 상품의 옵션 목록;
			const sameProductList = props.list.filter((item) => item.product.productNo === productNo);
			//동일 상품 중 필수옵션 개수가 1개만 있는 경우;
			if (
				sameProductList.filter((item) => item.product.option.type === PRODUCT_OPTIONS_TYPE.REQUIRED)
					.length === 1
			) {
				//모든 선택옵션에 대해 checked 공통 적용;
				elList
					.querySelectorAll("input[type='checkbox'][data-productno='" + productNo + "']")
					.forEach((el) => {
						el.checked = checked;
					});
			}
		} else {
			//선택한 상품이 선택옵션인 경우;
		}
		const count = elList.querySelectorAll("input[type='checkbox']:checked").length;
		ref.selectedCount.current.innerText = count;

		if (count === list.length) {
			ref.selectAll.current.checked = true;
		} else {
			ref.selectAll.current.checked = false;
		}
	};

	/**
	 * 취소 클릭 시
	 * @param {Event} e
	 */
	const handlerClickClose = async (e) => {
		//버블링 막기;
		await e.preventDefault();
		await e.stopPropagation();

		await modalStack.removeModal(props.id);
	};

	/**
	 * 완료 클릭 시
	 * @param {Event} e
	 */
	const handlerClickComplete = async (e) => {
		//버블링 막기;
		await e.preventDefault();
		await e.stopPropagation();

		const elList = ref.list.current;
		const elsCheckbox = elList.querySelectorAll("input[type='checkbox']:checked");

		if (elsCheckbox.length === 0) {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.alert.selectTargetRefund,
			});
			return false;
		}

		let items = [];
		let itemNoList = [];
		let i = 0;
		const iLen = elsCheckbox.length;
		let io; //elsCheckbox[ i ];
		for (; i < iLen; ++i) {
			io = elsCheckbox[i];
			items.push(list[io.value]);
			itemNoList.push(list[io.value].itemNo);
		}

		if (props.handlerClickConfirm) {
			await modalStack.removeModal(props.id);
			await props.handlerClickConfirm(items, itemNoList, props.order);
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		const getData = async () => {
			await setInnerSpinner();
			if (props.list) {
				await setList(props.list);
			}
			await removeInnerSpinner();
		};
		getData();
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="selectRefundTargetContainer">
			<div className="title">{textInfo.orderRefund.selectRefundTarget}</div>
			<div className="selectAllCover">
				<input
					type="checkbox"
					id="selectAllRefundTarget"
					onChange={(e) => handlerChangeSelectAll(e)}
					ref={ref.selectAll}
				/>
				<label htmlFor="selectAllRefundTarget">{textInfo.button.selectAll}</label>
				{list && list.length ? (
					<span>
						<span className="selectedCount" ref={ref.selectedCount}>
							0
						</span>
						&nbsp;/&nbsp;{list.length}
					</span>
				) : (
					<></>
				)}
			</div>
			<ul className="refundTargetList" ref={ref.list}>
				{list && list.length ? (
					list.map((item, index) => (
						<li className="refundTargetItem" key={index}>
							<div className="selectRefundTargetCover">
								<input
									type="checkbox"
									id={"selectRefundTarget" + index}
									value={index}
									data-value={item.itemNo}
									data-productno={item.product.productNo}
									onChange={(e) => handlerChangeSelectItem(e, item)}
								/>
								<label htmlFor={"selectRefundTarget" + index} />
							</div>
							<div className="productImageCover">
								<Image src={item.product.thumbnail.webPath} />
							</div>
							<div className="productInfo">
								<div className="productName">{item.product.name}</div>
								{/* 옵션 */}
								{item?.product?.option?.type === PRODUCT_OPTIONS_TYPE.REQUIRED &&
								item?.product?.option?.categoryName1 === "" &&
								item?.product?.option?.optionName1 === "" ? (
									<div className="productOptionQuantity">
										{item.quantity}
										<span className="quantityUnit">{textInfo.common.quantityUnit}</span>
									</div>
								) : (
									<>
										<div className="shoppingDetailProductItemOptionTitle">
											{item?.product?.option?.type === PRODUCT_OPTIONS_TYPE.REQUIRED
												? textInfo.product.detail.option.optionType.required
												: textInfo.product.detail.option.optionType.additional}
										</div>
										<div className="shoppingDetailProductItemOptionValue">
											<ProductOptionBox
												option={item.product.option}
												productData={item?.product}
												quantity={item?.quantity}
											/>
										</div>
									</>
								)}
							</div>
						</li>
					))
				) : (
					<></>
				)}
			</ul>
			<div className="buttonCover">
				<div className="button clickable close" onClick={(e) => handlerClickClose(e)}>
					{textInfo.button.cancel}
				</div>
				<div className="button clickable confirm" onClick={(e) => handlerClickComplete(e)}>
					{textInfo.button.complete}
				</div>
			</div>
		</div>
	);
};

export default SelectRefundTarget;
