/**
 * 카테고리 상품 목록
 */

import useCategory from "src/hooks/product/useCategory";
import useProductSearchByCategory from "src/hooks/search/useSearchProductByCategory";
import ProductList from "src/pages/component/product/productList";
import SearchCategoryFilter from "src/pages/product/searchCategoryFilter";
import PaginationWithQuery from "src/pages/component/paginationWithQuery";
import ProductSortList from "src/pages/component/product/productSortList";
import { PAGINATION_DEFAULT_OFFSET } from "src/data";

import "src/styles/product/categoryProductDisplay.scss";
import "src/styles/productList.scss";

const CategoryProductDisplay = () => {
	const { categoryList, isLoading } = useCategory();
	const { productList, totalPage } = useProductSearchByCategory();

	if (isLoading) return;

	return (
		<div className="container underTopMenu categoryProductDisplay">
			<div className="contents">
				<SearchCategoryFilter categoryList={categoryList} />
				<ProductSortList />
				<div className="productDisplayContainer">
					<div className="productListContainer">
						{productList && productList.length > 0 ? (
							<ProductList
								{...{ items: productList }}
								limit={PAGINATION_DEFAULT_OFFSET.PRODUCT_LIST}
							/>
						) : (
							<div className="noData"></div>
						)}
					</div>
					{productList && productList.length > 0 && <PaginationWithQuery totalPage={totalPage} />}
				</div>
			</div>
			<div className="divisionTransparent70" />
		</div>
	);
};

export default CategoryProductDisplay;
