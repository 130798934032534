/**
 * 건강고민 추천 상품 목록
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useContext, useEffect, useState } from "react";
import { AppContext } from "src/App";

import useAuth from "src/hooks/useAuth";
import useQueryParams from "src/hooks/useQueryParams";
import { getIssueProductList } from "src/api/product/issue";
import useIssueList from "src/hooks/useIssueList";
import ProductIssueFilter from "src/pages/component/product/productIssueFilter";
import ProductListPagination from "src/pages/component/product/productListPagination";
import ProductListInfinityScroll from "src/pages/component/product/productListInfinityScroll";
import {
	DEVICE_TYPE,
	PAGINATION_DEFAULT_OFFSET,
	PAGINATION_TYPE,
	PRODUCT_LIST_ORDER,
	PRODUCT_TYPE_NO,
	HEALTH_ISSUE_EFFECT,
	HEALTH_ISSUE_INGREDIENTS,
} from "src/data";
const textInfo = require("src/data/textInfo.json");
import "src/styles/product/healthIssueRecommendedProductDisplay.scss";

const HealthIssueRecommendedProductDisplay = () => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const auth = useAuth();
	const { device, mainSelectedMenuType } = useContext(AppContext);

	const [resultTotalCount, setResultTotalCount] = useState(0);
	const [paginationType, setPaginationType] = useState(
		device === DEVICE_TYPE.PC ? PAGINATION_TYPE.PAGE : PAGINATION_TYPE.SCROLL
	);
	const params = useQueryParams();
	const issueNo = Number(params.get("issueNo"));
	const param = {
		typeNo: PRODUCT_TYPE_NO[mainSelectedMenuType],
		issueNoList: [issueNo],
		offset: PAGINATION_DEFAULT_OFFSET.PRODUCT_LIST,
		page: 1,
		sort: PRODUCT_LIST_ORDER[0].value,
	};

	// 이슈항목 불러오기
	const { data: issueList } = useIssueList({
		typeNo: PRODUCT_TYPE_NO[mainSelectedMenuType],
		// 변환
		select: (data) => {
			const issueMap = {
				effect: HEALTH_ISSUE_EFFECT,
				ingredients: HEALTH_ISSUE_INGREDIENTS,
			};

			let result = data?.data.map((issue) => ({
				issueNo: issue.issueNo,
				name: issue.name,
				en: issueMap[mainSelectedMenuType][issue.name],
			}));

			result = [
				...result,
				{ issueNo: null, name: "접기", en: "closeHealthIssue" },
				{ issueNo: null, name: "열기", en: "openHealthIssue" },
			];

			// 탈모/손톱 제거
			return result.filter((issue) => issue.name !== "탈모/손톱");
		},
		staleTime: 1000 * 60 * 60,
	});

	//화면 resize 등;
	useEffect(() => {
		setPaginationType(device === DEVICE_TYPE.PC ? PAGINATION_TYPE.PAGE : PAGINATION_TYPE.SCROLL);
	}, [device]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="container underTopMenu healthIssueRecommendedProductDisplay">
			<div className="contents">
				<div className="title">
					{auth.isLogin ? (
						<span className="userInfo">
							{`'${auth.user.nickname} ${textInfo.healthIssue.member}'`}
						</span>
					) : (
						textInfo.healthIssue.notLoginMember + textInfo.healthIssue.member
					)}
					{textInfo.healthIssue.recommendedProductResult}{" "}
					<span className="resultCount">
						<span className="resultCountNumber">{resultTotalCount.toLocaleString()}</span>
						{textInfo.common.countUnit}
					</span>
				</div>
				{issueList && issueNo && (
					<div className="selectedHealthIssueNameList">
						# {issueList?.find((issue) => issue.issueNo === issueNo)?.name}
					</div>
				)}
			</div>
			<ProductIssueFilter issueList={issueList} selectedIssueNo={issueNo} />
			<div className="contents">
				{/* todo : query 방식 페이지네이션으로 변경 */}
				{paginationType === PAGINATION_TYPE.PAGE ? (
					<ProductListPagination
						getDataList={getIssueProductList}
						setResultTotalCount={setResultTotalCount}
						param={param}
					/>
				) : (
					<ProductListInfinityScroll
						getDataList={getIssueProductList}
						setResultTotalCount={setResultTotalCount}
						param={param}
					/>
				)}
			</div>
		</div>
	);
};

export default HealthIssueRecommendedProductDisplay;
