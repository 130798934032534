/**
 * 회원가입
 */

import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/**
 * 이메일 중복 체크
 * @param {Object} d
 * <code>
 * {
 * 		email: ""
 * }
 * </code>
 */
export const duplicateCheckEmail = async (d) => {
	const url = `${API_PATHS.MEMBER_DUPLICATE_CHECK_EMAIL}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 닉네임 중복 체크
 * @param {Object} d
 * <code>
 * {
 * 		nickname: ""
 * }
 * </code>
 */
export const duplicateCheckNickname = async (d) => {
	const url = `${API_PATHS.MEMBER_DUPLICATE_CHECK_NICKNAME}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 본인 인증 요청
 * @param {Object} d
 * <code>
 * {
 * 		env: "" //MOBILE, PC, APP;
 * 		, memberNo: 0 //회원식별자;
 * 		, type : "" //본인인증 유형(NEW:회원가입, PW:비밀번호 찾기, EMAIL:아이디 찾기, CHANGE:비밀번호 변경);
 * }
 * </code>
 */
export const requestVerify = async (d) => {
	const url = `${API_PATHS.MEMBER_VERIFY}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * ci 중복 조회
 * @param {Object} d
 * <code>
 * {
 * 		ci: ""
 * }
 * </code>
 * @returns
 */
export const duplicateCheckCi = async (d) => {
	const url = `${API_PATHS.MEMBER_DUPLICATE_CHECK_VERIFY_CI}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 회원 가입 - 일반
 * @param {Object} d
 * <code>
 * {
 * 		address1: ""
 * 		, address2: ""
 * 		, birth: ""
 * 		, ci: ""
 * 		, email: ""
 * 		, gender: ""
 * 		, isCheckAdEmail: false
 * 		, isCheckAdSms: false
 * 		, name: ""
 * 		, nickname: ""
 * 		, password: ""
 * 		, passwordConfirm: ""
 * 		, phone: ""
 * 		, postcode: ""
 * }
 * </code>
 * @returns
 */
export const requestSignup = async (d) => {
	const url = `${API_PATHS.MEMBER_SIGNUP}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
