/**
 * 소셜로그인 REDIRECT - KAKAO
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect } from "react";
import axios from "axios";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */

const RedirectSocialLoginKakao = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 인증코드로 access_token 발급하여 부모창에 전달
	 * @param {String} code
	 */
	const getToken = async (code) => {
		const d = {
			grant_type: "authorization_code",
			client_id: `${process.env.REACT_APP_SOCIAL_KAKAO_REST_API}`,
			redirect_uri: `${process.env.REACT_APP_SOCIAL_KAKAO_REDIRECT_URI}`,
			code: code,
			client_secret: `${process.env.REACT_APP_SOCIAL_KAKAO_SECRET}`,
		};
		const url = "https://kauth.kakao.com/oauth/token";
		await axios({
			url,
			headers: { "content-type": "application/x-www-form-urlencoded" },
			data: d,
			method: "POST",
		}).then((result) => {
			if (window.opener) {
				if (result.status === 200 && result.data.access_token) {
					const data = JSON.parse(JSON.stringify(result.data));
					data.snsType = "KAKAO";
					window.opener.postMessage(data, "*");
				}
			}
			window.self.close();
		});
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		//인증코드;
		const code = new URL(window.location.href).searchParams.get("code");
		// const error = new URL(window.location.href).searchParams.get("error");
		if (code) {
			getToken(code);
		}
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return <></>;
};

export default RedirectSocialLoginKakao;
