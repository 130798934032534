import apiMyLinme from "src/api/axiosMyLinme";
import API_PATHS from "src/api/apiPaths";

/**
 * 알람 목록 조회
 * @param {Object} d
 * <code>
 * [{
 * 		page : 1
 * 		, offset : 10
 * }]
 * </code>
 * @returns
 */
export const getAlarmList = async (d) => {
	const url = `${API_PATHS.ALARM_LIST}`;
	try {
		const result = await apiMyLinme.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 알람 등록
 * @param {Object} d
 * <code>
	{
		"orderItemNo" : 22,
		"productNo" : 400,
		"cycle" : 1,
		"intake" : 1,
		"unit" : "포",
		"productName" : "닭곰탕, 닭다리",
		"productImagePath" : "www.image",
		"startDate" : "2024-06-19",
		"endDate" : "2024-06-30",
		"holidayFlagCd" : true,
		"timeForMorning" : "",
		"activeFlagForMorningCd" : false,
		"timeForAfternoon" : "14:00",
		"activeFlagForAfternoonCd" : true,
		"timeForEvening" : "",
		"activeFlagForEveningCd" : false,
		"remainQty" : 10,
		"totQty" : 10
	}
 * </code>
 * @returns
 */
export const creditAlarm = async (d) => {
	const url = `${API_PATHS.ALARM_CREDIT}`;
	try {
		const result = await apiMyLinme.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 알람 수정
 * @param {Object} d
 * <code>
	{
   	    "alarmNo" : 123,
		"orderItemNo" : 22,
		"productNo" : 400,
		"cycle" : 1,
		"intake" : 1,
		"unit" : "포",
		"productName" : "닭곰탕, 닭다리",
		"productImagePath" : "www.image",
		"startDate" : "2024-06-19",
		"endDate" : "2024-06-30",
		"holidayFlagCd" : true,
		"timeForMorning" : "",
		"activeFlagForMorningCd" : false,
		"timeForAfternoon" : "14:00",
		"activeFlagForAfternoonCd" : true,
		"timeForEvening" : "",
		"activeFlagForEveningCd" : false,
		"remainQty" : 10,
		"totQty" : 10
	}
 * </code>
 * @returns
 */
export const modifyAlarm = async (d) => {
	const url = `${API_PATHS.ALARM_MODIFY}`;
	try {
		const result = await apiMyLinme.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 알람 삭제
 * @param {Object} d
 * <code>
	{
		alarmNo : 1
 * </code>
 * @returns
 */
export const deleteAlarm = async (d) => {
	const url = `${API_PATHS.ALARM_DELETE}`;
	try {
		const result = await apiMyLinme.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 알람 ON / OFF
 * @param {Object} d
 * <code>
	{
	alarmNo = 1
	action = true
	}
 * </code>
 * @returns
 */
export const onOffAlarm = async (d) => {
	const url = `${API_PATHS.ALARM_ONOFF}`;
	try {
		const result = await apiMyLinme.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 알람 건너뛰기 / 섭취완료
 * @param {Object} d
 * <code>
	{
	alarmNo = 1
	onOff = true
	}
 * </code>
 * @returns
 */
export const actionAlarm = async (d) => {
	const url = `${API_PATHS.ALARM_ACTION}`;
	try {
		const result = await apiMyLinme.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 알람 적용 상품 검색
 * @param {Object} d
 * <code>
 * [{
 * 		page : 1
 * 		, offset : 10
 * }]
 * </code>
 * @returns
 */
export const getAlarmProductList = async (d) => {
	const url = `${API_PATHS.ALARM_PRODUCT_LIST}`;
	try {
		const result = await apiMyLinme.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 구매확정 후 알람 등록
 * @param {Object} d
 * <code>
	{
	orderItemNo= 1234
	}
 * </code>
 * @returns
 */
export const confirmCredit = async (d) => {
	const url = `${API_PATHS.ALARM_CONFIRM_CREDIT}`;
	try {
		const result = await apiMyLinme.post(url, null, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
