/**
 * 마이페이지 - 쇼핑 내역 탭
 */

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "src/styles/myPage/shopping.scss";

const pathList = require("src/path/pathJSON.json");
const textInfo = require("src/data/textInfo.json");

const MyPageShoppingTab = () => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const navigate = useNavigate();

	const [path, setPath] = useState("order");

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 활성화 메뉴 변경
	 * @param {Event} e
	 */
	const handlerClickListType = (key) => {
		switch (key) {
			case "order":
				navigate(pathList.myPage.shoppingOrder);
				break;
			case "claim":
				navigate(pathList.myPage.shoppingClaim);
				break;
			default:
				break;
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		const pathArray = window.location.pathname.split("/");
		const orderPart = pathArray[pathArray.length - 1];

		if (orderPart === "order") {
			setPath("order");
		} else if (orderPart === "claim") {
			setPath("claim");
		}
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<ul className="statusTypeList">
			<li
				className={"statusTypeItem order " + (path === "order" ? "on" : "")}
				onClick={() => handlerClickListType("order")}
			>
				{textInfo.myPage.myShopping.order.title}
			</li>
			<li
				className={"statusTypeItem claim " + (path === "claim" ? "on" : "")}
				onClick={() => handlerClickListType("claim")}
			>
				{textInfo.myPage.myShopping.claim.title}
			</li>
		</ul>
	);
};

export default MyPageShoppingTab;
