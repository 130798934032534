/**
 * 상품 리뷰 관련
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */
import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/* ---------------------------------------------------------------------------------------------------- */
/* FUNCTION */
/* ---------------------------------------------------------------------------------------------------- */

/**
 * 리뷰 삭제
 * @param {Number} reviewNo
 * @returns
 */
export const deleteReview = async (reviewNo) => {
	const url = `${API_PATHS.PRODUCT_DETAIL_REVIEW_DELETE}`;
	try {
		const result = await api.delete(url.replace(":reviewNo", reviewNo));
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 리뷰 수정
 * @param {Number} reviewNo
 * @param {Number} itemNo
 * @param {Object} d
 * <code>
 * {
 * 		*content: ""
 * 		, delFileNoList: [ 0, 0, ... ]
 * 		, fileNoList: [ 0, 0, ... ]
 * 		, *rate: 0
 * }
 * </code>
 * @returns
 */
export const modifyReview = async (reviewNo, itemNo, d) => {
	const url = `${API_PATHS.PRODUCT_DETAIL_REVIEW_MODIFY}`;
	try {
		const result = await api.put(url.replace(":reviewNo", reviewNo).replace(":itemNo", itemNo), d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 리뷰 단건 조회
 * @param {Number} productNo
 * @param {Number} reviewNo
 * @returns
 */
export const getReviewDetail = async (productNo, reviewNo) => {
	const url = `${API_PATHS.PRODUCT_DETAIL_REVIEW_DETAIL}`;
	try {
		const result = await api.get(
			url.replace(":productNo", productNo).replace(":reviewNo", reviewNo)
		);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 리뷰 좋아요/좋아요 취소
 * @param {Number} reviewNo//리뷰번호;
 * @returns
 */
export const setReviewLike = async (reviewNo) => {
	const url = `${API_PATHS.PRODUCT_DETAIL_REVIEW_LIKE}`;
	try {
		const result = await api.post(url.replace(":reviewNo", reviewNo));
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 리뷰 작성
 * @param {Number} itemNo
 * @param {Object} d
 * <code>
 * {
 * 		*content: ""
 * 		, fileNoList: [ 0, 0, ... ]
 * 		, *rate: 0
 * }
 * </code>
 * @returns
 */
export const writeReview = async (itemNo, d) => {
	const url = `${API_PATHS.PRODUCT_DETAIL_REVIEW_WRITE}`;
	try {
		const result = await api.post(url.replace(":itemNo", itemNo), d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
