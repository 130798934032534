/**
 * 메인 - 전체 상품 목록
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useContext, useEffect, useState } from "react";

import { PAGINATION_DEFAULT_OFFSET, PRODUCT_LIST_ORDER, PRODUCT_TYPE_NO } from "src/data/constEnum";

import { AppContext } from "src/App";
import ProductListInfinityScroll from "src/pages/component/product/productListInfinityScroll";
import { productListCategory } from "src/api/product/category";

const textInfo = require("src/data/textInfo.json");

const AllProductList = () => {
	const { mainSelectedMenuType } = useContext(AppContext);

	const [param, setParam] = useState({
		typeNo: PRODUCT_TYPE_NO[mainSelectedMenuType],
		offset: PAGINATION_DEFAULT_OFFSET.PRODUCT_LIST,
		page: 1,
		sort: PRODUCT_LIST_ORDER[0].value,
		categoryNoList: -1,
	});

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		setParam({ ...param, typeNo: PRODUCT_TYPE_NO[mainSelectedMenuType] });
	}, [mainSelectedMenuType]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="container allProductList">
			<div className="contents">
				<div className="allProductListTitle">{textInfo.common.allProductList}</div>
				<ProductListInfinityScroll getDataList={productListCategory} param={param} />
			</div>
		</div>
	);
};

export default AllProductList;
