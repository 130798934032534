/**
 * 검색 레이어
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { Link } from "react-router-dom";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/topLayer.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
// const textInfo = require( "src/data/textInfo.json" );

const TopLayer = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<>
			{props?.isShow && (
				<div
					className="backgroundLayer"
					onClick={() => {
						props?.setIsShow(false);
					}}
				/>
			)}
			<div
				className={"topLayer" + (props?.isShow === true ? " on " : "")}
				onMouseLeave={() => {
					props?.setIsShow(false);
				}}
			>
				{props?.data.map((el, idx) => {
					if (el?.onClick)
						return (
							<div key={idx} onClick={el.onClick} className="item">
								{el.label}
							</div>
						);
					return (
						<Link key={idx} to={el.path}>
							<div className="item">{el.label}</div>
						</Link>
					);
				})}
			</div>
		</>
	);
};

export default TopLayer;
