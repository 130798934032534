/**
 * 결제 완료
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

// import { orderPaymentResult } from "src/api/order/payment";
import { orderDetail } from "src/api/myShopping/order";
import { API_RESULT_STATUS, PAYMENT_METHOD_TYPE } from "src/data/constEnum";
import { getTimeStampMomentKo } from "src/utils/date";

import CartProgressStage from "src/pages/component/cart/cartProgressStage";
import Image from "src/pages/component/common/image";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/payment/paymentComplete.scss";
import useAuth from "src/hooks/useAuth";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

const htmlInfo = require("src/data/htmlInfo.json");
const textInfo = require("src/data/textInfo.json");

const PaymentComplete = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const auth = useAuth();
	const location = useLocation();
	const [orderNo, setOrderNo] = useState(null);
	const [data, setData] = useState(null);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 상품 수 카운트하여 반환
	 * @returns {String}
	 */
	const calcProductCount = () => {
		let result = "";
		let count = 0;
		if (data && data.orderItems && data.orderItems.length) {
			let i = 0;
			const iLen = data.orderItems.length;
			let io = null; //data.orderItems[ i ];
			let temp = [];
			for (i; i < iLen; ++i) {
				io = data.orderItems[i].product;
				temp.push(io.productNo);
			}
			temp = Array.from(new Set(temp)); //중복 제거;
			count = temp.length;
			// if( count > 1 ) count -= 1;
		}
		/*/
		if( count > 1 ) result = count.toLocaleString() + textInfo.common.other + " " + textInfo.common.orderCountUnit;
		else result = count.toLocaleString() + textInfo.common.orderCountUnit;
		//*/
		result = count.toLocaleString() + textInfo.common.orderCountUnit;
		return result;
	};

	/**
	 * 주문번호로 정보 호출
	 * @param {Number} orderNo
	 * @returns
	 */
	const requestOrderDetail = async (orderNo) => {
		try {
			const result = await orderDetail(orderNo);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				await setData(result.data);
				return result.data;
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (error) {
			window.linmeLog.log(error);
			return false;
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		const getData = async () => {
			if (location.state !== null && location.state?.orderNo) {
				const orderNo = location.state?.orderNo;
				if (orderNo) await requestOrderDetail(orderNo);
			}
		};
		getData();
		auth.setUserData();
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="container underTopMenu paymentCompleteContainer">
			<div className="contentsBackground">
				<div className="contents">
					<CartProgressStage {...{ on: "step3" }} />
				</div>
				<div className="contents orderCover">
					<div className="orderContentContainer">
						<div className="orderContentLeft">
							<div
								className="orderCompleteText"
								dangerouslySetInnerHTML={{ __html: htmlInfo.order.orderComplete }}
							/>
							<div className="orderCompleteAfterGuide">
								{textInfo.order.orderCompleteAfterGuide}
							</div>
							<ul className="orderInfo">
								<li>
									<div className="label">{textInfo.order.orderNo}</div>
									<div className="data">{data ? data.orderCode : ""}</div>
								</li>
								<li>
									<div className="label">{textInfo.order.orderDate}</div>
									<div className="data">
										{data ? getTimeStampMomentKo(data.registered, "YYYY년 MM월 DD일 a hh:mm") : ""}
									</div>
								</li>
								<li>
									<div className="label">{textInfo.order.deliveryAddress}</div>
									<div className="data">
										<dl>
											<dd>{data && data.address ? data.address.name : ""}</dd>
											<dd>{data?.address?.virtualNumber || data?.address?.phone || ""}</dd>
											<dd>{data && data.address ? data.address.postcode : ""}</dd>
											<dd className="address">
												{data && data.address
													? data.address.address1 + " " + data.address.address2
													: ""}
											</dd>
											{data && data.address && data.address.memo ? (
												<dd className="memo">{data.address.memo}</dd>
											) : (
												<></>
											)}
										</dl>
									</div>
								</li>
							</ul>
						</div>
						<div className="orderContentRight">
							<div className="orderProduct">
								{textInfo.order.orderItems}
								<span className="orderProductCount">
									{data && data.orderItems ? calcProductCount() : ""}
								</span>
							</div>
							<div className="orderProductInfo">
								<div className="productImageCover">
									{data && data.orderItems ? (
										<Image
											src={data?.orderItems?.[0].product?.thumbnail?.webPath}
											alt={data.orderItems[0].product.name}
										/>
									) : (
										<></>
									)}
								</div>
								<div className="productName">
									<span>{data && data.orderItems ? data.orderItems[0].product.name : ""}</span>
									<Link to={pathList.myPage.shoppingDetail + data?.orderNo}>
										<div className="button moveToDetail">{textInfo.button.moveToOrderDetail}</div>
									</Link>
								</div>
							</div>
							<ul className="priceInfoList">
								<li className="priceInfoItem">
									<div className="label">{textInfo.order.finalPrice}</div>
									<div className="data">
										{data && data.price ? data.price.finalPrice.toLocaleString() : 0}
										<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
									</div>
								</li>
								<li className="priceInfoItem">
									<div className="label">{textInfo.order.productPrice}</div>
									<div className="data">
										{data && data.price ? data.price.productPrice.toLocaleString() : 0}
										<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
									</div>
								</li>
								<li className="priceInfoItem">
									<div className="label">{textInfo.order.deliveryPrice}</div>
									<div className="data">
										{data && data.price ? data.price.deliveryPrice.toLocaleString() : 0}
										<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
									</div>
								</li>
								<li className="priceInfoItem">
									<div className="label">{textInfo.order.discountPrice}</div>
									<div className="data">
										(-)&nbsp;
										{data && data.price && data.price.discount
											? (
													Math.abs(data.price.discount.couponDiscountPrice) +
													Math.abs(data.price.discount.rewardDiscountPrice)
											  ).toLocaleString()
											: 0}
										<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
									</div>
								</li>
								{/* <li className="discountDetail">
									<div className="label">등급할인</div>
									<div className="data">(-)1,119<span className="currencyUnit">{textInfo.common.currencyUnit}</span></div>
								</li> */}
								{data && data.price && data.price.discount.couponDiscountPrice !== 0 ? (
									<li className="priceInfoItem discountDetail">
										<div className="label">{textInfo.order.discountCoupon}</div>
										<div className="data">
											(-)&nbsp;
											{data && data.price && data.price.discount
												? Math.abs(data.price.discount.couponDiscountPrice).toLocaleString()
												: 0}
											<span className="currencyUnit">{textInfo.common.currencyUnit}</span>
										</div>
									</li>
								) : (
									<></>
								)}
								<li className="priceInfoItem discountDetail">
									<div className="label">{textInfo.order.usedReward}</div>
									<div className="data">
										(-)&nbsp;
										{data && data.price && data.price.discount
											? Math.abs(data.price.discount.rewardDiscountPrice).toLocaleString()
											: 0}
										<span className="currencyUnit">{textInfo.common.rewardUnit}</span>
									</div>
								</li>
							</ul>
							<ul className="paymentInfo">
								<div className="paymentInfoTitle">{textInfo.order.payment.title}</div>
								<li>
									<div className="label">{textInfo.order.payment.method}</div>
									<div className="data">
										{data && data.payment && data.payment.method === PAYMENT_METHOD_TYPE.CARD ? (
											<>
												<span>
													{textInfo.payment.payMethod.creditCard} {textInfo.order.payment.pay}
												</span>
												<span>
													{data.payment.cardInfo.issuer} ({data.payment.cardInfo.cardNo}) |{" "}
													{data.payment.cardInfo.installment === 0
														? textInfo.payment.paid.card.once
														: data.payment.cardInfo.installment +
														  textInfo.payment.paid.card.installment}
												</span>
											</>
										) : (
											<></>
										)}
										{data && data.payment && data.payment.method === PAYMENT_METHOD_TYPE.REWARD ? (
											<>
												<span>{textInfo.payment.paid.reward}</span>
											</>
										) : (
											<></>
										)}
									</div>
								</li>
								<li>
									<div className="label">{textInfo.order.payment.approvalAt}</div>
									<div className="data">
										{
											data && data.payment && data.payment.method === PAYMENT_METHOD_TYPE.CARD
												? moment(data.payment.cardInfo.approvalAt).format("YYYY.MM.DD HH:mm:ss")
												: "" /* 신용카드 */
										}
										{
											data && data.payment && data.payment.method === PAYMENT_METHOD_TYPE.VBANK
												? ""
												: "" /* 가상계좌 */
										}
										{
											data && data.payment && data.payment.method === PAYMENT_METHOD_TYPE.PHONE
												? ""
												: "" /* 휴대폰결제 */
										}
										{
											data && data.payment && data.payment.method === PAYMENT_METHOD_TYPE.REWARD
												? moment(data.registered).format("YYYY.MM.DD HH:mm:ss")
												: "" /* 리워드결제 */
										}
									</div>
								</li>
							</ul>
						</div>
					</div>
					<div className="rewardsAfterOrderListContainer">
						<ul className="rewardsAfterOrderList">
							<li className="rewardsAfterOrderItem">
								<div className="label">{textInfo.order.rewardBenefit.title}</div>
								<div className="data">
									{textInfo.order.rewardBenefit.max}{" "}
									<span className="number">
										{data && data.reward ? data.reward?.totalReward?.toLocaleString() : 0}
									</span>
									&nbsp;<span className="rewardUnit">{textInfo.common.rewardUnit}</span>
								</div>
							</li>
							<li className="rewardsAfterOrderItem">
								<div className="label">{textInfo.order.rewardBenefit.purchaseConfirmation}</div>
								<div className="data">
									<span className="number">
										{data && data.reward ? data?.reward?.confirmReward?.toLocaleString() : 0}
									</span>
									<span className="rewardUnit">{textInfo.common.rewardUnit}</span>
								</div>
							</li>
							<li className="rewardsAfterOrderItem">
								<div className="label">
									{textInfo.order.rewardBenefit.notificationRegistrationCompleted}
								</div>
								<div className="data">
									<span className="number">
										{data && data.reward ? data?.reward?.nutrientReward?.toLocaleString() : 0}
									</span>
									<span className="rewardUnit">{textInfo.common.rewardUnit}</span>
								</div>
							</li>
							<li className="rewardsAfterOrderItem">
								<div className="label">{textInfo.order.rewardBenefit.writeReview}</div>
								<div className="data">
									<span className="number">
										{data && data.reward ? data?.reward?.reviewReward?.toLocaleString() : 0}
									</span>
									<span className="rewardUnit">{textInfo.common.rewardUnit}</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="orderCompleteButtonContainer">
				<Link to={pathList.myPage.shoppingOrder}>
					<div className="orderCompleteButton">{textInfo.button.moveToOrder}</div>
				</Link>
				<Link to={pathList.main}>
					<div className="orderCompleteButton">{textInfo.button.moveToShoppingHome}</div>
				</Link>
			</div>
		</div>
	);
};

export default PaymentComplete;
