import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import Image from "src/pages/component/common/image";
import styles from "src/styles/product/detail/productDetailTimer.module.scss";

const INTERVAL = 1000;

const ProductDetailTimer = ({ endAt, isShowTimer }) => {
	const [timeLeft, setTimeLeft] = useState({ hours: "00", minutes: "00", seconds: "00" });

	const calcTime = useCallback(() => {
		const now = moment();
		const endTime = moment(endAt);
		const duration = moment.duration(endTime.diff(now));

		const hours = Math.floor(duration.asHours());
		setTimeLeft({
			hours: hours.toString().padStart(2, "0"),
			minutes: moment(duration.minutes(), "m").format("mm"),
			seconds: moment(duration.seconds(), "s").format("ss"),
		});
	}, [endAt]);

	useEffect(() => {
		calcTime();
		const timerId = setInterval(calcTime, INTERVAL);
		return () => clearInterval(timerId);
	}, [calcTime]);

	if (!isShowTimer) return null;
	if (timeLeft.hours === "00" && timeLeft.minutes === "00" && timeLeft.seconds === "00") {
		return null;
	}

	return (
		<div className={styles.timer}>
			<Image
				className={styles.clockIcon}
				srcSet="
          ../../../../images/topic/clock_topic.png 1x,
          ../../../../images/topic/clock_topic@2x.png 2x,
          ../../../../images/topic/clock_topic@3x.png 3x
        "
				alt="타임 특가"
			/>
			<div className={styles.timerInfo}>
				<span>타임특가</span>
				<span className={styles.remainTime}>
					<span>{timeLeft.hours}</span>
					<span>:</span>
					<span>{timeLeft.minutes}</span>
					<span>:</span>
					<span>{timeLeft.seconds}</span>
				</span>
			</div>
		</div>
	);
};

export default React.memo(ProductDetailTimer);
