import useModalStack from "src/hooks/useModalStack";

import ReviewWriteConfirm from "src/pages/product/modal/reviewWriteConfirm";
const textInfo = require("src/data/textInfo.json");

/** 제품후기 작성 버튼 */
const WriteReviewButton = (props) => {
	const { checkData } = props;
	const modalStack = useModalStack();

	/**
	 * 리뷰 쓰기
	 * @param {Event} e
	 */
	const handlerClickOpenModalReviewWrite = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.addModal({
			id: "modalReviewWriteConfirm",
			type: "confirm",
			component: <ReviewWriteConfirm {...props} />,
			item: null,
			order: null,
			data: props,
			itemNo: checkData?.itemNo,
		});
	};

	return (
		<div className="button writeReview" onClick={(e) => handlerClickOpenModalReviewWrite(e)}>
			{textInfo.product.review.writeReview}
		</div>
	);
};

export default WriteReviewButton;
