// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thumbs .swiper-slide {
  opacity: 0.3;
}
.thumbs .swiper-slide-thumb-active {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/styles/swiperThumbs.scss"],"names":[],"mappings":"AACC;EACC,YAAA;AAAF;AAGC;EACC,UAAA;AADF","sourcesContent":[".thumbs {\n\t.swiper-slide {\n\t\topacity: 0.3;\n\t}\n\n\t.swiper-slide-thumb-active {\n\t\topacity: 1;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
