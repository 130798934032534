/**
 * 쇼핑내역 - 주문/배송 - 구매확정 후 알람 설정 완료 팝업
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import useModalStack from "src/hooks/useModalStack";
import useAuth from "src/hooks/useAuth";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myPage/order/confirmAlarm.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const ConfirmAlarmResult = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();
	const auth = useAuth();

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 취소 클릭 시
	 * @param {Event} e
	 */
	const handlerClickClose = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (props.handlerClickClose) await props.handlerClickClose();

		modalStack.removeModal(props.id);
	};

	/**
	 * 완료 클릭 시
	 * @param {Event} e
	 */
	const handlerClickComplete = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		if (props.handlerClickConfirm) await props.handlerClickConfirm();

		modalStack.removeModal(props.id);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="modalConfirmAlarmResultContainer">
			<div className="title">{textInfo.orderAlarm.result.title}</div>
			<div className="data">
				{textInfo.orderAlarm.result.desc1}
				{auth?.user?.nickname || textInfo.healthIssue.notLoginMember}
				{textInfo.orderAlarm.result.desc2}
			</div>
			<div className="buttonCover">
				<div className="button clickable close" onClick={(e) => handlerClickClose(e)}>
					{textInfo.orderAlarm.result.close}
				</div>
				<div className="button clickable confirm" onClick={(e) => handlerClickComplete(e)}>
					{textInfo.orderAlarm.result.confirm}
				</div>
			</div>
		</div>
	);
};

export default ConfirmAlarmResult;
