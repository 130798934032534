import { useNavigate } from "react-router-dom";
import { getTimeStamp } from "src/utils/date";
const textInfo = require("src/data/textInfo.json");
const pathList = require("src/path/pathJSON.json");

/**
 * 주문 정보 컨테이너
 */
const OrderInfoContainer = ({ order }) => {
	const navigate = useNavigate();

	/**
	 * 주문 상세보기 클릭 시
	 * @param {Event} e
	 * @param {Number} orderNo
	 */
	const handlerClickMoveToDetail = async (orderNo) => {
		if (orderNo) navigate(pathList.myPage.shoppingDetail + orderNo);
	};

	return (
		<div className="orderInfoContainer">
			<div className="orderInfoBox">
				<div className="orderInfo">
					<div className="orderDate">{getTimeStamp(order.registered, "YYYY-MM-DD")}</div>
					<div className="orderProductCount">
						<span>{order.orderItems.length}</span>
						{textInfo.common.quantityUnit}
					</div>
				</div>
				<div className="orderCode">
					{textInfo.order.orderNo} : <span>{order.orderCode}</span>
				</div>
			</div>
			<div className="moveToDetail" onClick={() => handlerClickMoveToDetail(order.orderNo)}>
				{textInfo.button.moveToDetail}
			</div>
		</div>
	);
};

export default OrderInfoContainer;
