/**
 * 기본 레이아웃
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { Outlet } from "react-router-dom";

import TopMain from "src/pages/layout/topMain";
import Footer from "./footer";
import FixedMenu from "./fixedMenu";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "./../../styles/common.scss";

const LayoutLinme = () => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="mainWrapper">
			<TopMain />
			<main>
				<Outlet />
			</main>
			<Footer />
			<FixedMenu />
		</div>
	);
};

export default LayoutLinme;
