import { useEffect, useState, useCallback } from "react";
import { search } from "src/api/product/search";
import useQueryParams from "src/hooks/useQueryParams";
import useModalStack from "src/hooks/useModalStack";
import ModalCommonAlert from "src/pages/common/modalAlert";
import { removeSpinner, setScrollTop, setSpinner } from "src/utils/linmeDisplay";
import { PAGINATION_DEFAULT_OFFSET, PRODUCT_LIST_ORDER } from "src/data/constEnum";
const htmlInfo = require("src/data/htmlInfo.json");

/**
 * useSearchResult 훅
 * - 상품 검색 결과, 총 페이지 수, 총 상품 수를 반환
 * - 쿼리 파라미터를 기반 검색
 *
 * @returns {Object} 검색된 상품 목록, 총 페이지 수, 총 상품 수를 포함한 객체
 *  - productList: 검색된 상품 목록 배열
 *  - totalPage: 총 페이지 수
 *  - totalCount: 총 상품 수
 */
const useSearchByKeyword = () => {
	const [productList, setProductList] = useState([]);
	const [totalPage, setTotalPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);

	const query = useQueryParams();
	const modalStack = useModalStack();
	const queryObj = query.queryStringToObject();
	// 쿼리 파라미터로부터 검색에 필요한 키워드, 페이지 번호, 정렬 기준을 추출
	const { keyword, page = 1, sort = PRODUCT_LIST_ORDER[0].value } = queryObj;
	const offset = PAGINATION_DEFAULT_OFFSET.PRODUCT_LIST;

	/**
	 * 상품 목록을 검색하는 함수
	 * - 쿼리 파라미터를 바탕으로 검색 요청.
	 */
	const fetchProductList = useCallback(async () => {
		setSpinner();
		const req = { keyword, page, offset, sort };

		try {
			// API 호출을 통해 상품 목록을 검색
			const result = await search(req);
			const { content, totalCount } = result.data;

			setProductList(content);
			setTotalPage(Math.ceil(totalCount / offset)); // 총 페이지 수 계산
			setTotalCount(totalCount);
			setScrollTop();
		} catch (error) {
			window.linmeLog.error(error);
			modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				html: htmlInfo.error.dataCall,
			});
		} finally {
			removeSpinner();
		}
	}, [keyword, page, sort, offset]);

	/**
	 * 의존성 배열에 포함된 값이 변경될 때마다 상품 목록을 다시 검색
	 * - 검색어, 페이지 번호, 정렬 기준이 변경될 때마다 호출.
	 */
	useEffect(() => {
		fetchProductList();
	}, [fetchProductList]);

	// 검색된 상품 목록, 총 페이지 수, 총 상품 수를 반환
	return { productList, totalPage, totalCount };
};

export default useSearchByKeyword;
