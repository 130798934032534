/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";
import styled from "styled-components";

import { COLOR } from "src/data/constEnum";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
// const textInfo = require( "src/data/textInfo.json" );

const ToggleContainer = styled.div`
	&.toggleContainer {
		position: relative;
		display: block;
		width: ${(props) =>
			props.$styleObj && props.$styleObj.width ? props.$styleObj.width : "106px"};
		height: ${(props) =>
			props.$styleObj && props.$styleObj.height ? props.$styleObj.height : "31px"};
		top: 0;
		left: 0;
		cursor: pointer;
	}
`;
const ToggleCover = styled.div`
	&.toggleCover {
		position: relative;
		width: 100%;
		height: 100%;
		border-radius: 15.5px;
		box-shadow: ${(props) =>
			props.$styleObj && props.$styleObj.boxShadow
				? props.$styleObj.boxShadow
				: "0 1px 3px 0 rgba(0, 0, 0, 0.16), inset 0 0 3px 0 rgba(0, 0, 0, 0.16)"};
		border: ${(props) =>
			props.$styleObj && props.$styleObj.border
				? props.$styleObj.border
				: "1px solid " + COLOR.WHITE};
		background-color: ${(props) =>
			props.$styleObj && props.$styleObj.backgroundColor
				? props.$styleObj.backgroundColor
				: "unset"};
		font-size: ${(props) =>
			props.$styleObj && props.$styleObj.fontSize ? props.$styleObj.fontSize : "15px"};
		font-weight: normal;
		line-height: normal;
		letter-spacing: -0.75px;
		color: ${COLOR.WHITE};

		&.toggleChecked {
			background-color: ${(props) =>
				props.$styleObj && props.$styleObj.checkedBackgroundColor
					? props.$styleObj.checkedBackgroundColor
					: "rgba( 255, 255, 255, 0.85 )"};
			border: ${(props) =>
				props.$styleObj && props.$styleObj.checkedBorder
					? props.$styleObj.checkedBorder
					: "1px solid " + COLOR.WHITE};
		}
	}
`;
const ToggleCircle = styled.div`
	&.toggleCircle {
		position: absolute;
		top: ${(props) => (props.$styleObj && props.$styleObj.top ? props.$styleObj.top : "4px")};
		left: ${(props) => (props.$styleObj && props.$styleObj.left ? props.$styleObj.left : "6px")};
		right: unset;
		width: ${(props) =>
			props.$styleObj && props.$styleObj.width ? props.$styleObj.width : "25px"};
		height: ${(props) =>
			props.$styleObj && props.$styleObj.height ? props.$styleObj.height : "25px"};
		border-radius: 50%;
		background-color: ${(props) =>
			props.$styleObj && props.$styleObj.backgroundColor
				? props.$styleObj.backgroundColor
				: COLOR.LINME};
		transition: 0.8s;

		&.toggleChecked {
			left: unset;
			right: ${(props) =>
				props.$styleObj && props.$styleObj.checkedRight ? props.$styleObj.checkedRight : "3px"};
			background-color: ${(props) =>
				props.$styleObj && props.$styleObj.checkedBackgroundColor
					? props.$styleObj.checkedBackgroundColor
					: COLOR.LINME};
		}
	}
`;
const ToggleLabel = styled.div`
	&.toggleLabel {
		position: absolute;
		width: fit-content;
		top: 8px;
		left: 38px;
		font-size: 15px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.75px;
		text-align: center;
		color: ${(props) =>
			props.$styleObj && props.$styleObj.color ? props.$styleObj.color : COLOR.WHITE};

		&.toggleChecked {
			left: 16px;
			color: ${(props) =>
				props.$styleObj && props.$styleObj.checkedColor
					? props.$styleObj.checkedColor
					: COLOR.LINME};
		}
	}
`;

const ToggleContainerWithoutCircle = styled.div`
	&.toggleContainer {
		position: relative;
		display: block;
		width: ${(props) =>
			props.$styleObj && props.$styleObj.width ? props.$styleObj.width : "138px"};
		height: ${(props) =>
			props.$styleObj && props.$styleObj.height ? props.$styleObj.height : "25px"};
		top: 0;
		left: 0;
		border-radius: 12px;
		background-color: ${COLOR.GRAY06};
		cursor: pointer;
	}
`;
const ToggleCircleWithoutCircle = styled.div`
	&.toggleCircle {
		position: absolute;
		top: 0;
		left: 0;
		right: unset;
		width: 50%;
		height: 100%;
		border-radius: 12px;
		background-color: ${COLOR.LINME};
		transition: 0.8s;

		&.toggleChecked {
			left: unset;
			right: 0;
			background-color: ${COLOR.LINME};
		}
	}
`;
const ToggleLabelWithoutCircle1 = styled.div`
	&.toggleLabel1 {
		position: absolute;
		width: 50%;
		height: fit-content;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.26px;
		text-align: center;
		color: ${COLOR.GRAY02};

		&.toggleChecked {
			left: unset;
			right: 0;
			color: ${COLOR.GRAY02};
		}
	}
`;
const ToggleLabelWithoutCircle2 = styled.div`
	&.toggleLabel2 {
		position: absolute;
		width: 50%;
		height: fit-content;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.26px;
		text-align: center;
		color: ${COLOR.WHITE};

		&.toggleChecked {
			left: unset;
			right: 0;
			color: ${COLOR.WHITE};
		}
	}
`;

const Toggle = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const [isOn, setIsOn] = useState(false);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 토글이 켜진 상태(isOn)를 boolean 타입으로 변경
	 */
	const handlerClickToggle = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		// setIsOn(!isOn);
		if (props.afterToggle) props.afterToggle(isOn, props);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		setIsOn(props.isOn);
	}, [props.isOn]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<>
			{props.isWithoutCircle ? (
				<ToggleContainerWithoutCircle
					className="toggleContainer"
					onClick={(e) => {
						handlerClickToggle(e);
					}}
					// $styleObj={( props.styles && props.styles.container ) ? props.styles.container : {} }
				>
					<ToggleCircleWithoutCircle
						className={`toggleCircle ${isOn ? "toggleChecked" : ""}`}
						// $styleObj={( props.styles && props.styles.circle ) ? props.styles.circle : {} }
					/>
					<ToggleLabelWithoutCircle1
						className={`toggleLabel1 ${!isOn ? "toggleChecked" : ""}`}
						// $styleObj={( props.styles && props.styles.label ) ? props.styles.label : {} }
					>
						{!isOn ? props.str1 : props.str2}
					</ToggleLabelWithoutCircle1>
					<ToggleLabelWithoutCircle2
						className={`toggleLabel2 ${isOn ? "toggleChecked" : ""}`}
						// $styleObj={( props.styles && props.styles.label ) ? props.styles.label : {} }
					>
						{isOn ? props.str1 : props.str2}
					</ToggleLabelWithoutCircle2>
				</ToggleContainerWithoutCircle>
			) : (
				<ToggleContainer
					className="toggleContainer"
					onClick={(e) => {
						handlerClickToggle(e);
					}}
					$styleObj={props.styles && props.styles.container ? props.styles.container : {}}
				>
					<ToggleCover
						className={`toggleCover ${isOn ? "toggleChecked" : ""}`}
						$styleObj={props.styles && props.styles.container ? props.styles.container : {}}
					/>
					<ToggleCircle
						className={`toggleCircle ${isOn ? "toggleChecked" : ""}`}
						$styleObj={props.styles && props.styles.circle ? props.styles.circle : {}}
					/>
					<ToggleLabel
						className={`toggleLabel ${isOn ? "toggleChecked" : ""}`}
						$styleObj={props.styles && props.styles.label ? props.styles.label : {}}
					>
						{isOn ? props.str1 : props.str2}
					</ToggleLabel>
				</ToggleContainer>
			)}
		</>
	);
};

export default Toggle;
