/**
 * 클레임 관련 함수
 */

const textInfo = require("src/data/textInfo.json");

/**
 * 클레임 사유 목록을 셀렉트박스에서 사용할 object로 가공하여 반환
 * @param {String} type
 * @param {Array} d
 * @returns {Array}
 */
export const makeClaimReasonSelectOptions = (type, d) => {
	let r = [];
	let i = 0;
	const iLen = d.length;
	let io; //d[i];
	for (; i < iLen; ++i) {
		io = d[i];
		r.push({ label: io.reason, value: io.reasonNo });
	}
	return r;
};

/**
 * 택배사 목록을 셀렉트박스에서 사용할 object로 가공하여 반환
 * @param {Array} d
 * @returns {Array}
 */
export const makeDeliveryCompanySelectOptions = (d) => {
	let r = [];
	let i = 0;
	const iLen = d.length;
	let io; //d[i];
	for (; i < iLen; ++i) {
		io = d[i];
		r.push({ label: io.name, value: io.companyNo + "" });
	}
	return r;
};
