/**
 * 마이페이지 - 1:1 문의 내역
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useContext, useEffect, useState } from "react";

import useModalStack from "src/hooks/useModalStack";
import {
	API_RESULT_STATUS,
	COLOR,
	INQUIRY_TYPE_LIST,
	PAGINATION_DEFAULT_OFFSET,
} from "src/data/constEnum";
import { getLastPageNumber } from "src/utils/number";
import { getInquiryList } from "src/api/product/inquiry";

import Pagination from "src/pages/component/pagination";
import InquiryCard from "src/pages/component/inquiry/inquiryCard";
import InquiryWrite from "src/pages/component/inquiry/inquiryWrite";
import CustomSelectBox from "src/pages/component/common/customSelectBox";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myPage/inquiry.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const MyPageInquiry = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();
	const [inquiryType, setInquireType] = useState(INQUIRY_TYPE_LIST[0]);
	const [list, setList] = useState(null);
	const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);
	const [paginationOffset, setPaginationOffset] = useState(PAGINATION_DEFAULT_OFFSET.DEFAULT);
	const [paginationTotalCount, setPaginationTotalCount] = useState(0);
	const [paginationLastPage, setPaginationLastPage] = useState(0);
	const [paginationIsLastPage, setPaginationIsLastPage] = useState(false);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 문의하기 클릭 시
	 * @param {Event} e
	 */
	const handlerClickInquiryWrite = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		modalStack.addModal({
			id: "modalInquiryWrite",
			type: "confirm",
			component: <InquiryWrite />,
			inquiryType: null,
			redraw: redraw,
		});
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 다시 그리기
	 * @param {Number} pageNo
	 */
	const redraw = async (pageNo) => {
		if (pageNo) await requestInquiryList(pageNo);
		else await requestInquiryList(paginationCurrentPage);
	};

	/**
	 * 문의 목록 조회
	 * @param {Number} pageNo
	 * @param {String} listType
	 */
	const requestInquiryList = async (pageNo, listType) => {
		try {
			let d = {
				offset: paginationOffset,
				page: pageNo,
				type: listType ? listType : inquiryType.value,
			};
			if (d.type === "ALL") delete d.type;

			const result = await getInquiryList(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (result.data.content) {
					setList(result.data.content);

					setPaginationCurrentPage(result.data.page);
					setPaginationTotalCount(result.data.totalCount);
					setPaginationIsLastPage(result.data.isLastPage);
					setPaginationLastPage(getLastPageNumber(result.data.totalCount, paginationOffset));
				}
				return result.data;
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (err) {
			window.linmeLog.error(err);
			return false;
		}
	};

	/**
	 * 문의 타입 선택 후 적용
	 * @param {Object} d
	 */
	const setSelectedInquiryType = async (o) => {
		await setInquireType(o);
		await requestInquiryList(1, o.value);
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		const getData = async () => {
			await requestInquiryList(1);
		};
		getData();
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="inquiryContainer">
			<div className="inquiryHeader">
				<div className="title">{textInfo.myPage.myInquiry.title}</div>
				<div className="buttonCover">
					<CustomSelectBox
						options={INQUIRY_TYPE_LIST}
						value={inquiryType?.value}
						onChange={(item) => setSelectedInquiryType(item)}
					/>
					<div
						className="button clickable inquiryWrite"
						onClick={(e) => handlerClickInquiryWrite(e)}
					>
						{textInfo.button.inquiry}
					</div>
				</div>
			</div>
			<div className="inquiryCover">
				<ul className="inquiryList">
					<li className="inquiryItem title">
						<div className="inquiryQ">
							<div className="inquiryContent">{textInfo.myPage.myInquiry.inquiryTitle}</div>
							<div className="inquiryWriteDate">{textInfo.myPage.myInquiry.inquiryWriteDate}</div>
							<div className="inquiryStatus">{textInfo.myPage.myInquiry.inquiryStatus}</div>
						</div>
					</li>
					{list && list.length ? (
						list.map((item, index) => (
							<InquiryCard {...{ data: item }} from={"myInquiry"} redraw={redraw} key={index} />
						))
					) : (
						<li className="noData" />
					)}
				</ul>
				{list && list.length ? (
					<Pagination
						{...{
							currentPage: paginationCurrentPage,
							offset: paginationOffset,
							totalCount: paginationTotalCount,
							lastPage: paginationLastPage,
							isLastPage: paginationIsLastPage,
							redraw: redraw,
						}}
					/>
				) : (
					<></>
				)}
			</div>
		</div>
	);
};

export default MyPageInquiry;
