import { memo, useRef } from "react";
import useBannerList from "src/hooks/main/useBannerList";
import BannerSlideItem from "src/pages/component/carousel/bannerSlideItem";
import { clsx } from "clsx";
import { BANNER_TYPE } from "src/data";
import styles from "src/styles/main/subBanner.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

/**
 * 메인페이지 띠 배너
 */
const SubBanner = () => {
	const { data: bannerList, isLoading, isError } = useBannerList(BANNER_TYPE.HOME_SUB);
	const swiperRef = useRef(null);

	if (isLoading || isError) return;

	return (
		<div className={clsx([styles.container])}>
			<div className={styles.innerContainer}>
				<Swiper
					ref={swiperRef}
					modules={[Autoplay]}
					loop={bannerList?.length > 1}
					speed={600}
					autoplay={{
						delay: 2500,
						disableOnInteraction: false,
						pauseOnMouseEnter: true,
						stopOnLastSlide: false,
						waitForTransition: true,
					}}
				>
					{bannerList.map((banner) => (
						<SwiperSlide key={banner?.itemNo}>
							<BannerSlideItem banner={banner} className={styles.bannerImage} />
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</div>
	);
};

export default memo(SubBanner);
