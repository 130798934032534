import { useState, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { getMainMonth } from "src/api/myLinme/main";
import moment from "moment";
import { queryKeys } from "src/data";

const useAlarmCalenderMonth = () => {
	const [currentMonth, setCurrentMonth] = useState(new Date());
	const formattedMonth = useMemo(() => moment(currentMonth).format("YYYY-MM-01"), [currentMonth]);

	const { data } = useQuery({
		queryKey: [queryKeys.ALARM_MONTH, formattedMonth],
		queryFn: () => getMainMonth({ nowMonth: formattedMonth }),
		select: (data) => data?.data,
		staleTime: 1000 * 60 * 5, // 5분
		cacheTime: 1000 * 60 * 30, // 30분
	});

	const handleMonth = (date) => {
		setCurrentMonth(date);
	};

	return { data, currentMonth, handleMonth };
};

export default useAlarmCalenderMonth;
