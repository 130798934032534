/**
 * 쇼핑내역 - 주문/배송 - 배송조회
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";
import moment from "moment";

import useModalStack from "src/hooks/useModalStack";
import { getDeliveryDetail } from "src/api/myShopping/order";
import { API_RESULT_STATUS } from "src/data/constEnum";
import { setInnerSpinner, removeInnerSpinner } from "src/utils/linmeDisplay";

import ModalCommonAlert from "src/pages/common/modalAlert";
import Image from "src/pages/component/common/image";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myPage/order/deliveryDetail.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const DeliveryDetail = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();
	const [item, setItem] = useState(null);
	const [data, setData] = useState(null);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 배송 조회 요청
	 * @param {Number} itemNo
	 * @returns
	 */
	const requestGetDeliveryDetail = async (itemNo) => {
		try {
			const result = await getDeliveryDetail(itemNo);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				await setData(result.data);
				return result.data;
			} else {
				await window.linmeLog.log(result);
				return false;
			}
		} catch (err) {
			return false;
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		const getData = async () => {
			await setInnerSpinner();
			if (props.item && props.item.itemNo) {
				const itemNo = props.item.itemNo;
				await setItem(props.item);
				const result = await requestGetDeliveryDetail(itemNo);
				if (!result) {
					await modalStack.addModal({
						id: "modalCommonAlert",
						type: "alert",
						component: <ModalCommonAlert />,
						text: textInfo.alert.errorDeliveryTrace,
					});
					// await modalStack.removeModal( props.id );
				}
			}
			await removeInnerSpinner();
		};
		getData();
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="deliveryDetailContainer">
			<div className="title">{textInfo.order.deliveryTrace.title}</div>
			<div className="productInfo">
				<div className="productImageCover">
					<Image
						className="productImage"
						src={props.item.product.thumbnail.webPath}
						alt={props.item.product.name}
					/>
				</div>
				<div className="productName">{props.item.product.name}</div>
				<div className="productOrderDate">{"2023.11.21 구매"}</div>
			</div>
			<ul className="deliveryInfoList">
				<li className="deliveryInfoItem">
					<div className="label">{textInfo.order.deliveryTrace.invoiceCode}</div>
					<div className="data">{data && data.baseInfo ? data.baseInfo.invoiceCode : "-"}</div>
				</li>
				<li className="deliveryInfoItem">
					<div className="label">{textInfo.order.deliveryTrace.companyName}</div>
					<div className="data">{data && data.baseInfo ? data.baseInfo.companyName : "-"}</div>
				</li>
			</ul>
			<ul className="partnerInfoList">
				<li className="partnerInfoItem">
					<div className="label">{textInfo.order.deliveryTrace.partnerName}</div>
					<div className="data">{data && data.partner ? data.partner.name : "-"}</div>
				</li>
			</ul>
			<ul className="deliveryTraceList">
				{data && data.history && data.history.length ? (
					data.history.map((delivery, index) => (
						<li className="deliveryTraceItem" key={index}>
							<div className="time">
								<span>{moment(delivery.time).format("MM:DD")}</span>
								<span>{moment(delivery.time).format("HH:mm")}</span>
							</div>
							<div className="data">
								<span className="location">{delivery.where}</span>
								<span>{delivery.content}</span>
								<span>{delivery.managerName}</span>
							</div>
						</li>
					))
				) : (
					<li className="noData">
						<span>{textInfo.order.deliveryTrace.noData}</span>
					</li>
				)}
			</ul>
		</div>
	);
};

export default DeliveryDetail;
