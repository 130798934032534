/**
 * 마이페이지 - 내 정보 관리 - 알림 설정
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";

import useModalStack from "src/hooks/useModalStack";
import { API_RESULT_STATUS, ALARM_SETTING_SHOW_FOR_WEB, COLOR } from "src/data/constEnum";
import {
	getMemberNotificationSetting,
	setMemberNotificationSetting,
} from "src/api/member/notification";
import { copyStructure } from "src/utils/obj";
import {
	removeInnerSpinner,
	setFooterFixed,
	setFooterRelative,
	setInnerSpinner,
} from "src/utils/linmeDisplay";

import Toggle from "src/pages/component/toggle";
import ModalCommonAlert from "src/pages/common/modalAlert";
import MoveToBack from "src/pages/component/moveToBack";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/myPage/myInfo/myInfoNotification.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const MyPageMyInfoNotification = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const modalStack = useModalStack();
	const [alarmInfo, setAlarmInfo] = useState({});
	const [list, setList] = useState([]);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 토글 시
	 * @param {Boolean} isOn
	 * @param {Object} item
	 */
	const afterToggle = async (isOn, item) => {
		const d = await copyStructure(alarmInfo);
		d[item.type] = !isOn;

		await setAlarmInfo(d);
		await requestSetMemberNotificationSetting(d);
	};

	/**
	 * 알림 설정 조회
	 * @returns
	 */
	const requestGetMemberNotificationSetting = async () => {
		try {
			const d = {
				offset: 20,
				page: 1,
			};
			const result = await getMemberNotificationSetting(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (result.data) {
					let a = [];
					let o;
					for (let k in result.data) {
						if (ALARM_SETTING_SHOW_FOR_WEB[k]) {
							o = {};
							o.label = textInfo.myPage.myInfoNotification.data[k];
							o.type = k;
							o.value = result.data[k];
							o.description = textInfo.myPage.myInfoNotification.data[k + "Description"];
							a.push(o);
						}
					}
					setList(a);
					setAlarmInfo(result.data);
				}
				return result.data;
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	/**
	 * 알림 설정 저장
	 * @returns
	 */
	const requestSetMemberNotificationSetting = async (d) => {
		try {
			setInnerSpinner();
			const result = await setMemberNotificationSetting(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (result.data) {
					let a = [];
					let o;
					for (let k in result.data) {
						if (ALARM_SETTING_SHOW_FOR_WEB[k]) {
							o = {};
							o.label = textInfo.myPage.myInfoNotification.data[k];
							o.type = k;
							o.value = result.data[k];
							o.description = textInfo.myPage.myInfoNotification.data[k + "Description"];
							a.push(o);
						}
					}
					setList(a);
					setAlarmInfo(result.data);
				}
				await modalStack.addModal({
					id: "modalCommonAlert",
					type: "alert",
					component: <ModalCommonAlert />,
					text: textInfo.alert.changedMemberAlarmSetting,
				});
				return result.data;
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		} finally {
			removeInnerSpinner();
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		const getData = async () => {
			// await setFooterFixed();//푸터 위치 관련;
			await requestGetMemberNotificationSetting(); //알림 설정 조회;
		};
		getData();

		return () => {
			//푸터 위치 관련 해제;
			// setFooterRelative();
		};
	}, []);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="myInfoNotificationContainer">
			<div className="titleWrapper">
				<MoveToBack />
				<div className="title">{textInfo.myPage.myInfoNotification.title}</div>
			</div>
			<div className="myInfoNotificationCover">
				<ul className="notificationList">
					{list && list.length ? (
						list.map((item, index) => (
							<li
								className={"notificationItem " + (item.description ? "" : "noDescription")}
								key={index}
							>
								<div className="notificationName">{item.label}</div>
								<div className="notificationToggleCover">
									<Toggle
										str1=""
										str2=""
										styles={{
											container: {
												width: "45px",
												height: "22px",
												boxShadow: "unset",
												backgroundColor: COLOR.GRAY02,
												checkedBackgroundColor: COLOR.LINME,
											},
											circle: {
												width: "15px",
												height: "15px",
												top: "4px",
												left: "5px",
												backgroundColor: COLOR.WHITE,
												checkedRight: "3px",
												checkedBackgroundColor: COLOR.WHITE,
											},
											label: null,
										}}
										type={item.type}
										afterToggle={afterToggle}
										isOn={item.value}
									/>
								</div>
								<div className="notificationDescription">{item.description}</div>
							</li>
						))
					) : (
						<li className="noData" />
					)}
				</ul>
			</div>
		</div>
	);
};

export default MyPageMyInfoNotification;
