import { useRef, useEffect, memo } from "react";
import { SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

import usePageVisibility from "src/hooks/usePageVisibility";
import useInView from "src/hooks/useInView";
import useBannerAction from "src/hooks/banner/useBannerAction";
import BannerSlideItem from "src/pages/component/carousel/bannerSlideItem";
import Swiper from "src/pages/component/swiper";

// Swiper styles
import "src/styles/customSlick.scss";

const BannerSlider = ({ items: bannerList }) => {
	const swiperRef = useRef(null);
	const isPageVisible = usePageVisibility();
	const [ref, isIntersecting] = useInView({
		threshold: 0.6,
	});
	const { onViewBanner, onClickBanner } = useBannerAction(bannerList);

	const handlePrev = () => swiperRef.current?.swiper.slidePrev();
	const handleNext = () => swiperRef.current?.swiper.slideNext();

	useEffect(() => {
		const swiperInstance = swiperRef.current?.swiper;
		if (!swiperInstance) return;

		// 화면 밖으로 이동 시 autoplay 정지
		const shouldAutoplay = isIntersecting && isPageVisible;

		if (shouldAutoplay) {
			return swiperInstance.autoplay.start();
		} else {
			return swiperInstance.autoplay.pause();
		}
	}, [isPageVisible, isIntersecting]);

	if (!bannerList || bannerList.length === 0) return;

	return (
		<div className="container bannerSlider" ref={ref}>
			<div className="slickCustomArrow">
				<div className="prev" onClick={handlePrev}></div>
				<div className="next" onClick={handleNext}></div>
			</div>
			<Swiper
				ref={swiperRef}
				modules={[Autoplay]}
				loop
				speed={600}
				autoplay={{
					delay: 2500,
					disableOnInteraction: false,
					pauseOnMouseEnter: true,
					stopOnLastSlide: false,
					waitForTransition: true,
				}}
				onSlideChangeTransitionEnd={(swiper) => onViewBanner(swiper.realIndex)}
				className="swiperContainer"
			>
				{bannerList.map((banner) => (
					<SwiperSlide key={banner?.itemNo}>
						<BannerSlideItem
							banner={banner}
							// 랜딩 페이지로 이동할때만 호출
							onClick={() => banner?.landing.value && onClickBanner(banner?.itemNo)}
						/>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

export default memo(BannerSlider);
