/**
 * New 상품 카드
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { Link } from "react-router-dom";

import { PRODUCT_STATUS } from "src/data/constEnum";

import Image from "src/pages/component/common/image";
import { getProductDetailType } from "src/utils/linmeSearch";
import styles from "src/styles/product/productItem.module.scss";
import clsx from "clsx";

const pathList = require("src/path/pathJSON.json");
const textInfo = require("src/data/textInfo.json");

const ProductItem = (props) => {
	const { product } = props;
	// 배송비 정보 표시
	const getDeliveryPrice = (deliveryPrice) => {
		if (deliveryPrice && deliveryPrice > 0) {
			return `${
				textInfo.payment.checkOrderProduct.deliveryPrice
			} ${deliveryPrice?.toLocaleString()}${textInfo.common.currencyUnit}`;
		}
		return `${textInfo.payment.deliveryInfo.free}`;
	};

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div
			className={clsx([
				styles.productCard,
				product?.status === PRODUCT_STATUS.DEAD && styles.saleEnd,
				product?.status === PRODUCT_STATUS.SOLDOUT && styles.soldout,
			])}
		>
			<Link
				className={styles.productCardContainer}
				to={
					pathList.product.detail + `${getProductDetailType(product?.type)}/` + product?.productNo
				}
			>
				{/* 상단 */}
				<div className={styles.productCardImageContainer}>
					{/* 상품 이미지 */}
					{product?.thumbnail && (
						<div className={styles.productImageCover}>
							<Image
								className={styles.productImage}
								src={product?.thumbnail.webPath}
								alt={product?.name}
							/>
						</div>
					)}
				</div>
				{/* 하단 */}
				<div className={styles.productCardItem}>
					{/* 상품명 */}
					<div className={styles.productName}>{product?.name}</div>
					<div className={styles.productPriceContainer}>
						{product?.salePercent > 0 && (
							<div className={styles.productPriceInfo}>
								{/* 할인율 */}
								<div className={styles.discountRate}>
									{product?.salePercent?.toLocaleString()}
									<span className={styles.percentUnit}>{textInfo.common.percentUnit}</span>
								</div>
								{/* 할인 전 금액 */}
								<div className={styles.price}>
									{product?.price?.toLocaleString()}
									{textInfo.common.currencyUnit}
								</div>
							</div>
						)}
						{/* 할인 금액 */}
						<div className={styles.salePrice}>
							{product?.salePrice?.toLocaleString()}
							{textInfo.common.currencyUnit}
						</div>
						{/* 배송비 */}
						<div className={styles.deliveryPrice}>{getDeliveryPrice(product?.deliveryPrice)}</div>
					</div>
				</div>
			</Link>
		</div>
	);
};

export default ProductItem;
