import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/**
 * 안심번호 사용 가능 여부 조회
 * @returns
 */
export const checkAvailableVirtualNumber = async () => {
	const url = `${API_PATHS.ORDER_CHECK_VIRTUAL_NUMBER}`;
	try {
		const result = await api.get(url);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 신규 주문 생성 ( 상품 상세에서 바로구매 - 단건 )
 * @param {Object} d
 * <code>
 * {
 * 		type: "PRODUCT"//CART: 장바구니, PRODUCT: 상품 상세 - 바로구매
 * 		, optionList: [
 * 			{
 * 				optionCategoryNo: 0
 * 				, optionNo: 0
 * 				, quantity: 0
 * 			}
 * 			, {
 * 				optionCategoryNo: 0
 * 				, optionNo: 0
 * 				, quantity: 0
 * 			}
 * 			, ...
 * 		]
 * 		, productNo: 0
 * }
 * </code>
 * @returns
 */
export const createOrderProduct = async (d) => {
	const url = `${API_PATHS.ORDER}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 신규 주문 생성 ( 장바구니에서 선택 주문 )
 * @param {Object} d
 * <code>
 * {
 * 		type: "CART"//CART: 장바구니, PRODUCT: 상품 상세 - 바로구매
 * 		, cartNoList: [ 0, 0, ... ]
 * }
 * </code>
 */
export const createOrderProductFromCart = async (d) => {
	const url = `${API_PATHS.ORDER}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 배송지/쿠폰사용/리워드 사용 등 주문 정보 변경
 * @param {Number} orderNo
 * @param {Object} d
 * <code>
 * {
 * 		memberAddressNo: addressNo
 * 		, memberCouponNo: -1
 * 		, usedReward: 0
 * }
 * </code>
 * @returns
 */
export const changeOrder = async (orderNo, d) => {
	const url = `${API_PATHS.ORDER_MODIFY}${orderNo}`;
	try {
		// const result = await api.post( url, d );
		const result = await api.post(url.replace(":orderNo", orderNo), d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 주문번호로 해당 주문에 사용 가능한 쿠폰 목록 조회
 * @param {Number} orderNo
 * @param {Object} d
 * <code>
 * {
 * 		offset: 0
 * 		, page: 0
 * }
 * </code>
 * @returns
 */
export const getCouponListForOrder = async (orderNo, d) => {
	const url = `${API_PATHS.ORDER_COUPON_LIST}${orderNo}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
