/**
 * 리워드 - 사용내역
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import useAuth from "src/hooks/useAuth";
import { getRewardHistory } from "src/api/member/reward";
import { API_RESULT_STATUS, PAGINATION_DEFAULT_OFFSET } from "src/data/constEnum";
import Pagination from "src/pages/component/pagination";
import { getTimeStamp } from "src/utils/date";
import { getLastPageNumber } from "src/utils/number";
import {
	setSpinner,
	removeSpinner,
	setInnerSpinner,
	removeInnerSpinner,
	setScrollTop,
} from "src/utils/linmeDisplay";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/reward/history.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */

const pathList = require("src/path/pathJSON.json");

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const RewardHistory = () => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const auth = useAuth();

	const [historyData, setHistoryData] = useState([]);
	const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);
	const [paginationOffset, setPaginationOffset] = useState(
		PAGINATION_DEFAULT_OFFSET.REWARD_HISTORY
	);
	const [paginationTotalCount, setPaginationTotalCount] = useState(0);
	const [paginationLastPage, setPaginationLastPage] = useState(0);
	const [paginationIsLastPage, setPaginationIsLastPage] = useState(false);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 다시 그리기
	 * @param {Number} pageNo
	 */
	const redraw = async (pageNo) => {
		await setInnerSpinner();
		await requestRewardHistory(pageNo);
		await setScrollTop();
		await removeInnerSpinner();
	};

	/**
	 * 리워드 사용내역 조회
	 * @param {Number} pageNo
	 */
	const requestRewardHistory = async (pageNo) => {
		try {
			let d = {
				offset: paginationOffset,
				page: pageNo,
			};
			const result = await getRewardHistory(d);
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				if (result?.data?.content) {
					setHistoryData(result.data.content);

					setPaginationCurrentPage(result.data.page);
					setPaginationTotalCount(result.data.totalCount);
					setPaginationIsLastPage(result.data.isLastPage);
					setPaginationLastPage(getLastPageNumber(result.data.totalCount, paginationOffset));
				}
			} else {
				window.linmeLog.log(result);
			}
		} catch (error) {
			window.linmeLog.log(error);
		}
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		if (!auth.isLogin) return;

		const getData = async () => {
			await setSpinner();
			await requestRewardHistory(1);
			await removeSpinner();
		};
		getData();
	}, [auth.isLogin]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<>
			<div className="rewardHistoryTitle">{textInfo.reward.history.title}</div>
			<ul className="rewardHistoryList">
				{historyData && historyData.length ? (
					<li className="rewardHistoryItem label">
						{textInfo.reward.history.subTitle}
						{/*
						<div className="date">일자</div>
						<div className="rewardReason">사유</div>
						<div className="rewardPoint">사용</div>
						*/}
					</li>
				) : (
					<></>
				)}
				{historyData && historyData.length ? (
					historyData.map((item, index) => (
						<li className="rewardHistoryItem" key={index}>
							<div className="date">{getTimeStamp(item.registered, "YYYY.MM.DD")}</div>
							<div className="rewardReason">{item.message}</div>
							<div className={"rewardPoint " + (item.amount > 0 ? "plus" : "minus")}>
								<span>{item.amount ? item.amount.toLocaleString() : "-"}</span>R
							</div>
						</li>
					))
				) : (
					<li className="rewardHistoryItem noData" />
				)}
				{historyData && historyData.length ? (
					<Pagination
						{...{
							currentPage: paginationCurrentPage,
							offset: paginationOffset,
							totalCount: paginationTotalCount,
							lastPage: paginationLastPage,
							isLastPage: paginationIsLastPage,
							redraw: redraw,
						}}
					/>
				) : (
					<></>
				)}
			</ul>
		</>
	);
};

export default RewardHistory;
