/**
 * 레이아웃 리워드
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { Outlet, useLocation } from "react-router-dom";

import RewardCard from "src/pages/component/reward/rewardCard";

import TopMain from "src/pages/layout/topMain";
import Footer from "src/pages/layout/footer";
import FixedMenu from "src/pages/layout/fixedMenu";
import MoveToBack from "src/pages/component/moveToBack";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/common.scss";
import "src/styles/reward/layoutReward.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const LayoutReward = () => {
	const { pathname } = useLocation();

	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="mainWrapper">
			<TopMain />
			<main>
				<div
					className={
						"container underTopMenu rewardContainer " +
						(pathname === pathList.reward.history ? "none" : "")
					}
				>
					<div className="contents">
						<div className="titleWrapper">
							<MoveToBack />
							<div className="title">{textInfo.reward.title}</div>
						</div>
						<div className="rewardContents">
							<RewardCard />
							<div className="rewardInfo">
								<Outlet />
							</div>
						</div>
					</div>
				</div>
			</main>
			<Footer />
			{/* <Top /> */}
			<FixedMenu />
		</div>
	);
};

export default LayoutReward;
