/**
 * 리뷰 목록
 */
import useProductReview from "src/hooks/product/useProductReview";
import Pagination from "src/pages/component/pagination";
import ReviewCard from "src/pages/component/review/reviewCard";

const ReviewList = (props) => {
	const { productNo } = props;
	// 상품 번호 기반 리뷰 목록 조회
	const { data, handlePageNo } = useProductReview({ productNo, pageNo: 1 });
	const reviewList = data?.content;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<>
			<ul className="reviewList">
				{reviewList && reviewList.length ? (
					reviewList.map((item, index) => (
						<li className="reviewItem" key={index}>
							<ReviewCard {...props} reviewItem={item} />
						</li>
					))
				) : (
					<li className="noData" />
				)}
			</ul>
			{reviewList && reviewList.length !== 0 && (
				<Pagination
					currentPage={data?.page}
					offset={data?.offset}
					totalCount={data?.totalCount}
					isLastPage={data?.isLastPage}
					redraw={handlePageNo}
				/>
			)}
		</>
	);
};

export default ReviewList;
