/**
 * 주문 관련 함수
 */

import { ORDER_STATUS, PRODUCT_OPTIONS_TYPE } from "src/data/constEnum";

// const textInfo = require( "src/data/textInfo.json" );

/**
 * 주문 상태에 따른 버튼 조절
 * 선택옵션
 * 	- 교환 가능
 * 	- 구매확정 불가
 * 	- 리뷰 작성 불가
 * 	- "구매확정" 상태가 아닌 필수옵션이 1개라도 존재해야만 환불요청 가능
 * @param {Object} item
 * @param {Object} order
 * @returns {Array}
 */
export const makeButtonByOrderStatus = (item, order) => {
	let result = [];
	switch (item.status) {
		case ORDER_STATUS.INIT.value: //주문 초기화 (주문내역 비노출);
		case ORDER_STATUS.PAY_FAIL.value: //결제실패 (주문내역 비노출);
			break;
		case ORDER_STATUS.PAY_WAIT.value: //결제대기;
			break;
		case ORDER_STATUS.PAY_FIN.value: //결제완료;
		case ORDER_STATUS.DLVY_READY.value: //배송준비중;
		case ORDER_STATUS.PRD_READY.value: //상품준비중;
			result.push("claimCancel"); //취소하기;
			break;
		case ORDER_STATUS.DLVY_IN.value: //배송중;
		case ORDER_STATUS.DLVY_FIN.value: //배송완료;
			if (item.product.option.type === PRODUCT_OPTIONS_TYPE.REQUIRED) {
				result.push("orderConfirm"); //구매확정;
			}
			result.push("deliveryTrace"); //배송조회;
			result.push("claimChange"); //교환요청;
			if (item.product.option.type === PRODUCT_OPTIONS_TYPE.ADDITIONAL) {
				if (
					order.orderItems.filter(
						(p) =>
							p.product.option.type === PRODUCT_OPTIONS_TYPE.REQUIRED &&
							p.status !== ORDER_STATUS.CONFIRM
					).length > 1
				) {
					result.push("claimRefund"); //환불요청;
				}
			} else {
				result.push("claimRefund"); //환불요청;
			}
			break;
		case ORDER_STATUS.CONFIRM.value: //구매확정;
			if (item.product.option.type === PRODUCT_OPTIONS_TYPE.REQUIRED) {
				if (item.isCheckReview) result.push("completeReview"); //리뷰작성완료;
				else result.push("writeReview"); //리뷰작성;
				result.push("repurchase"); //재구매;
			}
			break;
		case "REFUND_REQ": //환불요청;
			break;
		case "REFUND_IN": //환불 진행중;
			break;
		case "REFUND_FIN": //환불완료;
			break;
		case "EXCH_REQ": //교환요청;
			break;
		case "EXCH_IN": //교환 진행중;
			break;
		case "EXCH_FIN": //교환완료;
			if (item.product.option.type === PRODUCT_OPTIONS_TYPE.REQUIRED) {
				result.push("orderConfirm"); //구매확정;
			}
			break;
		case "CANCEL_REQ": //결제취소요청;
			break;
		case "CANCEL_FIN": //결제취소완료;
			break;
		case "REFUND_CANCEL": //환불철회;
			break;
		case "EXCH_CANCEL": //교환철회;
			break;
		case "REFUND_REQ_CANCEL": //환불철회 요청;
			break;
		case "EXCH_REQ_CANCEL": //교환철회 요청;
			break;
		default:
			break;
	} //end switch;

	result.push("inquiry"); //문의하기;

	return result;
};
