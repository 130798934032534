/**
 * 검색 레이어
 */

import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import useModalStack from "src/hooks/useModalStack";

import { getPopularKeyword } from "src/api/product/search";
import ModalCommonAlert from "src/pages/common/modalAlert";
import { addQueryParam } from "src/utils/linmeSearch";
import {
	getCookieSearchKeyword,
	removeCookieAllSearchKeyword,
	removeCookieSearchKeyword,
	setCookieSearchKeyword,
} from "src/utils/linmeCookie";
import { removeInnerSpinner, setInnerSpinner } from "src/utils/linmeDisplay";

import { API_RESULT_STATUS } from "src/data/constEnum";
import { COOKIE_KEY } from "src/data/key";

import "src/styles/search/layer/searchProduct.scss";

const pathList = require("src/path/pathJSON.json");
const textInfo = require("src/data/textInfo.json");

const SearchProduct = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const [isOpen, setIsOpen] = useState(props.isShow);
	const [listRecently, setListRecently] = useState(null);
	const [listPopular, setListPopular] = useState(null);
	const [recentlyIsMore, setRecentlyIsMore] = useState(false);
	const refSearchKeyword = useRef(null);
	const cookieName = COOKIE_KEY.SEARCH;

	const modalStack = useModalStack();
	const navigate = useNavigate();

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 닫기 버튼 클릭
	 * @param {Event} e
	 */
	const handleClickClose = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		setIsOpen(!isOpen);
		props.handlerToggleLayer(e, !isOpen);
	};

	/**
	 * 최근 검색어 / 인기 검색어 클릭
	 * @param {Event} e
	 * @param {String} keyword
	 */
	const handlerClickKeyword = async (e, keyword) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await setCookieSearchKeyword(cookieName, keyword);
		setListRecently(await getCookieSearchKeyword(cookieName));

		refSearchKeyword.current.value = keyword;
		search();
	};

	/**
	 * 최근 검색어 모두 지우기
	 */
	const handlerClickRemoveAllKeywordRecently = async (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await removeCookieAllSearchKeyword(cookieName);
		await setListRecently(await getCookieSearchKeyword(cookieName));
	};

	/**
	 * 최근 검색어 1건 삭제
	 * @param {Event} e
	 * @param {Number} index
	 * @param {String} keyword
	 */
	const handlerClickRemoveKeyword = async (e, index, keyword) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		await removeCookieSearchKeyword(cookieName, index, keyword);
		await setListRecently(await getCookieSearchKeyword(cookieName));
	};

	/**
	 * 검색 아이콘 클릭
	 * @param {Event} e
	 */
	const handlerClickSearch = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		search();
	};

	/**
	 * 최근 검색어 펼쳐보기 클릭
	 * @param {Event} e
	 */
	const handlerClickToggleRecentlyMore = (e) => {
		setRecentlyIsMore(!recentlyIsMore);
	};

	/**
	 * 검색 키 업 이벤트 발생 시
	 * @param {KeyboardEvent} e
	 */
	const handlerKeyUpSearch = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		let key = e.key || e.keyCode;

		if (key === "Enter" || key === 13) {
			search();
		}
	};

	const handleSetRecentKeyword = async (keyword) => {
		await setCookieSearchKeyword(cookieName, keyword);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 최근 검색어 가져오기
	 */
	const getRecentSearchKeyword = async () => {
		const recentKeyword = await getCookieSearchKeyword(cookieName);
		setListRecently(await getCookieSearchKeyword(cookieName));
	};

	/**
	 * 인기 검색어 목록 조회
	 * @returns
	 */
	const requestGetPopularKeyword = async () => {
		try {
			const result = await getPopularKeyword();
			if (result.status.toUpperCase() === API_RESULT_STATUS.SUCCESS) {
				setListPopular(result.data);
				return result.data;
			} else {
				window.linmeLog.log(result);
				return false;
			}
		} catch (err) {
			window.linmeLog.error(err);
			return false;
		}
	};

	/**
	 * 검색
	 */
	const search = async () => {
		const searchKeyword = refSearchKeyword.current.value;
		const trimKeyword = searchKeyword.replace(/\s+/g, "");

		if (searchKeyword === "") {
			modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.search.placeholder,
			});
			return false;
		}
		setIsOpen(!isOpen);
		await handleSetRecentKeyword(trimKeyword);
		if (props.afterInput) props.afterInput(trimKeyword);
		const newUrl = addQueryParam(pathList.search.result, "keyword", trimKeyword);
		navigate(newUrl);
	};

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		const getData = async () => {
			setIsOpen(props.isShow);
			if (props.isShow && refSearchKeyword.current) {
				refSearchKeyword.current.value = props.searchKeywordNow;
				await refSearchKeyword?.current?.focus();
			}

			await setInnerSpinner();
			if (!listPopular) await requestGetPopularKeyword();
			await getRecentSearchKeyword();
			await removeInnerSpinner();
		};
		getData();
	}, [props.isShow]);

	useEffect(() => {
		refSearchKeyword?.current?.focus();
	}, [isOpen]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<>
			{isOpen && <div className="backgroundLayer" onClick={handleClickClose} />}
			<div className={"layerSearchProduct " + props.className + (isOpen === true ? " on " : "")}>
				<div className="searchContainer">
					<input
						type="text"
						className="searchKeyword"
						placeholder={textInfo.search.placeholder}
						defaultValue={props.searchKeywordNow}
						onKeyUp={(e) => handlerKeyUpSearch(e)}
						ref={refSearchKeyword}
					/>
					<div className="searchIcon" onClick={(e) => handlerClickSearch(e)} />
				</div>
				<div className="recentlyKeyword">
					<div className="title">{textInfo.search.keywordRecently}</div>
					<div className="removeAll" onClick={(e) => handlerClickRemoveAllKeywordRecently(e)}>
						{textInfo.search.removeAllKeyword}
					</div>
					<div className="recentlyKeywordContent">
						<div className="recentlyKeywordCover on">
							<ul className={"recentlyKeywordList " + (recentlyIsMore ? "on" : "")}>
								{listRecently && listRecently.length ? (
									listRecently.map((item, index) => (
										<li className="recentlyKeywordItem" key={index}>
											<span onClick={(e) => handlerClickKeyword(e, item.content)}>
												{item.content}
											</span>
											<div
												className="removeRecentlyKeyword"
												onClick={(e) => handlerClickRemoveKeyword(e, index, item.content)}
											/>
										</li>
									))
								) : (
									<li className="noData" />
								)}
							</ul>
							{listRecently && listRecently.length ? (
								<>
									<div
										className={"listShowMore " + (recentlyIsMore ? "" : "on")}
										onClick={(e) => handlerClickToggleRecentlyMore(e)}
									>
										<div>{textInfo.search.keywordRecentlyResultOpen}</div>
									</div>
									<div
										className={"listShowClose " + (recentlyIsMore ? "on" : "")}
										onClick={(e) => handlerClickToggleRecentlyMore(e)}
									>
										<div>{textInfo.search.keywordRecentlyResultClose}</div>
									</div>
								</>
							) : (
								<></>
							)}
						</div>
					</div>
				</div>
				<div className="popularKeyword">
					<div className="title">{textInfo.search.keywordPopular}</div>
					<div className="dateBase">
						{moment().format(
							"MM" + textInfo.search.baseDateMonth + " DD" + textInfo.search.baseDateDay
						)}{" "}
						{textInfo.search.baseDate}
					</div>
					<div className="popularKeywordContent">
						<ul className="popularKeywordList">
							{listPopular && listPopular.length ? (
								listPopular.map((item, index) => (
									<li
										className="popularKeywordItem"
										key={index}
										onClick={(e) => handlerClickKeyword(e, item.content)}
									>
										{item.content}
									</li>
								))
							) : (
								<li className="noData" />
							)}
						</ul>
					</div>
				</div>
				<div className="clickable closeLayer" onClick={(e) => handleClickClose(e)}>
					{textInfo.button.close}
				</div>
			</div>
		</>
	);
};

export default SearchProduct;
