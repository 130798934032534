import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";

import useAuth from "src/hooks/useAuth";
import useModalStack from "src/hooks/useModalStack";

import {
	getChallengeAttendance,
	getChallengeInfo,
	postChallengeAttendance,
} from "src/api/challenge/challenge";
import {
	removeInnerSpinner,
	removeSpinner,
	setInnerSpinner,
	setSpinner,
} from "src/utils/linmeDisplay";
import ModalCommonAlert from "src/pages/common/modalAlert";

import { queryKeys } from "src/data";

const textInfo = require("src/data/textInfo.json");

// ----------------------------------------------------------------------------------------------------;
// query;
// ----------------------------------------------------------------------------------------------------;

/**
 * 챌린지 상세 정보 조회
 * @param {Number} challengeNo
 */
const requestChallengeDetail = async (challengeNo) => {
	try {
		await setSpinner();
		const result = await getChallengeInfo(challengeNo);
		return result.data;
	} catch (err) {
		window.linmeLog.error(err);
		return false;
	} finally {
		await removeSpinner();
	}
};

/**
 * useChallengeDetail
 * 챌린지 상세 정보 조회
 * @param {number} challengeNo - 챌린지 번호
 * @returns {Object} - 리턴 값:
 *    - `data`: 챌린지 상세 정보
 *    - `isLoading`: 로딩 상태
 */
const useChallengeDetail = (challengeNo) => {
	const auth = useAuth();
	const { data, isLoading } = useQuery({
		queryKey: [queryKeys.CHALLENGE_DETAIL, challengeNo],
		enabled: auth.isLogin,
		queryFn: () => requestChallengeDetail(challengeNo),
	});

	return { data, isLoading };
};

/**
 * 출석 체크 이력 조회
 * @param {Number} challengeNo
 */
const requestChallengeAttendance = async (challengeNo) => {
	try {
		await setSpinner();
		const result = await getChallengeAttendance(challengeNo);
		return result.data;
	} catch (err) {
		window.linmeLog.error(err);
		return false;
	} finally {
		await removeSpinner();
	}
};

/**
 * useChallengeAttendance
 * 출석 체크 이력 조회
 * @param {number} challengeNo - 챌린지 번호
 * @returns {Object} - 리턴 값:
 *    - `data`: 챌린지 상세 정보
 *    - `isLoading`: 로딩 상태
 */
const useChallengeAttendance = (challengeNo) => {
	const auth = useAuth();
	const { data, isLoading, refetch } = useQuery({
		queryKey: [queryKeys.CHALLENGE_ATTENDANCE, challengeNo],
		enabled: auth.isLogin,
		queryFn: () => requestChallengeAttendance(challengeNo),
	});

	return { data, isLoading, refetch };
};

// ----------------------------------------------------------------------------------------------------;
// mutation;
// ----------------------------------------------------------------------------------------------------;

/**
 * 출석 체크하기
 * @param {Number} challengeNo
 */
const requestPostChallengeAttendance = async (challengeNo) => {
	const result = await postChallengeAttendance(challengeNo);
	return result;
};

/**
 * usePostChallengeAttendance
 * 출석 체크하기
 * @param {number} challengeNo - 챌린지 번호
 */
const usePostChallengeAttendance = () => {
	const modalStack = useModalStack();
	const mutation = useMutation({
		mutationFn: requestPostChallengeAttendance,
		onMutate: () => {
			setInnerSpinner();
		},
		onSuccess: async (data) => {
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				text: textInfo.reward.challenge.attendance.complete,
			});
		},
		onError: async (error) => {
			window.linmeLog.error(error);
			await modalStack.addModal({
				id: "modalCommonAlert",
				type: "alert",
				component: <ModalCommonAlert />,
				html: error?.response?.data?.message || textInfo.reward.challenge.fail,
			});
		},
		onSettled: () => {
			removeInnerSpinner();
		},
	});

	return mutation;
};

// ----------------------------------------------------------------------------------------------------;
// FUNCTION;
// ----------------------------------------------------------------------------------------------------;

// 출석 완료인지 체크
const getIsComplete = (challengeAttendanceData, date) => {
	if (!isCurrentMonth(date)) return false;
	return challengeAttendanceData?.attendanceDays?.includes(Number(moment(date).format("DD")));
};

// 이번달인지 체크
const isCurrentMonth = (date) => {
	const currentMonth = moment().month();
	const currentYear = moment().year();
	const targetMonth = moment(date).month();
	const targetYear = moment(date).year();

	return currentMonth === targetMonth && currentYear === targetYear;
};

export {
	useChallengeDetail,
	useChallengeAttendance,
	usePostChallengeAttendance,
	getIsComplete,
	isCurrentMonth,
};
