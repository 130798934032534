import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/**
 * 고민 목록
 * @param {Object} d
 * <code>
 * {
 * 		typeNo: 1 // 1:기능식품, 2:맞춤식품;
 * }
 * </code>
 */
export const getIssueList = async (d) => {
	const url = `${API_PATHS.ISSUE_LIST}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 *
 * @param {Object} d
 * <code>
 * {
 * 		typeNo: 1 // 1:기능식품, 2:맞춤식품;
 * 		, issueNoList: {Array} //고민식별자 - [ 1, 2, 3, ... ];
 * 		, offset: 20
 * 		, page: 1
 * 		, sort: "" //정렬 ( PRODUCT_LIST_ORDER );
 * }
 * </code>
 */
export const getIssueProductList = async (d) => {
	const url = `${API_PATHS.PRODUCT_LIST_ISSUE}`;
	try {
		const result = await api.get(url, { params: d });
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
