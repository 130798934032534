const textInfo = require("src/data/textInfo.json");

/**
 * 주문내역 상세 펼쳐보기 컴포넌트
 */
const OrderExpandCover = ({ order }) => {
	/**
	 * 주문내역 상세 펼쳐보기/접기
	 * @param {Event} e
	 */
	const handlerClickExpand = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		const ct = e.currentTarget;
		const className = ct.getElementsByClassName("on")[0].className; //현재 활성화 되어있는 버튼 알아내기;
		const productList = ct.closest("li").getElementsByClassName("orderProductItem"); //상품 목록;
		const buttonClose = ct.querySelector(".productListClose");
		const buttonOpen = ct.querySelector(".productListOpen");

		if (className.indexOf("productListOpen") > -1) {
			//현재 펼쳐있는 상태면 접기;
			//주문 상품 목록 열기;
			for (let i = 0; i < productList.length; ++i) {
				if (!productList[i].classList.contains("on")) productList[i].classList.add("on");
			}

			//접기로 변경;
			buttonOpen.classList.remove("on");
			buttonClose.classList.add("on");
		} else {
			const productList = ct.closest("li").getElementsByClassName("orderProductItem");

			//주문 상품 목록 닫기 - 첫번째 상품은 열어두기;
			for (let i = 1; i < productList.length; ++i) {
				if (productList[i].classList.contains("on")) productList[i].classList.remove("on");
			}

			//펼쳐보기로 변경;
			buttonClose.classList.remove("on");
			buttonOpen.classList.add("on");
		}
	};
	return (
		<div className="expandCover">
			<div className="expandContent" onClick={(e) => handlerClickExpand(e)}>
				{order.orderItems.length - 1}
				{textInfo.common.orderList}&nbsp;
				<div className="productListClose">{textInfo.button.collapse}</div>
				<div className="productListOpen on">{textInfo.button.expand}</div>
			</div>
		</div>
	);
};

export default OrderExpandCover;
