/**
 * 마이린미 메인 - 추천 상품 리스트
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { AppContext } from "src/App";
import { getMainProductDetail } from "src/api/myLinme/main";
import {
	DEVICE_TYPE,
	PAGINATION_DEFAULT_OFFSET,
	PAGINATION_TYPE,
	PRODUCT_LIST_ORDER,
	PRODUCT_TYPE_NO,
} from "src/data/constEnum";
import useAuth from "src/hooks/useAuth";

import ProductListPagination from "src/pages/component/product/productListPagination";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/product/mainProductDisplay.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
const pathList = require("src/path/pathJSON.json");

// const htmlInfo = require( "src/data/htmlInfo.json" );
// const textInfo = require( "src/data/textInfo.json" );

const RecommendedProductList = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const auth = useAuth();
	const { device, mainSelectedMenuType } = useContext(AppContext);

	const [resultTotalCount, setResultTotalCount] = useState(0);
	const [paginationType, setPaginationType] = useState(
		device === DEVICE_TYPE.PC ? PAGINATION_TYPE.PAGE : PAGINATION_TYPE.SCROLL
	);

	const location = useLocation();
	const [param, setParam] = useState({
		offset: PAGINATION_DEFAULT_OFFSET.PRODUCT_LIST,
		page: 1,
		sort: PRODUCT_LIST_ORDER[0].value,
	});

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	//화면 resize 등;
	useEffect(() => {
		setPaginationType(device === DEVICE_TYPE.PC ? PAGINATION_TYPE.PAGE : PAGINATION_TYPE.SCROLL);
	}, [device]);

	// useEffect(() => {
	// 	setParam({ ...param, typeNo: PRODUCT_TYPE_NO[ mainSelectedMenuType ] });
	// }, [ mainSelectedMenuType ]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className="container underTopMenu mainProductDisplay">
			<div className="contents">
				<ProductListPagination
					getDataList={getMainProductDetail}
					setResultTotalCount={setResultTotalCount}
					param={param}
				/>
			</div>
		</div>
	);
};

export default RecommendedProductList;
