// 카테고리 pc 버전 컴포넌트
import { useState } from "react";
import useQueryParams from "src/hooks/useQueryParams";
import { clsx } from "clsx";
import styles from "src/styles/categoryPC.module.scss";

/**
 * 카테고리 리스트 PC 컴포넌트
 * @param {Array} props.categoryList 카테고리 리스트
 * @param {Function} props.handlerClickToggle 토글 핸들러
 */
const CategoryListPC = (props) => {
	const { categoryList, handlerClickToggle } = props;
	const query = useQueryParams({ path: "/category", isSetPageFirst: true });
	const [selectedProductType, setSelectedProductType] = useState(undefined);

	// depth 2 클릭 시
	const handleClickDepth = (categoryNo) => {
		handlerClickToggle();
		query.setAll({ productType: selectedProductType, categoryNo });
	};

	return (
		<div className={styles.categoryContainer}>
			<div className={styles.categoryItemContainer}>
				{categoryList?.map((category) => {
					const isSelected = selectedProductType === category.categoryNo;
					return (
						<div
							key={category.categoryNo}
							className={clsx([styles.categoryItem, isSelected && styles.selected])}
							onClick={() => setSelectedProductType(category.categoryNo)}
						>
							{category.name}
							<span className={clsx([styles.arrow])} />
						</div>
					);
				})}
			</div>
			{!!selectedProductType && (
				<div className={clsx([styles.categoryItemContainer, styles.depth2])}>
					{categoryList[selectedProductType - 1]?.child.map((category) => {
						return (
							<div
								key={category.categoryNo}
								className={styles.categoryItem}
								onClick={() => handleClickDepth(category.categoryNo)}
							>
								{category.name}
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};
export default CategoryListPC;
