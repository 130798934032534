/**
 * 마이페이지 - 쇼핑 내역 - 클레임
 */

import { useLocation } from "react-router-dom";

import ClaimList from "src/pages/component/claim/claimList";
import MyPageShoppingTab from "src/pages/myPage/shopping/shoppingTab";

import "src/styles/myPage/shopping.scss";

const MyPageShoppingClaim = () => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const location = useLocation();

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<>
			<div className="shoppingCover">
				<MyPageShoppingTab />
				<ClaimList data={location.state ? location.state : null} />
			</div>
		</>
	);
};

export default MyPageShoppingClaim;
