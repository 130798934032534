/**
 * 마이페이지 - 쇼핑내역 - 취소
 */

import api from "src/api/axios";
import API_PATHS from "src/api/apiPaths";

/**
 * 취소 예정 금액 조회
 * @param {Object} d
 * <code>
 * {
 * 		orderNo: 0
 * 		, itemNoList: [ 0, 0, ... ]
 * }
 * </code>
 */
export const expectedRefundAmount = async (d) => {
	const url = `${API_PATHS.ORDER_CANCEL_EXPECTED_REFUND_AMOUNT}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 주문 취소
 * @param {Object} d
 * <code>
 * {
 * 		content: ""
 * 		, itemNoList: [ 0, 0, ... ]
 * 		, orderNo: 0
 * 		, reasonNo: 0
 * }
 * </code>
 * @returns
 */
export const orderCancel = async (d) => {
	const url = `${API_PATHS.ORDER_CANCEL}`;
	try {
		const result = await api.post(url, d);
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};

/**
 * 주문 취소건 상세 조회
 * @param {Number} orderNo
 * @returns
 */
export const orderCancelDetail = async (claimNo) => {
	const url = `${API_PATHS.ORDER_CANCEL_DETAIL}`;
	try {
		const result = await api.get(url.replace(":claimNo", claimNo));
		return result.data;
	} catch (err) {
		await Promise.reject(err);
	}
};
