/**
 * 섭취알람 캘린더
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import moment from "moment";
import { useState } from "react";
import Calendar from "react-calendar";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "react-calendar/dist/Calendar.css";
import "src/styles/myLinme/alarmCalendar.scss";

const AlarmCalendar = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	//handleChangeMonth : (date: Date) => void;
	const { alarmDates, handleChangeMonth } = props;

	const [targetDate, setTargetDate] = useState(null);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 선택 날짜에 대해 설정
	 * @param {Date} newDate
	 */
	const handlerChangeDate = (newDate) => {
		setTargetDate(newDate);

		if (props?.afterSelected) props?.afterSelected(newDate);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 일별 남은 섭취 알람
	 * @param {Object}
	 * @returns
	 */
	const titleContentDisplay = ({ date, view }) => {
		let cName = "leftPillCount";
		let leftCount = null;
		const d = moment(date);

		alarmDates?.forEach((data) => {
			const formattedDate = moment(`${2024}-${data.month}-${data.day}`, "YYYY-MM-DD");

			if (formattedDate.format("YYYY-MM-DD") === d.format("YYYY-MM-DD")) {
				// 0일 경우 모두 섭취 완료
				if (data.score === 0) {
					cName = "leftPillCount complete";
					leftCount = null;
				} else {
					leftCount = data.score;
				}
			}
		});

		return <div className={cName}>{leftCount}</div>;
	};

	const setTileClassName = ({ date, view }) => {
		const today = new Date();
		const diffSource = moment(today);
		const diffTarget = moment(date);
		const diff = diffSource.diff(diffTarget, "days");
		if (diff > 0) return "before";
		else if (diffSource.format("YYYY-MM-DD") === diffTarget.format("YYYY-MM-DD")) {
			return "today";
		} else return "after";
	};

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;
	return (
		<div className="calendarContainer">
			<Calendar
				className={"linmeCalender " + (alarmDates && alarmDates.length ? "" : "noData")}
				value={targetDate}
				onChange={handlerChangeDate}
				formatDay={(locale, date) => moment(date).format("D")} //"일" 제거. 숫자만 보이게;
				formatYear={(locale, date) => moment(date).format("YYYY")} // 네비게이션 눌렀을때 숫자 년도만 보이게
				formatMonthYear={(locale, date) => moment(date).format("YYYY. MM")} // 네비게이션에서 2023. 12 이렇게 보이도록 설정
				calendarType="gregory" // 일요일 부터 시작
				showNeighboringMonth={true} // 전달, 다음달 날짜 보이기;
				next2Label={null} // +1년 & +10년 이동 버튼 숨기기
				prev2Label={null} // -1년 & -10년 이동 버튼 숨기기
				minDetail="month" //년월 선택하여 이동하는 기능 막기;
				tileContent={titleContentDisplay}
				tileClassName={setTileClassName}
				onActiveStartDateChange={({ activeStartDate }) => handleChangeMonth(activeStartDate)} // 달이 변경될 때 데이터 변경
			/>
		</div>
	);
};
export default AlarmCalendar;
