// 주문 상품 확인

import { memo } from "react";
import { Link } from "react-router-dom";
import Image from "src/pages/component/common/image";
import ProductOptionsList from "src/pages/component/order/productOptionsList";
import TEXT_INFO from "src/data/textInfo.json";
import PATH_LIST from "src/path/pathJSON.json";
import { getProductDetailType } from "src/utils/linmeSearch";

const CombinedProduct = ({ product, index }) => {
	const isOnlyQuantity =
		product?.required?.length === 1 &&
		product.required[0].categoryName1 === "" &&
		product.required[0].optionName1 === "";

	return (
		<li className="productItem">
			<div className="product">
				<Link
					to={
						PATH_LIST?.product?.detail +
						`${getProductDetailType(product.type)}/` +
						product?.productNo
					}
				>
					<div className="productImageCover">
						<Image src={product?.thumbnail?.webPath} alt={product?.name} />
					</div>
				</Link>
				<div>
					<div className="productItemBrandName">
						<Link
							to={
								PATH_LIST?.product?.detail +
								`${getProductDetailType(product.type)}/` +
								product?.productNo
							}
						>
							{product?.brand?.name}
						</Link>
					</div>
					<div className="productItemProductName">
						<Link
							to={
								PATH_LIST?.product?.detail +
								`${getProductDetailType(product.type)}/` +
								product?.productNo
							}
						>
							{product?.name}
						</Link>
					</div>
					{isOnlyQuantity ? (
						<div className="productOptionQuantity">
							{product?.required[0]?.quantity}
							<span className="quantityUnit">{TEXT_INFO.common.quantityUnit}</span>
						</div>
					) : (
						<>
							{/* 옵션 - 필수 옵션 */}
							{product?.required?.length > 0 && (
								<div className="productOptionList">
									<div className="productOptionListTitle">
										{TEXT_INFO.product.detail.option.optionType.required}
									</div>
									{product?.required?.map((option) => (
										<ProductOptionsList key={option.optionNo} option={option} />
									))}
								</div>
							)}
							{/* 옵션 - 선택 옵션 */}
							{product?.additional?.length > 0 && (
								<div className="productOptionList">
									<div className="productOptionListTitle">
										{TEXT_INFO.product.detail.option.optionType.additional}
									</div>
									{product?.additional?.map((option) => (
										<ProductOptionsList key={option.optionNo} option={option} />
									))}
								</div>
							)}
						</>
					)}
				</div>
			</div>
			{/* 판매가 */}
			<div className="salePriceInfo">
				{product?.totalPrice?.toLocaleString()}
				<span className="currencyUnit">{TEXT_INFO.common.currencyUnit}</span>
			</div>
			{/* 구매가 */}
			<div className="priceInfo">
				<div className="price">
					{product?.totalPrice?.toLocaleString()}
					<span className="currencyUnit">{TEXT_INFO.common.currencyUnit}</span>
				</div>
				<div className="priceContainer">
					{product?.totalSalePrice?.toLocaleString()}
					<span className="currencyUnit">{TEXT_INFO.common.currencyUnit}</span>
				</div>
			</div>
			{/* 배송비 */}
			<div className={`deliveryPriceInfo ${index === 0 ? "" : "gray"}`}>
				{product?.deliveryPrice?.toLocaleString()}
				<span className="currencyUnit">{TEXT_INFO.common.currencyUnit}</span>
			</div>
		</li>
	);
};

export default memo(CombinedProduct);
