/**
 * 상품 상세 - 상품 설명
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { memo, useRef } from "react";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

const textInfo = require("src/data/textInfo.json");

const ProductTabContentProductDescription = (props) => {
	const { isCheckEditor, healthData, desc } = props;
	return (
		<div className="productDescription">
			<a
				id="tabContentProductDescription"
				href="{()=>false}"
				onClick={(e) => e.preventDefault()}
				style={{ cursor: "unset" }}
			>
				{isCheckEditor ? (
					<div className="freeForm" dangerouslySetInnerHTML={{ __html: desc }}></div>
				) : (
					<div className="linmeForm on">
						{desc ? ( //상세 설명;
							<div className="paragraph">
								<div className="title">
									<div className="checkProductDetail"></div>
									<span>{textInfo.product.info.description}&nbsp;&nbsp;</span>
								</div>
								<div className="content">
									<span>{desc}</span>
								</div>
							</div>
						) : (
							<></>
						)}
						{healthData.mainFunction ? ( //주요 기능;
							<div className="paragraph">
								<div className="title">
									<div className="checkProductDetail"></div>
									<span>{textInfo.product.info.mainFunction}</span>
								</div>
								<div className="content mainFunction">{healthData.mainFunction}</div>
							</div>
						) : (
							<></>
						)}
						{healthData.dailyNutritional && healthData.dailyNutritional.length > 0 ? ( //1일 섭취량 함량;
							<div className="paragraph">
								<div className="title left">
									<div className="checkProductDetail"></div>
									<span>{textInfo.product.info.dailyTake}</span>
								</div>
								<div className="content">
									<ul className="dailyIntakeAmountList">
										<li className="dailyIntakeAmountItem classification">
											<div className="dailyIntake">{textInfo.product.info.dailyIntake}</div>
											<div className="dailyContent">{textInfo.product.info.dailyContent}</div>
											<div className="dailyNutrient">{textInfo.product.info.dailyNutrient}</div>
										</li>
										{healthData.dailyNutritional.map((each, index) => (
											<li className="dailyIntakeAmountItem" key={index}>
												<div className="dailyIntake">{each.dailyIntake}</div>
												<div className="dailyContent">{each.content}</div>
												<div className="dailyNutrient">{each.nutritionalStandard}</div>
											</li>
										))}
									</ul>
									<div className="dailyIntakeAmountInfo"></div>
								</div>
							</div>
						) : (
							<></>
						)}
						{healthData.materialText ? ( //원료명 및 함량;
							<div className="paragraph">
								<div className="title left">
									<div className="checkProductDetail"></div>
									<span>{textInfo.product.info.rawMaterialNameAndContent}</span>
								</div>
								<div className="content left">{healthData.materialText}</div>
							</div>
						) : (
							<></>
						)}
						{healthData.howToEat ? ( //섭취량 및 섭취방법;
							<div className="paragraph right">
								<div className="title">
									<div className="checkProductDetail"></div>
									<span>{textInfo.product.info.suggestedUse}</span>
								</div>
								<div className="content">{healthData.howToEat}</div>
							</div>
						) : (
							<></>
						)}
						{healthData.howToStore ? ( //보관방법;
							<div className="paragraph right">
								<div className="title">
									<div className="checkProductDetail"></div>
									<span>{textInfo.product.info.cautionForStorage}</span>
								</div>
								<div className="content">{healthData.howToStore}</div>
							</div>
						) : (
							<></>
						)}
						{healthData.caution ? ( //섭취 시 주의사항;
							<div className="paragraph precautionsForIngestion">
								<div className="title left">
									<div className="checkProductDetail"></div>
									<span>{textInfo.product.info.cautionForIntake}</span>
								</div>
								<div className="content left">{healthData.caution}</div>
							</div>
						) : (
							<></>
						)}
						{healthData.customerCenterNotes ? ( //소비자 상담 관련 전화번호;
							<div className="paragraph">
								<div className="title left">
									<div className="checkProductDetail"></div>
									<span>{textInfo.product.info.customerCenterNotes}</span>
								</div>
								<div className="content left">{healthData.customerCenterNotes}</div>
							</div>
						) : (
							<></>
						)}
						{healthData.etcInfo ? ( //기타;
							<div className="paragraph">
								<div className="title left">
									<div className="checkProductDetail"></div>
									<span>{textInfo.product.info.etc}</span>
								</div>
								<div className="content left">{healthData.etcInfo}</div>
							</div>
						) : (
							<></>
						)}
					</div>
				)}
			</a>
		</div>
	);
};

export default memo(ProductTabContentProductDescription);
