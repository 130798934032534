import React from "react";
import Image from "src/pages/component/common/image";

// 문의 상세
const InquiryDetail = ({ inquiry }) => {
	// if (inquiry.isCheckSecret) return null;

	return (
		<div className="inquiryDetail">
			<div className="question">{inquiry.content}</div>
			{inquiry.reqFile && inquiry.reqFile.length > 0 && (
				<ul className="questionFiles">
					{inquiry.reqFile.map((image, i) => (
						<li className="questionImage" key={i}>
							<Image src={image.webPath} alt="" />
						</li>
					))}
				</ul>
			)}
			{inquiry.replyContent && (
				<div className="answer">
					{inquiry.replyContent}
					{inquiry.repFile && inquiry.repFile.webPath && (
						<Image src={inquiry.repFile.webPath} alt="" />
					)}
				</div>
			)}
		</div>
	);
};

export default InquiryDetail;
