/**
 * Move To App ( deepLink )
 */

/* ---------------------------------------------------------------------------------------------------- */
/* import */
/* ---------------------------------------------------------------------------------------------------- */

import { useEffect, useState } from "react";
import { isMobile as isMobileOrTablet } from "react-device-detect";

import { moveAppDownload } from "src/utils/linmeDevice";

/* ---------------------------------------------------------------------------------------------------- */
/* styles */
/* ---------------------------------------------------------------------------------------------------- */

import "src/styles/moveToApp.scss";

/* ---------------------------------------------------------------------------------------------------- */
/* import pathList */
/* ---------------------------------------------------------------------------------------------------- */
// const pathList = require( "src/path/pathJSON.json" );

// const htmlInfo = require( "src/data/htmlInfo.json" );
const textInfo = require("src/data/textInfo.json");

const MoveToApp = (props) => {
	// ----------------------------------------------------------------------------------------------------;
	// CONST;
	// ----------------------------------------------------------------------------------------------------;

	const [isShow, setIsShow] = useState(false);

	// ----------------------------------------------------------------------------------------------------;
	// EVENT;
	// ----------------------------------------------------------------------------------------------------;

	/**
	 * 앱 실행하기
	 * @param {Event} e
	 */
	const handlerClickExecApp = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		moveAppDownload();
	};

	/**
	 * 모바일 웹으로 계속 하기
	 * @param {Event} e
	 */
	const handlerClickExecWeb = (e) => {
		//버블링 막기;
		e.preventDefault();
		e.stopPropagation();

		sessionStorage.setItem("execWeb", true);
		setIsShow(false);
	};

	// ----------------------------------------------------------------------------------------------------;
	// FUNCTION;
	// ----------------------------------------------------------------------------------------------------;

	// ----------------------------------------------------------------------------------------------------;
	// HOOK;
	// ----------------------------------------------------------------------------------------------------;

	useEffect(() => {
		const sessionExecWeb = sessionStorage.getItem("execWeb");
		if (isMobileOrTablet && sessionExecWeb !== "true") setIsShow(true);
	}, [isMobileOrTablet]);

	// ----------------------------------------------------------------------------------------------------;
	// RENDER;
	// ----------------------------------------------------------------------------------------------------;

	return (
		<div className={"moveToApp " + (isShow ? "on" : "")}>
			<div className="selectEnv">
				<div className="innerContent">
					<div className="copy" />
					{/* 앱 실행하기 */}
					<div className="button clickable execApp" onClick={(e) => handlerClickExecApp(e)}>
						{textInfo.moveToApp.execApp}
					</div>
					{/* 모바일 웹으로 보기 */}
					<div className="button clickable execWeb" onClick={(e) => handlerClickExecWeb(e)}>
						{textInfo.moveToApp.execWeb}
					</div>
				</div>
			</div>
		</div>
	);
};

export default MoveToApp;
